import { fetchAllData } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { removeFile } from "clients/files/files.client";
import { ContentItem } from "../types";

const useDeleteContentItem = () => {
  const deleteContentItem = async (contentItem: ContentItem) => {
    return removeFile({
      parentID: contentItem.parentID,
      fileId: contentItem.id,
    });
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(deleteContentItem, {
    onSuccess: async () => {
      await fetchAllData();
    },
  });

  return {
    deleteContentItem: mutate,
    deletingContentItem: isLoading,
    deleteContentItemSuccess: isSuccess,
    deleteContentItemError: error as string,
  };
};

export default useDeleteContentItem;
