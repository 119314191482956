import React from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

export const ModalHeader: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div
      data-testid="modalHeader"
      className={tw(`
        flex flex-col items-center justify-center text-center p-4 pt-12 lg:px-8  pb-2 lg:pb-2
        ${className}
      `)}
    >
      {children}
    </div>
  );
};
