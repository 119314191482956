import { SearchMonoIcon } from "Assets";
import { RecentSearch } from "../../types";
import { Text } from "UIKit";
import { useTranslation } from "react-i18next";

interface RecentSearchesListProps {
  recentSearches: RecentSearch[];
  onClickSearch: (search: RecentSearch) => void;
}

const RecentSearchesList = ({ recentSearches, onClickSearch }: RecentSearchesListProps) => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="master-search-recent-search-list"
      className="flex flex-col justify-center items-center space-y-2 my-4"
    >
      <Text level={1} className="text-grey-600 my-4">
        {t("master_search_recent_searches")}
      </Text>
      <div className="w-full max-h-50vh overflow-y-scroll">
        {recentSearches.map((search) => (
          <div
            key={search.searchText}
            className="self-start cursor-pointer p-3 w-full hover:bg-blue-50"
            onClick={() => onClickSearch(search)}
          >
            <div className="flex space-x-2">
              <SearchMonoIcon className="w-5 h-5 text-grey-600 self-center my-auto" />
              <div>
                {search.highlightedText?.map((ht, i) => {
                  if (ht.isMatch) {
                    return (
                      <Text key={`${ht.text}-${i}`} level={1} className="font-bold">
                        {ht.text}
                      </Text>
                    );
                  }

                  return <span key={`${ht.text}-${i}`}>{ht.text}</span>;
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentSearchesList;
