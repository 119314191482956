import { useCallback } from "react";
import { FileCompletionStatus } from "@eolas-medical/core";

import {
  MP4Icon,
  PDFIcon,
  CheckIcon,
  CircleIcon,
  DomainIcon,
  MSOfficeIcon,
  AttentionIcon,
} from "Assets";

import { CompletedFileProps } from "Utilities/types";

import { Button } from "../Button";
import { FileTileWrapper, FileTileProps, DefaultFileControls } from "./shared";
import { DownloadFileButton } from "Components";

const iconSize = { height: 40, width: 40 };

type LeftIcon = "pdf" | "mp4" | "link" | "ms-office";

const leftIcon: Record<LeftIcon, React.ReactNode> = {
  pdf: <PDFIcon {...iconSize} />,
  mp4: <MP4Icon {...iconSize} />,
  link: <DomainIcon {...iconSize} />,
  "ms-office": <MSOfficeIcon {...iconSize} />,
};

export interface EducationPortalFileTileProps extends FileTileProps, CompletedFileProps {
  loading?: boolean;
  onMarkAsComplete(fileId: string, status: FileCompletionStatus): void;
}

export const EducationPortalFileTile: React.FC<EducationPortalFileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  isChecked,
  isDragging,
  loading = false,
  completionLabel,
  onMarkAsComplete,
  isButtonDisabled,
  isCompleted = false,
  onSelectFile = () => {},
  ...handlers
}) => {
  const onComplete = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      onMarkAsComplete(eolasFile.id, "completed");
    },
    [eolasFile.id, onMarkAsComplete],
  );

  const showCompleteStatus = isChecked ? (
    <CheckIcon className="text-green" height={20} width={20} />
  ) : (
    <CircleIcon className="text-grey-300" height={20} width={20} />
  );

  return (
    <FileTileWrapper
      className="h-44"
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <span className="flex justify-center items-center w-min">
        {leftIcon[eolasFile.type as LeftIcon]}
      </span>
      <div className="flex flex-col flex-1 justify-between h-full">
        <div className="flex">
          <div className="flex flex-col justify-center flex-1">
            <span className="font-semibold text-lg line-clamp-1 w-4/5 break-all">
              {eolasFile.name}
            </span>
            <span className="text-grey-dark line-clamp-1 w-4/5 break-all">
              {eolasFile.description}
            </span>
          </div>
          <div className="self-start flex flex-col items-end justify-between w-max h-20">
            <DownloadFileButton eolasFile={eolasFile} />

            {!isAdmin &&
              (isCompleted ? (
                showCompleteStatus
              ) : (
                <AttentionIcon className="text-red self-end" height={20} width={20} />
              ))}
          </div>
        </div>
        {!isAdmin && (
          <span className="mt-2">
            <Button
              size="sm"
              color="grey"
              className="w-full"
              isLoading={loading}
              onClick={onComplete}
              disabled={isButtonDisabled}
              data-testid="mark-as-complete-button"
            >
              {completionLabel}
            </Button>
          </span>
        )}
      </div>

      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
