import { QueryEnvironment, useEolasQuery } from "Hooks";
import portfolioKeys from "./portfolio.queryKeys";
import { getAssignedAssessors } from "../client/portfolio.client";
import { Assessor } from "../types";

interface UseAssignedAssessors {
  assignedAssessors: Assessor[];
  assignedAssessorsLoading: boolean;
}

const useAssignedAssessors = (eventId: string): UseAssignedAssessors => {
  const { isLoading, data } = useEolasQuery({
    queryFn: () => getAssignedAssessors(eventId),
    queryKey: portfolioKeys.assignedAssessors(eventId),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 30000,
      staleTime: 15 * (60 * 1000), //15 mins
    },
  });

  return {
    assignedAssessors: data || [],
    assignedAssessorsLoading: isLoading,
  };
};

export default useAssignedAssessors;
