import { contentClient, fetchAllData } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { FileSectionCreateDto } from "modules/myFiles/types";
import { mapToCreateSectionDto } from "../helpers";

interface CreateContentSectionProps {
  contentRepositoryId: string;
  section: FileSectionCreateDto;
}

const useAddContentSection = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async ({ contentRepositoryId, section }: CreateContentSectionProps) => {
      const mappedSection = mapToCreateSectionDto(section);
      await contentClient.createSection({ parentID: contentRepositoryId, input: mappedSection });
    },
    {
      onSuccess: async () => {
        await fetchAllData();
      },
    },
  );

  return {
    addContentSection: mutate,
    addingContentSection: isLoading,
    addContentSectionSuccess: isSuccess,
    addContentSectionError: error as string,
  };
};

export default useAddContentSection;
