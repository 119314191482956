import { ClockIcon } from "Assets";
import useAssignedAssessors from "modules/portfolio/data/useAssignedAssessors";
import { EventStatus, EventType } from "modules/portfolio/types";
import { Text, Title, Avatar } from "UIKit";
import { useTranslation } from "react-i18next";
import Reassign from "./Reassign/Reassign";
import SendReminder from "./SendReminder/SendReminder";

interface AssignedAssessorsProps {
  eventId: string;
  eventStatus: EventStatus;
  eventType: EventType;
  eventOwnerId: string;
  eventOwnerEmail: string;
  isEventOwner?: boolean;
}
const AssignedAssessors = ({
  eventId,
  eventStatus,
  eventType,
  eventOwnerId,
  eventOwnerEmail,
  isEventOwner = false,
}: AssignedAssessorsProps) => {
  const { t } = useTranslation();
  const { assignedAssessors = [], assignedAssessorsLoading } = useAssignedAssessors(eventId);

  const assessor = assignedAssessors[0]; // TODO: extend component for many assessors

  return (
    <>
      <div data-testid="assigned-assessor" className="rounded-xl bg-white p-6 space-y-2">
        <div className="flex justify-between items-center">
          <Title level={6}>{t("portfolio_assigned_assessor")}</Title>
          <div className="flex items-center space-x-2">
            <ClockIcon className="text-grey-600 h-6 w-6" />
            <Text level={1} className="text-grey-600">
              {t("portfolio_wating_for_assessor")}
            </Text>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-6 items-center space-y-4 md:space-y-0 justify-between mt-8">
          <Avatar label={assessor?.email} isLoading={assignedAssessorsLoading} />
          {isEventOwner && (
            <div className="ml-auto flex items-center" data-testid="assigned-assessor-buttons">
              <SendReminder eventId={eventId} eventStatus={eventStatus} assessor={assessor} />
              <Reassign
                eventId={eventId}
                eventStatus={eventStatus}
                eventType={eventType}
                assessor={assessor}
                eventOwnerId={eventOwnerId}
                eventOwnerEmail={eventOwnerEmail}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AssignedAssessors;
