import { useTranslation } from "react-i18next";
import { Button, FileTileProps } from "UIKit";
import { useDownloadFile } from "./hooks";
import { DOWNLOADABLE_TYPES } from "Utilities/helpers";
import { EolasFileType } from "Utilities/types";

interface DownloadFileButtonProps extends Omit<FileTileProps, "isAdmin"> {
  textClass?: string;
}

export const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({
  eolasFile,
  className,
  textClass,
}) => {
  const { t } = useTranslation();
  const { saveFile } = useDownloadFile(eolasFile);

  const isDownloadable = DOWNLOADABLE_TYPES.has(eolasFile.type as EolasFileType);

  return isDownloadable ? (
    <Button
      color="blue"
      iconLeft="DownloadIcon"
      variant="link"
      type="submit"
      onClick={saveFile}
      className={`px-0 ${className}`}
    >
      <span className={`hidden xl:block ml-3 ${textClass}`}>{t("file_download_button")}</span>
    </Button>
  ) : null;
};
