import { useEolasForm } from "Hooks/useEolasForm";
import * as yup from "yup";
import { TeamMember } from "../../../types";

export const validationSchema = yup.object().shape({
  name: yup.string().required("fieldError_name_required"),
  role: yup.string().required("fieldError_staff_role_required"),
  bio: yup.string().max(320),
});

export interface TeamMemberFormValues {
  name: string;
  role: string;
  bio: string;
  photo: string | File | null;
}

const useTeamMemberForm = (teamMember?: TeamMember) => {
  const defaultValues = {
    name: teamMember?.name,
    role: teamMember?.role,
    bio: teamMember?.bio,
    photo: teamMember?.photoUrl,
  };

  const formData = useEolasForm<TeamMemberFormValues>({ validationSchema, defaultValues });
  return formData;
};
export default useTeamMemberForm;
