import envConfig from "env-config";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LDClient, LDUser, initialize } from "launchdarkly-js-client-sdk";

import { userStore, UserData, AppUserData, EolasRegions } from "@eolas-medical/core";

import { LDFlags } from "Utilities/types";
import { appConfig } from "AppTypeConfig";

import { useSentry } from "./SentryContext";
import { errorStore } from "../Stores/ErrorStore";
import { useSupportedRegion } from "Hooks/useSupportedRegion/useSupportedRegion";

interface LDState {
  flags: LDFlags;
  isLoading: boolean;
  client?: LDClient;
}

enum ActionTypes {
  CLIENT_INITALISED = "CLIENT_INITALISED",
  UPDATE_FLAGS = "UPDATE_FLAGS",
  RESET = "RESET",
}

interface Action {
  type: ActionTypes;
}

interface ClientInitalisedAction extends Action {
  client: LDClient;
}

interface UpdateFlagsAction extends Action {
  flags: LDFlags;
}

export interface LDContextValue extends LDState {
  initClient: any;
  // add any fns here
}

const APP_ATTRIBUTES = {
  isWeb: true,
  appType: appConfig.appType,
  version: envConfig.REACT_APP_VERSION as string,
};

const ANONYMOUS_USER = { anonymous: true, custom: APP_ATTRIBUTES, key: "anonymous" };

function getLDUser({
  sessionUser,
  appUser,
  supportedEolasRegion,
}: {
  sessionUser?: UserData;
  appUser?: AppUserData;
  supportedEolasRegion: EolasRegions | null;
}): LDUser {
  if (!sessionUser?.id) {
    return ANONYMOUS_USER;
  }

  const region: EolasRegions | null = supportedEolasRegion;

  const userAttributes = appUser
    ? {
        appUserID: appUser.id,
        appID: appUser.appID,
        accessLevel: appUser.accessLevel,
      }
    : {};

  return {
    key: sessionUser.id,
    // @ts-ignore
    custom: {
      ...APP_ATTRIBUTES,
      ...userAttributes,
      eolasRegion: region ? region : "unsupported",
    },
  };
}

const LDContext = React.createContext<LDContextValue>({} as LDContextValue);

const initialState: LDState = {
  // if LD is enabled, set to empty and fetch fresh if it's disabled we use the default flags
  flags: envConfig.REACT_APP_LAUNCH_DARKLY_ENABLED ? {} : appConfig.defaultLDFlags,
  isLoading: envConfig.REACT_APP_LAUNCH_DARKLY_ENABLED,
};

const ldReducer: React.Reducer<LDState, Action> = (state, action) => {
  switch (action.type) {
    case ActionTypes.CLIENT_INITALISED:
      const { client } = action as ClientInitalisedAction;
      return { ...state, client };
    case ActionTypes.UPDATE_FLAGS:
      const { flags } = action as UpdateFlagsAction;
      return { ...state, flags, isLoading: false };
    case ActionTypes.RESET:
      return { flags: appConfig.defaultLDFlags, isLoading: false };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const LDProvider: React.FC = observer(({ children }) => {
  const { userData: user, appUserData: appUser } = userStore;
  const isLoggedIn = userStore.userSession.isLoggedIn;
  // add this to the user store
  const isLoading = false;

  const { supportedEolasRegion } = useSupportedRegion();
  const { addBreadcrumb } = useSentry();
  const [state, dispatch] = React.useReducer(ldReducer, initialState);

  useEffect(() => {
    initClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  // user changes hook
  useEffect(() => {
    if (!state.client) {
      return;
    }

    const currentLdUser = state.client.getUser();

    if (!user && currentLdUser) {
      addBreadcrumb({
        category: "launchdarkly",
        message: "LD identify",
      });
      state.client.identify(getLDUser({ supportedEolasRegion }));
      return;
    }

    if (currentLdUser?.key !== user?.id || currentLdUser?.custom?.appUserID !== appUser?.id) {
      addBreadcrumb({
        category: "launchdarkly",
        message: "LD identify - user changed",
      });
      const ldUser = getLDUser({ sessionUser: user, appUser, supportedEolasRegion });
      state.client.identify(ldUser);
    }
  }, [user, appUser, state.client, addBreadcrumb, supportedEolasRegion]);

  const initClient = () => {
    if (isLoading || !envConfig.REACT_APP_LAUNCH_DARKLY_ENABLED) {
      return;
    }

    addBreadcrumb({
      category: "launchdarkly",
      message: "LD initialize",
    });
    const ldUser: LDUser = getLDUser({ sessionUser: user, appUser, supportedEolasRegion });
    try {
      const client = initialize(envConfig.REACT_APP_LAUNCH_CLIENT_SIDE_ID, ldUser);

      client.on("ready", () => {
        addBreadcrumb({
          category: "launchdarkly",
          message: "LD flags ready",
        });
        const flags = client.allFlags();
        dispatch({ type: ActionTypes.UPDATE_FLAGS, flags } as UpdateFlagsAction);
      });

      client.on("change", () => {
        addBreadcrumb({
          category: "launchdarkly",
          message: "LD flags changed",
        });
        const flags = client.allFlags();
        dispatch({ type: ActionTypes.UPDATE_FLAGS, flags } as UpdateFlagsAction);
      });

      client.on("error", (error) => {
        addBreadcrumb({
          category: "launchdarkly",
          message: "error",
        });

        if (Object.keys(state.flags || {}).length === 0) {
          // if flags are empty, use the default flags
          dispatch({
            type: ActionTypes.UPDATE_FLAGS,
            flags: appConfig.defaultLDFlags,
          } as UpdateFlagsAction);
        }
      });

      dispatch({ type: ActionTypes.CLIENT_INITALISED, client } as ClientInitalisedAction);
    } catch (error: any) {
      errorStore.captureError({ error, source: "network" });
    }
  };

  const value: LDContextValue = { ...state, initClient };

  return <LDContext.Provider value={value}>{children}</LDContext.Provider>;
});

function useLaunchDarkly() {
  const context = React.useContext(LDContext);
  if (context === undefined) {
    throw new Error("useLaunchDarkly must be used within a LDProvider");
  }
  return context;
}

LDProvider.displayName = "LDProvider";

export { LDProvider, useLaunchDarkly };
