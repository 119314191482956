import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { OrganisationLevelSection } from "@eolas-medical/core";

import { EolasFileList } from "Components";
import { useMainSectionVisit } from "Hooks";
import { InnerPageWrapper, NavButton, PageTitle } from "UIKit";

import { useMedusaFile } from "../useMedusaFile";

export const MedusaFilesPage: React.FC<RouteComponentProps> = observer(
  ({ match: { params }, history }: any) => {
    const { t } = useTranslation();
    const { onSelectMedusaFile } = useMedusaFile();

    useMainSectionVisit({
      mainSectionId: OrganisationLevelSection.medusaMedications,
      subSectionId: decodeURIComponent(params.id),
    });

    return (
      <InnerPageWrapper>
        <PageTitle
          title={t("medusa_files_title", {
            monoType: params.sectionName,
          })}
          titleClass="capitalize"
        />
        <NavButton onClick={history.goBack} />
        <EolasFileList
          isAdmin={false}
          draggable={false}
          defaultSort="alphabetical"
          onSelectFile={onSelectMedusaFile}
          mainSectionId={OrganisationLevelSection.medusaMedications}
          subSectionId={decodeURIComponent(params.id)}
        />
      </InnerPageWrapper>
    );
  },
);
