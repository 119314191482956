/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import { sectionStore, userStore } from "@eolas-medical/core";
import OneSignal from "react-onesignal";

import { Text, Button } from "UIKit";
import { appConfig } from "AppTypeConfig";
import { BaseAppRoute } from "Utilities/types";
import { CollapseIcon, EolasIcons } from "Assets/Icons";

import { EolasIcon } from "../../assets/appLogo";
import { customLogos } from "../../assets/customLogos";
import { logout } from "Pages/Authentication/auth.actions";
import { useQueryClient } from "@tanstack/react-query";
import { useCustomTheme } from "../../hooks/useCustomTheme";
import useEolasNavigation from "../../context/useEolasNavigation";
import useSpacesContext from "modules/spaces/context/useSpacesContext";

const buttonClass = "h-10 px-0 rounded-xl w-full";
const spaceActiveClass = "text-blue-500";
const organisationActiveClass = "text-red-hospital";
const knowledgeActiveClass = " text-green-600";

interface NavigationProps {
  drawerExpanded: boolean;
  toggleDrawerExpanded: (drawerExpanded: any) => void;
}

export const DesktopNavigation: React.FC<NavigationProps> = observer(
  ({ drawerExpanded, toggleDrawerExpanded }) => {
    const { push } = useHistory();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { customLogo, customName, hasCustomBranding } = useCustomTheme();
    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
    const { onClearSpace } = useSpacesContext();

    const AppIcon = (customLogo && customLogos[customLogo]) || EolasIcon;
    const appName = hasCustomBranding ? customName : appConfig.appName;

    const onSwitchOrg = React.useCallback(async () => {
      sectionStore.clearStore();
      sectionStore.setIsAdmin(false);
      userStore.signOutOrganisation();
      queryClient.clear();
      onClearSpace();
      OneSignal.sendTag("departmentCreationDate", "null");
      push(`/${BaseAppRoute.selectSpace}`);
    }, [push, onClearSpace, queryClient]);

    const onLogout = React.useCallback(async () => {
      setIsLoggingOut(true);
      queryClient.clear();
      await logout();
      onClearSpace();
      push("/");
    }, [push, onClearSpace, queryClient]);

    const {
      activeTab,
      hasSpacesTab,
      hasOrganisationTab,
      hasSelectedSpace,
      hasKnowledgeTab,
      goMeTab,
      goToOrganisationTab,
      goToSpaceTab,
      goToSelectSpace,
      goToKnowledgeTab,
    } = useEolasNavigation();

    return (
      <motion.div
        layout="size"
        initial={{
          borderRadius: 32,
        }}
        className={`
        hidden lg:flex flex-col items-center bg-white p-5 my-5 ml-5
        ${drawerExpanded ? "w-56" : "w-20"}
      `}
        transition={{ type: "spring", stiffness: 98, damping: 13 }}
        style={{
          border: "0.5px solid #EBEFF1",
          boxShadow: "11px 14px 24px 0px #0000000A",
        }}
      >
        <motion.button
          layout
          onClick={toggleDrawerExpanded}
          animate={{ rotate: drawerExpanded ? 0 : 180 }}
          transition={{ type: "spring", mass: 1, stiffness: 98, damping: 13 }}
          className={`
          bg-grey-200 h-8 w-8 rounded-full flex items-center justify-center
          ${drawerExpanded ? "self-end order-1" : "self-center order-2"}
        `}
        >
          <CollapseIcon className="h-4 w-4" />
        </motion.button>

        <motion.div
          className={`
          flex flex-col items-center w-2/3 self-center space-y-2
          ${drawerExpanded ? "order-2 mt-4" : "order-1 mb-4"}
        `}
        >
          <motion.div layout>
            <AppIcon className="h-10 w-10" />
          </motion.div>

          <AnimatePresence>
            {drawerExpanded && (
              <motion.span className="text-darkblue-500 text-center font-bold">
                {appName}
              </motion.span>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div className="flex flex-col self-stretch space-y-2 mt-8 order-4">
          <NavigationItem
            navdrawerExpanded={drawerExpanded}
            testId="navigation_me-tab"
            onClick={goMeTab}
            label={t("navigation_me_tab")}
            isActive={activeTab === "my-profile"}
            activeClassname="bg-yellow-me text-yellow-700"
            icon="PersonIcon"
          />
          {!hasSelectedSpace && (
            <NavigationItem
              navdrawerExpanded={drawerExpanded}
              testId="navigation_department-tab"
              onClick={goToSelectSpace}
              activeClassname={spaceActiveClass}
              isActive={activeTab === "select-space"}
              label={t("navigation_department_tab")}
              icon={"SpacesIcon"}
            />
          )}

          {hasSelectedSpace && (
            <>
              {hasSpacesTab && (
                <NavigationItem
                  navdrawerExpanded={drawerExpanded}
                  onClick={goToSpaceTab}
                  testId="navigation_department-tab"
                  isActive={activeTab === "spaces"}
                  label={t("navigation_department_tab")}
                  activeClassname={spaceActiveClass}
                  icon="SpacesIcon"
                />
              )}

              {hasOrganisationTab && (
                <NavigationItem
                  navdrawerExpanded={drawerExpanded}
                  onClick={goToOrganisationTab}
                  testId="navigation_hospital-tab"
                  label={t("navigation_hospital_tab")}
                  isActive={activeTab === "organisation"}
                  activeClassname={organisationActiveClass}
                  icon="OrganisationIcon"
                />
              )}
            </>
          )}

          {hasKnowledgeTab && (
            <NavigationItem
              navdrawerExpanded={drawerExpanded}
              onClick={goToKnowledgeTab}
              label={t("navigation_knowledge_tab")}
              isActive={activeTab === "knowledge"}
              activeClassname={knowledgeActiveClass}
              testId="navigation_knowledge-tab"
              icon="KnowledgeHubIcon"
            />
          )}
        </motion.div>

        <div className={`flex flex-col order-10 mt-auto self-stretch `}>
          <a target="_blank" rel="noreferrer" href="https://support.eolasmedical.com/en/ ">
            <Button
              color="grey"
              variant="ghost"
              iconLeft="SearchFileIcon"
              className={`${buttonClass} text-grey-600 `}
            >
              {drawerExpanded && (
                <Text level={2} className="ml-4 w-2/3 text-left">
                  {t("navigation_user_guide")}
                </Text>
              )}
            </Button>
          </a>

          <a target="_blank" rel="noreferrer" href={appConfig.termlyPrivacyPolicy}>
            <Button
              color="grey"
              variant="ghost"
              iconLeft="PolicyIcon"
              className={`${buttonClass} text-grey-600 `}
            >
              {drawerExpanded && (
                <Text level={2} className="ml-4 w-2/3 text-left">
                  {t("navigation_privacy_policy")}
                </Text>
              )}
            </Button>
          </a>

          <Button
            color="grey"
            variant="ghost"
            iconLeft="CookiesIcon"
            // @ts-ignore
            onClick={() => displayPreferenceModal()}
            className={`${buttonClass} text-grey-600 termly-cookie-preference-button`}
            id="termly-consent-preferences"
          >
            {drawerExpanded && (
              <Text level={2} className="ml-4 w-2/3 text-left">
                {t("navigation_manage_cookies")}
              </Text>
            )}
          </Button>

          <Button
            color="grey"
            variant="outline"
            onClick={onSwitchOrg}
            iconLeft="ShuffleMonoIcon"
            className="h-10 px-0 mt-6"
            disabled={!hasSelectedSpace}
          >
            {drawerExpanded && (
              <Text level={2} className="ml-2">
                {t("navigation_switch_organisation")}
              </Text>
            )}
          </Button>

          <Button
            onClick={onLogout}
            iconLeft={"LogoutIcon"}
            data-testid="logoutButton"
            className="h-10 px-0 mt-3"
            isLoading={isLoggingOut}
          >
            {drawerExpanded && (
              <Text level={2} className="ml-4">
                {t("navigation_logout")}
              </Text>
            )}
          </Button>
        </div>
      </motion.div>
    );
  },
);

interface NavigationItemProps {
  label: string;
  testId?: string;
  icon: EolasIcons;
  isActive: boolean;
  navdrawerExpanded: boolean;
  activeClassname?: string;
  onClick(): void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  icon,
  label,
  testId,
  isActive,
  navdrawerExpanded,
  activeClassname,
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      data-testid={testId}
      color="grey"
      variant="ghost"
      iconLeft={icon}
      className={`h-10 px-0 rounded-xl w-full  ${
        isActive ? activeClassname : "bg-transparent text-grey-600"
      }`}
    >
      {navdrawerExpanded && (
        <Text level={2} className="ml-4 w-2/3 text-left">
          {label}
        </Text>
      )}
    </Button>
  );
};
