import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { EolasFile, getPreSignUrl } from "@eolas-medical/core";
import { VideoPlayer } from "Components";
import { Button, Modal, NavButton } from "UIKit";
import { BlobTypes } from "modules/generic.types";
import { useFileDownload } from "shared/hooks/useFileDownload";
import useFileViewer from "../../hooks/useFileViewer";

interface VideoViewerProps {
  video: EolasFile;
}
const VideoViewer = ({ video }: VideoViewerProps) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const { file, setFile, onFileLoaded, onFileError } = useFileViewer();

  const getFile = useCallback(
    async (key: string) => {
      const presignedUrl = await getPreSignUrl(key);
      return {
        fileName: video.name || "",
        fileUrl: presignedUrl,
        fileExt: video.type || "pdf",
      };
    },
    [video],
  );

  const { downloadFile } = useFileDownload({ getFileAsync: getFile });

  const handleDownloadFile = () => {
    downloadFile(video.id, { onSuccess: () => {} });
  };

  const fetchFileUrl = useCallback(async () => {
    const { fileUrl } = await getFile(video.key || "");
    setFile({
      id: video.id,
      name: video.name || "",
      type: video.type as BlobTypes,
      url: fileUrl,
    });
  }, [setFile, video, getFile]);

  useEffect(() => {
    fetchFileUrl();
  }, [fetchFileUrl]);

  return (
    <Modal hasCloseIcon={false} overlayColor="bg-grey-light" open={true} onClose={goBack}>
      <div className="h-100vh w-100vw">
        <div className="bg-grey-light py-1">
          <div className="flex justify-center space-x-3">
            <NavButton onClick={goBack} />
            <Button size="sm" onClick={handleDownloadFile}>
              {t("general_download_original")}
            </Button>
          </div>
        </div>
        <div className="bg-grey-light overflow-auto h-full">
          <div className="p-4">
            <VideoPlayer
              url={file?.url || ""}
              title={video.name || ""}
              onLoad={onFileLoaded}
              onError={onFileError}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VideoViewer;
