import { Redirect } from "react-router-dom";

import { loader } from "Assets";
import { Modal, ModalBody, LottieWithHeader, InnerModalWrapper } from "UIKit";

import { LoginFlow } from "../../components/LoginFlow";
import { useAcceptInvitation } from "../hooks/useAcceptInvitation";
import { InvitationAccepted } from "../../components/InvitationAccepted";
import { InvitationFailure } from "../../components/InvitationFailure";
import { useEffect } from "react";
import OneSignal from "react-onesignal";

export const AcceptInvitation = () => {
  useEffect(() => {
    OneSignal.sendTag("startedQuickDepartmentAccessDate", new Date().toISOString());
  }, []);

  return (
    <Modal hasCloseIcon={false} open onClose={() => {}}>
      <ModalContent />
    </Modal>
  );
};

const ModalContent = () => {
  const { status, invitationDetails, error } = useAcceptInvitation();

  if (status === "NotLoaded") {
    return (
      <InnerModalWrapper>
        <ModalBody>
          <LottieWithHeader
            animation={loader}
            animationSize="20rem"
            lottieOptions={{ loop: true }}
            text="Processing invitation..."
          />
        </ModalBody>
      </InnerModalWrapper>
    );
  }

  if (status === "Login") {
    return <LoginFlow invitation={invitationDetails} />;
  }

  if (status === "SignUp") {
    return <Redirect to={{ pathname: "/signup", state: invitationDetails }} />;
  }

  if (status === "Accepted") {
    return <InvitationAccepted />;
  }

  return <InvitationFailure error={error} />;
};
