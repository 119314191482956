import { AttachedIcon } from "Assets";
import ReactTooltip from "react-tooltip";
import { theme, Text } from "UIKit";

interface AttachmentReferenceProps {
  isReferenced: boolean;
  tooltipText?: string;
  fileId?: string;
}

export const AttachmentReference = ({
  isReferenced,
  tooltipText,
  fileId,
}: AttachmentReferenceProps) => {
  return (
    <span className="self-start h-2 w-2">
      {tooltipText && (
        <ReactTooltip
          id={`${fileId}_referenced-attachment`}
          type="light"
          place="right"
          effect="solid"
          className="tooltip shadow-md rounded-lg"
          backgroundColor={theme.colors.white}
        >
          <Text level={3}>{tooltipText}</Text>
        </ReactTooltip>
      )}
      {isReferenced && <AttachedIcon data-for={`${fileId}_referenced-attachment`} data-tip />}
    </span>
  );
};
