import { useCallback, useEffect, useState, useImperativeHandle, RefObject } from "react";
import { InnerModalWrapper, ModalBody, ModalWizardButtons, ModalHeader } from "../Modal/components";
import { Title, Text, Button } from "UIKit";
import { WizardProgress } from "UIKit/FormElements";
import { Step, WizardRef } from "./types";
import { defaultNextStep, defaultPrevStep, getStepIndexFromSteps } from "./helpers";

interface WizardProps<T extends string> {
  wizardControl: RefObject<WizardRef<T>>;
  steps: Step<T>[];
  initialStep?: T;
  showSteps?: boolean;
  showProgress?: boolean;
  getNextStep?: (currentStep: T) => T;
  getPrevStep?: (currentStep: T) => T;
}

const Wizard = <T extends string>(props: WizardProps<T>) => {
  const {
    wizardControl,
    steps,
    initialStep,
    showSteps = false,
    showProgress = false,
    getNextStep = (currentStep: T) => defaultNextStep(steps, currentStep),
    getPrevStep = (currentStep: T) => defaultPrevStep(steps, currentStep),
  } = props;

  const [currentStep, setCurrentStep] = useState<T>(initialStep || steps[0].name);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const currentStepIndex = getStepIndexFromSteps(steps, currentStep);

  const {
    title,
    subtitle,
    component,
    canProceed = true,
    submitError,
    isLoading = false,
    onSubmit,
    onPrev,
    nextLabel = "Next",
    backLabel = "Back",
    backDisabled = false,
    nextDisabled = false,
    onlySubmitStep = false,
    topIcon,
    hideButtons = false,
  } = steps[currentStepIndex];

  const handleNext = useCallback(() => {
    const nextStep = getNextStep(currentStep);

    if (nextStep) {
      setCurrentStep(nextStep);
    }
  }, [currentStep, getNextStep]);

  const handlePrevious = async () => {
    if (onPrev) {
      await onPrev();
    }

    const prevStep = getPrevStep(currentStep);

    if (prevStep) {
      setCurrentStep(prevStep);
    }
  };

  const handleSubmit = async () => {
    if (onSubmit) {
      await onSubmit();
      setIsSubmitting(true);
      return;
    }
    handleNext();
  };

  useEffect(() => {
    if (isSubmitting) {
      if (canProceed) {
        handleNext();
        setIsSubmitting(false);
      }
      if (submitError) {
        setIsSubmitting(false);
      }
    }
  }, [canProceed, handleNext, isSubmitting, submitError]);

  // Expose internal method using ref
  useImperativeHandle<any, WizardRef<T>>(wizardControl, () => ({
    submit: handleSubmit,
    steps,
  }));

  const renderButtons = () => {
    if (hideButtons) {
      return null;
    }

    if (onlySubmitStep) {
      return (
        <div className="flex justify-center">
          <Button
            onClick={handleSubmit}
            isLoading={isLoading}
            type="submit"
            disabled={nextDisabled}
          >
            {nextLabel}
          </Button>
        </div>
      );
    }

    return (
      <ModalWizardButtons
        maxSteps={steps.length}
        currentStep={currentStepIndex}
        backDisabled={backDisabled}
        nextDisabled={nextDisabled}
        onBack={handlePrevious}
        isLoading={isLoading}
        onNext={handleSubmit}
        backLabel={backLabel}
        nextLabel={nextLabel}
        showSteps={showSteps}
      />
    );
  };

  const showHeader = topIcon || title || subtitle;

  return (
    <InnerModalWrapper>
      {showProgress && <WizardProgress currentStep={currentStepIndex} maxSteps={steps.length} />}

      {showHeader && (
        <ModalHeader className="flex flex-col">
          {topIcon && <div className="w-10 h-10 md:w-12 md:h-12">{topIcon}</div>}
          <Title level={6}> {title}</Title>
          {subtitle && (
            <Text level={1} className="text-grey-700">
              {subtitle}
            </Text>
          )}
        </ModalHeader>
      )}
      <ModalBody className="overflow-y-auto overflow-x-hidden max-h-70vh">
        <div className="grid grid-cols-2 gap-x-6 gap-y-2 p-2">
          <div className="col-span-2">
            {component}
            {renderButtons()}
          </div>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default Wizard;
