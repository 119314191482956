import React, { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import OneSignal from "react-onesignal";
import envConfig from "env-config";
import { userStore, AppPlatform, notificationsClient } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import webNotificationsKeys from "modules/webNotifications/data/webNotifications.queryKeys";
import PushNotificationsContext from "./pushNotifications.context";

const PushNotificationsProvider: React.FC = ({ children }) => {
  const { userID } = userStore;
  const { appUserData: appUser } = userStore;
  const accessLevel = appUser.accessLevel;
  const { selectedSpace } = useSpacesContext();

  const { flags } = useLaunchDarkly();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (userID && flags[LDFlagNames.WEB_PUSH_NOTIFICATIONS] && userStore.userData.email) {
      OneSignal.init({
        appId: envConfig.REACT_APP_ONESIGNAL_APP_ID,
      });

      // Set external user ID (userId) and email for user targeting
      OneSignal.setExternalUserId(userID);
      OneSignal.setEmail(userStore.userData.email);

      // Update notification center when a notification is displayed
      OneSignal.on("notificationDisplay", () => {
        queryClient.refetchQueries(webNotificationsKeys.list());
      });
    }
  }, [flags, userID, queryClient]);

  useEffect(() => {
    if (userID && flags[LDFlagNames.WEB_PUSH_NOTIFICATIONS]) {
      OneSignal.isPushNotificationsEnabled((isEnabled) => {
        if (!isEnabled) {
          OneSignal.showSlidedownPrompt();
        } else {
          OneSignal.getUserId(function (userId) {
            if (userId) {
              // Set departmentId, organisationId, and accessLevel tags
              if (selectedSpace?.id) OneSignal.sendTag("departmentId", selectedSpace.id);
              if (selectedSpace?.organisationID)
                OneSignal.sendTag("organisationId", selectedSpace.organisationID);
              if (accessLevel) OneSignal.sendTag("accessLevel", accessLevel);

              // Save the notification token and configuration
              if (!userID) {
                notificationsClient.saveAnonymousToken({
                  tokenId: userId,
                  platform: AppPlatform.WEB,
                });
              } else {
                notificationsClient.saveUserToken({
                  tokenId: userId,
                  userId: userID,
                  departmentId: selectedSpace?.id,
                  organisationId: selectedSpace?.organisationID,
                  platform: AppPlatform.WEB,
                });
              }
            }
          });
        }
      });
    } else {
      //if no userID (not logged in) tags will be set as null
      OneSignal.sendTag("departmentId", "null");
      OneSignal.sendTag("organisationId", "null");
      OneSignal.sendTag("accessLevel", "null");
    }
  }, [userID, selectedSpace, flags, accessLevel]);

  return (
    <PushNotificationsContext.Provider value={{}}>{children}</PushNotificationsContext.Provider>
  );
};

export default PushNotificationsProvider;
