import { observer } from "mobx-react-lite";
import { ReactNode, useCallback, useEffect, useState } from "react";

import { userStore } from "@eolas-medical/core";

import { Modal } from "UIKit";

import { TnCModal, DownloadAppsModal } from "../components";
import useUpdateUserProfile from "Pages/MeTab/pages/MyProfile/hooks/useUpdateUserProfile";

export const SelfSetup = observer(() => {
  const { userData, updateData, userID } = userStore;

  const [modal, setModal] = useState<ReactNode>(null);

  const { updateUser, updatingUser } = useUpdateUserProfile({
    onSuccess: (user) => {
      if (user) {
        updateData({ user });
        handleCloseModal();
        setModal(<DownloadAppsModal onCloseModal={handleCloseModal} />);
      }
    },
  });

  const handleCloseModal = useCallback(() => setModal(null), [setModal]);

  const handleTnCSuccess = useCallback(async () => {
    if (userID) {
      const completedTour = new Date().toISOString();
      await updateUser(userID, {
        ...userData,
        completedTour,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCloseModal, userData]);

  useEffect(() => {
    const shouldShowTermsModal = userID && !userData.completedTour;

    if (shouldShowTermsModal) {
      setModal(<TnCModal onSubmit={handleTnCSuccess} isLoading={updatingUser} />);
    }
  }, [userID, userData.completedTour, handleTnCSuccess, updatingUser]);

  return (
    <Modal
      open={!!modal}
      hasCloseIcon={false}
      onClose={handleCloseModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      {modal}
    </Modal>
  );
});
