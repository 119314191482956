import { useTranslation } from "react-i18next";
import {
  ConfigureFileValues,
  Step,
  Wizard,
  useConfigureFileStep,
  useWizard,
  useNameAndUrlStep,
  NameAndUrlValues,
} from "UIKit/Wizard";
import useEditContentItem from "modules/contentRepository/data/useEditContentItem";
import { ContentItem } from "modules/contentRepository/types";
import { useMemo } from "react";

interface EditContentItemModalProps {
  contentItem: ContentItem;
  onClose: () => void;
}

const EditContentItemModal = ({ contentItem, onClose }: EditContentItemModalProps) => {
  const { t } = useTranslation();
  const { editContentItem, editingContentItem } = useEditContentItem();
  const { wizardControl, submitWizard } = useWizard<"edit-item">();

  const handleUpdateFileItem = ({ name, keywords, expiryDate }: ConfigureFileValues) => {
    editContentItem(
      {
        contentItem,
        editItem: {
          name,
          description: keywords,
          expiryDate,
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  const handleUpdateLinkItem = ({ name, url }: NameAndUrlValues) => {
    editContentItem(
      {
        contentItem,
        editItem: {
          name,
          linkUrl: url,
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  const editLinkDetailsStep = useNameAndUrlStep<"edit-item">({
    stepName: "edit-item",
    title: t("repository_edit_item_title"),
    onSubmit: handleUpdateLinkItem,
    isLoading: editingContentItem,
    nameLabel: t("repository_file_name_label"),
    namePlaceholder: t("repository_file_name_placeholder"),
    urlLabel: t("filePicker_url_label"),
    urlPlaceholder: t("filePicker_url_placeholder"),
    initialValues: {
      name: contentItem.name,
      url: contentItem.linkUrl || "",
    },
    nextLabel: t("general_done"),
    backLabel: t("general_cancel"),
  });

  const editFileDetails = useConfigureFileStep<"edit-item">({
    stepName: "edit-item",
    title: t("repository_edit_item_title"),
    onEnter: submitWizard,
    onSubmit: handleUpdateFileItem,
    isLoading: editingContentItem,
    item: {
      name: contentItem.name || undefined,
      expiryDate: contentItem.expiryDate || undefined,
      keywords: contentItem.description || undefined,
    },
    toggleExpiryCheckText: t("repository_item_expiry_checkbox_text"),
    toggleExpiryLabel: t("repository_item_expiry_checkbox_label"),
    expiryDatePickerLabel: t("repository_item_expiry_date_picker_label"),
    nameInputLabel: t("repository_file_name_label"),
    nameInputPlaceholder: t("repository_file_name_placeholder"),
    keyWordsLabel: t("repository_file_keywords_label"),
    keyWordsPlaceholder: t("repository_file_keywords_placeholder"),
    nextLabel: t("general_done"),
    backDisabled: true,
  });

  const steps: Step<"edit-item">[] = useMemo(() => {
    if (contentItem.type === "link") {
      return [editLinkDetailsStep];
    }

    return [editFileDetails];
  }, [editFileDetails, editLinkDetailsStep, contentItem.type]);

  return (
    <div data-testid="edit-content-item-modal">
      <Wizard steps={steps} wizardControl={wizardControl} />
    </div>
  );
};

export default EditContentItemModal;
