import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";
import { DownloadFileButton } from "Components";

export const RegularFileTile: React.FC<FileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  isDragging,
  className = "h-40",
  onSelectFile = () => {},
  ...handlers
}) => {
  const { t } = useTranslation();
  const { info, updatedAt, name } = eolasFile;

  return (
    <FileTileWrapper
      className={className}
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-1 h-full justify-between">
        <span className="text-lg font-semibold mb-1 line-clamp-1 leading-10">{name}</span>
        <div className="flex flex-col">
          {info && (
            <span className="text-sm text-grey-darker capitalize line-clamp-2">
              {t("fileTile_updated_by", { user: info })}
            </span>
          )}

          {updatedAt && (
            <span className="text-sm text-grey-darker">
              {t("fileTile_last_update", {
                date: format(new Date(updatedAt as string), "do MMMM yyyy, HH:mm"),
              })}
            </span>
          )}
        </div>
      </div>
      <div className="w-fit self-start flex flex-col items-end justify-between h-full">
        <DownloadFileButton eolasFile={eolasFile} />
      </div>
      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
