import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import {
  AnalyticsEvents,
  EolasFile,
  NationalResourcesClickPayload,
  NationalResourceEntityType,
} from "@eolas-medical/core";

import { NavButton, PageTitle, InnerPageWrapper, Modal } from "UIKit";

import useSearchNationalResources from "modules/nationalResources/data/useSearchNationalResources";
import { EolasList } from "Components/EolasFileList/EolasList";
import CommunityListItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import { trackEvent } from "API/Analytics";
import { CommunitySectionType } from "../../ClinicalCalculators/types";
import { PatientLeafletQRModal } from "Pages/Spaces/pages/Space/pages/MiniApp/PatientLeaflets/components";

const PatientLeafletsBody: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  const [searchItems, setSearchItems] = useState<EolasFile[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [modal, setModal] = useState<React.ReactNode>(null);
  const [showSearchResult, setShowSearchResult] = useState(false);

  const history = useHistory();

  const location = useLocation();
  const { body } = (location.state as { body: string }) || {};

  const { t } = useTranslation();

  const { searchResult, onSetSearchInput } = useLocalSearch({
    data: searchItems,
    keysToSearch: ["name"],
  });

  const { searchNationalResources, searchingNationalResources } = useSearchNationalResources();

  const handleLocalSearch = useCallback(() => {
    onSetSearchInput(searchInput);
    setShowSearchResult(true);
  }, [onSetSearchInput, searchInput]);

  const handleClickLeaflet = (leaflet: EolasFile) => {
    trackEvent(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
      nationalResourceType: NationalResourceEntityType.LEAFLET,
      resourceName: leaflet.name,
    } as NationalResourcesClickPayload);
    setModal(<PatientLeafletQRModal file={leaflet} />);
  };

  const handleClearSearch = useCallback(() => {
    setSearchInput("");
    setShowSearchResult(false);
  }, []);

  useEffect(() => {
    searchNationalResources(
      {
        entityType: NationalResourceEntityType.LEAFLET,
        query: body,
        mode: "searchByNationalBody",
      },
      {
        onSuccess: (response) => {
          setSearchItems(response);
        },
      },
    );
  }, [body, searchNationalResources]);

  useEffect(() => {
    if (searchInput === "") {
      setShowSearchResult(false);
    }
  }, [searchInput]);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={t("community_patientLeaflets_title")} />
        <NavButton onClick={() => history.goBack()} />
        <EolasList<EolasFile, "click">
          value={searchInput}
          placeholderSearchText={t("findPatientLeaflets_searchPlaceholder")}
          items={showSearchResult ? searchResult : searchItems}
          isSearchable
          searchType="click"
          isLoading={searchingNationalResources}
          onSearchInputChange={setSearchInput}
          onClearSearch={handleClearSearch}
          onClickSearch={handleLocalSearch}
          renderItem={(eolasFile) => (
            <CommunityListItem
              eolasFile={eolasFile}
              onSelectFile={handleClickLeaflet}
              sectionType={CommunitySectionType.NATIONAL_RESOURCE}
            />
          )}
        />
      </InnerPageWrapper>
      <Modal open={!!modal} onClose={() => setModal(null)}>
        {modal}
      </Modal>
    </>
  );
});

export default PatientLeafletsBody;
