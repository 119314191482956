import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { SpaceUpdateDto, SpecialtyOptions, specialtyOptions } from "@eolas-medical/core";

import { Option } from "UIKit/Select/Select";
import useImageBank from "Hooks/useImageBank";
import ImagePicker from "UIKit/ImagePicker/ImagePicker";
import { useTranslation } from "react-i18next";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useValidateSpaceName from "modules/spaces/data/useValidateSpace";
import AccessTypeForm from "Pages/Spaces/components/AccessTypeForm/AccessTypeForm";
import useWizard from "UIKit/Wizard/useWizard";
import { Step, Wizard } from "UIKit";
import SpaceForm from "Pages/Spaces/pages/SelectSpace/components/CreateSpaceWizard/components/SpaceForm/SpaceForm";

export interface SpaceUpdate {
  name: string;
  description: string;
  specialty: SpecialtyOptions[] | undefined;
  imageUrl?: string;
}

type UpdateSpaceSteps = "data" | "image" | "access";

interface EditSpaceDetailsWizardProps {
  onClose: () => void;
  onUpdate: (space: SpaceUpdateDto) => void;
  isSuccess: boolean;
  isLoading: boolean;
}

const EditSpaceDetailsWizard = ({
  onClose,
  onUpdate,
  isSuccess,
  isLoading,
}: EditSpaceDetailsWizardProps) => {
  const { t } = useTranslation();
  const { selectedSpace } = useSpacesContext();
  const { wizardControl } = useWizard<UpdateSpaceSteps>();
  const spaceImage = selectedSpace?.image;
  const defaultImageUrl = !spaceImage?.isCustomImage ? spaceImage?.url : undefined;

  const { imageBank, imageBankLoading } = useImageBank();

  const spaceIsPublic = selectedSpace?.accessType === "public";

  const [isPublic, setIsPublic] = useState<boolean>(spaceIsPublic);
  const [image, setImage] = useState<File | null>();
  const [imageUrl, setImageUrl] = useState<string | undefined>(defaultImageUrl);
  const { validateSpace } = useValidateSpaceName();

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    specialty: yup.array().min(1, "Specialty is required").nullable(),
  });

  const defaultSpecialties = useMemo(() => {
    const selectedSpecialties = selectedSpace?.specialty?.flatMap((spec) => spec.split(","));

    return specialtyOptions.filter((option) => {
      return selectedSpecialties?.some((spec: string) => {
        return spec === option.value;
      });
    });
  }, [selectedSpace]);

  const { control, handleSubmit, getValues, formState, setError } = useForm({
    defaultValues: {
      name: selectedSpace?.name,
      description: selectedSpace?.description,
      specialty: defaultSpecialties,
    },
    resolver: yupResolver(schema),
  });

  const handleEditSpace = useCallback(async () => {
    const values = getValues();
    const spaceSpecialties = values.specialty?.map(({ value }: Option) => value);

    const updateSpaceParams: SpaceUpdateDto = {
      ...values,
      accessType: isPublic ? "public" : "private",
      specialty: spaceSpecialties,
    };

    if (image !== undefined || image === null) {
      updateSpaceParams.file = image;
    }

    if (imageUrl !== undefined) {
      updateSpaceParams.url = imageUrl;
    } else if (imageUrl === null) {
      updateSpaceParams.url = null;
    }

    onUpdate(updateSpaceParams);
  }, [getValues, image, imageUrl, isPublic, onUpdate]);

  const handleBlobChange = useCallback((file: File | null) => {
    setImage(file);
  }, []);

  const handleChangeAccessType = (isPublic: boolean) => {
    setIsPublic(isPublic);
  };

  const handleGalleryChange = useCallback((image: string) => {
    setImageUrl(image);
  }, []);

  const handleValidateSpace = useCallback(async () => {
    const newSpaceName = getValues("name");
    if (selectedSpace?.name !== newSpaceName) {
      const { name: spaceNameIsValid } = await validateSpace({
        spaceName: newSpaceName || "",
        spaceId: selectedSpace?.id,
      });
      if (!spaceNameIsValid) {
        setError("name", { message: t("space_name_exists_error") });
      }
    }
  }, [selectedSpace, validateSpace, getValues, setError, t]);

  const steps: Step<UpdateSpaceSteps>[] = [
    {
      name: "data",
      title: t("space_details_first_step_title"),
      component: (
        <SpaceForm
          control={control}
          defaultSpecialties={defaultSpecialties}
          isNameValid={!formState.errors.name}
        />
      ),
      backLabel: t("general_cancel"),
      canProceed: formState.isSubmitSuccessful,
      isLoading: formState.isSubmitting,
      onSubmit: handleSubmit(handleValidateSpace),
      onPrev: () => onClose(),
    },
    {
      name: "image",
      title: t("space_details_second_step_title"),
      component: (
        <ImagePicker
          galleryImages={imageBank}
          errorDraggedImageText={t("imagePicker_too_large_error")}
          isLoading={imageBankLoading}
          onChangeBlob={handleBlobChange}
          onChangeImageUrl={handleGalleryChange}
          defaultImage={spaceImage}
          cropperAspectRatio={4}
        />
      ),
      backLabel: t("addFile_wizard_back_button"),
    },
    {
      name: "access",
      title: t("space_access_type_title", { spaceName: getValues("name") }),
      subtitle: t("space_access_type_subtitle"),
      component: (
        <AccessTypeForm defaultIsPublic={spaceIsPublic} onChange={handleChangeAccessType} />
      ),
      canProceed: isSuccess,
      isLoading,
      onSubmit: handleSubmit(handleEditSpace),
      nextLabel: t("general_save"),
    },
  ];

  return <Wizard wizardControl={wizardControl} steps={steps} showProgress showSteps />;
};

export default EditSpaceDetailsWizard;
