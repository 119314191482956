import Fuse from "fuse.js";
import { useCallback, useMemo, useState } from "react";
import { fileStore, sectionStore, AppLevelSection, EolasMainSection } from "@eolas-medical/core";

import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { formatDate } from "Utilities/general";

export const useMasterSearch = (showAll?: Boolean) => {
  const { flags } = useLaunchDarkly();
  const [masterSearchValue, setMasterSearchValue] = useState("");
  const checklistsList = sectionStore.checklistTemplatesList;

  const files = Object.values(fileStore.fileMap).filter(
    (file) => file.type !== AppLevelSection.completedChecklist,
  );

  const filteredFiles = useMemo(() => {
    if (showAll && masterSearchValue.length < 2) return files;

    if (masterSearchValue.length < 2) {
      return [];
    }

    const activeSectionIDs = [
      ...sectionStore.getMainSectionList("department").map((childRef) => childRef.id),
      ...sectionStore.getMainSectionList("hospital").map((childRef) => childRef.id),
    ];

    const fuseFilesConfig = flags[LDFlagNames.UPDATED_SEARCH]
      ? {
          ignoreLocation: false,
          minMatchCharLength: 2,
          threshold: 0.4,
          ignoreFieldNorm: true,
          distance: 200,
          keys: ["searchField"],
        }
      : {
          ignoreLocation: true,
          threshold: 0.15,
          distance: 90,
          keys: ["searchField"],
        };

    const fuseFiles = new Fuse(files, {
      ...fuseFilesConfig,
    });

    const foundFiles = fuseFiles
      .search(masterSearchValue)
      .map((res) => res.item)
      .filter(({ mainSectionID, searchField, updatedAt }) => {
        if (!activeSectionIDs.includes(mainSectionID as string)) return false;
        if (!searchField || !updatedAt) return false;

        return true;
      });

    return foundFiles;
  }, [showAll, masterSearchValue, files, flags]);

  const filteredChecklists = useMemo(() => {
    if (showAll && masterSearchValue.length < 2) return checklistsList;
    if (masterSearchValue.length < 2) {
      return [];
    }
    const optionsForChecklists = {
      includeScore: true,
      threshold: 0.15,
      distance: 90,
      keys: ["checklistName"],
    };

    const fuseChecklists = new Fuse(checklistsList, optionsForChecklists);
    const foundChecklists = fuseChecklists.search(masterSearchValue).map((res) => res.item) ?? [];

    return foundChecklists;
  }, [masterSearchValue, showAll, checklistsList]);

  const clearMasterSearch = useCallback(() => {
    setMasterSearchValue("");
  }, [setMasterSearchValue]);

  const onMasterSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      setMasterSearchValue(inputValue);
    },
    [setMasterSearchValue],
  );

  return {
    filteredFiles,
    masterSearchValue,
    clearMasterSearch,
    filteredChecklists,
    onMasterSearchChange,
  };
};

export const useSearchMainSectionFiles = (mainSectionType: EolasMainSection) => {
  const [searchValue, setSearchValue] = useState("");

  const { id } = sectionStore.getChildReferenceByMainSectionType(mainSectionType)!;
  const mainSectionOrder = sectionStore.getChildrenOrder(id).map((child) => child.id);

  const mainSectionFileOrder = mainSectionOrder.flatMap((sectionID) => {
    return sectionStore.getChildrenOrder(sectionID);
  });

  const files = fileStore.getFiles(mainSectionFileOrder);

  const filteredFiles = useMemo(() => {
    if (!searchValue) return [];

    return files.filter(({ searchField, updatedAt }) => {
      if (!searchField || !updatedAt) return false;

      const searchableDate = formatDate(updatedAt, "PPPP dd.MM.yyyy dd/MM/yyyy").toLowerCase();

      return (
        searchableDate.includes(searchValue) || searchField.toLowerCase().includes(searchValue)
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  return {
    searchValue,
    filteredFiles,
    onSearchChange,
  };
};
