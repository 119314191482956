import { Redirect, Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { repoConfig } from "AppTypeConfig";
import { PageContainer } from "Components";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { BaseAppRoute } from "Utilities/types";
import { MeTabRoutes } from "../Pages/MeTab/MeTabRoutes";
import { KnowledgeRoutes } from "Pages/Knowledge";
import { FileViewer } from "Pages/FileViewer";
import SpacesRoutes from "Pages/Spaces/SpacesRoutes";

export const MainRouter = observer(() => {
  const { hasKnowledgeTab } = useEolasNavigation();

  return (
    <>
      <PageContainer version={repoConfig.version} commitId={repoConfig.commitId}>
        <Switch>
          <Route exact path="/" component={SpacesRoutes} />
          <Route path={`/${BaseAppRoute.spaces}`} component={SpacesRoutes} />
          <Route path={`/${BaseAppRoute.organisation}`} component={SpacesRoutes} />

          <Route path={`/${BaseAppRoute.myProfile}`} component={MeTabRoutes} />

          {hasKnowledgeTab && (
            <Route path={`/${BaseAppRoute.knowledge}`} component={KnowledgeRoutes} />
          )}

          <Route exact path="/documents/viewer/:fileId" component={FileViewer} />

          <Redirect to="/" />
        </Switch>
      </PageContainer>
    </>
  );
});
