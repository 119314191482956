import {
  AppLevelSection,
  ChildReference,
  EolasMainSection,
  sectionStore,
  userStore,
} from "@eolas-medical/core";
import { useMainSectionFlags } from "Hooks";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { mapToMainSection } from "modules/mainSections/helpers";
import { MainSection } from "modules/mainSections/types";

interface UseSpaceMainSections {
  activeMainSections: MainSection[];
  deactivatedMainSections: MainSection[];
  inactiveMainSections: MainSection[];
  hasDeactivatedSections: boolean;
}

const useSpaceMainSections = (isAdminView = false): UseSpaceMainSections => {
  const { isMainSectionEnabled } = useMainSectionFlags();
  const { getIconUrl } = useGetIconUrl();
  const { appUserData } = userStore;

  const mapSectionList = (sections: ChildReference[]) => {
    return sections
      .map(mapToMainSection)
      .map((s) => ({ ...s, iconUrl: s.iconName ? getIconUrl(s.iconName) : undefined }));
  };

  const activeMainSections: ChildReference[] = sectionStore
    .getMainSectionList(
      "department",
      (child) =>
        child.disabled === "false" ||
        ((child.disabled as unknown) as boolean) === false ||
        child.disabled === null,
    )
    .filter(({ id, identity }: ChildReference) => {
      const { mainSectionType } = sectionStore.getSection(id);

      return (
        (mainSectionType in AppLevelSection || identity === "genericContentRepository") &&
        isMainSectionEnabled(mainSectionType as EolasMainSection) &&
        (!isAdminView || !appUserData.limitedAccess || appUserData.limitedAccess.includes(id))
      );
    });

  const deactivatedMainSections: ChildReference[] = sectionStore
    .getMainSectionList(
      "department",
      (child) => child.disabled === "true" || ((child.disabled as unknown) as boolean) === true,
    )
    .filter(({ id, identity }: ChildReference) => {
      const { mainSectionType } = sectionStore.getSection(id);
      return (
        (mainSectionType in AppLevelSection || identity === "genericContentRepository") &&
        isMainSectionEnabled(mainSectionType as EolasMainSection)
      );
    });

  const inactiveMainSections = sectionStore
    .getMainSectionList(
      "department",
      (child) => child.disabled === "true" || child.disabled === "false", // Needs to be like this if not filter fails
    )
    .filter(
      (section) =>
        !activeMainSections.includes(section) && !deactivatedMainSections.includes(section),
    );

  const hasDeactivatedSections = deactivatedMainSections.length > 0;

  return {
    activeMainSections: mapSectionList(activeMainSections),
    deactivatedMainSections: mapSectionList(deactivatedMainSections),
    inactiveMainSections: mapSectionList(inactiveMainSections),
    hasDeactivatedSections,
  };
};

export default useSpaceMainSections;
