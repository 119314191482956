import React, { useState, useEffect } from "react";
import {
  ProfileVerificationStatus,
  ProfessionalBody,
  ProfileVerification,
} from "@eolas-medical/core";
import { InnerModalWrapper, ModalBody, ModalHeader, Text, Title } from "UIKit";
import { ProfileVerificationIcon } from "Assets/Icons";
import { useTranslation } from "react-i18next";
import VerificationForm from "./components/VerificationForm";
import EditVerificationModal from "./components/EditVerificationModal";
import { observer } from "mobx-react-lite";
import { ProfessionalBodyLabel } from "Pages/MeTab/types";

export interface ProfileVerificationModalProps {
  onSubmit?: () => void;
  onClose: () => void;
  profileVerification?: ProfileVerification;
}

export interface SelectedVerification {
  type?: string;
  label?: string;
}

export interface ProfileFormElements {
  id: string;
  label: string;
  placeholder: string;
}

type VerificationModalState = "verificationList" | "editVerification" | "verificationForm";

const ProfileVerificationModal = observer(
  ({ onSubmit, onClose, profileVerification }: ProfileVerificationModalProps) => {
    const [modalState, setModalState] = useState<VerificationModalState>("verificationList");
    const [selectedVerification, setSelectedVerification] = useState<SelectedVerification>();
    const { t } = useTranslation();

    const isEditable =
      profileVerification?.status === ProfileVerificationStatus.PENDING ||
      profileVerification?.status === ProfileVerificationStatus.VERIFIED;

    const professionalVerificationList: SelectedVerification[] = [
      {
        type: ProfessionalBodyLabel[ProfessionalBody.GENERAL_MEDICAL_COUNCIL],
        label: t("General Medical Council Number"),
      },
      {
        type: ProfessionalBodyLabel[ProfessionalBody.NURSING_AND_MIDWIFERY_COUNCIL],
        label: t("Nursing and Midwifery Council Pin"),
      },
      {
        type: ProfessionalBodyLabel[ProfessionalBody.GENERAL_PHARMACEUTICAL_COUNCIL],
        label: t("General Pharmaceutical Council Number"),
      },
      {
        label: t("profile_verification_choose_different_body"),
      },
    ];

    const formElements: ProfileFormElements[] = selectedVerification?.type
      ? [
          {
            id: "professionalNumber",
            label: t("profile_verification_registration_number"),
            placeholder: t("profile_verification_add_professional_number"),
          },
        ]
      : [
          {
            id: "professionalBody",
            label: t("profile_verification_professional_body_name"),
            placeholder: t("profile_verification_add_professional_body"),
          },
          {
            id: "professionalNumber",
            label: t("profile_verification_registration_number"),
            placeholder: t("profile_verification_add_professional_number"),
          },
        ];

    useEffect(() => {
      if (isEditable) {
        setModalState("editVerification");
      }
    }, [isEditable]);

    const handleConfirmEdit = () => {
      setSelectedVerification({
        type: profileVerification?.professionalBody,
        label: profileVerification?.professionalBody,
      });
      setModalState("verificationForm");
    };

    const handleCancelEdit = () => {
      setModalState("verificationList");
    };

    if (modalState === "editVerification") {
      return <EditVerificationModal onConfirm={handleConfirmEdit} onClose={handleCancelEdit} />;
    }

    if (modalState === "verificationForm") {
      return (
        <VerificationForm
          selectedVerification={selectedVerification}
          formElements={formElements}
          profileVerification={profileVerification}
          onClose={() => onClose()}
        />
      );
    }

    return (
      <InnerModalWrapper>
        <ModalHeader className="flex flex-col space-y-3">
          <ProfileVerificationIcon className="w-20 h-20" />
          <Title level={5}>{t("profile_verification_title")}</Title>
          <Text level={2} className="text-grey-700 font-bold">
            {t("profile_verification_intro_text")}
          </Text>
        </ModalHeader>

        <ModalBody>
          <div className="overflow-y-auto" style={{ maxHeight: "60vh" }}>
            <div className="flex flex-col justify-center items-center space-y-4">
              {professionalVerificationList.map((verification) => {
                return (
                  <div
                    key={verification.label}
                    className="w-full rounded-2xl border border-grey-400 text-center p-3 cursor-pointer transition-all duration-300 hover:border-grey-600"
                    onClick={() => {
                      setSelectedVerification({
                        type: verification.type,
                        label: verification.label,
                      });
                      setModalState("verificationForm");
                    }}
                  >
                    <Title level={9}>{verification.label}</Title>
                  </div>
                );
              })}
            </div>
          </div>
        </ModalBody>
      </InnerModalWrapper>
    );
  },
);

export default ProfileVerificationModal;
