import { useCallback, useState } from "react";

export type RequestStatus = "initial" | "success" | "pending" | "error";

export interface ApiRequest {
  status: RequestStatus;
  error?: string;
}

export const useRequestStatus = (initialState?: ApiRequest) => {
  const [requestStatus, setRequestStatus] = useState<ApiRequest>(
    initialState ?? { status: "initial", error: "" },
  );

  const onRequestError = useCallback(
    (error: string) => {
      setRequestStatus({
        status: "error",
        error,
      });
    },
    [setRequestStatus],
  );

  const isSuccessful = requestStatus.status === "success";
  const isLoading = requestStatus.status === "pending";
  const error = requestStatus.error;

  return {
    error,
    isLoading,
    isSuccessful,
    requestStatus,
    onRequestError,
    setRequestStatus,
  };
};
