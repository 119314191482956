import { useMutation, useQueryClient } from "@tanstack/react-query";
import webNotificationsKeys from "./webNotifications.queryKeys";
import { replaceObject } from "Utilities/helpers";
import { NotificationStatus, notificationsClient } from "@eolas-medical/core";

const useMarkAsRead = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (notificationId: string) =>
      notificationsClient.updateNotificationStatus({
        notificationId,
        status: NotificationStatus.READ,
      }),
    {
      onSuccess: (updatedNotification) => {
        queryClient.setQueryData(webNotificationsKeys.list(), ({ notifications }: any) => {
          if (notifications) {
            const updatedList = replaceObject(notifications, updatedNotification, "id");
            return {
              notifications: updatedList,
            };
          }
        });
      },
    },
  );

  return {
    markNotificationAsRead: mutate,
    markingNotification: isLoading,
  };
};

export default useMarkAsRead;
