import { Text, Title } from "UIKit/Typography";

interface BulletItemProps {
  number: number;
  title: string;
  description: string;
}

const BulletItem = ({ number, title, description }: BulletItemProps) => {
  return (
    <div className="flex items-start mb-2">
      <div>
        <div className="flex items-center">
          <Text
            level={5}
            className="h-6 w-6 rounded-full border border-grey-500 text-blue-500 flex items-center justify-center mr-2"
          >
            {number}
          </Text>
          <Title level={7}>{title}</Title>
        </div>
        <Text level={2} className="ml-8">
          {description}
        </Text>
      </div>
    </div>
  );
};

export default BulletItem;
