import { Space } from "@eolas-medical/core";
import { createContext } from "react";

export interface SpacesContextValue {
  isAdmin: boolean;
  selectedSpaceId?: string;
  isInSpace: boolean;
  isPublic: boolean;
  selectedSpace?: Space;
  onSelectSpace: (space: Space) => void;
  onSwitchToAdmin: (isAdmin: boolean) => void;
  onClearSpace: () => void;
}

const SpacesContext = createContext<SpacesContextValue>({
  isAdmin: false,
  selectedSpaceId: undefined,
  isInSpace: false,
  isPublic: false,
  selectedSpace: undefined,
  onSelectSpace: (space: Space) => {},
  onSwitchToAdmin: () => {},
  onClearSpace: () => {},
});

export default SpacesContext;
