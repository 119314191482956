import i18n from "i18n";
import axios from "axios";

import { EolasFileType } from "Utilities/types";

export interface SelectFileParams {
  fileKey: string | null;
  fileType: EolasFileType;
}

export const openWindow = (url: string) => {
  const newWin = window.open(url);
  if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
    alert(i18n.t("popip_blocked_message"));
  }
};

// #region File Storage Service/Module
export const uploadFileToS3 = async (
  presignedPostLink: any,
  file: File,
  onUploadProgress?: any,
) => {
  return new Promise((resolve, reject) => {
    const form = new FormData();

    Object.keys(presignedPostLink.fields).forEach((key) => {
      form.append(key, presignedPostLink.fields[key]);
    });

    form.append("file", file);

    const uploadToS3 = () =>
      axios({
        method: "POST",
        url: presignedPostLink.url,
        data: form,
        onUploadProgress,
      });
    uploadToS3()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadFileToPresignedS3 = async (
  presignedUrl: string,
  file: File,
  onUploadProgress?: (progress: number) => void,
  signal?: AbortSignal,
) => {
  const uploadToS3 = () =>
    axios.put(presignedUrl, file, {
      onUploadProgress: ({ loaded, total }) => {
        if (total) {
          const progress = Math.round((loaded / total) * 100);
          onUploadProgress && onUploadProgress(progress);
        }
      },
      headers: {
        "content-type": file.type,
      },
      signal,
    });

  return new Promise((resolve, reject) => uploadToS3().then(resolve).catch(reject));
};

export const downloadFromUrl = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.download = filename;
  link.target = "_blank";
  link.href = url;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};
// #endregion
