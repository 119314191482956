import { GraphQLError } from "graphql";
import { ChildReference } from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";

export interface UseActivateSectionParams {
  updateSectionHandler: (
    id: string,
  ) => Promise<{ data: any; errors: readonly GraphQLError[] | undefined }>;
}

export const useActivateSections = ({ updateSectionHandler }: UseActivateSectionParams) => {
  const { error, isLoading, setRequestStatus, isSuccessful } = useRequestStatus();

  const onActivate = ({ type, ...section }: ChildReference) => async () => {
    setRequestStatus({ status: "pending", error: "" });

    const { errors } = await updateSectionHandler(section.id);

    if (errors && errors[0]) {
      const [{ message = "Unexpected error" }] = errors;
      setRequestStatus({ status: "error", error: message });
    } else {
      setRequestStatus({ status: "success", error: "" });
    }
  };

  return {
    error,
    isLoading,
    onActivate,
    isSuccessful,
  };
};
