import { TextInputConfig, TextInputSizes } from "modules/portfolio/types";
import { FC } from "react";
import { Control } from "react-hook-form";
import { FormElement, Input, Textarea } from "UIKit";

export interface PortfolioInputConfig extends TextInputConfig {
  control: Control;
  title: string;
  fieldName: string;
  warningText?: string;
}
export const PortfolioTextInput: FC<PortfolioInputConfig> = ({
  control,
  fieldName,
  title,
  inputSize,
  warningText,
  required = false,
}) => {
  return inputSize === TextInputSizes.SINGLE ? (
    <FormElement
      label={title}
      control={control}
      id={fieldName}
      required={required}
      warningText={warningText}
    >
      {(field, { className }) => (
        <Input
          {...field}
          value={field.value || ""}
          size="lg"
          className={`${className} rounded-xl`}
          style={{ marginTop: 12 }}
          maxLength={250}
        />
      )}
    </FormElement>
  ) : (
    <FormElement
      label={title}
      control={control}
      id={fieldName}
      required={required}
      warningText={warningText}
    >
      {(field, { className }) => (
        <Textarea
          {...field}
          className={`${className} rounded-lg p-3`}
          rowSpan={inputSize === TextInputSizes.LARGE ? 4 : 3}
          style={{ marginTop: 12, resize: "vertical" }}
        />
      )}
    </FormElement>
  );
};
