import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm, ValidationMode } from "react-hook-form";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface FormConfig<T> {
  mode?: keyof ValidationMode;
  reValidateMode?: "onBlur" | "onChange" | "onSubmit";
  validationSchema: any;
  defaultValues?: any;
}

export const useEolasForm = <T extends FieldValues>({
  validationSchema,
  defaultValues,
  reValidateMode,
  mode,
}: FormConfig<T>) => {
  const methods = useForm<T>({
    mode: mode || "onBlur",
    reValidateMode: reValidateMode || "onChange",
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  return methods;
};
