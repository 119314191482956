import { MP4Icon, PDFIcon, DomainIcon, MSOfficeIcon } from "Assets";
import { DownloadFileButton } from "Components";
import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";

const iconSize = { height: 40, width: 40 };

type LeftIcon = "pdf" | "mp4" | "link" | "ms-office";

const leftIcon: Record<LeftIcon, React.ReactNode> = {
  pdf: <PDFIcon {...iconSize} />,
  mp4: <MP4Icon {...iconSize} />,
  link: <DomainIcon {...iconSize} />,
  "ms-office": <MSOfficeIcon {...iconSize} />,
};

export const WellbeingFileTile: React.FC<FileTileProps> = ({
  canDrag,
  isAdmin,
  eolasFile,
  isDragging,
  onSelectFile = () => {},
  ...handlers
}) => {
  return (
    <FileTileWrapper
      className="h-40"
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <span className="flex justify-center items-center">
        {leftIcon[eolasFile.type as LeftIcon]}
      </span>

      <div className="flex flex-col flex-1 h-full self-start">
        <span className="font-semibold text-md lg:text-lg line-clamp-1 leading-10">
          {eolasFile.name}
        </span>
        <span className="text-grey-dark text-sm lg:text-md line-clamp-3">
          {eolasFile.description}
        </span>
      </div>
      <div className="w-fit self-start flex flex-col items-end justify-between h-full">
        <DownloadFileButton eolasFile={eolasFile} />
      </div>
      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
