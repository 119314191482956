import React, { useState, useEffect } from "react";
import { Parser } from "html-to-react";

export const useParseHtmlToReactNodes = (jsonString: string) => {
  const [reactComponents, setReactComponents] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    const convertHtmlJsonToReactComponents = async () => {
      const htmlString = decodeURIComponent(jsonString);
      // @ts-ignore
      const htmlToReactParser = new Parser();
      const components = htmlToReactParser.parse(htmlString);
      setReactComponents(components);
    };

    convertHtmlJsonToReactComponents();
  }, [jsonString]);

  return reactComponents;
};
