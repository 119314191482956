import { useCallback } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, FormElement, FormError, Input, Textarea } from "UIKit";
import { TeamMember } from "../../../../types";
import AddTeamMemberPhoto from "../AddTeamMemberPhoto/AddTeamMemberPhoto";
import useTeamMemberForm, { TeamMemberFormValues } from "../../hooks/useTeamMemberForm";

interface TeamMemberFormProps {
  teamMember?: TeamMember;
  isLoading: boolean;
  error: string;
  onSubmit: (values: TeamMemberFormValues) => void;
}

const TeamMemberForm = ({ teamMember, isLoading, error, onSubmit }: TeamMemberFormProps) => {
  const { t } = useTranslation();
  const formMethods = useTeamMemberForm(teamMember);

  const handlePickPhoto = useCallback(
    (photo: File | null) => {
      formMethods.setValue("photo", photo);
    },
    [formMethods],
  );

  const handleSubmitForm = useCallback(() => {
    const values = formMethods.getValues();
    onSubmit(values);
  }, [formMethods, onSubmit]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(handleSubmitForm)}
        className="overflow-y-auto p-2"
        style={{ maxHeight: "60vh" }}
        data-testid="team-member-form"
      >
        <div>
          <FormElement
            required
            id="name"
            control={formMethods.control}
            labelClassName="font-semibold"
            label={t("meetTheTeam_modal_staffName")}
          >
            {(field) => (
              <Input
                {...field}
                size="lg"
                placeholder={t("meetTheTeam_modal_staffName_placeholder")}
              />
            )}
          </FormElement>
          <FormElement
            required
            control={formMethods.control}
            id="role"
            labelClassName="font-semibold"
            label={t("meetTheTeam_modal_staffRole")}
          >
            {(field) => (
              <Input
                {...field}
                size="lg"
                placeholder={t("meetTheTeam_modal_staffRole_placeholder")}
              />
            )}
          </FormElement>

          <FormElement
            control={formMethods.control}
            id="bio"
            labelClassName="font-semibold"
            label={t("meetTheTeam_modal_bio")}
          >
            {(field) => (
              <Textarea
                {...field}
                placeholder={t("meetTheTeam_modal_bio_placeholder")}
                characterLimit={320}
                className="resize-none"
              />
            )}
          </FormElement>

          <AddTeamMemberPhoto onPickPhoto={handlePickPhoto} currentPhoto={teamMember?.photoUrl} />
        </div>
        <Button
          type="submit"
          isLoading={isLoading}
          className="self-center w-full sm:w-1/3 mt-6 mx-auto"
          data-testid="team-member-form-submit"
        >
          {t("meetTheTeam_modal_submit")}
        </Button>
        <FormError error={error} />
      </form>
    </FormProvider>
  );
};

export default TeamMemberForm;
