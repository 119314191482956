import { useState } from "react";
import { Modal } from "UIKit";

import { useSessionContext } from "modules/session";

import { AuthenticationWizard, SignInStep, SignUpStep } from "./types";
import LandingPage from "./pages/Landing/LandingPage";
import SoftSignOutModal from "./components/SoftSignOutModal/SoftSignOutModal";
import SignInWizard from "./components/SignInWizard/SignInWizard";
import SignUpWizard from "./components/SignUpWizard/SignUpWizard";
import ForgotPasswordWizard from "./components/ForgotPasswordWizard/ForgotPasswordWizard";
import { timeEvent, trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";

interface SignInWizardState {
  defaultStep: SignInStep;
  email?: string;
}

interface SignUpWizardState {
  defaultStep: SignUpStep;
  email?: string;
}

interface ForgotPasswordWizardState {
  email: string;
}

const Authentication = () => {
  const { sessionState, user, clearSession } = useSessionContext();

  const [openedWizard, setOpenedWizard] = useState<AuthenticationWizard | null>(null);

  const [signInWizardState, setSignInWizardState] = useState<SignInWizardState>({
    defaultStep: "validate-email",
  });

  const [signUpWizardState, setSignUpWizardState] = useState<SignUpWizardState>({
    defaultStep: "validate-email",
  });

  const [
    forgotPasswordWizardState,
    setForgotPasswordWizardState,
  ] = useState<ForgotPasswordWizardState>({
    email: "",
  });

  const handleOpenSignInWizard = () => {
    trackEvent(AnalyticsEvents.SIGN_IN_BTN_LOGIN_FLOW);
    setOpenedWizard("sign-in-wizard");
  };

  const handleOpenSignUpWizard = () => {
    trackEvent(AnalyticsEvents.CREATE_NEW_ACCOUNT_LOGIN_FLOW);
    timeEvent(AnalyticsEvents.START_CREATE_ACCOUNT_NEW_ACCOUNT);
    setOpenedWizard("sign-up-wizard");
  };

  const handleCloseWizard = () => {
    setOpenedWizard(null);
    setSignInWizardState({
      defaultStep: "validate-email",
    });
    setSignUpWizardState({
      defaultStep: "validate-email",
    });
  };

  const handleChangeWizard = (newWizard: AuthenticationWizard, email: string) => {
    if (newWizard === "sign-in-wizard") {
      trackEvent(AnalyticsEvents.FORGOT_PASSWORD_LOGIN_FLOW);
      setSignInWizardState({
        defaultStep: "validate-password",
        email,
      });
    }

    if (newWizard === "sign-up-wizard") {
      setSignUpWizardState({
        defaultStep: "create-account",
        email,
      });
    }

    if (newWizard === "forgot-password-wizard") {
      setForgotPasswordWizardState({ email });
    }

    setOpenedWizard(newWizard);
  };

  return (
    <>
      <LandingPage onClickSignIn={handleOpenSignInWizard} onClickSignUp={handleOpenSignUpWizard} />

      <Modal open={!!openedWizard} onClose={handleCloseWizard}>
        {openedWizard === "sign-in-wizard" && (
          <SignInWizard
            onChangeWizard={handleChangeWizard}
            defaultStep={signInWizardState.defaultStep}
            email={signInWizardState.email}
          />
        )}
        {openedWizard === "sign-up-wizard" && (
          <SignUpWizard
            onChangeWizard={handleChangeWizard}
            defaultStep={signUpWizardState.defaultStep}
            email={signUpWizardState.email}
          />
        )}
        {openedWizard === "forgot-password-wizard" && (
          <ForgotPasswordWizard email={forgotPasswordWizardState.email} />
        )}
      </Modal>

      <Modal open={sessionState === "soft-sign-out" && !!user.email} onClose={clearSession}>
        <SoftSignOutModal email={user.email!} />
      </Modal>
    </>
  );
};

export default Authentication;
