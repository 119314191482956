import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Input, FormElement, Title, Textarea, Toggle } from "UIKit";

export const SubmissionIssues = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <div className="flex flex-col p-4 sm:p-6 space-y-1 sm:space-y-2 bg-white shadow-sm rounded-md">
      <Title level={7}>{t("checklistSubmission_issues_title")}</Title>

      <FormElement id="checklistIssues" control={control}>
        {({ value, onChange }) => {
          return (
            <Toggle
              checked={value}
              onChange={onChange}
              id="checklistIssues"
              label={t("checklistSubmission_flag_issue_label")}
            />
          );
        }}
      </FormElement>

      <FormElement
        id="additionalInfo"
        label={t("checklistSubmission_additional_info_label")}
        control={control}
      >
        {(field, { className }) => (
          <Textarea
            {...field}
            className={`${className} bg-grey-50 hover:bg-white`}
            placeholder={t("checklistSubmission_additional_info_placeholder")}
          />
        )}
      </FormElement>

      <FormElement
        control={control}
        id="secondTeamMember"
        label={t("checklistSubmission_second_member_label")}
      >
        {(field, { className }) => (
          <Input
            {...field}
            size="lg"
            className={`${className} bg-grey-50 hover:bg-white`}
            placeholder={t("checklistSubmission_second_member_placeholder")}
          />
        )}
      </FormElement>
    </div>
  );
};
