import { PadlockIcon } from "Assets";
import { FormElement, Input, Text } from "UIKit";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ShowPasswordIcon from "../ShowPasswordIcon/ShowPasswordIcon";
import { Control } from "react-hook-form";
interface ValidatePasswordModalProps {
  control: Control<{ password: string }>;
  error?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onClickForgotPassword: () => void;
}

const ValidatePasswordModal = ({
  control,
  error,
  onSubmit,
  onClickForgotPassword,
}: ValidatePasswordModalProps) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div data-testid="validate-password-modal">
      <form onSubmit={onSubmit}>
        <FormElement id="password" control={control} errorMessage={error}>
          {(field) => (
            <Input
              {...field}
              autoFocus
              iconLeft={<PadlockIcon />}
              placeholder={t("login_enterPassword")}
              type={showPassword ? "text" : "password"}
              data-testid="validate-password"
              iconRight={
                <ShowPasswordIcon
                  showPassword={showPassword}
                  onClick={() => setShowPassword((prev) => !prev)}
                />
              }
            />
          )}
        </FormElement>

        <div className="flex items-center justify-center cursor-pointer mt-6">
          <Text level={1} className="text-blue-500" onClick={onClickForgotPassword}>
            {t("login_forgotPassword")}
          </Text>
        </div>
      </form>
    </div>
  );
};

export default ValidatePasswordModal;
