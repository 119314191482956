import React from "react";
import { useRouteMatch } from "react-router-dom";

import { BaseAppRoute } from "Utilities/types";
import { useCustomTheme } from "../Navigation/hooks/useCustomTheme";

export const GradientBackdrop: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  const backgroundImage = useGradientBackdrop();

  return (
    <div
      style={{
        backgroundImage,
        backgroundColor: "#FAFAFA",
      }}
      className={className ? className : "h-100vh flex flex-1"}
    >
      {children}
    </div>
  );
};

const useGradientBackdrop = () => {
  const { customBrandColor } = useCustomTheme();
  const isOnMyProfile = !!useRouteMatch({ exact: true, path: `/${BaseAppRoute.myProfile}` });

  const isOnSelectSpace = !!useRouteMatch({ exact: true, path: `/${BaseAppRoute.selectSpace}` });
  const isOnSpace = !!useRouteMatch({
    exact: true,
    path: `/${BaseAppRoute.spaces}/:spaceName`,
  });
  const isOnOrganisation = !!useRouteMatch({
    exact: true,
    path: `/${BaseAppRoute.organisation}/:organisationName`,
  });
  const isOnKnowledge = !!useRouteMatch({ exact: true, path: `/${BaseAppRoute.knowledge}` });

  const backgroundImageGradient = React.useMemo(() => {
    if (customBrandColor) {
      return `linear-gradient(180deg, ${customBrandColor} -15%, rgba(236, 196, 172, 0) 35%, #FAFAFA 100%)`;
    }

    if (isOnSelectSpace) {
      return "";
    }
    if (isOnOrganisation) {
      return `linear-gradient(180deg, #CE6931 -15%, rgba(236, 196, 172, 0) 35%, #FAFAFA 100%)`;
    }
    if (isOnSpace) {
      return `linear-gradient(180deg, #1564FE -15%, rgba(145, 189, 255, 0) 35%, #FAFAFA 100%)`;
    }
    if (isOnMyProfile) {
      return `linear-gradient(180deg, #EAC715 -15%, rgba(145, 189, 255, 0) 35%, #FAFAFA 100%)`;
    }
    if (isOnKnowledge) {
      return `linear-gradient(180deg, #5CB227 -15%, rgba(145, 189, 255, 0) 35%, #FAFAFA 100%)`;
    }

    return "";
  }, [
    isOnOrganisation,
    isOnSelectSpace,
    isOnSpace,
    isOnMyProfile,
    isOnKnowledge,
    customBrandColor,
  ]);

  return backgroundImageGradient;
};
