import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sendReminderAssessor } from "../client/portfolio.client";
import portfolioKeys from "./portfolio.queryKeys";

const useSendReminderAssessor = (eventId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(sendReminderAssessor, {
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: portfolioKeys.assignedAssessors(eventId) });
    },
  });

  return {
    sendReminderAssessor: mutate,
    sendingReminderAssessor: isLoading,
    ...rest,
  };
};

export default useSendReminderAssessor;
