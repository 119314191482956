import { useRouteMatch } from "react-router-dom";
import { NavButton, PageTitle, Text } from "UIKit";

interface SupervisionPageTitleProps {
  title: string;
  subtitle: string;
  toReplaceUrl: string;
}
const SupervisionPageTitle = ({ title, subtitle, toReplaceUrl }: SupervisionPageTitleProps) => {
  const { url } = useRouteMatch();

  return (
    <div className="flex flex-col items-center justify-center">
      <PageTitle title={title} />
      <Text level={2} className="text-grey-700 mb-4">
        {subtitle}
      </Text>
      <NavButton to={url.replace(`/${toReplaceUrl}`, "")} />
    </div>
  );
};

export default SupervisionPageTitle;
