import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import { Button } from "../../Button";

import { Modal } from "../Modal";
import { Title, Text } from "../../Typography";
import { ModalHeader, InnerModalWrapper, ModalBody } from "../components";

interface ExitChecklistProps {
  title: string;
  description: string;
  cancelText: string;
  confirmText: string;
  when: boolean;
  onCancel?: () => boolean;
  onConfirm?: () => boolean;
}

export const Prompt: React.FC<ExitChecklistProps> = ({
  when,
  title,
  description,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
}) => {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [onCancel, setShowPrompt, currentPath, history]);

  const handleConfirm = useCallback(async () => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [history, currentPath, onConfirm]);

  return (
    <Modal open={showPrompt} onClose={handleCancel}>
      <InnerModalWrapper>
        <ModalHeader>
          <Title level={5}>{title}</Title>
        </ModalHeader>

        <ModalBody className="space-y-4 sm:space-y-8">
          <Text level={2} className="text-center">
            {description}
          </Text>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Button color="grey" onClick={handleCancel}>
              {cancelText}
            </Button>
            <Button color="red" variant="outline" onClick={handleConfirm}>
              {confirmText}
            </Button>
          </div>
        </ModalBody>
      </InnerModalWrapper>
    </Modal>
  );
};
