import WebViewer from "@pdftron/webviewer";
import { initializeVideoViewer } from "@pdftron/webviewer-video";
import { useTranslation } from "react-i18next";
import { AnalyticsEvents } from "@eolas-medical/core";
import { useEffect, useState, useRef, useCallback } from "react";

import { trackEvent } from "API/Analytics";
import { errorStore } from "Stores/ErrorStore";
import { useNotifications, Notification } from "Components/Notifications";
import { Blob } from "modules/myFiles/types";

interface BasicFile {
  id: string;
  name: string;
  blob?: Blob;
}
export const useEolasFileViewer = <T extends BasicFile>() => {
  const { t } = useTranslation();
  const notificationRef = useRef<Notification>();
  const viewer = useRef<HTMLDivElement>(null);
  const [file, setFile] = useState<T | null>(null);

  const { showNotification, hideNotification, updateNotification } = useNotifications();

  const onFileError = useCallback(
    (e: any) => {
      updateNotification({
        type: "error",
        id: notificationRef.current!.id,
        description: t("fileViewer_error"),
      });
      errorStore.captureError({ error: e.message, source: "user" });
    },
    [t, updateNotification],
  );

  const onFileLoaded = useCallback(() => {
    hideNotification(notificationRef.current!.id);
  }, [hideNotification]);

  const onViewerLoadError = useCallback(() => {
    updateNotification({
      type: "error",
      id: notificationRef.current!.id,
      description: t("fileViewer_load_error"),
    });
  }, [t, updateNotification]);

  useEffect(() => {
    notificationRef.current = showNotification({
      type: "loading",
      description: t("fileViewer_opening_pdf"),
    });
    trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_STARTED);
  }, [t, showNotification]);

  const videoViewer = useCallback(
    () =>
      viewer.current && file && file.blob?.getURL
        ? WebViewer(
            {
              path: "/pdftron",
              initialDoc: file.blob.getURL,
              disabledElements: ["ribbons", "toolsHeader", "toggleNotesButton", "selectToolButton"],
            },
            viewer.current,
          )
            .then(async (instance) => {
              const license = process.env.REACT_APP_PDF_TRON;
              instance.UI.addEventListener(instance.UI.Events.DOCUMENT_LOADED, onFileLoaded);
              instance.UI.addEventListener(instance.UI.Events.LOAD_ERROR, onViewerLoadError);
              instance.Core.disableEmbeddedJavaScript();
              // Extends WebViewer to allow loading HTML5 videos (.mp4, ogg, webm).
              const { loadVideo } = await initializeVideoViewer(instance, { license });
              if (file.blob?.getURL && file.blob.type === "mp4") loadVideo(file.blob.getURL);
            })
            .catch(onFileError)
        : null,
    [file, onFileError, onFileLoaded, onViewerLoadError],
  );

  useEffect(() => {
    if (!file) return;

    videoViewer();
    return () => {
      hideNotification(notificationRef.current!.id);
    };
  }, [file, onFileError, onFileLoaded, onViewerLoadError, hideNotification, videoViewer]);

  return { viewer, file, setFile, showNotification, onFileLoaded, onFileError };
};
