import { Text } from "UIKit";
import { useTranslation } from "react-i18next";

const EmailNotVerifiedModal = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="email-not-verified-modal" className="text-center px-4 mb-8 -mt-4">
      <Text level={2}>{t("user_complete_sign_up")}</Text>
    </div>
  );
};

export default EmailNotVerifiedModal;
