import React from "react";
import { twMerge } from "tailwind-merge";

interface TagProps {
  label: string;
  className?: string;
}

const Tag = ({ label, className = "" }: TagProps) => {
  return (
    <div
      className={twMerge(
        "hidden sm:block bg-quartz-50 px-3 py-1 text-sm rounded-sm font-bold leading-5 text-quartz-600",
        className,
      )}
    >
      {label}
    </div>
  );
};

export default Tag;
