import { FormLabel } from "../FormLabel";
import { InputTypeButton } from "../../InputTypeButton";

export interface SelectCardProps<T> {
  selected: T;
  label?: string;
  className?: string;
  onChange: (value: T) => void;
  options: { value: T; label: string; icon?: JSX.Element }[];
}

export function SelectCard<T extends string>({
  label,
  options,
  selected,
  className = "",
  onChange,
}: SelectCardProps<T>) {
  return (
    <div className={className}>
      {label && (
        <FormLabel required htmlFor="sex">
          {label}
        </FormLabel>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {options.map((option) => {
          return (
            <InputTypeButton
              key={option.value}
              isSelected={selected === option.value}
              icon={option.icon}
              onClick={() => {
                onChange(option.value);
              }}
            >
              {option.label}
            </InputTypeButton>
          );
        })}
      </div>
    </div>
  );
}
