import { UserIcon } from "Assets";
import React from "react";
import { Loader, Text } from "UIKit";

interface AvatarProps {
  label?: string;
  isLoading?: boolean;
}

function Avatar({ label, isLoading }: AvatarProps) {
  if (isLoading) {
    return <Loader size={24} className="bg-transparent" />;
  }

  return (
    <div data-testid="avatar" className={`flex items-center ${label ? "space-x-4" : ""}`}>
      <UserIcon className={`h-8 w-8`} />
      {label ? <Text level={1}>{label}</Text> : null}
    </div>
  );
}

export default Avatar;
