import { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useLaunchDarkly } from "Contexts";
import { BaseAppRoute, LDFlagNames } from "Utilities/types";
import { useEolasNavigation } from "Components/Navigation/hooks";

import useSpacesContext from "modules/spaces/context/useSpacesContext";

import { SelectSpace } from "./pages/SelectSpace/SelectSpace";
import { SpaceRoutes } from "./pages/Space/SpaceRoutes";
import { SpaceAdminRoutes } from "./pages/Space/SpaceAdminRoutes";
import { OrganisationAdminRoutes } from "./pages/Space/OrganisationAdminRoutes";
import { OrganisationRoutes } from "./pages/Space/OrganisationRoutes";
import { getOrganisationRoute, getSpaceRoute } from "./helpers";
import useShouldCreateSpace from "./pages/SelectSpace/hooks/useShouldCreateSpace";

const SpacesRoutes = () => {
  const { hasOrganisationTab, hasSpacesTab } = useEolasNavigation();
  const { isAdmin, isInSpace, selectedSpace } = useSpacesContext();
  const { flags } = useLaunchDarkly();
  const { shouldCreateSpace } = useShouldCreateSpace();
  const showAdminRoutes = flags[LDFlagNames.READ_ONLY_ACTIVATED] ? false : isAdmin;

  const redirectRoute = useMemo(() => {
    return hasSpacesTab ? getSpaceRoute(selectedSpace) : getOrganisationRoute(selectedSpace);
  }, [hasSpacesTab, selectedSpace]);

  if (isInSpace) {
    if (!selectedSpace) return null;

    if (showAdminRoutes) {
      return (
        <Switch>
          {hasOrganisationTab && (
            <Route
              path={`/${BaseAppRoute.organisation}/:organisationName`}
              component={OrganisationAdminRoutes}
            />
          )}
          {hasSpacesTab && (
            <Route path={`/${BaseAppRoute.spaces}/:spaceName`} component={SpaceAdminRoutes} />
          )}
          <Redirect to={redirectRoute} />;
        </Switch>
      );
    }

    return (
      <Switch>
        {hasOrganisationTab && (
          <Route
            path={`/${BaseAppRoute.organisation}/:organisationName`}
            component={OrganisationRoutes}
          />
        )}
        {hasSpacesTab && (
          <Route path={`/${BaseAppRoute.spaces}/:spaceName`} component={SpaceRoutes} />
        )}
        <Redirect to={redirectRoute} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={`/${BaseAppRoute.selectSpace}`} component={SelectSpace} />
      <Redirect to={{ pathname: redirectRoute, state: { shouldCreateSpace } }} />
    </Switch>
  );
};

export default SpacesRoutes;
