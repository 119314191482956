import React from "react";
import { throttle } from "lodash";

export const useScrollToTop = () => {
  const [scrollToTop, setScrollToTop] = React.useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll = React.useCallback(
    throttle(() => {
      setScrollToTop(window.scrollY > 300);
    }, 200),
    [],
  );

  const _onScrollToTop = React.useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  React.useEffect(() => {
    document.addEventListener("scroll", onScroll, true);

    return () => {
      document.removeEventListener("scroll", onScroll, true);
    };
  }, [onScroll]);

  return {
    scrollToTop,
    goToTop: _onScrollToTop,
  };
};
