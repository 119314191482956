import { ChildReference, contentClient, fetchAllData, sectionStore } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { ContentRepositoryId } from "../types";
import { DropResult } from "react-beautiful-dnd";
import { reorderItems } from "UIKit";

const useReorderContent = (contentRepositoryId: ContentRepositoryId) => {
  const reorderContent = async (dropResult: DropResult) => {
    const childReferences = sectionStore.getChildrenOrder(contentRepositoryId);
    if (!dropResult.destination) return;
    if (dropResult.source.index === dropResult.destination.index) return;

    const newOrder: ChildReference[] = reorderItems(
      childReferences,
      dropResult.source.index,
      dropResult.destination?.index!,
    );

    sectionStore.setChildrenOrder(contentRepositoryId, newOrder);
    return contentClient.reorder({
      input: {
        id: contentRepositoryId,
        listOrder: newOrder.map((item) => item.id),
      },
    });
  };

  const { mutate } = useMutation(reorderContent, {
    onSuccess: async () => {
      await fetchAllData();
    },
    onMutate: (dropResult: DropResult) => {
      const childReferences = sectionStore.getChildrenOrder(contentRepositoryId);
      const oldOrder = [...childReferences];

      return { oldOrder, dropResult };
    },
    onError: (_, variables, context) => {
      sectionStore.setChildrenOrder(contentRepositoryId, context?.oldOrder as ChildReference[]);
    },
  });

  return {
    reorderContent: mutate,
  };
};

export default useReorderContent;
