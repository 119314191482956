import { useHistory } from "react-router-dom";

import { UserInviteIcon } from "Assets";
import { Button, ModalBody, Title, Text, InnerModalWrapper } from "UIKit";

export const InvitationAccepted = () => {
  const { push } = useHistory();

  return (
    <InnerModalWrapper>
      <ModalBody className="items-center">
        <UserInviteIcon className="h-20 w-20 mb-8" />
        <Title level={5}>Invitation Accepted</Title>
        <Text level={1} className="text-grey-700">
          This invitation was already accepted.
        </Text>

        <Button onClick={() => push("/")} className="w-full sm:w-1/2 mt-8">
          To Login
        </Button>
      </ModalBody>
    </InnerModalWrapper>
  );
};
