import { snakeCase } from "lodash";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AnalyticsEvents,
  CommunityLevelSection,
  EolasFile,
  NationalResourceEntityType,
  NationalResourcesClickPayload,
} from "@eolas-medical/core";

import { removeLastUrlPath } from "Utilities";
import { useMainSectionVisit, useSelectFile } from "Hooks";
import { InnerPageWrapper, NavButton, PageTitle } from "UIKit";

import { useHistory } from "react-router";
import useNationalBodies from "modules/nationalResources/data/useNationalBodies";
import { CommunitySectionType, EOLAS_CALCULATOR } from "./types";
import { EolasList } from "Components/EolasFileList/EolasList";
import CommunityListItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import useSearchNationalResources from "modules/nationalResources/data/useSearchNationalResources";
import { trackEvent } from "API/Analytics";
import { BaseAppRoute } from "Utilities/types";

const ClinicalCalculators = observer(({ match: { url } }: any) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const [showSearchResult, setShowSearchResult] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const { onSelectFile } = useSelectFile();

  useMainSectionVisit({
    mainSectionId: CommunityLevelSection.clinicalCalculators,
  });

  const { nationalBodies, gettingNationalBodies } = useNationalBodies(
    NationalResourceEntityType.CALCULATOR,
  );

  const { searchNationalResources, searchingNationalResources } = useSearchNationalResources();

  const clinicalCalculators = useMemo(() => {
    const updatedNationalBodies = [
      ...(nationalBodies ?? []),
      {
        name: EOLAS_CALCULATOR,
      },
    ];

    return updatedNationalBodies;
  }, [nationalBodies]);

  const handleClickBody = useCallback(
    ({ name }) => {
      const snakeCaseBody = snakeCase(name);
      history.push({
        pathname: `/${BaseAppRoute.knowledge}/clinicalCalculators/${snakeCaseBody}`,
        state: { body: name },
      });
    },
    [history],
  );

  const handleClearSearch = useCallback(() => {
    setSearchInput("");
    setShowSearchResult(false);
  }, []);

  const handleClickCalculator = (calculator: EolasFile) => {
    trackEvent(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
      nationalResourceType: NationalResourceEntityType.CALCULATOR,
      resourceName: calculator.name,
    } as NationalResourcesClickPayload);
    onSelectFile(calculator);
  };

  const handleSearchCalculators = useCallback(() => {
    setShowSearchResult(true);
    searchNationalResources(
      {
        entityType: NationalResourceEntityType.CALCULATOR,
        query: searchInput,
        mode: "searchbyInput",
      },
      {
        onSuccess: (response) => {
          setSearchResults(response);
        },
      },
    );
  }, [searchInput, searchNationalResources]);

  useEffect(() => {
    if (searchInput === "") {
      setShowSearchResult(false);
    }
  }, [searchInput]);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={t("clinicalCalculators_title")} />
        <NavButton to={removeLastUrlPath(url)} />
        <EolasList<EolasFile, "click">
          value={searchInput}
          placeholderSearchText={t("findClinicalCalculator_searchPlaceholder")}
          items={showSearchResult ? searchResults : clinicalCalculators}
          isSearchable
          isLoading={searchingNationalResources || gettingNationalBodies}
          searchType="click"
          onSearchInputChange={setSearchInput}
          onClearSearch={handleClearSearch}
          onClickSearch={handleSearchCalculators}
          renderItem={(eolasFile) => (
            <CommunityListItem
              eolasFile={eolasFile}
              onSelectFile={eolasFile.description ? handleClickCalculator : handleClickBody}
              sectionType={
                eolasFile.description
                  ? CommunitySectionType.NATIONAL_RESOURCE
                  : CommunitySectionType.BODY
              }
            />
          )}
        />
      </InnerPageWrapper>
    </>
  );
});

export default ClinicalCalculators;
