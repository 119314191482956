import { useFileOperations } from "Hooks";
import { EolasFile } from "Utilities/types";
import { useRequestStatus } from "Utilities";
import { fetchAllData } from "@eolas-medical/core";
import { errorStore } from "Stores/ErrorStore";

export const useDeleteDepartmentArea = (department?: EolasFile) => {
  const { removeFile } = useFileOperations();

  const {
    error: deleteError,
    isLoading: isDeleteLoading,
    isSuccessful: isDeleteSuccessful,
    setRequestStatus: setDeleteStatus,
  } = useRequestStatus();

  const onDelete = async () => {
    setDeleteStatus({ status: "pending", error: "" });
    try {
      await removeFile({
        variables: {
          id: department?.id,
          parentID: department?.parentID,
        },
      });
      await fetchAllData();
      setDeleteStatus({ status: "success", error: "" });
    } catch (err: any) {
      const errorMessage = errorStore.captureError({
        error: err,
        source: "user",
        retryCallback: removeFile,
        retryParameters: {
          variables: {
            id: department?.id,
            parentID: department?.parentID,
          },
        },
      });
      setDeleteStatus({ status: "error", error: errorMessage });
    }
  };

  return {
    onDelete,
    deleteError,
    isDeleteLoading,
    isDeleteSuccessful,
  };
};
