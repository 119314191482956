import { Text, Title, Loader, ModalBody } from "UIKit";

export const SameUserStep = () => {
  return (
    <ModalBody className="flex-col items-center">
      <Title level={5}>Accepting invitation</Title>
      <Text level={1} className="my-4">
        Please wait while give you access to the organisation...
      </Text>
      <Loader size={40} />
    </ModalBody>
  );
};
