import { FormElement, AttachmentItem, EventStatus, FormElementType } from "modules/portfolio/types";
import { Control } from "react-hook-form";
import PortfolioFormElement from "Pages/MeTab/pages/Portfolio/components/PortfolioFormElement/PortfolioFormElement";
import PortfolioReadOnlyElement from "Pages/MeTab/pages/Portfolio/components/PortfolioReadOnlyElement/PortfolioReadOnlyElement";
import Panel from "UIKit/Panel/Panel";
import { Text } from "UIKit";
import { InformationIcon } from "Assets";

interface EventFormBodyProps {
  formElements: FormElement[];
  control: Control<any>;
  status: EventStatus;
  areAttachmentsShared: boolean;
  info: string | null;
  onDownloadAttachment: (attachment: AttachmentItem) => void;
  onClickAttachment: (attachment: AttachmentItem) => void;
}
const EventFormBody = ({
  formElements,
  control,
  status,
  areAttachmentsShared,
  info,
  onDownloadAttachment,
  onClickAttachment,
}: EventFormBodyProps) => {
  const extraActions = {
    showAttachmentsMessage: areAttachmentsShared,
    onDownloadAttachment,
    onClickAttachment,
  };

  if (status === EventStatus.IN_PROGRESS) {
    return (
      <>
        {info ? (
          <div className="flex flex-row mb-6">
            <InformationIcon width={24} height={24} className="mr-2.5" />
            <Text level={1}>{info}</Text>
          </div>
        ) : null}
        <div className="mb-6">
          {formElements.map((el) => (
            <div key={el.fieldName}>
              <PortfolioFormElement item={el} control={control} {...extraActions} />
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="mb-6">
      <Panel testId="submitted-event-body">
        {formElements
          .filter((el) => (el.type === FormElementType.ATTACHMENTS ? el.value : true))
          .map((el) => {
            return (
              <div key={el.fieldName} className="mb-4 last:mb-0">
                <PortfolioReadOnlyElement element={el} {...extraActions} />
              </div>
            );
          })}
      </Panel>
    </div>
  );
};

export default EventFormBody;
