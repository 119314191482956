import { ComponentConfigOption } from "modules/portfolio/types";
import { Control } from "react-hook-form";
import { FormElement, ToggleButton } from "UIKit";
import usePortfolioTags from "./hooks/usePortfolioTags";
import { useMediaQuery } from "Hooks";

interface PortfolioTagsProps {
  title: string;
  control: Control;
  fieldName: string;
  required: boolean;
  options: ComponentConfigOption[];
  inputSize?: string;
}
export const PortfolioTags = ({
  title,
  control,
  fieldName,
  required,
  options,
  inputSize,
}: PortfolioTagsProps) => {
  const { getNewValue, getTagColor } = usePortfolioTags();
  const media = useMediaQuery();

  const containerStyle = inputSize === "large" ? "flex-col gap-2 items-center" : "flex-wrap gap-6";
  const buttonStyle = inputSize === "large" ? "w-64 sm:w-128" : "w-24 sm:w-32";

  return (
    <FormElement label={title} control={control} id={fieldName} required={required}>
      {(fieldProps) => {
        const { onChange, value } = fieldProps;
        return (
          <div className={`flex ${containerStyle}`}>
            {options.map(({ value: tagValue, type: tagType }) => (
              <ToggleButton
                key={tagValue}
                color={getTagColor(tagType)}
                isSelected={value === tagValue}
                text={tagValue}
                onClick={() => onChange(getNewValue(tagValue, value))}
                textLevel={media === "xs" ? 2 : 1}
                unselectedBackground="bg-white"
                buttonClassName={buttonStyle}
              />
            ))}
          </div>
        );
      }}
    </FormElement>
  );
};
