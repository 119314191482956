import { useCallback } from "react";
import { AnalyticsEvents, sectionStore, userStore } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { getRouteFromNotification, hasToBeAdmin, hasToSingOutFromSpace } from "../helpers";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { WebNotification } from "modules/webNotifications/types";

interface UseClickNotification {
  onClickNotification: (notification: WebNotification) => void;
}

const useClickNotification = (): UseClickNotification => {
  const { onClearSpace, onSwitchToAdmin, selectedSpace } = useSpacesContext();

  const onClickNotification = useCallback(
    async (notification: WebNotification) => {
      trackEvent(AnalyticsEvents.NOTIFICATION_CLICKED, {
        notificationType: notification.notificationType,
      });

      sectionStore.setIsAdmin(hasToBeAdmin(notification.notificationType));
      onSwitchToAdmin(hasToBeAdmin(notification.notificationType));

      if (hasToSingOutFromSpace(notification.notificationType)) {
        await userStore.signOutOrganisation();
        onClearSpace();
      }
      const route = getRouteFromNotification(notification, selectedSpace);
      window.location.href = `${window.origin}/${route}`;
    },
    [onClearSpace, onSwitchToAdmin, selectedSpace],
  );

  return {
    onClickNotification,
  };
};

export default useClickNotification;
