import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { submitFeedback } from "../client/portfolio.client";
import portfolioKeys from "./portfolio.queryKeys";
import { mapFeedbackForm } from "../helpers";

const useSubmitFeedback = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(submitFeedback, {
    onSuccess: (updatedFeedback) => {
      queryClient.invalidateQueries({
        queryKey: portfolioKeys.feedbackList(),
      });

      queryClient.setQueryData(portfolioKeys.feedbackDetail(updatedFeedback.eventId), () =>
        mapFeedbackForm(updatedFeedback),
      );
    },
  });
  return {
    submitFeedback: mutate,
    submittingFeedback: isLoading,
  };
};

export default useSubmitFeedback;
