import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";

interface MedusaFileTileProps extends FileTileProps {
  isMedusaFile?: boolean;
}

export const MedusaFileTile: React.FC<MedusaFileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  isDragging,
  isMedusaFile,
  onSelectFile = () => {},
  ...handlers
}) => {
  const { description, name } = eolasFile;

  return (
    <FileTileWrapper
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      isMedusaFile={isMedusaFile}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-1">
        <span className="text-lg font-semibold mb-1">{name}</span>
        {description && <span className="text-sm text-grey-darker capitalize">{description}</span>}
      </div>

      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
