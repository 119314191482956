import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { EolasFileList } from "Components";
import { useMainSectionVisit } from "Hooks";
import { AddButton, InnerPageWrapper, NavButton, PageTitle } from "UIKit";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const AdminHospitalsList: React.FC<RouteComponentProps> = observer(
  ({ history: { push }, match: { url } }) => {
    const { t } = useTranslation();
    useMainSectionVisit({ mainSectionId: AppLevelSection.hospitalList });
    const { spaceSection } = useSpaceSection(AppLevelSection.hospitalList);

    const onHospitalClick = useCallback(
      (hospital: EolasFile) => {
        push(`${url}/edit/${encodeURIComponent(hospital.id)}`);
      },
      [push, url],
    );

    return (
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t("hospitalsAdmin_hospital_page_title")} />

        <NavButton to={url.replace(`/${AppLevelSection.hospitalList}`, "")} />

        <AddButton onClick={() => push(`${url}/add`)}>
          {t("hospitalsAdmin_add_hospital_button")}
        </AddButton>

        <EolasFileList
          isAdmin
          draggable={true}
          onSelectFile={onHospitalClick}
          mainSectionId={AppLevelSection.hospitalList}
        />
      </InnerPageWrapper>
    );
  },
);
