import { useTranslation } from "react-i18next";
import { AttachmentItem, FormElement } from "modules/portfolio/types";
import { Text, Title } from "UIKit";
import PortfolioAttachmentList from "Pages/MeTab/pages/Portfolio/components/PortfolioAttachments/PortfolioAttachmentList";

interface PortfolioReadOnlyAttachmentsProps {
  element: FormElement;
  showAttachmentsMessage?: boolean;
  onDownloadAttachment?: (attachment: AttachmentItem) => void;
  onClickAttachment?: (attachment: AttachmentItem) => void;
}

const PortfolioReadOnlyAttachments = ({
  element,
  showAttachmentsMessage,
  onDownloadAttachment,
  onClickAttachment,
}: PortfolioReadOnlyAttachmentsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        data-testid="portfolio-read-only-attachments"
        className="flex items-center justify-between mt-8"
      >
        <Title level={7} className="text-base sm:text-lg">
          {element.componentConfig.title}
        </Title>
        {showAttachmentsMessage && (
          <Text
            level={1}
            className="text-base sm:text-lg text-grey-600 ml-auto text-right sm:text-left"
          >
            {t("portfolio_attachments_shared")}
          </Text>
        )}
      </div>
      <div className="flex flex-col items-center divide-y divide-grey-300">
        <PortfolioAttachmentList
          attachments={element.value ? (element.value as AttachmentItem[]) : []}
          onDownload={onDownloadAttachment}
          onClick={onClickAttachment}
        />
      </div>
    </>
  );
};

export default PortfolioReadOnlyAttachments;
