import React from "react";

import { ChildReference } from "Utilities/types";

import { AddSectionModal } from "./AddSectionModal";
import { DeleteSectionModal } from "./DeleteSectionModal";

export const useSectionManagement = (parentID: string) => {
  const [modal, setModal] = React.useState<React.ReactNode>(null);

  const onCloseModal = React.useCallback(() => setModal(null), []);

  const onAddSection = React.useCallback(() => {
    setModal(<AddSectionModal parentID={parentID} onCloseModal={onCloseModal} />);
  }, [setModal, onCloseModal, parentID]);

  const onEditSection = React.useCallback(
    (sectionReference: ChildReference) => {
      setModal(
        <AddSectionModal
          parentID={parentID}
          onCloseModal={onCloseModal}
          sectionReference={sectionReference}
        />,
      );
    },
    [parentID, setModal, onCloseModal],
  );

  const onDeleteSection = React.useCallback(
    (sectionReference: ChildReference) => {
      setModal(
        <DeleteSectionModal onCloseModal={onCloseModal} sectionReference={sectionReference} />,
      );
    },
    [setModal, onCloseModal],
  );

  return {
    modal,
    onCloseModal,
    onAddSection,
    onEditSection,
    onDeleteSection,
  };
};
