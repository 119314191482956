import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export enum QueryEnvironment {
  loggedIn = "loggedIn",
  inDepartment = "inDepartment",
  inOrganisation = "inOrganisation",
}

export enum PersistPermissions {
  persist = "eolas_persist",
  doNotPersist = "eolas_no_persist ",
}

interface UseEolasQueryParams<keyArray extends any[], funcData, error, data = funcData> {
  queryKey: [...keyArray];
  queryFn: (...params: Parameters<any>) => Promise<funcData>;
  options?: Omit<UseQueryOptions<funcData, error, data, [...keyArray]>, "queryKey" | "queryFn">;
  environment: QueryEnvironment;
  refetchOnScreenFocus?: boolean;
  shouldPersist?: boolean;
}

export const useEolasQuery = <keyArray extends any[], funcData, error, data = funcData>({
  queryKey,
  queryFn,
  options,
  environment,
  shouldPersist = true,
}: UseEolasQueryParams<keyArray, funcData, error, data>) => {
  const result = useQuery({
    queryKey,
    queryFn,
    useErrorBoundary: true,
    retry: 1,
    ...options,
  });

  return result;
};
