import { useMediaQuery } from "Hooks";
import { Sizes } from "UIKit/types";

export const useButtonSize = () => {
  const media = useMediaQuery();
  const isDesktop = media === "lg" || media === "xl" || media === "2xl";

  const buttonSize = isDesktop ? Sizes.MEDIUM : Sizes.SMALL;

  return { buttonSize };
};
