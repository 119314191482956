import { userStore } from "@eolas-medical/core";
import useAddFile from "modules/myFiles/data/useAddFile";
import useAllFiles from "modules/myFiles/data/useAllFiles";
import { CreateFileInput } from "modules/myFiles/types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal, AddFileModal } from "UIKit";

interface AddMyFileModalProps {
  handleClose(): void;
  open: boolean;
  sectionId?: string;
}

export const AddMyFileModal = ({ handleClose, open, sectionId }: AddMyFileModalProps) => {
  const { t } = useTranslation();
  const { userID = "" } = userStore;
  const { filesList } = useAllFiles(userID, sectionId);
  const {
    addFile,
    uploadProgress,
    addFileSuccessful,
    addFileError,
    isLoading,
    reset,
    abortAddFile,
  } = useAddFile();

  const onSubmit = useCallback(
    (input: CreateFileInput & { file: File }) => {
      return addFile({ ...input, sectionId, userId: userID });
    },
    [userID, sectionId, addFile],
  );

  const onClose = useCallback(() => {
    abortAddFile();
    handleClose();
    reset();
  }, [handleClose, reset, abortAddFile]);

  const onChange = useCallback(() => {
    if (addFileError) reset();
  }, [addFileError, reset]);

  const sectionFileNames = useMemo(() => filesList.map(({ name }) => name), [filesList]);

  return (
    <Modal data-testid="add-my-file-modal" open={open} onClose={onClose}>
      <AddFileModal
        onCloseModal={onClose}
        onBlobChange={onChange}
        onSubmit={onSubmit}
        onBack={reset}
        allowedFileTypes={["blob", "link"]}
        progress={uploadProgress}
        isSuccessful={addFileSuccessful}
        isLoading={isLoading}
        errorMessage={addFileError}
        existingFileNames={sectionFileNames}
        title={t(`my_files_add_file_title`)}
        pickerFormLabel={t("filePicker_file_to_upload_label")}
        pickerUploadLabel={t("filePicker_upload_file_button")}
        pickerChangeLabel={t("filePicker_change_file")}
        pickerDescriptionText={t("filePicker_helper_text")}
        pickerSubDescriptionText={t("filePicker_or")}
      />
    </Modal>
  );
};
