import * as icons from "Assets/Icons";
import type { EolasIcons } from "Assets/Icons";

import { Title, Text } from "../../Typography";
import { Button, ButtonProps } from "../../Button";
import { ModalBody, ModalHeader, InnerModalWrapper } from "../components";

export interface InformationModalProps {
  title?: string;
  description?: string;
  doneLabel?: string;
  iconType?: EolasIcons;
  doneButtonProps?: ButtonProps;
  onDone(e: React.MouseEvent<HTMLButtonElement>): void;
}

export const InformationModal: React.FC<InformationModalProps> = ({
  title,
  iconType,
  description,
  doneLabel = "Done",
  doneButtonProps,
  onDone,
}) => {
  const Icon = iconType ? icons[iconType] : null;

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        {Icon && <Icon className="h-20 w-20 mb-4" />}
        <Title level={5}>{title}</Title>
        <Text level={1} className="text-center text-grey-700">
          {description}
        </Text>
      </ModalHeader>

      <ModalBody className="space-y-4 sm:space-y-8">
        <Button
          size="lg"
          color="blue"
          variant="solid"
          onClick={onDone}
          className="order-1 sm:order-0"
          {...doneButtonProps}
        >
          {doneLabel}
        </Button>
      </ModalBody>
    </InnerModalWrapper>
  );
};
