import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { ContentRepositoryId } from "modules/contentRepository/types";

const useContentRepositoryParams = () => {
  const params = useParamsDecoded<{
    contentRepositoryId: ContentRepositoryId;
    subSectionId: string;
  }>();
  const { contentRepositoryId, subSectionId } = params;

  return {
    contentRepositoryId: contentRepositoryId ? `SEC#${contentRepositoryId}` : contentRepositoryId,
    subSectionId: subSectionId ? `SEC#${subSectionId}` : subSectionId,
  };
};

export default useContentRepositoryParams;
