import { extension } from "mime-types";
import { countries } from "countries-list";
import { addMonths, differenceInDays, format } from "date-fns";
import {
  ChecklistRecord,
  OrganisationLevelSection,
  generatePublicUrl,
  sectionStore,
} from "@eolas-medical/core";
import { S3GeneratorParams, AppEnv, MedusaData } from "Utilities/types";
import axios from "axios";

type AppEnvTypes = "dev" | "staging" | "prod";

export const API_ENV_PATH_PREFIXS: Record<AppEnv, AppEnvTypes> = {
  [AppEnv.DEVELOPMENT]: "dev",
  [AppEnv.STAGING]: "staging",
  [AppEnv.PRODUCTION]: "prod",
};

export function formatDate(date?: Date | string, tokens: string = "do MMMM yyyy, HH:mm"): string {
  if (!date) return "";

  return format(new Date(date), tokens);
}

export const convertToLink = (value: string): string => {
  const isHttp = value.startsWith("https://") || value.startsWith("http://");
  return isHttp ? value : `https://${value}`;
};

function addTimestampToFilename(filename: string) {
  const timestamp = new Date().getTime();
  const dotIndex = filename.lastIndexOf(".");
  if (dotIndex === -1) {
    return filename + "_" + timestamp;
  } else {
    return filename.slice(0, dotIndex) + "_" + timestamp + filename.slice(dotIndex);
  }
}

export const generateS3FileKey = ({
  isPublic,
  fileName,
  fileFormat,
  mainSectionId,
  addExtension,
}: S3GeneratorParams): string => {
  const { app, organisation } = sectionStore.data;

  const fileNameTimestamped = addTimestampToFilename(fileName);

  let s3key = `public/${organisation?.id}/${app?.id}/${mainSectionId}/${fileNameTimestamped}`;

  if (isPublic) {
    s3key = `public/${organisation?.id}/${app?.id}/sharedContent/${fileNameTimestamped}`;
  }

  if (addExtension) {
    const ext = extension(fileFormat);
    return `${s3key}.${ext}`;
  }

  return s3key;
};

export const generateS3PublicImageURL = (fileKey: string) => {
  return generatePublicUrl(fileKey, process.env.REACT_APP_PUBLIC_BUCKET_URL!);
};

export const isHospital = (mainSectionId: string) => {
  return mainSectionId in OrganisationLevelSection;
};

export const parseMedusaMetadata = (metadata: string | null = "{}"): MedusaData => {
  if (metadata) {
    const result = fromAwsJSON(metadata);
    return result.medusa || {};
  }
  return {};
};

export const createSearchField = ({
  name = "",
  description = "",
  type = "",
  date = "",
  createdBy = "",
  moreInfo = "",
}) => {
  const readableDate = date.length > 0 ? formatDate(date, "PPPPpppp") : "";

  let searchString = "";
  [name, description, readableDate, createdBy, type, moreInfo].forEach((string: any) => {
    if (string.length > 0) {
      searchString += `${string.toLowerCase()} `;
    }
  });

  return searchString.trim();
};

export const licenceCost = {
  UK: "£3000",
  EU: "€3000",
  REST_WORLD: "$5000",
};

export const daysLeftForCompetency = ({
  completedDate,
  expirationInMonths,
}: {
  completedDate: string;
  expirationInMonths: string;
}) => {
  const today: Date = new Date();

  const expireDate: string = addMonths(
    new Date(completedDate),
    Number(expirationInMonths),
  ).toISOString();
  return differenceInDays(new Date(expireDate), new Date(today));
};

export const hasExpirationLength = (expirationInMonths: string) => {
  return !!Number(expirationInMonths);
};

export const generateLicenceCost = (organisationLocation: keyof typeof countries) => {
  const countryCurrency = countries[organisationLocation].currency;
  const currencies = {
    UK: ["GBP"],
    EUROPE: [
      "EUR",
      "PLN",
      "ALL",
      "AMD",
      "AZN",
      "BAM",
      "BYN",
      "CHF",
      "CZK",
      "DKK",
      "GEL",
      "HRK",
      "HUF",
      "ISK",
      "MDL",
      "MKD",
      "NOK",
      "RON",
      "RSD",
      "RUB",
      "SEK",
      "TRY",
      "UAH",
    ],
  };

  if (currencies.UK.includes(countryCurrency)) {
    return licenceCost.UK;
  } else if (currencies.EUROPE.includes(countryCurrency)) {
    return licenceCost.EU;
  } else {
    return licenceCost.REST_WORLD;
  }
};

export const removeLastUrlPath = (url: string, segments = 1) =>
  url
    .split("/")
    .slice(0, -1 * segments)
    .join("/");

export const toAwsJSON = (object: object) => JSON.stringify(JSON.stringify(object));
export const fromAwsJSON = (json: string) => {
  const parseOrReturn = (json: string | object) =>
    typeof json === "string" ? JSON.parse(json) : json;

  return parseOrReturn(parseOrReturn(json));
};

export const isChecklist = (object: any): object is ChecklistRecord => {
  return object instanceof Object && "checklistName" in object;
};

export const servicesStatusCheck = async () => {
  // ping each service to verify user's connection status and any possible network blocks
  const PING_LIST = {
    CLICK: false,
    S3: false,
    COGNITO: false,
  };

  PING_LIST.S3 = await SendServiceCheck({
    url: `https://${process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET}.s3.eu-west-1.amazonaws.com`,
    expectedErrorStatus: 412,
  });

  PING_LIST.COGNITO = await SendServiceCheck({
    url: "https://cognito-idp.eu-west-1.amazonaws.com/",
    responseStatus: 200,
  });

  PING_LIST.CLICK = await SendServiceCheck({
    url: `${process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT}`,
    expectedErrorStatus: 400,
  });

  return Object.values(PING_LIST).every((service) => service === true);
};

type SendServiceCheckBody = {
  url: string;
  expectedErrorStatus?: number;
  responseStatus?: number;
};

const SendServiceCheck = ({ url, expectedErrorStatus, responseStatus }: SendServiceCheckBody) => {
  const abortController = new AbortController();
  return new Promise<boolean>((resolve) => {
    const timeoutID = setTimeout(() => {
      abortController.abort();
      resolve(false);
    }, 15000);
    axios
      .post(url, { signal: abortController.signal })
      .then((response) => {
        clearTimeout(timeoutID);
        resolve(responseStatus === response?.status);
      })
      .catch((error) => {
        clearTimeout(timeoutID);
        resolve(expectedErrorStatus === error?.response?.status);
      });
  });
};

export const hospitalFacilities: { [key: string]: string } = {
  hospitalsAdmin_facilities_emergency_dept: "Emergency Dept",
  hospitalsAdmin_facilities_ICU: "ICU",
  hospitalsAdmin_facilities_anaesthetics: "Anaesthetics",
  hospitalsAdmin_facilities_orthopaedics: "Orthopaedics",
  hospitalsAdmin_facilities_neurosurgery: "Neurosurgery",
  hospitalsAdmin_facilities_cardiothoracic: "Cardiothoracic",
  hospitalsAdmin_facilities_primary_pci: "Primary PCI",
  hospitalsAdmin_facilities_maxillofacial: "Maxillofacial",
  hospitalsAdmin_facilities_vascular: "Vascular",
  hospitalsAdmin_facilities_burns: "Burns",
  hospitalsAdmin_facilities_plastics: "Plastics",
  "hospitalsAdmin_facilities_emco-bypass": "ECMO/Bypass",
  hospitalsAdmin_facilities_interventional_radiology: "Interventional Radiology",
  hospitalsAdmin_facilities_paediatrics: "Paediatrics",
  "hospitalsAdmin_facilities_obstetrics-maternity": "Obstetrics/Maternity",
  hospitalsAdmin_facilities_blood: "Blood",
  "hospitalsAdmin_facilities_247-ct": "24/7 CT",
};

export const getImageUrl = (key: string): string => {
  return key.startsWith("s3://") || !key.startsWith("http") ? generateS3PublicImageURL(key) : key;
};
