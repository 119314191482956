import { appConfig } from "AppTypeConfig";
import { apple, google } from "Assets";
import { InnerPageWrapper, NavButton, PageTitle, Text, Title } from "UIKit";
import { removeLastUrlPath } from "Utilities";
import { useRouteMatch } from "react-router-dom";

const Chat = () => {
  const { url } = useRouteMatch();
  const backUrl = removeLastUrlPath(url, 1);

  return (
    <InnerPageWrapper>
      <PageTitle title="Chat" />

      <NavButton to={backUrl} />

      <div className="flex flex-col justify-center items-center space-y-5 text-center">
        <Title level={6} className="mt-4">
          🚀 Exciting news! Chat coming soon to web! 🌐
        </Title>
        <div className="flex flex-col">
          <Text level={1} className="text-grey-700">
            Chat is on the way to our web app! While it's not here yet, you can use it on our mobile
            app.
          </Text>
          <Text level={1} className="text-grey-700 ">
            Stay tuned for updates!
          </Text>
        </div>

        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 space-x-0 md:space-x-6 relative top-4">
          <a href={appConfig.appleAppStore} target="_blank" rel="noreferrer noopener">
            <img src={apple} className="h-12 rounded-lg" alt="app-store" />
          </a>

          <a href={appConfig.googlePlay} target="_blank" rel="noreferrer noopener">
            <img src={google} className="h-12 rounded-lg" alt="play-store" />
          </a>
        </div>
      </div>
    </InnerPageWrapper>
  );
};

export default Chat;
