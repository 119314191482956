import { useRouteMatch } from "react-router-dom";
import { InnerPageWrapper, PageTitle, Loader } from "UIKit";
import { EventStatus, FeedbackStatus, eventTypeLabels } from "modules/portfolio/types";
import StatusBadge from "Pages/MeTab/pages/Portfolio/components/StatusBadge/StatusBadge";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import EventClosedErrorPage from "../../components/EventClosedErrorPage/EventClosedErrorPage";
import useEvent from "modules/portfolio/data/useEvent";
import useFeedbackForm from "modules/portfolio/data/useFeedbackForm";
import usePortfolioParams from "Pages/MeTab/pages/Portfolio/hooks/usePortfolioParams";

export const FeedbackDetail = () => {
  const { url } = useRouteMatch();
  const { eventId, eventType } = usePortfolioParams();
  const { event, eventLoading } = useEvent(eventId);
  const { feedbackForm, feedbackFormLoading } = useFeedbackForm(eventId);
  const backUrl = url.replace(`/${eventType.toLowerCase()}/${eventId}`, "");

  const eventTitle = eventTypeLabels[eventType] || "";

  if (
    !feedbackFormLoading &&
    event?.status === EventStatus.COMPLETE &&
    feedbackForm?.status !== FeedbackStatus.COMPLETE
  ) {
    return <EventClosedErrorPage backUrl={backUrl} />;
  }

  if (!feedbackForm || feedbackFormLoading || eventLoading) {
    return (
      <InnerPageWrapper>
        <div className="m-auto px-2 flex flex-col items-center">
          <Loader className="bg-transparent" />
        </div>
      </InnerPageWrapper>
    );
  }

  return (
    <>
      <div className="mx-auto mt-12">
        <StatusBadge status={feedbackForm.status} hasBorder />
      </div>
      <PageTitle className="my-4" title={eventTitle} />
      <InnerPageWrapper>
        <FeedbackForm
          eventId={eventId}
          eventType={eventType}
          backUrl={backUrl}
          feedbackForm={feedbackForm}
          areAttachmentsShared={event.areAttachmentsShared}
        />
      </InnerPageWrapper>
    </>
  );
};
