import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import { maintenance } from "Assets";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: maintenance,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const MaintenancePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="h-screen flex flex-col justify-center">
      {/* Adding extra mb to account for extra white space in the lottie at the top, this centers things nicely */}
      <div className="text-center px-5 mb-16">
        <div className="w-2/3 lg:w-1/4 mx-auto">
          <Lottie {...defaultOptions} />
        </div>
        <div className="text-xl lg:text-2xl mt-8 font-bold">{t("maintenance_page_title")}</div>
        <div className="text-md lg:text-xl mt-6 text-grey-dark">{t("maintenance_page_msg")}</div>
      </div>
    </div>
  );
};
