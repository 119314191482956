import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { AnalyticsEvents, VisitPayload } from "@eolas-medical/core";

import { trackEvent } from "API/Analytics";

export const useMainSectionVisit = (payload: Omit<VisitPayload, "visitFrom">) => {
  const location = useLocation<{ from: VisitPayload["visitFrom"] }>();

  useEffect(() => {
    trackEvent(AnalyticsEvents.MAIN_SECTION_VISIT, {
      ...payload,
      visitFrom: location?.state?.from || "directNavigation",
    });
    // We only ever want this to be triggered once per main section load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
