import { trackEvent } from "API/Analytics";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { AnalyticsEvents, fetchAllData, sectionStore } from "@eolas-medical/core";

import { errorStore } from "Stores/ErrorStore";
import { toAwsJSON, fromAwsJSON, useRequestStatus } from "Utilities";

import { ChecklistBuilderForm, ChecklistNotifications, ChecklistOwner } from "../types";
import { checklistBuilderSchema } from "../validationSchemas";

import { cloneDeep } from "lodash";
import { useSpaceAdmins } from "modules/spaces/data/useSpaceAdmins";
import { PUBLISH_SECTION } from "Pages/Spaces/components/SectionManagement";

const UPDATE_SECTION = gql`
  mutation UpdateForSection($parentID: String!, $input: ModifySectionInput!) {
    updateForSection(parentID: $parentID, input: $input) {
      id
    }
  }
`;

interface UseChecklistBuilderParams {
  parentID: string;
  checklistID?: string;
}

const mapToDefaultValues = (checklistID?: string): ChecklistBuilderForm => {
  if (!checklistID) {
    return {
      checklistName: "",
      checklistSections: [],
      isAddingSection: false,
      owners: [],
    };
  }

  const { metadata } = sectionStore.getSection(checklistID);
  const { checklistTemplate } =
    typeof metadata === "string" ? fromAwsJSON(metadata ?? '"{}"') : metadata;

  return {
    isAddingSection: false,
    checklistName: checklistTemplate.checklistName,
    checklistSections: checklistTemplate.checklistSections,
    owners: checklistTemplate.owners ? cloneDeep(checklistTemplate.owners) : [],
  };
};

export const useChecklistBuilder = ({ parentID, checklistID }: UseChecklistBuilderParams) => {
  const [publishSection] = useMutation(PUBLISH_SECTION);
  const [updateForSection] = useMutation(UPDATE_SECTION);
  const { error, isLoading, isSuccessful, setRequestStatus } = useRequestStatus();
  const defaultValues = useMemo(() => {
    return mapToDefaultValues(checklistID);
  }, [checklistID]);

  const [isOpen, setModal] = useState(false);
  const [isAddingOwners, setIsAddingOwners] = useState(false);
  const [owners, setOwners] = useState(defaultValues.owners);
  const [ownerToModify, setOwnerToModify] = useState<ChecklistOwner | undefined>(undefined);

  const onCloseModal = useCallback(() => setModal(false), [setModal]);

  const handleOpenAddingOwnersModal = useCallback(() => setIsAddingOwners(true), [
    setIsAddingOwners,
  ]);
  const handleCloseAddingOwnersModal = useCallback(() => {
    setOwnerToModify(undefined);
    setIsAddingOwners(false);
  }, [setIsAddingOwners]);

  const handleAddOwner = useCallback(
    (owner: ChecklistOwner) => {
      const checklistComplete = owner.activeNotifications.includes(
        ChecklistNotifications.IS_COMPLETED,
      );
      const issuesFlagged = owner.activeNotifications.includes(
        ChecklistNotifications.ISSUES_FLAGGED,
      );

      // If the user is modifying an owner we replace the ownerToModify with the new one
      setOwners((owners) => [
        owner,
        ...(ownerToModify ? owners.filter((o) => o.id !== ownerToModify.id) : owners),
      ]);

      trackEvent(AnalyticsEvents.CHECKLIST_OWNER_ADDED, { issuesFlagged, checklistComplete });
    },
    [ownerToModify],
  );

  const onRemoveOwner = useCallback((id: string) => {
    setOwners((owners) => owners.filter((o) => o.id !== id));
    trackEvent(AnalyticsEvents.CHECKLIST_OWNER_REMOVED);
  }, []);

  const onEditOwner = useCallback(
    (owner: ChecklistOwner) => {
      setOwnerToModify(owner);
      handleOpenAddingOwnersModal();
    },
    [handleOpenAddingOwnersModal],
  );

  const formMethods = useForm<ChecklistBuilderForm>({
    resolver: yupResolver(checklistBuilderSchema),
    defaultValues: defaultValues,
  });
  const { control, handleSubmit, watch } = formMethods;

  const checklistName = watch("checklistName");

  const submitChecklist = async (values: ChecklistBuilderForm) => {
    const mutationFn = checklistID ? updateForSection : publishSection;

    const metadata = mapToChecklistTemplate(values, owners);

    try {
      setRequestStatus({ status: "pending", error: "" });
      await mutationFn({
        variables: {
          parentID,
          input: {
            metadata,
            id: checklistID,
            type: "checklistTemplate",
            name: values.checklistName,
          },
        },
      });
      setRequestStatus({ status: "success", error: "" });
      await fetchAllData();
    } catch (error: any) {
      const errorMessage = errorStore.captureError({ error, source: "user" });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  };

  const onSubmit = handleSubmit(async (values) => {
    if (values.isAddingSection) {
      setModal(true);
    } else {
      submitChecklist(values);
    }
  });

  const { spaceAdmins, isLoadingSpaceAdmins } = useSpaceAdmins(sectionStore.appID);
  const possibleOwners = useMemo(() => {
    return spaceAdmins.filter(({ userID }: { userID: string }) => {
      // When modifying an owner we want to include them in the list of possible owners
      return !owners.some((o) => o.id === userID) || ownerToModify?.id === userID;
    });
  }, [spaceAdmins, owners, ownerToModify]);

  return {
    error,
    isOpen,
    control,
    isLoading,
    formMethods,
    isSuccessful,
    checklistName,
    onSubmit,
    onCloseModal,
    submitChecklist,
    isAddingOwners,
    handleOpenAddingOwnersModal,
    handleCloseAddingOwnersModal,
    owners,
    handleAddOwner,
    possibleOwners,
    onRemoveOwner,
    ownerToModify,
    onEditOwner,
    isLoadingSpaceAdmins,
  };
};

const mapToChecklistTemplate = (values: ChecklistBuilderForm, owners: ChecklistOwner[]): string => {
  const result = {
    checklistName: values.checklistName,
    checklistSections: values.checklistSections.map(({ expanded, ...section }) => section),
    owners,
  };

  return toAwsJSON({ checklistTemplate: result });
};
