export type AuthenticationWizard = "sign-in-wizard" | "sign-up-wizard" | "forgot-password-wizard";

export type SignInStep =
  | "email-not-verified"
  | "validate-email"
  | "validate-password"
  | "verification-code"
  | "terms-and-conditions";

export type SignUpStep =
  | "create-account"
  | "create-password"
  | "validate-email"
  | "verification-code"
  | "validate-password"
  | "email-not-verified"
  | "terms-and-conditions";

export type ForgotPasswordStep = "verification-code" | "create-password";

export type ValidateEmailError = "account-already-exists" | "account-not-exists";

export const AuthenticationErrorMessage: Record<ValidateEmailError, string> = {
  "account-already-exists": "createAccount_already_exists_error",
  "account-not-exists": "login_not_existing_account_error",
};
