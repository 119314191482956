import { useTranslation } from "react-i18next";

import { AccessRequest } from "Utilities/types";
import { RefreshCWIcon, noFilesFound } from "Assets";
import { useGetAllAccessRequests } from "Hooks/usersService";

import { Title, Button, ModalBody, ModalHeader, LottieWithHeader, InnerModalWrapper } from "UIKit";

import { AccessRequestEntry } from "./AccessRequestEntry";

interface AccessRequestsModalProps {
  refetchUsers(): void;
}

export const AccessRequestsModal: React.FC<AccessRequestsModalProps> = ({ refetchUsers }) => {
  const { t } = useTranslation();
  const { accessRequests, count, refetch } = useGetAllAccessRequests();

  if (count === 0) {
    return (
      <InnerModalWrapper>
        <LottieWithHeader
          animationSize="125px"
          animation={noFilesFound}
          lottieOptions={{ loop: true }}
          text={t("manageUsers_accessRequest_noRequests")}
        />
        <Button
          color="grey"
          weight="bold"
          variant="outline"
          onClick={() => refetch()}
          style={{ borderRadius: 44 }}
          className="self-center px-3 my-4"
        >
          <RefreshCWIcon className="h-4 w-4 mr-2" />
          {t("manageUsers_refresh_requests")}
        </Button>
      </InnerModalWrapper>
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}> {t("manageUsers_accessRequestModal_title")}</Title>{" "}
      </ModalHeader>

      <ModalBody className="h-full overflow-y-auto">
        <Title className="mb-3" level={8}>
          {t("manageUsers_access_request_subtitle")}
        </Title>
        <div className="flex flex-col w-full rounded-md border border-grey-300 divide-y divide-grey-300">
          {accessRequests.map((request: AccessRequest) => {
            return (
              <AccessRequestEntry
                key={request.id}
                accessRequest={request}
                refetchUsers={refetchUsers}
                requestTextTitle={t("manageUsers_accessRequestModal_additionalInformation")}
              />
            );
          })}
        </div>

        <Button
          color="grey"
          weight="bold"
          variant="outline"
          onClick={() => refetch()}
          style={{ borderRadius: 44 }}
          className="self-center px-3 mt-4 sm:mt-8"
        >
          <RefreshCWIcon className="h-4 w-4 mr-2" />
          {t("manageUsers_refresh_requests")}
        </Button>
      </ModalBody>
    </InnerModalWrapper>
  );
};
