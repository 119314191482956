import { Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";

const SearchDisclaimer = () => {
  const { t } = useTranslation();
  const textColor = "text-grey-600";
  return (
    <div className="p-3">
      <Title level={6} className={`${textColor} my-4`}>
        {t("master_search_desclaimer_title")}
      </Title>
      <ul className={`list-disc ${textColor} px-3 space-y-2`}>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_portfolio")}
          </Text>
        </li>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_files")}
          </Text>
        </li>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_department")}
          </Text>
        </li>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_hospital")}
          </Text>
        </li>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_national_guidelines")}
          </Text>
        </li>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_clinical_calculators")}
          </Text>
        </li>
      </ul>
    </div>
  );
};

export default SearchDisclaimer;
