import { useCallback, useMemo } from "react";
import { useLocalSearch as useLocalFilter } from "shared/hooks/useLocalSearch";

import { MeResult, SearchSubType } from "../types";
import useAllFiles from "modules/myFiles/data/useAllFiles";
import useEventList from "modules/portfolio/data/useEventList";

interface UseMyFilesSearchProps {
  userId: string;
}

interface UseMyFilesSearch {
  meFiles: MeResult[];
  meFilesLoading: boolean;
  onSearchMeFiles: (text: string) => void;
}

const useMeSearch = ({ userId }: UseMyFilesSearchProps): UseMyFilesSearch => {
  const { filesList, filesListLoading } = useAllFiles(userId);
  const { eventList, eventListLoading } = useEventList(userId);

  const searchableFiles: MeResult[] = useMemo(() => {
    const portfolioFiles: MeResult[] = eventList.map((f) => ({
      id: f.id,
      event: f,
      title: f.title,
      mainSectionName: "My Portfolio",
      searchTypeSection: "me",
      searchSubType: "myPortfolio" as SearchSubType,
      iconName: "MyPortfolioIcon",
    }));

    const myFiles: MeResult[] = filesList.map((sr) => ({
      id: sr.id,
      myFile: sr,
      title: sr.name,
      mainSectionName: "My Files",
      searchTypeSection: "me",
      searchSubType: "myPortfolio" as SearchSubType,
      iconName: "MyFilesIcon",
    }));

    return [...myFiles, ...portfolioFiles];
  }, [filesList, eventList]);

  const { searchInput, searchResult, onSetSearchInput } = useLocalFilter({
    data: searchableFiles,
    keysToSearch: ["title"],
  });

  const handleSearch = useCallback(
    (text: string) => {
      onSetSearchInput(text);
    },
    [onSetSearchInput],
  );

  const meFiles: MeResult[] = useMemo(() => {
    if (searchInput.length < 2) return [];

    return searchResult;
  }, [searchResult, searchInput]);

  return {
    meFiles,
    meFilesLoading: filesListLoading || eventListLoading,
    onSearchMeFiles: handleSearch,
  };
};

export default useMeSearch;
