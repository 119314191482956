export const upload = {
  v: "5.5.8",
  fr: 25,
  ip: 0,
  op: 26,
  w: 50,
  h: 50,
  nm: "Composição 1",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "arrow contornos",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [25, 25, 0], ix: 2 },
        a: { a: 0, k: [11, 11, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.466, 0.45],
                    [0, 0],
                    [-0.462, 0.436],
                    [0, 0],
                    [-0.457, -0.441],
                    [0, 0],
                    [0.462, -0.436],
                    [0, 0],
                    [0.457, 0.455],
                    [0, 0],
                    [0, 0],
                    [0.653, 0],
                    [0, 0],
                    [0, 0.624],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.462, -0.441],
                    [0, 0],
                    [0.462, -0.441],
                    [0, 0],
                    [0.462, 0.441],
                    [0, 0],
                    [-0.466, 0.445],
                    [0, 0],
                    [0, 0],
                    [0, 0.624],
                    [0, 0],
                    [-0.653, 0],
                    [0, 0],
                    [0, 0],
                    [-0.456, 0.46],
                  ],
                  v: [
                    [-9.341, 1.571],
                    [-10.431, 0.531],
                    [-10.431, -1.058],
                    [-0.89, -10.171],
                    [0.775, -10.171],
                    [10.316, -1.063],
                    [10.316, 0.526],
                    [9.226, 1.567],
                    [7.542, 1.548],
                    [1.909, -4.096],
                    [1.909, 9.376],
                    [0.731, 10.501],
                    [-0.841, 10.501],
                    [-2.019, 9.376],
                    [-2.019, -4.096],
                    [-7.657, 1.552],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Caminho 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.011999999776, 0.663000009574, 0.957000014361, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Preenchimento 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [10.778, 10.501], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transformar",
            },
          ],
          nm: "Grupo 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 375,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "cloud contornos 4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [15, -21, 0],
              to: [0, 10.25, 0],
              ti: [0, -10.25, 0],
            },
            { t: 27, s: [15, 40.5, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [15, 9, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 3.53],
                    [-3.428, 0],
                    [-0.693, -0.285],
                    [-3.065, 0],
                    [-1.045, -3.491],
                    [-0.46, 0],
                    [0, -3.166],
                    [2.969, 0],
                    [0.188, 0.021],
                    [0, 0],
                    [0.238, 0],
                    [0.232, 0.021],
                    [0, 0],
                    [0.032, 0],
                    [0.032, 0],
                    [0, 0],
                  ],
                  o: [
                    [-3.268, -0.19],
                    [0, -3.655],
                    [0.787, 0],
                    [1.381, -2.674],
                    [3.622, 0],
                    [0.425, -0.115],
                    [2.969, 0],
                    [0, 3.165],
                    [-0.195, 0],
                    [0, 0],
                    [-0.232, 0.021],
                    [-0.237, 0],
                    [0, 0],
                    [-0.032, 0],
                    [-0.033, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-9.138, 8.612],
                    [-15, 2.007],
                    [-8.793, -4.61],
                    [-6.56, -4.169],
                    [0.555, -8.654],
                    [8.292, -2.633],
                    [9.623, -2.809],
                    [15, 2.923],
                    [9.623, 8.654],
                    [9.049, 8.622],
                    [1.261, 8.622],
                    [0.555, 8.654],
                    [-0.15, 8.622],
                    [-8.697, 8.622],
                    [-8.793, 8.623],
                    [-8.89, 8.622],
                    [-9.138, 8.622],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Caminho 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.894000004787, 0.917999985639, 0.925, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Preenchimento 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [15, 9.346], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transformar",
            },
          ],
          nm: "Grupo 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 375,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "cloud contornos 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [34.75, -52.5, 0],
              to: [0, 10.25, 0],
              ti: [0, -10.25, 0],
            },
            { t: 27, s: [34.75, 9, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [15, 9, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 3.53],
                    [-3.428, 0],
                    [-0.693, -0.285],
                    [-3.065, 0],
                    [-1.045, -3.491],
                    [-0.46, 0],
                    [0, -3.166],
                    [2.969, 0],
                    [0.188, 0.021],
                    [0, 0],
                    [0.238, 0],
                    [0.232, 0.021],
                    [0, 0],
                    [0.032, 0],
                    [0.032, 0],
                    [0, 0],
                  ],
                  o: [
                    [-3.268, -0.19],
                    [0, -3.655],
                    [0.787, 0],
                    [1.381, -2.674],
                    [3.622, 0],
                    [0.425, -0.115],
                    [2.969, 0],
                    [0, 3.165],
                    [-0.195, 0],
                    [0, 0],
                    [-0.232, 0.021],
                    [-0.237, 0],
                    [0, 0],
                    [-0.032, 0],
                    [-0.033, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-9.138, 8.612],
                    [-15, 2.007],
                    [-8.793, -4.61],
                    [-6.56, -4.169],
                    [0.555, -8.654],
                    [8.292, -2.633],
                    [9.623, -2.809],
                    [15, 2.923],
                    [9.623, 8.654],
                    [9.049, 8.622],
                    [1.261, 8.622],
                    [0.555, 8.654],
                    [-0.15, 8.622],
                    [-8.697, 8.622],
                    [-8.793, 8.623],
                    [-8.89, 8.622],
                    [-9.138, 8.622],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Caminho 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.894000004787, 0.917999985639, 0.925, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Preenchimento 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [15, 9.346], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transformar",
            },
          ],
          nm: "Grupo 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 375,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "cloud contornos 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [34.75, 9.25, 0],
              to: [0, 10.25, 0],
              ti: [0, -10.25, 0],
            },
            { t: 27, s: [34.75, 70.75, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [15, 9, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 3.53],
                    [-3.428, 0],
                    [-0.693, -0.285],
                    [-3.065, 0],
                    [-1.045, -3.491],
                    [-0.46, 0],
                    [0, -3.166],
                    [2.969, 0],
                    [0.188, 0.021],
                    [0, 0],
                    [0.238, 0],
                    [0.232, 0.021],
                    [0, 0],
                    [0.032, 0],
                    [0.032, 0],
                    [0, 0],
                  ],
                  o: [
                    [-3.268, -0.19],
                    [0, -3.655],
                    [0.787, 0],
                    [1.381, -2.674],
                    [3.622, 0],
                    [0.425, -0.115],
                    [2.969, 0],
                    [0, 3.165],
                    [-0.195, 0],
                    [0, 0],
                    [-0.232, 0.021],
                    [-0.237, 0],
                    [0, 0],
                    [-0.032, 0],
                    [-0.033, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-9.138, 8.612],
                    [-15, 2.007],
                    [-8.793, -4.61],
                    [-6.56, -4.169],
                    [0.555, -8.654],
                    [8.292, -2.633],
                    [9.623, -2.809],
                    [15, 2.923],
                    [9.623, 8.654],
                    [9.049, 8.622],
                    [1.261, 8.622],
                    [0.555, 8.654],
                    [-0.15, 8.622],
                    [-8.697, 8.622],
                    [-8.793, 8.623],
                    [-8.89, 8.622],
                    [-9.138, 8.622],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Caminho 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.894000004787, 0.917999985639, 0.925, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Preenchimento 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [15, 9.346], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transformar",
            },
          ],
          nm: "Grupo 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 375,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "cloud contornos",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [15, 40.75, 0],
              to: [0, 10.25, 0],
              ti: [0, -10.25, 0],
            },
            { t: 27, s: [15, 102.25, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [15, 9, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 3.53],
                    [-3.428, 0],
                    [-0.693, -0.285],
                    [-3.065, 0],
                    [-1.045, -3.491],
                    [-0.46, 0],
                    [0, -3.166],
                    [2.969, 0],
                    [0.188, 0.021],
                    [0, 0],
                    [0.238, 0],
                    [0.232, 0.021],
                    [0, 0],
                    [0.032, 0],
                    [0.032, 0],
                    [0, 0],
                  ],
                  o: [
                    [-3.268, -0.19],
                    [0, -3.655],
                    [0.787, 0],
                    [1.381, -2.674],
                    [3.622, 0],
                    [0.425, -0.115],
                    [2.969, 0],
                    [0, 3.165],
                    [-0.195, 0],
                    [0, 0],
                    [-0.232, 0.021],
                    [-0.237, 0],
                    [0, 0],
                    [-0.032, 0],
                    [-0.033, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-9.138, 8.612],
                    [-15, 2.007],
                    [-8.793, -4.61],
                    [-6.56, -4.169],
                    [0.555, -8.654],
                    [8.292, -2.633],
                    [9.623, -2.809],
                    [15, 2.923],
                    [9.623, 8.654],
                    [9.049, 8.622],
                    [1.261, 8.622],
                    [0.555, 8.654],
                    [-0.15, 8.622],
                    [-8.697, 8.622],
                    [-8.793, 8.623],
                    [-8.89, 8.622],
                    [-9.138, 8.622],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Caminho 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.894000004787, 0.917999985639, 0.925, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Preenchimento 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [15, 9.346], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transformar",
            },
          ],
          nm: "Grupo 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 375,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
