import { useTranslation } from "react-i18next";

import { Text } from "UIKit";
import { PersonIcon, KnowledgeHubIcon, SpacesIcon, OrganisationIcon } from "Assets/Icons";
import useEolasNavigation from "../../context/useEolasNavigation";

export const MobileNavigation = () => {
  const { t } = useTranslation();
  const {
    activeTab,
    hasSpacesTab,
    hasOrganisationTab,
    hasSelectedSpace,
    hasKnowledgeTab,
    goMeTab,
    goToOrganisationTab,
    goToSpaceTab,
    goToSelectSpace,
    goToKnowledgeTab,
  } = useEolasNavigation();

  return (
    <div
      style={{ left: "50%", transform: "translate(-50%)" }}
      className={`
        flex items-center space-x-6 py-2 px-8 bg-white shadow-lg rounded-full
        fixed bottom-4 z-40
      `}
    >
      <NavigationItem
        onClick={goMeTab}
        activeClassname="text-black"
        label={t("navigation_me_tab")}
        pillActiveClassname="bg-yellow-me"
        isActive={activeTab === "my-profile"}
        icon={<PersonIcon className="h-5 w-5 text-grey-600" />}
      />

      {!hasSelectedSpace && (
        <NavigationItem
          activeClassname="text-blue-500"
          pillActiveClassname="bg-blue-50"
          onClick={goToSelectSpace}
          isActive={activeTab === "select-space"}
          label={t("navigation_department_tab")}
          icon={<SpacesIcon className="h-5 w-5 text-grey-600" />}
        />
      )}

      {hasSelectedSpace && (
        <>
          {hasSpacesTab && (
            <NavigationItem
              onClick={goToSpaceTab}
              activeClassname="text-blue-500"
              pillActiveClassname="bg-blue-50"
              isActive={activeTab === "spaces"}
              label={t("navigation_department_tab")}
              icon={<SpacesIcon className="h-5 w-5 text-grey-600" />}
            />
          )}

          {hasOrganisationTab && (
            <NavigationItem
              onClick={goToOrganisationTab}
              activeClassname="text-black"
              isActive={activeTab === "organisation"}
              label={t("navigation_hospital_tab")}
              pillActiveClassname="bg-red-hospital"
              icon={<OrganisationIcon className="h-5 w-5 text-grey-600" />}
            />
          )}
        </>
      )}

      {hasKnowledgeTab && (
        <NavigationItem
          onClick={goToKnowledgeTab}
          label={t("navigation_knowledge_tab")}
          isActive={activeTab === "knowledge"}
          activeClassname="text-black"
          pillActiveClassname="bg-green-100"
          icon={<KnowledgeHubIcon className="h-5 w-5 text-grey-600" />}
        />
      )}
    </div>
  );
};

interface NavigationItemProps {
  label: string;
  testId?: string;
  icon: JSX.Element;
  isActive: boolean;
  pillActiveClassname?: string;
  activeClassname?: string;
  onClick(): void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  icon,
  label,
  isActive,
  activeClassname = "",
  pillActiveClassname = "",
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex flex-col items-center space-y-1 cursor-pointer ${
        isActive ? activeClassname : "text-grey-600"
      }`}
    >
      <div
        className={`
        flex items-center justify-center h-10 w-10 rounded-full
        ${isActive ? pillActiveClassname : ""}
      `}
      >
        {icon}
      </div>

      <Text level={3}>{label}</Text>
    </div>
  );
};
