import { useFileDownload, OnDownloadActions, GetFileAsync } from "shared/hooks/useFileDownload";
import { useCallback } from "react";
import { myFilesService } from "../client/myFiles.client";

const useMyFileDownload = () => {
  const getFileUrlWithUrl = useCallback(async (fileId: string) => {
    return await myFilesService.getFile(fileId, true);
  }, []);

  const getFileUrl: GetFileAsync = useCallback(async (fileId: string) => {
    const file = await myFilesService.getFile(fileId, true);
    return { fileName: file.name, fileUrl: file.blob?.getURL, fileExt: file.blob?.type };
  }, []);

  const { downloadFile, ...rest } = useFileDownload({ getFileAsync: getFileUrl });

  const download = useCallback(
    async (fileId: string, actions?: OnDownloadActions) => {
      downloadFile(fileId, { onSuccess: actions?.onSuccess, onError: actions?.onError });
    },
    [downloadFile],
  );

  return {
    download,
    getFileUrlWithUrl,
    ...rest,
  };
};

export default useMyFileDownload;
