import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import envConfig from "env-config";

interface ImageBankManifestItem {
  name: string;
  image: string;
  small_image: string;
}
export interface ImageBankItem {
  name: string;
  imageUrl: string;
  smallImageUrl: string;
}

interface UseImageBank {
  imageBank: ImageBankItem[];
  imageBankLoading: boolean;
}

const useImageBank = (): UseImageBank => {
  const fetchJson = () =>
    axios.get<ImageBankManifestItem[]>(
      `https://${envConfig.REACT_APP_STATIC_BASE_URL}/ImageBank/imageBankManifest.json`,
    );

  const { data, isLoading } = useQuery({
    queryKey: ["imageBank"],
    queryFn: fetchJson,
    staleTime: Infinity,
  });

  const imageBank: ImageBankItem[] = useMemo(
    () =>
      data?.data
        ? data.data.map((value: ImageBankManifestItem) => ({
            name: value.name,
            imageUrl: `https://${envConfig.REACT_APP_STATIC_BASE_URL}/ImageBank/ImgLarge/${value.image}`,
            smallImageUrl: `https://${envConfig.REACT_APP_STATIC_BASE_URL}/ImageBank/ImgSmall/${value.small_image}`,
          }))
        : [],
    [data],
  );

  return {
    imageBank,
    imageBankLoading: isLoading,
  };
};

export default useImageBank;
