import { userStore } from "@eolas-medical/core";
import { BaseAppRoute } from "Utilities/types";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

const TWELVE_HOURS_IN_MILLISECONDS = 12 * 60 * 60 * 1000;

function useCompleteProfileModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const history = useHistory();
  const { specialty } = userStore.userData;
  const { userID } = userStore;

  useEffect(() => {
    if (userID && !specialty) {
      const lastShownTime = localStorage.getItem(`completeProfileModalShownTime_${userID}`);

      // If the modal was never shown or if it was shown more than 12 hours ago, show the modal
      if (
        !lastShownTime ||
        Date.now() - parseInt(lastShownTime.split("_")[0], 10) >= TWELVE_HOURS_IN_MILLISECONDS
      ) {
        setModalIsOpen(true);
        // Store the current timestamp in local storage to track when the modal was last shown, with the user-specific key
        localStorage.setItem(`completeProfileModalShownTime_${userID}`, Date.now().toString());
      }
    }
  }, [specialty, userID]);

  const handleRedirect = () => {
    history.push(`/${BaseAppRoute.myProfile}/personal-details`, { openEditProfile: true });
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    // When the user dismisses the modal, calculate the new expiration time (12 hours in the future) and store it in local storage with the user-specific key
    const newExpirationTime = Date.now() + TWELVE_HOURS_IN_MILLISECONDS;
    localStorage.setItem(`completeProfileModalShownTime_${userID}`, newExpirationTime.toString());
  };

  return { modalIsOpen, onRedirect: handleRedirect, onCloseModal: handleCloseModal };
}

export default useCompleteProfileModal;
