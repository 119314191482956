import { FormEvent, RefObject, useRef } from "react";
import { WizardRef } from "./types";

export interface UseWizard<T extends string> {
  wizardControl: RefObject<WizardRef<T>>;
  submitWizard: (e?: FormEvent<HTMLElement>) => void;
}

const useWizard = <T extends string>(): UseWizard<T> => {
  const wizardRef = useRef<WizardRef<T>>(null);

  const submitWizard = (e?: React.FormEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }

    return wizardRef.current?.submit();
  };

  return {
    wizardControl: wizardRef,
    submitWizard,
  };
};

export default useWizard;
