import useRemoteIcons from "Hooks/useRemoteIcons";
import { Step, Wizard, useConfigureSectionStep, useWizard } from "UIKit/Wizard";
import useEditContentSection from "modules/contentRepository/data/useEditContentSection";
import { ContentSection, ContentRepositoryId } from "modules/contentRepository/types";

interface EditContentSectionModalProps {
  contentRepositoryId: ContentRepositoryId;
  contentSection: ContentSection;
  onClose: () => void;
}

const EditContentSectionWizard = ({
  contentRepositoryId,
  contentSection,
  onClose,
}: EditContentSectionModalProps) => {
  const { remoteIcons } = useRemoteIcons();
  const { wizardControl, submitWizard } = useWizard();
  const {
    editContentSection,
    editingContentSection,
    editContentSectionError,
  } = useEditContentSection();

  const handleSubmit = ({ name, iconName }: { name: string; iconName: string }) => {
    editContentSection(
      {
        parentID: contentRepositoryId,
        input: {
          id: contentSection.id,
          name,
          icon: iconName,
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  const editSectionStep = useConfigureSectionStep<"section">({
    title: "Edit new Section",
    stepName: "section",
    iconsList: remoteIcons,
    section: {
      name: contentSection.name || "",
      iconName: contentSection.iconName || "",
      iconUrl: contentSection.iconUrl || "",
    },
    isLoading: editingContentSection,
    error: editContentSectionError,
    onSubmit: handleSubmit,
    onEnter: submitWizard,
    onCancel: onClose,
  });

  const steps: Step<"section">[] = [editSectionStep];

  return <Wizard steps={steps} wizardControl={wizardControl} />;
};

export default EditContentSectionWizard;
