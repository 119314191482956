import { useMemo } from "react";
import * as yup from "yup";

export const useSectionValidationSchema = (
  validationItems: string[],
  requiredError: string,
  minLengthError: string,
  existsError: string,
) => {
  const sectionValidationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .required(requiredError)
          .min(3, minLengthError)
          .test("name-exists", existsError, function (newSection) {
            return !validationItems.some(
              (section) => section.toLowerCase() === newSection?.toLowerCase(),
            );
          }),
      }),
    [validationItems, existsError, minLengthError, requiredError],
  );

  return sectionValidationSchema;
};
