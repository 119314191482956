import { useTranslation } from "react-i18next";
import { Button, Checkbox, FormElement, InnerModalWrapper } from "UIKit";

import { appConfig } from "AppTypeConfig";
import { useForm } from "react-hook-form";
import { useCallback } from "react";

interface TnCModalProps {
  onSubmit(): void;
  isLoading: boolean;
}

export const TnCModal: React.FC<TnCModalProps> = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation();

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      termsAndContions: false,
      disclaimer: false,
    },
  });

  const handleUpdateCompletedTour = useCallback(async () => {
    onSubmit();
  }, [onSubmit]);

  const termsAndConditions = watch("termsAndContions");
  const disclaimer = watch("disclaimer");
  const isFormComplete = termsAndConditions && disclaimer;

  return (
    <InnerModalWrapper>
      <div className="overflow-y-auto" style={{ maxHeight: "80vh" }}>
        <div className="flex flex-col py-8 px-4 sm:px-20 space-y-6">
          <p data-testid="welcomeModalTitle" className="text-center font-bold text-lg">
            {t("departmentTour_TC_modal_title")}
          </p>

          <span>{t("departmentTour_TC_modal_description")}</span>

          <form
            className="flex flex-col space-y-6"
            onSubmit={handleSubmit(handleUpdateCompletedTour)}
          >
            <FormElement control={control} id="termsAndContions">
              {({ value, onChange }) => {
                return (
                  <Checkbox
                    id="tcCheck"
                    onChange={onChange}
                    disabled={isLoading}
                    checked={value || false}
                  >
                    {t("departmentTour_TC_read")}
                    <a
                      className="underline ml-1"
                      href={appConfig.termlyTermsOfService}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("departmentTour_TC_confirmTC")}
                    </a>
                  </Checkbox>
                );
              }}
            </FormElement>
            <FormElement control={control} id="disclaimer">
              {({ value, onChange }) => {
                return (
                  <Checkbox
                    id="disclaimerCheck"
                    onChange={onChange}
                    disabled={isLoading}
                    checked={value || false}
                  >
                    {t("departmentTour_TC_read")}
                    <a
                      className="underline mx-1"
                      href={appConfig.termlyDisclaimer}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("departmentTour_TC_confirmDisclaimer")}
                    </a>
                    {t("departmentTour_TC_and")}
                    <a
                      className="underline ml-1"
                      href={appConfig.termlyPrivacyPolicy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("departmentTour_TC_confirmPrivacyPolicy")}
                    </a>
                  </Checkbox>
                );
              }}
            </FormElement>

            <Button
              color="blue"
              type="submit"
              isLoading={isLoading}
              disabled={!isFormComplete}
              data-testid="TnCModalSubmit"
              className="self-center w-full sm:w-1/3 "
            >
              {t("departmentTour_TC_button")}
            </Button>
          </form>
        </div>
      </div>
    </InnerModalWrapper>
  );
};
