export type ChecklistItemType = "checkbox" | "text";

export type ChecklistSectionItem = {
  itemType: ChecklistItemType;
  itemTitle: string;
  itemInfo: string;
};

export type ChecklistSection = {
  expanded: boolean;
  checklistSectionName: string;
  sectionItems: ChecklistSectionItem[];
};

export type Checklist = {
  checklistName: string;
  checklistSections: ChecklistSection[];
  owners: ChecklistOwner[];
};

export type ChecklistBuilderForm = Checklist & {
  isAddingSection: boolean;
};

export enum ChecklistNotifications {
  IS_COMPLETED = "IS_COMPLETED",
  ISSUES_FLAGGED = "ISSUES_FLAGGED",
}

export interface ChecklistOwner {
  id: string;
  activeNotifications: ChecklistNotifications[];
}
