import { useTranslation } from "react-i18next";
import { useNotifications } from "Components/Notifications";
import useEditContentItem from "./useEditContentItem";
import { ContentItem } from "../types";

const useMarkAsFavorite = () => {
  const { t } = useTranslation();
  const {
    editContentItem,
    editingContentItem,
    editContentItemSuccess,
    editContentItemError,
  } = useEditContentItem();

  const { showNotification, updateNotification } = useNotifications();

  const markAsFavorite = async (contentItem: ContentItem, isFavorite: boolean) => {
    //backend is expecting false as string to remove favorite
    const editItem = {
      ...contentItem,
      highlighted: isFavorite ? new Date().toISOString() : "false",
    };

    const notification = showNotification({
      type: "loading",
    });

    await editContentItem({ contentItem, editItem });
    updateNotification({
      type: "success",
      id: notification.id,
      autoHideTimeout: 3000,
      description: isFavorite
        ? t("repository_item_favourite_add_success")
        : t("repository_item_favourite_remove_success"),
    });
  };

  return {
    markAsFavorite,
    isMarkingAsFavorite: editingContentItem,
    markAsFavoriteSuccess: editContentItemSuccess,
    markAsFavoriteError: editContentItemError,
  };
};

export default useMarkAsFavorite;
