import { useEffect, useState } from "react";

import { sectionStore } from "@eolas-medical/core";

export const useCalculatorWarning = (id: string) => {
  const [warning, setWarningMessage] = useState("");

  useEffect(() => {
    if (id) {
      const metadata = sectionStore.getSection(`SEC#${id}`).metadata ?? "{}";
      let parsedMetadata = typeof metadata === "string" ? JSON.parse(metadata) : metadata;
      if (typeof parsedMetadata === "string") {
        parsedMetadata = JSON.parse(parsedMetadata);
      }
      // TODO: DOES THIS ACTUALLY WORK??
      const { warningMessage } = parsedMetadata;
      setWarningMessage(warningMessage);
    }
  }, [id]);

  return {
    warning,
    setWarningMessage,
  };
};
