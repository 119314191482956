import { Assessor, EventStatus } from "modules/portfolio/types";
import { useCallback, useEffect, useState } from "react";
import useSendReminderAssessor from "modules/portfolio/data/useSendReminderAssessor";
import SendReminderButton from "Pages/MeTab/pages/Portfolio/components/SendReminderButton/SendReminderButton";
import { appConfig } from "AppTypeConfig";

interface SendReminderProps {
  eventId: string;
  eventStatus: EventStatus;
  assessor: Assessor;
}
const SendReminder = ({ eventId, eventStatus, assessor }: SendReminderProps) => {
  const [canSendReminder, setCanSendReminder] = useState(false);
  const { sendReminderAssessor, sendingReminderAssessor } = useSendReminderAssessor(eventId);

  useEffect(() => {
    setCanSendReminder(assessor?.canRemind || false);
  }, [assessor]);

  const handleSendReminder = useCallback(() => {
    sendReminderAssessor(
      {
        eventId,
        reminderBody: {
          userEmail: assessor.email,
          appType: appConfig.appType,
        },
      },
      { onSuccess: () => setCanSendReminder(false) },
    );
  }, [sendReminderAssessor, eventId, assessor]);

  if (!assessor || eventStatus !== EventStatus.PENDING_REVIEW) {
    return null;
  }

  return (
    <SendReminderButton
      onClick={handleSendReminder}
      isLoading={sendingReminderAssessor}
      isReminderSent={!canSendReminder}
    />
  );
};

export default SendReminder;
