import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "UIKit";
import { ScrollIcon } from "Assets";

export const useIsScrollable = <T extends HTMLElement = HTMLDivElement>() => {
  const { t } = useTranslation();
  const ref = React.useRef<T>(null);
  const [isScrollable, setIsScrollable] = React.useState(false);

  const ScrollHint = React.useMemo((): React.FC => {
    if (!isScrollable) return () => null;

    return ({ children }) => (
      <div className="flex items-center space-x-2 text-grey-500">
        <ScrollIcon className="w-6 h-6" />
        <Text level={3}>{children ?? t("scroll_hint_label")}</Text>
      </div>
    );
  }, [t, isScrollable]);

  // eslint-disable-next-line
  React.useLayoutEffect(() => {
    const container = ref.current;

    if (!container) return;

    if (container!.scrollHeight > container!.clientHeight) {
      setIsScrollable(true);
    }
  });

  return {
    ref,
    ScrollHint,
    isScrollable,
  };
};
