import { useForm } from "react-hook-form";
import { useCallback, useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { sectionStore, userStore, AnalyticsEvents, fetchAllData } from "@eolas-medical/core";

import { useFileOperations } from "Hooks";
import { trackEvent } from "API/Analytics";
import { fromAwsJSON, toAwsJSON, useRequestStatus, removeLastUrlPath } from "Utilities";

import {
  ChecklistInputParams,
  ChecklistSubmissionForm,
  ChecklistSubmissionInput,
  ChecklistSubmissionSection,
} from "../types";
import { Checklist } from "../../checklistBuilder/types";

type ChecklistSubmissionModalState = "confirmSubmission" | "confirmExit" | null;

// #region Mappers
const mapToDefaultValues = (template: Checklist): ChecklistSubmissionForm => {
  const sections: ChecklistSubmissionSection[] = template.checklistSections.map((section) => {
    return {
      expanded: true,
      skipped: false,
      isIncomplete: false,
      values: section.sectionItems.map((item) => (item.itemType === "text" ? "" : false)),
    };
  });

  const defaultValues: ChecklistSubmissionForm = {
    sections,
    additionalInfo: "",
    secondTeamMember: "",
    checklistIssues: false,
  };

  return defaultValues;
};

const mapToChecklistInput = ({
  values,
  template,
  checklistID,
  userID,
}: ChecklistInputParams): ChecklistSubmissionInput => {
  const completedChecklist = {
    ...template,
    additionalInfo: values.additionalInfo,
    checklistIssues: values.checklistIssues,
    secondTeamMember: values.secondTeamMember,
    checklistSections: template.checklistSections.map((section, sectionIndex) => {
      return {
        ...section,
        skipped: values.sections[sectionIndex].skipped,
        sectionItems: section.sectionItems.map((item, itemIndex) => {
          const itemValue = values.sections[sectionIndex].values[itemIndex];

          return item.itemType === "text"
            ? {
                ...item,
                inputInfo: itemValue,
              }
            : {
                ...item,
                checked: itemValue,
              };
        }),
      };
    }),
  };

  return {
    name: "",
    key: "",
    moreInfo: checklistID,
    type: "completedChecklist",
    metadata: toAwsJSON({ completedChecklist }),
    info: `${userStore.userData.givenName} ${userStore.userData.familyName}`,
    moreInfoTwo: userID,
  };
};
// #endregion

export const useChecklistSubmission = (checklistID: string) => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const { publishFile } = useFileOperations();
  const { error, isLoading, isSuccessful, setRequestStatus } = useRequestStatus();
  const { userID = "" } = userStore;

  const [modalState, setModalState] = useState<ChecklistSubmissionModalState>(null);
  const onCloseModal = useCallback(() => setModalState(null), [setModalState]);

  const { metadata } = sectionStore.getSection(checklistID);
  const { checklistTemplate: template }: { checklistTemplate: Checklist } =
    typeof metadata === "string" ? fromAwsJSON(metadata ?? '"{}"') : metadata;

  const formMethods = useForm<ChecklistSubmissionForm>({
    defaultValues: mapToDefaultValues(template),
  });

  const { setValue, handleSubmit } = formMethods;

  const onConfirmExit = () => {
    push(removeLastUrlPath(url, 2));
    trackEvent(AnalyticsEvents.CHECKLIST_SUBMISSION_CANCELLED, { checklistID });
  };

  const onLeaveChecklist = () => {
    if (formMethods.formState.isDirty) {
      setModalState("confirmExit");
    } else {
      push(removeLastUrlPath(url, 2));
    }
  };

  const submitChecklist = async (values: ChecklistSubmissionForm) => {
    const input = mapToChecklistInput({ checklistID, template, values, userID });

    setRequestStatus({ status: "pending", error: "" });
    try {
      await publishFile({
        variables: {
          input,
          parentID: checklistID,
        },
      });
      trackEvent(AnalyticsEvents.CHECKLIST_SUBMISSION_COMPLETED, { checklistID });
      await fetchAllData();
      setRequestStatus({ status: "success", error: "" });
    } catch (error: any) {
      setRequestStatus({ status: "error", error: error.message });
    }
  };

  const onSubmit = handleSubmit((values) => {
    let hasIncompleteSections = false;

    values.sections.forEach((section, sectionIndex) => {
      if (section.skipped) return;

      const isIncomplete = section.values.some((v) => !v);

      if (isIncomplete) {
        hasIncompleteSections = true;
        setValue(`sections.${sectionIndex}.isIncomplete`, true);
      }
    });

    if (hasIncompleteSections) {
      setModalState("confirmSubmission");
    } else {
      submitChecklist(values);
    }
  });

  useEffect(() => {
    // since formState is wrapped with a Proxy, this log is needed to subscribe to the form state changes
    // https://react-hook-form.com/api/useform/formstate/
    console.log("Checklist submission ongoing", formMethods.formState.isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formMethods.formState.isDirty) {
      trackEvent(AnalyticsEvents.CHECKLIST_SUBMISSION_STARTED, { checklistID });
    }
  }, [checklistID, formMethods.formState.isDirty]);

  return {
    error,
    template,
    isLoading,
    modalState,
    formMethods,
    isSuccessful,
    onSubmit,
    onCloseModal,
    onConfirmExit,
    submitChecklist,
    onLeaveChecklist,
  };
};
