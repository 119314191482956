import { Step } from "UIKit";
import VerificationCodeModal from "./VerificationCodeModal";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

interface UseVerificationCodeStep {
  email: string;
  canProceed: boolean;
  isLoading: boolean;
  error?: string;
  onSubmit: (code: string) => void;
  onResendCode: () => void;
  onEnter: () => void;
}

const useVerificationCodeStep = ({
  email,
  canProceed,
  isLoading,
  error,
  onSubmit,
  onResendCode,
  onEnter,
}: UseVerificationCodeStep): Step<"verification-code"> => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    code: yup
      .string()
      .trim()
      .length(6, t("createAccount_verifyAccountErrorLength"))
      .required(t("createAccount_verifyAccountErrorRequired")),
  });

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      code: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitVerificationCode = () => {
    const code = getValues("code");
    onSubmit(code);
  };

  return {
    name: "verification-code",
    title: t("createAccount_verifyText"),
    subtitle: t("createAccount_verifyText_subtitle", { email }),
    component: (
      <VerificationCodeModal
        control={control}
        onEnter={onEnter}
        onResendCode={onResendCode}
        error={error}
      />
    ),
    onSubmit: handleSubmit(handleSubmitVerificationCode),
    canProceed,
    isLoading,
    submitError: !!error,
    onlySubmitStep: true,
  };
};

export default useVerificationCodeStep;
