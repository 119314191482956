import { overrideTailwindClasses as tw } from "tailwind-override";

export const InnerPageWrapper: React.FC<{ className?: string }> = ({
  children,
  className = "",
}) => (
  <div
    className={tw(`
      w-full lg:w-4/5 flex-1 max-w-5xl
      px-4 sm:px-12 md:px-20 lg:px-0 pt-8 pb-28 lg:pb-8
      flex flex-col mx-auto space-y-6 lg:space-y-12 ${className}
    `)}
  >
    {children}
  </div>
);
