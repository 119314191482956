import { useState, useCallback } from "react";
import { FileSection } from "modules/myFiles/types";
import SectionTile from "../SectionTile/SectionTile";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Modal } from "UIKit";
import EditFileSectionModal from "../EditFileSectionModal/EditFileSectionModal";
import DeleteFileSectionModal from "../DeleteFileSectionModal/DeleteFileSectionModal";
import { useTranslation } from "react-i18next";

interface SectionItemProps {
  section: FileSection;
  "data-testid"?: string;
}

const SectionListItem = ({ section, "data-testid": dataTestId }: SectionItemProps) => {
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const [modal, setModal] = useState<React.ReactNode>(null);
  const { t } = useTranslation();

  const handeleCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const handleClickSection = useCallback(
    (section: FileSection) => {
      push(`${url}/${section.id}`);
    },
    [push, url],
  );

  const handleEditSection = () => {
    setModal(<EditFileSectionModal section={section} onClose={handeleCloseModal} />);
  };

  const handleDeleteSection = () => {
    setModal(<DeleteFileSectionModal section={section} onClose={handeleCloseModal} />);
  };

  return (
    <>
      <Modal open={!!modal} onClose={handeleCloseModal}>
        {modal}
      </Modal>
      <div id={section.id} data-testid="section-list-item">
        <SectionTile
          isDraggable={!section.isDefault}
          section={section}
          fileCount={section.fileCount}
          onClick={handleClickSection}
          disabledDeleteText={t("my_files_disabled_delete_section_tooltip", {
            fileCount: section.fileCount,
            plural: section.fileCount !== 1 ? "s" : "",
          })}
          onEdit={!section.isDefault ? handleEditSection : undefined}
          onDelete={!section.isDefault ? handleDeleteSection : undefined}
          data-testid={dataTestId}
        />
      </div>
    </>
  );
};

export default SectionListItem;
