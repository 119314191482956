import { useCallback, useState } from "react";
import { sectionStore } from "@eolas-medical/core";

import { useSelectFile, useCompleteFile } from "Hooks";
import { EolasFile, NewsItemFileType } from "Utilities/types";

export const useNewsItem = (newsItem: EolasFile) => {
  const { onCompleteFile } = useCompleteFile();
  const { error, isLoading, onSelectFile: selectFile } = useSelectFile("requestStatus");

  const { type = "" } = newsItem;
  const [videoURL, setVideoURL] = useState("");

  const onSelectFile = useCallback(() => {
    const isAdmin = sectionStore.isAdmin;

    selectFile(newsItem).then((url) => {
      if (type === NewsItemFileType.newsFeedMp4) {
        setVideoURL(url);
      }
      if (!isAdmin) {
        onCompleteFile(newsItem.id, "seen");
      }
    });
  }, [selectFile, newsItem, type, onCompleteFile]);

  const shouldShowPlayer = type === NewsItemFileType.newsFeedMp4 && videoURL;

  return {
    error,
    videoURL,
    isLoading,
    shouldShowPlayer,
    setVideoURL,
    onSelectFile,
  };
};
