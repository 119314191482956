import { useTranslation } from "react-i18next";

import { error } from "Assets/LottieAnimations";
import { LottieWithHeader, InnerModalWrapper, Text } from "UIKit";

import "../styles.css";

export const ServicesStatusAlertModal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <InnerModalWrapper>
      <div className="flex flex-col justify-center items-center p-4 lg:p-12">
        <LottieWithHeader
          animationSize="20%"
          text={""}
          animation={error}
          lottieOptions={{ loop: true }}
        />
        <div className="text-center flex flex-col">
          <Text level={2} className="mb-3">
            {t("services_check_alert_body_1")}
          </Text>
          <Text level={2} className="mb-3">
            {t("services_check_alert_body_2")}
          </Text>
          <Text level={2} className="mb-3 underline">
            <a href="mailto:support@eolasmedical.com">support@eolasmedical.com</a>
          </Text>
          <Text level={2} className="font-semibold underline">
            <a
              href="https://support.eolasmedical.com/en/articles/7924578-finding-and-joining-a-department"
              target="_blank"
              rel="noreferrer"
            >
              {t("services_check_alert_body_3")}
            </a>
          </Text>
        </div>
      </div>
    </InnerModalWrapper>
  );
};
