import React from "react";
import { LayoutGroup, motion } from "framer-motion";

import { useMediaQuery } from "Hooks";
import { LDNotificationBanner } from "Components/Notifications/LDNotificationBanner";
import { FooterProps } from "UIKit/PageElements/Footer";

import { useNotificationsBanner } from "./hooks";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { Navigation } from "Components/Navigation";
import { GradientBackdrop } from "Components/GradientBackdrop/GradientBackdrop";
import { CopyrightAndVersion } from "Components/CopyrightAndVersion/CopyrightAndVersion";

export interface PageContainerProps extends FooterProps {}

export const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  const { notificationText, bannerNotSeen, setBannerNotSeen } = useNotificationsBanner();
  const { drawerExpanded } = useEolasNavigation();
  const media = useMediaQuery();

  const isDesktop = media === "lg" || media === "xl" || media === "2xl";

  return (
    <GradientBackdrop>
      <LayoutGroup>
        <Navigation />

        <motion.main
          layout
          // we need this to set the virtual list scroll parent
          id="page-container"
          className="flex flex-col flex-1 h-100vh overflow-y-auto"
          transition={{ type: "spring", stiffness: 98, damping: 13 }}
        >
          {children}

          {isDesktop && <CopyrightAndVersion className="mt-auto pb-4" />}
          {notificationText && bannerNotSeen && (
            <LDNotificationBanner
              drawerExpanded={drawerExpanded}
              onClose={() => setBannerNotSeen(false)}
              text={notificationText}
            />
          )}
        </motion.main>
      </LayoutGroup>
    </GradientBackdrop>
  );
};
