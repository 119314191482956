import { useEffect, useMemo } from "react";

import { accessClient } from "@eolas-medical/core";

import { appConfig } from "AppTypeConfig";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import spacesKeys from "./spaces.queryKeys";

export const useSpaceList = (userId: string) => {
  const queryClient = useQueryClient();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: spacesKeys.list(),
    queryFn: () => accessClient.getUserDepartments(userId, appConfig.appType),
    enabled: !!userId,
    staleTime: 30000,
  });

  const spaceList = useMemo(() => {
    if (!data) return [];

    return [...data.departments]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item) => ({
        accessLevel: item.role,
        app: {
          appID: item.id,
          orgID: item.organisationID,
          name: item.name,
        },
      }));
  }, [data]);

  useEffect(() => {
    if (userId) {
      refetch();
    }
  }, [queryClient, userId, refetch]);

  return {
    spaceList,
    spacesLoading: isLoading,
    error,
    onRefreshSpaces: () => refetch(),
  };
};
