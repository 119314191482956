import React from "react";
import { AccessLevel, accessClient, sectionStore } from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";

export interface UpdateAccessLevelParams {
  userId: string;
  accessLevel: AccessLevel;
  limitedAccess?: string[];
}

export const useAdminEntry = (refetchUsers: any) => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();

  const onUpdateAccessLevel = React.useCallback(
    async ({ userId, accessLevel, limitedAccess }: UpdateAccessLevelParams) => {
      setRequestStatus({ status: "pending", error: "" });

      try {
        await accessClient.setUserAccessLevel({
          departmentId: sectionStore.appID,
          userId,
          accessLevel,
          limitedAccess,
        });
        await refetchUsers();
        setRequestStatus({ status: "success", error: "" });
      } catch (err: any) {
        errorStore.captureError({ error: err.message, source: "user" });
        setRequestStatus({ status: "error", error: err.message });
      }
    },
    [setRequestStatus, refetchUsers],
  );

  const onUpdateLimitedAccess = React.useCallback(
    async (userId: string, limitedAccess: string[]) => {
      setRequestStatus({ status: "pending", error: "" });

      try {
        await accessClient.updateLimitedAccess({
          departmentId: sectionStore.appID,
          userId,
          limitedAccess,
        });
        await refetchUsers();
        setRequestStatus({ status: "success", error: "" });
      } catch (err: any) {
        errorStore.captureError({ error: err.message, source: "user" });
        setRequestStatus({ status: "error", error: err.message });
      }
    },
    [setRequestStatus, refetchUsers],
  );

  return {
    error,
    isLoading,
    onUpdateAccessLevel,
    onUpdateLimitedAccess,
  };
};
