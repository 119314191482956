import { fileStore, sectionStore } from "@eolas-medical/core";
import { ContentItem, ContentRepositoryId } from "../types";
import { mapToContentItem } from "../helpers";

interface UseContentSectionItemsProps {
  contentRepositoryId: ContentRepositoryId;
  subSectionId?: string;
}

interface UseContentSectionItems {
  contentSectionItems: ContentItem[];
  contentSectionItemsLoading: false;
}
const useContentSectionItems = ({
  contentRepositoryId,
  subSectionId,
}: UseContentSectionItemsProps): UseContentSectionItems => {
  const childrenOrder = sectionStore.getChildrenOrder(subSectionId || contentRepositoryId);
  const items = fileStore.getFiles(childrenOrder ?? []).map(mapToContentItem);

  return {
    contentSectionItems: items,
    contentSectionItemsLoading: false,
  };
};

export default useContentSectionItems;
