import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  InnerModalWrapper,
  ModalBody,
  Title,
  ModalHeader,
  Text,
  Textarea,
  Button,
  FormElement,
} from "UIKit";

export interface DeleteAccountModalProps {
  onClose: () => void;
  isLoading: boolean;
  titleText: string;
  subtitleText: string;
  confirmText: string;
  cancelText: string;
  placeholderText: string;
  onSubmit: (deletionReason: string) => void;
}

const DeleteAccountModal = ({
  titleText,
  subtitleText,
  confirmText,
  cancelText,
  placeholderText,
  onClose,
  isLoading = false,
  onSubmit,
}: DeleteAccountModalProps) => {
  const { control, register, handleSubmit, setFocus } = useForm({
    defaultValues: {
      deletionReason: "",
    },
  });

  const handleDeleteAccount = useCallback(
    ({ deletionReason }) => {
      onSubmit(deletionReason);
    },
    [onSubmit],
  );

  useEffect(() => {
    setFocus("deletionReason");
  }, [setFocus]);

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <Title level={5}>{titleText}</Title>
        <Text level={1} className="text-center text-grey-700">
          {subtitleText}
        </Text>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(handleDeleteAccount)} style={{ maxHeight: "40rem" }}>
          <FormElement required id="deletionReason" control={control}>
            {(fieldProps) => {
              const { value, ...rest } = fieldProps;
              return (
                <Textarea
                  {...register("deletionReason")}
                  {...rest}
                  placeholder={placeholderText}
                  value={value}
                  className="resize-none"
                />
              );
            }}
          </FormElement>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
            <Button
              type="button"
              color="grey"
              onClick={onClose}
              variant="outline"
              className="order-1 sm:order-0"
              data-testid="cancel-button"
            >
              {cancelText}
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              variant="solid"
              color="red"
              className="order-0 sm:order-1"
              data-testid="done-button"
              disabled={isLoading}
            >
              {confirmText}
            </Button>
          </div>
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
export default DeleteAccountModal;
