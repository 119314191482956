import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { useIsScrollable } from "Hooks";
import { CheckCircleIcon, TickChecklistIcon, TextChecklistIcon } from "Assets";

import {
  Input,
  Title,
  Button,
  ModalBody,
  FormElement,
  ModalHeader,
  InputTypeButton,
  InnerModalWrapper,
} from "UIKit";

import { ChecklistSectionItem } from "../types";
import { sectionItemSchema } from "../validationSchemas";

type AddChecklistForm = Omit<ChecklistSectionItem, "id">;

interface AddChecklistItemModalProps {
  checklistItem?: ChecklistSectionItem;
  onCancel(): void;
  onSubmit(item: ChecklistSectionItem): void;
}

const mapToDefaultValues = (checklistItem?: ChecklistSectionItem): AddChecklistForm => {
  return {
    itemInfo: checklistItem?.itemInfo ?? "",
    itemTitle: checklistItem?.itemTitle ?? "",
    itemType: checklistItem?.itemType ?? "checkbox",
  };
};

export const AddChecklistItemModal: React.FC<AddChecklistItemModalProps> = ({
  onCancel,
  onSubmit,
  checklistItem,
}) => {
  const { t } = useTranslation();
  const { ref, ScrollHint } = useIsScrollable();
  const { control, handleSubmit } = useForm<AddChecklistForm>({
    resolver: yupResolver(sectionItemSchema),
    defaultValues: mapToDefaultValues(checklistItem),
  });

  const _onSubmit = () => {
    handleSubmit((values) => {
      onSubmit(values);
      onCancel();
    })();
  };

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{checklistItem ? "Edit Item" : "Add New Item"}</Title>
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="add-file__modal-body">
        <form className="add-file-form space-y-2">
          <div className="add-file-form__content max-h-60vh" ref={ref}>
            <FormElement
              required
              id="itemType"
              control={control}
              label={t("checklistBuilder_item_type_label")}
            >
              {({ onChange, value }) => {
                return (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <InputTypeButton
                      data-testid={`checkbox-icon`}
                      isSelected={value === "checkbox"}
                      onClick={() => onChange("checkbox")}
                      className={value === "checkbox" ? "shadow-md" : ""}
                      icon={<TickChecklistIcon width={32} height={32} />}
                    >
                      {t("iconPicker_checkbox")}
                    </InputTypeButton>
                    <InputTypeButton
                      data-testid={`text-icon`}
                      isSelected={value === "text"}
                      onClick={() => onChange("text")}
                      className={value === "text" ? "shadow-md" : ""}
                      icon={<TextChecklistIcon width={32} height={32} />}
                    >
                      {t("iconPicker_text")}
                    </InputTypeButton>
                  </div>
                );
              }}
            </FormElement>

            <FormElement
              required
              id="itemTitle"
              control={control}
              label={t("checklistBuilder_item_title_label")}
            >
              {(field, { className }) => (
                <Input
                  {...field}
                  size="lg"
                  className={`${className} bg-grey-50 hover:bg-white`}
                  placeholder={t("checklistBuilder_item_title_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              id="itemInfo"
              control={control}
              label={t("checklistBuilder_item_info_label")}
            >
              {(field, { className }) => (
                <Input
                  {...field}
                  size="lg"
                  className={`${className} bg-grey-50 hover:bg-white`}
                  placeholder={t("checklistBuilder_item_info_placeholder")}
                />
              )}
            </FormElement>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
            <Button size="lg" color="grey" variant="outline" type="button" onClick={onCancel}>
              {t("general_cancel")}
            </Button>

            <Button size="lg" type="button" onClick={_onSubmit}>
              <CheckCircleIcon className="w-6 h-6 mr-3" />
              {t(
                checklistItem
                  ? "checklistBuilder_edit_item_button"
                  : "checklistBuilder_add_item_button",
              )}
            </Button>
          </div>
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
