import { ProfessionalBody, ProfileVerificationStatus } from "@eolas-medical/core";

export const ProfileStatusLabel = {
  [ProfileVerificationStatus.UNVERIFIED]: "Not Verified",
  [ProfileVerificationStatus.PENDING]: "Pending Verification",
  [ProfileVerificationStatus.VERIFIED]: "Verified",
};

export const ProfessionalBodyLabel = {
  [ProfessionalBody.GENERAL_MEDICAL_COUNCIL]: "General Medical Council",
  [ProfessionalBody.NURSING_AND_MIDWIFERY_COUNCIL]: "Nursing and Midwifery Council",
  [ProfessionalBody.GENERAL_PHARMACEUTICAL_COUNCIL]: "General Pharmaceutical Council",
};
