import { useMemo } from "react";
import { EventStatus, FeedbackStatus, SupervisionStatus } from "modules/portfolio/types";
import { Pill } from "UIKit/Pill";
import { Sizes } from "UIKit/types";

interface StatusBadgeProps {
  status?: EventStatus | FeedbackStatus | SupervisionStatus;
  hasBorder?: boolean;
  size?: Sizes;
}
const StatusBadge = ({ status, hasBorder, size }: StatusBadgeProps) => {
  const { badgeColors, text }: { badgeColors: string; text: string } = useMemo(() => {
    switch (status) {
      case EventStatus.IN_PROGRESS:
        return { badgeColors: "bg-blue-50 border-blue-600 text-blue-600", text: "New" };
      case SupervisionStatus.PENDING:
        return { badgeColors: "bg-yellow-100 text-yellow-700", text: "Pending" };
      case EventStatus.PENDING_REVIEW:
        return {
          badgeColors: "bg-yellow-50 border-yellow-400 text-yellow-400",
          text: "Pending",
        };
      case FeedbackStatus.REVIEW_IN_PROGRESS:
        return {
          badgeColors: "bg-yellow-50 border-yellow-400 text-yellow-400",
          text: "Pending Review",
        };
      case FeedbackStatus.COMPLETE:
      case EventStatus.COMPLETE:
        return {
          badgeColors: "bg-green-50 border-green-500 text-green-500",
          text: "Completed",
        };
      case SupervisionStatus.DECLINED:
      case EventStatus.DECLINED:
        return { badgeColors: "bg-red-100 border-red-600 text-red-600", text: "Declined" };
      case SupervisionStatus.APPROVED:
        return { badgeColors: "bg-green-50 text-green-500", text: "Approved" };
      default:
        return { badgeColors: "bg-blue-50 text-blue-600", text: "New" };
    }
  }, [status]);

  const border = hasBorder ? "border" : "";

  return (
    <div className="text-center" data-testid="event-status-badge">
      <Pill size={size} value={text} className={`${badgeColors} ${border}`} />
    </div>
  );
};

export default StatusBadge;
