import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { InputTypeButton, FormLabel } from "UIKit";
import { CallIcon, EmailOpenedIcon, LandlineIcon, PagerIcon } from "Assets";

import { ContactType } from "../types";

const iconSize = { width: 32, height: 32 };

export const SelectContactType: React.FC = () => {
  const { t } = useTranslation();
  const { setValue, trigger, watch } = useFormContext();

  const fileType = watch("type");

  const setContactType = (type: ContactType) => () => {
    setValue("type", type);
    setValue("hasExpiryDate", false);
    setValue("info", "");
    setValue("description", "");
    trigger();
  };

  return (
    <div className="flex flex-col space-y-2 mb-4">
      <FormLabel htmlFor="" required>
        {t("contacts_select_type")}
      </FormLabel>

      <div className="grid grid-cols-2 gap-2 sm:gap-4">
        <InputTypeButton
          isSelected={fileType === "email"}
          onClick={setContactType("email")}
          icon={<EmailOpenedIcon {...iconSize} />}
        >
          {t("contacts_modal_addNew_email")}
        </InputTypeButton>

        <InputTypeButton
          icon={<LandlineIcon {...iconSize} />}
          isSelected={fileType === "extension"}
          onClick={setContactType("extension")}
        >
          {t("contacts_modal_addNew_extension")}
        </InputTypeButton>

        <InputTypeButton
          icon={<CallIcon {...iconSize} />}
          isSelected={fileType === "telephone"}
          onClick={setContactType("telephone")}
        >
          {t("contacts_modal_addNew_telephone")}
        </InputTypeButton>

        <InputTypeButton
          icon={<PagerIcon {...iconSize} />}
          isSelected={fileType === "pager"}
          onClick={setContactType("pager")}
        >
          {t("contacts_modal_addNew_pager")}
        </InputTypeButton>
      </div>
    </div>
  );
};
