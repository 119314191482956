import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { EolasFile, AppLevelSection, OrganisationLevelSection } from "@eolas-medical/core";

import { DeleteFileModal, EolasFileList } from "Components";
import { useMainSectionVisit } from "Hooks";
import { AddButton, NavButton, PageTitle, Modal, InnerPageWrapper } from "UIKit";
import { SubSectionRouteParams } from "Utilities/types";

import { AddContactModal, ContactDetailsModal } from "../components";
import { useEolasNavigation } from "Components/Navigation/hooks";
import useSpaceSubSection from "modules/spaces/data/useSpaceSubSection";

export const AdminContactsSection: React.FC<RouteComponentProps<SubSectionRouteParams>> = observer(
  ({ match: { url }, location: { pathname } }) => {
    const { t } = useTranslation();
    const { activeTab } = useEolasNavigation();
    const [modal, setModal] = useState<React.ReactNode>(null);
    const onCloseModal = () => setModal(null);

    const isOrganisationTab = activeTab === "organisation";
    const mainSectionID = isOrganisationTab
      ? OrganisationLevelSection.hospitalContacts
      : AppLevelSection.contacts;

    const { spaceSubSection } = useSpaceSubSection(mainSectionID);

    useMainSectionVisit({
      mainSectionId: mainSectionID,
      subSectionId: spaceSubSection.subSectionId,
    });

    const onAddNewContact = () => {
      setModal(
        <AddContactModal
          sectionID={spaceSubSection.subSectionId}
          onCloseModal={onCloseModal}
          mainSectionID={mainSectionID}
        />,
      );
    };

    const onEditContact = (contact: EolasFile) => {
      setModal(
        <AddContactModal
          contact={contact}
          sectionID={spaceSubSection.subSectionId}
          onCloseModal={onCloseModal}
          mainSectionID={mainSectionID}
        />,
      );
    };

    const onDeleteContact = (contact: EolasFile) => {
      setModal(<DeleteFileModal file={contact} onCloseModal={onCloseModal} />);
    };

    const onSelectFile = useCallback((contact: EolasFile) => {
      setModal(<ContactDetailsModal contact={contact} />);
    }, []);

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSubSection.subSectionName ?? ""} />
          <NavButton to={url.replace(`/${spaceSubSection.encodedSubSectionId}`, "")} />

          <AddButton onClick={onAddNewContact} data-testid="add-new-contact">
            {t("contacts_add_new_contact_button")}
          </AddButton>

          <EolasFileList
            isAdmin
            defaultSort="alphabetical"
            draggable={false}
            onEdit={onEditContact}
            subSectionId={spaceSubSection.subSectionId}
            onDelete={onDeleteContact}
            onSelectFile={onSelectFile}
            onAddItem={onAddNewContact}
            mainSectionId={mainSectionID}
            infoText={t("contacts_contact_order")}
          />
        </InnerPageWrapper>

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </>
    );
  },
);
