import { AuthFunctionNames, authenticationClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { errorStore } from "Stores/ErrorStore";

const useValidateUserEmail = () => {
  const { mutate, isLoading, error, isSuccess, reset } = useMutation(
    async (email: string) => {
      const { exists, emailVerified } = await authenticationClient.verifyEmail(email);
      return { exists, userConfirmed: emailVerified };
    },
    {
      onError: (error: string) => {
        errorStore.captureError({
          error,
          source: "user",
          authFunctionName: AuthFunctionNames.login,
        });
      },
    },
  );

  return {
    validateUserEmail: mutate,
    validatingUserEmail: isLoading,
    validateUserEmailError: error || undefined,
    validateUserEmailSuccess: isSuccess,
    resetValidateUserEmail: reset,
  };
};

export default useValidateUserEmail;
