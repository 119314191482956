import { useMutation } from "@tanstack/react-query";
import {
  Space,
  SpaceCreateDto,
  accessClient,
  fileStore,
  getAllFilesByMainSections,
  profilesClient,
  sectionStore,
  spacesClient,
  userStore,
} from "@eolas-medical/core";

interface CustomError {
  message: string;
}

// A function to determine if the error conforms to CustomError
function isCustomError(error: any): error is CustomError {
  return error && typeof error.message === "string";
}

const useCreateSpace = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async (spaceDto: SpaceCreateDto) => {
      return spacesClient.createSpace(spaceDto);
    },
    {
      onSuccess: async (newSpace: Space) => {
        const { data } = await accessClient.shouldAllowUserAccess(newSpace.id);
        const { appUser, sections, app, organisation } = data;
        sectionStore.setStoreFromApi({ app, organisation, sections }, true);
        const [refreshedProfile, files] = await Promise.all([
          profilesClient.getUserProfile(userStore.userID!),
          getAllFilesByMainSections(),
        ]);

        userStore.signInOrganisation({ appUser, user: refreshedProfile });

        fileStore.upsertFiles(files, true);
      },
    },
  );

  const errorCreatingSpace = isCustomError(error) ? error : undefined;

  return {
    createSpace: mutate,
    creatingSpace: isLoading,
    errorCreatingSpace: errorCreatingSpace?.message ?? "",
    createSpaceSuccess: isSuccess,
  };
};

export default useCreateSpace;
