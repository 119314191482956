import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, RouteComponentProps } from "react-router-dom";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";
import PortfolioErrorFallback from "./components/PortfolioErrorFallaback/PortfolioErrorFallback";
import { PortfolioAndFeedback } from "./pages/PortfolioAndFeedback";
import { EventList } from "./pages/MyPortfolio/pages/EventList/EventList";
import { EventDetail } from "./pages/MyPortfolio/pages/EventDetail/EventDetail";
import { FeedbackList } from "./pages/Feedback/pages/FeedbackList/FeedbackList";
import { FeedbackDetail } from "./pages/Feedback/pages/FeedbackDetail/FeedbackDetail";
import { FileViewer } from "Pages/MeTab/pages/MyFiles/pages/Files/FileViewer";
import { AttachmentFileViewer } from "./components/AttachmentFileViewer/AttachmentFileViewer";
import { Supervision } from "./pages/Supervision/Supervision";
import { SuperviseesList, SupervisorsList } from "./pages/Supervision";
import { SuperviseeEventList } from "./pages/Supervision/pages/SuperviseeEventList/SuperviseeEventList";

const Portfolio: React.FC<RouteComponentProps> = ({ match: { path } }) => {
  const {
    MY_PORTFOLIO_URL,
    EVENT_TYPE_PARAM,
    EVENT_ID_PARAM,
    FEEDBACK_URL,
    SUPERVISION_URL,
    SUPERVISORS_URL,
    SUPERVISEES_URL,
    SUPERVISEE_ID_PARAM,
    SUPERVISEE_NAME_PARAM,
  } = PortfolioConstants;

  return (
    <ErrorBoundary FallbackComponent={PortfolioErrorFallback}>
      <Route exact path={`${path}`} component={PortfolioAndFeedback} />

      {/* Events */}
      <Route exact path={`${path}/${MY_PORTFOLIO_URL}`} component={EventList} />
      <Route
        exact
        path={`${path}/${MY_PORTFOLIO_URL}/:${EVENT_TYPE_PARAM}`}
        component={EventDetail}
      />
      <Route
        exact
        path={`${path}/${MY_PORTFOLIO_URL}/:${EVENT_TYPE_PARAM}/:${EVENT_ID_PARAM}`}
        component={EventDetail}
      />

      {/* Feedback */}
      <Route exact path={`${path}/${FEEDBACK_URL}`} component={FeedbackList} />
      <Route
        exact
        path={`${path}/${FEEDBACK_URL}/:${EVENT_TYPE_PARAM}/:${EVENT_ID_PARAM}`}
        component={FeedbackDetail}
      />

      {/* Supervision */}
      <Route exact path={`${path}/${SUPERVISION_URL}`} component={Supervision} />
      <Route
        exact
        path={`${path}/${SUPERVISION_URL}/${SUPERVISORS_URL}`}
        component={SupervisorsList}
      />
      <Route
        exact
        path={`${path}/${SUPERVISION_URL}/${SUPERVISEES_URL}`}
        component={SuperviseesList}
      />
      <Route
        exact
        path={`${path}/${SUPERVISION_URL}/${SUPERVISEES_URL}/:${SUPERVISEE_ID_PARAM}/:${SUPERVISEE_NAME_PARAM}`}
        component={SuperviseeEventList}
      />
      <Route
        exact
        path={`${path}/${SUPERVISION_URL}/${SUPERVISEES_URL}/:${SUPERVISEE_ID_PARAM}/:${SUPERVISEE_NAME_PARAM}/:${EVENT_TYPE_PARAM}/:${EVENT_ID_PARAM}`}
        component={EventDetail}
      />
      <Route
        exact
        path={`${path}/${SUPERVISION_URL}/${SUPERVISEES_URL}/:${SUPERVISEE_ID_PARAM}/attachment/:attachmentId`}
        component={AttachmentFileViewer}
      />

      <Route exact path={`${path}/file/:fileId`} component={FileViewer} />
      <Route exact path={`${path}/attachment/:attachmentId`} component={AttachmentFileViewer} />
    </ErrorBoundary>
  );
};

export default Portfolio;
