import { AppLevelSection } from "@eolas-medical/core";
import { useS3FileUpload } from "Hooks";
import { generateS3FileKey } from "Utilities";

interface UseUploadTeamMemberPhoto {
  uploadTeamMemberPhoto: (photo: string | File | null) => Promise<string>;
}
const useUploadTeamMemberPhoto = (): UseUploadTeamMemberPhoto => {
  const uploadFile = useS3FileUpload();

  const uploadTeamMemberPhoto = async (photo: string | File | null) => {
    let s3Key = "";

    if (!photo) return s3Key;

    if (typeof photo !== "string") {
      s3Key = generateS3FileKey({
        isPublic: true,
        addExtension: true,
        fileName: photo.name,
        fileFormat: photo.type,
        mainSectionId: AppLevelSection.meetTheTeam,
      });
      await uploadFile(s3Key, photo);
    } else {
      s3Key = photo;
    }

    return s3Key;
  };

  return { uploadTeamMemberPhoto };
};

export default useUploadTeamMemberPhoto;
