import { EolasList, SortFn } from "Components/EolasFileList/EolasList";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import { ContentItem, ContentRepositoryId } from "modules/contentRepository/types";
import { useCallback, useMemo, useState } from "react";
import ContentItemInstance from "../ContentItemInstance/ContentItemInstance";
import useContentSectionItems from "modules/contentRepository/data/useContentSectionItems";
import useReorderContent from "modules/contentRepository/data/useReorderContent";
import { observer } from "mobx-react-lite";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

interface ContentItemListProps {
  contentRepositoryId: ContentRepositoryId;
  isAdmin: boolean;
  subSectionId?: string;
}

export type MenuActionType = "edit" | "delete" | "download";

const ContentItemList = observer(
  ({ contentRepositoryId, subSectionId, isAdmin }: ContentItemListProps) => {
    const { flags } = useLaunchDarkly();
    const isSortByFavourites = !!flags[LDFlagNames.MY_FAVOURITES];

    const { contentSectionItems, contentSectionItemsLoading } = useContentSectionItems({
      contentRepositoryId,
      subSectionId,
    });

    const { reorderContent } = useReorderContent(subSectionId || contentRepositoryId);

    const [sortMethod, setSortMethod] = useState<SortFn>();

    const { searchInput, searchResult, onSetSearchInput } = useLocalSearch({
      data: contentSectionItems,
      keysToSearch: ["name"],
    });

    const items = useMemo(
      () => (searchInput ? searchResult : contentSectionItems).slice().sort(sortMethod),
      [sortMethod, searchInput, searchResult, contentSectionItems],
    );

    const isSearching = useMemo(() => searchInput?.length > 0, [searchInput]);

    const handleDragFile = useCallback(
      ({ dropResult }) => {
        reorderContent(dropResult);
      },
      [reorderContent],
    );

    const renderFile = useCallback(
      (contentItem: ContentItem) => (
        <ContentItemInstance
          contentItem={contentItem}
          data-testid={`content-item-${contentItem.id}`}
          isAdmin={isAdmin}
        />
      ),
      [isAdmin],
    );

    return (
      <div data-testid="content-item-list">
        <EolasList<ContentItem>
          items={items}
          renderItem={renderFile}
          onSearchInputChange={onSetSearchInput}
          onSortMethodChange={(sortMethod) => setSortMethod(() => sortMethod)}
          isDragable={!isSearching && isAdmin}
          isSortable
          isSearchable
          hasFavourites={isSortByFavourites}
          isLoading={contentSectionItemsLoading}
          onDragEnd={handleDragFile}
        />
      </div>
    );
  },
);

export default ContentItemList;
