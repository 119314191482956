import { useMediaQuery } from "Hooks";
import { CircleIcon, Text } from "UIKit";
import { Pill } from "UIKit/Pill";
import { Sizes } from "UIKit/types";

interface SearchResultItemProps {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  pillBgColor: string;
  pillTextColor: string;
  pillIcon: React.ReactNode;
  searchTypeSection: string;
  mainSectionName: string;
}

const SearchResultItem = ({
  icon,
  title,
  subtitle,
  pillBgColor,
  pillTextColor,
  pillIcon,
  searchTypeSection,
  mainSectionName,
}: SearchResultItemProps) => {
  const media = useMediaQuery();
  const isMobile = media === "xs";

  const containerClass = isMobile ? "grid grid-cols-8" : "flex space-x-4";

  return (
    <div
      className={`bg-white rounded-xl shadow-md p-4 cursor-pointer border-2 border-transparent hover:border-blue-100 border-grey-300
     ${containerClass}`}
      data-testid="master-search-results-item"
    >
      <div className="col-start-1 col-end-2">
        <CircleIcon
          icon={icon}
          backgroundColor={pillBgColor}
          height={`h-10 sm:h-16`}
          width={`w-10 sm:w-16`}
        />
      </div>

      <div className="col-start-2 col-end-9 ml-4 flex flex-col space-y-2 max-w-2xl">
        <Text level={1}>{title}</Text>
        {subtitle && (
          <Text level={2} className="text-grey-700">
            {subtitle}
          </Text>
        )}
        <div className="flex flex-1 flex-wrap items-center">
          <Pill
            value={searchTypeSection}
            className={`bg-${pillBgColor} text-${pillTextColor} h-6 text-sm mr-1`}
            size={Sizes.MEDIUM}
            icon={pillIcon}
          />
          <Pill
            value={mainSectionName}
            className={`bg-${pillBgColor} text-${pillTextColor} h-6 text-sm my-2`}
            size={Sizes.MEDIUM}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchResultItem;
