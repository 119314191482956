import { EventType } from "modules/portfolio/types";
import { eventIcons } from "Pages/MeTab/pages/Portfolio/icons";

const useEventIcons = (): {
  [key in EventType]: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
} => {
  return {
    [EventType.AUDIT_QI_PROJECT]: eventIcons.AuditIcon,
    [EventType.CASE_DISCUSSION]: eventIcons.CaseBasedDiscussionIcon,
    [EventType.CLINICAL_EVENT]: eventIcons.ClinicalEventIcon,
    [EventType.MEETING]: eventIcons.MeetingIcon,
    [EventType.MULTI_SOURCE_FEEDBACK]: eventIcons.MultiSourceFeedbackIcon,
    [EventType.PDP]: eventIcons.PdpIcon,
    [EventType.PROCEDURAL_SKILL]: eventIcons.ProceduralSkillIcon,
    [EventType.REFLECTION]: eventIcons.ReflectionIcon,
    [EventType.TEACHING_DELIVERY]: eventIcons.TeachingDeliveryIcon,
    [EventType.ULTRASOUND_LOG]: eventIcons.UltraSoundIcon,
    [EventType.ULTRASOUND_VASCULAR_ACCESS]: eventIcons.UltraSoundVascularAccessIcon,
    [EventType.ULTRASOUND_CARDIAC]: eventIcons.UltraSoundCardiacIcon,
    [EventType.ULTRASOUND_DVT]: eventIcons.UltraSoundDVTIcon,
    [EventType.ULTRASOUND_THORACIC]: eventIcons.UltraSoundThoracicIcon,
    [EventType.ULTRASOUND_ABDOMINAL_RENAL]: eventIcons.UltrasoundAbdominalRenalIcon,
    [EventType.ACUTE_CARE_ASSESSMENT_TOOL]: eventIcons.AcuteCareAssessmentToolIcon,
    [EventType.DIRECT_OBSERVED_PROCEDURAL_SKILL]: eventIcons.DirectlyObservedProceduralSkillsIcon,
    [EventType.ADVANCED_PRACTICE_ANNUAL_APPRAISAL]: eventIcons.AdvancedPracticeAnnualAppraisalIcon,
    [EventType.EXTENDED_SUPERVISED_LEARNING_EVENT]: eventIcons.ExtendedSupervisedLearningEventIcon,
  };
};

export default useEventIcons;
