import { Space } from "@eolas-medical/core";
import { AdminMainSectionTile, Modal, reorderItems } from "UIKit";
import { MainSection, MainSectionIdentityLabel } from "modules/mainSections/types";
import { MedusaStatusView } from "../MedusaStatus/MedusaStatus";
import { useLocation } from "react-router-dom";
import { LDFlagNames } from "Utilities/types";
import { useState } from "react";
import { useLaunchDarkly } from "Contexts";
import { EditMedusaSectionModal } from "../EditMedusaSectionModal/EditMedusaSectionModal";
import EditSectionModal from "../EditSectionModal/EditSectionModal";
import { DragResult, EolasList } from "Components/EolasFileList/EolasList";
import useReorderMainSections from "modules/mainSections/data/useReorderMainSections";
import { useMedusa } from "../../data/useMedusa";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";

interface ContentManagementListProps {
  selectedSpace: Space;
  mainSections: MainSection[];
  canEdit: boolean;
  isSpaceContent: boolean;
}

const ContentManagementList = ({
  mainSections,
  selectedSpace,
  canEdit,
  isSpaceContent,
}: ContentManagementListProps) => {
  const { flags } = useLaunchDarkly();
  const { pathname } = useLocation();
  const [modal, setModal] = useState<React.ReactNode>(null);
  const { getIconComponent } = useIconComponent();
  const { reorderSections } = useReorderMainSections({
    space: selectedSpace,
    isSpaceMainSection: isSpaceContent,
  });

  const { status } = useMedusa();

  const handleCloseModal = () => {
    setModal(null);
  };

  const handleEditSection = (section: MainSection) => {
    setModal(
      !flags[LDFlagNames.DISABLE_MEDUSA_ACTIVATION] && section.iconName === "medusaMedications" ? (
        <EditMedusaSectionModal section={section} onCloseModal={handleCloseModal} />
      ) : (
        <EditSectionModal section={section} onCloseModal={handleCloseModal} />
      ),
    );
  };

  const handleDragEnd = ({ dropResult }: DragResult<MainSection>) => {
    if (!dropResult) return;
    if (!dropResult.destination) return;
    if (dropResult.source.index === dropResult.destination.index) return;

    const reorderedItems: MainSection[] = reorderItems(
      mainSections,
      dropResult.source.index,
      dropResult.destination?.index!,
    );

    reorderSections(reorderedItems);
  };

  const getSectionRoute = (mainSection: MainSection) => {
    switch (mainSection.identity) {
      case "miniApp": {
        return `${pathname}/${mainSection.iconName}`;
      }
      case "genericContentRepository": {
        return `${pathname}/content-repository/${encodeURIComponent(mainSection.routeId)}`;
      }
      default:
        return `${pathname}/${mainSection.iconName}`;
    }
  };

  const renderAdminSection = (section: MainSection, isDragging?: boolean) => {
    const link = getSectionRoute(section);

    const renderTitle =
      section.iconName === "medusaMedications" ? (
        <h3 className="text-lg font-semibold line-clamp-2" title={section.name!}>
          {section.name} <MedusaStatusView status={status} />
        </h3>
      ) : undefined;

    return (
      <AdminMainSectionTile
        to={link}
        key={section.id}
        id={section.id}
        title={section.name}
        tagLabel={MainSectionIdentityLabel[section.identity ?? "genericContentRepository"]}
        renderTitle={renderTitle}
        onEdit={() => handleEditSection(section)}
        description={section.description!}
        disabled={section.iconName === "medusaMedications"}
        icon={section.iconUrl && getIconComponent(section?.iconUrl || "")}
      />
    );
  };

  return (
    <>
      <EolasList<MainSection>
        items={mainSections}
        isDragable={canEdit}
        onDragEnd={handleDragEnd}
        renderItem={renderAdminSection}
      />
      <Modal open={!!modal} onClose={() => setModal(null)}>
        {modal}
      </Modal>
    </>
  );
};

export default ContentManagementList;
