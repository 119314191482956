import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Title, SuccessModal, ModalHeader, InnerModalWrapper } from "UIKit";

import DeactivatedSection from "../DeactivatedSection/DeactivatedSection";
import { ActivateMedusaSection } from "../ActivateMedusaSection/ActivateMedusaSection";
import { MainSection } from "modules/mainSections/types";

export interface ActivateSectionsModalProps {
  deactivatedMainSections: MainSection[];
  onCloseModal(): void;
}

export const ActivateSectionsModal: React.FC<ActivateSectionsModalProps> = observer(
  ({ deactivatedMainSections, onCloseModal }) => {
    const { t } = useTranslation();

    if (deactivatedMainSections.length === 0) {
      return <SuccessModal onComplete={onCloseModal} text="contentManagement_allFeaturesActive" />;
    }

    return (
      <InnerModalWrapper>
        <ModalHeader>
          <Title level={5}>{t("contentManagement_activateSection")}</Title>
        </ModalHeader>

        <div className="flex flex-col h-full divide-y divide-y-grey-300 overflow-y-auto">
          {deactivatedMainSections.map((section) =>
            section.iconName === "medusaMedications" ? (
              <ActivateMedusaSection
                key={section.id}
                section={section}
                onCloseModal={onCloseModal}
              />
            ) : (
              <DeactivatedSection key={section.id} section={section} onCloseModal={onCloseModal} />
            ),
          )}
        </div>
      </InnerModalWrapper>
    );
  },
);
