import { useTranslation } from "react-i18next";

import { DeleteFileIcon } from "Assets";
import { Text, Title, InnerModalWrapper, Button } from "UIKit";

interface DeleteChecklistItemModalProps {
  title: string;
  onDelete(): void;
}

export const DeleteChecklistItemModal: React.FC<DeleteChecklistItemModalProps> = ({
  title,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <InnerModalWrapper>
      <div className="p-6 px-12 sm:p-12">
        <div className="flex flex-col items-center flex-1 space-y-4 sm:space-y-8">
          <DeleteFileIcon className="w-12 h-12 sm:w-16 sm:h-16" />

          <Title level={5} className="line-clamp-2 text-center">
            {title}
          </Title>

          <Text level={2} className="font-semibold text-center text-grey-700">
            {t("checklistBuilder_delete_section_item_description")}
          </Text>
        </div>

        <div className="flex flex-col flex-1">
          <Button
            size="lg"
            color="red"
            variant="outline"
            onClick={onDelete}
            className="w-full self-center mt-4 sm:mt-8"
          >
            {t("checklistBuilder_delete_section_item_button")}
          </Button>
        </div>
      </div>
    </InnerModalWrapper>
  );
};
