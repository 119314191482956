import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import envConfig from "../env-config";
import { CaptureContext } from "@sentry/types";

Sentry.init({
  dsn: envConfig.REACT_APP_SENTRY_DSN,
  enabled: envConfig.REACT_APP_SENTRY_ENABLED,
  integrations: [new Integrations.BrowserTracing()],
  release: envConfig.REACT_APP_VERSION,
  environment: envConfig.REACT_APP_APP_ENV,
  tracesSampleRate: envConfig.REACT_APP_SENTRY_TRACE_SAMPLE_RATE,
});

Sentry.addBreadcrumb({
  category: "Sentry",
  message: "Initialised",
});

Sentry.setTag("app-version", envConfig.REACT_APP_VERSION);

interface SentryUser {
  id: string;
  appUserID?: string;
  appID?: string;
  setOnRefetch?: boolean;
}

function useSentry() {
  return {
    captureException: (exception: any, captureContext?: CaptureContext) => {
      const isError = exception instanceof Error;
      const err = isError
        ? exception
        : new Error(`${exception.message || exception.name || exception.code} `);

      const context = isError
        ? captureContext
        : //@ts-ignore
          { ...captureContext, extra: { ...captureContext?.extra, exception } };

      return Sentry.captureException(err, context as any);
    },
    addBreadcrumb: Sentry.addBreadcrumb,
    setUser: (user?: SentryUser) => {
      if (user) {
        Sentry.addBreadcrumb({
          category: "Sentry",
          message: "Sentry.setUser",
        });
        Sentry.setUser(user);
      } else {
        Sentry.addBreadcrumb({
          category: "Sentry",
          message: "Clearing user",
        });
        Sentry.configureScope((scope) => scope.setUser(null));
      }
    },
  };
}

export { useSentry, Sentry };
