import { Link, useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { ClientMainSectionTile, InnerPageWrapper, PageTitle, GridContainer } from "UIKit";
import { useIcons } from "Hooks";
import useOrganisationAdminSections from "modules/spaces/data/useOrganisationAdminSections";

const OrganisationAdmin = observer(() => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { getSpaceAdminDashboardIcon } = useIcons();
  const { hasOrganisationTab, hasSpacesTab } = useEolasNavigation();

  const { activeAdminMainSections } = useOrganisationAdminSections();

  return (
    <InnerPageWrapper>
      <PageTitle data-testid="admin-home" title={t("adminHome_title")} />

      <GridContainer>
        {activeAdminMainSections.map(({ id, route, title, description }) => {
          const Icon = getSpaceAdminDashboardIcon(id);

          // HOTFIX: 09/06/2023
          if (hasOrganisationTab && hasSpacesTab && route === "manageUsers") {
            return null;
          }

          return (
            <Link key={id} to={`${url}/${route}`}>
              <ClientMainSectionTile
                id={id as any}
                title={t(title)}
                description={t(description)}
                Icon={<Icon className="h-8 w-8 lg:h-14 lg:w-14" />}
              />
            </Link>
          );
        })}
      </GridContainer>
    </InnerPageWrapper>
  );
});

export default OrganisationAdmin;
