import { useState } from "react";
import { useTranslation } from "react-i18next";

import { profilesClient, userStore } from "@eolas-medical/core";

import { SuccessModal } from "UIKit";

import { VerificationCodeModal } from "./VerificationCodeModal";

import {
  useVerifyEmailMutation,
  useSendEmailVerificationCodeMutation,
} from "Hooks/graphql/user-mutations.generated";
import { AddAnotherEmailState, EditEmailsModalStates } from "./AddAnotherEmail";

interface EditEmailsModalProps {
  closeModal: () => void;
  initialShowAddAnotherEmailInput?: boolean;
}

export const EditEmailsModal: React.FC<EditEmailsModalProps> = ({
  closeModal,
  initialShowAddAnotherEmailInput,
}) => {
  const [modalState, setModalState] = useState<EditEmailsModalStates>("ADD_ANOTHER_EMAIL");

  const { userData: user } = userStore;
  const { t } = useTranslation();
  const [emailToVerify, setEmailToVerify] = useState("");
  const [
    sendEmailVerificationCode,
    { loading: sendingCode },
  ] = useSendEmailVerificationCodeMutation();
  const [verifyEmailMutation, { loading: verifyingCode }] = useVerifyEmailMutation();

  const sendVerificationCode = async (email: string) => {
    setEmailToVerify(email);
    return sendCodeToEmail({ email });
  };

  const verifyEmail = async (code: string) => {
    const { errors, data } = await verifyEmailMutation({
      variables: { userID: user?.id, email: emailToVerify, code },
    });

    if (errors && errors.length) {
      const [error] = errors;
      throw error;
    }

    if (user.id) {
      profilesClient.getUserProfile(user.id).then((user) => {
        userStore.updateData({ user });
        setModalState("SUCCESS");
      });
    }

    return data;
  };

  const resendVerificationCode = async () => {
    return sendCodeToEmail({ email: emailToVerify });
  };

  const sendCodeToEmail = async ({ email }: { email: string }) => {
    const { errors, data } = await sendEmailVerificationCode({
      variables: {
        email,
      },
    });

    if (errors && errors.length) {
      const [error] = errors;
      throw error;
    }
    setModalState("VERIFY_CODE");
    return data;
  };

  if (modalState === "ADD_ANOTHER_EMAIL") {
    return (
      <AddAnotherEmailState
        sendingCode={sendingCode}
        sendVerificationCode={sendVerificationCode}
        initialShowAddAnotherEmailInput={initialShowAddAnotherEmailInput}
      />
    );
  }

  if (modalState === "VERIFY_CODE") {
    return (
      <VerificationCodeModal
        verifyEmail={verifyEmail}
        verifying={verifyingCode}
        resendVerificationCode={resendVerificationCode}
        closeModal={closeModal}
      />
    );
  }

  if (modalState === "SUCCESS") {
    return (
      <SuccessModal
        onComplete={() => setModalState("ADD_ANOTHER_EMAIL")}
        text={t("my-medical_personal_details_emails_email_added")}
      />
    );
  }
  return null;
};
