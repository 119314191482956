import { useTranslation } from "react-i18next";

import { ArrowRightIcon } from "Assets";
import { Button, IconButton, Avatar } from "UIKit";

import { SupervisionStatus } from "modules/portfolio/types";
import StatusBadge from "Pages/MeTab/pages/Portfolio/components/StatusBadge/StatusBadge";
import { useButtonSize } from "Pages/MeTab/pages/Portfolio/hooks/useButtonSize";

interface SupervisionCardProps {
  id: string;
  email: string;
  status: SupervisionStatus;
  onClick?: () => void;
  onApprove?: (supervisionRequestId: string) => void;
  onCancel?: (supervisionRequestId: string) => void;
  onRemove?: (supervisionId: string) => void;
  onDecline?: (supervisionId: string) => void;
  onResend?: (supervisionEmail: string) => void;
  sendReminderComponent?: React.ReactNode;
}

const SupervisionCard = ({
  id,
  email,
  status,
  onClick,
  onApprove,
  onCancel,
  onRemove,
  onDecline,
  onResend,
  sendReminderComponent = null,
}: SupervisionCardProps) => {
  const { t } = useTranslation();

  const { buttonSize } = useButtonSize();

  return (
    <div
      data-testid="timeline-event-card"
      className={`bg-white border border-grey-300 rounded-xl items-center h-48 gap-y-2 lg:h-24 p-5 overflow-hidden grid grid-cols-4 grid-rows-3 lg:grid-cols-8 lg:grid-rows-1 xl:grid-cols-12 ${
        onClick ? "cursor-pointer" : ""
      }`}
      onClick={onClick}
    >
      <div className="row-start-2 col-start-1 flex items-center lg:row-start-1 lg:col-end-3">
        <Avatar label={email} />
      </div>

      <div className="flex items-center justify-center row-start-1 col-start-1 lg:row-start-1 lg:col-start-4 lg:col-end-6 xl:col-start-6 xl:col-end-8">
        <StatusBadge status={status} size={buttonSize} />
      </div>

      <div className="flex flex-1 items-center justify-end space-x-4 row-start-3 col-start-5 col-end-1 lg:row-start-1 lg:col-start-6 lg:col-end-9 xl:col-start-8 xl:col-end-13">
        {sendReminderComponent}

        {onResend && (
          <Button
            variant="outline"
            color="blue"
            size={buttonSize}
            onClick={(e) => {
              e.stopPropagation();
              onResend(email);
            }}
          >
            {t("portfolio_supervision_resend")}
          </Button>
        )}

        {onApprove && (
          <Button
            variant="solid"
            color="blue"
            size={buttonSize}
            onClick={(e) => {
              e.stopPropagation();
              onApprove(id);
            }}
          >
            {t("portfolio_supervision_approve")}
          </Button>
        )}

        {onDecline && (
          <Button
            variant="solid"
            color="white"
            className="text-red-500"
            size={buttonSize}
            onClick={(e) => {
              e.stopPropagation();
              onDecline(id);
            }}
          >
            {t("portfolio_supervision_decline")}
          </Button>
        )}

        {onCancel && (
          <Button
            variant="solid"
            color="white"
            className="text-red-500"
            size={buttonSize}
            onClick={(e) => {
              e.stopPropagation();
              onCancel(id);
            }}
          >
            {t("portfolio_supervision_cancel")}
          </Button>
        )}

        {onRemove && (
          <Button
            variant="solid"
            color="white"
            className="text-red-500"
            size={buttonSize}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(id);
            }}
          >
            {t("portfolio_supervision_remove")}
          </Button>
        )}

        {onClick && (
          <IconButton
            size={buttonSize}
            variant="rounded"
            icon={<ArrowRightIcon />}
            className="bg-blue-50 text-blue-500"
          />
        )}
      </div>
    </div>
  );
};

export default SupervisionCard;
