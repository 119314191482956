import React from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

export type TextLevel = 1 | 2 | 3 | 4 | 5;

export interface TextProps extends React.HTMLProps<HTMLElement> {
  level: TextLevel;
  element?: "span" | "p" | "label";
}

const textStyles: Record<TextLevel, string> = {
  "1": "text-base font-semibold", // Body 1 in Figma
  "2": "text-sm font-semibold", // Body 2 in Figma
  "3": "text-xs font-bold", // Message in Figma
  "4": "text-2xs font-semibold", // Caption in Figma
  "5": "text-3xs font-semibold", // Small Text in Figma
};

export const Text: React.FC<TextProps> = ({ level, className, element = "span", ...props }) => {
  const textClass = tw(`${textStyles[level]} ${className}`);

  return React.createElement(element, { className: textClass, ...props });
};
