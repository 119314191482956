import { userStore } from "@eolas-medical/core";
import useAllFiles from "modules/myFiles/data/useAllFiles";
import useEditFile from "modules/myFiles/data/useEditFile";
import { MyFile, UpdateFileInput } from "modules/myFiles/types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal, EditFileModal } from "UIKit";

interface EditFileModalProps {
  handleClose(): void;
  open: boolean;
  file: MyFile;
}

export const EditMyFileModal = ({ handleClose, open, file }: EditFileModalProps) => {
  const { t } = useTranslation();
  const { userID = "" } = userStore;
  const { filesList } = useAllFiles(userID, file.sectionId);
  const { editFile, editFileError, editFileSuccessful, editingFile, reset } = useEditFile();

  const onSubmit = useCallback(
    (input: UpdateFileInput) => {
      return editFile({ id: file.id, ...input });
    },
    [editFile, file.id],
  );

  const onClose = useCallback(() => {
    handleClose();
    reset();
  }, [handleClose, reset]);

  const sectionFileNames = useMemo(() => filesList.map(({ name }) => name), [filesList]);

  return (
    <Modal data-testid="edit-file-modal" open={open} onClose={handleClose}>
      <EditFileModal
        onCloseModal={onClose}
        onSubmit={onSubmit}
        isSuccessful={editFileSuccessful}
        isLoading={editingFile}
        errorMessage={editFileError}
        existingFileNames={sectionFileNames}
        title={t(`my_files_edit_file_title`)}
        file={file}
      />
    </Modal>
  );
};
