import { useLocation } from "react-router-dom";
import { Variants, motion } from "framer-motion";
import { MainSectionTile } from "UIKit";
import { MainSection } from "modules/mainSections/types";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";

interface KnowledgeSectionListProps {
  knowledgeSectionList: MainSection[];
}

const childrenVariants: Variants = {
  out: { x: -50, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

const KnowledgeSectionList: React.FC<KnowledgeSectionListProps> = ({
  knowledgeSectionList,
}: KnowledgeSectionListProps) => {
  const { getIconComponent } = useIconComponent();

  const { pathname } = useLocation();

  const getSectionRoute = (knowledgeSection: MainSection) => {
    return `${pathname}/${knowledgeSection.iconName}`;
  };

  return (
    <>
      {knowledgeSectionList.map((knowledgeSectionListItem: MainSection) => {
        const { id, iconUrl, name, description, disabled } = knowledgeSectionListItem;

        const link = getSectionRoute(knowledgeSectionListItem);

        return (
          <motion.div
            variants={childrenVariants}
            whileHover={{ scale: 1.01 }}
            className="mt-4 flex flex-col divide-y divide-grey-light"
            key={id}
          >
            <MainSectionTile
              icon={iconUrl && getIconComponent(iconUrl)}
              title={name || ""}
              description={description || ""}
              disabled={disabled}
              to={link}
              data-testid={`knowledge-section-${id}`}
            />
          </motion.div>
        );
      })}
    </>
  );
};

export default KnowledgeSectionList;
