import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection, MajorIncidentSubSection } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { AddButton, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";

import { AddIncidentModal, MajorIncidentsList } from "../components";

export const AddMajorIncident: React.FC<RouteComponentProps> = ({ match: { url } }) => {
  useMainSectionVisit({
    mainSectionId: AppLevelSection.majorIncidents,
    subSectionId: MajorIncidentSubSection.incidents,
  });

  const { t } = useTranslation();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const openMajorIncidentForm = useCallback(() => {
    setModal(<AddIncidentModal onClose={onCloseModal} />);
  }, [setModal, onCloseModal]);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={t("incidents_add_incident_title")} />
        <NavButton to={url.replace("/add", "")} />

        <AddButton
          size="lg"
          color="red"
          weight="bold"
          className="sm:self-center"
          data-testid="add-incident"
          onClick={openMajorIncidentForm}
        >
          {t("incidents_activate_new_incident")}
        </AddButton>

        <MajorIncidentsList />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
};
