import { useCallback } from "react";
import { EolasMainSection } from "@eolas-medical/core";

import { LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts/LaunchDarklyContext";

export const sectionToFlags: Record<EolasMainSection, LDFlagNames> = {
  admissionGuides: LDFlagNames.ADMISSION_GUIDES_SECTION,
  clinicalPathways: LDFlagNames.CLINICAL_PATHWAYS_SECTION,
  surveys: LDFlagNames.SURVEYS_SECTION,
  emergencyActionCards: LDFlagNames.EMERGENCY_ACTION_CARDS_SECTION,
  equipmentLocations: LDFlagNames.EQUIPMENT_LOCATIONS_SECTION,
  highlights: LDFlagNames.HIGHLIGHTS_SECTION,
  howTo: LDFlagNames.HOW_TO_SECTION,
  patientLeaflets: LDFlagNames.PATIENT_LEAFLETS_SECTION,
  theDepartment: LDFlagNames.THE_DEPARTMENT_SECTION,
  wellbeing: LDFlagNames.WELLBEING_SECTION,
  guidelines: LDFlagNames.GUIDELINES_SECTION,
  clinicalDocuments: LDFlagNames.CLINICAL_DOCUMENTS_SECTION,
  policyDocuments: LDFlagNames.POLICY_DOCUMENTS_SECTION,
  rotas: LDFlagNames.ROTA_SECTION,
  newsFeed: LDFlagNames.NEWS_FEED_SECTION,
  meetTheTeam: LDFlagNames.MEET_THE_TEAM_SECTION,
  contacts: LDFlagNames.CONTACTS_SECTION,
  completedChecklist: LDFlagNames.CHECKLIST_SECTION,
  checklists: LDFlagNames.CHECKLIST_SECTION,
  incidentReporting: LDFlagNames.INCIDENT_REPORTING_SECTION,
  educationPortal: LDFlagNames.EDUCATION_PORTAL_SECTION,
  hospitalList: LDFlagNames.HOSPITAL_LISTS_SECTION,
  majorIncidents: LDFlagNames.MAJOR_INCIDENT_SECTION,
  importantLinks: LDFlagNames.IMPORTANT_LINKS_SECTION,
  drugMonitoring: LDFlagNames.NOT_USED,
  hospitalContacts: LDFlagNames.HOSPITAL_CONTACTS_SECTION,
  hospitalGuidelines: LDFlagNames.HOSPITAL_GUIDELINES_SECTION,
  medusaMedications: LDFlagNames.MEDUSA_SECTIONS,
  majorIncidentDocuments: LDFlagNames.MAJOR_INCIDENT_SECTION,
  researchAndAudit: LDFlagNames.RESEARCH_AND_AUDIT,
  communicationPortal: LDFlagNames.COMMUNICATION_PORTAL,
  learningManagement: LDFlagNames.LEARNING_MANAGEMENT,
  organisationWellbeing: LDFlagNames.ORGANISATION_WELLBEING_SECTION,
  genericOrganisationGuidelines: LDFlagNames.GENERIC_ORGANISATION_GUIDELINES_SECTION,
  nationalGuidelines: LDFlagNames.NATIONAL_GUIDELINES,
  clinicalCalculators: LDFlagNames.CT_CLINICAL_CALCULATORS,
  communityPatientLeaflets: LDFlagNames.COMMUNITY_PATIENT_LEAFLETS,
  communityMedications: LDFlagNames.COMMUNITY_MEDICATIONS_SECTION,
  myFiles: LDFlagNames.MY_FILES,
  myPortfolio: LDFlagNames.MY_PORTFOLIO,
  channels: LDFlagNames.CHAT_SECTION,
  activityFeed: LDFlagNames.ADMIN_INVITE_USERS,
};

export function useMainSectionFlags() {
  const { flags } = useLaunchDarkly();

  const isMainSectionEnabled = useCallback(
    (sectionID: EolasMainSection) => {
      return flags[sectionToFlags[sectionID]] ?? true;
    },
    [flags],
  );

  return {
    isMainSectionEnabled,
  };
}
