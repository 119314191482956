import { AuthFunctionNames, authenticationClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { errorStore } from "Stores/ErrorStore";

const useForgotPassword = () => {
  const { mutate, isLoading, error, isSuccess } = useMutation(authenticationClient.forgotPassword, {
    onError: (error: string) => {
      errorStore.captureError({
        error,
        source: "user",
        authFunctionName: AuthFunctionNames.forgotPassword,
      });
    },
  });

  return {
    forgotPassword: mutate,
    forgotPasswordLoading: isLoading,
    forgotPasswordError: error || undefined,
    forgotPasswordSuccess: isSuccess,
  };
};

export default useForgotPassword;
