import { Space } from "@eolas-medical/core";
import { removeBracketsAndPercent } from "Utilities/helpers";
import { BaseAppRoute } from "Utilities/types";

export const getSpaceRoute = (space?: Space) => {
  if (!space) {
    return `/${BaseAppRoute.selectSpace}`;
  }
  return `/${BaseAppRoute.spaces}/${encodeURIComponent(
    removeBracketsAndPercent(space.name) || "",
  )}`;
};

export const getOrganisationRoute = (space?: Space) => {
  if (!space) {
    return `/${BaseAppRoute.selectSpace}`;
  }
  return `/${BaseAppRoute.organisation}/${encodeURIComponent(
    removeBracketsAndPercent(space.name) || "",
  )}`;
};

export function isFile(result?: File | Link): result is File {
  return !!result && "path" in result;
}

interface Link {
  name: string;
  url: string;
}

export function isLink(result?: File | Link): result is Link {
  return !!result && "url" in result;
}
