import { useTranslation } from "react-i18next";

import { EnvelopeIcon, RefreshCWIcon } from "Assets";
import {
  Text,
  Title,
  Loader,
  Button,
  TagInput,
  ModalBody,
  FormError,
  FormFieldError,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import { useInviteUsers } from "../../../hooks/useInviteUsers";
import { UserInvitation } from "../UserInvitation";

const InviteUsersByEmailModal = () => {
  const { t } = useTranslation();
  const {
    emails,
    isLoading,
    isDisabled,
    invitations,
    validationError,
    isRefreshLoading,
    invitationsError,
    invitationsLoading,
    setEmails,
    onSendInvites,
    emailValidator,
    refetchInvitations,
    onRefreshInvitations,
  } = useInviteUsers();

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("manageUsers_invite_modal_title")}</Title>
        <Text level={1} className="text-grey-700">
          {t("manageUsers_invite_modal_description")}
        </Text>
      </ModalHeader>

      <ModalBody className="overflow-y-auto">
        <div className="grid grid-cols-1 md:grid-cols-6 gap-4 flex-1">
          <div className="flex flex-col space-y-2 md:col-span-5 relative">
            <TagInput
              maxTags={10}
              tags={emails}
              iconType="MailIcon"
              onChange={setEmails}
              validator={emailValidator}
              placeholder={t("manageUsers_invite_placeholder")}
            />

            <FormFieldError>{validationError}</FormFieldError>
          </div>

          <Button onClick={onSendInvites} isLoading={isLoading} disabled={isDisabled}>
            <EnvelopeIcon className="h-5 w-5 mr-2" />
            {t("manageUsers_invite_send_button")}
          </Button>
        </div>

        <div
          className={`
            flex flex-col border border-grey-300 overflow-y-auto
            rounded-md mt-4 max-h-40vh hsm:max-h-40vh hmd:max-h-50vh
            ${
              invitationsLoading || invitations.length === 0
                ? "bg-grey-50 items-center justify-center"
                : "bg-white divide-y divide-grey-300"
            }
          `}
        >
          {invitationsLoading ? (
            <Loader />
          ) : invitations.length === 0 ? (
            <Title level={8} className="text-grey-500 py-2">
              {t("manageUsers_invite_no_invitations_placeholder")}
            </Title>
          ) : (
            invitations.map((invitation) => (
              <UserInvitation
                key={invitation.id}
                invitation={invitation}
                refetchInvitations={refetchInvitations}
              />
            ))
          )}
        </div>

        <Button
          color="grey"
          weight="bold"
          variant="outline"
          isLoading={isRefreshLoading}
          style={{ borderRadius: 44 }}
          onClick={onRefreshInvitations}
          className="self-center px-3 mt-4 sm:mt-8"
        >
          <RefreshCWIcon className="h-4 w-4 mr-2" />
          {t("manageUsers_invite_refresh")}
        </Button>

        <FormError error={invitationsError} />
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default InviteUsersByEmailModal;
