import { useCallback, useEffect, useState } from "react";

export const useBrowserState = () => {
  const [status, setStatus] = useState("visible");
  const [prevStatus, setPrevStatus] = useState("");

  const handleVisibilityStatusChange = useCallback(() => {
    if (status !== document.visibilityState) {
      setPrevStatus(status);
      setStatus(document.visibilityState);
    }
  }, [status]);

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityStatusChange);
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityStatusChange);
    };
  }, [handleVisibilityStatusChange]);

  return { status, prevStatus };
};
