import { get } from "lodash";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DropResult } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";

import { DragIcon } from "Assets";
import { AddButton, SortableList, Text, Modal, FormFieldError } from "UIKit";

import { ChecklistSectionItem } from "../types";
import { ChecklistItem } from "./BuilderChecklistItem";
import { AddChecklistItemModal } from "./AddChecklistItemModal";
import { DeleteChecklistItemModal } from "./DeleteChecklistItemModal";

export interface BuilderSectionItemListProps {
  missingSectionError?: string;
  sectionItems: ChecklistSectionItem[];
  onDeleteItem(index: number): void;
  onSwap(dropResult: DropResult): void;
  onAddItem(item: ChecklistSectionItem): void;
  onEditItem(index: number, item: ChecklistSectionItem): void;
}

export const BuilderSectionItemList: React.FC<BuilderSectionItemListProps> = ({
  sectionItems,
  missingSectionError,
  onSwap,
  onAddItem,
  onEditItem,
  onDeleteItem,
}) => {
  const { t } = useTranslation();
  const { formState, trigger } = useFormContext();
  const error = get(formState, "errors.sectionItems.message", "");

  const [modal, setModal] = useState<React.ReactNode>(null);
  const onCloseModal = useCallback(() => setModal(null), [setModal]);

  const onAddChecklistItem = useCallback(() => {
    setModal(
      <AddChecklistItemModal
        onCancel={onCloseModal}
        onSubmit={(item) => {
          onAddItem(item);
          trigger("sectionItems");
        }}
      />,
    );
  }, [trigger, setModal, onCloseModal, onAddItem]);

  const onEditChecklistItem = useCallback(
    (index: number, sectionItem: ChecklistSectionItem) => () => {
      setModal(
        <AddChecklistItemModal
          onCancel={onCloseModal}
          checklistItem={sectionItem}
          onSubmit={(updatedItem) => onEditItem(index, updatedItem)}
        />,
      );
    },
    [setModal, onCloseModal, onEditItem],
  );

  const onDeleteChecklistItem = useCallback(
    (index: number, sectionItem: ChecklistSectionItem) => () => {
      setModal(
        <DeleteChecklistItemModal
          title={sectionItem.itemTitle}
          onDelete={() => {
            onDeleteItem(index);
            onCloseModal();
          }}
        />,
      );
    },
    [onCloseModal, onDeleteItem],
  );

  return (
    <>
      {sectionItems.length > 1 && (
        <div className="flex justify-center items-center space-x-2 mb-4">
          <DragIcon className="w-4 h-4 text-quartz-400" />
          <Text level={2} className="text-grey-600 font-semibold">
            {t("contentManagement_info")}
          </Text>
        </div>
      )}

      {sectionItems.length > 0 && (
        <SortableList
          onDragEnd={onSwap}
          droppableId={`checklist-items`}
          droppableClassname="space-y-0 border-t-2 border-grey-300 my-4"
        >
          {sectionItems.map((item, index) => {
            return (
              <ChecklistItem
                item={item}
                key={`${item.itemTitle}-${index}`}
                onEdit={onEditChecklistItem(index, item)}
                onDelete={onDeleteChecklistItem(index, item)}
              />
            );
          })}
        </SortableList>
      )}

      <div className="flex flex-col">
        <AddButton
          size="md"
          color="grey"
          onClick={onAddChecklistItem}
          className={`self-start sm:self-start ${
            error || missingSectionError ? "input-error" : ""
          }`}
        >
          {t("checklistBuilder_add_item_button")}
        </AddButton>
        <FormFieldError className="mt-2">{error || missingSectionError}</FormFieldError>
      </div>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
};
