import { useTranslation } from "react-i18next";
import { DeleteModal, SuccessModal } from "UIKit";
import { FileSection } from "modules/myFiles/types";
import { useCallback } from "react";
import useDeleteSection from "modules/myFiles/data/useDeleteSection";

interface DeleteSectionModalProps {
  section: FileSection;
  onClose?: () => void;
}
const DeleteFileSectionModal = ({ section, onClose = () => {} }: DeleteSectionModalProps) => {
  const { t } = useTranslation();
  const {
    deleteSection,
    deletingSection,
    deleteSectionSuccessful,
    deleteSectionError,
  } = useDeleteSection(section.id);

  const handleDeleteSection = useCallback(() => {
    deleteSection();
  }, [deleteSection]);

  if (deleteSectionSuccessful) {
    return <SuccessModal onComplete={onClose} text={t("my_files_delete_section_successful")} />;
  }

  return (
    <div data-testid="delete-section-modal">
      <DeleteModal
        title={t("my_files_delete_section")}
        onCloseModal={onClose}
        onDelete={handleDeleteSection}
        isLoading={deletingSection}
        error={deleteSectionError}
        description={t("my_files_delete_section_description")}
      />
    </div>
  );
};

export default DeleteFileSectionModal;
