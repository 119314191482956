import { LoaderIcon } from "Assets";

export const LoaderWrapper = ({ children, className }: any) => {
  return (
    <span className={"inline-block " + className}>
      <div className={"inline-flex items-center justify-center"}>
        <LoaderIcon className="animate-spin h-4 w-4 mr-2" />
        {children}
        <span>.</span>
        <span className="animate-opacity-1">.</span>
        <span className="animate-opacity-2">.</span>
      </div>
    </span>
  );
};
