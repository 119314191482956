import React from "react";

export interface ProgressBarProps {
  progress: number;
  className?: string;
  showPercentage?: boolean;
}

const _ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  className = "",
  showPercentage = true,
}) => {
  return (
    <div className={`bg-grey-light rounded-xl h-8 w-full ${className}`}>
      <div
        role="progressbar"
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={progress}
        style={{ width: `${progress}%` }}
        className={`
          flex justify-center items-center
          h-full rounded-l-xl bg-green shadow-md transition-width transition-slowest ease w-full
          ${progress === 100 ? "rounded-r-xl" : ""}
        `}
      >
        {showPercentage && <p className="text-white text-lg font-bold">{progress}%</p>}
      </div>
    </div>
  );
};

export const ProgressBar = React.memo(_ProgressBar);
