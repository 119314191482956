import { DeleteModal } from "UIKit";
import { useCallback } from "react";
import { TeamMember } from "../../../../types";
import useRemoveTeamMember from "../../../../data/useRemoveTeamMember";

interface RemoveTeamMemberModalProps {
  onCloseModal: () => void;
  teamMember: TeamMember;
}
const RemoveTeamMemberModal = ({ teamMember, onCloseModal }: RemoveTeamMemberModalProps) => {
  const {
    removeTeamMember,
    removingTeamMember,
    removeTeamMemberError,
    removeTeamMemberSuccessful,
  } = useRemoveTeamMember();

  const handleRemoveTeamMember = useCallback(() => {
    removeTeamMember(teamMember.id);
  }, [removeTeamMember, teamMember]);

  return (
    <DeleteModal
      error={removeTeamMemberError}
      onDelete={handleRemoveTeamMember}
      isLoading={removingTeamMember}
      isSuccessful={removeTeamMemberSuccessful}
      onCloseModal={onCloseModal}
      title={teamMember.name}
    />
  );
};

export default RemoveTeamMemberModal;
