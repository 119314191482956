import { fileStore } from "@eolas-medical/core";
import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { observer } from "mobx-react-lite";
import FlashcardViewer from "./components/FlashcardViewer/FlashcardViewer";
import DocumentViewer from "./components/DocumentViewer/DocumentViewer";
import { isVideoType } from "Utilities/fileHelpers";
import { BlobTypes } from "modules/generic.types";
import VideoViewer from "./components/VideoViewer/VideoViewer";

export const FileViewer = observer(() => {
  const { fileId } = useParamsDecoded<{ fileId: string }>();

  const file = fileStore.getFile(fileId);

  if (file.type === "flashcard") {
    return <FlashcardViewer flashcard={file} />;
  }

  if (isVideoType(file.type as BlobTypes)) {
    return <VideoViewer video={file} />;
  }

  return <DocumentViewer document={file} />;
});
