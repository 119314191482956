import { DownloadFileButton } from "Components";
import { format } from "date-fns";

import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";

const SimpleFileTile: React.FC<FileTileProps> = ({
  canDrag,
  isAdmin,
  eolasFile,
  isDragging,
  onSelectFile = () => {},
  ...handlers
}) => {
  const { name, info, description, updatedAt } = eolasFile;

  return (
    <FileTileWrapper
      className="h-40"
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col justify-between flex-1 h-full">
        <span className="font-semibold text-lg line-clamp-1 leading-10">{name}</span>
        <span className="text-grey-dark text-sm lg:text-md line-clamp-2">{description}</span>
        <div className="flex flex-col pt-2">
          <span className="text-sm text-grey-dark line-clamp-1">{info}</span>
          <span className="text-sm text-grey-dark">
            {format(new Date(updatedAt as string), "do MMMM yyyy, HH:mm")}
          </span>
        </div>
      </div>

      <div className="w-fit self-start flex flex-col items-end justify-between h-full">
        <DownloadFileButton eolasFile={eolasFile} />
      </div>
      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};

export const HowToFileTile = SimpleFileTile;
export const EquipmentLocationFileTile = SimpleFileTile;
