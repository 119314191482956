import { useCallback, useMemo } from "react";
import { EolasMainSection, fileStore, sectionStore } from "@eolas-medical/core";
import { default as useLocalFilter } from "shared/hooks/useLocalSearch/useLocalSearch";
import { LocalResult } from "../types";
import { makeChecklistMasterSearchFile } from "../helpers";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { getMainSectionIdentity } from "modules/mainSections/helpers";

interface UseLocalSearch {
  localFiles: LocalResult[];
  localFilesLoading: boolean;
  onSearchLocalFiles: (text: string) => void;
}

const useLocalSearch = (): UseLocalSearch => {
  const { filesList } = fileStore;

  const { getIconUrl } = useGetIconUrl();

  const formattedCheckLists = useMemo(() => {
    return sectionStore.checklistTemplatesList.map((ck) =>
      makeChecklistMasterSearchFile({ checklist: ck }),
    );
  }, []);

  const { searchInput, searchResult, onSetSearchInput } = useLocalFilter({
    data: [...formattedCheckLists, ...filesList],
    keysToSearch: ["name", "description", "searchField"],
  });

  const handleSearch = useCallback(
    (text: string) => {
      onSetSearchInput(text);
    },
    [onSetSearchInput],
  );

  const localFiles: LocalResult[] = useMemo(() => {
    if (searchInput.length < 2) return [];

    return searchResult
      .filter((e) => e !== null)
      .map((sr) => {
        const { mainSectionType, mainSectionIdentity, childReference } = getMainSectionIdentity(
          sr.mainSectionID!,
        );

        return {
          id: sr.id || "",
          file: sr,
          title: sr.name || "",
          subtitle: sr.description || "",
          mainSectionName: childReference?.name || "",
          searchTypeSection: "local",
          searchSubType: mainSectionType ?? mainSectionIdentity ?? ("" as EolasMainSection),
          iconName: childReference?.icon ? getIconUrl(childReference?.icon) : undefined,
        };
      });
  }, [searchResult, searchInput, getIconUrl]);

  return {
    localFiles,
    localFilesLoading: false,
    onSearchLocalFiles: handleSearch,
  };
};

export default useLocalSearch;
