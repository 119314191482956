import { AttachmentItem } from "modules/portfolio/types";
import PortfolioAttachmentItem from "./PortfolioAttachmentItem";

interface PortfolioAttachmentListProps {
  attachments: AttachmentItem[];
  onRemove?: (index: number) => void;
  onDownload?: (attachment: AttachmentItem) => void;
  onClick?: (attachment: AttachmentItem) => void;
}
const PortfolioAttachmentList = ({
  attachments,
  onRemove,
  onDownload,
  onClick = () => {},
}: PortfolioAttachmentListProps) => {
  return (
    <>
      {attachments?.map((attachment: AttachmentItem, index: any) => (
        <div key={attachment.id} className="w-full" onClick={() => onClick(attachment)}>
          <PortfolioAttachmentItem
            attachment={attachment}
            onDownload={onDownload}
            onRemove={onRemove && (() => onRemove(index))}
          />
        </div>
      ))}
    </>
  );
};

export default PortfolioAttachmentList;
