import { get } from "lodash";
import { DropResult } from "react-beautiful-dnd";
import { useFormContext, useFieldArray } from "react-hook-form";

import { ChecklistBuilderForm, ChecklistSection } from "../types";

export const useBuilderSectionList = () => {
  const {
    watch,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext<ChecklistBuilderForm>();

  const watchedFields = watch("checklistSections");
  const { fields, append, move, remove } = useFieldArray({ control, name: "checklistSections" });

  const isAddingNewSection = watch("isAddingSection");

  const setIsAddingNewSection = (isAdding: boolean) => {
    setValue("isAddingSection", isAdding);
  };

  const sections = fields.map((field, index) => {
    return {
      ...field,
      ...watchedFields[index],
    };
  });

  const checklistSectionsError = get(errors, "checklistSections.message", "");

  const onAddSection = (section: ChecklistSection) => {
    append(section);
    setIsAddingNewSection(false);
    trigger("checklistSections");
  };

  const onAddNewSection = () => {
    setIsAddingNewSection(true);
  };

  const onDeleteSection = (sectionIndex: number) => {
    remove(sectionIndex);
  };

  const onSwap = (dropResult: DropResult) => {
    if (!dropResult.destination) return;
    if (dropResult.source.index === dropResult.destination.index) return;

    move(dropResult.source.index, dropResult.destination.index);
  };

  return {
    sections,
    isAddingNewSection,
    checklistSectionsError,
    onSwap,
    onAddSection,
    onDeleteSection,
    onAddNewSection,
    setIsAddingNewSection,
  };
};
