import React from "react";
import ReactModal, { Props } from "react-modal";

import { XIcon } from "Assets/Icons";

import { IconButton } from "../Button";

import "./Modal.css";

export interface ModalProps extends Omit<Props, "isOpen"> {
  open: boolean;
  className?: string;
  contentLabel?: string;
  overlayColor?: string;
  hasCloseIcon?: boolean;
  overlayClassName?: string;
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  "data-testid"?: string;
  onClose(): void;
}

export const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  children,
  className,
  contentLabel = "",
  overlayClassName,
  hasCloseIcon = true,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick,
  overlayColor = "rgba(0, 0, 0, 0.45)",
  "data-testid": dataTestId,
  ...props
}) => {
  return (
    <ReactModal
      isOpen={open}
      preventScroll
      ariaHideApp={false}
      onRequestClose={onClose}
      contentLabel={contentLabel}
      shouldCloseOnEsc={shouldCloseOnEsc}
      className={`
          modal relative rounded-b-none rounded-t-sm md:rounded-b-lg md:rounded-t-lg
          ${className}
        `}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={`
        flex items-end md:items-center justify-center
        fixed inset-0 z-40
        ${overlayClassName}
      `}
      style={{
        overlay: {
          backgroundColor: overlayColor,
        },
      }}
      testId={dataTestId}
      {...props}
    >
      {hasCloseIcon && (
        <IconButton
          size="sm"
          type="button"
          icon={<XIcon />}
          variant="rounded"
          onClick={onClose}
          data-testid="modal-close-button"
          className="absolute top-4 right-4 z-10"
        />
      )}

      {children}
    </ReactModal>
  );
};
