import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { EolasFile, OrganisationLevelSection } from "@eolas-medical/core";

import { useMainSectionVisit, useCompleteFile } from "Hooks";

import { EolasFileList, DeleteFileModal } from "Components";

import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import {
  AddNewsItemModal,
  NewsItemStatsModal,
  NewsItemViewModal,
} from "Pages/Spaces/components/NewsItems";

export const CommunicationsPortal: React.FC<RouteComponentProps> = observer(
  ({ match: { url } }) => {
    const { t } = useTranslation();
    const { onCompleteFile } = useCompleteFile();

    useMainSectionVisit({ mainSectionId: OrganisationLevelSection.communicationPortal });

    const { isAdmin } = useSpacesContext();
    const [modal, setModal] = useState<React.ReactNode>(null);

    const { spaceSection } = useSpaceSection(OrganisationLevelSection.communicationPortal);

    const onCloseModal = useCallback(() => {
      setModal(null);
    }, [setModal]);

    const onAddModal = useCallback(() => {
      setModal(
        <AddNewsItemModal
          onCloseModal={onCloseModal}
          mainSectionID={OrganisationLevelSection.communicationPortal}
        />,
      );
    }, [setModal, onCloseModal]);

    const onDeleteNewsItem = useCallback(
      (newsItem) => {
        setModal(<DeleteFileModal file={newsItem} onCloseModal={onCloseModal} />);
      },
      [setModal, onCloseModal],
    );

    const onShowStats = useCallback(
      (newsItem) => {
        setModal(
          <NewsItemStatsModal
            newsItem={newsItem}
            onCloseModal={onCloseModal}
            sectionType={OrganisationLevelSection.communicationPortal}
          />,
        );
      },
      [setModal, onCloseModal],
    );

    const onSelectFile = useCallback(
      (newsItem: EolasFile) => {
        if (!newsItem.type) {
          onCompleteFile(newsItem.id, "seen");
        }
        setModal(<NewsItemViewModal newsItem={newsItem} />);
      },
      [onCompleteFile],
    );

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection.name ?? t("communicationPortal_title")} />
          <NavButton to={url.replace(`/${OrganisationLevelSection.communicationPortal}`, "")} />

          {isAdmin && (
            <AddButton onClick={onAddModal} data-testid="add-new-file">
              {t(`${OrganisationLevelSection.communicationPortal}_add_new_file`)}
            </AddButton>
          )}

          <EolasFileList
            defaultSort="date"
            draggable={false}
            isAdmin={isAdmin}
            onEdit={onShowStats}
            onAddItem={onAddModal}
            onDelete={onDeleteNewsItem}
            onSelectFile={onSelectFile}
            mainSectionId={OrganisationLevelSection.communicationPortal}
          />
        </InnerPageWrapper>

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </>
    );
  },
);
