import { useState } from "react";
import { ForgotPasswordStep } from "Pages/Authentication/types";
import { Step, Wizard, useWizard } from "UIKit/Wizard";
import useForgotPassword from "modules/authentication/data/useForgotPassword";
import useResetPassword from "modules/authentication/data/useResetPassword";
import useCreatePasswordStep from "../CreatePasswordModal/useCreatePasswordStep";
import useVerificationCodeStep from "../VerificationCodeModal/useVerificationCodeStep";

import useSignInUser from "modules/authentication/data/useSignInUser";
import useResendVerificationCode from "modules/authentication/data/useResendVerificationCode";
import useAssistanceVerificationCode from "modules/authentication/data/useAssistanceVerificationCode";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";

interface ForgotPasswordWizardProps {
  email: string;
}

interface ForgotPasswordState {
  email: string;
  newPassword: string;
}

const ForgotPasswordWizard = ({ email }: ForgotPasswordWizardProps) => {
  const {
    forgotPassword,
    forgotPasswordLoading,
    forgotPasswordSuccess,
    forgotPasswordError,
  } = useForgotPassword();
  const {
    resetPassword,
    resettingPassword,
    resetPasswordError,
    resetPasswordSuccess,
  } = useResetPassword();

  const { resendVerificationCode } = useResendVerificationCode();

  const { requestAssistanceVerificationCode } = useAssistanceVerificationCode();

  const { signInUser } = useSignInUser();

  const [wizardState, setWizardState] = useState<ForgotPasswordState>({
    email,
    newPassword: "",
  });

  const { wizardControl, submitWizard } = useWizard();

  const handleResetPassword = (code: string) => {
    resetPassword(
      { email: wizardState.email, code, newPassword: wizardState.newPassword },
      {
        onSuccess: async () => {
          trackEvent(AnalyticsEvents.CORRECT_CODE_ENTERED_LOGIN_FLOW);
          await signInUser({ email: wizardState.email, password: wizardState.newPassword });
        },
        onError: () => {
          trackEvent(AnalyticsEvents.INCORRECT_CODE_ENTERED_LOGIN_FLOW);
        },
      },
    );
  };

  const handleSubmitForgotPassword = (newPassword: string) => {
    setWizardState((prev) => ({ ...prev, newPassword }));
    forgotPassword({ email: wizardState.email });
  };

  const handleResendCode = () => {
    resendVerificationCode(wizardState.email);
    requestAssistanceVerificationCode({
      queryParams: { email: wizardState.email, origin: "FORGOT_PASSWORD" },
    });
    trackEvent(AnalyticsEvents.DID_NOT_RECEIVE_CODE_LOGIN_FLOW);
  };

  const createPasswordStep = useCreatePasswordStep({
    isLoading: forgotPasswordLoading,
    canProceed: forgotPasswordSuccess,
    error: forgotPasswordError,
    onSubmit: handleSubmitForgotPassword,
    onEnter: submitWizard,
  });

  const verificationCodeStep = useVerificationCodeStep({
    email,
    isLoading: resettingPassword,
    canProceed: resetPasswordSuccess,
    error: resetPasswordError,
    onSubmit: handleResetPassword,
    onResendCode: handleResendCode,
    onEnter: submitWizard,
  });

  const steps: Step<ForgotPasswordStep>[] = [createPasswordStep, verificationCodeStep];

  return <Wizard wizardControl={wizardControl} steps={steps} showSteps={false} />;
};

export default ForgotPasswordWizard;
