import { AppUser } from "API";
import React from "react";

const USERS_PER_PAGE = 50;

export const useUserList = (users: AppUser[]) => {
  const [searchInput, setSearchInput] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);

  const onInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    setSearchInput(e.target.value.toLowerCase());
  }, []);

  const filteredUsers = React.useMemo(() => {
    if (searchInput === "") {
      return users;
    }

    return users.filter(({ user }) => {
      return (
        user?.given_name?.toLowerCase().includes(searchInput) ||
        user?.family_name?.toLowerCase().includes(searchInput) ||
        user?.email?.toLowerCase().includes(searchInput)
      );
    });
  }, [users, searchInput]);

  const usersSlice = React.useMemo(() => {
    return filteredUsers.slice(currentPage * USERS_PER_PAGE, (currentPage + 1) * USERS_PER_PAGE);
  }, [currentPage, filteredUsers]);

  const hasMorePages = React.useMemo(() => {
    return currentPage * USERS_PER_PAGE + USERS_PER_PAGE < users.length;
  }, [currentPage, users.length]);

  const tablePageDisplay = React.useMemo(() => {
    const to = hasMorePages ? currentPage * USERS_PER_PAGE + USERS_PER_PAGE : users.length;

    return {
      to,
      total: users.length,
      from: currentPage * USERS_PER_PAGE + 1,
    };
  }, [currentPage, hasMorePages, users.length]);

  return {
    usersSlice,
    currentPage,
    searchInput,
    hasMorePages,
    tablePageDisplay,
    onInputChange,
    setCurrentPage,
  };
};
