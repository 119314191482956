import i18n from "i18n";
import React from "react";
import { useHistory } from "react-router-dom";
import { AppLevelSection, getPreSignUrl, sectionStore } from "@eolas-medical/core";

import { EolasFile } from "Utilities/types";
import { generateS3PublicImageURL, useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { useNotifications } from "Components/Notifications";

export type SelectFileLoadingType = "notification" | "requestStatus";

export const openWindow = (url: string) => {
  const newWin = window.open(url);
  if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
    alert(i18n.t("popip_blocked_message"));
  }
};

export const useSelectFile = (loadingType: SelectFileLoadingType = "notification") => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();
  const { showNotification, hideNotification, updateNotification } = useNotifications();
  const { push } = useHistory();

  const _selectFile = React.useCallback(
    ({ key, type, mainSectionID, id }): Promise<string> => {
      return new Promise(async (resolve, reject) => {
        switch (type) {
          case "link":
          case "newsFeedLink":
            openWindow(key);
            resolve("opened");
            break;
          case "ms-office":
          case "mov":
          case "doc":
          case "docx":
          case "ppt":
          case "pptx":
          case "xls":
          case "xlsx":
          case "apng":
          case "png":
          case "jpg":
          case "jpeg":
          case "application/pdf":
          case "pdf":
            try {
              push(`/documents/viewer/${encodeURIComponent(id)}`);
              resolve(key);
            } catch (error: any) {
              errorStore.captureError({ error: error.message, source: "user" });
              reject(error.message);
            }

            break;
          case "mp4":
            try {
              let mp4URL;
              const mainSection = mainSectionID
                ? sectionStore.getMainSectionTypeFromMainSectionID(mainSectionID)
                : null;
              if (mainSection === AppLevelSection.patientLeaflets) {
                mp4URL = generateS3PublicImageURL(key);
              } else {
                mp4URL = await getPreSignUrl(key);
              }
              resolve(mp4URL.toString());
            } catch (error: any) {
              errorStore.captureError({ error: error.message, source: "user" });
              reject(error.message);
            }

            break;
          case "img":
          default:
            reject(i18n.t("selectFile_type_not_supported", { type }));
        }
      });
    },
    [push],
  );

  const onSelectFile = React.useCallback(
    async ({ name, key, type, mainSectionID, id }: Partial<EolasFile>): Promise<string> => {
      if (!key) return "";
      const promise = _selectFile({ key, type, mainSectionID, id });

      if (loadingType === "notification") {
        const _notification = showNotification({
          type: "loading",
          title: name as string,
          description: i18n.t("notifications_open_file"),
        });

        return promise
          .then((response) => {
            hideNotification(_notification.id);
            return response;
          })
          .catch(() => {
            updateNotification({
              type: "error",
              title: name as string,
              autoHideTimeout: 5000,
              id: _notification.id,
              description: i18n.t("notifications_open_file_error"),
            });
            throw new Error(i18n.t("notifications_open_file_error"));
          });
      } else {
        setRequestStatus({ status: "pending", error: "" });
        return promise
          .then((response) => {
            setRequestStatus({ status: "success", error: "" });
            return response;
          })
          .catch((error) => {
            setRequestStatus({ status: "error", error });
            throw new Error(i18n.t("notifications_open_file_error"));
          });
      }
    },
    [
      loadingType,
      _selectFile,
      setRequestStatus,
      hideNotification,
      showNotification,
      updateNotification,
    ],
  );

  return { isLoading, error, onSelectFile, openWindow };
};
