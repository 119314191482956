import { EventStatus, PortfolioComplexComponent } from "modules/portfolio/types";
import { ButtonColorScheme } from "UIKit/Button/types";
import { ButtonVariant } from "UIKit/Button/types";

interface GetFormElementConfig {
  componentName: PortfolioComplexComponent;
  eventStatus?: EventStatus;
  areAttachmentsShared?: boolean;
}

type FormElementConfig = {
  text: string;
  icon?: string | null;
  buttonColor?: ButtonColorScheme;
  variant?: ButtonVariant;
  onClick?: () => any;
};

const useFormElementConfig = () => {
  const getFormElementConfig = ({ componentName }: GetFormElementConfig): FormElementConfig => {
    switch (componentName) {
      case PortfolioComplexComponent.ATTACH_BUTTON: {
        return {
          icon: "AttachIcon",
          text: "Attach Evidence",
          buttonColor: "grey",
          variant: "outline",
        };
      }
    }
  };

  return { getFormElementConfig };
};

export default useFormElementConfig;
