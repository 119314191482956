import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import { Button, Modal, NavButton } from "UIKit";
import { FileViewerComponent, useEolasFileViewer, VideoPlayer } from "Components";
import useMyFileDownload from "modules/myFiles/data/useMyFileDownload";
import { isVideoType } from "Pages/MeTab/pages/MyFiles/helpers";
import { MyFile } from "modules/myFiles/types";

export type DocumentRouteParams = {
  fileId: string;
};

export const FileViewer: React.FC<RouteComponentProps<DocumentRouteParams>> = observer(
  ({
    history: { goBack, length },
    match: {
      params: { fileId },
    },
  }) => {
    const { getFileUrlWithUrl, download } = useMyFileDownload();

    const { viewer, file, onFileLoaded, onFileError, setFile } = useEolasFileViewer<MyFile>();

    const fetchFileGetUrl = useCallback(async () => {
      const file = await getFileUrlWithUrl(fileId);
      if (file) setFile(file);
    }, [fileId, getFileUrlWithUrl, setFile]);

    const handleDownloadOriginal = useCallback(() => {
      if (file) {
        download(file.id);
      }
    }, [download, file]);

    useEffect(() => {
      fetchFileGetUrl();
    }, [fetchFileGetUrl]);

    return (
      <Modal hasCloseIcon={false} overlayColor="bg-grey-light" open={true} onClose={goBack}>
        <div className="h-100vh w-100vw">
          {length > 1 && (
            <div className="bg-grey-light py-1">
              <div className="flex justify-center space-x-3">
                <NavButton onClick={goBack} />
                <Button size="sm" onClick={handleDownloadOriginal}>
                  Download Original
                </Button>
              </div>
            </div>
          )}
          <div className="bg-grey-light overflow-auto h-full">
            {isVideoType(file?.blob?.type) && file?.blob?.getURL ? (
              <div className="p-4">
                <VideoPlayer
                  url={file.blob.getURL}
                  title={file.name}
                  onLoad={onFileLoaded}
                  onError={onFileError}
                />
              </div>
            ) : (
              viewer && <FileViewerComponent viewer={viewer} />
            )}
          </div>
        </div>
      </Modal>
    );
  },
);
