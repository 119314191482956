import { Step } from "UIKit";
import { useTranslation } from "react-i18next";
import SpaceImage from "./SpaceImage";
import { useState } from "react";

interface useSpaceDescriptionStepProps {
  isLoading?: boolean;
  error?: string;
  onSubmit: ({ image, imageUrl }: { image?: File; imageUrl?: string }) => void;
}

export const useSpaceImageStep = ({
  isLoading,
  error,
  onSubmit,
}: useSpaceDescriptionStepProps): Step<"image"> => {
  const { t } = useTranslation();

  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>();

  const handleSubmitSpaceImage = async () => {
    onSubmit({ image, imageUrl });
  };

  const handleSubmitImage = (file: File | null) => {
    if (file) {
      setImage(file);
    }
  };

  const handleSelectImage = (imageUrl: string) => {
    setImageUrl(imageUrl);
  };

  return {
    name: "image",
    title: t("space_create_cover_image_step"),
    component: <SpaceImage onChangeBlob={handleSubmitImage} onChangeImageUrl={handleSelectImage} />,
    onSubmit: handleSubmitSpaceImage,
  };
};
