import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AnalyticsEvents } from "@eolas-medical/core";

import { trackEvent, timeEvent } from "API/Analytics";
import { InnerModalWrapper, LottieWithHeader, SuccessModal } from "UIKit";
import { emailSent, PasswordConfirmIcon, ForgotPasswordIcon } from "Assets";

import { PasswordForm } from "./PasswordForm";
import { PasswordReset } from "./PasswordReset";

import { useRegistrationStage } from "../hooks";
import "../styles.css";

export interface ForgotPasswordModalProps {
  closeModal(): void;
}

type Steps = "enterEmail" | "verificationAnimation" | "enterCodeAndPassword" | "complete";

export const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();

  const [userEmail, setUserEmail] = useState(null);
  const { setStage, currentStage } = useRegistrationStage<Steps>("enterEmail");

  useEffect(() => {
    trackEvent(AnalyticsEvents.FORGOT_PASSWORD_STARTED);
    timeEvent(AnalyticsEvents.FORGOT_PASSWORD_DURATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentStage === "complete") {
    return (
      <SuccessModal onComplete={closeModal} text={t("createAccount_forgotPassword_changed")} />
    );
  }

  if (currentStage === "verificationAnimation") {
    return (
      <InnerModalWrapper>
        <LottieWithHeader
          className="py-24"
          animationSize="20%"
          textClassName="mb-8"
          animation={emailSent}
          onComplete={setStage("enterCodeAndPassword")}
          text={t("createAccount_verifyAnimationTitle")}
        />
      </InnerModalWrapper>
    );
  }

  return (
    <InnerModalWrapper>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col flex-1 items-center justify-center lg:bg-grey-light lg:rounded-l-2xl">
          {currentStage === "enterEmail" && (
            <ForgotPasswordIcon className="my-6" height={55} width={55} />
          )}
          {currentStage === "enterCodeAndPassword" && (
            <PasswordConfirmIcon className="hidden lg:flex" height={55} width={55} />
          )}
        </div>

        {currentStage === "enterEmail" && (
          <PasswordForm setUserEmail={setUserEmail} setStage={setStage("verificationAnimation")} />
        )}

        {currentStage === "enterCodeAndPassword" && (
          <PasswordReset
            userEmail={userEmail}
            onFormSubmit={setStage("complete")}
            onResendCode={setStage("verificationAnimation")}
          />
        )}
      </div>
    </InnerModalWrapper>
  );
};
