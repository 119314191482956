import { Step } from "UIKit";
import { useTranslation } from "react-i18next";
import TemplateSelector from "./TemplateSelector";
import { useState } from "react";

interface useSpaceTemplateSelectorStepProps {
  isLoading?: boolean;
  spaceTemplateId: string;
  onSubmit: () => void;
  onChange: ({ spaceTemplateId }: { spaceTemplateId: string }) => void;
  errorCreatingSpace: string;
}

export const useSpaceTemplateSelectorStep = ({
  isLoading,
  onSubmit,
  onChange,
  spaceTemplateId,
  errorCreatingSpace,
}: useSpaceTemplateSelectorStepProps): Step<"templateSelector"> => {
  const [validationError, setValidationError] = useState("");
  const { t } = useTranslation();

  const handleTemplateSelection = ({ spaceTemplateId }: { spaceTemplateId: string }) => {
    setValidationError("");
    onChange({ spaceTemplateId });
  };

  const handleSubmitTemplateSelector = async () => {
    if (spaceTemplateId === "") {
      setValidationError(t("select_template_error"));
    } else {
      onSubmit();
    }
  };

  return {
    name: "templateSelector",
    title: t("space_create_select_template"),
    component: (
      <TemplateSelector
        onChange={handleTemplateSelection}
        spaceTemplateId={spaceTemplateId}
        error={validationError || errorCreatingSpace}
      />
    ),
    onSubmit: handleSubmitTemplateSelector,
    isLoading,
    canProceed: !!validationError,
    submitError: !!errorCreatingSpace,
    nextLabel: t("general_finish"),
  };
};
