import useImageBank from "Hooks/useImageBank";
import ImagePicker from "UIKit/ImagePicker/ImagePicker";
import { generateS3PublicImageURL } from "Utilities";

interface FlashcardHeroImageStepProps {
  errorDraggedImageText: string;
  onChangeBlob: (file: File | null) => void;
  onChangeImageUrl: (imageUrl: string) => void;
  defaultImage: string;
}

export const FlashcardHeroImageStep = ({
  errorDraggedImageText,
  onChangeBlob,
  onChangeImageUrl,
  defaultImage,
}: FlashcardHeroImageStepProps) => {
  const { imageBank, imageBankLoading } = useImageBank();

  if (imageBankLoading) return null;

  const isCustomImage = !imageBank.some((item) => item.imageUrl === defaultImage);

  const imageUrl = isCustomImage ? generateS3PublicImageURL(defaultImage) : defaultImage;

  return (
    <ImagePicker
      galleryImages={imageBank}
      errorDraggedImageText={errorDraggedImageText}
      isLoading={imageBankLoading}
      onChangeBlob={onChangeBlob}
      onChangeImageUrl={onChangeImageUrl}
      defaultImage={defaultImage ? { url: imageUrl, isCustomImage } : undefined}
    />
  );
};
