import { useEffect, useState } from "react";

const getDeviceType = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.includes("mobile") || userAgent.includes("android")) {
    return "phone";
  } else if (userAgent.includes("tablet")) {
    return "tablet";
  } else {
    return "desktop";
  }
};

export type DeviceType = "phone" | "tablet" | "desktop";

const useDeviceType = (): DeviceType => {
  const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType());

  useEffect(() => {
    const detectDeviceType = () => {
      setDeviceType(getDeviceType());
    };

    window.addEventListener("resize", detectDeviceType);

    return () => {
      window.removeEventListener("resize", detectDeviceType);
    };
  }, []);

  return deviceType;
};

export default useDeviceType;
