import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

import { Text, IconButton, theme } from "UIKit";
import { LoginTokenIcon, CopyIcon, DeleteIcon } from "Assets";

import { SetTokensFn } from "./types";
import { useLoginToken } from "./useLoginToken";

interface LoginTokenProps {
  token: string;
  setTokens: SetTokensFn;
}

export const LoginToken: React.FC<LoginTokenProps> = ({ token, setTokens }) => {
  const { t } = useTranslation();
  const { tooltipText, onCopyClicked, afterTooltipHide, onDeleteToken, isLoading } = useLoginToken(
    token,
    setTokens,
  );

  return (
    <div className="flex items-center p-6">
      <LoginTokenIcon className="w-10 h-10" />

      <div className="flex flex-col md:flex-row">
        <Text className="flex-1 text-grey-700 font-bold ml-4" level={2}>
          {`${window.location.origin}/auto-login/${encodeURIComponent(token)}`}
        </Text>

        <ReactTooltip
          border
          type="light"
          place="bottom"
          effect="solid"
          id={`copy-${token}`}
          className={"tooltip"}
          afterHide={afterTooltipHide}
          backgroundColor={theme.colors.white}
          borderColor={theme.colors.grey.darker}
        >
          <span className="block font-normal">{t(tooltipText)}</span>
        </ReactTooltip>

        <div className="flex items-center justify-end space-x-2">
          <IconButton
            size="sm"
            data-tip
            variant="rounded"
            icon={<CopyIcon />}
            onClick={onCopyClicked}
            data-for={`copy-${token}`}
          />

          <IconButton
            size="sm"
            variant="rounded"
            isLoading={isLoading}
            icon={<DeleteIcon />}
            onClick={onDeleteToken}
          />
        </div>
      </div>
    </div>
  );
};
