import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ClientMainSectionTile, InnerPageWrapper, PageTitle, GridContainer } from "UIKit";
import { useIcons } from "Hooks";
import useSpaceAdminSections from "modules/spaces/data/useSpaceAdminSections";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { getSpaceRoute } from "Pages/Spaces/helpers";

const SpaceAdmin = observer(() => {
  const { t } = useTranslation();
  const { selectedSpace } = useSpacesContext();
  const { getSpaceAdminDashboardIcon } = useIcons();
  const { activeAdminMainSections } = useSpaceAdminSections();

  return (
    <InnerPageWrapper>
      <PageTitle data-testid="admin-home" title={t("adminHome_title")} />

      <GridContainer>
        {activeAdminMainSections.map(({ id, route, title, description, disabled }) => {
          const Icon = getSpaceAdminDashboardIcon(id);

          const component = (
            <ClientMainSectionTile
              id={id as any}
              title={t(title)}
              description={t(description)}
              Icon={<Icon className="h-8 w-8 lg:h-14 lg:w-14" />}
              disabled={disabled}
            />
          );

          if (!disabled) {
            return (
              <Link key={id} to={`${getSpaceRoute(selectedSpace)}/${route}`} data-testid={title}>
                {component}
              </Link>
            );
          }

          return (
            <div key={id}>
              <ClientMainSectionTile
                id={id as any}
                title={t(title)}
                description={t(description)}
                Icon={<Icon className="h-8 w-8 lg:h-14 lg:w-14" />}
                disabled={disabled}
              />
            </div>
          );
        })}
      </GridContainer>
    </InnerPageWrapper>
  );
});

export default SpaceAdmin;
