import { AuthFunctionNames, authenticationClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { errorStore } from "Stores/ErrorStore";

const useSignUpUser = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(authenticationClient.signUp, {
    onError: (error: string) => {
      errorStore.captureError({
        error,
        source: "user",
        authFunctionName: AuthFunctionNames.signUp,
      });
    },
  });

  return {
    signUpUser: mutate,
    isSigningUp: isLoading,
    signUpError: error || undefined,
    signUpSuccessful: isSuccess,
  };
};

export default useSignUpUser;
