import { EolasList } from "Components/EolasFileList/EolasList";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import useContentSections from "modules/contentRepository/data/useContentSections";
import { ContentSection, ContentRepositoryId } from "modules/contentRepository/types";
import { useCallback } from "react";
import ContentSectionItem from "../ContentSectionItem/ContentSectionItem";
import useReorderContent from "modules/contentRepository/data/useReorderContent";

interface ContentSectionListProps {
  contentRepositoryId: ContentRepositoryId;
  isAdmin: boolean;
}

const ContentSectionList = ({ contentRepositoryId, isAdmin }: ContentSectionListProps) => {
  const { contentSections, contentSectionsLoading } = useContentSections(contentRepositoryId);

  const { reorderContent } = useReorderContent(contentRepositoryId);

  const { searchInput, searchResult, onSetSearchInput } = useLocalSearch<ContentSection>({
    data: contentSections,
    keysToSearch: ["name"],
  });

  const handleDragSection = useCallback(
    ({ dropResult }) => {
      reorderContent(dropResult);
    },
    [reorderContent],
  );

  const renderItem = useCallback(
    (contentSection: ContentSection) => {
      return (
        <ContentSectionItem
          contentRepositoryId={contentRepositoryId}
          contentSection={contentSection}
          isAdmin={isAdmin}
        />
      );
    },
    [contentRepositoryId, isAdmin],
  );

  return (
    <div data-testid="content-section-list">
      <EolasList<ContentSection>
        items={searchResult}
        renderItem={renderItem}
        onSearchInputChange={onSetSearchInput}
        isSearchable
        isDragable={searchInput?.length === 0 && isAdmin}
        isLoading={contentSectionsLoading}
        onDragEnd={handleDragSection}
      />
    </div>
  );
};

export default ContentSectionList;
