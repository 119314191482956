import { AdminMonoIcon, HomeMonoIcon } from "Assets/Icons";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text } from "UIKit";
import { useRoleSwitcher } from "../../hooks";

export const DesktopRoleSwitcher = observer(() => {
  const { t } = useTranslation();
  const { isAdmin, switchToAdmin, switchToUser } = useRoleSwitcher();

  return (
    <>
      <button
        onClick={switchToUser}
        data-testid="role-switcher_home-button"
        className={`
          flex items-center justify-center h-12 w-24 rounded-xl border bg-white hover:bg-grey-50
          ${!isAdmin ? "text-blue-500 border-blue-100" : "text-black border-grey-300"}
        `}
      >
        <HomeMonoIcon className="h-6 w-6" />
        <Text level={2} className="font-bold ml-2">
          {t("navigation_home")}
        </Text>
      </button>

      <button
        onClick={switchToAdmin}
        data-testid="role-switcher_admin-button"
        className={`
          flex items-center justify-center h-12 w-36 rounded-xl border bg-white hover:bg-grey-50
          ${isAdmin ? "text-blue-500 border-blue-100" : "text-black border-grey-300"}
        `}
      >
        <AdminMonoIcon className="h-6 w-6" />
        <Text level={2} className="font-bold ml-2">
          {t("navigation_admin_panel")}
        </Text>
      </button>
    </>
  );
});
