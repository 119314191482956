import React, { useState, useMemo, useCallback, useEffect } from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";
import { SearchBox } from "UIKit/SearchBox/SearchBox";
import { ClickSearchBox, Loader, VirtualGrid, VirtualSortableList } from "UIKit";
import { useSortOrder } from "Hooks/useSortOrder";
import { ListOrderByOption } from "Components/EolasFileList/ListOrderBy";
import { DragIcon } from "Assets";
import { DropResult } from "react-beautiful-dnd";

export type SortFn = <T>(a: T, b: T) => number;

export type SearchType = "debounced" | "click";
export interface DragResult<T> {
  newPrevItem?: T;
  reorderedItem: T;
  newNextItem?: T;
  dropResult?: DropResult;
}

export interface EolasListItem {
  id: string;
  isDragDisabled?: boolean;
}
export interface EolasListProps<T extends EolasListItem, S extends SearchType> {
  items: T[];
  renderItem: (item: T, isDragging?: boolean) => React.ReactNode;
  className?: string;
  isSearchable?: boolean;
  isSortable?: boolean;
  emptyItemsLabel?: string;
  emptyItemsComponent?: React.ReactNode;
  isDragable?: boolean;
  defaultSort?: ListOrderByOption;
  displayMode?: "list" | "grid";
  sortDateBy?: "createdAt" | "updatedAt";
  searchType?: S;
  infoText?: string;
  hasFavourites?: boolean;
  isLoading?: boolean;
  sortFn?(first: T, second: T): number;
  onSearchInputChange?: (inputText: string) => void;
  onSortMethodChange?: (sortFn?: SortFn) => void;
  onDragEnd?: ({ newPrevItem, reorderedItem, newNextItem, dropResult }: DragResult<T>) => void;
  onClickSearch?: () => void;
  onClearSearch?: () => void;
  value?: string;
  placeholderSearchText?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type RequireClickSearchProps<T, S> = S extends "click"
  ? {
      onClickSearch: () => void;
      onClearSearch: () => void;
    }
  : {};

export const EolasList = <T extends EolasListItem, S extends SearchType = "debounced">({
  items,
  renderItem,
  className,
  isSearchable = false,
  isSortable = false,
  emptyItemsLabel = "Nothing found",
  emptyItemsComponent = null,
  isDragable = false,
  displayMode = "list",
  sortDateBy = "updatedAt",
  defaultSort = "dragAndDrop",
  infoText,
  hasFavourites = false,
  isLoading,
  searchType,
  sortFn,
  onSearchInputChange = () => {},
  onSortMethodChange,
  onDragEnd = () => {},
  onClickSearch = () => {},
  onClearSearch = () => {},
  value,
  placeholderSearchText,
}: EolasListProps<T, S> & RequireClickSearchProps<EolasListProps<T, S>, S>) => {
  const [eolasList, setEolasList] = useState(items);

  const [SortComponent, { orderBy, sortMethod }] = useSortOrder({
    initialOrder: defaultSort,
    isDraggable: isDragable,
    sortDateBy,
    favourites: hasFavourites,
    sortFn,
  });

  /**
   * Sets the eolasList list every time the items change
   */
  useEffect(() => {
    setEolasList(items);
  }, [items]);

  /**
   * When isSortable it calls the onSortMethodChange callback
   * every time the sortMethod changes
   */
  useEffect(() => {
    if (isSortable && onSortMethodChange) {
      onSortMethodChange(sortMethod);
    }
  }, [isSortable, onSortMethodChange, sortMethod]);

  /**
   * Calls the onSearchInputChange callback
   */
  const handleSearchInputChanged = useCallback(
    (text: string) => {
      if (isSearchable && onSearchInputChange) {
        onSearchInputChange(text);
      }
    },
    [onSearchInputChange, isSearchable],
  );

  /**
   * Updated the intermediate list with a reordered version of the items
   */
  const handleDragEnd = useCallback(
    (dropResult) => {
      const { source, destination } = dropResult;

      if (!destination) return;
      if (source.index === dropResult.index) return;

      const { index: destinationIndex } = destination;
      const { index: sourceIndex } = source;

      const newItems = [...eolasList];
      const [reorderedItem] = newItems.splice(sourceIndex, 1);
      newItems.splice(destinationIndex, 0, reorderedItem);

      setEolasList(newItems);

      const newIndex = newItems.indexOf(reorderedItem);
      const newPrevItem = newItems[newIndex - 1]; // returns undefined for -1
      const newNextItem = newItems[newIndex + 1];

      onDragEnd({ newPrevItem, reorderedItem, newNextItem, dropResult });
    },
    [onDragEnd, eolasList],
  );

  const canDrag = useMemo(() => isDragable && eolasList.length > 1 && orderBy === "dragAndDrop", [
    isDragable,
    eolasList,
    orderBy,
  ]);

  const renderList = () => {
    if (isLoading) return <Loader className="bg-transparent h-30vh" />;

    if (eolasList?.length === 0 && emptyItemsComponent) {
      return <>{emptyItemsComponent}</>;
    }

    return (
      <>
        {isSortable && eolasList.length > 1 && <div className="mb-6">{SortComponent}</div>}

        {canDrag && (
          <div className="items-center justify-center space-x-2 mb-6 hidden sm:flex">
            <DragIcon width={16} height={16} className="text-grey-500" />
            <span className="text-center text-grey-mid inline">
              Click and drag to easily rearrange the order
            </span>
          </div>
        )}

        {infoText && <span className="text-center text-grey-darker mb-6">{infoText}</span>}

        <div>
          {displayMode === "grid" ? (
            <VirtualGrid items={eolasList} renderItem={renderItem} />
          ) : (
            <VirtualSortableList
              items={eolasList}
              onDragEnd={handleDragEnd}
              droppableId="eolas-file-list"
              isDragDisabled={!canDrag}
              emptyListLabel={emptyItemsLabel}
              renderItem={renderItem}
            />
          )}
        </div>
      </>
    );
  };

  const renderSearchBox = () => {
    if (searchType === "click") {
      return (
        <ClickSearchBox
          value={value}
          onChangeText={handleSearchInputChanged}
          onClickSearch={onClickSearch}
          onClearSearch={onClearSearch}
          placeholder={placeholderSearchText}
        />
      );
    }
    return (
      <SearchBox onChangeText={handleSearchInputChanged} placeholder={placeholderSearchText} />
    );
  };

  return (
    <div data-testid="eolas-list" className={tw(`flex flex-col space-y-6 pb-8 ${className}`)}>
      {isSearchable && renderSearchBox()}
      <div data-testid="eolas-list-items-container">{renderList()}</div>
    </div>
  );
};
