import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { sectionStore, userStore } from "@eolas-medical/core";

import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import useEolasNavigation from "../context/useEolasNavigation";
import useSpacesContext from "modules/spaces/context/useSpacesContext";

export type EolasRole = "admin" | "user";

export const useRoleSwitcher = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { flags } = useLaunchDarkly();

  const { activeTab, hasSelectedSpace } = useEolasNavigation();

  const { onSwitchToAdmin } = useSpacesContext();

  const isMyMed = !sectionStore.activeDepartmentTab;

  const { appUserData: appUser } = userStore;
  const accessLevel = flags[LDFlagNames.READ_ONLY_ACTIVATED] ? "user" : appUser.accessLevel;

  const rootPath = React.useMemo(() => {
    const actualPath = pathname.split("/");
    return `${actualPath[1]}/${actualPath[2]}`;
  }, [pathname]);

  const { isSpaceActive, isOrganisationActive } = React.useMemo(() => {
    const isSpaceActive = activeTab === "spaces";

    const isOrganisationActive = activeTab === "organisation";

    return {
      isOrganisationActive,
      isSpaceActive,
    };
  }, [activeTab]);

  const showSwitcher = React.useMemo(() => {
    if (activeTab === "my-profile" || activeTab === "knowledge") return false;
    if (!hasSelectedSpace) return false;

    if (accessLevel === "admin" && isSpaceActive) return true;
    if (
      (flags[LDFlagNames.SHOW_HOSPITAL_ADMIN] || isMyMed) &&
      accessLevel === "admin" &&
      isOrganisationActive
    )
      return true;

    return false;
  }, [
    activeTab,
    accessLevel,
    flags,
    isSpaceActive,
    isOrganisationActive,
    hasSelectedSpace,
    isMyMed,
  ]);

  const switchToUser = React.useCallback(() => {
    sectionStore.setIsAdmin(false);
    onSwitchToAdmin(false);
    push(`/${rootPath}`);
  }, [rootPath, onSwitchToAdmin, push]);

  const switchToAdmin = React.useCallback(() => {
    sectionStore.setIsAdmin(true);
    onSwitchToAdmin(true);
    push(`/${rootPath}`);
  }, [rootPath, onSwitchToAdmin, push]);

  return {
    showSwitcher,
    isAdmin: sectionStore.isAdmin,
    switchToUser,
    switchToAdmin,
  };
};
