import { useTranslation } from "react-i18next";
import { overrideTailwindClasses as tw } from "tailwind-override";

import { AttentionIcon } from "Assets";

import { Text } from "../Typography";

export const FormFieldError: React.FC<{
  className?: string;
}> = ({ children, className = "", ...props }) => {
  const { t } = useTranslation();
  return (
    <div className={tw(`flex items-center pl-1 h-5 space-x-1 ${className}`)} {...props}>
      {children ? (
        <>
          <AttentionIcon className="text-red-500" width={12} height={12} />
          <Text level={3} className="text-red-500">
            {t(children as string)}
          </Text>
        </>
      ) : null}
    </div>
  );
};

export const FormError: React.FC<{ className?: string; error?: string }> = ({
  error,
  className,
}) => {
  if (!error) return null;

  return (
    <div className={tw(`flex items-center justify-center space-x-2 mt-4 sm:mt-8 ${className}`)}>
      <Text level={1} className="font-semibold text-red text-center">
        {error}
      </Text>
    </div>
  );
};
