import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";

import { Modal } from "UIKit";

import { SearchResult, SearchType } from "./types";

import useMasterSearch from "./hooks/useMasterSearch";
import useSelectResult from "./hooks/useSelectResult";
import MasterSearchResults from "./components/MasterSearchResults";
import useRecentSearches from "./hooks/useRecentSearches";
import { trackEvent } from "API/Analytics";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

const MasterSearch = observer(() => {
  const [searchType, setSearchType] = useState<SearchType>("all");
  const { userID = "", userSession } = userStore;
  const { flags } = useLaunchDarkly();
  const knowledgeSearchDisabled = !flags[LDFlagNames.COMMUNITY_TAB];

  const { recentSearches, onSetSearchInput } = useRecentSearches();

  const {
    searchInput,
    searchValue,
    searchLoading,
    searchResult,
    onSearchInputChange,
    onSearch,
    onClearSearch,
  } = useMasterSearch({ userId: userID, searchType, knowledgeSearchDisabled });

  const { modal, onSelectResult, onCloseModal } = useSelectResult();

  // Function to handle changes in the search text input
  const handleChangeText = useCallback(
    (text: string) => {
      onSearchInputChange(text);
      onSetSearchInput(text);
    },
    [onSearchInputChange, onSetSearchInput],
  );

  // Function to handle click on the search button
  const handleClickSearch = useCallback(
    (searchText?: string) => {
      onSearch(searchText);
      trackEvent(AnalyticsEvents.MASTER_SEARCH_PERFORMED, { searchString: searchText });
      setSearchType("all");
    },
    [onSearch],
  );

  // Function to handle clearing the search input
  const handleClearSearch = useCallback(() => {
    onSetSearchInput("");
    onClearSearch();
  }, [onClearSearch, onSetSearchInput]);

  // Function to handle click on a filter pill
  const handleClickFilter = useCallback(
    (searchType: SearchType) => {
      setSearchType(searchType);
      trackEvent(AnalyticsEvents.SEARCH_FILTER_USED, { searchString: searchValue, searchType });
    },
    [searchValue],
  );

  // Function to handle click on a search result
  const handleClickResult = useCallback(
    (result: SearchResult) => {
      onSelectResult(result);
      trackEvent(AnalyticsEvents.SEARCH_RESULT_CLICKED, {
        searchString: searchValue,
        fileIdentity: result.searchTypeSection,
        mainSection: result.searchSubType,
        itemName: result.title,
        itemId: result.id,
      });
    },
    [searchValue, onSelectResult],
  );

  const handleClickMessage = useCallback((searchType: SearchType) => {
    setSearchType(searchType);
  }, []);

  return (
    <>
      <MasterSearchResults
        recentSearches={recentSearches}
        searchInput={searchInput}
        searchValue={searchValue}
        searchLoading={searchLoading && searchInput.length > 0}
        searchResult={searchResult}
        searchType={searchType}
        onClickMessage={handleClickMessage}
        knowledgeSearchDisabled={knowledgeSearchDisabled}
        localSearchDisabled={!userSession.isInOrganisation}
        onClearSearch={handleClearSearch}
        onChangeText={handleChangeText}
        onClickFilter={handleClickFilter}
        onClickSearch={handleClickSearch}
        onClickResult={handleClickResult}
      />

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});

export default MasterSearch;
