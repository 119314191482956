import * as Yup from "yup";

const validateDuplicateNames = (fileNames: string[], newValue?: string, existingName?: string) => {
  const isDuplicate = fileNames.some(
    (name) => name.toLocaleLowerCase().trim() === newValue?.toLocaleLowerCase().trim(),
  );

  if (isDuplicate) {
    return newValue === existingName;
  }
  return !isDuplicate;
};

const requiredNameMessage = "File name is required";
const duplicateNameMessage = "my_files_error_duplicate_name";
const requiredUrlMessage = "Url is required";

const fileName = Yup.string().required(requiredNameMessage);

export const blobValidationSchema = (fileNames: string[], existingName?: string) => {
  return Yup.object().shape({
    name: fileName.test("nonDuplicate", duplicateNameMessage, (newValue) =>
      validateDuplicateNames(fileNames, newValue, existingName),
    ),
  });
};

export const linkValidationSchema = (fileNames: string[], existingName?: string) => {
  return Yup.object().shape({
    name: fileName.test("nonDuplicate", duplicateNameMessage, (newValue) =>
      validateDuplicateNames(fileNames, newValue, existingName),
    ),
    url: Yup.string().required(requiredUrlMessage),
  });
};
