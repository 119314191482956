import { useMemo } from "react";
import { MedusaStatus } from "@eolas-medical/core";

import { LoaderWrapper } from "UIKit";

export interface MedusaStatusProps {
  status: MedusaStatus;
  className?: string;
}

export const MedusaStatusView = ({ status, className = "" }: MedusaStatusProps) => {
  const statusView = useMemo(() => {
    switch (status) {
      case "error":
        return (
          <span className={`px-4 py-2 bg-red-50 text-red rounded ${className}`}>{status}</span>
        );
      case "enabled":
        return (
          <span className={`px-4 py-2 bg-green-50 text-green rounded ${className}`}>{status}</span>
        );
      case "pending":
        return (
          <LoaderWrapper className={`px-4 py-2 bg-yellow-50 text-yellow rounded ${className}`}>
            {status}
          </LoaderWrapper>
        );
      case "processing":
        return (
          <LoaderWrapper className={`px-4 py-2 bg-darkblue-50 text-darkblue rounded ${className}`}>
            {status}
          </LoaderWrapper>
        );
      case "disabled":
      default:
        return (
          <span className={`px-4 py-2 bg-grey-100 text-grey-500 rounded ${className}`}>
            {status}
          </span>
        );
    }
  }, [status, className]);

  return statusView;
};
