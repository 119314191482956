import { accessClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { AccessLinkToken } from "../pages/AccessLink/AccessLink";

interface ValidateAccessProps {
  email: string;
  token: AccessLinkToken;
}

export interface ResponseValidateAccessToken {
  createdAt: string;
  departmentId: string;
  id: string;
  updatedAt: string;
  userExists: boolean;
}

const useValidateAccessToken = () => {
  const { mutate, isLoading, error } = useMutation<
    ResponseValidateAccessToken,
    Error,
    ValidateAccessProps
  >(async ({ email, token }) => {
    return accessClient.validateAccessToken(token, email);
  });

  return {
    validateAccessToken: mutate,
    validatingAccessToken: isLoading,
    validateAccessTokenError: error,
  };
};

export default useValidateAccessToken;
