import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ChecklistNotifications } from "../types";
import { useTranslation } from "react-i18next";
import { Option } from "UIKit/Select/Select";

const ACTIVE_NOTIFICATIONS = "activeNotifications";
const OWNER = "owner";

export const useAddChecklistOwner = ({
  defaultOwner,
  defaultActiveNotifications = [],
}: {
  defaultOwner?: Option;
  defaultActiveNotifications?: ChecklistNotifications[];
} = {}) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    [OWNER]: yup.object().required(t("checklistBuilder_owner_error_message")),
    [ACTIVE_NOTIFICATIONS]: yup
      .array()
      .of(yup.string())
      .min(1, t("checklistBuilder_notification_error_message")),
  });

  const { control, handleSubmit, setValue, clearErrors } = useForm({
    defaultValues: {
      [ACTIVE_NOTIFICATIONS]: defaultActiveNotifications,
      [OWNER]: defaultOwner,
    },
    resolver: yupResolver(schema),
  });

  const handleClickToggleButton = useCallback(
    (activeNotifications: ChecklistNotifications[], notification: ChecklistNotifications) => {
      if (activeNotifications.includes(notification)) {
        setValue(
          ACTIVE_NOTIFICATIONS,
          activeNotifications.filter((n) => n !== notification),
        );
      } else {
        setValue(ACTIVE_NOTIFICATIONS, [...activeNotifications, notification]);
      }
      clearErrors();
    },
    [setValue, clearErrors],
  );

  const onClickChecklistCompleted = useCallback(
    (activeNotifications: ChecklistNotifications[]) =>
      handleClickToggleButton(activeNotifications, ChecklistNotifications.IS_COMPLETED),
    [handleClickToggleButton],
  );

  const onClickIssuesFlagged = useCallback(
    (activeNotifications: ChecklistNotifications[]) =>
      handleClickToggleButton(activeNotifications, ChecklistNotifications.ISSUES_FLAGGED),
    [handleClickToggleButton],
  );

  return {
    control,
    handleSubmit,
    onClickChecklistCompleted,
    onClickIssuesFlagged,
    ACTIVE_NOTIFICATIONS,
    OWNER,
  };
};
