import { createContext } from "react";
import { SessionState } from "../types";
import { UserData } from "@eolas-medical/core";

export interface SessionContextValue {
  sessionState: SessionState;
  user: UserData;
  refreshSession: () => void;
  clearSession: () => void;
}

const SessionContext = createContext<SessionContextValue>({
  sessionState: "signed-out",
  user: {},
  refreshSession: () => {},
  clearSession: () => {},
});

export default SessionContext;
