import { useMutation, MutateOptions } from "@tanstack/react-query";
import { NotificationType } from "@eolas-medical/core/src/clients/notifications.client";
import { notificationsClient } from "@eolas-medical/core";

type Args = {
  body: string;
  title: string;
  userIds: string[];
  departmentId: string;
  route: string;
  type: NotificationType;
  metadata?: Record<string, string>;
  organisationId?: string;
};

export const useSendPushNotifications = () => {
  const { reset, mutate, error, data, isLoading, isSuccess } = useMutation<any, any, Args>(
    async ({ userIds, title, body, type, departmentId, metadata, organisationId, route }) => {
      return notificationsClient.sendNotifications({
        userIds,
        title,
        body,
        type,
        departmentId,
        metadata,
        organisationId,
        route,
      });
    },
  );

  const onSendPN = (args: Args, options?: MutateOptions<any, any, Args>) => {
    reset();
    mutate(args, options);
  };
  return {
    data,
    error,
    onSendPN,
    isSuccess,
    isLoading,
  };
};
