import { useMemo } from "react";
import { EventType } from "../types";
import useEventFormTemplatesSummary from "./useEventFormTemplates";

const useEventFormTypes = (): EventType[] => {
  const { templatesSummary = [] } = useEventFormTemplatesSummary();

  const formTypes: EventType[] = useMemo(() => templatesSummary.map((ts) => ts.eventType), [
    templatesSummary,
  ]);

  return formTypes;
};

export default useEventFormTypes;
