import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { OrganisationLevelSection } from "@eolas-medical/core";

import { DeleteFileModal, EolasFileList, VideoPlayer } from "Components";
import { useMainSectionVisit } from "Hooks";
import { SubSectionRouteParams } from "Utilities/types";
import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";

import { useLearningManagement } from "../hooks/useLearningManagement";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSubSection from "modules/spaces/data/useSpaceSubSection";
import { AddItemWithExpirationModal } from "Pages/Spaces/components/AddEolasFile";

export const LearningManagementSection: React.FC<
  RouteComponentProps<SubSectionRouteParams>
> = observer(({ match: { url } }) => {
  const { t } = useTranslation();

  const { spaceSubSection } = useSpaceSubSection(OrganisationLevelSection.learningManagement);

  useMainSectionVisit({
    mainSectionId: OrganisationLevelSection.learningManagement,
    subSectionId: spaceSubSection.subSectionId,
  });

  const { isAdmin } = useSpacesContext();

  const {
    modal,
    error,
    video,
    setModal,
    videoRef,
    onCloseModal,
    onSelectFile,
    onCloseVideo,
    onVideoProgress,
  } = useLearningManagement();

  const onAddModal = useCallback(() => {
    setModal(
      <AddItemWithExpirationModal
        subSectionId={spaceSubSection.subSectionId}
        onCloseModal={onCloseModal}
        mainSectionID={OrganisationLevelSection.learningManagement}
      />,
    );
  }, [onCloseModal, spaceSubSection, setModal]);

  const onEditItem = useCallback(
    (file) => {
      setModal(
        <AddItemWithExpirationModal
          file={file}
          subSectionId={spaceSubSection.subSectionId}
          onCloseModal={onCloseModal}
          mainSectionID={OrganisationLevelSection.learningManagement}
        />,
      );
    },
    [onCloseModal, spaceSubSection, setModal],
  );

  const onDeleteItem = useCallback(
    (file) => {
      setModal(<DeleteFileModal onCloseModal={onCloseModal} file={file} />);
    },
    [setModal, onCloseModal],
  );

  return (
    <InnerPageWrapper>
      <PageTitle title={spaceSubSection.subSectionName ?? ""} />

      <NavButton to={url.replace(`/${spaceSubSection.encodedSubSectionId}`, "")} />

      {isAdmin && (
        <AddButton onClick={onAddModal} data-testid="add-new-file">
          {t("general_add_new_file")}
        </AddButton>
      )}

      {video.url && (
        <div ref={videoRef}>
          <VideoPlayer
            url={video.url}
            title={video.name}
            closeVideo={onCloseVideo}
            onVideoProgress={onVideoProgress}
          />
        </div>
      )}

      {error && <span className="text-red text-center">{t("general_file_error")}</span>}

      <EolasFileList
        isAdmin={isAdmin}
        onEdit={onEditItem}
        onAddItem={onAddModal}
        onDelete={onDeleteItem}
        subSectionId={spaceSubSection.subSectionId}
        onSelectFile={onSelectFile}
        mainSectionId={OrganisationLevelSection.learningManagement}
      />

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </InnerPageWrapper>
  );
});
