import { useMutation, useQueryClient } from "@tanstack/react-query";
import { approveSupervisionRequest } from "../client/portfolio.client";
import portfolioKeys from "./portfolio.queryKeys";

const useApproveSupervisionRequest = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(approveSupervisionRequest, {
    onSuccess: (updatedSupervision, variables) => {
      queryClient.refetchQueries(portfolioKeys.superviseesList());
    },
  });

  return {
    approveRequest: mutate,
    approvingRequest: isLoading,
    ...rest,
  };
};

export default useApproveSupervisionRequest;
