import { get } from "lodash";
import { useTranslation } from "react-i18next";

import {
  Input,
  Title,
  Button,
  Textarea,
  ModalBody,
  FormError,
  FilePicker,
  FormElement,
  ProgressBar,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import { EolasFileType } from "Utilities/types";
import { useIsScrollable, useAllowedFileTypes } from "Hooks";

import { AddItemModalProps } from "../types";
import { useAddEolasFile } from "../useAddEolasFile";

export interface AddItemWithFileModalProps extends AddItemModalProps {
  withKeywords?: boolean;
  allowedFileTypes: EolasFileType[];
}

export const AddItemWithFileModal: React.FC<AddItemWithFileModalProps> = ({
  file,
  onCloseModal,
  mainSectionID,
  allowedFileTypes,
  withKeywords = false,
}) => {
  const { t } = useTranslation();

  const {
    error,
    control,
    onSubmit,
    progress,
    isLoading,
    formErrors,
    isSuccessful,
    filePickerType,
    filePickerValue,
    onSelectFileType,
    onFilePickerChange,
  } = useAddEolasFile({
    mainSectionID,
    eolasFile: file,
    contentType: get(allowedFileTypes, "0"),
  });

  const { ref, ScrollHint } = useIsScrollable();
  const allowedTypes = useAllowedFileTypes(allowedFileTypes);

  if (isSuccessful) {
    return <SuccessModal onComplete={onCloseModal} />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>
          {t(file ? "general_edit_file_modal_title" : "general_add_file_modal_title")}
        </Title>
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="add-file__modal-body">
        <form onSubmit={onSubmit} className="add-file-form">
          <div className="space-y-4 add-file-form__content max-h-60vh" ref={ref}>
            <FormElement required id="name" control={control} label={t("file_name_label")}>
              {(field) => (
                <Input
                  {...field}
                  size="lg"
                  disabled={isLoading}
                  data-testid="CM-addOrUpdateFileInput-text"
                  placeholder={t("contentManagement_editFeature_placeholder")}
                />
              )}
            </FormElement>

            {withKeywords && (
              <FormElement
                id="description"
                control={control}
                label={t(`${mainSectionID}_keywords_label`)}
              >
                {(field) => (
                  <Textarea
                    {...field}
                    disabled={isLoading}
                    data-testid="fileDescription-input"
                    placeholder={t(`${mainSectionID}_keywords_placeholder`)}
                  />
                )}
              </FormElement>
            )}

            <FilePicker
              required
              isDisabled={isLoading}
              value={filePickerValue}
              fileType={filePickerType}
              onChange={onFilePickerChange}
              error={formErrors.key?.message}
              onSelectType={onSelectFileType}
              allowedFileTypes={allowedTypes}
            />

            {isLoading && progress > 0 && <ProgressBar progress={progress} />}
          </div>
          <FormError error={error} />
          <Button
            size="lg"
            type="submit"
            isLoading={isLoading}
            style={{ minHeight: "3rem" }}
            className="add-file__submit-button"
          >
            {t("general_submit")}
          </Button>
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
