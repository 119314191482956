import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { DeleteFileModal, EolasFileList } from "Components";
import { AddButton, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";

import { AddPatientLeafletModal, PatientLeafletQRModal } from "../components";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const PatientLeaflets: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  useMainSectionVisit({ mainSectionId: AppLevelSection.patientLeaflets });
  const { isAdmin } = useSpacesContext();
  const { t } = useTranslation();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { spaceSection } = useSpaceSection(AppLevelSection.patientLeaflets);

  const onCloseModal = () => setModal(null);

  const onAddFile = () => {
    setModal(<AddPatientLeafletModal onCloseModal={onCloseModal} />);
  };
  const onEditFile = (file: EolasFile) => {
    setModal(<AddPatientLeafletModal leaflet={file} onCloseModal={onCloseModal} />);
  };
  const onDeleteFile = (file: EolasFile) => {
    setModal(<DeleteFileModal file={file} onCloseModal={onCloseModal} />);
  };
  const onShowQR = (file: EolasFile) => {
    setModal(<PatientLeafletQRModal file={file} />);
  };

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t(AppLevelSection.patientLeaflets)} />
        <NavButton to={url.replace(`/${AppLevelSection.patientLeaflets}`, "")} />

        {isAdmin && <AddButton onClick={onAddFile}>{t("fileWithSearch_addNew_file")}</AddButton>}

        <EolasFileList
          isAdmin={isAdmin}
          onEdit={onEditFile}
          onAddItem={onAddFile}
          onSelectFile={onShowQR}
          onDelete={onDeleteFile}
          mainSectionId={AppLevelSection.patientLeaflets}
        />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});
