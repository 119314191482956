import { fetchAllData } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { ContentItem, AddContentItemDto } from "../types";
import { mapToFlashcardUpdateDto } from "../helpers";
import { updateFile } from "clients/files/files.client";
import { generateS3FileKey } from "Utilities";
import { useS3FileUpload } from "Hooks";
import { useState } from "react";
import { AxiosProgressEvent } from "axios";

interface EditFlashcardItem {
  flashcard: ContentItem;
  editItem: Partial<AddContentItemDto>;
  file?: File;
}

const useEditFlashcardItem = (contentRepositoryId: string) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadFile = useS3FileUpload();

  const setProgress = (progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 25) / progressEvent.total!);
    setUploadProgress(percentCompleted);
  };

  const editFlashcardItem = async ({ flashcard, editItem, file }: EditFlashcardItem) => {
    let key = editItem.imageUrl ?? flashcard.key;

    if (file) {
      key = generateS3FileKey({
        isPublic: true,
        fileName: editItem.name || flashcard.name,
        fileFormat: file.type,
        mainSectionId: contentRepositoryId,
        addExtension: true,
      });
      await uploadFile(key, file, setProgress);
    }

    const fileMapped = await mapToFlashcardUpdateDto(flashcard, editItem);
    fileMapped.key = key;

    await updateFile({
      parentID: contentRepositoryId,
      input: fileMapped,
    });
    setUploadProgress(50);
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    ({ flashcard, editItem, file }: EditFlashcardItem) =>
      editFlashcardItem({ flashcard, editItem, file }),
    {
      onSuccess: async () => {
        await fetchAllData();
        setUploadProgress(100);
      },
    },
  );

  return {
    editFlashcardItem: mutate,
    editingFlashcardItem: isLoading,
    editFlashcardItemSuccess: isSuccess,
    editFlashcardItemError: error as string,
    editFlashcardItemProgress: uploadProgress,
  };
};

export default useEditFlashcardItem;
