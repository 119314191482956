import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InnerModalWrapper, ModalBody, ModalHeader, SuccessModal, Title } from "UIKit";
import TeamMemberForm from "../TeamMemberForm/TeamMemberForm";
import { TeamMember } from "../../../../types";
import useEditTeamMember from "../../../../data/useEditTeamMember";
import { TeamMemberFormValues } from "../../hooks/useTeamMemberForm";

interface EditTeamMemberModalProps {
  teamMember: TeamMember;
  onCloseModal: () => void;
}

const EditTeamMemberModal = ({ teamMember, onCloseModal }: EditTeamMemberModalProps) => {
  const { t } = useTranslation();
  const {
    editTeamMember,
    editingTeamMember,
    editTeamMemberSuccessful,
    editTeamMemberError,
  } = useEditTeamMember();

  const handleSubmitTeamMember = useCallback(
    ({ name, role, bio, photo }: TeamMemberFormValues) => {
      editTeamMember({ id: teamMember.id, name, role, bio, photo });
    },
    [editTeamMember, teamMember],
  );

  if (editTeamMemberSuccessful) {
    return <SuccessModal onComplete={onCloseModal} text={"meetTheTeam_edit_staff_success"} />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("meetTheTeam_add_modal_title")}</Title>
      </ModalHeader>
      <ModalBody>
        <TeamMemberForm
          teamMember={teamMember}
          isLoading={editingTeamMember}
          error={editTeamMemberError}
          onSubmit={handleSubmitTeamMember}
        />
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default EditTeamMemberModal;
