import { accessClient, sectionStore } from "@eolas-medical/core";
import { useQueryClient, useMutation, MutateOptions } from "@tanstack/react-query";

type Args = { accessRequestId: string };

// refetch users won't be used in the future when we gonna use react-query for the user list
// will remove when ES-322 is done
export const useAccessRequests = (refetchUsers: () => void) => {
  const queryClient = useQueryClient();

  const acceptMutation = useMutation<any, any, { userId: string }>(
    ({ userId }) => {
      return accessClient.approveDepartmentAccess(userId, sectionStore.appID);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["access-requests"] });
        refetchUsers();
      },
    },
  );

  const declineMutation = useMutation<any, any, Args>(
    ({ accessRequestId }) => {
      return accessClient.rejectDepartmentAccess(accessRequestId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["access-requests"] });
        refetchUsers();
      },
    },
  );

  const onAcceptRequest = (
    userId: string,
    options?: MutateOptions<any, any, { userId: string }>,
  ) => {
    acceptMutation.reset();
    acceptMutation.mutate({ userId }, options);
  };

  const onDeclineRequest = (accessRequestId: string, options?: MutateOptions<any, any, Args>) => {
    declineMutation.reset();
    declineMutation.mutate({ accessRequestId }, options);
  };

  return {
    acceptError: acceptMutation.error,
    acceptLoading: acceptMutation.isLoading,
    onAcceptRequest,
    declineError: declineMutation.error,
    declineLoading: declineMutation.isLoading,
    onDeclineRequest,
  };
};
