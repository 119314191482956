import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { EolasChecklistSection } from "Utilities/types";
import { fetchAllData } from "@eolas-medical/core";
import { UPDATE_SECTION_INFO } from "Pages/Spaces/components/SectionManagement";

export const useDeactivateChecklist = (checklist: EolasChecklistSection, isDisabled: boolean) => {
  const { isLoading, isSuccessful, error, setRequestStatus } = useRequestStatus();
  const [updateSectionInfo] = useMutation(UPDATE_SECTION_INFO);

  const onDeactivate = useCallback(() => {
    setRequestStatus({ status: "pending", error: "" });
    updateSectionInfo({
      variables: {
        parentID: checklist.parentID,
        input: {
          id: checklist.id,
          disabled: isDisabled ? "false" : "true",
        },
      },
    })
      .then(async () => {
        await fetchAllData();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error: any) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  }, [checklist.id, checklist.parentID, isDisabled, setRequestStatus, updateSectionInfo]);

  return {
    error,
    isLoading,
    isSuccessful,
    onDeactivate,
  };
};
