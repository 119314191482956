import { useEffect } from "react";

interface UseResizeScreenProps {
  onResize: () => void;
}

const useResizeScreen = ({ onResize }: UseResizeScreenProps) => {
  useEffect(() => {
    const handleResize = () => {
      onResize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [onResize]);
};

export default useResizeScreen;
