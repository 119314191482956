import { observer } from "mobx-react-lite";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import {
  EolasFile,
  AppLevelSection,
  MajorIncidentSubSection,
  sectionStore,
} from "@eolas-medical/core";

import { noFilesFound } from "Assets";
import { EolasFileList } from "Components";
import { useMainSectionVisit } from "Hooks";

import { LottieWithHeader, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";

import { DeleteMajorIncidentModal, IncidentInfoModal } from "../components";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const AdminIncidents: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  useMainSectionVisit({
    mainSectionId: AppLevelSection.majorIncidents,
    subSectionId: MajorIncidentSubSection.incidents,
  });

  const { spaceSection } = useSpaceSection(AppLevelSection.majorIncidents);

  const { id: incidentsSectionID } = sectionStore
    .getChildrenOrder(spaceSection.id)
    .find((child) => child?.icon === MajorIncidentSubSection.incidents)!;

  const { t } = useTranslation();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const openIncidentInfo = useCallback(
    (incident: EolasFile) => {
      setModal(<IncidentInfoModal incident={incident} />);
    },
    [setModal],
  );

  const onDeleteIncident = useCallback(
    (incident: EolasFile) => {
      setModal(<DeleteMajorIncidentModal file={incident} onCloseModal={onCloseModal} />);
    },
    [setModal, onCloseModal],
  );

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={t("incidents_page_title")} />
        <NavButton to={url.replace("/all", "")} />

        <EolasFileList
          isAdmin
          defaultSort="date"
          draggable={false}
          onDelete={onDeleteIncident}
          adminNoFiles={<AdminNoFiles />}
          onSelectFile={openIncidentInfo}
          subSectionId={incidentsSectionID}
          mainSectionId={AppLevelSection.majorIncidents}
        />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});

const AdminNoFiles = () => {
  const { t } = useTranslation();
  return (
    <LottieWithHeader
      animationSize="125px"
      animation={noFilesFound}
      lottieOptions={{ loop: true }}
      text={t("incidents_no_incidents_found")}
    />
  );
};
