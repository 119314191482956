export const theme = {
  fontFamily: {
    nunito: ["Nunito"],
  },
  flex: {
    1: "1 1 0%",
    2: "2 2 0%",
    3: "3 3 0%",
  },
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#FFF",
    primary: {
      DEFAULT: "#3375ED",
      hover: "#1E5172",
      light: "#C4CEE2",
      focus: "#D0DFFB",
    },
    blue: {
      DEFAULT: "#3375ED",
      hover: "#1E5172",
      light: "#C4CEE2",
      50: "#EBF1FD",
      100: "#C2D5FA",
      200: "#99BAF6",
      300: "#709EF2",
      400: "#4782EF",
      500: "#3374ED",
      600: "#2E68D5",
      700: "#2451A6",
      800: "#1A3A77",
      900: "#0F2347",
    },
    darkblue: {
      DEFAULT: "#00207f",
      50: "#e6e9f2",
      100: "#CCD2E5",
      200: "#99a6cc",
      300: "#6679b2",
      400: "#334d99",
      500: "#00207f",
      600: "#001a66",
      700: "#001659",
      800: "#00134c",
      900: "#000d33",
    },
    red: {
      DEFAULT: "#FF4646",
      hover: "#802323",
      light: "#ECC4C4",
      focus: "#FFC8C8",
      50: "#ffeded",
      100: "#ffc8c8",
      200: "#ffa3a3",
      300: "#ff7e7e",
      400: "#ff6b6b",
      500: "#de3448",
      600: "#e63f3f",
      700: "#b33131",
      800: "#661c1c",
      900: "#4c1515",
    },
    green: {
      DEFAULT: "#29a745",
      hover: "#195f1d",
      light: "#96B99E",
      50: "#eaf6ec",
      100: "#d4edda",
      200: "#94d3a2",
      300: "#69c17d",
      400: "#3eb058",
      500: "#29a745",
      600: "#25963e",
      700: "#1d7530",
      800: "#155423",
      900: "#0c3215",
    },
    yellow: {
      DEFAULT: "#FCC404",
      hover: "#896a02",
      light: "#DED7BF",
      50: "#fff9e6",
      100: "#feedb4",
      200: "#fee282",
      300: "#fdd036",
      400: "#fcca1d",
      500: "#fcc404",
      600: "#e3b004",
      700: "#b08903",
      800: "#7e6202",
      900: "#4c3b01",
    },
    grey: {
      DEFAULT: "#F7F9FA",
      hover: "#E4E4E4",
      light: "#F7F9FA",
      pale: "#DEE1E5",
      mid: "#666",
      dark: "#808080",
      darker: "#999999",
      50: "#fafbfc",
      100: "#f5f7f8",
      200: "#F0F3F5",
      300: "#EBEFF1",
      400: "#D7E0E4",
      500: "#B9C2C7",
      600: "#90979b",
      700: "#676c6f",
      800: "#3d4142",
      900: "#292B2C",
    },
    quartz: {
      DEFAULT: "#B0BCE0",
      50: "#F7F8FC",
      100: "#EFF2F9",
      200: "#D8DEF0",
      300: "#C8D0E9",
      400: "#b8c3e3",
      500: "#B0BCE0",
      600: "#9EA9CA",
      700: "#8D96B3",
      800: "#7B849D",
      900: "#6A7186",
    },
  },
  fontSize: {
    "3xs": "0.562rem", // 9px
    "2xs": "0.625rem", // 10px
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    base: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "1.75rem", // 28px
    "4xl": "2rem", // 32px
    "5xl": "2.5rem", // 40px
    "6xl": "3rem", // 48px
    "7xl": "3.375rem", // 54px
  },
  extend: {
    spacing: {
      0: "0px",
      px: "1px",
      0.5: "2px",
      1: "0.25rem",
      2: "0.5rem",
      2.5: "0.625rem",
      3: "0.75rem",
      3.5: "0.875rem",
      4: "1rem",
      4.5: "1.125rem",
      5: "1.25rem",
      6: "1.5rem",
      8: "2rem",
      10: "2.5rem",
      12: "3rem",
      16: "4rem",
      20: "5rem",
      24: "6rem",
      32: "8rem",
      40: "10rem",
      48: "12rem",
      56: "14rem",
      64: "16rem",
      88: "22rem",
      120: "30rem",
      128: "32rem",
      200: "40rem",
      220: "45rem",
      "10vh": "10vh",
      "20vh": "20vh",
      "30vh": "30vh",
      "40vh": "40vh",
      "50vh": "50vh",
      "60vh": "60vh",
      "70vh": "70vh",
      "80vh": "80vh",
      "90vh": "90vh",
      "100vh": "100vh",
      "10vw": "10vw",
      "20vw": "20vw",
      "30vw": "30vw",
      "40vw": "40vw",
      "50vw": "50vw",
      "60vw": "60vw",
      "70vw": "70vw",
      "80vw": "80vw",
      "90vw": "90vw",
      "100vw": "100vw",
    },
    borderRadius: {
      xs: "0.25rem",
      sm: "0.5rem",
      md: "0.75rem",
      lg: "1.5rem",
      huge: "1.5rem",
    },
    borderWidth: {
      DEFAULT: "1px",
      3: "3px",
    },
    boxShadow: {
      xs: "0px 1px 1px 0 rgba(0, 0, 0, 0.08)",
      sm: "0px 4px 15px rgba(0, 0, 0, 0.02)",
      md: "0px 6px 6px rgba(0, 0, 0, 0.06)",
      lg: "2px 7px 12px rgba(0, 0, 0, 0.08)",
      xl: "4px 17px 24px rgba(0, 0, 0, 0.08)",
      blue: "0 0 0 4px rgba(194, 213, 250, 0.3)",
      red: "0 0 0 4px rgba(255, 200, 200, 0.3)",
    },
    minHeight: {
      "10vh": "10vh",
      "20vh": "20vh",
      "30vh": "30vh",
      "40vh": "40vh",
      "50vh": "50vh",
      "60vh": "60vh",
      "70vh": "70vh",
      "80vh": "80vh",
      "90vh": "90vh",
      "100vh": "100vh",
    },
    screens: {
      "md-max": { max: "767px" },
      hsm: { raw: "(min-height: 720px)" },
      hmd: { raw: "(min-height: 800px)" },
      hlg: { raw: "(min-height: 1024px)" },
    },
    animation: {
      "opacity-2": "opacity2 1s ease-in infinite",
      "opacity-1": "opacity1 1s ease-in-out infinite",
      "spin-slow": "slowSpin 2s linear infinite",
    },
    keyframes: {
      opacity1: {
        "0%": { opacity: 0 },
        "33%": { opacity: 1 },
      },
      opacity2: {
        "0%": { opacity: 0 },
        "66%": { opacity: 1 },
      },
      slowSpin: {
        from: { transform: "rotate(0deg)" },
        to: { transform: "rotate(360deg)" },
      },
    },
  },
};
