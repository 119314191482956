import { useState } from "react";
import { EditIcon, GoToIcon, UserProfileIcon } from "Assets";
import { CircleIcon, IconButton, Loader } from "UIKit";
import Panel from "UIKit/Panel/Panel";
import { Title } from "UIKit/Typography";
import { ProfileVerificationStatus } from "@eolas-medical/core";
import { getVerificationStatusIcon } from "Pages/MeTab/helpers";

export type ProfileActionType = "edit" | "view";

export interface ProfileActionCardProps {
  title: string;
  value?: string;
  leftIcon: React.ElementType;
  action?: ProfileActionType;
  imageIcon?: string;
  status?: ProfileVerificationStatus;
  onClick?: () => void;
  renderValueSection?: () => React.ReactNode;
  isLoading?: boolean;
}

const ProfileActionCard = ({
  title,
  value,
  leftIcon: LeftIcon,
  action = "edit",
  onClick,
  renderValueSection,
  imageIcon,
  status,
  isLoading = false,
}: ProfileActionCardProps) => {
  const [imageAvailable, setImageAvailable] = useState(true);

  const handleImageError = () => {
    setImageAvailable(false);
  };

  const ActionIcon = (): JSX.Element => {
    const iconMap = {
      edit: <EditIcon width={24} height={24} data-testid="edit-icon" />,
      view: <GoToIcon width={24} height={24} data-testid="view-icon" />,
    };

    return iconMap[action] || <EditIcon width={24} height={24} />;
  };

  const renderLeftIcon = () => {
    if (isLoading) {
      return <Loader className="h-16 w-16 sm:h-24 sm:w-24" />;
    }

    if (!imageAvailable)
      return (
        <CircleIcon height="24" width="24" icon={<UserProfileIcon />} backgroundColor="grey" />
      );

    if (imageIcon) {
      return (
        <CircleIcon
          height="24"
          width="24"
          icon={
            <img
              src={imageIcon}
              alt={""}
              onError={handleImageError} // Call the event handler when the image fails to load
              className="h-24 w-24 rounded-full object-cover"
            />
          }
        />
      );
    }
    return <CircleIcon height="24" width="24" icon={<LeftIcon />} backgroundColor="grey" />;
  };
  return (
    <Panel className="tile-border rounded-xl">
      <div
        className={`grid cols-6 cursor-pointer bg-white ${
          renderValueSection ? "h-70 grid-rows-3" : "h-25 grid-rows-1 items-center justify-center"
        }xl:grid xl:grid-cols-10`}
        onClick={onClick}
      >
        <div className="mr-4 w-24 h-24 xl:col-start-1 xl:col-end-2">{renderLeftIcon()}</div>
        <div
          className={`text-ellipsis overflow-hidden ${
            renderValueSection ? "self-center" : ""
          } col-start-2 col-span-3 xl:ml-4 xl:col-start-2 xl:col-span-8`}
        >
          <div className="flex items-center gap-x-2">
            <Title level={6}>{title}</Title>
            {status ? getVerificationStatusIcon(status) : null}
          </div>
          <div className={`mt-4  ${value ? "inline-block" : "hidden"} md:inline-block`}>
            {renderValueSection ? renderValueSection() : value}
          </div>
        </div>
        <div
          className={`col-span-6 mt-2 ${
            renderValueSection ? "col-start-1 static md:hidden" : "col-start-2 hidden"
          } xl:col-start-2 xl:ml-4`}
        >
          {renderValueSection ? renderValueSection() : value}
        </div>
        <div className="flex justify-end col-start-11 col-span-1 row-start-1 xl:col-start-10">
          <IconButton
            size="sm"
            variant="rounded"
            icon={<ActionIcon />}
            className="bg-blue-50 text-blue-500"
          />
        </div>
      </div>
    </Panel>
  );
};
export default ProfileActionCard;
