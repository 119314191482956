import { observer } from "mobx-react-lite";

import { Redirect, useLocation } from "react-router-dom";
import { userStore } from "@eolas-medical/core";

import { AuthenticationRoutes, MainRouter } from "./Routes";
import { useQueryParams } from "Hooks";
import { useBootIntercom } from "Hooks";
import useSpacesContext from "modules/spaces/context/useSpacesContext";

const AppRoutes = observer(() => {
  const { userSession } = userStore;
  const location = useLocation();
  const { isInSpace, isAdmin } = useSpacesContext();
  const { newUser = false } = (location.state as { newUser: boolean }) || {};

  const queryParams = useQueryParams();
  const callbackUrl = queryParams.get("callbackUrl");

  const isAuthenticateRoute = !userSession.isLoggedIn;
  const isMainRoute = !isAuthenticateRoute;

  useBootIntercom({
    isAuthenticateRoute,
    isSelectSpaceRoute: !isAuthenticateRoute && !isInSpace,
    isMainRoute,
    newUser,
    isAdmin,
  });

  if (userSession.isLoggedIn && callbackUrl) {
    return <Redirect to={callbackUrl} />;
  }

  return (
    <>
      {isAuthenticateRoute && <AuthenticationRoutes />}
      {isMainRoute && <MainRouter />}
    </>
  );
});

export default AppRoutes;
