import React, { useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { isEmpty } from "lodash";
import { userStore, sectionStore } from "@eolas-medical/core";

import { useLaunchDarkly } from "Contexts";
import { LDFlagNames, BaseAppRoute, ActiveTab } from "Utilities/types";

import NavigationContext, { NavigationContextValue } from "./navigation.context";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { getOrganisationRoute, getSpaceRoute } from "Pages/Spaces/helpers";

const NavigationProvider: React.FC = observer(({ children }) => {
  const { push } = useHistory();
  const { flags } = useLaunchDarkly();
  const { activeHospitalTab, activeDepartmentTab } = sectionStore;
  const { appUserData } = userStore;
  const { selectedSpace } = useSpacesContext();
  const [drawerExpanded, setDrawerExpanded] = useState(true);

  const { hasOrganisationTab, hasSelectedSpace, hasKnowledgeTab, hasSpacesTab } = useMemo(() => {
    const hasSpacesTab = activeDepartmentTab;

    const hasSelectedSpace = !isEmpty(appUserData);

    const hasKnowledgeTab = !!flags[LDFlagNames.COMMUNITY_TAB];

    const hasOrganisationTab = activeHospitalTab;

    return { hasOrganisationTab, hasSelectedSpace, hasKnowledgeTab, hasSpacesTab };
  }, [appUserData, flags, activeHospitalTab, activeDepartmentTab]);

  const isOnSelectSpace = !!useRouteMatch({ path: `/${BaseAppRoute.selectSpace}` });
  const isOnProfile = !!useRouteMatch({ path: `/${BaseAppRoute.myProfile}` });
  const isOnSpace = !!useRouteMatch({ path: `/${BaseAppRoute.spaces}/${selectedSpace?.name}` });
  const isOnOrganisation = !!useRouteMatch({
    path: `/${BaseAppRoute.organisation}/${selectedSpace?.name}`,
  });
  const isOnKnowledge = !!useRouteMatch({ path: `/${BaseAppRoute.knowledge}` });

  const activeTab = React.useMemo((): ActiveTab => {
    if (isOnProfile) {
      return "my-profile";
    }

    if (isOnSpace) {
      return "spaces";
    }

    if (isOnOrganisation) {
      return "organisation";
    }

    if (isOnKnowledge) {
      return "knowledge";
    }

    if (isOnSelectSpace) {
      return "select-space";
    }

    return hasSpacesTab ? "spaces" : "organisation";
  }, [isOnProfile, isOnOrganisation, isOnSelectSpace, hasSpacesTab, isOnSpace, isOnKnowledge]);

  const goMeTab = React.useCallback(() => {
    push(`/${BaseAppRoute.myProfile}`);
  }, [push]);

  const goToSelectSpace = React.useCallback(() => {
    push(`/${BaseAppRoute.selectSpace}`);
  }, [push]);

  const goToSpaceTab = React.useCallback(() => {
    push(getSpaceRoute(selectedSpace));
  }, [selectedSpace, push]);

  const goToOrganisationTab = React.useCallback(() => {
    push(getOrganisationRoute(selectedSpace));
  }, [selectedSpace, push]);

  const goToKnowledgeTab = React.useCallback(() => {
    push(`/${BaseAppRoute.knowledge}`);
  }, [push]);

  const toggleDrawerExpanded = () => setDrawerExpanded((drawerExpanded) => !drawerExpanded);

  const navigationValue: NavigationContextValue = {
    activeTab,
    drawerExpanded,
    hasSpacesTab,
    hasOrganisationTab,
    hasSelectedSpace,
    hasKnowledgeTab,
    goMeTab,
    goToOrganisationTab,
    goToSpaceTab,
    goToSelectSpace,
    goToKnowledgeTab,
    toggleDrawerExpanded,
  };

  return (
    <NavigationContext.Provider value={navigationValue}>{children}</NavigationContext.Provider>
  );
});

export default NavigationProvider;
