import React from "react";
import { accessClient } from "@eolas-medical/core";
import { useMutation, MutateOptions } from "@tanstack/react-query";

export const useShouldAllowAccess = () => {
  const { reset, mutate, error, data, isLoading } = useMutation<any, any, { appId: string }>(
    async ({ appId }) => {
      return accessClient.shouldAllowUserAccess(appId);
    },
  );

  const shouldAllowUserAccess = React.useCallback(
    (appId: string, options?: MutateOptions<any, any, { appId: string }>) => {
      reset();
      mutate({ appId }, options);
    },
    [reset, mutate],
  );

  return {
    error: error,
    userAccess: data,
    shouldAllowAccessLoading: isLoading,
    shouldAllowUserAccess,
  };
};
