import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { PageTitle, InnerPageWrapper, GridContainer } from "UIKit";
import { RouteComponentProps } from "react-router-dom";
import MasterSearch from "Components/MasterSearch/MasterSearch";
import KnowledgeSectionList from "./components/KnowledgeSectionList/KnowledgeSectionList";
import useKnowledgeSections from "modules/mainSections/data/useKnowledgeSections";

export const Knowledge: React.FC<RouteComponentProps> = observer(() => {
  const { t } = useTranslation();
  const { knowledgeSections } = useKnowledgeSections();

  return (
    <InnerPageWrapper>
      <PageTitle
        subTitle={t("pageSubTitle")}
        data-testid="client-home-title"
        title={t("knowledge_title")}
      />

      <MasterSearch />

      <GridContainer>
        <KnowledgeSectionList knowledgeSectionList={knowledgeSections} />
      </GridContainer>
    </InnerPageWrapper>
  );
});
