import React, { useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "Assets";
import { IconButton } from "UIKit/Button";
import { motion, AnimatePresence } from "framer-motion";

type CarouselProps = {
  children: React.ReactElement[];
};

export const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="relative w-full h-full flex justify-center items-center">
      <IconButton
        icon={<ArrowLeftIcon className="w-8 h-8" />}
        variant="rounded"
        size="lg"
        color="blue"
        onClick={() => setCurrentIndex((currentIndex - 1 + children.length) % children.length)}
        className="z-10 px-1 lg:px-3 mr-1 lg:mr-6"
      />

      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, x: 300 }} // slide in from the right
          animate={{ opacity: 1, x: 0 }} // slide to the center
          exit={{ opacity: 0, x: -300 }} // slide out to the left
          transition={{ duration: 0.1, type: "tween" }}
        >
          {children[currentIndex]}
        </motion.div>
      </AnimatePresence>

      <IconButton
        icon={<ArrowRightIcon className="w-8 h-8" />}
        variant="rounded"
        size="lg"
        color="blue"
        onClick={() => setCurrentIndex((currentIndex + 1) % children.length)}
        className="z-10 px-1 lg:px-3 ml-1 lg:ml-6"
      />
    </div>
  );
};
