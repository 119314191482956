import {
  AnalyticsEvents,
  AppLevelSection,
  EolasFile,
  EolasMainSection,
  OrganisationLevelSection,
  isFileCompleted,
  sectionStore,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { FileViewerComponent } from "Components";
import { useDownloadFile } from "Components/EolasFileList/hooks";
import { useFileViewer } from "Components/FileViewer/useFileViewer";
import { useCompleteFile } from "Hooks";
import { Button, Modal, NavButton } from "UIKit";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

interface DocumentViewerProps {
  document: EolasFile;
}

const fileTrackingSections = [
  AppLevelSection.educationPortal,
  AppLevelSection.newsFeed,
  OrganisationLevelSection.learningManagement,
  OrganisationLevelSection.communicationPortal,
] as EolasMainSection[];

const DocumentViewer = ({ document }: DocumentViewerProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { saveFile } = useDownloadFile(document);
  const { onCompleteFile } = useCompleteFile();

  const mainSection = document.mainSectionID
    ? sectionStore.getMainSectionTypeFromMainSectionID(document.mainSectionID)
    : null;

  const mainSectionIdentity = document.mainSectionID
    ? sectionStore.getMainSectionIdentityByMainSectionId(document.mainSectionID)
    : null;

  // the order is important here as if the mainSection is null, we want to use the mainSectionIdentity
  const idForTracking = mainSection ?? mainSectionIdentity;

  const { viewer } = useFileViewer(document.key || "", mainSection ?? undefined);

  useEffect(() => {
    if (idForTracking) {
      trackEvent(AnalyticsEvents.OPEN_FILE, {
        mainSectionId: idForTracking,
        fileId: document.id,
        fileType: document.type || "",
      });
    }

    if (mainSection && !fileTrackingSections.includes(mainSection)) {
      return;
    }

    const isAdmin = sectionStore.isAdmin;
    if (isAdmin) return;

    const isCompleted = isFileCompleted(document.id);
    if (isCompleted) return;

    onCompleteFile(decodeURIComponent(document.id), "seen");
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal hasCloseIcon={false} overlayColor="bg-grey-light" open={true} onClose={history.goBack}>
      <div className="h-100vh w-100vw">
        <div className="bg-grey-light py-1">
          <div className="flex justify-center space-x-3">
            <NavButton onClick={history.goBack} />
            <Button size="sm" onClick={saveFile}>
              {t("general_download_original")}
            </Button>
          </div>
        </div>

        <div className="bg-grey-light" style={{ height: "94%" }}>
          {document.key && <FileViewerComponent viewer={viewer} />}
        </div>
      </div>
    </Modal>
  );
};

export default DocumentViewer;
