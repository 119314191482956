import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnalyticsEvents } from "@eolas-medical/core";
import { useQueryParams } from "Hooks";
import { trackEvent } from "API/Analytics";
import { NavButton, ConfirmationModal, Modal } from "UIKit";
import {
  EventType,
  FeedbackForm as FeedbackFormTemplate,
  FormValues,
} from "modules/portfolio/types";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";
import FeedbackFormButtons from "./FeedbackFormButtons";
import FeedbackFormBody from "./FeedbackFormBody";
import EventDescriptionSection from "../EventDescriptionSection/EventDescriptionSection";
import useFeedbackForm from "../../hooks/useFeedbackForm";
import useFeedbackActions from "../../hooks/useFeedbackActions";
import { createFeedbackBody } from "modules/portfolio/helpers";

interface FeedbackFormProps {
  eventId: string;
  eventType: EventType;
  feedbackForm: FeedbackFormTemplate;
  backUrl: string;
  areAttachmentsShared: boolean;
}
const FeedbackForm = ({
  eventId,
  eventType,
  areAttachmentsShared,
  backUrl,
  feedbackForm,
}: FeedbackFormProps) => {
  const { t } = useTranslation();
  const { replace } = useHistory();
  const queryParams = useQueryParams();
  const { id: feedbackId, formElements, eventDescription, eventOwner, actionButtons } =
    feedbackForm || {};

  const { control, getValues, handleSubmit } = useFeedbackForm({ formElements });

  const {
    modalState,
    decliningFeedback,
    deletingFeedback,
    submittingFeedback,
    onClickSubmit,
    onConfirmSubmit,
    onClickDecline,
    onDecline,
    onDelete,
    onClickDelete,
    onCloseModal,
    onDownloadAttachment,
    onClickAttachment,
  } = useFeedbackActions({ eventId, eventType, backUrl, feedbackId });

  const handleConfirmSubmit = useCallback(() => {
    const values: FormValues = getValues();
    const feedbackBody = createFeedbackBody(values, formElements);
    onConfirmSubmit(feedbackBody);
  }, [getValues, formElements, onConfirmSubmit]);

  useEffect(() => {
    if (queryParams.has(PortfolioConstants.TRACK_QUERY_PARAM)) {
      trackEvent(AnalyticsEvents.PORTFOLIO_FEEDBACK_LINK_INTERACTION, { eventType });
      queryParams.delete(PortfolioConstants.TRACK_QUERY_PARAM);
      replace({ search: queryParams.toString() });
    }
  }, [replace, queryParams, eventType]);

  useEffect(() => {
    trackEvent(AnalyticsEvents.PORTFOLIO_FEEDBACK_STARTED, { eventType });
  }, [eventType]);

  return (
    <>
      <NavButton onClick={() => replace(backUrl)} />
      <Modal open={modalState !== null} onClose={onCloseModal}>
        {modalState === "confirmSubmit" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={handleConfirmSubmit}
            iconType="GreenTickIcon"
            title={t("portfolio_submit_feedback_title")}
            description={t("portfolio_submit_feedback_subtitle")}
            acceptLabel={t("portfolio_submit_feedback_submit")}
            declineLabel={t("portfolio_submit_feedback_cancel")}
            declineButtonProps={{ disabled: submittingFeedback }}
            acceptButtonProps={{ color: "blue", isLoading: submittingFeedback }}
          />
        )}
        {modalState === "confirmDecline" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onDecline}
            iconType="MajorIncidentIcon"
            title={t("portfolio_decline_feedback_title")}
            description={t("portfolio_decline_feedback_subtitle")}
            acceptLabel={t("portfolio_decline_feedback_submit")}
            declineLabel={t("portfolio_decline_feedback_cancel")}
            declineButtonProps={{ disabled: decliningFeedback }}
            acceptButtonProps={{ color: "red", isLoading: decliningFeedback }}
          />
        )}
        {modalState === "confirmDelete" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onDelete}
            iconType="MajorIncidentIcon"
            title={t("portfolio_decline_feedback_title")}
            description={t("portfolio_decline_feedback_subtitle")}
            acceptLabel={t("portfolio_decline_feedback_submit")}
            declineLabel={t("portfolio_decline_feedback_cancel")}
            declineButtonProps={{ disabled: deletingFeedback }}
            acceptButtonProps={{ color: "red", isLoading: deletingFeedback }}
          />
        )}
      </Modal>
      <div>
        <EventDescriptionSection
          eventOwner={eventOwner}
          eventDescription={eventDescription}
          areAttachmentsShared={areAttachmentsShared}
          onDownloadAttachment={onDownloadAttachment}
          onClickAttachment={onClickAttachment}
        />
        <FeedbackFormBody feedbackForm={feedbackForm} control={control} />
      </div>
      <FeedbackFormButtons
        actionButtons={actionButtons}
        onClickDelete={onClickDelete}
        onClickDecline={onClickDecline}
        onClickSubmit={handleSubmit(() => onClickSubmit())}
      />
    </>
  );
};

export default FeedbackForm;
