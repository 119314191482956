import { useMutation } from "@tanstack/react-query";
import { portfolioWizardService } from "modules/portfolio/client/PortfolioWizardService";
import { EventType, WizardFormElements } from "../types";

export interface WizardPayload {
  ownerId: string;
  eventDate: string;
  eventType: EventType;
  prompt: string;
  formElements: WizardFormElements[];
}

export interface ResponseCompleteWizard {
  completeWizard: string;
  isLoadingWizard: boolean;
  completeWizardError: Error;
}

const useCompleteWizard = () => {
  const { mutate, isLoading, error } = useMutation<ResponseCompleteWizard, Error, WizardPayload>(
    async (wizard) => {
      return portfolioWizardService.completeWizard(wizard);
    },
  );

  return {
    completeWizard: mutate,
    isLoadingWizard: isLoading,
    completeWizardError: error,
  };
};

export default useCompleteWizard;
