import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { SectionList, useSectionManagement } from "Pages/Spaces/components/SectionManagement";
import { AddButton, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const Wellbeing: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  const { t } = useTranslation();
  useMainSectionVisit({ mainSectionId: AppLevelSection.wellbeing });

  const { isAdmin } = useSpacesContext();
  const { spaceSection } = useSpaceSection(AppLevelSection.wellbeing);

  const {
    modal,
    onAddSection,
    onCloseModal,
    onEditSection,
    onDeleteSection,
  } = useSectionManagement(spaceSection.id);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t(AppLevelSection.wellbeing)} />

        <NavButton to={url.replace(`/${AppLevelSection.wellbeing}`, "")} />

        {isAdmin && (
          <AddButton onClick={onAddSection} data-testid="add-new-subSection">
            {t("addNewSection_button")}
          </AddButton>
        )}

        <SectionList
          isAdmin={isAdmin}
          sectionID={spaceSection.id}
          onEdit={onEditSection}
          onAddItem={onAddSection}
          onDelete={onDeleteSection}
        />

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </InnerPageWrapper>
    </>
  );
});
