import React, { useCallback } from "react";

import { DeleteIcon, EditMonoIcon, StarIcon, StarOutlineIcon } from "Assets";

import { IconButton } from "../../Button";
import { ControlsWrapper, FileTileProps } from "./";

type DefaultFileControlsProps = Omit<FileTileProps, "isAdmin" | "onSelectFile">;

export const DefaultFileControls: React.FC<DefaultFileControlsProps> = ({
  onEdit,
  onDelete,
  eolasFile,
  onHighlight,
  highlightLoading,
}) => {
  const isHighlighted = !!eolasFile.highlighted && eolasFile.highlighted !== "false";

  const _onEdit = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onEdit && onEdit(eolasFile);
    },
    [eolasFile, onEdit],
  );

  const _onDelete = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onDelete && onDelete(eolasFile);
    },
    [eolasFile, onDelete],
  );

  const _onHightlight = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onHighlight && onHighlight(eolasFile);
    },
    [eolasFile, onHighlight],
  );

  return (
    <ControlsWrapper>
      {onHighlight && (
        <IconButton
          size="sm"
          onClick={_onHightlight}
          isLoading={highlightLoading}
          data-testid={`highlightButtonFor-${eolasFile.name}`}
          icon={isHighlighted ? <StarIcon /> : <StarOutlineIcon />}
        />
      )}

      {onDelete && (
        <IconButton
          size="sm"
          onClick={_onDelete}
          icon={<DeleteIcon />}
          data-testid={`deleteButtonFor-${eolasFile.name}`}
        />
      )}

      {onEdit && (
        <IconButton
          size="sm"
          onClick={_onEdit}
          icon={<EditMonoIcon />}
          data-testid={`editButtonFor-${eolasFile.name}`}
        />
      )}
    </ControlsWrapper>
  );
};
