import { FormError, Carousel } from "UIKit";
import { useGetTemplates } from "./hooks/useGetTemplates";
import { Template } from "./types";
import { TemplateCard } from "./components/TemplateCard";

interface TemplateSelectorProps {
  onChange: ({ spaceTemplateId }: { spaceTemplateId: string }) => void;
  spaceTemplateId: string;
  error: string;
}
const TemplateSelector = ({ onChange, spaceTemplateId, error }: TemplateSelectorProps) => {
  const templates: Template[] = useGetTemplates();

  const handleCardClick = (selectedTemplateId: string) => {
    if (selectedTemplateId === spaceTemplateId) {
      onChange({ spaceTemplateId: "" });
    } else {
      onChange({ spaceTemplateId: selectedTemplateId });
    }
  };

  return (
    <>
      <div className="w-full  hidden lg:block">
        <Carousel>
          {templates.map((template) => (
            <TemplateCard
              key={template.id}
              isSelected={template.id === spaceTemplateId}
              title={template.title}
              description={template.description}
              sections={template.mainSections}
              onClick={() => handleCardClick(template.id)}
            />
          ))}
        </Carousel>
      </div>
      <div className="w-full lg:hidden block">
        {templates.map((template) => (
          <TemplateCard
            key={template.id}
            isSelected={template.id === spaceTemplateId}
            title={template.title}
            description={template.description}
            sections={template.mainSections}
            onClick={() => handleCardClick(template.id)}
          />
        ))}
      </div>
      <div className="h-16">
        <FormError error={error} />
      </div>
    </>
  );
};

export default TemplateSelector;
