import { useFormContext } from "react-hook-form";

import { CheckIcon } from "Assets";
import { Text, Input, Title } from "UIKit";

import { ChecklistSectionItem } from "../../checklistBuilder/types";
import "../styles.css";

export interface SectionItemProps {
  itemIndex: number;
  sectionIndex: number;
  isSectionSkipped: boolean;
  sectionItem: ChecklistSectionItem;
}

export const SectionItem: React.FC<SectionItemProps> = ({
  itemIndex,
  sectionItem,
  sectionIndex,
  isSectionSkipped,
}) => {
  const { register } = useFormContext();

  return (
    <div
      key={`${sectionItem.itemTitle}-${itemIndex}`}
      className={`
        flex items-start border-t border-grey-300
        px-4 sm:px-0 py-4 sm:py-5
      `}
    >
      <Text
        level={1}
        className="font-semibold self-start text-grey-600 hidden sm:inline mr-4 sm:mr-6 mt-1"
      >
        {itemIndex + 1}
      </Text>

      <div
        className={`
          flex flex-col flex-1
          ${sectionItem.itemType === "checkbox" ? "mr-4" : ""}
          ${isSectionSkipped && "text-grey-500"}
        `}
      >
        <Title level={8} className="line-clamp-1">
          {sectionItem.itemTitle}
        </Title>
        {sectionItem.itemInfo && (
          <Text level={1} className="font-semibold">
            {sectionItem.itemInfo}
          </Text>
        )}

        {sectionItem.itemType === "text" && (
          <Input
            size="lg"
            disabled={isSectionSkipped}
            placeholder="Enter item value..."
            className="input bg-grey-50 hover:bg-white mt-2 sm:mt-4"
            {...register(`sections.${sectionIndex}.values.${itemIndex}`)}
          />
        )}
      </div>

      {sectionItem.itemType === "checkbox" && (
        <div
          className={`
            flex items-center justify-center border bg-grey-50
            w-8 h-8 sm:w-10 sm:h-10
            self-center rounded-full border-grey-400 
            ${isSectionSkipped ? "cursor-not-allowed pointer-events-none" : "cursor-pointer"}
          `}
        >
          <input
            hidden
            type="checkbox"
            id={`sections.${sectionIndex}.values.${itemIndex}`}
            {...register(`sections.${sectionIndex}.values.${itemIndex}`)}
          />

          <label
            className="submission-label flex items-center justify-center cursor-pointer"
            htmlFor={`sections.${sectionIndex}.values.${itemIndex}`}
          >
            <CheckIcon className="w-8 h-8" />
          </label>
        </div>
      )}
    </div>
  );
};
