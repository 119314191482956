import { gql } from "@apollo/client";

export const PublishFileDocument = gql`
  mutation PublishFile($parentID: String!, $input: PublishFileInput!) {
    publishFile(parentID: $parentID, input: $input) {
      id
      name
      size
      description
      key
      type
      highlighted
      deleted
      info
      infoTwo
      moreInfo
      moreInfoTwo
      searchField
      expiryDate
      createdAt
      updatedAt
      metadata
    }
  }
`;

export const UpdateFileDocument = gql`
  mutation UpdateForFile($parentID: String!, $input: ModifyFileInput!) {
    updateForFile(parentID: $parentID, input: $input) {
      id
      name
      size
      description
      key
      type
      highlighted
      deleted
      info
      infoTwo
      moreInfo
      moreInfoTwo
      searchField
      expiryDate
      createdAt
      updatedAt
      metadata
    }
  }
`;

export const RemoveFileDocument = gql`
  mutation RemoveFile($parentID: String!, $id: String!) {
    removeFile(parentID: $parentID, id: $id)
  }
`;
