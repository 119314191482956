export const noNotifications = {
  v: "4.10.0",
  fr: 30,
  ip: 0,
  op: 454,
  w: 500,
  h: 296,
  nm: "9-notification",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "bell-outline Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_1_0p333_0",
              t: 0,
              s: [250.777, 147.796, 0],
              e: [250.777, 133, 0],
              to: [-0.00000775921853, -2.46598386764526, 0],
              ti: [0.00000775921853, -2.53401613235474, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_1_0p333_0",
              t: 120,
              s: [250.777, 133, 0],
              e: [250.777, 163, 0],
              to: [-0.00000775921853, 2.53401613235474, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_1_0p333_0",
              t: 300,
              s: [250.777, 163, 0],
              e: [250.777, 133, 0],
              to: [0, 0, 0],
              ti: [0, 2.53401660919189, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_1_0p333_0",
              t: 390,
              s: [250.777, 133, 0],
              e: [250.777, 147.796, 0],
              to: [0, -2.53401660919189, 0],
              ti: [0, -2.46598339080811, 0],
            },
            {
              t: 450,
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [30.5, 32.5, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [172.566, 172.566, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -0.498],
                    [0.497, 0],
                    [0, 0],
                    [0, 0.497],
                    [-0.497, 0],
                  ],
                  o: [
                    [0.497, 0],
                    [0, 0.497],
                    [0, 0],
                    [-0.498, 0],
                    [0, -0.498],
                    [0, 0],
                  ],
                  v: [
                    [10.788, 0.9],
                    [11.686, 1.799],
                    [10.788, 2.699],
                    [7.191, 2.699],
                    [6.292, 1.799],
                    [7.191, 0.9],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -0.498],
                    [0.496, 0],
                    [0, 0],
                    [0, 0.497],
                    [-0.497, 0],
                  ],
                  o: [
                    [0.496, 0],
                    [0, 0.497],
                    [0, 0],
                    [-0.498, 0],
                    [0, -0.498],
                    [0, 0],
                  ],
                  v: [
                    [-7.191, 0.9],
                    [-6.292, 1.799],
                    [-7.191, 2.699],
                    [-10.788, 2.699],
                    [-11.688, 1.799],
                    [-10.788, 0.9],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.497, 0],
                    [0.003, -0.497],
                    [3.47, 0],
                    [0, 3.469],
                    [-0.497, 0],
                    [0, -0.497],
                    [-2.48, 0],
                    [0, 2.479],
                  ],
                  o: [
                    [0.497, 0],
                    [0, 3.469],
                    [-3.469, 0],
                    [0, -0.498],
                    [0.498, 0],
                    [0, 2.479],
                    [2.48, 0],
                    [0, -0.498],
                  ],
                  v: [
                    [5.393, 6.294],
                    [6.29, 7.192],
                    [-0.003, 13.484],
                    [-6.296, 7.192],
                    [-5.396, 6.294],
                    [-4.497, 7.192],
                    [-0.001, 11.688],
                    [4.494, 7.192],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 3",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 3,
              ty: "sh",
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.496, 0],
                    [0, 0],
                    [0, 0.495],
                    [-0.495, 0],
                    [0, 0],
                    [0, -0.496],
                  ],
                  o: [
                    [0, 0],
                    [-0.495, 0],
                    [0, -0.496],
                    [0, 0],
                    [0.496, 0],
                    [0, 0.495],
                  ],
                  v: [
                    [26.969, 22.475],
                    [-26.969, 22.475],
                    [-27.868, 21.576],
                    [-26.969, 20.676],
                    [26.969, 20.676],
                    [27.868, 21.576],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 4",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 4,
              ty: "sh",
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.163, 0],
                    [0.44, 3.04],
                    [0, 0],
                  ],
                  o: [
                    [-3.162, 0],
                    [0, 0],
                    [-0.44, 3.04],
                  ],
                  v: [
                    [-0.001, 29.667],
                    [-6.202, 24.273],
                    [6.201, 24.273],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 5",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 5,
              ty: "sh",
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 2.613],
                    [0, 0],
                    [-12.393, 0],
                    [0, -11.378],
                    [0, 0],
                    [-1.004, -1.458],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, -11.377],
                    [12.393, 0],
                    [0, 0],
                    [0, 2.613],
                    [0, 0],
                    [1.004, -1.458],
                  ],
                  v: [
                    [-22.475, 12.587],
                    [-22.475, -5.394],
                    [-0.001, -26.028],
                    [22.475, -5.394],
                    [22.475, 12.587],
                    [24.113, 18.878],
                    [-24.113, 18.878],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 6",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 6,
              ty: "sh",
              ix: 7,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.234, 0],
                    [-0.318, -1.134],
                    [0.875, 0],
                    [0.85, -0.084],
                  ],
                  o: [
                    [1.234, 0],
                    [-0.85, -0.084],
                    [-0.874, 0],
                    [0.317, -1.134],
                  ],
                  v: [
                    [-0.001, -29.666],
                    [2.585, -27.695],
                    [-0.001, -27.827],
                    [-2.585, -27.695],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 7",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 7,
              ty: "sh",
              ix: 8,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.487, 0],
                    [0, 3.6],
                    [0, 0],
                    [0, 0],
                    [11.183, 1.922],
                    [2.322, 0],
                    [0.233, -2.258],
                    [0.125, -10.855],
                    [0, 0],
                    [0, 0],
                    [1.273, 0],
                    [0, -1.487],
                    [-1.486, 0],
                    [0, 0],
                    [-4.153, 0],
                    [-0.453, 4.035],
                    [0, 0],
                    [0, 1.486],
                  ],
                  o: [
                    [-1.274, 0],
                    [0, 0],
                    [0, 0],
                    [-0.125, -10.855],
                    [-0.234, -2.258],
                    [-2.321, 0],
                    [-11.183, 1.923],
                    [0, 0],
                    [0, 0],
                    [0, 3.6],
                    [-1.487, 0],
                    [0, 1.486],
                    [0, 0],
                    [0.453, 4.035],
                    [4.154, 0],
                    [0, 0],
                    [1.487, 0],
                    [0, -1.487],
                  ],
                  v: [
                    [26.969, 18.88],
                    [24.272, 12.587],
                    [24.272, -5.635],
                    [24.258, -5.635],
                    [4.447, -27.431],
                    [-0.001, -31.462],
                    [-4.447, -27.431],
                    [-24.258, -5.635],
                    [-24.273, -5.635],
                    [-24.273, 12.587],
                    [-26.969, 18.878],
                    [-29.665, 21.576],
                    [-26.969, 24.271],
                    [-7.999, 24.271],
                    [-0.001, 31.462],
                    [7.999, 24.271],
                    [26.969, 24.271],
                    [29.665, 21.576],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 8",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.455000005984, 0.475, 0.556999954523, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 1,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.455000005984, 0.475, 0.556999954523, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [30.726, 32.232],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 11,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 480,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "bell-bg Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_1_0p333_0",
              t: 0,
              s: [243, 157, 0],
              e: [243, 126.5, 0],
              to: [0, -5.08333349227905, 0],
              ti: [0, -0.16666667163372, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_1_0p333_0",
              t: 90,
              s: [243, 126.5, 0],
              e: [243, 158, 0],
              to: [0, 0.16666667163372, 0],
              ti: [-0.83333331346512, -1.91666662693024, 0],
            },
            {
              i: {
                x: 0.667,
                y: 0.977,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_0p977_0p333_0",
              t: 270,
              s: [243, 158, 0],
              e: [243, 138.002, 0],
              to: [0.83105611801147, 1.91142904758453, 0],
              ti: [-0.84464484453201, 0.28351178765297, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0.024,
              },
              n: "0p667_1_0p333_0p024",
              t: 360,
              s: [243, 138.002, 0],
              e: [243, 157, 0],
              to: [0.00231445301324, -0.00077686458826, 0],
              ti: [0, -3.16635847091675, 0],
            },
            {
              t: 450,
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [30, 32, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [172.566, 172.566, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.487, 0],
                    [0, 3.601],
                    [0, 0],
                    [0, 0],
                    [11.182, 1.922],
                    [2.322, 0],
                    [0.233, -2.259],
                    [0.125, -10.856],
                    [0, 0],
                    [0, 0],
                    [1.272, 0],
                    [0, -1.486],
                    [-1.487, 0],
                    [0, 0],
                    [-4.154, 0],
                    [-0.453, 4.035],
                    [0, 0],
                    [0, 1.487],
                  ],
                  o: [
                    [-1.273, 0],
                    [0, 0],
                    [0, 0],
                    [-0.125, -10.856],
                    [-0.235, -2.259],
                    [-2.322, 0],
                    [-11.183, 1.923],
                    [0, 0],
                    [0, 0],
                    [0, 3.601],
                    [-1.488, 0],
                    [0, 1.487],
                    [0, 0],
                    [0.452, 4.035],
                    [4.154, 0],
                    [0, 0],
                    [1.487, 0],
                    [0, -1.486],
                  ],
                  v: [
                    [26.969, 18.879],
                    [24.273, 12.587],
                    [24.273, -5.634],
                    [24.258, -5.634],
                    [4.449, -27.43],
                    [0.001, -31.463],
                    [-4.447, -27.43],
                    [-24.258, -5.634],
                    [-24.273, -5.634],
                    [-24.273, 12.586],
                    [-26.969, 18.878],
                    [-29.665, 21.575],
                    [-26.969, 24.272],
                    [-7.999, 24.272],
                    [0.001, 31.463],
                    [7.999, 24.272],
                    [26.969, 24.272],
                    [29.665, 21.575],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.936999990426, 0.944999964097, 0.961000031116, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [29.726, 32.232],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 480,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "z Outlines",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_1_0p333_0",
              t: 0,
              s: [323, 109, 0],
              e: [323, 81, 0],
              to: [0, -4.66666650772095, 0],
              ti: [0, -5, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_1_0p333_0",
              t: 120,
              s: [323, 81, 0],
              e: [323, 139, 0],
              to: [0, 5, 0],
              ti: [0, -1.83333337306976, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_1_0p333_0",
              t: 300,
              s: [323, 139, 0],
              e: [323, 92, 0],
              to: [0, 1.83333337306976, 0],
              ti: [0, 5, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              n: "0p667_1_0p333_0",
              t: 390,
              s: [323, 92, 0],
              e: [323, 109, 0],
              to: [0, -5, 0],
              ti: [0, -2.83333325386047, 0],
            },
            {
              t: 450,
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [13, 7.5, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [172.566, 172.566, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [8.025, -5.572],
                    [10.249, -5.564],
                    [8.014, -3.663],
                    [8.01, -2.316],
                    [12.57, -2.299],
                    [12.574, -3.602],
                    [10.294, -3.611],
                    [12.579, -5.557],
                    [12.583, -6.855],
                    [8.029, -6.874],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.122, -2.632],
                    [2.217, -2.62],
                    [-1.133, 0.23],
                    [-1.141, 2.249],
                    [5.697, 2.277],
                    [5.704, 0.322],
                    [2.286, 0.309],
                    [5.712, -2.607],
                    [5.723, -4.559],
                    [-1.118, -4.587],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-12.559, 0.307],
                    [-8.092, 0.322],
                    [-12.577, 4.135],
                    [-12.583, 6.839],
                    [-3.434, 6.874],
                    [-3.426, 4.258],
                    [-7.999, 4.24],
                    [-3.411, 0.339],
                    [-3.402, -2.275],
                    [-12.553, -2.309],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 3",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.455000005984, 0.475, 0.556999954523, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [12.694, 7.389],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 5,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 480,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "heart Outlines 3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 0,
              s: [30],
              e: [100],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 90,
              s: [100],
              e: [30],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 240,
              s: [30],
              e: [100],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p833_1_0p333_0"],
              t: 330,
              s: [100],
              e: [30],
            },
            {
              t: 450,
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p833_1_0p333_0"],
              t: 256,
              s: [0],
              e: [45],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              n: ["0p833_1_0p167_0"],
              t: 259,
              s: [45],
              e: [-45],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              n: ["0p833_1_0p167_0"],
              t: 264,
              s: [-45],
              e: [0],
            },
            {
              t: 268,
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [87.5, 196, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [5.5, 4.5, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [108.081, 108.081, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.378, -0.061],
                    [-0.339, 0.237],
                    [0, 0],
                    [0.876, 1.25],
                    [1.25, -0.875],
                    [0, 0],
                    [0, 0],
                    [1.251, -0.875],
                    [-0.877, -1.25],
                  ],
                  o: [
                    [0.237, 0.339],
                    [0.377, 0.072],
                    [0, 0],
                    [1.251, -0.875],
                    [-0.876, -1.25],
                    [0, 0],
                    [0, 0],
                    [-0.877, -1.25],
                    [-1.252, 0.875],
                    [0, 0],
                  ],
                  v: [
                    [-1.947, 3.812],
                    [-0.977, 4.418],
                    [0.142, 4.18],
                    [3.787, 1.645],
                    [4.466, -2.204],
                    [0.615, -2.883],
                    [0.29, -2.66],
                    [0.066, -2.98],
                    [-3.786, -3.659],
                    [-4.465, 0.189],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 4,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [1, 0.451000019148, 0.517999985639, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [5.635, 4.49],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 480,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "heart Outlines 2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 0,
              s: [30],
              e: [100],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 90,
              s: [100],
              e: [30],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 240,
              s: [30],
              e: [100],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p833_1_0p333_0"],
              t: 330,
              s: [100],
              e: [30],
            },
            {
              t: 450,
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p833_1_0p333_0"],
              t: 127,
              s: [0],
              e: [45],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              n: ["0p833_1_0p167_0"],
              t: 130,
              s: [45],
              e: [-45],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              n: ["0p833_1_0p167_0"],
              t: 135,
              s: [-45],
              e: [0],
            },
            {
              t: 139,
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [259.5, 257, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [5.5, 4.5, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [141.414, 141.414, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.378, -0.061],
                    [-0.339, 0.237],
                    [0, 0],
                    [0.876, 1.25],
                    [1.25, -0.875],
                    [0, 0],
                    [0, 0],
                    [1.251, -0.875],
                    [-0.877, -1.25],
                  ],
                  o: [
                    [0.237, 0.339],
                    [0.377, 0.072],
                    [0, 0],
                    [1.251, -0.875],
                    [-0.876, -1.25],
                    [0, 0],
                    [0, 0],
                    [-0.877, -1.25],
                    [-1.252, 0.875],
                    [0, 0],
                  ],
                  v: [
                    [-1.947, 3.812],
                    [-0.977, 4.418],
                    [0.142, 4.18],
                    [3.787, 1.645],
                    [4.466, -2.204],
                    [0.615, -2.883],
                    [0.29, -2.66],
                    [0.066, -2.98],
                    [-3.786, -3.659],
                    [-4.465, 0.189],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 4,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [1, 0.451000019148, 0.517999985639, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [5.635, 4.49],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 480,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "heart Outlines",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 0,
              s: [30],
              e: [100],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 90,
              s: [100],
              e: [30],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 240,
              s: [30],
              e: [100],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p833_1_0p333_0"],
              t: 330,
              s: [100],
              e: [30],
            },
            {
              t: 450,
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p833_1_0p333_0"],
              t: 386,
              s: [0],
              e: [45],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              n: ["0p833_1_0p167_0"],
              t: 389,
              s: [45],
              e: [-45],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              n: ["0p833_1_0p167_0"],
              t: 394,
              s: [-45],
              e: [0],
            },
            {
              t: 398,
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [380.5, 51.5, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [5.5, 4.5, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [214.141, 214.141, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.378, -0.061],
                    [-0.339, 0.237],
                    [0, 0],
                    [0.876, 1.25],
                    [1.25, -0.875],
                    [0, 0],
                    [0, 0],
                    [1.251, -0.875],
                    [-0.877, -1.25],
                  ],
                  o: [
                    [0.237, 0.339],
                    [0.377, 0.072],
                    [0, 0],
                    [1.251, -0.875],
                    [-0.876, -1.25],
                    [0, 0],
                    [0, 0],
                    [-0.877, -1.25],
                    [-1.252, 0.875],
                    [0, 0],
                  ],
                  v: [
                    [-1.947, 3.812],
                    [-0.977, 4.418],
                    [0.142, 4.18],
                    [3.787, 1.645],
                    [4.466, -2.204],
                    [0.615, -2.883],
                    [0.29, -2.66],
                    [0.066, -2.98],
                    [-3.786, -3.659],
                    [-4.465, 0.189],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 4,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [1, 0.451000019148, 0.517999985639, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [5.635, 4.49],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 480,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "plus Outlines 2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 0,
              s: [30],
              e: [100],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 90,
              s: [100],
              e: [30],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 240,
              s: [30],
              e: [100],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p833_1_0p333_0"],
              t: 330,
              s: [100],
              e: [30],
            },
            {
              t: 450,
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 151,
              s: [0],
              e: [360],
            },
            {
              t: 165,
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [170.5, 59.25, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [7.5, 7.5, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.463, -0.608],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.547, -1.374],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.468, 0.586],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.571, 1.368],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.463, 0.607],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.547, 1.374],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.468, -0.585],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.571, -1.368],
                  ],
                  v: [
                    [4.735, -3.592],
                    [0.746, -1.98],
                    [-0.763, -5.713],
                    [-2.751, -6.512],
                    [-3.747, -4.507],
                    [-2.24, -0.775],
                    [-6.228, 0.837],
                    [-7.144, 2.81],
                    [-5.1, 3.63],
                    [-1.111, 2.019],
                    [0.397, 5.752],
                    [2.426, 6.534],
                    [3.382, 4.546],
                    [1.874, 0.813],
                    [5.863, -0.799],
                    [6.794, -2.734],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 4,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.984000052658, 0.842999985639, 0.616000007181, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [7.365, 7.493],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 480,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "plus Outlines",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 0,
              s: [30],
              e: [100],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 90,
              s: [100],
              e: [30],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 240,
              s: [30],
              e: [100],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p833_1_0p333_0"],
              t: 330,
              s: [100],
              e: [30],
            },
            {
              t: 450,
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              n: ["0p667_1_0p333_0"],
              t: 318,
              s: [0],
              e: [360],
            },
            {
              t: 334,
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [409.5, 173, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [7.5, 7.5, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [173.333, 173.333, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.463, -0.608],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.547, -1.374],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.468, 0.586],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.571, 1.368],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.463, 0.607],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.547, 1.374],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.468, -0.585],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.571, -1.368],
                  ],
                  v: [
                    [4.735, -3.592],
                    [0.746, -1.98],
                    [-0.763, -5.713],
                    [-2.751, -6.512],
                    [-3.747, -4.507],
                    [-2.24, -0.775],
                    [-6.228, 0.837],
                    [-7.144, 2.81],
                    [-5.1, 3.63],
                    [-1.111, 2.019],
                    [0.397, 5.752],
                    [2.426, 6.534],
                    [3.382, 4.546],
                    [1.874, 0.813],
                    [5.863, -0.799],
                    [6.794, -2.734],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 4,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.984000052658, 0.842999985639, 0.616000007181, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [7.365, 7.493],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 480,
      st: 0,
      bm: 0,
    },
  ],
};
