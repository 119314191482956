export default {
  v: "5.5.4",
  fr: 60,
  ip: 0,
  op: 60,
  w: 800,
  h: 800,
  nm: "under maintenance",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Layer 3 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [363.686, 181.874, 0], ix: 2 },
            a: { a: 0, k: [58.921, 48.555, 0], ix: 1 },
            s: { a: 0, k: [225.798, 225.798, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.99, -7.23],
                        [4.3, -3.104],
                        [0.074, -0.041],
                        [1.15, -0.67],
                        [2.38, -1.38],
                        [-0.51, 3.72],
                        [3.43, 6.31],
                        [-3.45, -6.34],
                      ],
                      o: [
                        [-0.51, 3.73],
                        [-0.035, 0.028],
                        [-1.06, 0.71],
                        [-2.38, 1.38],
                        [3.39, -1.96],
                        [0.99, -7.24],
                        [-3.44, -6.34],
                        [3.42, 6.31],
                      ],
                      v: [
                        [8.525, 6.09],
                        [3.238, 15.51],
                        [3.016, 15.666],
                        [0.345, 17.24],
                        [-6.805, 21.39],
                        [-1.265, 11.86],
                        [-6.075, -9.29],
                        [3.725, -15.05],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.885999971278, 0.658999992819, 0.328999986836, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [108.063, 72.73], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.226, -5.327],
                        [0.031, -10.594],
                        [9.219, 5.323],
                        [-0.03, 10.601],
                      ],
                      o: [
                        [9.219, 5.323],
                        [-0.029, 10.601],
                        [-9.226, -5.327],
                        [0.03, -10.594],
                      ],
                      v: [
                        [0.055, -19.187],
                        [16.701, 9.641],
                        [-0.054, 19.19],
                        [-16.702, -9.644],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 15,
                        s: [0.219607844949, 0.219607844949, 0.176470592618, 1],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 26,
                        s: [1, 1, 0.258823513985, 1],
                      },
                      { t: 55, s: [0.219607844949, 0.219607844949, 0.176470592618, 1] },
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [87.313, 70.119], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.259],
                        [4.369, -2.624],
                        [-0.521, 0.298],
                        [4.311, 2.63],
                        [2.883, 3.97],
                        [1.489, 4.918],
                        [-1.184, 4.85],
                        [-3.081, 1.794],
                        [-2.576, 1.5],
                        [-3.01, -0.143],
                        [-3.567, -2.966],
                        [-1.288, -10.322],
                      ],
                      o: [
                        [0.013, 4.822],
                        [-2.545, 1.529],
                        [-4.657, 2.871],
                        [-4.207, -2.567],
                        [-3.017, -4.156],
                        [-1.429, -4.718],
                        [0.849, -3.476],
                        [2.576, -1.499],
                        [2.487, -1.448],
                        [4.732, 0.225],
                        [7.969, 6.627],
                        [0.156, 1.25],
                      ],
                      v: [
                        [25.281, 7.649],
                        [19.466, 20.332],
                        [9.171, 26.299],
                        [-5.626, 24.716],
                        [-16.251, 14.565],
                        [-23.137, 0.828],
                        [-24.109, -13.98],
                        [-18.285, -21.949],
                        [-10.557, -26.446],
                        [-2.724, -29.027],
                        [9.919, -23.341],
                        [25.04, 3.889],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.976000019148, 0.760999971278, 0.411999990426, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [92.298, 67.69], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.99, -7.23],
                        [4.3, -3.104],
                        [0.074, -0.041],
                        [1.15, -0.67],
                        [2.38, -1.38],
                        [-0.51, 3.72],
                        [3.43, 6.31],
                        [-3.45, -6.34],
                      ],
                      o: [
                        [-0.51, 3.73],
                        [-0.035, 0.028],
                        [-1.06, 0.71],
                        [-2.38, 1.38],
                        [3.39, -1.96],
                        [0.99, -7.24],
                        [-3.44, -6.34],
                        [3.42, 6.31],
                      ],
                      v: [
                        [8.525, 6.09],
                        [3.238, 15.51],
                        [3.016, 15.666],
                        [0.345, 17.24],
                        [-6.805, 21.39],
                        [-1.265, 11.86],
                        [-6.075, -9.29],
                        [3.725, -15.05],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.885999971278, 0.658999992819, 0.328999986836, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [108.063, 72.73], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.226, -5.327],
                        [0.031, -10.594],
                        [9.219, 5.323],
                        [-0.03, 10.601],
                      ],
                      o: [
                        [9.219, 5.323],
                        [-0.029, 10.601],
                        [-9.226, -5.327],
                        [0.03, -10.594],
                      ],
                      v: [
                        [0.055, -19.187],
                        [16.701, 9.641],
                        [-0.054, 19.19],
                        [-16.702, -9.644],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 0.675, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [87.313, 70.119], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.259],
                        [4.369, -2.624],
                        [-0.521, 0.298],
                        [4.311, 2.63],
                        [2.883, 3.97],
                        [1.489, 4.918],
                        [-1.184, 4.85],
                        [-3.081, 1.794],
                        [-2.576, 1.5],
                        [-3.01, -0.143],
                        [-3.567, -2.966],
                        [-1.288, -10.322],
                      ],
                      o: [
                        [0.013, 4.822],
                        [-2.545, 1.529],
                        [-4.657, 2.871],
                        [-4.207, -2.567],
                        [-3.017, -4.156],
                        [-1.429, -4.718],
                        [0.849, -3.476],
                        [2.576, -1.499],
                        [2.487, -1.448],
                        [4.732, 0.225],
                        [7.969, 6.627],
                        [0.156, 1.25],
                      ],
                      v: [
                        [25.281, 7.649],
                        [19.466, 20.332],
                        [9.171, 26.299],
                        [-5.626, 24.716],
                        [-16.251, 14.565],
                        [-23.137, 0.828],
                        [-24.109, -13.98],
                        [-18.285, -21.949],
                        [-10.557, -26.446],
                        [-2.724, -29.027],
                        [9.919, -23.341],
                        [25.04, 3.889],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.976000019148, 0.760999971278, 0.411999990426, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [92.298, 67.69], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.99, -7.23],
                        [4.3, -3.104],
                        [0.075, -0.041],
                        [1.15, -0.67],
                        [2.38, -1.38],
                        [-0.51, 3.72],
                        [3.43, 6.31],
                        [-3.45, -6.34],
                      ],
                      o: [
                        [-0.51, 3.73],
                        [-0.035, 0.028],
                        [-1.059, 0.71],
                        [-2.38, 1.38],
                        [3.39, -1.96],
                        [0.99, -7.24],
                        [-3.44, -6.34],
                        [3.42, 6.31],
                      ],
                      v: [
                        [8.525, 6.09],
                        [3.238, 15.51],
                        [3.015, 15.666],
                        [0.345, 17.24],
                        [-6.805, 21.39],
                        [-1.265, 11.86],
                        [-6.075, -9.29],
                        [3.725, -15.05],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.885999971278, 0.658999992819, 0.328999986836, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [41.309, 34.46], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 7",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.226, -5.327],
                        [0.03, -10.594],
                        [9.22, 5.323],
                        [-0.03, 10.601],
                      ],
                      o: [
                        [9.219, 5.323],
                        [-0.03, 10.601],
                        [-9.225, -5.327],
                        [0.03, -10.594],
                      ],
                      v: [
                        [0.055, -19.187],
                        [16.702, 9.641],
                        [-0.054, 19.19],
                        [-16.702, -9.644],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 0,
                        s: [0.219607844949, 0.219607844949, 0.17654748261, 1],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 11,
                        s: [1, 1, 0.258823543787, 1],
                      },
                      { t: 40, s: [0.219607844949, 0.219607844949, 0.176470592618, 1] },
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [20.559, 31.849], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 8",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.26],
                        [4.369, -2.625],
                        [-0.521, 0.298],
                        [4.311, 2.63],
                        [2.883, 3.97],
                        [1.489, 4.919],
                        [-1.185, 4.85],
                        [-3.082, 1.794],
                        [0, 0],
                        [-3.01, -0.143],
                        [-3.567, -2.966],
                        [-1.288, -10.322],
                      ],
                      o: [
                        [0.013, 4.822],
                        [-2.545, 1.529],
                        [-4.657, 2.871],
                        [-4.207, -2.567],
                        [-3.017, -4.156],
                        [-1.429, -4.717],
                        [0.848, -3.476],
                        [0, 0],
                        [2.488, -1.448],
                        [4.732, 0.225],
                        [7.969, 6.627],
                        [0.156, 1.25],
                      ],
                      v: [
                        [25.281, 7.649],
                        [19.466, 20.332],
                        [9.171, 26.299],
                        [-5.626, 24.716],
                        [-16.251, 14.565],
                        [-23.137, 0.827],
                        [-24.108, -13.98],
                        [-18.284, -21.949],
                        [-10.557, -26.447],
                        [-2.724, -29.027],
                        [9.919, -23.341],
                        [25.04, 3.889],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.976000019148, 0.760999971278, 0.411999990426, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [25.544, 29.421], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 9",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.99, -7.23],
                        [4.3, -3.104],
                        [0.075, -0.041],
                        [1.15, -0.67],
                        [2.38, -1.38],
                        [-0.51, 3.72],
                        [3.43, 6.31],
                        [-3.45, -6.34],
                      ],
                      o: [
                        [-0.51, 3.73],
                        [-0.035, 0.028],
                        [-1.059, 0.71],
                        [-2.38, 1.38],
                        [3.39, -1.96],
                        [0.99, -7.24],
                        [-3.44, -6.34],
                        [3.42, 6.31],
                      ],
                      v: [
                        [8.525, 6.09],
                        [3.238, 15.51],
                        [3.015, 15.666],
                        [0.345, 17.24],
                        [-6.805, 21.39],
                        [-1.265, 11.86],
                        [-6.075, -9.29],
                        [3.725, -15.05],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.885999971278, 0.658999992819, 0.328999986836, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [41.309, 34.46], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 10",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.226, -5.327],
                        [0.03, -10.594],
                        [9.22, 5.323],
                        [-0.03, 10.601],
                      ],
                      o: [
                        [9.219, 5.323],
                        [-0.03, 10.601],
                        [-9.225, -5.327],
                        [0.03, -10.594],
                      ],
                      v: [
                        [0.055, -19.187],
                        [16.702, 9.641],
                        [-0.054, 19.19],
                        [-16.702, -9.644],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 0.675, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [20.559, 31.849], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 11",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.26],
                        [4.369, -2.625],
                        [-0.521, 0.298],
                        [4.311, 2.63],
                        [2.883, 3.97],
                        [1.489, 4.919],
                        [-1.185, 4.85],
                        [-3.082, 1.794],
                        [0, 0],
                        [-3.01, -0.143],
                        [-3.567, -2.966],
                        [-1.288, -10.322],
                      ],
                      o: [
                        [0.013, 4.822],
                        [-2.545, 1.529],
                        [-4.657, 2.871],
                        [-4.207, -2.567],
                        [-3.017, -4.156],
                        [-1.429, -4.717],
                        [0.848, -3.476],
                        [0, 0],
                        [2.488, -1.448],
                        [4.732, 0.225],
                        [7.969, 6.627],
                        [0.156, 1.25],
                      ],
                      v: [
                        [25.281, 7.649],
                        [19.466, 20.332],
                        [9.171, 26.299],
                        [-5.626, 24.716],
                        [-16.251, 14.565],
                        [-23.137, 0.827],
                        [-24.108, -13.98],
                        [-18.284, -21.949],
                        [-10.557, -26.447],
                        [-2.724, -29.027],
                        [9.919, -23.341],
                        [25.04, 3.889],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.976000019148, 0.760999971278, 0.411999990426, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [25.544, 29.421], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 12",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 60,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Layer 4 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [276.49, 653.624, 0], ix: 2 },
            a: { a: 0, k: [117.781, 56.187, 0], ix: 1 },
            s: { a: 0, k: [225.798, 225.798, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.312, 0.69],
                        [-0.838, -0.441],
                        [0.265, -0.529],
                        [-2.735, -0.489],
                        [1.074, -0.161],
                        [1.147, 1.276],
                      ],
                      o: [
                        [0, 0],
                        [0.839, 0.442],
                        [-0.264, 0.529],
                        [2.735, 0.489],
                        [-2.24, 0.338],
                        [-1.147, -1.276],
                      ],
                      v: [
                        [-3.25, -2.088],
                        [-1.471, -3.014],
                        [-1.765, -1.721],
                        [0.327, 1.618],
                        [2.926, 3.117],
                        [-2.853, 1.132],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.948999980852, 0.458999992819, 0.361000001197, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [27.289, 5.903], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.149, -1.255],
                        [5.867, 3.389],
                        [0.578, 1.62],
                        [0, 0],
                        [-0.396, -0.232],
                        [-5.834, 3.388],
                        [-0.314, 0.232],
                      ],
                      o: [
                        [-0.545, 1.604],
                        [-5.818, 3.389],
                        [-2.165, -1.272],
                        [0, 0],
                        [0.33, 0.247],
                        [5.867, 3.388],
                        [0.364, -0.215],
                        [0, 0],
                      ],
                      v: [
                        [14.66, 2.933],
                        [10.611, 7.329],
                        [-10.528, 7.329],
                        [-14.66, 2.9],
                        [-11.635, -10.718],
                        [-10.528, -10.008],
                        [10.627, -10.008],
                        [11.652, -10.67],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [31.021, 33.77], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.861, -1.076],
                        [1.234, 0],
                        [0.931, 0.544],
                        [-0.126, 0.795],
                        [0, 0],
                        [-0.7, 0.396],
                        [-0.147, 0.063],
                        [-1.735, -1.004],
                      ],
                      o: [
                        [-0.93, 0.544],
                        [-1.212, 0],
                        [-1.056, -0.606],
                        [0, 0],
                        [0.178, -0.523],
                        [0.136, -0.073],
                        [1.871, -0.857],
                        [1.872, 1.076],
                      ],
                      v: [
                        [3.204, 2.08],
                        [-0.183, 2.895],
                        [-3.549, 2.08],
                        [-4.939, -0.146],
                        [-4.877, -0.407],
                        [-3.57, -1.829],
                        [-3.151, -2.038],
                        [3.183, -1.829],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [31.202, 4.797], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.057, 1.431],
                        [0, 0],
                        [1.107, 0.628],
                        [0.231, 0.099],
                        [0, 0],
                        [2.726, -1.249],
                        [0.215, -0.116],
                        [0.281, -0.826],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.034, -0.215],
                        [-3.653, -2.115],
                        [-5.31, 0.597],
                        [0, 0],
                        [-0.745, 0.207],
                      ],
                      o: [
                        [0, 0],
                        [-0.281, -0.826],
                        [-0.215, -0.116],
                        [0, 0],
                        [-2.739, -1.246],
                        [-0.231, 0.099],
                        [-1.107, 0.628],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.05, 0.215],
                        [-0.363, 2.76],
                        [4.132, 2.386],
                        [0, 0],
                        [0, 0],
                        [2.169, -0.602],
                      ],
                      v: [
                        [12.318, -24.379],
                        [12.218, -24.792],
                        [10.152, -27.038],
                        [9.492, -27.37],
                        [9.43, -27.37],
                        [0.104, -27.37],
                        [-0.557, -27.038],
                        [-2.624, -24.792],
                        [-2.723, -24.379],
                        [-6.85, -5.76],
                        [-11.896, 16.991],
                        [-12.012, 17.635],
                        [-7.085, 25.337],
                        [8.07, 28.022],
                        [8.07, -19.143],
                        [8.699, -20.109],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [26.222, 28.868], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.402, 2.758],
                        [0, 0],
                        [0.05, 0.215],
                        [0, 0],
                        [0, 0],
                        [1.107, 0.628],
                        [0.232, 0.1],
                        [0, 0],
                        [2.726, -1.248],
                        [0.214, -0.116],
                        [0.281, -0.826],
                        [0, 0],
                        [0, 0],
                        [0.033, -0.215],
                        [-3.652, -2.115],
                        [-6.578, 3.819],
                      ],
                      o: [
                        [0, 0],
                        [-0.033, -0.215],
                        [0, 0],
                        [0, 0],
                        [-0.28, -0.826],
                        [-0.215, -0.116],
                        [0, 0],
                        [-2.738, -1.246],
                        [-0.232, 0.1],
                        [-1.108, 0.628],
                        [0, 0],
                        [0, 0],
                        [-0.05, 0.215],
                        [-0.363, 2.761],
                        [6.611, 3.819],
                        [3.628, -2.111],
                      ],
                      v: [
                        [16.782, 17.366],
                        [16.796, 17.366],
                        [16.68, 16.721],
                        [7.508, -24.648],
                        [7.408, -25.061],
                        [5.343, -27.308],
                        [4.681, -27.639],
                        [4.619, -27.639],
                        [-4.706, -27.639],
                        [-5.367, -27.308],
                        [-7.433, -25.061],
                        [-7.532, -24.648],
                        [-16.705, 16.721],
                        [-16.821, 17.366],
                        [-11.896, 25.068],
                        [11.954, 25.068],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [31.032, 29.138], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.129, -0.71],
                        [0, 0],
                        [-1.575, 0.915],
                        [0, 0],
                        [0.695, -2.875],
                        [-4.26, -1.009],
                        [-1.044, -1.518],
                      ],
                      o: [
                        [0, 0],
                        [-1.585, -0.916],
                        [0, 0],
                        [-11.47, 5.678],
                        [-1.213, 5.025],
                        [2.064, 0.488],
                        [1.246, 1.815],
                      ],
                      v: [
                        [10.592, 14.613],
                        [-11.835, 1.667],
                        [-11.854, -1.664],
                        [10.426, -14.613],
                        [-1.674, -2.525],
                        [6.792, 6.467],
                        [12.182, 9.103],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.948999980852, 0.458999992819, 0.361000001197, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [17.639, 49.815], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.808, -1.05],
                        [0, 0],
                        [1.819, 1.05],
                        [0, 0],
                        [-1.807, 1.051],
                        [0, 0],
                        [-1.819, -1.05],
                      ],
                      o: [
                        [1.819, 1.051],
                        [0, 0],
                        [-1.807, 1.05],
                        [0, 0],
                        [-1.819, -1.05],
                        [0, 0],
                        [1.807, -1.05],
                        [0, 0],
                      ],
                      v: [
                        [28.944, -1.912],
                        [28.965, 1.911],
                        [3.394, 16.771],
                        [-3.205, 16.771],
                        [-28.944, 1.911],
                        [-28.966, -1.912],
                        [-3.394, -16.771],
                        [3.205, -16.771],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [31.023, 49.816], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 7",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.323, -0.111],
                        [0.119, -0.033],
                        [0.159, -0.032],
                        [0.195, -0.025],
                        [0.045, -0.005],
                        [0.264, -0.002],
                        [0.042, 0],
                        [0.257, 0.027],
                        [0.038, 0.004],
                        [0.245, 0.055],
                        [0.045, 0.011],
                        [0.207, 0.075],
                        [0.039, 0.016],
                        [0.22, 0.127],
                        [0, 0],
                        [0.171, 0.516],
                        [-0.001, 0.174],
                        [0, 0],
                        [-0.916, -0.529],
                        [0, 0],
                        [-0.234, -0.093],
                        [-0.014, -0.006],
                        [-0.04, -0.014],
                        [-0.138, -0.039],
                        [-0.08, -0.02],
                        [-0.045, -0.01],
                        [-0.039, -0.008],
                        [-0.156, -0.023],
                        [-0.057, -0.007],
                        [-0.039, -0.004],
                        [-0.056, -0.004],
                        [-0.155, -0.004],
                        [-0.049, 0],
                        [-0.042, 0.001],
                        [-0.07, 0.003],
                        [-0.173, 0.016],
                        [-0.018, 0.002],
                        [-0.045, 0.006],
                        [-0.135, 0.023],
                        [-0.057, 0.012],
                        [-0.154, 0.044],
                        [-0.081, 0.026],
                        [-0.037, 0.013],
                        [-0.277, 0.16],
                      ],
                      o: [
                        [-0.277, 0.161],
                        [-0.115, 0.04],
                        [-0.153, 0.043],
                        [-0.189, 0.039],
                        [-0.045, 0.005],
                        [-0.26, 0.028],
                        [-0.042, 0],
                        [-0.26, -0.002],
                        [-0.038, -0.004],
                        [-0.255, -0.03],
                        [-0.045, -0.01],
                        [-0.221, -0.053],
                        [-0.04, -0.015],
                        [-0.249, -0.096],
                        [0, 0],
                        [-0.686, -0.397],
                        [-0.057, -0.172],
                        [0, 0],
                        [-0.002, 0.697],
                        [0, 0],
                        [0.208, 0.12],
                        [0.014, 0.006],
                        [0.039, 0.014],
                        [0.132, 0.048],
                        [0.079, 0.022],
                        [0.044, 0.011],
                        [0.038, 0.009],
                        [0.153, 0.032],
                        [0.057, 0.008],
                        [0.039, 0.005],
                        [0.056, 0.006],
                        [0.154, 0.012],
                        [0.049, 0.001],
                        [0.042, 0],
                        [0.07, 0],
                        [0.175, -0.006],
                        [0.019, -0.002],
                        [0.046, -0.005],
                        [0.137, -0.017],
                        [0.057, -0.011],
                        [0.159, -0.032],
                        [0.083, -0.023],
                        [0.036, -0.012],
                        [0.323, -0.111],
                        [0, 0],
                      ],
                      v: [
                        [16.861, 6.161],
                        [15.949, 6.561],
                        [15.596, 6.669],
                        [15.13, 6.785],
                        [14.555, 6.884],
                        [14.421, 6.902],
                        [13.633, 6.945],
                        [13.507, 6.946],
                        [12.731, 6.903],
                        [12.615, 6.89],
                        [11.863, 6.762],
                        [11.732, 6.729],
                        [11.09, 6.535],
                        [10.969, 6.494],
                        [10.262, 6.161],
                        [-15.478, -8.699],
                        [-16.764, -10.103],
                        [-16.848, -10.623],
                        [-16.859, -6.948],
                        [-15.488, -5.024],
                        [10.251, 9.837],
                        [10.917, 10.155],
                        [10.958, 10.17],
                        [11.079, 10.21],
                        [11.48, 10.346],
                        [11.721, 10.405],
                        [11.854, 10.437],
                        [11.967, 10.465],
                        [12.432, 10.546],
                        [12.604, 10.565],
                        [12.721, 10.578],
                        [12.889, 10.597],
                        [13.352, 10.622],
                        [13.497, 10.621],
                        [13.623, 10.62],
                        [13.833, 10.619],
                        [14.355, 10.585],
                        [14.41, 10.577],
                        [14.544, 10.558],
                        [14.953, 10.502],
                        [15.119, 10.46],
                        [15.585, 10.344],
                        [15.837, 10.281],
                        [15.939, 10.236],
                        [16.85, 9.837],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [17.556, 60.426], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 8",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.049, -0.158],
                        [0.007, -0.018],
                        [0.093, -0.141],
                        [0.019, -0.026],
                        [0.145, -0.131],
                        [0.107, -0.077],
                        [0.114, -0.065],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.019, 0.011],
                        [-0.082, 0.058],
                        [-0.091, 0.082],
                        [-0.008, 0.008],
                        [-0.084, 0.107],
                        [-0.018, 0.026],
                        [-0.017, 0.026],
                        [-0.024, 0.042],
                        [-0.033, 0.09],
                        [-0.005, 0.014],
                        [-0.005, 0.017],
                        [-0.012, 0.056],
                        [-0.002, 0.091],
                        [0, 0.014],
                        [0, 0],
                      ],
                      o: [
                        [-0.006, 0.019],
                        [-0.05, 0.147],
                        [-0.017, 0.027],
                        [-0.102, 0.141],
                        [-0.091, 0.082],
                        [-0.099, 0.07],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.019, -0.011],
                        [0.092, -0.055],
                        [0.108, -0.077],
                        [0.008, -0.007],
                        [0.109, -0.1],
                        [0.02, -0.025],
                        [0.019, -0.025],
                        [0.028, -0.041],
                        [0.049, -0.088],
                        [0.006, -0.014],
                        [0.006, -0.018],
                        [0.018, -0.057],
                        [0.018, -0.09],
                        [0.001, -0.013],
                        [0, 0],
                        [-0.001, 0.161],
                      ],
                      v: [
                        [13.386, -9.738],
                        [13.37, -9.684],
                        [13.15, -9.251],
                        [13.097, -9.173],
                        [12.728, -8.763],
                        [12.414, -8.531],
                        [12.116, -8.319],
                        [-13.455, 6.541],
                        [-13.466, 10.217],
                        [12.106, -4.644],
                        [12.162, -4.677],
                        [12.403, -4.855],
                        [12.718, -5.089],
                        [12.746, -5.11],
                        [13.035, -5.421],
                        [13.087, -5.498],
                        [13.139, -5.576],
                        [13.223, -5.699],
                        [13.347, -5.966],
                        [13.36, -6.009],
                        [13.375, -6.062],
                        [13.424, -6.23],
                        [13.454, -6.501],
                        [13.455, -6.542],
                        [13.466, -10.217],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [47.872, 60.045], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 9",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.265, -0.152],
                        [1.738, -1.085],
                        [0.399, -0.231],
                        [0, 0],
                        [5.318, -2.929],
                        [2.386, -0.229],
                        [2.3, 1.328],
                        [4.501, 2.598],
                        [4.092, 2.426],
                        [-0.394, 1.209],
                        [-0.706, 0.41],
                        [-1.684, 0.978],
                        [-5.332, 3.098],
                        [-1.588, 0.911],
                        [-2.256, -1.245],
                        [-8.594, -4.962],
                      ],
                      o: [
                        [1.863, 1.075],
                        [-0.391, 0.245],
                        [0, 0],
                        [-5.249, 3.051],
                        [-0.903, 0.497],
                        [-2.748, 0.262],
                        [-4.501, -2.599],
                        [-4.12, -2.379],
                        [-0.95, -0.562],
                        [0.269, -0.826],
                        [1.684, -0.978],
                        [5.332, -3.099],
                        [1.584, -0.921],
                        [2.22, -1.272],
                        [8.689, 4.794],
                        [0.265, 0.152],
                      ],
                      v: [
                        [29.976, -1.426],
                        [29.958, 2.565],
                        [28.758, 3.262],
                        [22.633, 6.822],
                        [6.885, 15.973],
                        [1.245, 17.595],
                        [-6.477, 16.696],
                        [-17.566, 9.789],
                        [-29.928, 2.651],
                        [-31.445, -0.006],
                        [-29.695, -1.683],
                        [-24.645, -4.618],
                        [-8.648, -13.914],
                        [-3.898, -16.676],
                        [3.383, -16.779],
                        [29.182, -1.884],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 10, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [34.936, 53.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 10",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.313, 0.69],
                        [-0.838, -0.441],
                        [0.265, -0.529],
                        [-2.735, -0.489],
                        [1.074, -0.161],
                        [1.147, 1.276],
                      ],
                      o: [
                        [0, 0],
                        [0.838, 0.442],
                        [-0.265, 0.529],
                        [2.735, 0.489],
                        [-2.24, 0.338],
                        [-1.147, -1.276],
                      ],
                      v: [
                        [-3.25, -2.088],
                        [-1.471, -3.014],
                        [-1.765, -1.721],
                        [0.327, 1.618],
                        [2.926, 3.117],
                        [-2.853, 1.132],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.948999980852, 0.458999992819, 0.361000001197, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [130.272, 27.211], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 11",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.149, -1.255],
                        [5.867, 3.389],
                        [0.578, 1.62],
                        [0, 0],
                        [-0.397, -0.232],
                        [-5.834, 3.388],
                        [-0.314, 0.232],
                      ],
                      o: [
                        [-0.545, 1.604],
                        [-5.818, 3.389],
                        [-2.166, -1.272],
                        [0, 0],
                        [0.33, 0.248],
                        [5.867, 3.388],
                        [0.364, -0.215],
                        [0, 0],
                      ],
                      v: [
                        [14.66, 2.933],
                        [10.611, 7.329],
                        [-10.528, 7.329],
                        [-14.66, 2.9],
                        [-11.635, -10.718],
                        [-10.528, -10.008],
                        [10.627, -10.008],
                        [11.652, -10.67],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [134.004, 55.078], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 12",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.861, -1.076],
                        [1.234, 0],
                        [0.93, 0.544],
                        [-0.126, 0.795],
                        [0, 0],
                        [-0.7, 0.396],
                        [-0.147, 0.062],
                        [-1.735, -1.004],
                      ],
                      o: [
                        [-0.93, 0.544],
                        [-1.213, 0],
                        [-1.056, -0.606],
                        [0, 0],
                        [0.178, -0.523],
                        [0.136, -0.073],
                        [1.871, -0.858],
                        [1.872, 1.076],
                      ],
                      v: [
                        [3.204, 2.08],
                        [-0.183, 2.895],
                        [-3.549, 2.08],
                        [-4.939, -0.146],
                        [-4.877, -0.407],
                        [-3.57, -1.829],
                        [-3.151, -2.037],
                        [3.183, -1.829],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [134.185, 26.106], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 13",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.057, 1.431],
                        [0, 0],
                        [1.107, 0.628],
                        [0.231, 0.099],
                        [0, 0],
                        [2.726, -1.248],
                        [0.215, -0.116],
                        [0.281, -0.826],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.034, -0.215],
                        [-3.653, -2.115],
                        [-5.31, 0.597],
                        [0, 0],
                        [-0.745, 0.207],
                      ],
                      o: [
                        [0, 0],
                        [-0.281, -0.826],
                        [-0.215, -0.116],
                        [0, 0],
                        [-2.739, -1.246],
                        [-0.231, 0.099],
                        [-1.107, 0.628],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.05, 0.215],
                        [-0.363, 2.76],
                        [4.132, 2.386],
                        [0, 0],
                        [0, 0],
                        [2.169, -0.602],
                      ],
                      v: [
                        [12.318, -24.379],
                        [12.218, -24.792],
                        [10.152, -27.039],
                        [9.492, -27.37],
                        [9.43, -27.37],
                        [0.104, -27.37],
                        [-0.557, -27.039],
                        [-2.624, -24.792],
                        [-2.723, -24.379],
                        [-6.85, -5.761],
                        [-11.896, 16.99],
                        [-12.012, 17.635],
                        [-7.085, 25.337],
                        [8.07, 28.021],
                        [8.07, -19.144],
                        [8.699, -20.11],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [129.205, 50.178], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 14",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.402, 2.758],
                        [0, 0],
                        [0.05, 0.215],
                        [0, 0],
                        [0, 0],
                        [1.107, 0.628],
                        [0.232, 0.1],
                        [0, 0],
                        [2.725, -1.248],
                        [0.214, -0.116],
                        [0.28, -0.826],
                        [0, 0],
                        [0, 0],
                        [0.033, -0.215],
                        [-3.653, -2.115],
                        [-6.577, 3.819],
                      ],
                      o: [
                        [0, 0],
                        [-0.033, -0.215],
                        [0, 0],
                        [0, 0],
                        [-0.28, -0.826],
                        [-0.215, -0.116],
                        [0, 0],
                        [-2.738, -1.246],
                        [-0.232, 0.1],
                        [-1.108, 0.628],
                        [0, 0],
                        [0, 0],
                        [-0.05, 0.215],
                        [-0.364, 2.761],
                        [6.611, 3.819],
                        [3.629, -2.111],
                      ],
                      v: [
                        [16.783, 17.366],
                        [16.796, 17.366],
                        [16.68, 16.721],
                        [7.508, -24.647],
                        [7.408, -25.061],
                        [5.343, -27.308],
                        [4.681, -27.639],
                        [4.619, -27.639],
                        [-4.706, -27.639],
                        [-5.367, -27.308],
                        [-7.433, -25.061],
                        [-7.532, -24.647],
                        [-16.704, 16.721],
                        [-16.821, 17.366],
                        [-11.896, 25.068],
                        [11.953, 25.068],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [134.014, 50.446], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 15",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.129, -0.71],
                        [0, 0],
                        [-1.575, 0.915],
                        [0, 0],
                        [0.695, -2.875],
                        [-4.261, -1.008],
                        [-1.043, -1.518],
                      ],
                      o: [
                        [0, 0],
                        [-1.585, -0.915],
                        [0, 0],
                        [-11.47, 5.678],
                        [-1.213, 5.025],
                        [2.064, 0.488],
                        [1.247, 1.815],
                      ],
                      v: [
                        [10.592, 14.613],
                        [-11.835, 1.667],
                        [-11.854, -1.664],
                        [10.426, -14.613],
                        [-1.674, -2.525],
                        [6.792, 6.467],
                        [12.182, 9.103],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.948999980852, 0.458999992819, 0.361000001197, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [120.622, 71.124], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 16",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 16,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.808, -1.05],
                        [0, 0],
                        [1.819, 1.05],
                        [0, 0],
                        [-1.807, 1.051],
                        [0, 0],
                        [-1.819, -1.05],
                      ],
                      o: [
                        [1.819, 1.051],
                        [0, 0],
                        [-1.807, 1.05],
                        [0, 0],
                        [-1.819, -1.05],
                        [0, 0],
                        [1.807, -1.05],
                        [0, 0],
                      ],
                      v: [
                        [28.944, -1.912],
                        [28.965, 1.911],
                        [3.394, 16.771],
                        [-3.205, 16.771],
                        [-28.944, 1.911],
                        [-28.965, -1.912],
                        [-3.393, -16.771],
                        [3.204, -16.771],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [134.006, 71.124], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 17",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 17,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.323, -0.111],
                        [0.119, -0.033],
                        [0.159, -0.032],
                        [0.194, -0.025],
                        [0.045, -0.005],
                        [0.264, -0.002],
                        [0.042, 0],
                        [0.256, 0.027],
                        [0.039, 0.004],
                        [0.245, 0.055],
                        [0.044, 0.011],
                        [0.208, 0.075],
                        [0.039, 0.016],
                        [0.22, 0.127],
                        [0, 0],
                        [0.171, 0.516],
                        [-0.001, 0.174],
                        [0, 0],
                        [-0.916, -0.529],
                        [0, 0],
                        [-0.234, -0.093],
                        [-0.014, -0.006],
                        [-0.04, -0.014],
                        [-0.138, -0.039],
                        [-0.081, -0.02],
                        [-0.045, -0.01],
                        [-0.039, -0.008],
                        [-0.156, -0.023],
                        [-0.057, -0.007],
                        [-0.039, -0.004],
                        [-0.056, -0.004],
                        [-0.155, -0.004],
                        [-0.048, 0],
                        [-0.042, 0.001],
                        [-0.07, 0.003],
                        [-0.173, 0.016],
                        [-0.018, 0.002],
                        [-0.045, 0.006],
                        [-0.135, 0.023],
                        [-0.057, 0.012],
                        [-0.154, 0.043],
                        [-0.081, 0.026],
                        [-0.037, 0.013],
                        [-0.277, 0.16],
                      ],
                      o: [
                        [-0.277, 0.161],
                        [-0.115, 0.04],
                        [-0.153, 0.043],
                        [-0.189, 0.039],
                        [-0.045, 0.005],
                        [-0.26, 0.028],
                        [-0.042, 0],
                        [-0.26, -0.002],
                        [-0.039, -0.004],
                        [-0.254, -0.03],
                        [-0.045, -0.01],
                        [-0.221, -0.053],
                        [-0.04, -0.015],
                        [-0.249, -0.096],
                        [0, 0],
                        [-0.686, -0.396],
                        [-0.057, -0.172],
                        [0, 0],
                        [-0.002, 0.697],
                        [0, 0],
                        [0.208, 0.12],
                        [0.013, 0.006],
                        [0.039, 0.014],
                        [0.132, 0.048],
                        [0.079, 0.022],
                        [0.044, 0.011],
                        [0.038, 0.009],
                        [0.153, 0.032],
                        [0.057, 0.008],
                        [0.039, 0.005],
                        [0.056, 0.006],
                        [0.154, 0.012],
                        [0.049, 0.001],
                        [0.043, 0],
                        [0.07, 0],
                        [0.175, -0.006],
                        [0.019, -0.002],
                        [0.046, -0.005],
                        [0.137, -0.017],
                        [0.057, -0.011],
                        [0.159, -0.032],
                        [0.083, -0.024],
                        [0.036, -0.012],
                        [0.323, -0.111],
                        [0, 0],
                      ],
                      v: [
                        [16.861, 6.161],
                        [15.949, 6.561],
                        [15.596, 6.669],
                        [15.13, 6.785],
                        [14.555, 6.884],
                        [14.421, 6.902],
                        [13.633, 6.945],
                        [13.507, 6.946],
                        [12.731, 6.903],
                        [12.615, 6.89],
                        [11.863, 6.762],
                        [11.731, 6.729],
                        [11.089, 6.535],
                        [10.968, 6.494],
                        [10.261, 6.161],
                        [-15.478, -8.699],
                        [-16.764, -10.103],
                        [-16.848, -10.623],
                        [-16.859, -6.948],
                        [-15.488, -5.024],
                        [10.251, 9.837],
                        [10.917, 10.155],
                        [10.958, 10.17],
                        [11.078, 10.21],
                        [11.48, 10.346],
                        [11.721, 10.405],
                        [11.854, 10.437],
                        [11.967, 10.465],
                        [12.432, 10.546],
                        [12.604, 10.565],
                        [12.721, 10.578],
                        [12.889, 10.597],
                        [13.352, 10.622],
                        [13.496, 10.621],
                        [13.623, 10.62],
                        [13.833, 10.619],
                        [14.355, 10.585],
                        [14.41, 10.577],
                        [14.544, 10.558],
                        [14.953, 10.502],
                        [15.119, 10.46],
                        [15.585, 10.345],
                        [15.837, 10.281],
                        [15.939, 10.236],
                        [16.85, 9.837],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [120.539, 81.734], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 18",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 18,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.049, -0.158],
                        [0.007, -0.018],
                        [0.093, -0.141],
                        [0.019, -0.025],
                        [0.145, -0.131],
                        [0.107, -0.077],
                        [0.114, -0.065],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.019, 0.01],
                        [-0.083, 0.058],
                        [-0.091, 0.082],
                        [-0.008, 0.008],
                        [-0.084, 0.106],
                        [-0.018, 0.026],
                        [-0.017, 0.026],
                        [-0.023, 0.042],
                        [-0.033, 0.09],
                        [-0.005, 0.014],
                        [-0.005, 0.017],
                        [-0.012, 0.056],
                        [-0.002, 0.09],
                        [0, 0.014],
                        [0, 0],
                      ],
                      o: [
                        [-0.006, 0.019],
                        [-0.05, 0.147],
                        [-0.017, 0.027],
                        [-0.102, 0.141],
                        [-0.091, 0.082],
                        [-0.099, 0.071],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.019, -0.011],
                        [0.092, -0.056],
                        [0.108, -0.077],
                        [0.008, -0.007],
                        [0.109, -0.1],
                        [0.019, -0.025],
                        [0.019, -0.025],
                        [0.028, -0.041],
                        [0.05, -0.088],
                        [0.006, -0.014],
                        [0.006, -0.018],
                        [0.018, -0.056],
                        [0.017, -0.09],
                        [0.001, -0.014],
                        [0, 0],
                        [-0.001, 0.161],
                      ],
                      v: [
                        [13.386, -9.738],
                        [13.37, -9.684],
                        [13.15, -9.251],
                        [13.097, -9.173],
                        [12.728, -8.763],
                        [12.414, -8.531],
                        [12.116, -8.319],
                        [-13.455, 6.541],
                        [-13.466, 10.217],
                        [12.106, -4.644],
                        [12.162, -4.676],
                        [12.403, -4.855],
                        [12.718, -5.089],
                        [12.746, -5.11],
                        [13.035, -5.42],
                        [13.087, -5.498],
                        [13.139, -5.576],
                        [13.222, -5.699],
                        [13.347, -5.966],
                        [13.36, -6.009],
                        [13.375, -6.062],
                        [13.424, -6.23],
                        [13.454, -6.5],
                        [13.455, -6.542],
                        [13.466, -10.217],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [150.855, 81.354], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 19",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 19,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.265, -0.152],
                        [1.738, -1.085],
                        [0.399, -0.232],
                        [2.042, -1.186],
                        [5.317, -2.929],
                        [2.386, -0.229],
                        [2.3, 1.328],
                        [4.5, 2.598],
                        [4.092, 2.426],
                        [-0.395, 1.209],
                        [-0.706, 0.41],
                        [-1.684, 0.978],
                        [0, 0],
                        [-1.588, 0.911],
                        [-2.256, -1.245],
                        [-8.595, -4.962],
                      ],
                      o: [
                        [1.863, 1.075],
                        [-0.392, 0.245],
                        [-2.042, 1.186],
                        [-5.249, 3.051],
                        [-0.903, 0.497],
                        [-2.748, 0.262],
                        [-4.501, -2.599],
                        [-4.12, -2.379],
                        [-0.95, -0.562],
                        [0.269, -0.826],
                        [1.684, -0.978],
                        [0, 0],
                        [1.584, -0.921],
                        [2.22, -1.272],
                        [8.689, 4.794],
                        [0.265, 0.152],
                      ],
                      v: [
                        [29.976, -1.426],
                        [29.958, 2.565],
                        [28.758, 3.263],
                        [22.633, 6.822],
                        [6.886, 15.973],
                        [1.245, 17.595],
                        [-6.476, 16.696],
                        [-17.565, 9.789],
                        [-29.927, 2.651],
                        [-31.444, -0.006],
                        [-29.695, -1.683],
                        [-24.644, -4.618],
                        [-8.648, -13.914],
                        [-3.897, -16.676],
                        [3.384, -16.779],
                        [29.182, -1.884],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 10, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [137.918, 75.059], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 20",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 20,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.312, 0.691],
                        [-0.838, -0.441],
                        [0.265, -0.529],
                        [-2.736, -0.489],
                        [1.074, -0.162],
                        [1.147, 1.275],
                      ],
                      o: [
                        [0, 0],
                        [0.839, 0.442],
                        [-0.264, 0.529],
                        [2.734, 0.488],
                        [-2.24, 0.337],
                        [-1.147, -1.276],
                      ],
                      v: [
                        [-3.25, -2.088],
                        [-1.471, -3.014],
                        [-1.765, -1.721],
                        [0.328, 1.619],
                        [2.926, 3.118],
                        [-2.853, 1.133],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.948999980852, 0.458999992819, 0.361000001197, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [195.827, 46.252], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 21",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 21,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.148, -1.255],
                        [5.867, 3.389],
                        [0.578, 1.619],
                        [0, 0],
                        [-0.397, -0.232],
                        [-5.834, 3.387],
                        [-0.314, 0.232],
                      ],
                      o: [
                        [-0.545, 1.604],
                        [-5.818, 3.389],
                        [-2.166, -1.272],
                        [0, 0],
                        [0.331, 0.248],
                        [5.867, 3.387],
                        [0.364, -0.215],
                        [0, 0],
                      ],
                      v: [
                        [14.66, 2.933],
                        [10.611, 7.329],
                        [-10.528, 7.329],
                        [-14.66, 2.901],
                        [-11.636, -10.718],
                        [-10.528, -10.007],
                        [10.627, -10.007],
                        [11.652, -10.669],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [199.559, 74.12], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 22",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 22,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.86, -1.076],
                        [1.233, 0],
                        [0.93, 0.544],
                        [-0.125, 0.795],
                        [0, 0],
                        [-0.701, 0.397],
                        [-0.146, 0.062],
                        [-1.735, -1.004],
                      ],
                      o: [
                        [-0.931, 0.544],
                        [-1.213, 0],
                        [-1.056, -0.605],
                        [0, 0],
                        [0.178, -0.523],
                        [0.136, -0.073],
                        [1.871, -0.858],
                        [1.871, 1.076],
                      ],
                      v: [
                        [3.205, 2.08],
                        [-0.182, 2.895],
                        [-3.548, 2.08],
                        [-4.939, -0.146],
                        [-4.876, -0.407],
                        [-3.569, -1.829],
                        [-3.151, -2.037],
                        [3.184, -1.829],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [199.74, 45.147], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 23",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 23,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.056, 1.43],
                        [0, 0],
                        [1.108, 0.628],
                        [0.232, 0.099],
                        [0, 0],
                        [2.726, -1.248],
                        [0.214, -0.115],
                        [0.281, -0.827],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.033, -0.215],
                        [-3.652, -2.115],
                        [-5.31, 0.597],
                        [0, 0],
                        [-0.745, 0.206],
                      ],
                      o: [
                        [0, 0],
                        [-0.28, -0.827],
                        [-0.214, -0.115],
                        [0, 0],
                        [-2.738, -1.246],
                        [-0.231, 0.099],
                        [-1.107, 0.628],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.05, 0.215],
                        [-0.363, 2.759],
                        [4.132, 2.387],
                        [0, 0],
                        [0, 0],
                        [2.169, -0.602],
                      ],
                      v: [
                        [12.318, -24.378],
                        [12.218, -24.791],
                        [10.152, -27.039],
                        [9.491, -27.37],
                        [9.429, -27.37],
                        [0.104, -27.37],
                        [-0.557, -27.039],
                        [-2.623, -24.791],
                        [-2.723, -24.378],
                        [-6.85, -5.761],
                        [-11.895, 16.991],
                        [-12.011, 17.636],
                        [-7.086, 25.337],
                        [8.07, 28.021],
                        [8.07, -19.144],
                        [8.699, -20.109],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [194.76, 69.219], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 24",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 24,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.401, 2.757],
                        [0, 0],
                        [0.05, 0.215],
                        [0, 0],
                        [0, 0],
                        [1.107, 0.628],
                        [0.231, 0.099],
                        [0, 0],
                        [2.726, -1.249],
                        [0.215, -0.115],
                        [0.281, -0.826],
                        [0, 0],
                        [0, 0],
                        [0.034, -0.215],
                        [-3.653, -2.115],
                        [-6.578, 3.819],
                      ],
                      o: [
                        [0, 0],
                        [-0.033, -0.215],
                        [0, 0],
                        [0, 0],
                        [-0.281, -0.826],
                        [-0.215, -0.115],
                        [0, 0],
                        [-2.738, -1.247],
                        [-0.231, 0.099],
                        [-1.107, 0.628],
                        [0, 0],
                        [0, 0],
                        [-0.049, 0.215],
                        [-0.363, 2.76],
                        [6.611, 3.819],
                        [3.629, -2.11],
                      ],
                      v: [
                        [16.783, 17.367],
                        [16.797, 17.367],
                        [16.681, 16.722],
                        [7.508, -24.647],
                        [7.409, -25.06],
                        [5.343, -27.308],
                        [4.682, -27.638],
                        [4.62, -27.638],
                        [-4.706, -27.638],
                        [-5.367, -27.308],
                        [-7.433, -25.06],
                        [-7.532, -24.647],
                        [-16.705, 16.722],
                        [-16.821, 17.367],
                        [-11.895, 25.068],
                        [11.954, 25.068],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [199.569, 69.487], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 25",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 25,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.13, -0.711],
                        [0, 0],
                        [-1.575, 0.915],
                        [0, 0],
                        [0.695, -2.876],
                        [-4.26, -1.009],
                        [-1.044, -1.518],
                      ],
                      o: [
                        [0, 0],
                        [-1.585, -0.915],
                        [0, 0],
                        [-11.47, 5.677],
                        [-1.213, 5.025],
                        [2.064, 0.488],
                        [1.246, 1.816],
                      ],
                      v: [
                        [10.592, 14.613],
                        [-11.835, 1.665],
                        [-11.854, -1.664],
                        [10.428, -14.613],
                        [-1.674, -2.525],
                        [6.792, 6.467],
                        [12.182, 9.102],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.948999980852, 0.458999992819, 0.361000001197, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [186.177, 90.165], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 26",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 26,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.808, -1.051],
                        [0, 0],
                        [1.819, 1.049],
                        [0, 0],
                        [-1.808, 1.05],
                        [0, 0],
                        [-1.819, -1.05],
                      ],
                      o: [
                        [1.819, 1.051],
                        [0, 0],
                        [-1.807, 1.049],
                        [0, 0],
                        [-1.819, -1.051],
                        [0, 0],
                        [1.808, -1.051],
                        [0, 0],
                      ],
                      v: [
                        [28.944, -1.912],
                        [28.965, 1.912],
                        [3.394, 16.773],
                        [-3.205, 16.773],
                        [-28.944, 1.912],
                        [-28.965, -1.911],
                        [-3.394, -16.77],
                        [3.205, -16.771],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [199.561, 90.165], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 27",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 27,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.323, -0.112],
                        [0.119, -0.033],
                        [0.159, -0.032],
                        [0.195, -0.025],
                        [0.045, -0.005],
                        [0.265, -0.002],
                        [0.042, 0],
                        [0.257, 0.028],
                        [0.038, 0.004],
                        [0.245, 0.054],
                        [0.044, 0.011],
                        [0.207, 0.075],
                        [0.039, 0.015],
                        [0.22, 0.127],
                        [0, 0],
                        [0.171, 0.516],
                        [-0.001, 0.175],
                        [0, 0],
                        [-0.916, -0.528],
                        [0, 0],
                        [-0.234, -0.093],
                        [-0.014, -0.005],
                        [-0.04, -0.015],
                        [-0.139, -0.04],
                        [-0.08, -0.019],
                        [-0.045, -0.009],
                        [-0.039, -0.009],
                        [-0.156, -0.022],
                        [-0.057, -0.007],
                        [-0.039, -0.005],
                        [-0.056, -0.005],
                        [-0.155, -0.004],
                        [-0.049, 0],
                        [-0.042, 0],
                        [-0.071, 0.002],
                        [-0.173, 0.017],
                        [-0.019, 0.002],
                        [-0.046, 0.006],
                        [-0.135, 0.024],
                        [-0.057, 0.012],
                        [-0.154, 0.043],
                        [-0.081, 0.026],
                        [-0.036, 0.013],
                        [-0.277, 0.161],
                      ],
                      o: [
                        [-0.277, 0.16],
                        [-0.115, 0.039],
                        [-0.153, 0.043],
                        [-0.189, 0.039],
                        [-0.045, 0.006],
                        [-0.26, 0.028],
                        [-0.042, 0.001],
                        [-0.26, -0.002],
                        [-0.038, -0.004],
                        [-0.255, -0.031],
                        [-0.044, -0.01],
                        [-0.222, -0.054],
                        [-0.04, -0.014],
                        [-0.249, -0.097],
                        [0, 0],
                        [-0.687, -0.397],
                        [-0.057, -0.172],
                        [0, 0],
                        [-0.002, 0.697],
                        [0, 0],
                        [0.208, 0.12],
                        [0.014, 0.005],
                        [0.039, 0.014],
                        [0.133, 0.047],
                        [0.078, 0.021],
                        [0.044, 0.011],
                        [0.038, 0.009],
                        [0.153, 0.031],
                        [0.057, 0.009],
                        [0.039, 0.005],
                        [0.056, 0.005],
                        [0.154, 0.012],
                        [0.049, 0.001],
                        [0.042, 0.001],
                        [0.07, -0.001],
                        [0.174, -0.006],
                        [0.019, -0.002],
                        [0.045, -0.005],
                        [0.136, -0.016],
                        [0.057, -0.011],
                        [0.159, -0.033],
                        [0.083, -0.024],
                        [0.037, -0.012],
                        [0.323, -0.112],
                        [0, 0],
                      ],
                      v: [
                        [16.861, 6.162],
                        [15.949, 6.562],
                        [15.596, 6.669],
                        [15.13, 6.785],
                        [14.555, 6.884],
                        [14.421, 6.902],
                        [13.633, 6.945],
                        [13.507, 6.946],
                        [12.731, 6.903],
                        [12.615, 6.891],
                        [11.863, 6.763],
                        [11.732, 6.73],
                        [11.089, 6.535],
                        [10.968, 6.495],
                        [10.261, 6.162],
                        [-15.477, -8.698],
                        [-16.764, -10.103],
                        [-16.848, -10.623],
                        [-16.859, -6.948],
                        [-15.488, -5.024],
                        [10.251, 9.837],
                        [10.917, 10.156],
                        [10.958, 10.17],
                        [11.078, 10.211],
                        [11.481, 10.347],
                        [11.721, 10.405],
                        [11.854, 10.437],
                        [11.967, 10.466],
                        [12.432, 10.546],
                        [12.604, 10.565],
                        [12.721, 10.579],
                        [12.889, 10.598],
                        [13.352, 10.622],
                        [13.497, 10.621],
                        [13.623, 10.621],
                        [13.835, 10.62],
                        [14.355, 10.585],
                        [14.411, 10.577],
                        [14.546, 10.558],
                        [14.953, 10.502],
                        [15.119, 10.461],
                        [15.585, 10.345],
                        [15.837, 10.282],
                        [15.939, 10.237],
                        [16.85, 9.837],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [186.094, 100.775], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 28",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 28,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.049, -0.158],
                        [0.007, -0.019],
                        [0.093, -0.141],
                        [0.019, -0.025],
                        [0.145, -0.131],
                        [0.108, -0.076],
                        [0.114, -0.065],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.019, 0.01],
                        [-0.082, 0.058],
                        [-0.091, 0.082],
                        [-0.008, 0.007],
                        [-0.084, 0.107],
                        [-0.018, 0.027],
                        [-0.017, 0.025],
                        [-0.024, 0.042],
                        [-0.034, 0.09],
                        [-0.005, 0.013],
                        [-0.005, 0.017],
                        [-0.012, 0.056],
                        [-0.002, 0.09],
                        [0, 0.015],
                        [0, 0],
                      ],
                      o: [
                        [-0.005, 0.018],
                        [-0.05, 0.146],
                        [-0.017, 0.026],
                        [-0.101, 0.142],
                        [-0.091, 0.082],
                        [-0.099, 0.071],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.019, -0.012],
                        [0.092, -0.056],
                        [0.108, -0.077],
                        [0.008, -0.006],
                        [0.109, -0.101],
                        [0.02, -0.025],
                        [0.019, -0.025],
                        [0.028, -0.042],
                        [0.049, -0.088],
                        [0.005, -0.014],
                        [0.006, -0.019],
                        [0.018, -0.056],
                        [0.018, -0.09],
                        [0.001, -0.013],
                        [0, 0],
                        [-0.001, 0.161],
                      ],
                      v: [
                        [13.386, -9.738],
                        [13.37, -9.684],
                        [13.15, -9.25],
                        [13.097, -9.173],
                        [12.728, -8.763],
                        [12.414, -8.531],
                        [12.116, -8.319],
                        [-13.455, 6.542],
                        [-13.466, 10.216],
                        [12.106, -4.643],
                        [12.162, -4.677],
                        [12.403, -4.855],
                        [12.718, -5.09],
                        [12.746, -5.109],
                        [13.035, -5.421],
                        [13.087, -5.499],
                        [13.139, -5.576],
                        [13.223, -5.699],
                        [13.348, -5.966],
                        [13.36, -6.009],
                        [13.375, -6.063],
                        [13.424, -6.23],
                        [13.454, -6.5],
                        [13.455, -6.543],
                        [13.466, -10.216],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [216.41, 100.395], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 29",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 29,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.265, -0.153],
                        [1.737, -1.084],
                        [0.399, -0.232],
                        [2.042, -1.185],
                        [5.318, -2.929],
                        [2.386, -0.229],
                        [2.3, 1.328],
                        [4.501, 2.599],
                        [4.092, 2.426],
                        [-0.394, 1.21],
                        [-0.706, 0.41],
                        [-1.684, 0.978],
                        [0, 0],
                        [-1.589, 0.911],
                        [-2.256, -1.244],
                        [-8.594, -4.962],
                      ],
                      o: [
                        [1.863, 1.075],
                        [-0.392, 0.245],
                        [-2.042, 1.187],
                        [-5.249, 3.051],
                        [-0.902, 0.498],
                        [-2.748, 0.263],
                        [-4.5, -2.598],
                        [-4.12, -2.379],
                        [-0.95, -0.562],
                        [0.269, -0.826],
                        [1.684, -0.978],
                        [0, 0],
                        [1.584, -0.92],
                        [2.219, -1.272],
                        [8.689, 4.795],
                        [0.265, 0.153],
                      ],
                      v: [
                        [29.976, -1.426],
                        [29.958, 2.565],
                        [28.757, 3.263],
                        [22.632, 6.822],
                        [6.885, 15.973],
                        [1.245, 17.595],
                        [-6.477, 16.695],
                        [-17.565, 9.789],
                        [-29.927, 2.651],
                        [-31.444, -0.007],
                        [-29.695, -1.684],
                        [-24.645, -4.618],
                        [-8.648, -13.914],
                        [-3.896, -16.676],
                        [3.383, -16.779],
                        [29.182, -1.884],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 10, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [203.474, 94.1], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 30",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 30,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 60,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Layer 2 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [419.435, 381.684, 0], ix: 2 },
            a: { a: 0, k: [122.675, 114.604, 0], ix: 1 },
            s: { a: 0, k: [225.798, 225.798, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-2.95, 8.339],
                        [0.05, 7.633],
                        [2.95, -8.339],
                        [-0.041, -7.611],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.027000000898, 0.027000000898, 0.027000000898, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [148.319, 207.371], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-16.925, -8.487],
                        [-13.925, -9.193],
                        [16.925, 8.482],
                        [13.933, 9.193],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.027000000898, 0.027000000898, 0.027000000898, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [126.09, 159.877], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6.205, 2.34],
                        [3.215, 3.05],
                        [-6.205, -2.34],
                        [-3.205, -3.05],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.027000000898, 0.027000000898, 0.027000000898, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [13.665, 95.49], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-16.925, -8.487],
                        [-13.925, -9.193],
                        [16.925, 8.48],
                        [13.932, 9.193],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.027000000898, 0.027000000898, 0.027000000898, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [64.623, 124.685], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-2.95, 8.339],
                        [0.05, 7.633],
                        [2.95, -8.339],
                        [-0.041, -7.611],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.027000000898, 0.027000000898, 0.027000000898, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [159.471, 146.561], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-16.925, -8.486],
                        [-13.925, -9.192],
                        [16.925, 8.481],
                        [13.933, 9.192],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.027000000898, 0.027000000898, 0.027000000898, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [129.784, 71.342], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-16.925, -8.487],
                        [-13.925, -9.193],
                        [16.925, 8.482],
                        [13.933, 9.193],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.027000000898, 0.027000000898, 0.027000000898, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [68.337, 36.148], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 7",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [23.285, 30.07],
                        [20.375, 46.02],
                        [-1.975, 33.22],
                        [-23.285, -46.02],
                        [7.575, -28.35],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.097999999102, 0.090000002992, 0.090000002992, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [136.145, 108.88], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 8",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [26.08, 48.46],
                        [-4.78, 30.78],
                        [-26.08, -48.46],
                        [4.78, -30.78],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.097999999102, 0.090000002992, 0.090000002992, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [77.49, 76.12], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 9",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [15.235, 37.29],
                        [-15.235, 19.84],
                        [-4.815, -37.29],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.097999999102, 0.090000002992, 0.090000002992, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [26.655, 51.96], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 10",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [78.085, 11.2],
                        [69.925, 55.95],
                        [67.015, 71.9],
                        [44.665, 59.1],
                        [14.065, 41.58],
                        [-16.795, 23.9],
                        [-47.615, 6.25],
                        [-78.085, -11.2],
                        [-67.665, -68.33],
                        [-67.015, -71.9],
                        [-38.095, -55.34],
                        [-7.235, -37.66],
                        [23.355, -20.14],
                        [54.215, -2.47],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.964999988032, 0.573000021542, 0.118000000598, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [89.505, 83], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 11",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-79.589, -10.845],
                        [65.518, 72.256],
                        [68.518, 71.55],
                        [79.589, 10.844],
                        [-65.519, -72.256],
                        [-68.519, -71.55],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.838999968884, 0.426999978458, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [91.005, 82.647], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 12",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-16.925, -8.487],
                        [-13.925, -9.193],
                        [16.925, 8.48],
                        [13.932, 9.193],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.027000000898, 0.027000000898, 0.027000000898, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [118.621, 132.151], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 13",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-16.925, -8.487],
                        [-13.925, -9.193],
                        [16.925, 8.48],
                        [13.933, 9.193],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.027000000898, 0.027000000898, 0.027000000898, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [57.174, 96.957], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 14",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [19.555, 16.205],
                        [16.645, 32.155],
                        [-5.705, 19.355],
                        [-19.555, -32.155],
                        [11.305, -14.484],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.097999999102, 0.090000002992, 0.090000002992, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [128.715, 183.555], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 15",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [22.355, 34.595],
                        [-8.505, 16.915],
                        [-22.355, -34.595],
                        [8.505, -16.925],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.097999999102, 0.090000002992, 0.090000002992, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [70.055, 150.795], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 16",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 16,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [15.235, 28.455],
                        [-15.235, 11.005],
                        [-8.036, -28.455],
                        [1.385, -23.065],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.097999999102, 0.090000002992, 0.090000002992, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [15.495, 121.605], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 17",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 17,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [76.15, 21.83],
                        [71.86, 45.33],
                        [68.95, 61.28],
                        [46.6, 48.48],
                        [16, 30.96],
                        [-14.86, 13.28],
                        [-45.68, -4.37],
                        [-76.15, -21.82],
                        [-68.95, -61.28],
                        [-59.53, -55.89],
                        [-28.71, -38.23],
                        [2.15, -20.56],
                        [32.75, -3.03],
                        [63.61, 14.641],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.964999988032, 0.573000021542, 0.118000000598, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [76.41, 154.43], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 18",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 18,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [77.66, 21.475],
                        [70.45, 60.935],
                        [67.45, 61.635],
                        [-77.66, -21.465],
                        [-70.45, -60.925],
                        [-67.45, -61.635],
                        [-58.04, -56.245],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.838999968884, 0.426999978458, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [77.91, 154.075], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 19",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 19,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [19.33, -77.192],
                        [10.506, -81.779],
                        [-19.33, 77.191],
                        [-10.507, 81.779],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.816000007181, 0.823999980852, 0.827000038297, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [138.819, 147.179], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 20",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 20,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [20.801, -77.5],
                        [11.977, -82.088],
                        [9.035, -81.471],
                        [-20.801, 77.5],
                        [-11.978, 82.088],
                        [-9.036, 81.471],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.732999973671, 0.741000007181, 0.74900004069, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [140.29, 146.87], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 21",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 21,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [18.275, -16.461],
                        [-25.946, 8.982],
                        [-18.274, 16.461],
                        [25.946, -8.983],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.816000007181, 0.823999980852, 0.827000038297, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [163.353, 165.126], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 22",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 22,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [23.125, -9.296],
                        [-21.096, 16.147],
                        [-23.125, 9.297],
                        [21.096, -16.147],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.732999973671, 0.741000007181, 0.74900004069, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [160.51, 164.815], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 23",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 23,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-13.258, -57.668],
                        [-22.082, -62.256],
                        [13.259, 57.667],
                        [22.082, 62.256],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.651000019148, 0.658999992819, 0.670999983245, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [174.348, 127.038], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 24",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 24,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-11.787, -57.977],
                        [-20.611, -62.565],
                        [-23.553, -61.948],
                        [11.788, 57.976],
                        [20.611, 62.565],
                        [23.553, 61.947],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.732999973671, 0.741000007181, 0.74900004069, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [175.818, 126.729], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 25",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 25,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [19.33, -77.192],
                        [10.507, -81.779],
                        [-19.33, 77.191],
                        [-10.507, 81.779],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.816000007181, 0.823999980852, 0.827000038297, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [27.087, 83.265], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 26",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 26,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [20.801, -77.5],
                        [11.977, -82.088],
                        [9.036, -81.471],
                        [-20.801, 77.5],
                        [-11.977, 82.088],
                        [-9.036, 81.471],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.732999973671, 0.741000007181, 0.74900004069, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [28.557, 82.956], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 27",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 27,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-54.421, -29.123],
                        [50.01, 30.965],
                        [54.421, 29.122],
                        [-50.011, -30.965],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.732999973671, 0.741000007181, 0.74900004069, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [101.052, 45.636], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 28",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 28,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-54.612, -21.955],
                        [49.819, 38.133],
                        [54.612, 21.955],
                        [-49.819, -38.133],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.651000019148, 0.658999992819, 0.670999983245, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [96.502, 54.653], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 29",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 29,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [23.044, -12.332],
                        [-21.177, 13.112],
                        [-23.044, 12.332],
                        [21.177, -13.112],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.816000007181, 0.823999980852, 0.827000038297, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [46.851, 97.083], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 30",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 30,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [23.125, -9.297],
                        [-21.096, 16.147],
                        [-23.125, 9.297],
                        [21.096, -16.147],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.732999973671, 0.741000007181, 0.74900004069, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [48.778, 100.901], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 31",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 31,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-13.258, -57.668],
                        [-22.082, -62.256],
                        [13.259, 57.668],
                        [22.082, 62.256],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.651000019148, 0.658999992819, 0.670999983245, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [62.616, 63.124], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 32",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 32,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-11.787, -57.977],
                        [-20.611, -62.565],
                        [-23.553, -61.948],
                        [11.788, 57.977],
                        [20.611, 62.565],
                        [23.553, 61.947],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.732999973671, 0.741000007181, 0.74900004069, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [64.086, 62.815], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 33",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 33,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-45.721, -32.314],
                        [-37.678, -35.258],
                        [66.077, 25.186],
                        [55.98, 28.755],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-18.529, -50.57],
                        [-27.667, -46.181],
                        [-23.384, -50.121],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-11.822, -51.189],
                        [-13.689, -50.529],
                        [-14.411, -50.951],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 3,
                  ty: "sh",
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [100.418, 13.047],
                        [97.32, 14.142],
                        [96.124, 13.443],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 4,
                  ty: "sh",
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [99.467, 8.74],
                        [89.208, 9.415],
                        [-6.852, -46.546],
                        [6.686, -51.503],
                        [-12.773, -55.496],
                        [-50.808, -52.992],
                        [-41.984, -48.404],
                        [-33.237, -49.211],
                        [-42.943, -40.189],
                        [-52.947, -36.651],
                        [-56.4, -38.727],
                        [-82.396, -26.241],
                        [-118.926, -13.327],
                        [-110.102, -8.74],
                        [-76.487, -21.048],
                        [24.807, 39.776],
                        [-6.686, 50.909],
                        [2.137, 55.496],
                        [118.926, 12.733],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 5",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "mm",
                  mm: 1,
                  nm: "Merge Paths 1",
                  mn: "ADBE Vector Filter - Merge",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 10, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [126.175, 173.462], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 34",
              np: 7,
              cix: 2,
              bm: 0,
              ix: 34,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 60,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Layer 5 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [635.314, 276.33, 0], ix: 2 },
            a: { a: 0, k: [71.763, 69.956, 0], ix: 1 },
            s: { a: 0, k: [225.798, 225.798, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.299, 0.693],
                        [4.299, -4.207],
                        [1.769, 4.207],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [31.042, 86.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.299, 0.693],
                        [4.299, -4.207],
                        [1.769, 4.207],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [60.724, 103.965], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-3.034, -0.449],
                        [1.498, -3.065],
                        [3.034, 3.065],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [59.459, 105.107], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.885, -26.952],
                        [3.394, 13.142],
                        [-1.815, 30.471],
                        [-7.885, 26.952],
                        [-2.681, 9.635],
                        [1.817, -30.471],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [34.627, 60.495], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.885, -26.952],
                        [3.393, 13.142],
                        [-1.816, 30.471],
                        [-7.885, 26.952],
                        [-2.682, 9.635],
                        [1.816, -30.471],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [64.309, 77.701], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [35.013, 27.648],
                        [-47.617, -20.37],
                        [-35.012, -27.648],
                        [47.617, 20.371],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.948999980852, 0.458999992819, 0.361000001197, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [61.329, 27.898], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 7",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [38.58, 48.393],
                        [-44.05, 0.374],
                        [-38.58, -48.393],
                        [44.05, -0.374],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [52.292, 55.921], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 8",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [37.319, 37.297],
                        [-45.311, -10.722],
                        [-37.319, -37.297],
                        [45.311, 10.722],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.948999980852, 0.458999992819, 0.361000001197, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [45.561, 93.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 9",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [41.327, 28.396],
                        [-41.302, -19.623],
                        [-41.327, -28.396],
                        [41.303, 19.623],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.933000033509, 0.226999993418, 0.141000007181, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [41.577, 111.266], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 10",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6.096, -45.697],
                        [11.831, -3.398],
                        [19.948, 13.876],
                        [19.971, 22.648],
                        [8.766, 29.118],
                        [4.992, 27.663],
                        [-4.987, 33.424],
                        [-8.742, 39.227],
                        [-19.948, 45.697],
                        [-101.682, -2.271],
                        [-94.576, -37.261],
                        [-89.106, -86.415],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.842999985639, 0.152999997606, 0.152999997606, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [102.852, 93.966], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [11.139, 37.464],
                        [-71.491, -10.554],
                        [-11.139, -37.464],
                        [71.491, 10.555],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 10, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [71.785, 102.197], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 11",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 60,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "construction",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
