import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MutationCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { userStore, sectionStore } from "@eolas-medical/core";

import { isMobile } from "Utilities/helpers";
import { errorStore } from "Stores/ErrorStore";
import { NotificationsProvider } from "Components/Notifications";
import { useBrowserState } from "Hooks/useBrowserState";
import { useSentry } from "./SentryContext";
import { LDProvider } from "./LaunchDarklyContext";
import { observer } from "mobx-react-lite";
import { globalWaitForHydration } from "Utilities/appStartup";
import { useAppSync } from "Hooks";
import { IntercomProvider } from "react-use-intercom";
import envConfig from "env-config";
import MasterSearchProvider from "Components/MasterSearch/context/MasterSearchProvider";
import { PushNotificationsProvider } from "services/pushNotifications";
import { SpacesProvider } from "modules/spaces/context/SpacesProvider";
import { SessionProvider } from "modules/session";
import { DocumentCacheProvider } from "services/documentCache";

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error: any) => {
      errorStore.captureError({ error: error?.message, source: "user" });
    },
  }),
});

export const EolasProviders: React.FC = observer(({ children }) => {
  const [hydrated, setHydration] = useState<boolean>(false);
  const { sync } = useAppSync();

  const { status, prevStatus } = useBrowserState();
  const { setUser: setSentryUser } = useSentry();

  const appNetworkStatus = useRef<"online" | "offline">("online");
  const ismobile = useMemo(() => isMobile(), []);

  const { appID } = sectionStore;
  const {
    userSession: { isLoggedIn, isInOrganisation },
  } = userStore;

  const updateOnlineStatus = useCallback(async () => {
    const networkStatus = navigator.onLine ? "online" : "offline";
    if (appNetworkStatus.current === "offline" && networkStatus === "online" && appID) {
      sync();
    }
    appNetworkStatus.current = networkStatus;
  }, [appID, sync]);

  useEffect(() => {
    if (isLoggedIn && isInOrganisation) {
      sync();
    }
  }, [isLoggedIn, isInOrganisation, sync]);

  useEffect(() => {
    globalWaitForHydration()
      .then(() => {
        setHydration(true);
        const userData = userStore.userData;
        if (userData.id && userData.email) {
          setSentryUser({
            id: userData.id,
            appUserID: userStore.appUserID,
            appID: appID,
          });
        }
      })
      .catch((error) => {
        errorStore.captureError({
          error,
          source: "network",
          retryCallback: globalWaitForHydration,
        });
      });
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, [appID, setSentryUser, updateOnlineStatus]);

  useEffect(() => {
    if (ismobile && status === "visible" && prevStatus === "hidden") {
      sync();
    }
  }, [status, prevStatus, ismobile, sync]);

  if (!hydrated) {
    return <div />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <DocumentCacheProvider>
        <IntercomProvider appId={envConfig.REACT_APP_INTERCOM_APP_ID}>
          <LDProvider>
            <SessionProvider>
              <SpacesProvider>
                <PushNotificationsProvider>
                  <NotificationsProvider>
                    <MasterSearchProvider>{children}</MasterSearchProvider>
                  </NotificationsProvider>
                </PushNotificationsProvider>
              </SpacesProvider>
            </SessionProvider>
          </LDProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </IntercomProvider>
      </DocumentCacheProvider>
    </QueryClientProvider>
  );
});
