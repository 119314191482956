import { UserIcon } from "Assets";
import {
  AttachmentItem,
  EventDescription,
  EventOwner,
  FormElementType,
} from "modules/portfolio/types";
import { Text, Title } from "UIKit";
import { format } from "date-fns";
import PortfolioReadOnlyElement from "Pages/MeTab/pages/Portfolio/components/PortfolioReadOnlyElement/PortfolioReadOnlyElement";
import Panel from "UIKit/Panel/Panel";

interface EventDescriptionSectionProps {
  eventOwner: EventOwner;
  eventDescription: EventDescription[];
  onDownloadAttachment?: (attachment: AttachmentItem) => void;
  onClickAttachment?: (attachment: AttachmentItem) => void;
  areAttachmentsShared?: boolean;
}

const EventDescriptionSection = ({
  eventOwner,
  eventDescription,
  onDownloadAttachment,
  onClickAttachment,
  areAttachmentsShared = false,
}: EventDescriptionSectionProps) => {
  const [date, ...rest] = eventDescription;
  const { value } = date;

  const extraProps = {
    showAttachmentsMessage: false,
    onClickAttachment,
    onDownloadAttachment,
  };

  return (
    <div className="mb-6">
      <Panel>
        <div className="flex flex-1 justify-between">
          <div className="flex items-center">
            <UserIcon className="h-10 w-10 mr-4" />
            <Title level={6}>{eventOwner.name}</Title>
          </div>

          <Text level={1} className="text-grey-700">
            {value ? format(new Date(value as string), "do MMMM yyyy") : ""}
          </Text>
        </div>
        <div className="mt-6">
          {rest
            .filter((ed) =>
              ed.type === FormElementType.ATTACHMENTS ? areAttachmentsShared && ed.value : true,
            )
            .map((ed: EventDescription) => {
              return (
                <div key={ed.fieldName} className="mb-4 last:mb-0">
                  <PortfolioReadOnlyElement element={ed} {...extraProps} />
                </div>
              );
            })}
        </div>
      </Panel>
    </div>
  );
};

export default EventDescriptionSection;
