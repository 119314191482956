import { useHighlightFile } from "Hooks";
import { useCallback } from "react";
import { TeamMember } from "../types";
import { fileStore } from "@eolas-medical/core";

const useHighLightTeamMember = () => {
  const { toggleHighlight, highlightLoading } = useHighlightFile();

  const handleToggleHighlight = useCallback(
    (teamMember: TeamMember) => {
      const originalFile = fileStore.getFile(teamMember.id);

      toggleHighlight(originalFile);
    },
    [toggleHighlight],
  );

  return {
    toggleHighlight: handleToggleHighlight,
    highlightLoading,
  };
};

export default useHighLightTeamMember;
