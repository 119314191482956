import { FavouriteButton, FavouriteButtonFilled } from "UIKit";

interface FavouriteToggleProps {
  isFavourite: boolean;
  isLoading?: boolean;
  className?: string;
  onClick: (isFavourite: boolean) => void;
  "data-testid"?: string;
}

export const FavouriteToggle = ({
  isFavourite,
  isLoading,
  onClick,
  className = "",
  "data-testid": dataTestId,
}: FavouriteToggleProps) => {
  const Favorite = isFavourite ? FavouriteButtonFilled : FavouriteButton;

  return (
    <Favorite
      data-testid={dataTestId}
      className={`static ${className}`}
      size="xs"
      onClick={() => onClick(!isFavourite)}
      isLoading={isLoading}
    />
  );
};
