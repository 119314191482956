import { NotificationBellIcon } from "Assets";
import { Text } from "UIKit";

interface NotificationBellProps {
  notificationCount: number;
  onClick: () => void;
}

const NotificationBell = ({ notificationCount, onClick }: NotificationBellProps) => {
  return (
    <button
      className={`
      flex items-center justify-center h-8 w-8 lg:h-12 lg:w-12 rounded-full border bg-white hover:bg-grey-50
      text-blue-500
    `}
      data-testid="notification-bell"
      onClick={onClick}
    >
      <div data-testid="notification-icon">
        <NotificationBellIcon className="w-4 h-4 lg:w-6 lg:h-6" />
      </div>
      {notificationCount > 0 && (
        <div
          data-testid="notification-count"
          className="absolute top-0 left-6 lg:left-8 flex items-center justify-center border border-red-600 w-4 h-4 bg-red-600 rounded-full"
        >
          <Text level={3} className="text-white">
            {notificationCount > 9 ? "9+" : notificationCount}
          </Text>
        </div>
      )}
    </button>
  );
};

export default NotificationBell;
