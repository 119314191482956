import { useMemo } from "react";
import { ImageIcon, OfficeIcon, PDFIcon, VideoIcon, WeblinkIcon } from "Assets";
import { EolasFileType } from "Utilities/types";

interface FileTypeIconProps {
  type?: EolasFileType;
  className?: string;
}

export const FileTypeItemIcon = ({ type, className }: FileTypeIconProps) => {
  const Icon = useMemo(() => {
    const lowercaseType = type?.toLocaleLowerCase();
    switch (lowercaseType) {
      case "mp4":
      case "mov":
        return VideoIcon;
      case "pdf":
        return PDFIcon;
      case "jpeg":
      case "jpg":
      case "png":
        return ImageIcon;
      case "ppt":
      case "pptx":
      case "doc":
      case "docx":
      case "xls":
      case "xlsx":
        return OfficeIcon;
      default:
        return WeblinkIcon;
    }
  }, [type]);

  return <Icon className={className} />;
};
