import { warning } from "Assets";

import { Modal } from "../";
import { Button } from "../../Button";
import { Title } from "../../Typography";
import { LottieWithHeader } from "../../LottieWithHeader";
import { InnerModalWrapper, ModalHeader, ModalBody } from "../components";

interface WarningModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  onConfirm: () => void;
  text?: string;
  title?: string;
  confrimTitle?: string;
}

export const WarningModal = ({
  isOpen,
  text = "",
  title = "Warning",
  confrimTitle = "Confirm",
  handleClose,
  onConfirm,
}: WarningModalProps) => {
  const handleConfirm = () => {
    onConfirm();
    if (handleClose) handleClose();
  };

  const onClose = () => handleClose ?? handleConfirm;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <InnerModalWrapper>
        <ModalHeader>
          <Title level={5}>{text}</Title>
        </ModalHeader>

        <ModalBody className="space-y-4 sm:space-y-8">
          <LottieWithHeader text={title} animationSize="15%" animation={warning} />

          <div className={`grid grid-cols-1 gap-4 ${handleClose ? "sm:grid-cols-2" : ""}`}>
            {handleClose && (
              <Button color="grey" onClick={handleClose}>
                Cancel
              </Button>
            )}

            <Button color="blue" onClick={handleConfirm}>
              {confrimTitle}
            </Button>
          </div>
        </ModalBody>
      </InnerModalWrapper>
    </Modal>
  );
};
