import { useFileDownload } from "shared/hooks/useFileDownload";
import { useNotifications } from "Components/Notifications";
import { DOWNLOADABLE_TYPES } from "Utilities/helpers";
import { ContentItem } from "../types";
import { useDocumentCache } from "services/documentCache";

const useDownloadContentFile = (contentItem: ContentItem) => {
  const { showNotification, hideNotification } = useNotifications();
  const { getDocumentUrl } = useDocumentCache();

  const getFile = async () => {
    const extension = contentItem.fileUrl?.split(".")?.pop();
    const s3FileUrl = await getDocumentUrl(contentItem.fileUrl);

    return {
      fileName: contentItem.name,
      fileUrl: s3FileUrl,
      fileExt: extension,
    };
  };

  const { downloadFile } = useFileDownload({ getFileAsync: getFile });

  const downloadContentFile = async () => {
    if (!contentItem.fileUrl) return;

    const isDownloadable = DOWNLOADABLE_TYPES.has(contentItem.type);

    if (!isDownloadable) {
      return;
    }

    showNotification({ type: "loading", title: "Getting file", id: "download-loading" });

    downloadFile(contentItem.fileUrl, {
      onSuccess: () => hideNotification("download-loading"),
    });
  };

  return {
    downloadContentFile,
  };
};

export default useDownloadContentFile;
