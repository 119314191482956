import { useState } from "react";

export const useRegistrationStage = <T>(initialStage: T) => {
  const [currentStage, _setStage] = useState<T>(initialStage);

  const setStage = (stage: T) => () => {
    _setStage(stage);
  };

  return {
    setStage,
    currentStage,
  };
};
