import { makeApolloClient } from "API/functions/makeApolloClient";
import { queryApollo } from "API/functions/queryApollo";
import envConfig from "env-config";
import { ListAppUsersByAppDocument } from "./departments.graphql";

const apiUrl = `https://${envConfig.REACT_APP_API_BASE_URL}/proxy/graphql`;

export const departmentClient = makeApolloClient(apiUrl);

export const getDepartmentUsers = async ({ departmentId }: { departmentId: string }) => {
  const departmentUsers = await queryApollo(departmentClient, {
    query: ListAppUsersByAppDocument,
    variables: { appID: departmentId },
  });

  return departmentUsers;
};
