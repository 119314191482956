import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { EolasFile, AppLevelSection } from "@eolas-medical/core";

import { EolasFileList, DeleteFileModal } from "Components";

import { useCompleteFile, useMainSectionVisit } from "Hooks";
import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import {
  AddNewsItemModal,
  NewsItemStatsModal,
  NewsItemViewModal,
} from "Pages/Spaces/components/NewsItems";

export const NewsFeed: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  const { t } = useTranslation();
  const { onCompleteFile } = useCompleteFile();

  useMainSectionVisit({ mainSectionId: AppLevelSection.newsFeed });

  const { isAdmin } = useSpacesContext();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { spaceSection } = useSpaceSection(AppLevelSection.newsFeed);

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const onAddModal = useCallback(() => {
    setModal(
      <AddNewsItemModal onCloseModal={onCloseModal} mainSectionID={AppLevelSection.newsFeed} />,
    );
  }, [setModal, onCloseModal]);

  const onDeleteNewsItem = useCallback(
    (newsItem) => {
      setModal(<DeleteFileModal file={newsItem} onCloseModal={onCloseModal} />);
    },
    [setModal, onCloseModal],
  );

  const onShowStats = useCallback(
    (newsItem) => {
      setModal(
        <NewsItemStatsModal
          newsItem={newsItem}
          onCloseModal={onCloseModal}
          sectionType={AppLevelSection.newsFeed}
        />,
      );
    },
    [setModal, onCloseModal],
  );

  const onSelectFile = useCallback(
    (newsItem: EolasFile) => {
      if (!newsItem.type) {
        onCompleteFile(newsItem.id, "seen");
      }
      setModal(<NewsItemViewModal newsItem={newsItem} />);
    },
    [onCompleteFile],
  );
  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t("newsFeed_title")} />
        <NavButton to={url.replace(`/${AppLevelSection.newsFeed}`, "")} />

        {isAdmin && (
          <AddButton onClick={onAddModal} data-testid="add-new-file">
            {t(`${AppLevelSection.newsFeed}_add_new_file`)}
          </AddButton>
        )}

        <EolasFileList
          defaultSort="date"
          draggable={false}
          isAdmin={isAdmin}
          onEdit={onShowStats}
          onAddItem={onAddModal}
          onDelete={onDeleteNewsItem}
          onSelectFile={onSelectFile}
          mainSectionId={AppLevelSection.newsFeed}
        />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});
