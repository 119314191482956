import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createEvent, updateEvent } from "../client/portfolio.client";
import { mapEventForm } from "../helpers";
import portfolioKeys from "./portfolio.queryKeys";
import { EventDto } from "../types";

interface SaveEventMutation {
  eventId?: string;
  eventBody: EventDto;
}

const useSaveEvent = () => {
  const queryClient = useQueryClient();

  const saveEventMutation = async ({ eventId, eventBody }: SaveEventMutation) => {
    if (eventId) {
      return updateEvent({ eventId, eventBody });
    }

    if (!eventId && eventBody) {
      return createEvent(eventBody);
    }
  };

  const { mutate, isLoading, ...rest } = useMutation(saveEventMutation, {
    onSuccess: (newEvent) => {
      if (newEvent) {
        queryClient.invalidateQueries({
          queryKey: portfolioKeys.lists(),
        });
        queryClient.setQueryData(portfolioKeys.detail(newEvent.id), () => mapEventForm(newEvent));
      }
    },
  });

  return {
    saveEvent: mutate,
    savingEvent: isLoading,
    ...rest,
  };
};

export default useSaveEvent;
