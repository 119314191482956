import {
  Button,
  ConfirmationModal,
  InnerModalWrapper,
  Loader,
  ModalBody,
  Text,
  Title,
} from "UIKit";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import { useCallback, useEffect, useState } from "react";
import useExportToImage from "Hooks/useExportToImage";
import { trackEvent } from "API/Analytics";
import { AccessLinkToken } from "Pages/Authentication/pages/Invitations/pages/AccessLink/AccessLink";

interface AccessQRCodeModalProps {
  onDone: () => void;
  accessToken: AccessLinkToken;
  onCopyLink: () => void;
  onExportToImage: () => void;
  titleText: string;
  descriptionText: string;
  onGenerateAccessToken: () => void;
  isLoading?: boolean;
  accessRoute: string;
  exportedQRCodeName?: string;
  showRegenerate?: boolean;
}

const AccessQRCodeModal = ({
  onDone,
  accessToken,
  onCopyLink,
  onExportToImage,
  titleText,
  descriptionText,
  onGenerateAccessToken,
  isLoading = false,
  accessRoute,
  exportedQRCodeName = "invite-qr",
  showRegenerate = false,
}: AccessQRCodeModalProps) => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);
  const [showConfirmGenerate, setShowConfirmGenerate] = useState(false);

  const { parentRef, exportToImage } = useExportToImage();

  const { userID: userId = "" } = userStore;

  const handleGenerateToken = useCallback(async () => {
    onGenerateAccessToken && onGenerateAccessToken();
    navigator.clipboard.writeText("");
    setIsClicked(false);
    setShowConfirmGenerate(false);
    trackEvent(AnalyticsEvents.ACCESS_QR_REGENERATED, { userId });
  }, [onGenerateAccessToken, userId]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(accessRoute);
    setIsClicked(true);
    onCopyLink();
  }, [onCopyLink, accessRoute]);

  const handleExportToImage = useCallback(() => {
    exportToImage(exportedQRCodeName);
    onExportToImage();
  }, [exportToImage, exportedQRCodeName, onExportToImage]);

  const copyLinkButtonText = isClicked ? t("copy_to_clipboard_tooltip") : t("copy_to_clipboard");

  useEffect(() => {
    if (!accessToken && !isLoading) onGenerateAccessToken();
    trackEvent(AnalyticsEvents.ACCESS_QR_VIEWED, { userId });
  }, [accessToken, onGenerateAccessToken, isLoading, userId]);

  return (
    <InnerModalWrapper>
      {showConfirmGenerate ? (
        <ConfirmationModal
          onDecline={() => setShowConfirmGenerate(false)}
          onAccept={handleGenerateToken}
          iconType="WarningIcon"
          title={t("manageUsers_invite_users_confirmation_title")}
          description={t("manageUsers_invite_users_confirmation_subtitle")}
          acceptLabel={t("manageUsers_invite_users_confirmation_confirm_button")}
          declineLabel={t("manageUsers_invite_users_confirmation_cancel_button")}
          declineButtonProps={{ disabled: isLoading }}
          acceptButtonProps={{ color: "red", isLoading: isLoading }}
        />
      ) : (
        <ModalBody>
          <Title level={5} className="text-center">
            {titleText}
          </Title>
          <Text level={1} className="text-center">
            {descriptionText}
          </Text>

          <div className="my-8 self-center">
            <div ref={parentRef}>
              {isLoading ? (
                <Loader className="bg-transparent h-20vh" />
              ) : (
                <QRCode data-testid="event-QR-code" size={220} value={accessRoute} />
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
            <Button
              data-testid="copy-clipboard-button"
              size="sm"
              iconLeft={isClicked ? undefined : "CopyIcon"}
              color="grey"
              variant="outline"
              className="order-1 sm:order-0"
              onClick={handleCopyLink}
            >
              <Text className="ml-2 text-sm sm:text-base" level={1}>
                {copyLinkButtonText}
              </Text>
            </Button>

            <Button
              data-testid="export-png-button"
              size="sm"
              iconLeft="ExportIcon"
              color="grey"
              variant="outline"
              className="order-0 sm:order-1"
              onClick={handleExportToImage}
            >
              <Text className="ml-2 text-sm sm:text-base" level={1}>
                {t("manageUsers_invite_by_link_export_as_png")}
              </Text>
            </Button>
          </div>
          <div className="flex flex-col items-center mt-4">
            {showRegenerate && (
              <Button
                data-testid="generate-token-button"
                color="red"
                variant="outline"
                size="sm"
                className="w-full rounded-xl mb-4"
                onClick={() => setShowConfirmGenerate(true)}
              >
                {t("manageUsers_invite_by_link_generate_button")}
              </Button>
            )}

            <Button
              onClick={onDone}
              data-testid="done-button"
              size="sm"
              className="w-full rounded-xl"
            >
              {t("manageUsers_invite_by_link_done_button")}
            </Button>
          </div>
        </ModalBody>
      )}
    </InnerModalWrapper>
  );
};

export default AccessQRCodeModal;
