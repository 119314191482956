import { useCallback, useEffect, useState } from "react";
import { SupervisorRequestCreatedBy } from "modules/portfolio/types";
import SendReminderButton from "Pages/MeTab/pages/Portfolio/components/SendReminderButton/SendReminderButton";
import useSendReminderSupervision from "modules/portfolio/data/useSendReminderSupervision";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";
import { useNotifications } from "Components/Notifications";
import { createErrorNotification } from "Pages/MeTab/pages/Portfolio/helpers";
import { PortfolioErrorMessages } from "Pages/MeTab/pages/Portfolio/errors";
import { appConfig } from "AppTypeConfig";

interface SendReminderProps {
  ownerId: string;
  supervisorEmail: string;
  canRemind: boolean;
  createdBy: SupervisorRequestCreatedBy;
}
const SendReminder = ({ ownerId, supervisorEmail, canRemind, createdBy }: SendReminderProps) => {
  const [canSendReminder, setCanSendReminder] = useState(false);
  const { sendReminderSupervision, sendingReminderSupervision } = useSendReminderSupervision(
    createdBy,
  );
  const { showNotification } = useNotifications();

  const isCreatedBySupervisee = createdBy === SupervisorRequestCreatedBy.SUPERVISEE;

  useEffect(() => {
    setCanSendReminder(canRemind);
  }, [canRemind]);

  const handleSendReminder = useCallback(() => {
    sendReminderSupervision(
      {
        appType: appConfig.appType,
        supervisorEmail,
        createdBy,
        ownerId,
      },
      {
        onSuccess: () => {
          if (isCreatedBySupervisee) {
            trackEvent(AnalyticsEvents.SUPERVISEE_SENT_REMINDER_TO_SUPERVISOR);
          }
        },
        onError: () => {
          showNotification(createErrorNotification(PortfolioErrorMessages.SENDING_REMINDER_ERROR));
        },
      },
    );
  }, [
    sendReminderSupervision,
    showNotification,
    isCreatedBySupervisee,
    supervisorEmail,
    ownerId,
    createdBy,
  ]);

  return (
    <SendReminderButton
      onClick={handleSendReminder}
      isLoading={sendingReminderSupervision}
      isReminderSent={!canSendReminder}
    />
  );
};

export default SendReminder;
