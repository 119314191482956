import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ScrollToTop = () => {
  const { listen } = useHistory();

  useEffect(() => {
    const stopListening = listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      stopListening();
    };
  }, [listen]);

  return null;
};

export default ScrollToTop;
