import { FlashcardCreatorStep } from "./FlashcardCreatorStep";
import { Editor as TinyMCEEditor } from "tinymce";
import { useCallback, useRef, useState } from "react";
import { Step } from "UIKit/Wizard";
import { ContentRepositoryId } from "modules/contentRepository/types";

interface UseFlashcardCreatorStepProps {
  pageTitle: string;
  flashcardTitle?: string;
  editorContent?: string;
  flashcardDescription?: string;
  onSubmit: (flashcardContent: string) => void;
  contentRepositoryId: ContentRepositoryId;
}

export const useFlashcardCreatorStep = <T extends string>({
  pageTitle,
  flashcardDescription,
  flashcardTitle,
  editorContent,
  onSubmit,
  contentRepositoryId,
}: UseFlashcardCreatorStepProps): Step<T> => {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmitFlashcard = () => {
    const editor = editorRef.current;
    const editorContent = editor?.getContent();
    onSubmit(editorContent!);
  };

  const onEditorInit = useCallback((evt: any, editor: TinyMCEEditor) => {
    setIsLoading(false);
    editorRef.current = editor;
  }, []);

  return {
    name: "flashcard-creator" as T,
    title: pageTitle,
    canProceed: true,
    onSubmit: handleSubmitFlashcard,
    component: (
      <FlashcardCreatorStep
        contentRepositoryId={contentRepositoryId}
        flashcardTitle={flashcardTitle}
        editorContent={editorContent}
        flashcardDescription={flashcardDescription}
        isLoading={isLoading}
        onEditorInit={onEditorInit}
      />
    ),
  };
};
