import { MainSection } from "modules/mainSections/types";
import useOrganisationMainSections from "modules/spaces/data/useOrganisationMainSections";
import useSpaceMainSections from "modules/spaces/data/useSpaceMainSections";

interface UseContentManagementSections {
  activeMainSections: MainSection[];
  deactivatedMainSections: MainSection[];
  hasDeactivatedSections: boolean;
  inactiveMainSections: MainSection[];
}

interface UseContentManagementSectionsProps {
  isSpaceContent: boolean;
}

const useContentManagementSections = ({
  isSpaceContent,
}: UseContentManagementSectionsProps): UseContentManagementSections => {
  const spaceSections = useSpaceMainSections(true);
  const orgSections = useOrganisationMainSections();

  return isSpaceContent ? spaceSections : orgSections;
};

export default useContentManagementSections;
