import { useFormContext } from "react-hook-form";
import React, { useCallback, useEffect, useState, useRef } from "react";

import { ChecklistSubmissionForm } from "../types";

type SectionModalState = "confirmSkip" | "confirmClear" | null;

const DEBOUNCE_TIME = 1500;

export const useSection = (sectionIndex: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [modalState, setModalState] = useState<SectionModalState>(null);
  const { setValue, watch } = useFormContext<ChecklistSubmissionForm>();

  const onCloseModal = useCallback(() => setModalState(null), [setModalState]);

  const onConfirm = useCallback(
    (state: SectionModalState) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setModalState(state);
    },
    [setModalState],
  );

  const isExpanded = watch(`sections.${sectionIndex}.expanded`);
  const isSkipped = watch(`sections.${sectionIndex}.skipped`);
  const isIncomplete = watch(`sections.${sectionIndex}.isIncomplete`);
  const sectionValues: Array<string | boolean> = watch(`sections.${sectionIndex}.values`);
  const stringifiedValues = JSON.stringify(sectionValues);

  const isComplete = sectionValues.every((v) => {
    if (typeof v === "string") return !!v.trim();
    return v;
  });

  const markAsComplete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (isComplete) return;

    sectionValues.forEach((value, index) => {
      // don't autocomplete the already completed fields
      if (value) return;

      const valueKey = `sections.${sectionIndex}.values.${index}` as const;
      setValue(valueKey, typeof value === "string" ? "Completed" : true);
    });
    setValue(`sections.${sectionIndex}.expanded`, false, { shouldDirty: true });
  };

  const onClearSection = () => {
    sectionValues.forEach((value, index) => {
      const valueKey = `sections.${sectionIndex}.values.${index}` as const;
      setValue(valueKey, typeof value === "string" ? "" : false);
    });
    setValue(`sections.${sectionIndex}.expanded`, true);
    onCloseModal();
  };

  const toggleExpanded = () => {
    setValue(`sections.${sectionIndex}.expanded`, !isExpanded);
  };

  const toggleSkipped = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setValue(`sections.${sectionIndex}.skipped`, !isSkipped, { shouldDirty: true });
    setModalState(null);
  };

  useEffect(() => {
    if (isComplete) {
      setValue(`sections.${sectionIndex}.isIncomplete`, false);
    }
  }, [isComplete, sectionIndex, setValue]);

  useEffect(() => {
    if (isComplete) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setValue(`sections.${sectionIndex}.expanded`, false);
      }, DEBOUNCE_TIME);
    }
  }, [isComplete, stringifiedValues, sectionIndex, setValue]);

  return {
    isSkipped,
    isExpanded,
    modalState,
    isComplete,
    isIncomplete,
    onConfirm,
    onCloseModal,
    toggleSkipped,
    markAsComplete,
    toggleExpanded,
    onClearSection,
  };
};
