import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { DebounceInput } from "react-debounce-input";
import { SearchMonoIcon } from "Assets";

export interface SearchBoxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onChangeText: (text: string) => void;
  value?: string;
  placeholder?: string;
  icon?: React.ReactNode;
  showIcon?: boolean;
  autoFocus?: boolean;
  className?: string;
  "data-testid"?: string;
  rightSection?: React.ReactNode;
  debounceTimeout?: number;
}

export const SearchBox = forwardRef<HTMLInputElement, SearchBoxProps>(
  (
    {
      onChangeText,
      placeholder = "SearchBarPlaceholder",
      icon,
      showIcon = true,
      value,
      "data-testid": dataTestId = "searchbox-input",
      autoFocus,
      className,
      ref: legacyRef,
      rightSection,
      debounceTimeout = 300,
      ...otherProps
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const iconComponent = (
      <div className="mr-4">
        {icon ? icon : <SearchMonoIcon className="w-5 h-5 text-grey-600 self-center my-auto" />}
      </div>
    );

    return (
      <div
        className={`w-full border bg-white border-grey-300 h-12 rounded-lg items-center flex flex-row px-4 ${className}`}
      >
        {showIcon && iconComponent}
        <DebounceInput
          inputRef={ref}
          autoFocus={autoFocus}
          minLength={1}
          debounceTimeout={debounceTimeout}
          value={value}
          data-testid={dataTestId}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeText(e.target.value);
          }}
          className="flex-1 bg-transparent self-center my-auto"
          placeholder={t(placeholder)}
          {...otherProps}
        />

        {rightSection}
      </div>
    );
  },
);
