import { useFormContext } from "react-hook-form";

import PasswordValidationRules from "./PasswordValidationRules";
import PasswordInput from "./PasswordInput";
interface PasswordInputValidatorProps {
  id?: string;
  label?: string;
  placeholder?: string;
  onValidation?: (valid: boolean) => void;
}

export const PasswordInputValidator: React.FC<PasswordInputValidatorProps> = ({
  label,
  placeholder,
  id = "password",
  onValidation,
}) => {
  const { watch } = useFormContext();
  const password = watch(id);

  return (
    <>
      <PasswordInput
        id={id}
        label={label}
        placeholder={placeholder}
        testID="create-password-input"
      />
      <PasswordValidationRules password={password} onChange={onValidation} />
    </>
  );
};

export default PasswordInputValidator;
