import { useCallback, useEffect, useRef } from "react";
import { ImageUploaderState } from "../ImageUploader";
import { getMimeTypeFromImg } from "../helpers";

function useImageCropping(
  pickerState: ImageUploaderState,
  onChange: (file: File | null, filePreview?: string) => void,
  fileName?: string,
) {
  const imageRef = useRef<any>(null);

  const onZoomIn = useCallback(() => {
    if (imageRef !== null && imageRef.current !== null) {
      const cropper: Cropper = imageRef.current.cropper;
      cropper.zoom(0.1);
    }
  }, []);

  const onZoomOut = useCallback(() => {
    if (imageRef !== null && imageRef.current !== null) {
      const cropper: Cropper = imageRef.current.cropper;
      cropper.zoom(-0.1);
    }
  }, []);

  const onCropEnd = useCallback(() => {
    if (imageRef !== null && imageRef.current !== null) {
      const canvas = imageRef.current.cropper.getCroppedCanvas();

      const mimeType = getMimeTypeFromImg(imageRef.current);

      const imagePreview = canvas.toDataURL(mimeType);

      canvas.toBlob(async (blob: any) => {
        const imageName = fileName ?? pickerState.file?.name.split(".")[0];
        const croppedFile = new File([blob], imageName || "blob", {
          type: blob.type,
        });
        onChange(croppedFile, imagePreview);
      }, mimeType);
    }
  }, [onChange, fileName, pickerState.file]);

  useEffect(() => {
    // we need to run this effect to sync the file name with the Eolas file name
    if (!fileName) return;

    onCropEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  return {
    imageRef,
    onZoomIn,
    onZoomOut,
    onCropEnd,
  };
}

export default useImageCropping;
