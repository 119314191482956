import { observer } from "mobx-react-lite";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { OrganisationLevelSection } from "@eolas-medical/core";

import { useSelectFile } from "Hooks";
import { DeleteFileModal, EolasFileList } from "Components";
import { EolasFile } from "Utilities/types";
import { AddButton, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { AddItemWithFileModal } from "Pages/Spaces/components/AddEolasFile";

export const DrugMonitoring: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  const { t } = useTranslation();
  const { onSelectFile } = useSelectFile();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { isAdmin } = useSpacesContext();
  const { spaceSection } = useSpaceSection(OrganisationLevelSection.drugMonitoring);

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const onAddFile = useCallback(() => {
    setModal(
      <AddItemWithFileModal
        allowedFileTypes={["pdf"]}
        onCloseModal={onCloseModal}
        mainSectionID={OrganisationLevelSection.drugMonitoring}
      />,
    );
  }, [onCloseModal]);

  const onEditFile = useCallback(
    (eolasFile: EolasFile) => {
      setModal(
        <AddItemWithFileModal
          file={eolasFile}
          allowedFileTypes={["pdf"]}
          onCloseModal={onCloseModal}
          mainSectionID={OrganisationLevelSection.drugMonitoring}
        />,
      );
    },
    [onCloseModal],
  );

  const onDeleteFile = useCallback(
    (file: EolasFile) => {
      setModal(<DeleteFileModal file={file} onCloseModal={onCloseModal} />);
    },
    [onCloseModal],
  );

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t(OrganisationLevelSection.drugMonitoring)} />
        <NavButton to={url.replace(`/${OrganisationLevelSection.drugMonitoring}`, "")} />

        {isAdmin && <AddButton onClick={onAddFile}>{t("fileWithSearch_addNew_file")}</AddButton>}

        <EolasFileList
          isAdmin={isAdmin}
          onEdit={onEditFile}
          onAddItem={onAddFile}
          onDelete={onDeleteFile}
          onSelectFile={onSelectFile}
          mainSectionId={OrganisationLevelSection.drugMonitoring}
        />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});
