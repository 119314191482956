import { sectionStore } from "@eolas-medical/core";

enum CustomLogos {
  cci = "cci",
}
interface UseCustomTheme {
  hasCustomBranding: boolean;
  customBrandColor?: string;
  customLogo?: CustomLogos;
  customName?: string;
}
export const useCustomTheme = (): UseCustomTheme => {
  const { data } = sectionStore;
  let customBrandColor = undefined;
  let customLogo: CustomLogos | undefined = undefined;
  let customName: string | undefined = undefined;

  const brandColor = data.organisation?.metadata?.customBrandColour;
  const brandLogo = data.organisation?.metadata?.customLogo;
  const brandName = data.organisation?.name;

  if (brandColor && typeof brandColor === "string") {
    customBrandColor = brandColor;
  }

  if (
    brandLogo &&
    typeof brandLogo === "string" &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (<any>Object).values(CustomLogos).includes(brandLogo)
  ) {
    customLogo = brandLogo as CustomLogos;
  }

  if (brandName) {
    customName = brandName;
  }

  return {
    hasCustomBranding: !!customLogo || !!customBrandColor,
    customBrandColor,
    customLogo,
    customName,
  };
};
