// @ts-ignore
import * as Types from "../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type EnableMedusaMutationVariables = Types.Exact<{
  apiKey: Types.Scalars["String"];
  organisationId: Types.Scalars["String"];
  sectionId: Types.Scalars["String"];
}>;

export type EnableMedusaMutation = {
  __typename?: "Mutation";
  enableMedusa?:
    | { __typename?: "EnableMedusaOutput"; status?: Types.MedusaStatus | null | undefined }
    | null
    | undefined;
};

export const EnableMedusaDocument = gql`
  mutation EnableMedusa($apiKey: String!, $organisationId: String!, $sectionId: String!) {
    enableMedusa(apiKey: $apiKey, organisationId: $organisationId, sectionId: $sectionId) {
      status
    }
  }
`;
export type EnableMedusaMutationFn = Apollo.MutationFunction<
  EnableMedusaMutation,
  EnableMedusaMutationVariables
>;

/**
 * __useEnableMedusaMutation__
 *
 * To run a mutation, you first call `useEnableMedusaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableMedusaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableMedusaMutation, { data, loading, error }] = useEnableMedusaMutation({
 *   variables: {
 *      apiKey: // value for 'apiKey'
 *      organisationId: // value for 'organisationId'
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useEnableMedusaMutation(
  baseOptions?: Apollo.MutationHookOptions<EnableMedusaMutation, EnableMedusaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnableMedusaMutation, EnableMedusaMutationVariables>(
    EnableMedusaDocument,
    options,
  );
}
export type EnableMedusaMutationHookResult = ReturnType<typeof useEnableMedusaMutation>;
export type EnableMedusaMutationResult = Apollo.MutationResult<EnableMedusaMutation>;
export type EnableMedusaMutationOptions = Apollo.BaseMutationOptions<
  EnableMedusaMutation,
  EnableMedusaMutationVariables
>;
