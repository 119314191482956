import * as yup from "yup";

import { MajorIncidentSubSection, EolasFileType } from "Utilities/types";

import { IAddEolasFileForm, ValidationSchemaCreator } from "./types";

export const itemWithFileSchema: ValidationSchemaCreator<
  Pick<IAddEolasFileForm, "name" | "key">
> = () =>
  yup.object().shape({
    name: yup.string().required("fieldError_name_required"),
    key: yup.string().required("fieldError_key_required"),
  });

export const itemMeetTheTeam: ValidationSchemaCreator<
  Pick<IAddEolasFileForm, "name" | "description">
> = () =>
  yup.object().shape({
    name: yup.string().required("fieldError_name_required"),
    description: yup.string().required("fieldError_staff_role_required"),
  });

export const guidelinesSchema: ValidationSchemaCreator<any> = () => [
  yup.object().shape({
    key: yup.string().required("fieldError_key_required"),
    type: yup
      .string()
      .oneOf<EolasFileType>(["pdf", "link", "ms-office"])
      .required("fieldError_file_type_required"),
  }),
  yup.object().shape({
    name: yup.string().required("fieldError_name_required"),
    description: yup.string().required("fieldError_guidelines_keywords_required"),
    hasExpiryDate: yup.boolean().nullable().notRequired(),
    expiryDate: yup.mixed().when("hasExpiryDate", {
      is: (value: any) => !!value,
      then: yup.mixed().test({
        test: (v) => !!v,
        message: "fieldError_expiry_date_required",
      }),
      otherwise: yup.mixed().nullable().notRequired(),
    }),
  }),
  yup.object().shape({
    info: yup.string().required("fieldError_organisation_required"),
  }),
];

export const guidelineDocumentsSchema: ValidationSchemaCreator<any> = () => [
  yup.object().shape({
    key: yup.string().required("fieldError_key_required"),
    type: yup
      .string()
      .oneOf<EolasFileType>(["pdf", "link", "ms-office"])
      .required("fieldError_file_type_required"),
  }),
  yup.object().shape({
    name: yup.string().required("fieldError_name_required"),
    description: yup.string().required("fieldError_document_keywords_required"),
    hasExpiryDate: yup.boolean().nullable().notRequired(),
    expiryDate: yup.mixed().when("hasExpiryDate", {
      is: (value: any) => !!value,
      then: yup.mixed().test({
        test: (v) => !!v,
        message: "fieldError_expiry_date_required",
      }),
      otherwise: yup.mixed().nullable().notRequired(),
    }),
  }),
  yup.object().shape({
    info: yup.string().required("fieldError_organisation_required"),
  }),
];

export const itemWithExpirationInMonths: ValidationSchemaCreator<
  Pick<IAddEolasFileForm, "name" | "description" | "key" | "hasExpiryDate" | "moreInfo">
> = () =>
  yup.object().shape({
    name: yup.string().required("fieldError_name_required"),
    description: yup.string().required("fieldError_file_content_required"),
    key: yup.string().required("fieldError_key_required"),
    type: yup
      .string()
      .oneOf<EolasFileType>(["pdf", "mp4", "link", "ms-office"])
      .required(),
    hasExpiryDate: yup.boolean().notRequired(),
    moreInfo: yup.mixed().when("hasExpiryDate", {
      is: (value: any) => !!value,
      then: yup.string().required("fieldError_duration_in_month_required"),
      otherwise: yup.mixed().nullable().notRequired(),
    }),
  });

type HospitalSpecificFields = {
  info: string[];
  moreInfo: {
    coordinates: string;
    lighting: string;
    transfer: string;
  };
  moreInfoTwo: string[];
};

export const hospitalSchema: ValidationSchemaCreator<
  Pick<IAddEolasFileForm, "name" | "description"> & HospitalSpecificFields
> = () =>
  yup.object().shape({
    name: yup.string().required("fieldError_name_required"),
    description: yup.string().required("fieldError_hospital_address_required"),
    info: yup.array().min(1).required("fieldError_hospital_contacts_required"),
    moreInfo: yup.object().shape({
      coordinates: yup.string().required("fieldError_hospital_landing_coords_required"),
      lighting: yup.string().required("fieldError_hospital_lighting_required"),
      transfer: yup.string().required("fieldError_hospital_transfer_required"),
    }),
    moreInfoTwo: yup.array().min(1).required("fieldError_hospital_facilities_required"),
  });

type IncidentReportingFields = {
  info: {
    date: string;
    location: string;
    details: string;
    immediateActions: string;
    contributingFactors: string;
    critical?: boolean;
  };
};

export const contactSchema: ValidationSchemaCreator<
  Pick<IAddEolasFileForm, "name" | "description" | "hasExpiryDate" | "info">
> = () =>
  yup.object().shape({
    name: yup.string().required("fieldError_contacts_name_required"),
    description: yup.string().required("fieldError_contacts_details_required"),
    type: yup
      .string()
      .oneOf(["email", "telephone", "extension", "pager"])
      .required("fieldError_contacts_type_required"),
    hasExpiryDate: yup.boolean().notRequired(),
    info: yup.mixed().when("type", {
      is: (value: any) => value === "extension",
      then: yup.string().required("fieldError_contacts_extension_required"),
      otherwise: yup.mixed().nullable().notRequired(),
    }),
  });

export const incidentReportingSchema: ValidationSchemaCreator<
  Pick<IAddEolasFileForm, "name"> & IncidentReportingFields
> = () =>
  yup.object().shape({
    name: yup.string().required("fieldError_incidentReporting_name_required"),
    info: yup.object().shape({
      date: yup.string().nullable().required("fieldError_incidentReporting_date_required"),
      location: yup.string().required("fieldError_incidentReporting_location_required"),
      details: yup.string().required("fieldError_incidentReporting_details_required"),
      immediateActions: yup.string().required("fieldError_incidentReporting_actions_required"),
      contributingFactors: yup.string().required("fieldError_incidentReporting_factors_required"),
      critical: yup.boolean().notRequired(),
    }),
  });

export const departmentAreaSchema: ValidationSchemaCreator<
  Pick<IAddEolasFileForm, "name" | "description">
> = () =>
  yup.object().shape({
    name: yup.string().required("fieldError_department_name_required"),
    description: yup.string().required("fieldError_department_description_required"),
  });

export const newsItemSchema: ValidationSchemaCreator<
  Pick<IAddEolasFileForm, "name" | "description">
> = () =>
  yup.object().shape({
    name: yup.string().required("fieldError_name_required"),
    description: yup.string().required("fieldError_file_content_required"),
    type: yup.string().oneOf(["pdf", "mp4", "link", "ms-office", ""]),
    key: yup.mixed().when("type", {
      is: (value: any) => value !== "",
      then: yup.mixed().test({
        test: (v) => !!v,
        message: "fieldError_key_required",
      }),
      otherwise: yup.mixed().nullable().notRequired(),
    }),
  });

export const majorIncidentsSchema: ValidationSchemaCreator<any> = ({ sectionID }) => {
  if (sectionID === MajorIncidentSubSection.incidents) {
    return yup.object({
      name: yup.string().required("fieldError_majorIncident_name_required"),
    });
  }

  return yup.object().shape({
    name: yup.string().required("fieldError_majorIncident_document_name_required"),
    type: yup.string().oneOf(["pdf", "link", "ms-office"]),
    key: yup.string().required("fieldError_key_required"),
  });
};
