import { spacesClient } from "@eolas-medical/core";

interface ValidateSpaceProps {
  spaceName: string;
  spaceId?: string;
}

const useValidateSpaceName = () => {
  const validateSpace = ({ spaceName, spaceId }: ValidateSpaceProps) => {
    return spacesClient.validateSpace({ spaceName, spaceId });
  };
  return { validateSpace };
};
export default useValidateSpaceName;
