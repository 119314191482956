import { useMediaQuery } from "Hooks";

import { MobileDrawer, MobileNavigation, DesktopNavigation } from "./components";
import { useEolasNavigation } from "./hooks";
import DesktopHeader from "./components/DesktopHeader/DesktopHeader";

export const Navigation = () => {
  const { drawerExpanded, toggleDrawerExpanded } = useEolasNavigation();
  const media = useMediaQuery();
  const isDesktop = media === "lg" || media === "xl" || media === "2xl";

  return isDesktop ? (
    <>
      <DesktopHeader />
      <DesktopNavigation
        drawerExpanded={drawerExpanded}
        toggleDrawerExpanded={toggleDrawerExpanded}
      />
    </>
  ) : (
    <>
      <MobileNavigation />
      <MobileDrawer />
    </>
  );
};
