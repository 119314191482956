import React, { useState, useMemo } from "react";
import { InputTypeButton, Loader, Text } from "UIKit";
import { GalleryIcon, UploadImageIcon } from "Assets/Icons";
import ImageGallery from "UIKit/ImageGallery/ImageGallery";
import { ImageUploader } from "UIKit/ImageUploader/ImageUploader";
import { useTranslation } from "react-i18next";
import { ImageBankItem } from "Hooks/useImageBank";

type FileSource = "gallery" | "upload";

interface DefaultImage {
  url: string;
  isCustomImage: boolean;
}

interface ImagePickerWithGalleryProps {
  galleryImages: any[];
  errorDraggedImageText: any;
  onChangeBlob: (file: File | null) => void;
  onChangeImageUrl: (url: string) => void;
  isLoading: boolean;
  error?: string;
  defaultImage?: DefaultImage;
  cropperAspectRatio?: number;
}

const ImagePicker = ({
  galleryImages,
  errorDraggedImageText,
  onChangeBlob,
  onChangeImageUrl,
  defaultImage,
  isLoading,
  error,
  cropperAspectRatio = NaN,
}: ImagePickerWithGalleryProps) => {
  const { t } = useTranslation();
  const [fileSource, setFileSource] = useState<FileSource>(
    defaultImage?.isCustomImage ? "upload" : "gallery",
  );

  const [reorderGalleryImages, selectedImage] = useMemo(() => {
    if (!defaultImage) {
      return [galleryImages];
    }

    const selectedImageIndex = galleryImages.findIndex(
      (image: ImageBankItem) => image.imageUrl === defaultImage.url,
    );

    if (selectedImageIndex !== -1) {
      const updatedImages = [
        galleryImages[selectedImageIndex],
        ...galleryImages.slice(0, selectedImageIndex),
        ...galleryImages.slice(selectedImageIndex + 1),
      ];

      const selectedImage = galleryImages[selectedImageIndex];
      return [updatedImages, selectedImage];
    }

    return [galleryImages];
  }, [defaultImage, galleryImages]);

  const isGallerySelected = fileSource === "gallery";

  if (isLoading) {
    return <Loader className="bg-transparent" />;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-1 justify-between items-center space-x-4">
        <InputTypeButton
          isSelected={fileSource === "gallery"}
          icon={<GalleryIcon width={32} height={32} />}
          onClick={() => setFileSource("gallery")}
          className={`w-full ${fileSource === "gallery" ? "shadow-md" : ""}`}
        >
          <Text level={1}>{t("general_gallery")}</Text>
        </InputTypeButton>
        <InputTypeButton
          isSelected={fileSource === "upload"}
          icon={<UploadImageIcon width={32} height={32} />}
          onClick={() => setFileSource("upload")}
          className={`w-full ${fileSource === "upload" ? "shadow-md" : ""}`}
        >
          <Text level={1}>{t("general_upload_image")}</Text>
        </InputTypeButton>
      </div>
      {isGallerySelected ? (
        <ImageGallery
          images={reorderGalleryImages}
          onChange={onChangeImageUrl}
          defaultChecked={selectedImage?.smallImageUrl}
        />
      ) : (
        <div className="col-span-2">
          <ImageUploader
            onChange={onChangeBlob}
            fileToLargeError={errorDraggedImageText}
            error={error}
            defaultValue={defaultImage?.isCustomImage ? defaultImage?.url : undefined}
            croppingEnabled
            shouldCompress={false}
            cropperAspectRatio={cropperAspectRatio}
          />
        </div>
      )}
    </div>
  );
};

export default ImagePicker;
