export enum PortfolioConstants {
  PORTFOLIO_BASE_URL = "portfolio",
  MY_PORTFOLIO_URL = "my-portfolio",
  FEEDBACK_URL = "feedback",
  SUPERVISION_URL = "supervision",
  SUPERVISORS_URL = "supervisors",
  SUPERVISEES_URL = "supervisees",
  EVENT_ID_PARAM = "eventId",
  EVENT_TYPE_PARAM = "eventType",
  TRACK_QUERY_PARAM = "track",
  ATTACHMENT_NOTIFICATION_ID = "downloading-attachment",
  SUPERVISEE_ID_PARAM = "superviseeId",
  SUPERVISEE_NAME_PARAM = "superviseeName",
}

export const MAX_FEEDBACK_VALUE = 5;
