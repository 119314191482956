import { toTitleFormat } from "Utilities/helpers";
import { DepartmentIcon, GlobeIcon, UserShadowIcon } from "Assets";
import { WebNotificationConfig } from "../types";
import { WebNotification } from "modules/webNotifications/types";

const useWebNotificationsConfig = () => {
  const getWebNotificationProps = (webNotification: WebNotification): WebNotificationConfig => {
    switch (webNotification.notificationSection) {
      case "community":
        return {
          sectionName: "notification_center_community",
          subSectionName: webNotification.notificationSubSection
            ? toTitleFormat(webNotification.notificationSubSection)
            : "",
          color: "green",
          pillTextColor: "500",
          pillBgColor: "50",
          pillIcon: GlobeIcon,
        };
      case "department":
        return {
          sectionName: "notification_center_department",
          subSectionName: webNotification.notificationSubSection
            ? toTitleFormat(webNotification.notificationSubSection)
            : "",
          color: "blue",
          pillTextColor: "600",
          pillBgColor: "50",
          pillIcon: DepartmentIcon,
        };
      case "organisation":
        return {
          sectionName: "notification_center_organisation",
          subSectionName: webNotification.notificationSubSection
            ? toTitleFormat(webNotification.notificationSubSection)
            : "",
          color: "red",
          pillTextColor: "700",
          pillBgColor: "hospital",
          pillIcon: DepartmentIcon,
        };
      case "me":
        return {
          sectionName: "notification_center_me",
          subSectionName: webNotification.notificationSubSection
            ? toTitleFormat(webNotification.notificationSubSection)
            : "",
          color: "yellow",
          pillTextColor: "700",
          pillBgColor: "100",
          pillIcon: UserShadowIcon,
        };
      default:
        return {
          color: "grey",
          pillBgColor: "300",
          pillIcon: DepartmentIcon,
        };
    }
  };

  return { getWebNotificationProps };
};

export default useWebNotificationsConfig;
