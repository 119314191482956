import { AuthFunctionNames, authenticationClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { errorStore } from "Stores/ErrorStore";

const useVerifyCode = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(authenticationClient.confirmSignUp, {
    onError: (error: string) => {
      errorStore.captureError({
        error,
        source: "user",
        authFunctionName: AuthFunctionNames.confirmSignUp,
      });
    },
  });

  return {
    verifyCode: mutate,
    isVerifyingCode: isLoading,
    verifyCodeError: error,
    verifyCodeSuccessful: isSuccess,
  };
};

export default useVerifyCode;
