import { useMemo, useState } from "react";

import { sortByCreatedAt, sortByFavourites, sortByName, sortByUpdated } from "Utilities/helpers";
import { ListOrderBy, ListOrderByOption } from "Components/EolasFileList/ListOrderBy";

export interface UserSortOrderParams {
  initialOrder: ListOrderByOption;
  isDraggable?: boolean;
  sortDateBy?: "createdAt" | "updatedAt";
  favourites?: boolean;
  sortFn?: (a: any, b: any) => number;
}

export interface UseSortReturnParams {
  orderBy: ListOrderByOption;
  setOrder: (order: ListOrderByOption) => void;
  orderOptions: ListOrderByOption[];
  sortMethod?: (a: any, b: any) => number;
}

export type HookResponse = [JSX.Element, UseSortReturnParams];

export function useSortOrder({
  initialOrder,
  isDraggable,
  sortDateBy,
  favourites,
  sortFn,
}: UserSortOrderParams): HookResponse {
  const [orderBy, setOrder] = useState<ListOrderByOption>(initialOrder);

  const dateSortFn = useMemo(() => {
    if (sortDateBy === "createdAt") {
      return sortByCreatedAt;
    } else {
      return sortByUpdated;
    }
  }, [sortDateBy]);

  const orderOptions = useMemo(() => {
    let options: ListOrderByOption[] = ["alphabetical", "date"];
    if (isDraggable) {
      options = ["dragAndDrop", ...options];
    }
    if (!options.includes(initialOrder)) {
      options = [initialOrder, ...options];
    }
    if (favourites) {
      options = [...options, "favourites"];
    }
    return options;
  }, [isDraggable, initialOrder, favourites]);

  const sortMethod = useMemo(() => {
    switch (orderBy) {
      case "date":
        return dateSortFn;
      case "alphabetical":
        return sortByName;
      case "recent":
        return sortByUpdated;
      case "favourites":
        return sortByFavourites;
      default:
        return sortFn;
    }
  }, [dateSortFn, orderBy, sortFn]);

  const OrderComponent = useMemo(
    () => <ListOrderBy selectedOption={orderBy} handleClick={setOrder} options={orderOptions} />,
    [orderBy, setOrder, orderOptions],
  );

  return [OrderComponent, { orderBy, setOrder, orderOptions, sortMethod }];
}
