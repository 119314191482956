import { AppLevelSection, sectionStore } from "@eolas-medical/core";
import { useReorder } from "Hooks";

const useReorderTeamMembers = () => {
  const parentID = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.meetTheTeam)!.id;
  const { onDragEnd } = useReorder(parentID);

  return {
    reorderTeamMembers: onDragEnd,
  };
};

export default useReorderTeamMembers;
