import { useState } from "react";
import { observer } from "mobx-react-lite";
import { RouteProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { DeleteFileModal, EolasFileList } from "Components";
import { AddButton, InnerPageWrapper, PageTitle, NavButton, Modal } from "UIKit";

import { SimpleSectionFileModal } from "../components";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { AddItemWithAttachmentModal } from "Pages/Spaces/components/AddEolasFile";

export interface SimpleSectionProps extends RouteProps {
  mainSectionId: AppLevelSection;
  match?: any;
}

export const SimpleSection: React.FC<SimpleSectionProps> = observer(
  ({ mainSectionId, match: { url } }) => {
    const { t } = useTranslation();

    useMainSectionVisit({ mainSectionId: mainSectionId });

    const { isAdmin } = useSpacesContext();
    const { spaceSection } = useSpaceSection(mainSectionId);

    const [modal, setModal] = useState<React.ReactNode>(null);

    const onCloseModal = () => setModal(null);

    const onAddFile = () => {
      setModal(
        <AddItemWithAttachmentModal
          fileRequired={false}
          onCloseModal={onCloseModal}
          mainSectionID={mainSectionId}
        />,
      );
    };

    const onDeleteFile = (file: EolasFile) => {
      setModal(<DeleteFileModal file={file} onCloseModal={onCloseModal} />);
    };

    const onEditFile = (file: EolasFile) => {
      setModal(
        <AddItemWithAttachmentModal
          file={file}
          fileRequired={false}
          onCloseModal={onCloseModal}
          mainSectionID={mainSectionId}
        />,
      );
    };

    const onViewFile = (file: EolasFile) => {
      setModal(<SimpleSectionFileModal file={file} />);
    };

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection.name ?? t(mainSectionId)} />
          <NavButton to={url.replace(`/${mainSectionId}`, "")} />

          {isAdmin && <AddButton onClick={onAddFile}>{t("fileWithSearch_addNew_file")}</AddButton>}

          <EolasFileList
            isAdmin={isAdmin}
            onEdit={onEditFile}
            onAddItem={onAddFile}
            onDelete={onDeleteFile}
            onSelectFile={onViewFile}
            mainSectionId={mainSectionId}
          />
        </InnerPageWrapper>

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </>
    );
  },
);
