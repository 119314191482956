import React, { useCallback, useMemo } from "react";
import { Variants, motion } from "framer-motion";
import {
  Dropdown,
  DropdownOption,
  FavouriteToggle,
  FileTypeItemIcon,
  OperationsButton,
  Text,
  Title,
} from "UIKit";
import { DeleteIcon, DownloadIcon, DragIcon, EditMonoIcon } from "Assets";
import { format } from "date-fns";
import { getImageUrl } from "Utilities";
import { Action, EolasFileType } from "Utilities/types";

interface ContentItemTileProps {
  id: string;
  contentType: EolasFileType;
  title: string;
  description?: string;
  date?: string;
  "data-testid"?: string;
  isDraggable?: boolean;
  isDragging?: boolean;
  dateLabel?: string;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onDownload?: () => void;
  onClickFavourite?: (isFavourite: boolean) => void;
  isLoading?: boolean;
  imageUrl?: string;
  isFavourite?: boolean;
}

const childrenVariants: Variants = {
  out: { x: -25, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

const ContentItemTile: React.FC<ContentItemTileProps> = ({
  id,
  contentType,
  title,
  description,
  date,
  "data-testid": dataTestId,
  isDraggable,
  isDragging,
  dateLabel = "Date",
  onClick,
  onDelete,
  onEdit,
  onDownload,
  onClickFavourite,
  isLoading = false,
  imageUrl,
  isFavourite,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClick && onClick();
  };

  const handleFavouriteClick = useCallback(
    (isFavourite: boolean) => {
      onClickFavourite?.(isFavourite);
    },
    [onClickFavourite],
  );

  const handleActionMenuSelect = useCallback(
    (option: DropdownOption) => {
      if (option.value === "delete") {
        onDelete && onDelete();
      }

      if (option.value === "edit") {
        onEdit && onEdit();
      }

      if (option.value === "download") {
        onDownload && onDownload();
      }
    },
    [onDelete, onEdit, onDownload],
  );

  const menuOptions = useMemo(() => {
    let options: DropdownOption[] = [];

    if (onDelete) {
      options.push({
        label: Action.DELETE,
        value: "delete",
        icon: <DeleteIcon width={16} height={16} />,
      });
    }

    if (onEdit) {
      options.push({
        label: Action.EDIT,
        value: "edit",
        icon: <EditMonoIcon width={16} height={16} />,
      });
    }

    if (contentType !== "link" && contentType !== "flashcard" && onDownload) {
      options.push({
        label: Action.DOWNLOAD,
        value: "download",
        icon: <DownloadIcon width={16} height={16} />,
      });
    }
    return options;
  }, [contentType, onDelete, onEdit, onDownload]);

  return (
    <motion.div
      variants={childrenVariants}
      whileHover={{ scale: 1.01 }}
      className={`grid grid-cols-12 transition-all bg-white h-40 rounded-lg shadow-sm ${
        onClick ? "cursor-pointer" : ""
      } ${isDragging ? "tile-border__dragging" : "tile-border"}`}
      onClick={handleClick}
      data-testid={dataTestId}
    >
      <div className="row-start-1 col-start-1 col-end-4 lg:col-end-3 flex bg-blue-50 rounded-l-lg relative">
        {isDraggable && (
          <DragIcon
            data-testid="drag-icon"
            className="col-span-1 text-grey-500 self-start h-4 w-4 absolute top-5 left-2"
          />
        )}
        {imageUrl ? (
          <img
            src={getImageUrl(imageUrl)}
            alt=""
            className="w-full h-full object-cover rounded-l-lg"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <FileTypeItemIcon type={contentType} className="w-12 h-12" />
          </div>
        )}
      </div>

      <div className="row-start-1 col-start-4 lg:col-start-3 col-end-10 flex flex-col justify-between p-5">
        <div>
          <Title className="line-clamp-1" level={9}>
            {title}
          </Title>
          {description && (
            <Text level={1} className="text-grey-700 line-clamp-2">
              {description}
            </Text>
          )}
        </div>

        {date && (
          <Text level={1} className="font-bold text-xs">
            {dateLabel}
            <span className="font-semibold ml-3">{format(new Date(date), "do MMMM yyyy")}</span>
          </Text>
        )}
      </div>

      <div className="row-start-1 row-end-2 col-start-13 flex flex-col justify-between p-5">
        {menuOptions.length > 0 && (
          <Dropdown
            dropdownToggle={<OperationsButton className="static" size="xs" />}
            options={menuOptions}
            onSelect={handleActionMenuSelect}
          />
        )}
        {onClickFavourite && (
          <div className="self-end">
            <FavouriteToggle
              data-testid={`favorite-button-${id}`}
              onClick={handleFavouriteClick}
              isFavourite={!!isFavourite}
              isLoading={isLoading}
              className="rounded-sm px-2"
            />
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default ContentItemTile;
