import { QueryEnvironment, useEolasQuery } from "Hooks";
import { getAssessorsSummary } from "../client/portfolio.client";
import { AssessorSummary } from "../client/portfolio.graphql";
import portfolioKeys from "./portfolio.queryKeys";

interface useGetAssessorsSummaryProps {
  assessorsList: AssessorSummary[];
  assessorsListLoading: boolean;
}

export const useAssessorsSummary = (eventId: string): useGetAssessorsSummaryProps => {
  const { isLoading, data, ...rest } = useEolasQuery({
    queryFn: () => getAssessorsSummary(eventId),
    queryKey: portfolioKeys.reviewersList(eventId),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 300000,
      staleTime: 60000,
    },
  });

  return { assessorsListLoading: isLoading, assessorsList: data || [], ...rest };
};

export default useAssessorsSummary;
