import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { useCallback, useState, useMemo } from "react";

import { useSelectFile } from "Hooks";
import { EolasFile } from "Utilities/types";
import { formatDate } from "Utilities/general";
import { Button, Title, ModalHeader, InnerModalWrapper, ModalBody, FormError } from "UIKit";

interface SimpleSectionFileModalProps {
  file: EolasFile;
}

const useSectionFile = (file: EolasFile) => {
  const [videoURL, setVideoURL] = useState("");
  const { error, isLoading, onSelectFile: _onSelect } = useSelectFile("requestStatus");

  const { type = "" } = file;

  const hasAttachment = useMemo(() => {
    return type && ["link", "mp4", "pdf", "ms-office"].includes(type);
  }, [type]);

  const onSelectFile = useCallback(() => {
    _onSelect(file).then((url) => {
      if (type === "mp4") {
        setVideoURL(url);
      }
    });
  }, [_onSelect, file, type]);

  const shouldShowPlayer = type === "mp4" && videoURL;

  return {
    error,
    videoURL,
    isLoading,
    setVideoURL,
    onSelectFile,
    hasAttachment,
    shouldShowPlayer,
  };
};

export const SimpleSectionFileModal: React.FC<SimpleSectionFileModalProps> = ({ file }) => {
  const { t } = useTranslation();
  const { info, name, createdAt, description } = file;

  const {
    error,
    videoURL,
    isLoading,
    hasAttachment,
    shouldShowPlayer,
    setVideoURL,
    onSelectFile,
  } = useSectionFile(file);

  return (
    <InnerModalWrapper>
      <ModalHeader className="items-start">
        <Title level={5}>{name}</Title>
        <span className="text-sm text-grey-dark">{info}</span>
        <span className="text-sm text-grey-dark">{formatDate(createdAt as string)}</span>
      </ModalHeader>

      <ModalBody className="overflow-y-auto pr-0 sm:pr-0">
        <div className="max-h-50vh overflow-y-auto pr-6 sm:pr-12">{description}</div>

        {shouldShowPlayer ? (
          <div className="flex flex-col mt-4 sm:mt-8 pr-6 sm:pr-12">
            <div className="flex flex-col">
              <ReactPlayer
                playing
                controls
                width="100%"
                height="100%"
                stopOnUnmount
                url={videoURL}
                style={{ width: "100%" }}
                data-testid="videoPlayer"
              />

              <Button
                color="grey"
                weight="regular"
                className="self-center my-4"
                onClick={() => setVideoURL("")}
              >
                {t("EdPortal_videoPlayer_button")}
              </Button>
            </div>
          </div>
        ) : hasAttachment ? (
          <Button
            color="blue"
            weight="regular"
            variant="outline"
            isLoading={isLoading}
            onClick={onSelectFile}
            className="self-center my-4"
          >
            {t("newsFeed_view_attachement")}
          </Button>
        ) : null}

        <FormError error={error} />
      </ModalBody>
    </InnerModalWrapper>
  );
};
