import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { awaitApproval } from "Assets/LottieAnimations";
import { BaseAppRoute } from "Utilities/types";
import { InnerModalWrapper, Button, LottieWithHeader } from "UIKit";

import { FindSpaceStep, SearchSpaceResult } from "../../types";
import { useSelectSpaceActions } from "../../hooks/useSelectSpaceActions";
import { SelectSpaceStep } from "./components/SelectSpaceStep/SelectSpaceStep";
import RequestAccessForm from "./components/RequestAccessForm/RequestAccessForm";
import CreateSpaceModal from "../CreateSpaceWizard/CreateSpaceWizard";
import { Space } from "@eolas-medical/core";
import useSpacesContext from "modules/spaces/context/useSpacesContext";

interface SelectSpaceModalProps {
  apps?: SearchSpaceResult[];
  initialState?: FindSpaceStep;
}

const containerClassname = "flex flex-col p-12 space-y-8 sm:space-y-12";
const containerTextClassname = "text-xl sm:text-2xl text-center font-semibold";

export const SelectSpaceModal: React.FC<SelectSpaceModalProps> = ({ apps = [], initialState }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { onSelectSpace: onSelectNewSpace } = useSpacesContext();

  const {
    selectSpaceStep,
    selectSpaceMessage,
    requestingAccess,
    shouldAllowAccessLoading,
    error,
    loadingSpaceId,
    onSelectSpace,
    onRequestAccess,
    setSelectSpaceStep,
  } = useSelectSpaceActions(initialState);

  const handleSpaceCreated = (createdSpace: Space) => {
    onSelectNewSpace(createdSpace);
    push(`/${BaseAppRoute.spaces}/${createdSpace.name}`);
  };

  return (
    <InnerModalWrapper>
      {selectSpaceStep === "select-space" && (
        <SelectSpaceStep
          apps={apps}
          error={error}
          onSelectSpace={onSelectSpace}
          loadingAppID={loadingSpaceId}
          onAddSpace={() => setSelectSpaceStep("add-space")}
        />
      )}
      {selectSpaceStep === "add-space" && <CreateSpaceModal onCreate={handleSpaceCreated} />}
      {selectSpaceStep === "request-access" && (
        <RequestAccessForm isLoading={requestingAccess} onSubmit={onRequestAccess} />
      )}
      {selectSpaceStep === "no-trusted-email" && (
        <div className={containerClassname}>
          <span className={containerTextClassname}>{selectSpaceMessage}</span>

          <div
            className={`
              grid grid-cols-1 sm:grid-cols-2 gap-4
            `}
          >
            <Button
              size="lg"
              disabled={shouldAllowAccessLoading}
              onClick={() => {
                push(`/${BaseAppRoute.myProfile}/personal-details`, { addEmail: true });
              }}
            >
              {t("selectSpace_add_trusted_email_button")}
            </Button>

            <Button
              size="lg"
              variant="outline"
              disabled={shouldAllowAccessLoading}
              onClick={() => onRequestAccess()}
            >
              {t("selectSpace_request_access_button")}
            </Button>
          </div>
        </div>
      )}
      {selectSpaceStep === "request-sent" && (
        <LottieWithHeader
          animationSize="150px"
          animation={awaitApproval}
          textClassName="pb-8 px-8"
          text={t("selectSpace_requestSent")}
        />
      )}
    </InnerModalWrapper>
  );
};
