import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { EolasFile } from "@eolas-medical/core";
import { useSelectFile, useSelectFileModal } from "Hooks";
import { BaseAppRoute } from "Utilities/types";
import { SearchResult } from "../types";
import { isKnowledgeResult, isLocalResult, isMeResult } from "../helpers";

interface UseSelectResult {
  modal: React.ReactNode;
  onSelectResult: (result: SearchResult) => void;
  onCloseModal: () => void;
}

const useSelectResult = (): UseSelectResult => {
  const { push } = useHistory();
  const { modal, onSelectFile, onCloseModal } = useSelectFileModal({
    from: "masterSearch",
  });
  const { onSelectFile: selectFile } = useSelectFile();

  const onSelectResult = useCallback(
    (result: SearchResult) => {
      if (isLocalResult(result)) {
        onSelectFile(result.file as EolasFile);
      }

      if (isKnowledgeResult(result)) {
        if (result.searchSubType === "clinicalCalculators") {
          // TODO: when merging new calculators use onSelectFile
          selectFile(result.knowledgeFile);
          return;
        }
        onSelectFile(result.knowledgeFile as EolasFile);
      }

      if (isMeResult(result)) {
        if (result.event) {
          push(
            `/${BaseAppRoute.myProfile}/portfolio/my-portfolio/${result.event.type.toLowerCase()}/${
              result.event.id
            }`,
          );
        }

        if (result.myFile) {
          if (result.myFile.link) {
            window.open(result.myFile.link.url, "_blank");
          } else {
            push(`/${BaseAppRoute.myProfile}/my-files/file/${result.myFile.id}`);
          }
        }
      }
    },
    [onSelectFile, selectFile, push],
  );

  return {
    modal,
    onSelectResult,
    onCloseModal,
  };
};

export default useSelectResult;
