import React from "react";
import { motion, Variants } from "framer-motion";
import { Link } from "react-router-dom";
import { EolasFile } from "@eolas-medical/core";
import { overrideTailwindClasses as tw } from "tailwind-override";

import { DragIcon } from "Assets";
import { AttachmentReference } from "UIKit/AttachmentReference/AttachmentReference";

const childrenVariants: Variants = {
  out: { x: -25, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

export interface FileReference {
  fileId: string;
  tooltipText: string;
}

export interface FileTileWrapperProps {
  testId?: string;
  className?: string;
  canDrag?: boolean;
  isDragging?: boolean;
  reference?: FileReference;
  children: React.ReactNode;
  eolasFile: EolasFile;
  onClick?(e: React.MouseEvent): void;
  isMedusaFile?: boolean;
}

export const FileTileWrapper: React.FC<FileTileWrapperProps> = ({
  onClick,
  canDrag,
  children,
  eolasFile,
  isDragging,
  isMedusaFile,
  className = "",
}) => {
  const wrapperUI = (
    <motion.div
      variants={childrenVariants}
      whileHover={{ scale: 1.01 }}
      className={tw(`
        flex flex-row items-center transition-all bg-white
        space-x-4 space-y-0 rounded-lg p-4 shadow-sm
        ${onClick ? "cursor-pointer" : ""}
        ${isDragging ? "tile-border__dragging" : "tile-border"}
        ${className}
      `)}
    >
      {canDrag && <DragIcon className="text-grey-500 self-start h-4 w-4" />}
      {children}
    </motion.div>
  );

  if (!isMedusaFile && (eolasFile?.type === "pdf" || eolasFile?.type === "ms-office")) {
    return (
      <Link to={{ pathname: `/documents/viewer/${encodeURIComponent(eolasFile.id)}` }}>
        {wrapperUI}
      </Link>
    );
  }

  return React.cloneElement(wrapperUI, { onClick });
};

export const RegularFileWrapper: React.FC<Omit<FileTileWrapperProps, "eolasFile">> = ({
  onClick,
  canDrag,
  children,
  isDragging,
  testId,
  className = "",
  reference,
}) => {
  return (
    <motion.div
      data-testid={testId}
      onClick={onClick}
      variants={childrenVariants}
      whileHover={{ scale: 1.01 }}
      className={tw(
        `flex flex-row items-center transition-all bg-white space-x-2 space-y-0 rounded-md p-2 shadow-sm ${
          onClick ? "cursor-pointer" : ""
        } ${isDragging ? "tile-border__dragging" : "tile-border"} ${className}`,
      )}
    >
      <AttachmentReference
        isReferenced={!!reference}
        tooltipText={reference?.tooltipText}
        fileId={reference?.fileId}
      />
      {canDrag && <DragIcon className="hidden text-grey-500 self-center h-4 w-4 md:block" />}
      <div className="flex flex-1 items-center transition-all bg-white space-x-2 space-y-0 rounded-md p-2">
        {children}
      </div>
    </motion.div>
  );
};
