import { AdminDashboardIconType } from "Hooks";

type AdminDashboardSection = {
  title: string;
  route: string;
  description: string;
  id: AdminDashboardIconType;
};

const adminSectionsMyMedical: AdminDashboardSection[] = [
  {
    id: "contentManagement",
    title: "adminHome_cm_card_title",
    description: "adminHome_cm_card_description",
    route: `content-management`,
  },
  {
    id: "manageUsers",
    title: "adminHome_manage_users_card_title",
    description: "adminHome_manage_users_card_description",
    route: `manageUsers`,
  },
];

const useOrganisationAdminSections = () => {
  return {
    activeAdminMainSections: adminSectionsMyMedical,
  };
};

export default useOrganisationAdminSections;
