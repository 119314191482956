import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthFunctionNames, AnalyticsEvents } from "@eolas-medical/core";

import { BaseAppRoute } from "Utilities/types";
import { errorStore } from "Stores/ErrorStore";
import { useNotifications } from "Components/Notifications";
import { trackEvent, timeEvent } from "API/Analytics";

import { LoginCredentials } from "../types";
import { useRegistrationStage } from "../../hooks";
import { login } from "Pages/Authentication/auth.actions";

type Stage = "createAccount" | "enterVerificationCode" | "complete";

interface InvitationPayload {
  userID: string;
  email?: string;
  invitationID: string;
}

export const useSignUp = () => {
  const { push } = useHistory();
  const { showNotification } = useNotifications();
  const { search, state: routerState } = useLocation<InvitationPayload>();
  const [loginCredentials, setLoginCredentials] = React.useState<LoginCredentials>({
    email: "",
    password: "",
  });
  const { currentStage, setStage } = useRegistrationStage<Stage>("createAccount");

  const isFromInvitation = React.useMemo(() => {
    return !!routerState?.email;
  }, [routerState?.email]);

  const onCreateAccountSuccess = (loginCredentials: LoginCredentials) => {
    setLoginCredentials(loginCredentials);
    setStage("enterVerificationCode")();
  };

  const onVerificationSuccess = async () => {
    setStage("complete")();

    try {
      await login({ ...loginCredentials });

      push({
        pathname: `/${BaseAppRoute.spaces}`,
        search,
        state: { newUser: true },
      });
    } catch (error: any) {
      const errorMessage = errorStore.captureError({
        error,
        source: "user",
        authFunctionName: AuthFunctionNames.login,
      });

      showNotification({
        type: "error",
        autoHideTimeout: 5000,
        description: errorMessage,
      });
    }
  };

  React.useEffect(() => {
    if (isFromInvitation) {
      timeEvent(AnalyticsEvents.ACCEPT_INVITE_DURATION);
      trackEvent(AnalyticsEvents.ACCEPT_INVITE_STARTED);
    } else {
      timeEvent(AnalyticsEvents.SIGN_UP_DURATION);
      trackEvent(AnalyticsEvents.SIGN_UP_STARTED);
    }
  }, [isFromInvitation]);

  return {
    currentStage,
    loginCredentials,
    isFromInvitation,
    email: routerState?.email,
    setStage,
    onVerificationSuccess,
    onCreateAccountSuccess,
  };
};
