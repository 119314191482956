import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { useClickOutside } from "Hooks";
import { ClickSearchBox, Loader } from "UIKit";

import { RecentSearch, SearchResult, SearchType } from "../types";
import { getResultsPosition } from "../helpers";

import NoResultsMessage from "./NoResultsMessage/NoResultsMessage";
import SearchResultList from "./SearchResultList/SearchResultList";
import FilterPills from "./FilterPills/FilterPills";
import RecentSearchesList from "./RecentSearchesList/RecentSearchesList";
import SearchDisclaimer from "./SearchDisclaimer/SearchDisclaimer";

import useResizeScreen from "Hooks/useResizeScreen/useResizeScreen";
import useMasterSearchState from "./useMasterSearchState";
import useBlockScreenScroll from "Hooks/useBlockScreenScroll/useBlockScreenScroll";

import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";

interface MasterSearchResultsProps {
  searchInput: string;
  searchValue: string;
  searchLoading: boolean;
  searchResult: SearchResult[];
  searchType: SearchType;
  onClickMessage: (searchType: SearchType) => void;
  recentSearches: RecentSearch[];
  knowledgeSearchDisabled?: boolean;
  localSearchDisabled?: boolean;
  onClearSearch: () => void;
  onChangeText: (text: string) => void;
  onClickFilter: (searchType: SearchType) => void;
  onClickSearch: (searchText?: string) => void;
  onClickResult: (result: SearchResult) => void;
}
const MasterSearchResults = ({
  searchInput,
  searchValue,
  searchLoading,
  searchType,
  onClickMessage,
  searchResult,
  recentSearches,
  knowledgeSearchDisabled = false,
  localSearchDisabled = false,
  onClickSearch,
  onChangeText,
  onClearSearch,
  onClickFilter,
  onClickResult,
}: MasterSearchResultsProps) => {
  const { t } = useTranslation();

  const { state, onType, ...actions } = useMasterSearchState();
  const showModal = state.showRecentSearches || state.showResults;

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(dropdownRef, actions.onClickOutside);
  useResizeScreen({ onResize: actions.onClickOutside });
  useBlockScreenScroll(showModal);

  const handleChangeText = useCallback(
    (text: string) => {
      onChangeText(text);
      if (text !== state.searchText) {
        onType(text);
      }
    },
    [onType, onChangeText, state],
  );

  const handleClickPill = useCallback(
    (searchType: SearchType) => {
      onClickFilter(searchType);
      actions.onClickPill();
    },
    [actions, onClickFilter],
  );

  const handleClickSearch = useCallback(() => {
    onClickSearch(state.searchText);
    actions.onClickSearch();
  }, [actions, state, onClickSearch]);

  const handleClickRecentSearch = useCallback(
    (search: RecentSearch) => {
      onChangeText(search.searchText);
      onClickSearch(search.searchText);
      actions.onClickRecentSearch(search.searchText);
    },
    [actions, onClickSearch, onChangeText],
  );

  const handleClearSearch = useCallback(() => {
    onClearSearch();
    actions.onClear();
  }, [actions, onClearSearch]);

  const handleOnFocus = () => {
    actions.onClickSearchbox();
    trackEvent(AnalyticsEvents.MAIN_SECTION_VISIT, {
      visitFrom: "directNavigation",
      mainSectionId: "appGlobalSearch",
    });
  };

  const renderSearchBox = () => (
    <ClickSearchBox
      value={searchInput}
      isLoading={searchLoading}
      onClickSearch={handleClickSearch}
      onClearSearch={handleClearSearch}
      onChangeText={handleChangeText}
      onClick={actions.onClickSearchbox}
      onFocus={handleOnFocus}
      className="rounded-xl z-20 shadow-md"
      placeholder={t("master_search_placeholder")}
      data-testid="master-search-input"
    />
  );

  const renderRecentSearches = () => {
    if (searchInput.length === 0 && recentSearches.length === 0) {
      return <SearchDisclaimer />;
    }

    return (
      <RecentSearchesList recentSearches={recentSearches} onClickSearch={handleClickRecentSearch} />
    );
  };

  const renderResults = () => {
    if (searchLoading) {
      return (
        <div className="flex flex-col items-center justify-center space-y-4 mt-10 mb-24">
          <Loader />
        </div>
      );
    }

    if (searchValue.length === 0) {
      return null;
    }

    if (searchValue.length && searchResult.length === 0) {
      return (
        <NoResultsMessage
          searchType={searchType}
          searchValue={searchValue}
          onClickMessage={onClickMessage}
        />
      );
    }

    return <SearchResultList results={searchResult} onClickResult={onClickResult} />;
  };

  const { top, left, width } = getResultsPosition(dropdownRef);

  return (
    <>
      {showModal && (
        <motion.div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.45)", marginTop: "-4rem" }}
          className="-mt-16 flex items-end md:items-center justify-center fixed inset-0 z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        />
      )}
      <div
        className="mx-auto items-stretch w-full z-20 max-w-xs sm:max-w-xl md:max-w-2xl xl:max-w-3xl"
        ref={dropdownRef}
      >
        <div className="flex items-center justify-center">{renderSearchBox()}</div>
        {showModal && (
          <div
            className="absolute z-10"
            style={{ top, left, width }}
            data-testid="master-search-results"
          >
            <div className="bg-grey-50 rounded-b-lg p-2">
              {state.showResults && (
                <>
                  <FilterPills
                    localSearchDisabled={localSearchDisabled}
                    knowledgeSearchDisabled={knowledgeSearchDisabled}
                    selectedPill={searchType}
                    onClickPill={handleClickPill}
                  />

                  {renderResults()}
                </>
              )}
              {state.showRecentSearches && <>{renderRecentSearches()}</>}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MasterSearchResults;
