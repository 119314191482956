import React, { useMemo } from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

import { LoaderIcon } from "Assets";

import { ButtonType, IconButtonSize, IconButtonVariant, ButtonColorScheme } from "./types";

export interface IconButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "size"> {
  type?: ButtonType;
  isLoading?: boolean;
  size?: IconButtonSize;
  color?: ButtonColorScheme;
  variant?: IconButtonVariant;
  icon: React.ReactComponentElement<any, React.SVGProps<SVGSVGElement>>;
}

const sizes: Record<IconButtonSize, { iconSize: string; buttonSize: string }> = {
  xs: {
    buttonSize: "h-6 w-6",
    iconSize: "h-3 w-3",
  },
  sm: {
    buttonSize: "h-8 w-8",
    iconSize: "h-4 w-4",
  },
  md: {
    buttonSize: "h-10 w-10",
    iconSize: "h-5 w-5",
  },
  lg: {
    buttonSize: "h-12 w-12",
    iconSize: "h-6 w-6",
  },
  xl: {
    buttonSize: "h-14 w-14",
    iconSize: "h-7 w-7",
  },
};

type ColorMap = Record<ButtonColorScheme, string>;

const makeButtonStyles = (isDisabled?: boolean): ColorMap => {
  return {
    blue: isDisabled
      ? "bg-blue-200 border-blue-200 text-blue-50 cursor-not-allowed"
      : `
        bg-blue-500 border-blue-500 text-white
        hover:bg-blue-300 hover:border-blue-300
        active:bg-blue-600 active:border-blue-600
        `,
    red: isDisabled
      ? "bg-red-200 border-red-200 text-red-50 cursor-not-allowed"
      : `
        bg-red-500 border-red-500 text-white
        hover:bg-red-300 hover:border-red-300
        active:bg-red-600 active:border-red-600
      `,
    green: isDisabled
      ? "bg-green-200 border-green-200 text-green-50 cursor-not-allowed"
      : `
        bg-green-500 border-green-500 text-white
        hover:bg-green-300 hover:border-green-300
        active:bg-green-600 active:border-green-600
      `,
    yellow: isDisabled
      ? "bg-yellow-200 border-yellow-200 text-yellow-50 cursor-not-allowed"
      : `
        bg-yellow border-yellow text-white
        hover:bg-yellow-300 hover:border-yellow-300
        active:bg-yellow-600 active:border-yellow-600
      `,
    grey: isDisabled
      ? "bg-grey-100 border-grey-100 text-grey-300 cursor-not-allowed"
      : `
        bg-grey-300 border-grey-300 text-black
        hover:bg-grey-100 hover:border-grey-100
        active:bg-grey-200 active:border-grey-200
      `,
    black: "bg-black border-black text-white hover:bg-grey-700 hover:border-grey-700",
    white: "bg-white border-grey-400 text-black",
  };
};

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  disabled,
  children,
  isLoading,
  size = "md",
  color = "grey",
  className = "",
  variant = "square",
  ...props
}) => {
  const { buttonSize, iconSize, styles } = useMemo(() => {
    const styles = makeButtonStyles(disabled)[color];
    return { ...sizes[size], styles };
  }, [color, disabled, size]);

  return (
    <button
      className={tw(`
        flex items-center justify-center
        ${styles}
        ${buttonSize}
        ${variant === "square" ? "rounded-sm" : "rounded-full"}
        ${className}
      `)}
      {...props}
    >
      {isLoading ? (
        <LoaderIcon className={`animate-spin ${iconSize}`} />
      ) : (
        React.cloneElement(icon, { className: iconSize })
      )}
    </button>
  );
};
