import { useMemo } from "react";
import { ActionButton } from "modules/portfolio/types";
import { ActionButtonType } from "modules/portfolio/types";

interface UseFeedbackFormButtonsProps {
  actionButtons: ActionButton[];
  onSubmit: () => void;
  onDelete: () => void;
  onDecline: () => void;
}

const useFeedbackFormButtons = ({
  actionButtons,
  onSubmit,
  onDelete,
  onDecline,
}: UseFeedbackFormButtonsProps) => {
  const feedbackButtons: ActionButton[] = useMemo(() => {
    return actionButtons.map((ac) => {
      if (ac.type === ActionButtonType.DELETE_FORM) {
        return {
          ...ac,
          type: ActionButtonType.DECLINE_FEEDBACK,
          onClick: onDelete,
        };
      }
      if (ac.type === ActionButtonType.DECLINE_FEEDBACK) {
        return {
          ...ac,
          onClick: onDecline,
        };
      }
      if (ac.type === ActionButtonType.SUBMIT_FEEDBACK) {
        return {
          ...ac,
          onClick: onSubmit,
        };
      }
      return ac;
    });
  }, [actionButtons, onSubmit, onDelete, onDecline]);

  return feedbackButtons;
};

export default useFeedbackFormButtons;
