import { EolasRegions } from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";

export const useShowProfileVerification = () => {
  const { supportedEolasRegion } = useSupportedRegion();

  return {
    showProfileVerification:
      supportedEolasRegion === EolasRegions.Uk || supportedEolasRegion === EolasRegions.Ireland,
  };
};
