import ApolloLinkTimeout from "apollo-link-timeout-dynamic";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  WatchQueryFetchPolicy,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { authenticationClient } from "@eolas-medical/core";
import envConfig from "env-config";

const timeoutLink = new ApolloLinkTimeout({ timeout: 20000 });

const authLink = setContext(async (_, { headers }) => {
  const token = await authenticationClient.getToken();
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});
const fetchPolicy: WatchQueryFetchPolicy = "network-only";
// This is a factory for making the config object for each BFF apollo client:
const makeBffApolloOptions = (bffDomain: string) => {
  const httpLink = new HttpLink({
    uri: `https://${envConfig.REACT_APP_API_BASE_URL}/web-bff/${bffDomain}`,
  });
  return {
    link: ApolloLink.from([timeoutLink, authLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      query: {
        fetchPolicy,
      },
      watchQuery: {
        fetchPolicy,
      },
    },
  };
};
export const makeBffApolloClient = (bffDomain: string) => {
  return new ApolloClient(makeBffApolloOptions(bffDomain));
};
