import { useQuery } from "@tanstack/react-query";
import { getDepartmentUsers } from "clients/departments/departments.client";
import spacesKeys from "./spaces.queryKeys";

const useSpaceUsers = (spaceId: string) => {
  const { data, refetch: refetchUserList, isLoading } = useQuery(
    spacesKeys.usersList(spaceId),
    () => getDepartmentUsers({ departmentId: spaceId }),
  );

  return {
    spaceUsers: data ? data.items : [],
    refetchUserList,
    isLoadingSpaceUsers: isLoading,
  };
};

export default useSpaceUsers;
