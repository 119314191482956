import { ChooseFileTypeStep } from "./ChooseFileTypeStep";
import { EolasFileType } from "Utilities/types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useChooseFileTypeStepSchema } from "./useChooseFileTypeStepSchema";
import { LinkFormValues, ChooseFileTypeValues, AllowedChooseFileTypes } from "./types";
import { Step } from "../../types";
import { useState } from "react";

interface UseChooseFileTypeStepProps<T> {
  stepName: T;
  title: string;
  allowedTypes: AllowedChooseFileTypes[];
  defaultSelectedType?: EolasFileType;
  fileFormLabel?: string;
  fileUploadLabel?: string;
  fileChangeLabel?: string;
  fileDescriptionText?: string;
  linkNameLabel?: string;
  linkNamePlaceholder?: string;
  linkUrlLabel?: string;
  linkUrlPlaceholder?: string;
  flashcardTitle?: string;
  flashcardSubtitle?: string;
  flashcardDescription?: string;
  cancelLabel?: string;
  blobName?: string;
  link?: LinkFormValues;
  isLoading?: boolean;
  onSelectType: (type: EolasFileType) => void;
  onSubmit: (type: EolasFileType, result?: File | LinkFormValues) => void;
  onClose?: () => void;
}

export const useChooseFileTypeStep = <T extends string>({
  stepName,
  title = "Select Item Type",
  allowedTypes,
  defaultSelectedType = "blob",
  fileFormLabel = "File to Upload",
  fileUploadLabel = "Browse Files",
  fileChangeLabel = "Change File",
  fileDescriptionText = "Drag & Drop a file here or",
  linkNameLabel = "Name",
  linkNamePlaceholder = "Enter link name",
  linkUrlLabel = "URL",
  linkUrlPlaceholder = "Enter link URL",
  flashcardTitle = "",
  flashcardSubtitle = "",
  flashcardDescription = "",
  cancelLabel = "Cancel",
  link,
  blobName,
  isLoading,
  onSelectType,
  onSubmit,
  onClose,
}: UseChooseFileTypeStepProps<T>): Step<T> => {
  const [selectedType, setSelectedType] = useState<EolasFileType>(defaultSelectedType);
  const chooseFileTypeStepSchema = useChooseFileTypeStepSchema(selectedType);

  const {
    control,
    handleSubmit,
    formState,
    setValue,
    clearErrors,
    reset,
    getValues,
  } = useForm<ChooseFileTypeValues>({
    defaultValues: {
      link,
    },
    resolver: yupResolver(chooseFileTypeStepSchema),
  });

  const handleSelect = (type: EolasFileType) => {
    setSelectedType(type);
    onSelectType(type);
    reset();
  };

  const handleBlobAddition = (file: File) => {
    setValue("blob", file);
    clearErrors("blob");
  };

  const handleSubmitFileType = () => {
    const { blob, link } = getValues();
    onSubmit(selectedType, blob || link || undefined);
  };

  return {
    name: stepName,
    title,
    submitError: !formState.isValid,
    canProceed: formState.isSubmitSuccessful,
    onSubmit: handleSubmit(handleSubmitFileType),
    onPrev: onClose,
    backLabel: cancelLabel,
    isLoading: isLoading,
    component: (
      <ChooseFileTypeStep
        control={control}
        onSelect={handleSelect}
        allowedTypes={allowedTypes}
        selectedType={selectedType}
        onAddBlob={handleBlobAddition}
        blobName={getValues("blob")?.name || blobName}
        errors={formState.errors}
        fileFormLabel={fileFormLabel}
        fileChangeLabel={fileChangeLabel}
        fileUploadLabel={fileUploadLabel}
        fileDescriptionText={fileDescriptionText}
        linkNameLabel={linkNameLabel}
        linkNamePlaceholder={linkNamePlaceholder}
        linkUrlLabel={linkUrlLabel}
        linkUrlPlaceholder={linkUrlPlaceholder}
        flashcardTitle={flashcardTitle}
        flashcardSubtitle={flashcardSubtitle}
        flashcardDescription={flashcardDescription}
      />
    ),
  };
};
