import { useCallback } from "react";
import { useFileDownload, OnDownloadActions, GetFileAsync } from "shared/hooks/useFileDownload";
import { portfolioService } from "modules/portfolio/client/PortfolioService";
import { AttachmentItem } from "modules/portfolio/types";
import useMyFileDownload from "modules/myFiles/data/useMyFileDownload";

const useAttachmentDownload = () => {
  const { download: downloadMyFile, getFileUrlWithUrl } = useMyFileDownload();

  const getAttachment = useCallback(
    async ({
      fileId,
      attachmentId,
    }: {
      fileId?: string;
      attachmentId?: string;
    }): Promise<Pick<AttachmentItem, "id" | "blob" | "name"> | void> => {
      if (attachmentId) {
        const response = await portfolioService.getAttachment(attachmentId);
        return {
          id: attachmentId,
          name: response.data.name,
          blob: {
            id: attachmentId,
            mediaId: response.data.fileId,
            type: response.data.blobType,
            size: response.data.size,
            getURL: response.data.s3DownloadUrl,
          },
        };
      }

      if (fileId) {
        const myFile = await getFileUrlWithUrl(fileId);
        return myFile;
      }
    },
    [getFileUrlWithUrl],
  );

  const getAttachmentUrl: GetFileAsync = useCallback(async (attachmentId: string) => {
    const response = await portfolioService.getAttachment(attachmentId);
    return {
      fileName: response.data.name,
      fileUrl: response.data.s3DownloadUrl,
      fileExt: response.data.blobType,
    };
  }, []);

  const { downloadFile, ...rest } = useFileDownload({ getFileAsync: getAttachmentUrl });

  const download = useCallback(
    async (attachment: AttachmentItem, { onSuccess, onError }: OnDownloadActions) => {
      if (attachment.blob?.attachmentId) {
        downloadFile(attachment.blob?.attachmentId, { onSuccess, onError });
        return;
      }

      downloadMyFile(attachment.id, { onSuccess, onError });
    },
    [downloadFile, downloadMyFile],
  );

  return {
    download,
    getAttachment,
    ...rest,
  };
};

export default useAttachmentDownload;
