import QueryString from "qs";
import { get } from "lodash";
import { useLocation } from "react-router-dom";
import { searchClient, AnalyticsEvents, AppType } from "@eolas-medical/core";
import { useCallback, useEffect, useState, ChangeEvent } from "react";

import { useSentry } from "Contexts";
import { trackEvent } from "API/Analytics";
import { errorStore } from "Stores/ErrorStore";
import { LANDING_PAGE_SLUG } from "Utilities/types";
import { useRequestStatus } from "Utilities/useRequestStatus";

import { SelectSpaceModal } from "../components/SelectSpaceModal/SelectSpaceModal";
import { NoTextEnteredModal } from "../components/NoTextEnteredModal/NoTextEnteredModal";
import { appConfig } from "AppTypeConfig";

export const useFindSpace = () => {
  const { search } = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const { addBreadcrumb, captureException } = useSentry();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { isLoading, error, setRequestStatus } = useRequestStatus();

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const onCreateSpace = useCallback(() => {
    setModal(<SelectSpaceModal apps={[]} initialState="add-space" />);
  }, [setModal]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchInput(e.target.value.toLowerCase());
    },
    [setSearchInput],
  );

  const onSearch = useCallback(() => {
    if (searchInput.length < 2) {
      setModal(<NoTextEnteredModal />);
      return;
    }

    addBreadcrumb({
      category: "Search Organisation",
      message: `Searching for organisation request: ${searchInput}`,
    });
    trackEvent(AnalyticsEvents.ORGANISATION_SEARCH, { searchString: searchInput });

    setRequestStatus({ status: "pending", error: "" });
    searchClient
      .searchOrganisations(searchInput, appConfig.appType as AppType)
      .then((response) => {
        setRequestStatus({ status: "success", error: "" });
        setModal(<SelectSpaceModal apps={get(response, "items", [])} />);
      })
      .catch((error) => {
        captureException(error);
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  }, [searchInput, addBreadcrumb, setRequestStatus, captureException]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onSearch();
      }
    },
    [onSearch],
  );

  useEffect(() => {
    const query = QueryString.parse(search, { ignoreQueryPrefix: true });

    if (query.from === LANDING_PAGE_SLUG) {
      setModal(<SelectSpaceModal initialState="add-space" />);
    }
  }, [search]);

  return {
    modal,
    error,
    onChange,
    onSearch,
    isLoading,
    onKeyDown,
    searchInput,
    onCloseModal,
    setSearchInput,
    onCreateSpace,
  };
};
