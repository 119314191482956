import React, { useCallback, useState } from "react";

import { EolasChecklistSection } from "Utilities/types";

import {
  DeleteChecklistModal,
  UnresolvedIssuesModal,
  DeactivateChecklistModal,
} from "../components";

export const useCompletedChecklistsButtons = (
  checklist: EolasChecklistSection,
  isDisabled: boolean,
) => {
  const [modal, setModal] = useState<React.ReactNode>(null);
  const onCloseModal = useCallback(() => setModal(null), [setModal]);

  const onDeleteChecklist = () => {
    setModal(<DeleteChecklistModal checklist={checklist} closeModal={onCloseModal} />);
  };

  const onDeactivateChecklist = () => {
    setModal(
      <DeactivateChecklistModal
        checklist={checklist}
        isDisabled={isDisabled}
        onCloseModal={onCloseModal}
      />,
    );
  };

  const showUnresolvedIssues = () => {
    setModal(<UnresolvedIssuesModal checklist={checklist} onCloseModal={onCloseModal} />);
  };

  return {
    modal,
    onCloseModal,
    onDeleteChecklist,
    showUnresolvedIssues,
    onDeactivateChecklist,
  };
};
