import { sectionStore } from "@eolas-medical/core";

const useOrganisation = () => {
  const organisation = sectionStore.data.organisation;

  return {
    organisation,
  };
};

export default useOrganisation;
