import React from "react";
import ReactDOM from "react-dom";
import { uniqueId } from "lodash";
import { AnimatePresence } from "framer-motion";

import { NotificationCard } from "./NotificationCard";
import { NotificationsContext } from "./NotificationsContext";
import { Notification, NotificationsContextProps } from "./types";

const NotificationsWrapper: React.FC = ({ children }) => {
  return ReactDOM.createPortal(
    <div
      className={`
      flex flex-col space-y-2 fixed left-4 sm:left-60vw lg:left-70vw top-20 right-4 z-50
      max-h-40vh overflow-y-auto overflow-x-hidden
    `}
    >
      <AnimatePresence>{children}</AnimatePresence>
    </div>,
    document.body,
  );
};

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  const showNotification: NotificationsContextProps["showNotification"] = React.useCallback(
    (notification: Notification) => {
      const newNotification = {
        ...notification,
        id: notification.id ?? uniqueId("notification_id_"),
        type: notification.type ?? "success",
      };

      setNotifications((notifications) => [newNotification, ...notifications]);

      return newNotification;
    },
    [],
  );

  const hideNotification: NotificationsContextProps["hideNotification"] = React.useCallback(
    (id) => {
      setNotifications((notifications) =>
        notifications.filter((notification) => notification.id !== id),
      );
    },
    [],
  );

  const updateNotification: NotificationsContextProps["updateNotification"] = React.useCallback(
    (notification) => {
      setNotifications((notifications) =>
        notifications.map((n) => (n.id === notification.id ? notification : n)),
      );
    },
    [],
  );

  const context: NotificationsContextProps = {
    notifications,
    showNotification,
    hideNotification,
    updateNotification,
  };

  return (
    <NotificationsContext.Provider value={context}>
      <NotificationsWrapper>
        {notifications.map((notification) => {
          return (
            <NotificationCard {...notification} onHide={hideNotification} key={notification.id} />
          );
        })}
      </NotificationsWrapper>
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.displayName = "NotificationsProvider";
