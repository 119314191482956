import { Button } from "UIKit";
import { ExpiringPeriod } from "modules/contentRepository/data/useExpiringContentItems";
import { useTranslation } from "react-i18next";

interface ExpiringPeriodFiltersProps {
  selectedPeriod: ExpiringPeriod;
  onClickFilter: (period: ExpiringPeriod) => void;
}
const ExpiringPeriodFilters = ({ selectedPeriod, onClickFilter }: ExpiringPeriodFiltersProps) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      <Button
        className="flex-1"
        onClick={() => onClickFilter(1)}
        variant={selectedPeriod === 1 ? "solid" : "outline"}
        color={selectedPeriod === 1 ? "blue" : "white"}
      >
        {t("expiredPage_1month_button_label")}
      </Button>

      <Button
        className="flex-1"
        onClick={() => onClickFilter(3)}
        variant={selectedPeriod === 3 ? "solid" : "outline"}
        color={selectedPeriod === 3 ? "blue" : "white"}
      >
        {t("expiredPage_3month_button_label")}
      </Button>

      <Button
        className="flex-1"
        onClick={() => onClickFilter(6)}
        variant={selectedPeriod === 6 ? "solid" : "outline"}
        color={selectedPeriod === 6 ? "blue" : "white"}
      >
        {t("expiredPage_6month_button_label")}
      </Button>
      <Button
        className="flex-1"
        onClick={() => onClickFilter(12)}
        variant={selectedPeriod === 12 ? "solid" : "outline"}
        color={selectedPeriod === 12 ? "blue" : "white"}
      >
        {t("expiredPage_12month_button_label")}
      </Button>
    </div>
  );
};

export default ExpiringPeriodFilters;
