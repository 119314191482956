import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { appConfig } from "AppTypeConfig";
import { Checkbox, FormElement } from "UIKit";

interface TermsAndCondProps {
  control: Control<{ termsAndCond: boolean; disclaimer: boolean }>;
  isLoading: boolean;
  onSubmit: () => void;
}

const TermsAndCondModal = ({ control, isLoading, onSubmit }: TermsAndCondProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-6 py-4" data-testid="terms-and-cond-modal">
      <p className="text-justify">{t("departmentTour_TC_modal_description")}</p>

      <form className="flex flex-col space-y-6" onSubmit={onSubmit}>
        <FormElement control={control} id="termsAndCond">
          {({ value, onChange }) => {
            return (
              <Checkbox
                id="tcCheck"
                data-testid="tcCheck"
                onChange={onChange}
                disabled={isLoading}
                checked={value || false}
              >
                {t("departmentTour_TC_read")}
                <a
                  className="underline ml-1"
                  href={appConfig.termlyTermsOfService}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("departmentTour_TC_confirmTC")}
                </a>
              </Checkbox>
            );
          }}
        </FormElement>
        <FormElement control={control} id="disclaimer">
          {({ value, onChange }) => {
            return (
              <Checkbox
                id="disclaimerCheck"
                data-testid="disclaimerCheck"
                onChange={onChange}
                disabled={isLoading}
                checked={value || false}
              >
                {t("departmentTour_TC_read")}
                <a
                  className="underline mx-1"
                  href={appConfig.termlyDisclaimer}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("departmentTour_TC_confirmDisclaimer")}
                </a>
                {t("departmentTour_TC_and")}
                <a
                  className="underline ml-1"
                  href={appConfig.termlyPrivacyPolicy}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("departmentTour_TC_confirmPrivacyPolicy")}
                </a>
              </Checkbox>
            );
          }}
        </FormElement>
      </form>
    </div>
  );
};

export default TermsAndCondModal;
