import { NotificationType, Space } from "@eolas-medical/core";
import { WebNotification } from "modules/webNotifications/types";
import { BaseAppRoute } from "Utilities/types";
import { removeBracketsAndPercent } from "Utilities/helpers";

export const hasToSingOutFromSpace = (notificationType: NotificationType) => {
  switch (notificationType) {
    case "accessRequestApproved":
    case "departmentAcceptedInvitation":
      return true;
    default:
      return false;
  }
};

export const hasToBeAdmin = (notificationType: NotificationType) => {
  switch (notificationType) {
    case "checklistComplete":
    case "checklistIssue":
    case "departmentAccessRequest":
      return true;
    default:
      return false;
  }
};

export const getRouteFromNotification = (notification: WebNotification, selectedSpace?: Space) => {
  const selectedSpaceNameURI = selectedSpace
    ? encodeURIComponent(removeBracketsAndPercent(selectedSpace.name) || "")
    : "";

  switch (notification.notificationType) {
    // Spaces Notifications
    case "accessRequestApproved":
    case "departmentAcceptedInvitation":
      return `${BaseAppRoute.selectSpace}`;
    case "departmentAccessRequest":
      return `${BaseAppRoute.spaces}/${encodeURIComponent(
        notification.metadata.departmentName,
      )}/manageUsers`;
    case "checklistComplete":
    case "checklistIssue":
      return `${
        BaseAppRoute.spaces
      }/${selectedSpaceNameURI}/content-management/checklists/${encodeURIComponent(
        notification.metadata.parentId,
      )}/details/${encodeURIComponent(notification.metadata.checklistId)}`;
    case "communicationPortal":
      return `${BaseAppRoute.organisation}/communicationPortal`;
    case "incidents":
      return `${BaseAppRoute.spaces}/${selectedSpaceNameURI}/incidents`;
    case "newsFeed":
      return `${BaseAppRoute.spaces}/${selectedSpaceNameURI}/newsFeed`;

    // Me tab Notifications
    case "myPortfolioWizard":
    case "myPortfolioFeedbackDeclined":
    case "myPortfolioFeedbackReceived":
      return `${
        BaseAppRoute.myProfile
      }/portfolio/my-portfolio/${notification.metadata.eventType.toLowerCase()}/${
        notification.metadata.eventId
      }`;
    case "myPortfolioFeedbackRequest":
      return `${
        BaseAppRoute.myProfile
      }/portfolio/feedback/${notification.metadata.eventType.toLowerCase()}/${
        notification.metadata.eventId
      }`;
    case "supervisorRequestAccepted":
    case "supervisorRequestDeclined":
    case "supervisorRemoved":
      return `${BaseAppRoute.myProfile}/portfolio/supervision/supervisors`;
    case "superviseeRemoved":
    case "supervisorRequest":
      return `${BaseAppRoute.myProfile}/portfolio/supervision/supervisees`;
    default:
      return BaseAppRoute.spaces;
  }
};
