import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, Link } from "react-router-dom";

import {
  fileStore,
  sectionStore,
  AppLevelSection,
  MajorIncidentType,
  MajorIncidentSubSection,
} from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { NavButton, PageTitle, InnerPageWrapper } from "UIKit";
import { MajorIncidentIcon, AttentionIcon, PaperIcon } from "Assets";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

const linkClass = `
  flex items-center space-x-4 bg-white rounded-lg p-4 h-32
  border-2 border-transparent hover:border-blue-100 shadow-xxs
`;

export const MajorIncidents: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  const { t } = useTranslation();

  useMainSectionVisit({ mainSectionId: AppLevelSection.majorIncidents });

  const { isAdmin } = useSpacesContext();
  const { spaceSection } = useSpaceSection(AppLevelSection.majorIncidents);
  const { id: incidentsSectionID } = sectionStore
    .getChildrenOrder(spaceSection.id)
    .find((child) => child?.icon === MajorIncidentSubSection.incidents)!;

  const incidentsChildrenOrder = sectionStore.getChildrenOrder(incidentsSectionID);

  const hasActiveIncidents = fileStore
    .getFiles(incidentsChildrenOrder)
    .some((incident) => incident.moreInfoTwo === MajorIncidentType.active);

  return (
    <InnerPageWrapper>
      <PageTitle title={spaceSection.name ?? t("incidents_page_title")} />
      <NavButton to={url.replace(`/${AppLevelSection.majorIncidents}`, "")} />

      <div className="flex flex-col space-y-4">
        <Link
          className={linkClass}
          data-testid="major-incidents"
          to={`${url}/${isAdmin ? "all" : "add"}`}
        >
          <MajorIncidentIcon height={48} width={48} className="mx-4 md:mx-8 w-12 h-12" />

          <div className="flex flex-col items-start flex-1">
            <h3 className="line-clamp-2 font-semibold">
              {t(
                isAdmin
                  ? "incidents_nav_tile_all_major_incidents"
                  : "incidents_nav_tile_activate_incident",
              )}
            </h3>
          </div>
          {hasActiveIncidents && (
            <AttentionIcon
              width={30}
              height={30}
              fill="red"
              className="text-red"
              data-testid="exclamationMarkIcon"
            />
          )}
        </Link>

        <Link data-testid="incident-documents" to={`${url}/documents`} className={linkClass}>
          <PaperIcon height={48} width={48} className="mx-4 md:mx-8 w-12 h-12" />

          <div className="flex flex-col items-start flex-1">
            <h3 className="line-clamp-2 font-semibold">
              {t("incidents_nav_tile_incident_documents")}
            </h3>
          </div>
        </Link>
      </div>
    </InnerPageWrapper>
  );
});
