import { appConfig } from "AppTypeConfig";
import {
  EventDescription,
  EventDto,
  EventForm,
  EventStatus,
  EventType,
  FeedbackForm,
  FormElement,
  FormElementType,
  FormValues,
} from "./types";
import _ from "lodash";

const getFormElementValue = (element: FormElement | EventDescription) => {
  if (
    element.value &&
    element.type === FormElementType.ATTACHMENTS &&
    typeof element.value === "string"
  ) {
    return JSON.parse(element.value as string);
  }

  return element.value;
};

export const mapEventForm = (eventForm: EventForm): EventForm => {
  const formattedElements = eventForm.formElements.map((e) => {
    return {
      ...e,
      value: getFormElementValue(e),
    };
  });

  return {
    ...eventForm,
    formElements: formattedElements,
    status: eventForm.status || EventStatus.IN_PROGRESS,
  };
};

export const mapFeedbackForm = (feedbackForm: FeedbackForm): FeedbackForm => {
  const formattedEventDescription = feedbackForm.eventDescription.map((e) => {
    return {
      ...e,
      value: getFormElementValue(e),
    };
  });

  return {
    ...feedbackForm,
    eventDescription: formattedEventDescription,
  };
};

export const createEventBody = (
  eventType: EventType,
  ownerId: string,
  formValues: FormValues,
  formElements: FormElement[],
  appType?: string,
) => {
  const componentIdsMap = _.keyBy(formElements, "fieldName");
  const { date, title, shareAttachments = false, assignAssessor, ...rest } = formValues;
  const eventBody: EventDto = {
    title,
    date,
    type: eventType,
    ownerId,
    areAttachmentsShared: shareAttachments,
    eventValues: Object.keys(rest).map((key) => {
      const id = componentIdsMap[key].id;
      const value = rest[key as keyof typeof rest];
      const type = componentIdsMap[key].type;

      const eventValue = {
        componentId: id,
        stringValue: type === FormElementType.STRING ? value : undefined,
        dateValue: type === FormElementType.DATE ? value : undefined,
        intValue: type === FormElementType.NUMERIC ? value : undefined,
        booleanValue: type === FormElementType.BOOLEAN ? value : undefined,
        attachmentsIds:
          type === FormElementType.ATTACHMENTS ? value.map((v: any) => v.id) : undefined,
      };
      return eventValue;
    }),
  };

  if (assignAssessor) {
    eventBody.eventRequest = {
      appType: appConfig.appType,
      userEmail: assignAssessor,
    };
  }
  return eventBody;
};

export const createFeedbackBody = (formValues: FormValues, formElements: FormElement[]) => {
  const componentIdsMap = _.keyBy(formElements, "fieldName");
  const feedbackValues = Object.keys(formValues)
    .filter((key) => !!formValues[key as keyof typeof formValues])
    .map((key) => {
      const id = componentIdsMap[key].id;
      const value = formValues[key as keyof typeof formValues];
      const type = componentIdsMap[key].type;

      const feedbackValue = {
        componentId: id,
        stringValue: type === FormElementType.STRING ? value : undefined,
        dateValue: type === FormElementType.DATE ? value : undefined,
        intValue: type === FormElementType.NUMERIC ? value : undefined,
        booleanValue: type === FormElementType.BOOLEAN ? value : undefined,
      };
      return feedbackValue;
    });
  return {
    feedbackValues,
  };
};
