import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";

import { AppLevelSection, sectionStore } from "@eolas-medical/core";
import { Section } from "@eolas-medical/core";

import { useReorder } from "Hooks";
import { noFilesFound } from "Assets";
import { INameable } from "Utilities/helpers";
import { useSortOrder } from "Hooks/useSortOrder";
import { fromAwsJSON, removeLastUrlPath } from "Utilities";
import { useParamsDecoded } from "Utilities/useParamsDecoded";

import {
  NavButton,
  AddButton,
  PageTitle,
  SortableList,
  LottieWithHeader,
  InnerPageWrapper,
} from "UIKit";

import { ChecklistTile } from "../components";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { getSpaceRoute } from "Pages/Spaces/helpers";

interface ChecklistSectionRouteParams {
  sectionID: string;
}

export const ChecklistSection: React.FC<
  RouteComponentProps<ChecklistSectionRouteParams>
> = observer(({ match: { url } }) => {
  const { sectionID } = useParamsDecoded<ChecklistSectionRouteParams>();
  const [SortComponent, { orderBy, sortMethod }] = useSortOrder({
    initialOrder: "dragAndDrop",
    sortDateBy: "createdAt",
    isDraggable: true,
  });

  const { selectedSpace } = useSpacesContext();

  const parentSection = sectionStore.getSection(sectionID);

  const { childrenOrder = [], parentID } = parentSection;

  const childrenReference = sectionStore.getChildrenOrder(parentID!);
  const { name } = childrenReference.find((childRef) => childRef!.id === sectionID)!;

  const { t } = useTranslation();

  const isAdmin = sectionStore.isAdmin;

  const { isDragDisabled, onDragEnd } = useReorder(sectionID);

  const checkLists =
    childrenOrder?.reduce<Array<Section & INameable>>((sections, item) => {
      if ((isAdmin && item) || (item && item.disabled !== "true")) {
        const section = sectionStore.getSection(item.id);
        const {
          checklistTemplate: { checklistName },
        } =
          typeof section.metadata === "string"
            ? fromAwsJSON(section?.metadata || '"{}"')
            : section.metadata;
        return [...sections, { ...section, name: checklistName }];
      }
      return sections;
    }, []) ?? [];

  const orderedChecklists = sortMethod ? checkLists.slice().sort(sortMethod) : checkLists ?? [];

  return (
    <InnerPageWrapper>
      <PageTitle title={name!} />

      <NavButton to={removeLastUrlPath(url)} />

      {isAdmin && (
        <Link
          to={`${getSpaceRoute(selectedSpace)}/content-management/${
            AppLevelSection.checklists
          }/${encodeURIComponent(sectionID)}/builder`}
          className="self-center"
        >
          <AddButton>{t("checklists_add_new_checklist")}</AddButton>
        </Link>
      )}

      {isAdmin && orderedChecklists.length >= 2 && (
        <span className="text-center text-grey-mid">{t("contentManagement_info")}</span>
      )}

      {orderedChecklists.length > 1 && SortComponent}

      {orderedChecklists?.length ? (
        <SortableList
          onDragEnd={onDragEnd}
          droppableId="checklist-list"
          isDragDisabled={isDragDisabled || orderBy !== "dragAndDrop"}
        >
          {orderedChecklists.map((item) => (
            <ChecklistTile url={url} key={item.id} checklist={item} isAdmin={isAdmin} />
          ))}
        </SortableList>
      ) : (
        <LottieWithHeader
          animation={noFilesFound}
          lottieOptions={{ loop: true }}
          text={t("checklist_noChecklistsFound")}
        />
      )}
    </InnerPageWrapper>
  );
});
