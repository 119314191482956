import { AuthFunctionNames, authenticationClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { errorStore } from "Stores/ErrorStore";

const useResendVerificationCode = () => {
  const { mutate, isLoading, error, isSuccess } = useMutation(
    (email: string) => authenticationClient.resendSignUp({ email }),
    {
      onError: (error: string) => {
        errorStore.captureError({
          error,
          source: "user",
          authFunctionName: AuthFunctionNames.resendConfirmationCode,
        });
      },
    },
  );

  return {
    resendVerificationCode: mutate,
    resendingVerificationCode: isLoading,
    resendVerificationCodeError: error,
    resendVerificationCodeSuccess: isSuccess,
  };
};

export default useResendVerificationCode;
