import { useCallback, useEffect, useMemo } from "react";
import { SearchResult, SearchType } from "../types";

import useMeSearch from "./useMeSearch";
import useKnowledgeSearch from "./useKnowledgeSearch";
import useLocalSearch from "./useLocalSearch";
import useMasterSearchContext from "../context/useMasterSearchContext";
interface UseMasterSearchProps {
  userId: string;
  searchType: SearchType;
  knowledgeSearchDisabled: boolean;
}

interface UseMasterSearch {
  searchInput: string;
  searchValue: string;
  searchResult: SearchResult[];
  searchLoading: boolean;
  onSearchInputChange: (input: string) => void;
  onSearch: (searchText?: string) => void;
  onClearSearch: () => void;
}

const useMasterSearch = ({
  userId,
  searchType,
  knowledgeSearchDisabled,
}: UseMasterSearchProps): UseMasterSearch => {
  const { onSaveSearch } = useMasterSearchContext();
  const {
    searchInput,
    searchValue,
    knowledgeFiles,
    knowledgeFilesLoading,
    onSearchCommunity,
    onSetSearchInput,
    onClearSearch,
  } = useKnowledgeSearch(knowledgeSearchDisabled);

  const { meFiles, meFilesLoading, onSearchMeFiles } = useMeSearch({ userId });

  const { localFiles, localFilesLoading, onSearchLocalFiles } = useLocalSearch();

  useEffect(() => {
    onSearchMeFiles(searchValue);
    onSearchLocalFiles(searchValue);
    onSaveSearch(searchValue);
  }, [searchValue, onSearchMeFiles, onSearchLocalFiles, onSaveSearch]);

  const handleSearch = useCallback(
    (searchText?: string) => {
      onSearchCommunity(searchText);
    },
    [onSearchCommunity],
  );

  const handleSearchInputChange = useCallback(
    (text: string) => {
      onSetSearchInput(text);
    },
    [onSetSearchInput],
  );

  const handleClearSearch = useCallback(() => {
    onClearSearch();
  }, [onClearSearch]);

  const searchResult = useMemo(() => {
    if (searchValue.length < 2) return [];

    switch (searchType) {
      case "all":
        return [...localFiles, ...knowledgeFiles, ...meFiles];
      case "community":
        return knowledgeFiles;
      case "me":
        return meFiles;
      case "local":
        return localFiles;
      default:
        return [];
    }
  }, [searchValue, searchType, knowledgeFiles, meFiles, localFiles]);

  return {
    searchInput: searchInput,
    searchValue: searchValue,
    searchResult: searchResult,
    searchLoading: knowledgeFilesLoading || meFilesLoading || localFilesLoading,
    onSearchInputChange: handleSearchInputChange,
    onSearch: handleSearch,
    onClearSearch: handleClearSearch,
  };
};

export default useMasterSearch;
