import * as yup from "yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { MedusaStatus, hasProp, sectionStore } from "@eolas-medical/core";

import { parseMedusaMetadata, useRequestStatus } from "Utilities";
import { useEnableMedusaMutation } from "Hooks/graphql/enableMedusa.generated";
import { useDisableMedusaMutation } from "Hooks/graphql/disableMedusa.generated";
import { UPDATE_SECTION_INFO } from "Pages/Spaces/components/SectionManagement";

const Regexp = new RegExp(
  /((medusa\.wales\.nhs\.uk\/.*\?ID=(([\s\S]{16,36})$|([\s\S]{16,36})&))|(^[a-f0-9]{16,36}$))/i,
);

const validationSchema = yup.object({
  url: yup.string().required().matches(Regexp, { message: "Incorrect url format" }),
});

const validationSchemaInfo = yup.object({
  url: yup.string().required().matches(Regexp, { message: "Incorrect url format" }),
  description: yup.string().required(),
  name: yup.string().required(),
});

export interface DefaultValues {
  name?: string | null;
  description?: string | null;
  url?: string;
}

export function useMedusa(defaultValues: DefaultValues = {}, callback?: () => void) {
  const { isLoading, requestStatus, isSuccessful, setRequestStatus } = useRequestStatus();
  const [enableMedusa] = useEnableMedusaMutation();
  const [updateSectionInfo] = useMutation(UPDATE_SECTION_INFO);
  const [disableMedusa] = useDisableMedusaMutation();

  const mainSection = useMemo(() => {
    return (
      sectionStore
        .getMainSectionList("hospital", (child) => child.icon === "medusaMedications")
        .find(({ icon }) => icon === "medusaMedications") || { id: "" }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus]);
  const section = sectionStore.getSection(mainSection.id);

  const medusaData = useMemo(() => {
    if (typeof section.metadata === "string") {
      return parseMedusaMetadata(section.metadata);
    }

    if (section.metadata?.medusa) {
      return section.metadata.medusa;
    }

    return null;
  }, [section]);

  const { control, handleSubmit, ...formParams } = useForm({
    defaultValues: {
      ...defaultValues,
      url: medusaData ? medusaData.apiKey : undefined,
    },
    resolver: yupResolver(
      defaultValues.name || defaultValues.description ? validationSchemaInfo : validationSchema,
    ),
  });

  const onSubmit = handleSubmit(async (input) => {
    const id = getApiKeyFromUrl(input.url);
    let error: string = "";
    let response: unknown = null;
    try {
      if (id && input.url !== medusaData?.apiKey) {
        setRequestStatus({ error: "", status: "pending" });
        const { data, errors } = await enableMedusa({
          variables: {
            apiKey: id,
            sectionId: mainSection.id,
            organisationId: sectionStore.organisationID,
          },
        });

        if (errors && errors[0]) {
          error = errors[0].message;
        }

        if (data && !errors) {
          response = data;
          formParams.setValue("url", id);
        }
      }

      if (input.name !== defaultValues.name || input.description !== defaultValues.description) {
        setRequestStatus({ error: "", status: "pending" });
        const { data, errors } = await updateSectionInfo({
          variables: {
            input: { id: mainSection.id, name: input.name, description: input.description },
            parentID: section.ownerID,
          },
        });

        if (errors && errors[0]) {
          error = errors[0].message;
        }

        if (data && !errors) {
          response = data;
        }
      }
    } finally {
      if (error) {
        setRequestStatus({ error, status: "error" });
      }

      if (response && !error) {
        setRequestStatus({ error: "", status: "success" });
      }

      if (!error && callback) {
        return callback();
      }
    }
  });

  const onDisable = async (disableCallback?: () => void) => {
    setRequestStatus({ error: "", status: "pending" });
    const { data, errors } = await disableMedusa({
      variables: {
        sectionId: mainSection.id,
      },
    });

    if (errors && errors[0]) {
      setRequestStatus({ error: errors[0].message, status: "error" });
    }

    if (data && !errors) {
      setRequestStatus({ error: "", status: "success" });
      formParams.setValue("url", "");
      if (disableCallback) {
        return disableCallback();
      }
    }
  };

  return {
    ...formParams,
    isLoading,
    isActive: medusaData?.status === "enabled",
    status: (medusaData?.status || "disabled") as MedusaStatus,
    requestStatus: requestStatus.status,
    organisationId: medusaData?.apiKey || "",
    error: medusaData?.errorMessage || requestStatus.error,
    control,
    cached: hasProp(medusaData, "cached") ? medusaData.cached : false,
    isSuccessful,
    onSubmit,
    onDisable,
  };
}

const getApiKeyFromUrl = (url: string = "") => {
  const match = url.match(Regexp);
  if (match) {
    return (
      match.find((value) => typeof value === "string" && value.match(/^[a-f0-9]{16,36}$/)) || ""
    );
  }
  return "";
};
