import useDeleteFile from "modules/myFiles/data/useDeleteFile";
import { useTranslation } from "react-i18next";
import { Modal, DeleteFileModal } from "UIKit";

interface EditFileModalProps {
  handleClose(): void;
  open: boolean;
  fileId: string;
}

export const DeleteMyFileModal = ({ handleClose, open, fileId }: EditFileModalProps) => {
  const { t } = useTranslation();
  const { deleteFile, deletingFile, deleteFileError, deleteFileSuccessful } = useDeleteFile(fileId);

  return (
    <Modal data-testid="delete-file-modal" open={open} onClose={handleClose}>
      <DeleteFileModal
        title={t("my_files_delete_file_title")}
        description={t("my_files_delete_file_description")}
        onDelete={deleteFile}
        onCloseModal={handleClose}
        isLoading={deletingFile}
        error={deleteFileError}
        isSuccessful={deleteFileSuccessful}
      />
    </Modal>
  );
};
