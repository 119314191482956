import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { error401, MailIcon, PadlockIcon } from "Assets";

import {
  InnerModalWrapper,
  Button,
  FormElement,
  Input,
  LottieWithHeader,
  Title,
  Text,
  ModalBody,
} from "UIKit";

import useSignInUser from "modules/authentication/data/useSignInUser";

export interface SoftSignOutModalProps {
  email: string;
}

const SoftSignOutModal: React.FC<SoftSignOutModalProps> = ({ email }) => {
  const { t } = useTranslation();
  const { signInUser, signInError, isSigningIn } = useSignInUser();

  const schema = yup.object().shape({
    password: yup.string().required(t("login_requiredPassword")),
  });

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitPassword = async () => {
    const password = getValues("password");

    signInUser({ email, password });
  };

  return (
    <InnerModalWrapper>
      <ModalBody>
        <LottieWithHeader
          animationSize="50%"
          text=""
          animation={error401}
          lottieOptions={{ loop: true }}
        />
        <div className="flex flex-col items-center text-center mb-8">
          <Title level={5}>{t("session_expired_title_admin")}</Title>
          <Text level={1} className="text-grey-700">
            {t("session_expired_log_back_in")}
          </Text>
        </div>
        <div data-testid="validate-password-modal" className="w-full p-">
          <form
            onSubmit={handleSubmit(handleSubmitPassword)}
            className="flex flex-col justify-center space-y-7"
          >
            <div className="space-y-5">
              <div className="flex items-center space-x-4 ml-3">
                <MailIcon className="w-5 h-5" />
                <Text level={2}>{email}</Text>
              </div>
              <FormElement id="password" control={control} errorMessage={signInError}>
                {(field) => (
                  <Input
                    {...field}
                    autoFocus
                    iconLeft={<PadlockIcon />}
                    placeholder={t("login_enterPassword")}
                    type="password"
                    data-testid="validate-password"
                  />
                )}
              </FormElement>
            </div>

            <Button
              size="lg"
              type="submit"
              data-testid="sign-in-sign-in-button"
              className="self-center w-full md:w-1/2"
              isLoading={isSigningIn}
            >
              {t("login_login")}
            </Button>
          </form>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default SoftSignOutModal;
