import { Route } from "react-router-dom";
import { AppLevelSection } from "@eolas-medical/core";

import { Checklists, ChecklistBuilder, ChecklistSection, CompletedChecklists } from ".";
import { BaseAppRoute } from "Utilities/types";

export type AdminChecklistRoute =
  | "checklists"
  | "checklistSection"
  | "checklistDetails"
  | "checklistTemplateBuilder"
  | "checklistTemplateEditor";

const baseRouteSlug = `/${BaseAppRoute.spaces}/:spaceName/content-management/${AppLevelSection.checklists}`;

const adminChecklistRoutes: Record<AdminChecklistRoute, string> = {
  checklists: baseRouteSlug,
  checklistSection: `${baseRouteSlug}/:sectionID`,
  checklistDetails: `${baseRouteSlug}/:sectionID/details/:checklistID`,
  checklistTemplateBuilder: `${baseRouteSlug}/:sectionID/builder`,
  checklistTemplateEditor: `${baseRouteSlug}/:sectionID/builder/:checklistID`,
};

export const ChecklistsAdminRoutes = () => {
  return (
    <>
      <Route exact path={adminChecklistRoutes.checklists} component={Checklists} />

      <Route exact path={adminChecklistRoutes.checklistSection} component={ChecklistSection} />

      <Route exact path={adminChecklistRoutes.checklistDetails} component={CompletedChecklists} />

      <Route
        exact
        component={ChecklistBuilder}
        path={adminChecklistRoutes.checklistTemplateBuilder}
      />

      <Route
        exact
        component={ChecklistBuilder}
        path={adminChecklistRoutes.checklistTemplateEditor}
      />
    </>
  );
};
