import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect } from "react";

import { MailIcon, PadlockIcon } from "Assets/Icons";
import { repoConfig } from "AppTypeConfig";
import { Button, PageTitle, FormElement, Footer, Input, Modal } from "UIKit";

import { useLogin } from "../hooks";
import { ForgotPasswordModal, ServicesStatusAlertModal, VerifyEmailModal } from "../componentos";
import { AnalyticsEvents, sectionStore, userStore } from "@eolas-medical/core";
import { Sentry } from "Contexts";
import { SoftLogoutModal } from "../componentos/SoftLogoutModal";
import { servicesStatusCheck } from "Utilities";
import { storeClearup } from "../auth.actions";
import { trackEvent } from "API/Analytics";
import { useAppSync } from "Hooks";

const { commitId, version } = repoConfig;

export const Login = () => {
  const { t } = useTranslation();
  const { sync } = useAppSync();
  const { state } = useLocation<{ userEmail?: string }>();

  const {
    accessLevel,
    userSession: { isInOrganisation, isLoggedIn },
    appUserID,
    userData: { email },
  } = userStore;
  const { organisationID } = sectionStore;
  const { control, onSubmit, requestStatus, isFederatedDomain } = useLogin(state?.userEmail);
  const [modal, setModal] = React.useState<React.ReactNode>(null);

  const openModal = useCallback(
    (component: React.ReactNode) => () => {
      setModal(component);
    },
    [setModal],
  );

  const closeModal = useCallback(async () => {
    if (accessLevel !== "admin" && userStore.userID) {
      await sync();
    } else {
      storeClearup();
    }
    setModal(null);
  }, [accessLevel, sync]);

  useEffect(() => {
    if (!isInOrganisation) {
      // tracks initial login page load
      trackEvent(AnalyticsEvents.EOLAS_SESSION_START);
    }
  }, [isInOrganisation]);

  useEffect(() => {
    if (!isLoggedIn && appUserID && organisationID && email) {
      setModal(<SoftLogoutModal onClose={closeModal} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    servicesStatusCheck().then((allServicesResonded) => {
      if (!allServicesResonded) {
        Sentry.captureException("DOMAIN BLOCKED BY NETWORK");
        setModal(<ServicesStatusAlertModal />);
      }
    });
  }, []);

  const loginTitle = t("login_login");
  const loginSubtitle = "";

  return (
    <>
      <div className="h-screen flex flex-col bg-grey-50">
        <div className="flex flex-col flex-1 items-center justify-start pt-8 md:pt-0 md:justify-center">
          <PageTitle className="mb-8" subTitle={loginSubtitle} title={loginTitle} />

          <form onSubmit={onSubmit} className="flex flex-col self-center p-4 w-full md:w-1/2">
            <FormElement id="email" control={control} className="mb-4">
              {(field) => (
                <Input
                  {...field}
                  size="lg"
                  iconLeft={<MailIcon />}
                  placeholder={t("login_enterEmail")}
                  data-testid="sign-in-username-input"
                />
              )}
            </FormElement>

            {!isFederatedDomain && (
              <FormElement id="password" control={control} className="mb-4">
                {(field) => (
                  <Input
                    {...field}
                    size="lg"
                    type="password"
                    iconLeft={<PadlockIcon />}
                    data-testid="sign-in-password-input"
                    placeholder={t("login_enterPassword")}
                  />
                )}
              </FormElement>
            )}

            {!isFederatedDomain ? (
              <Button
                size="lg"
                type="submit"
                data-testid="sign-in-sign-in-button"
                className="self-center w-full md:w-1/2"
                isLoading={requestStatus.status === "pending"}
              >
                {t("login_login")}
              </Button>
            ) : (
              <Button
                size="lg"
                color="grey"
                iconLeft="Key"
                variant="outline"
                type="submit"
                data-testid="sign-in-sign-in-button"
                className="self-center w-full md:w-auto"
                isLoading={requestStatus.status === "pending"}
              >
                <span className="ml-3">{t("login_login_sso")}</span>
              </Button>
            )}

            <span
              className={`h-4 text-sm text-red text-center my-2 ${
                requestStatus.status !== "error" && "invisible"
              }`}
            >
              {requestStatus.error}
            </span>
          </form>

          <div className="flex flex-col items-center space-y-2 px-4 lg:px-0 w-full md:w-1/2">
            <Link
              to={(location) => ({ ...location, pathname: "/signup" })}
              className="w-full md:w-1/2"
            >
              <Button
                size="lg"
                color="grey"
                variant="link"
                weight="regular"
                className="no-underline w-full"
                data-testid="sign-in-create-account"
              >
                {t("login_createAccount")}
              </Button>
            </Link>

            <Button
              size="lg"
              color="grey"
              variant="link"
              weight="regular"
              className="no-underline w-full md:w-1/2"
              data-testid="sign-in-forgot-password"
              onClick={openModal(<ForgotPasswordModal closeModal={closeModal} />)}
            >
              {t("login_forgotPassword")}
            </Button>

            <Button
              size="lg"
              color="grey"
              variant="link"
              weight="regular"
              className="no-underline w-full md:w-1/2"
              data-testid="login-verifyEmail-button"
              onClick={openModal(<VerifyEmailModal closeModal={closeModal} />)}
            >
              {t("login_verifyEmail")}
            </Button>
          </div>
        </div>
        <Footer commitId={commitId} version={version} />
      </div>

      <Modal open={!!modal} onClose={closeModal} shouldCloseOnOverlayClick>
        {modal}
      </Modal>
    </>
  );
};
