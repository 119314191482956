import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  EolasFile,
  sectionStore,
  AppLevelSection,
  ChecklistRecord,
  EolasMainSection,
  OrganisationLevelSection,
  CommunityLevelSection,
} from "@eolas-medical/core";

import { VideoModal } from "UIKit";
import { BaseAppRoute } from "Utilities/types";
import { useSelectFile, useCompleteFile } from "Hooks";

import { ClinicalCalculatorsSearchResult } from "./types";
import { spaceRoutes } from "Pages/Spaces/pages/Space/SpaceRoutes";
import { ContactDetailsModal } from "Pages/Spaces/pages/Space/pages/MiniApp/Contacts/components";
import { PatientLeafletQRModal } from "Pages/Spaces/pages/Space/pages/MiniApp/PatientLeaflets/components";
import { HospitalPreviewModal } from "Pages/Spaces/pages/Space/pages/MiniApp/Hospitals/components";
import { SimpleSectionFileModal } from "Pages/Spaces/pages/Space/pages/SimpleSections/components";
import { NewsItemViewModal } from "Pages/Spaces/components/NewsItems";
import { useMedusaFile } from "Pages/Spaces/pages/Space/pages/MiniApp/MedusaMedications/useMedusaFile";
import { BlobTypes } from "modules/generic.types";
interface Options {
  from?: "masterSearch" | "notificationCenter";
}

export const useSelectFileModal = (options: Options = {}) => {
  const { push } = useHistory();
  const { onSelectFile: selectFile } = useSelectFile();
  const [modal, setModal] = useState<React.ReactNode>(null);
  const { onSelectMedusaFile } = useMedusaFile();
  const { onCompleteFile } = useCompleteFile();

  const onCloseModal = () => setModal(null);

  // TODO: remove this redirect when having new clinical calculators screen.
  const onSelectClinicalCalculator = (item: ClinicalCalculatorsSearchResult | EolasFile) => {
    const section = sectionStore.getSection(item.id!);

    push(`${BaseAppRoute.knowledge}/clinicalCalculators/${section.type}/${item.id}`, {
      from: options.from,
    });
    return;
  };

  const onSelectChecklist = (checklist: ChecklistRecord) => {
    const { id } = sectionStore.getChildReferenceOfSection(checklist.parentID!)!;
    push(
      `${BaseAppRoute.spaces}/checklists/${encodeURIComponent(id)}/details/${encodeURIComponent(
        checklist.id,
      )}`,
      {
        from: options.from,
      },
    );
    return;
  };

  const onSelectFile = (file: EolasFile) => {
    const mainSectionType: EolasMainSection = sectionStore.getMainSectionTypeFromMainSectionID(
      file.mainSectionID!,
    )!;

    if (mainSectionType === AppLevelSection.meetTheTeam) {
      push(spaceRoutes.meetTheTeam, {
        from: options.from,
      });
      return;
    }

    if (
      mainSectionType === AppLevelSection.contacts ||
      mainSectionType === OrganisationLevelSection.hospitalContacts
    ) {
      setModal(<ContactDetailsModal contact={file} />);
      return;
    }

    if (mainSectionType === AppLevelSection.patientLeaflets) {
      setModal(<PatientLeafletQRModal file={file} />);
      return;
    }

    if (mainSectionType === AppLevelSection.hospitalList) {
      setModal(<HospitalPreviewModal hospital={file} />);
      return;
    }

    if (mainSectionType === AppLevelSection.newsFeed) {
      setModal(<NewsItemViewModal newsItem={file} />);
      return;
    }

    if (
      mainSectionType === AppLevelSection.howTo ||
      mainSectionType === AppLevelSection.equipmentLocations
    ) {
      setModal(<SimpleSectionFileModal file={file} />);
      return;
    }

    if (mainSectionType === OrganisationLevelSection.medusaMedications) {
      onSelectMedusaFile(file);
      return;
    }

    if (mainSectionType === AppLevelSection.educationPortal) {
      onCompleteFile(file.id, "seen");
    }

    if (mainSectionType === AppLevelSection.checklists) {
      onSelectChecklist(file);
    }

    if (mainSectionType === CommunityLevelSection.clinicalCalculators) {
      onSelectClinicalCalculator(file);
    }

    if (mainSectionType === CommunityLevelSection.communityPatientLeaflets) {
      setModal(<PatientLeafletQRModal file={file} />);
      return;
    }

    if (file.type === "mp4") {
      selectFile(file).then((url) => {
        setModal(<VideoModal file={file} videoURL={url} />);
      });
      return;
    }

    if (file.type && file.type.includes(file.type as BlobTypes)) {
      selectFile(file);
    }
  };

  return { modal, onCloseModal, onSelectFile, onSelectChecklist, onSelectClinicalCalculator };
};
