import { Step } from "UIKit/Wizard";
import { useCallback, useMemo, useState } from "react";
import { FlashcardHeroImageStep } from "./FlashcardHeroImgStep";

interface UseFlashcardHeroImageStepProps {
  title: string;
  onSubmit: (image: { image?: File; imageUrl?: string }) => void;
  errorDraggedImageText: string;
  initialImage?: string;
}

export const useFlashcardHeroImageStep = ({
  title,
  onSubmit,
  initialImage,
  errorDraggedImageText,
}: UseFlashcardHeroImageStepProps): Step<"flashcard-hero-image"> => {
  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>(initialImage ?? "");

  const handleSubmitFlashcardImage = async () => {
    onSubmit({ image, imageUrl });
  };

  const handleSubmitImage = (file: File | null) => {
    if (file) {
      setImage(file);
    }
  };

  const handleSelectImage = useCallback((imageUrl: string) => {
    setImageUrl(imageUrl);
  }, []);

  const flashcardHeroImageStepComponent = useMemo(
    () => (
      <FlashcardHeroImageStep
        errorDraggedImageText={errorDraggedImageText}
        onChangeBlob={handleSubmitImage}
        onChangeImageUrl={handleSelectImage}
        defaultImage={imageUrl}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorDraggedImageText, handleSelectImage],
  );

  return {
    name: "flashcard-hero-image",
    title,
    canProceed: true,
    onSubmit: handleSubmitFlashcardImage,
    component: flashcardHeroImageStepComponent,
  };
};
