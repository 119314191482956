import { useLocation } from "react-router-dom";
import { useQueryParams } from "Hooks/useQueryParams";

const useCallbackUrl = () => {
  const { pathname } = useLocation();
  const queryParams = useQueryParams();

  const isNewUser = queryParams.get("isNewUser");
  const userEmail = queryParams.get("userEmail");

  queryParams.delete("userEmail");
  queryParams.delete("isNewUser");

  const queryParamString = queryParams.toString();
  const queryString = queryParamString ? `?${queryParamString}` : "";

  const callbackUrl = pathname !== "/" ? `callbackUrl=${pathname}${queryString}` : "";

  return {
    isNewUser: isNewUser === "true",
    userEmail,
    callbackUrl,
  };
};

export default useCallbackUrl;
