import { AnalyticsEvents } from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { trackEvent } from "API/Analytics";
import { FileUpdateDto, MyFile } from "modules/myFiles/types";
import { myFilesService } from "../client/myFiles.client";
import myFilesKeys from "./myFiles.queryKeys";

const useEditFile = () => {
  const queryClient = useQueryClient();
  let changesFavorite = false;

  const { mutate, isLoading, isSuccess, error, reset } = useMutation<MyFile, Error, FileUpdateDto>(
    async (dto) => {
      if ("isFavourite" in dto) changesFavorite = true;
      return myFilesService.updateFile(dto);
    },
    {
      onSuccess: (newFile) => {
        trackEvent(
          changesFavorite
            ? AnalyticsEvents.MYFILES_FILE_FAVOURITE
            : AnalyticsEvents.MYFILES_FILE_EDIT,
          {
            fileId: newFile.id,
            newIsFavouriteStatus: newFile.isFavourite,
          },
        );

        queryClient.setQueryData(myFilesKeys.allFilesList(), (actualList: any) => {
          return actualList.map((item: any) => {
            if (item.id === newFile.id) {
              return newFile;
            }
            return item;
          });
        });
      },
    },
  );

  return {
    editFile: mutate,
    editingFile: isLoading,
    editFileSuccessful: isSuccess,
    editFileError: error?.message,
    reset,
  };
};

export default useEditFile;
