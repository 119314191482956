import React from "react";
import { Text } from "UIKit/Typography";
import { overrideTailwindClasses as tw } from "tailwind-override";

export interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  className?: string;
  isValid?: boolean;
  characterLimit?: number;
  "data-testid"?: string;
}

export const Textarea = React.forwardRef<any, TextareaProps>(
  ({ className = "", isValid, characterLimit, "data-testid": testId, ...props }, ref) => {
    const charCount = props.value ? String(props.value).length : 0;
    const isLimitReached = characterLimit && charCount >= characterLimit;

    return (
      <div className="relative">
        <textarea
          ref={ref}
          data-testid={testId}
          className={tw(`
            text-base resize h-32 bg-white rounded-md p-2 w-full
            border ${isLimitReached ? "input-error" : "border-grey-300"}
            ${className}
          `)}
          {...props}
        />
        {characterLimit && (
          <Text
            level={3}
            className={`absolute right-0 pr-2 ${isLimitReached ? "text-red-500" : "text-grey-600"}`}
            style={{ bottom: -20 }}
          >
            {charCount}/{characterLimit}
          </Text>
        )}
      </div>
    );
  },
);
