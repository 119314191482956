import { ProfileVerificationStatus } from "@eolas-medical/core";
import { PendingIcon, UnverifiedIcon, VerifiedIcon } from "Assets";
import { Pill } from "UIKit/Pill";
import { ProfileStatusLabel } from "./types";

export const getVerificationStatusIcon = (status: ProfileVerificationStatus) => {
  switch (status) {
    case ProfileVerificationStatus.UNVERIFIED:
      return <UnverifiedIcon />;
    case ProfileVerificationStatus.PENDING:
      return <PendingIcon />;
    case ProfileVerificationStatus.VERIFIED:
      return <VerifiedIcon />;
    default:
      return <UnverifiedIcon />;
  }
};

export const getVerificationStatusPill = (
  status: ProfileVerificationStatus = ProfileVerificationStatus.UNVERIFIED,
) => {
  let icon, className;

  if (status === "UNVERIFIED") {
    icon = <UnverifiedIcon />;
    className = "text-red-500 bg-red-50 text-xs w-32 mx-auto font-bold";
  } else if (status === "PENDING") {
    icon = <PendingIcon />;
    className = "text-yellow-500 bg-yellow-100 text-xs w-40 mx-auto font-bold";
  } else if (status === "VERIFIED") {
    icon = <VerifiedIcon />;
    className = "text-green-500 bg-green-50 text-xs w-24 mx-auto font-bold";
  }

  return <Pill value={ProfileStatusLabel[status]} icon={icon} className={className} />;
};
