import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SpaceUpdateDto, spacesClient } from "@eolas-medical/core";
import spacesKeys from "./spaces.queryKeys";

interface UpdateSpaceProps {
  spaceId: string;
  spaceDto: SpaceUpdateDto;
}

const useUpdateSpace = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error, isSuccess } = useMutation(
    async ({ spaceId, spaceDto }: UpdateSpaceProps) => {
      return spacesClient.updateSpace(spaceId, spaceDto);
    },
    {
      onSuccess: (_data, space) => {
        queryClient.refetchQueries(spacesKeys.detail(space.spaceId));
      },
    },
  );

  return {
    updateSpace: mutate,
    updatingSpace: isLoading,
    errorUpdateSpace: error,
    updateSpaceSuccess: isSuccess,
  };
};

export default useUpdateSpace;
