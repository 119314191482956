import WebViewer from "@pdftron/webviewer";
import { useTranslation } from "react-i18next";
import { AnalyticsEvents, AppLevelSection, EolasMainSection } from "@eolas-medical/core";
import { useEffect, useState, useRef, useCallback } from "react";

import { trackEvent } from "API/Analytics";
import { errorStore } from "Stores/ErrorStore";
import { getPDFUrl } from "Hooks/useDocumentCache";
import { useNotifications, Notification } from "Components/Notifications";
import { generateS3PublicImageURL } from "Utilities";

export const useFileViewer = (fileKey: string, mainSection?: EolasMainSection) => {
  const key = decodeURIComponent(fileKey);

  const { t } = useTranslation();
  const notificationRef = useRef<Notification>();
  const viewer = useRef<HTMLDivElement>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const { showNotification, hideNotification, updateNotification } = useNotifications();

  const onPDFError = useCallback(
    (e: any) => {
      updateNotification({
        type: "error",
        id: notificationRef.current!.id,
        description: t("fileViewer_error"),
      });
      errorStore.captureError({ error: e.message, source: "user" });
    },
    [t, updateNotification],
  );

  const onPDFLoaded = useCallback(() => {
    hideNotification(notificationRef.current!.id);
  }, [hideNotification]);

  const onViewerLoadError = useCallback(() => {
    updateNotification({
      type: "error",
      id: notificationRef.current!.id,
      description: t("fileViewer_load_error"),
    });
  }, [t, updateNotification]);

  useEffect(() => {
    const getAllPdfUrls = async (fileKey: string, mainSection?: EolasMainSection) => {
      if (mainSection === AppLevelSection.patientLeaflets) {
        return generateS3PublicImageURL(fileKey);
      }
      return getPDFUrl(fileKey);
    };
    if (key) {
      notificationRef.current = showNotification({
        type: "loading",
        description: t("fileViewer_opening_pdf"),
      });
      trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_STARTED);
      getAllPdfUrls(key, mainSection)
        .then((res) => {
          setPdfUrl(res);
          trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_SUCCESS);
        })
        .catch((e) => {
          onPDFError(e);
          trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_ERROR);
        });
    }
  }, [key, mainSection, t, showNotification, onPDFError]);

  useEffect(() => {
    if (!pdfUrl) return;

    WebViewer(
      {
        path: "/pdftron",
        licenseKey: process.env.REACT_APP_PDF_TRON,
        initialDoc: pdfUrl,
        disabledElements: ["ribbons", "toolsHeader", "toggleNotesButton", "selectToolButton"],
      },
      viewer.current as HTMLDivElement,
    )
      .then(({ UI, Core }) => {
        UI.addEventListener(UI.Events.DOCUMENT_LOADED, onPDFLoaded);
        UI.addEventListener(UI.Events.LOAD_ERROR, onViewerLoadError);
        Core.disableEmbeddedJavaScript();
      })
      .catch(onPDFError);

    return () => {
      hideNotification(notificationRef.current!.id);
    };
  }, [pdfUrl, onPDFError, onPDFLoaded, onViewerLoadError, hideNotification]);

  return { viewer, pdfUrl, showNotification, onPDFLoaded, onPDFError };
};
