import { useState, useEffect } from "react";
import { inviteClient, userStore } from "@eolas-medical/core";

import { useParamsDecoded } from "Utilities/useParamsDecoded";

import { InvitationPayload } from "../../types";

type InvitationWithDetails = Omit<InvitationPayload, "invitationID">;
type InvitationStatus = "Accepted" | "SignUp" | "Login" | "NotLoaded" | "Error";
type InvitationWithStatus = { status: InvitationStatus };

function isInvitationStatus(
  invitation: InvitationWithDetails | InvitationWithStatus,
): invitation is InvitationWithStatus {
  return invitation && "status" in invitation;
}

type AcceptInvitationStatus = {
  error?: string;
  status: InvitationStatus;
  invitationDetails: InvitationPayload;
};

export const useAcceptInvitation = () => {
  const { token } = useParamsDecoded<{ token: string }>();

  const [acceptInvitationState, setAcceptInvitationState] = useState<AcceptInvitationStatus>({
    status: "NotLoaded",
    invitationDetails: { appID: "", email: "", invitationID: token, userID: "" },
  });

  useEffect(() => {
    userStore.setInviteId(token);
    inviteClient
      .getDetails(token)
      // @ts-ignore
      .then((invitationInfo: InvitationWithDetails | InvitationWithStatus) => {
        if (isInvitationStatus(invitationInfo)) {
          setAcceptInvitationState((prevState) => ({
            ...prevState,
            status: invitationInfo.status,
          }));
        } else {
          setAcceptInvitationState({
            status: invitationInfo.userID ? "Login" : "SignUp",
            invitationDetails: {
              invitationID: token,
              ...invitationInfo,
            },
          });
        }
      })
      .catch(() => {
        setAcceptInvitationState((prevState) => ({ ...prevState, status: "Error" }));
      });
  }, [token]);

  return {
    ...acceptInvitationState,
  };
};
