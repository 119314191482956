import { ApolloClient, TypedDocumentNode } from "@apollo/client";
import { addBreadcrumb } from "@sentry/react";

type DefaultType = { [key: string]: any };

export const mutateApollo = async <
  Result extends DefaultType,
  Variables extends DefaultType,
  Client extends ApolloClient<any>
>(
  client: Client,
  {
    mutation,
    variables,
  }: {
    mutation: TypedDocumentNode<Result, Variables>;
    variables: Variables;
  },
) => {
  const { data } = await client.mutate({ mutation, variables });
  const mutationName = (mutation.definitions[0] as any)?.selectionSet?.selections?.[0]?.name
    ?.value as string;
  if (!data) {
    addBreadcrumb({
      category: "Apollo mutate",
      message: `No data returned from apollo mutate, ${data}`,
    });
    throw new Error("Apollo mutate: no data.");
  }
  if (!mutationName) {
    addBreadcrumb({
      category: "Apollo mutate",
      message: `Mutate name not found, ${mutationName}`,
    });
    throw new Error("Apollo mutate: no mutate name");
  }
  const result = data[mutationName];

  return result;
};
