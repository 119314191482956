import { MailIcon } from "Assets";
import useDeviceType from "Hooks/useDeviceType/useDeviceType";
import { AuthenticationErrorMessage, ValidateEmailError } from "Pages/Authentication/types";
import { FormElement, Input, Text } from "UIKit";
import { useEffect, useState } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface ValidateEmailModalProps {
  control: Control<{ email: string; password?: string }>;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  error?: string;
  submitError?: ValidateEmailError;
  onClickCreateAccount: () => void;
  onClickSignIn: () => void;
}

const ValidateEmailModal = ({
  control,
  error,
  submitError,
  onSubmit,
  onClickCreateAccount,
  onClickSignIn,
}: ValidateEmailModalProps) => {
  const { t } = useTranslation();
  const deviceType = useDeviceType();

  const [errorComponentKey, setErrorComponentKey] = useState<ValidateEmailError>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const renderErrorComponent = () => {
    const showNotFoundAccountMessage = errorComponentKey === "account-not-exists";

    const showUserAlreadyExistsMessage = errorComponentKey === "account-already-exists";

    if (showNotFoundAccountMessage) {
      return (
        <div className="flex flex-col space-y-7" data-testid="not-account-message">
          <div>
            <Text level={1} className="font-bold">
              {t("login_not_existing_account_error_title")}
            </Text>

            <ul className="list-disc pl-6 mt-2">
              <li>{t("login_not_existing_account_error_check_1")}</li>
              <li>{t("login_not_existing_account_error_check_2")}</li>
              <li>{t("login_not_existing_account_error_check_3")}</li>
            </ul>
          </div>

          <Text
            level={1}
            className="text-blue-500 text-center cursor-pointer"
            onClick={onClickCreateAccount}
          >
            {t("login_not_existing_account_action")}
          </Text>
        </div>
      );
    }

    if (showUserAlreadyExistsMessage) {
      return (
        <div className="flex justify-center">
          <Text level={1} className="text-blue-500 cursor-pointer" onClick={onClickSignIn}>
            {t("createAccount_existing_account_action")}
          </Text>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (submitError || error) {
      const newErrorMessage = submitError ? t(AuthenticationErrorMessage[submitError]) : error;
      setErrorMessage(newErrorMessage);
    }

    if (submitError) {
      setErrorComponentKey(submitError);
    }
  }, [submitError, t, error]);

  return (
    <div data-testid="validate-email-modal" className="space-y-6">
      <form onSubmit={onSubmit}>
        <FormElement
          required
          control={control}
          id="email"
          labelClassName="font-semibold"
          errorMessage={errorMessage}
        >
          {(field) => (
            <Input
              {...field}
              autoFocus={deviceType === "desktop"}
              iconLeft={<MailIcon />}
              data-testid="sign-in-email"
              placeholder={t("login_enterEmail")}
            />
          )}
        </FormElement>
      </form>

      {renderErrorComponent()}
    </div>
  );
};

export default ValidateEmailModal;
