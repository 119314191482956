import { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { gql, useMutation } from "@apollo/client";

import { sectionStore, ChildReference } from "@eolas-medical/core";

import { reorderItems } from "UIKit";
import { errorStore } from "Stores/ErrorStore";

const REORDER = gql`
  mutation Reorder($input: ReorderInput!) {
    reorder(input: $input) {
      id
      childrenOrder {
        id
        type
        description
        icon
        name
        disabled
      }
    }
  }
`;

export const useReorder = (sectionID: string) => {
  const isAdmin = sectionStore.isAdmin;
  const childReferences = sectionStore.getChildrenOrder(sectionID);
  const [response, setResponse] = useState<{ reorder: any }>();
  const [reorder] = useMutation(REORDER);

  const onDragEnd = async (dropResult: DropResult) => {
    if (!dropResult.destination) return;
    if (dropResult.source.index === dropResult.destination.index) return;

    const oldOrder = [...childReferences];
    const newOrder: ChildReference[] = reorderItems(
      childReferences,
      dropResult.source.index,
      dropResult.destination?.index!,
    );

    sectionStore.setChildrenOrder(sectionID, newOrder);

    const { data, errors } = await reorder({
      variables: {
        input: {
          id: sectionID,
          listOrder: newOrder.map((item) => item.id),
        },
      },
    });

    if (errors && errors[0]) {
      const [error] = errors;
      errorStore.captureError({
        error,
        source: "user",
        retryCallback: reorder,
        retryParameters: {
          variables: {
            input: {
              id: sectionID,
              listOrder: newOrder.map((item) => item.id),
            },
          },
        },
      });
      sectionStore.setChildrenOrder(sectionID, oldOrder as ChildReference[]);
    }

    if (data) {
      setResponse(data);
    }

    return { data, errors };
  };

  return {
    response,
    childReferences,
    isDragDisabled: !isAdmin || childReferences.length < 2,
    onDragEnd,
  };
};
