import { useTranslation } from "react-i18next";

import {
  Input,
  Title,
  Button,
  FormError,
  ModalBody,
  FormElement,
  ModalHeader,
  InnerModalWrapper,
  Modal,
} from "UIKit";
import { ConfirmationCodeIcon } from "Assets";

import { LoginCredentials, VerificationCodeModalState } from "../types";
import { useVerificationCodeStep } from "../hooks";
import { useCallback, useState } from "react";
import useAssistanceVerificationCode from "../../../../modules/authentication/data/useAssistanceVerificationCode";
import RequestAssistanceModal from "../../componentos/RequestAssistanceModal";
import AssistanceSentModal from "../../componentos/AssistanceSentModal";

interface VerificationCodeStepProps {
  loginCredentials: LoginCredentials;
  isFromInvitation?: boolean;
  onVerificationSuccess(): void;
}

export const VerificationCodeStep: React.FC<VerificationCodeStepProps> = ({
  loginCredentials,
  isFromInvitation,
  onVerificationSuccess,
}) => {
  const { t } = useTranslation();

  const { email, firstName, lastName } = loginCredentials;

  const [modalState, setModalState] = useState<VerificationCodeModalState>(null);

  const { control, error, isLoading, onResendCode, onSubmit } = useVerificationCodeStep(
    loginCredentials,
    onVerificationSuccess,
    isFromInvitation,
  );

  const { requestAssistanceVerificationCode } = useAssistanceVerificationCode();

  const handleCloseModal = useCallback(() => {
    setModalState(null);
  }, []);

  const handleAssistanceVerificationCode = useCallback(() => {
    requestAssistanceVerificationCode(
      { queryParams: { email, firstName, lastName, origin: "SIGN_UP" } },
      {
        onSuccess: () => {
          setModalState("assistanceSent");
        },
      },
    );
  }, [email, firstName, lastName, requestAssistanceVerificationCode]);

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex-col">
        <ConfirmationCodeIcon className="h-20 w-20 mb-4" />
        <Title level={5}>{t("createAccount_verifyText")}</Title>
      </ModalHeader>

      <ModalBody>
        <form onSubmit={onSubmit}>
          <FormElement id="code" control={control}>
            {(field) => (
              <Input
                {...field}
                autoComplete="off"
                iconLeft={<ConfirmationCodeIcon />}
                data-testid="sign-in-create-verifyInput"
                placeholder={t("createAccount_verifyPlaceholder")}
              />
            )}
          </FormElement>

          <div className="flex justify-center">
            <Button color="grey" type="button" variant="link" onClick={onResendCode}>
              {t("createAccount_verifyResend")}
            </Button>

            <Button
              color="grey"
              type="button"
              variant="link"
              onClick={() => setModalState("requestAssistance")}
            >
              {t("user_not_received_verification_code")}
            </Button>
          </div>

          <Modal open={modalState !== null} onClose={handleCloseModal}>
            {modalState === "requestAssistance" ? (
              <RequestAssistanceModal onRequestAssistance={handleAssistanceVerificationCode} />
            ) : null}
            {modalState === "assistanceSent" ? (
              <AssistanceSentModal onDone={handleCloseModal} />
            ) : null}
          </Modal>

          <Button
            type="submit"
            isLoading={isLoading}
            className="self-center w-full mt-4"
            data-testid="create-verifyCode-submit"
          >
            {t("general_submit")}
          </Button>

          <FormError error={error} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
