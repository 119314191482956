import React from "react";

import * as icons from "Assets/Icons";
import { AddIcon, XIcon, EolasIcons } from "Assets";

import { Text } from "../../Typography";
import { IconButton } from "../../Button/IconButton";

type Tags = string[];

interface TagInputProps {
  tags: Tags;
  maxTags?: number;
  className?: string;
  placeholder?: string;
  iconType?: EolasIcons;
  onChange(tags: Tags): void;
  validator?(tag: string): boolean;
}

export const TagInput: React.FC<TagInputProps> = ({
  tags,
  maxTags,
  iconType,
  className,
  placeholder,
  validator = () => true,
  onChange,
}) => {
  const Icon = iconType ? icons[iconType] : null;
  const [inputValue, setInput] = React.useState("");
  const maxTagsReached = tags.length === maxTags;

  const onInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  }, []);

  const onAddTag = React.useCallback(() => {
    if (!validator(inputValue)) return;

    onChange([...tags, inputValue]);
    setInput("");
  }, [inputValue, onChange, tags, validator]);

  const onRemoveTag = (index: number) => () => {
    const _tags = [...tags];
    _tags.splice(index, 1);
    onChange(_tags);
  };

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        onAddTag();
      }
    },
    [onAddTag],
  );

  return (
    <div
      className={`
        flex flex-wrap items-center min-h-10 gap-2 input
        py-0.5 pl-2 pr-1 border-2 border-grey-200 rounded-md
        bg-white w-full ${className}
      `}
    >
      {Icon && <Icon className="h-8 w-8" />}
      {tags.map((tag, index) => (
        <div
          key={`${tag}-${index}`}
          className={`flex items-center bg-grey-100 h-8 rounded-md px-2`}
        >
          <Text level={2} className="font-semibold mr-2">
            {tag}
          </Text>
          <IconButton onClick={onRemoveTag(index)} size="xs" variant="rounded" icon={<XIcon />} />
        </div>
      ))}

      {!maxTagsReached && (
        <div className="flex flex-1">
          <input
            value={inputValue}
            onKeyDown={onKeyDown}
            onChange={onInputChange}
            placeholder={placeholder}
            className="h-8 w-52 flex-1"
          />
          <IconButton
            size="sm"
            color="blue"
            icon={<AddIcon />}
            onClick={onAddTag}
            disabled={inputValue.length === 0}
          />
        </div>
      )}
    </div>
  );
};
