import { useTranslation } from "react-i18next";
import { Button, InnerPageWrapper, Text, Title } from "UIKit";
import { useHistory } from "react-router-dom";
import { EventClosedIcon } from "Pages/MeTab/pages/Portfolio/icons";

const EventClosedErrorPage = ({ backUrl }: { backUrl: string }) => {
  const { t } = useTranslation();
  const { replace } = useHistory();

  return (
    <InnerPageWrapper>
      <div className="m-auto px-2 flex flex-col items-center" data-testid="event-closed-error-page">
        <EventClosedIcon />
        <Title className="mt-2" level={5}>
          {t("portfolio_event_closed_title")}
        </Title>
        <Text className="w-88 mt-2 text-center text-grey-700" level={2}>
          {t("portfolio_event_closed_subtitle")}
        </Text>
        <div className="mt-10">
          <Button className="h-12 w-56 rounded-xl" onClick={() => replace(backUrl)}>
            {t("portfolio_event_closed_back")}
          </Button>
        </div>
      </div>
    </InnerPageWrapper>
  );
};

export default EventClosedErrorPage;
