import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { SectionList, useSectionManagement } from "Pages/Spaces/components/SectionManagement";
import { AddButton, Button, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const ClinicalPathways: React.FC<RouteComponentProps> = observer(
  ({ history: { push }, match: { url } }) => {
    const { isAdmin } = useSpacesContext();
    useMainSectionVisit({ mainSectionId: AppLevelSection.clinicalPathways });

    const { t } = useTranslation();
    const { spaceSection } = useSpaceSection(AppLevelSection.clinicalPathways);

    const {
      modal,
      onAddSection,
      onCloseModal,
      onEditSection,
      onDeleteSection,
    } = useSectionManagement(spaceSection.id);

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection.name ?? t(AppLevelSection.clinicalPathways)} />

          <NavButton to={url.replace(`/${AppLevelSection.clinicalPathways}`, "")} />

          {isAdmin && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <AddButton onClick={onAddSection} data-testid="add-new-subSection">
                {t("addNewSection_button")}
              </AddButton>

              <Button
                size="lg"
                color="grey"
                weight="bold"
                variant="outline"
                data-testid="clinicalPathwaysManageExpired"
                onClick={() =>
                  push(
                    `${url.replace(`/${AppLevelSection.clinicalPathways}`, "")}/expired-pathways`,
                  )
                }
              >
                {t("clinicalPathways_manage_expired")}
              </Button>
            </div>
          )}

          <SectionList
            isAdmin={isAdmin}
            sectionID={spaceSection.id}
            onEdit={onEditSection}
            onAddItem={onAddSection}
            onDelete={onDeleteSection}
          />
        </InnerPageWrapper>
        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </>
    );
  },
);
