import { useLaunchDarkly } from "Contexts";
import { useQueryParams } from "Hooks";
import { LDFlagNames } from "Utilities/types";

const useShouldCreateSpace = () => {
  const { flags } = useLaunchDarkly();
  const queryParams = useQueryParams();
  const fromLanding = queryParams.get("from") === "landing-page";
  const readOnlyActivated = flags[LDFlagNames.READ_ONLY_ACTIVATED];

  return {
    shouldCreateSpace: fromLanding && !readOnlyActivated,
  };
};

export default useShouldCreateSpace;
