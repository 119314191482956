import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef, useState } from "react";

import { SpinningDotsIcon } from "Assets";
import { Footer, PageTitle } from "UIKit";
import { useSentry } from "Contexts/SentryContext";
import { appConfig, repoConfig } from "AppTypeConfig";
import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { useSelectSpaceActions } from "Pages/Spaces/pages/SelectSpace/hooks/useSelectSpaceActions";
import { loginWithToken, logout } from "../auth.actions";
import { loginTokenService } from "API/LoginTokenService";

const { commitId, version } = repoConfig;

export const AutoLogin = () => {
  const { t } = useTranslation();

  const { token } = useParamsDecoded<{ token: string }>();
  const { captureException } = useSentry();

  const { onSelectSpace } = useSelectSpaceActions();
  const [error, setError] = useState<boolean>(false);

  const isLoggingIn = useRef(false);

  const autoLogin = useCallback(async () => {
    try {
      if (isLoggingIn.current || error) {
        return;
      }
      await logout();
      isLoggingIn.current = true;
      const { data: loginData } = await loginTokenService.loginWithToken(token);

      await loginWithToken(loginData);
      await onSelectSpace({ appID: loginData.appID, orgID: "", name: "" });
    } catch (e) {
      captureException(e);
      setError(true);
      isLoggingIn.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    autoLogin();
  }, [autoLogin]);

  return (
    <>
      <div className="h-screen flex flex-col">
        <div className="flex flex-col flex-1 items-center justify-start pt-8 md:pt-0 md:justify-center">
          <PageTitle className="mb-8" subTitle={t("pageSubTitle")} title={appConfig.appName} />
          {error && "An error has occured, please contact your admin or support@eolasmedical.com"}
          {!error && (
            <>
              Please wait while we log you in...
              <div className="mt-10">
                <SpinningDotsIcon className="animate-spin" width={60} height={60} fill="#3375ed" />
              </div>
            </>
          )}
        </div>
        <Footer commitId={commitId} version={version} />
      </div>
    </>
  );
};
