import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { EolasFile } from "Utilities/types";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { useNotifications } from "Components/Notifications";

import { useFileOperations } from "./useFileOperations";
import { fetchAllData } from "@eolas-medical/core";

export const useHighlightFile = () => {
  const { t } = useTranslation();
  const { showNotification, updateNotification } = useNotifications();
  const { isLoading, setRequestStatus } = useRequestStatus();

  const { updateFile } = useFileOperations();

  const toggleHighlight = useCallback(
    (eolasFile: EolasFile) => {
      const isHighlighted = !!eolasFile.highlighted && eolasFile.highlighted !== "false";

      const notification = showNotification({
        type: "loading",
        description: t(isHighlighted ? "highlights_remove_loading" : "highlights_add_loading"),
      });
      setRequestStatus({ status: "pending" });

      updateFile({
        variables: {
          input: {
            id: eolasFile.id,
            highlighted: isHighlighted ? "false" : new Date().toISOString(),
          },
          parentID: eolasFile.parentID,
        },
      })
        .then(async () => {
          await fetchAllData();
          setRequestStatus({ status: "success" });
          updateNotification({
            type: "success",
            id: notification.id,
            autoHideTimeout: 3000,
            description: t(isHighlighted ? "highlights_remove_success" : "highlights_add_success"),
          });
        })
        .catch((error) => {
          errorStore.captureError({
            error,
            source: "user",
          });
          setRequestStatus({ status: "error" });
          updateNotification({
            type: "error",
            id: notification.id,
            autoHideTimeout: 3000,
            description: t(isHighlighted ? "highlights_remove_failure" : "highlights_add_failure"),
          });
        });
    },
    [t, setRequestStatus, showNotification, updateFile, updateNotification],
  );

  return {
    highlightLoading: isLoading,
    toggleHighlight,
  };
};
