import { Type, Static } from "@sinclair/typebox";
import envSchema from "env-schema";
import { AppEnv } from "Utilities/types";
import pkg from "../package.json";

const EnvConfigSchema = Type.Object({
  REACT_APP_VERSION: Type.Optional(Type.String({ default: pkg.version })),
  REACT_APP_COMMIT_ID: Type.Optional(Type.String()),
  REACT_APP_APP_ENV: Type.Enum(AppEnv),
  REACT_APP_SENTRY_ENABLED: Type.Boolean(),
  REACT_APP_SENTRY_DSN: Type.String(),
  REACT_APP_SENTRY_TRACE_SAMPLE_RATE: Type.Optional(Type.Number({ default: 1 })),
  REACT_APP_LAUNCH_DARKLY_ENABLED: Type.Boolean(),
  REACT_APP_LAUNCH_CLIENT_SIDE_ID: Type.String(),
  REACT_APP_MIXPANEL_ENABLED: Type.Boolean(),
  REACT_APP_MIXPANEL_TOKEN: Type.String(),
  REACT_APP_PDF_TRON: Type.Optional(Type.String()),
  // AWS Config
  REACT_APP_LOG_AWS_CONFIG: Type.Optional(Type.Boolean({ default: false })),
  REACT_APP_AWS_REGION: Type.String(),
  REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT: Type.String(),
  REACT_APP_AWS_USER_FILES_S3_BUCKET: Type.String(),
  REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID: Type.String(),

  REACT_APP_API_BASE_URL: Type.String(),
  REACT_APP_AUTH_TYPE: Type.String(),
  REACT_APP_AWS_COGNITO_USER_POOL_ID: Type.String(),
  REACT_APP_WEB_CLIENT_ID: Type.String(),
  REACT_APP_COGNITO_APP_DOMAIN: Type.String(),
  REACT_APP_SSO_DOMAINS: Type.String(),
  REACT_APP_STATIC_BASE_URL: Type.String(),
  REACT_APP_INTERCOM_APP_ID: Type.String(),
  REACT_APP_WEB_DOMAIN: Type.String(),
  REACT_APP_PROTOCOL: Type.String(),
  REACT_APP_ZAPIER_HOOK_URL: Type.String(),
  REACT_APP_ZAPIER_CATCH_VERIFICATION_CODE: Type.String(),
  REACT_APP_ZAPIER_CATCH_ACCOUNT: Type.String(),
  REACT_APP_SMARTLOOK_KEY: Type.String(),
  REACT_APP_ONESIGNAL_APP_ID: Type.String(),
  REACT_APP_TINYCLOUND_KEY: Type.String(),
});

export type EnvConfig = Static<typeof EnvConfigSchema>;

// will throw if the schema is invalid
const envConfig = envSchema<EnvConfig>({ schema: Type.Strict(EnvConfigSchema) });

export default envConfig;
