import { accessClient } from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import spacesKeys from "./spaces.queryKeys";

const useGenerateAccessToken = (spaceId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(async () => accessClient.generateAccessToken(spaceId), {
    onSuccess: (newToken) => {
      queryClient.setQueryData(spacesKeys.spaceAccessToken(spaceId), () => {
        const updatedToken = {
          accessTokens: [
            {
              id: newToken.token,
            },
          ],
        };

        return { ...updatedToken };
      });
      queryClient.invalidateQueries(spacesKeys.detail(spaceId));
    },
  });

  return {
    generateAccessToken: mutate,
    generatingAccessToken: isLoading,
  };
};

export default useGenerateAccessToken;
