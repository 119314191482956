import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteProps } from "react-router-dom";

import {
  EolasFile,
  sectionStore,
  AppLevelSection,
  EolasMainSection,
  OrganisationLevelSection,
} from "@eolas-medical/core";

import { Button, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";

import { useExpiredFiles } from "./useExpiredFiles";
import { AddItemWithOrganisationModal } from "../AddEolasFile";

interface ExpiredFilesPageProps extends RouteProps {
  mainSection: EolasMainSection;
  match?: any;
}

type ExpiredSections =
  | "guidelines"
  | "clinicalPathways"
  | "hospitalGuidelines"
  | "clinicalDocuments"
  | "policyDocuments"
  | "genericOrganisationGuidelines";

const routeSlugs: Record<ExpiredSections, string> = {
  [AppLevelSection.clinicalPathways]: "/expired-pathways",
  [AppLevelSection.guidelines]: "/guidelines-expired",
  [AppLevelSection.clinicalDocuments]: "/clinical-documents-expired",
  [AppLevelSection.policyDocuments]: "/policy-documents-expired",
  [OrganisationLevelSection.hospitalGuidelines]: "/expired-guidelines",
  [OrganisationLevelSection.genericOrganisationGuidelines]: "/generic-expired-guidelines",
};

const routeReplacements: Record<ExpiredSections, string> = {
  [AppLevelSection.clinicalPathways]: `/${AppLevelSection.clinicalPathways}`,
  [AppLevelSection.guidelines]: `/${AppLevelSection.guidelines}`,
  [AppLevelSection.clinicalDocuments]: `/${AppLevelSection.clinicalDocuments}`,
  [AppLevelSection.policyDocuments]: `/${AppLevelSection.policyDocuments}`,
  [OrganisationLevelSection.hospitalGuidelines]: `/${OrganisationLevelSection.hospitalGuidelines}`,
  [OrganisationLevelSection.genericOrganisationGuidelines]: `/${OrganisationLevelSection.genericOrganisationGuidelines}`,
};

export const ExpiredFilesPage: React.FC<ExpiredFilesPageProps> = observer(
  ({ mainSection, match: { url } }) => {
    const { t } = useTranslation();
    const { id: sectionID } = sectionStore.getChildReferenceByMainSectionType(mainSection)!;
    const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] = useState(true);
    const {
      modal,
      setModal,
      expiredFiles,
      onCloseModal,
      fileExpiringIn,
      filesExpiringSoon,
      changeExpiryPeriod,
      noExpiredFilesLabel,
    } = useExpiredFiles(mainSection);

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={t("expiredPage_title", { section: t(mainSection) })} />

          <NavButton
            to={url.replace(
              routeSlugs[mainSection as ExpiredSections],
              routeReplacements[mainSection as ExpiredSections],
            )}
          />

          <div className="flex flex-col space-y-4">
            <span className="font-semibold text-xl">
              {t("expiredPage_expired_files_header", { section: t(mainSection) })}
            </span>

            <div className="flex flex-col divide-y divide-grey space-y-4">
              {expiredFiles.length > 0 ? (
                expiredFiles.map((file) => (
                  <div
                    key={file.id}
                    onClick={() => {
                      setModal(
                        <AddItemWithOrganisationModal
                          isExpired
                          file={file}
                          onCloseModal={onCloseModal}
                          mainSectionID={mainSection}
                          subSectionId={sectionID}
                          setShouldCloseOnOverlayClick={setShouldCloseOnOverlayClick}
                        />,
                      );
                    }}
                    className="p-4 cursor-pointer rounded-md hover:bg-grey-hover"
                  >
                    {file.name}
                  </div>
                ))
              ) : (
                <span className="flex items-center text-lg text-grey-dark h-36">
                  {t("expiredPage_no_expired_files", { section: t(mainSection) })}
                </span>
              )}
            </div>

            <span className="font-semibold text-xl">
              {t("expiredPage_expiring_soon_header", { section: t(mainSection) })}
            </span>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <Button
                color="grey"
                className="flex-1"
                onClick={changeExpiryPeriod(1)}
                variant={fileExpiringIn === 1 ? "solid" : "ghost"}
                weight={fileExpiringIn === 1 ? "semibold" : "regular"}
              >
                {t("expiredPage_1month_button_label")}
              </Button>

              <Button
                color="grey"
                className="flex-1"
                onClick={changeExpiryPeriod(3)}
                variant={fileExpiringIn === 3 ? "solid" : "ghost"}
                weight={fileExpiringIn === 3 ? "semibold" : "regular"}
              >
                {t("expiredPage_3month_button_label")}
              </Button>

              <Button
                color="grey"
                className="flex-1"
                onClick={changeExpiryPeriod(6)}
                variant={fileExpiringIn === 6 ? "solid" : "ghost"}
                weight={fileExpiringIn === 6 ? "semibold" : "regular"}
              >
                {t("expiredPage_6month_button_label")}
              </Button>
              <Button
                color="grey"
                className="flex-1"
                onClick={changeExpiryPeriod(12)}
                variant={fileExpiringIn === 12 ? "solid" : "ghost"}
                weight={fileExpiringIn === 12 ? "semibold" : "regular"}
              >
                {t("expiredPage_12month_button_label")}
              </Button>
            </div>

            <div className="flex flex-col divide-y divide-grey space-y-4">
              {filesExpiringSoon.length > 0 ? (
                filesExpiringSoon.map((file: EolasFile) => (
                  <div
                    key={file.id}
                    onClick={() => {
                      setModal(
                        <AddItemWithOrganisationModal
                          file={file}
                          onCloseModal={onCloseModal}
                          mainSectionID={mainSection}
                          subSectionId={sectionID}
                          setShouldCloseOnOverlayClick={setShouldCloseOnOverlayClick}
                        />,
                      );
                    }}
                    className="p-4 cursor-pointer rounded-lg hover:bg-grey-hover"
                  >
                    {file.name}
                  </div>
                ))
              ) : (
                <span className="flex items-center text-lg text-grey-dark h-36">
                  {t(noExpiredFilesLabel, { section: t(mainSection) })}
                </span>
              )}
            </div>
          </div>
        </InnerPageWrapper>

        <Modal
          open={!!modal}
          onClose={onCloseModal}
          shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
          {modal}
        </Modal>
      </>
    );
  },
);
