import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import {
  CheckIcon,
  AttentionIcon,
  PiechartIcon,
  StarIcon,
  StarOutlineIcon,
  DeleteIcon,
} from "Assets";

import { IconButton } from "../Button";
import { ControlsWrapper, FileTileProps, RegularFileWrapper } from "./shared";
import { DownloadFileButton } from "Components";

export interface NewsFeedFileTileProps extends FileTileProps {
  isCompleted?: boolean;
}

export const NewsFeedFileTile: React.FC<NewsFeedFileTileProps> = ({
  canDrag,
  isAdmin,
  eolasFile,
  isDragging,
  isCompleted,
  highlightLoading,
  onEdit = () => {},
  onDelete = () => {},
  onHighlight = () => {},
  onSelectFile = () => {},
}) => {
  const { t } = useTranslation();
  const { createdAt } = eolasFile;
  const isHighlighted = !!eolasFile.highlighted && eolasFile.highlighted !== "false";

  return (
    <RegularFileWrapper
      className="h-40"
      testId={eolasFile?.name ?? ""}
      canDrag={canDrag}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-1 h-full justify-between">
        <span className="font-semibold text-lg line-clamp-1 leading-10">{eolasFile?.name}</span>
        <div className="flex flex-col">
          <span className="text-sm text-grey-dark line-clamp-2">{eolasFile?.info}</span>
          {createdAt && (
            <span className="text-sm text-grey-dark">
              {format(new Date(createdAt as string), "do MMMM yyyy, HH:mm")}
            </span>
          )}
        </div>
        {!isAdmin && (
          <div className="flex items-center mt-2">
            {isCompleted ? (
              <CheckIcon
                data-testid="completed-new-item"
                className="text-green"
                height={12}
                width={12}
              />
            ) : (
              <AttentionIcon
                data-testid="incomplete-new-item"
                className="text-red"
                height={12}
                width={12}
              />
            )}
            <span className="text-sm text-grey-dark ml-2">
              {t(isCompleted ? "newsFeed_item_viewed" : "newsFeed_item_not_viewed")}
            </span>
          </div>
        )}
      </div>
      <div className="w-fit self-start flex flex-col items-end justify-between h-full">
        <DownloadFileButton eolasFile={eolasFile} />
      </div>
      {isAdmin && (
        <ControlsWrapper>
          <IconButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onHighlight(eolasFile);
            }}
            isLoading={highlightLoading}
            data-testid={`highlightButtonFor-${eolasFile.name}`}
            icon={isHighlighted ? <StarIcon /> : <StarOutlineIcon />}
          />
          <IconButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(eolasFile);
            }}
            icon={<DeleteIcon />}
            data-testid={`deleteButtonFor-${eolasFile.name}`}
          />
          <IconButton
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(eolasFile);
            }}
            icon={<PiechartIcon />}
            data-testid={`pieChartButton-${eolasFile.name}`}
          />
        </ControlsWrapper>
      )}
    </RegularFileWrapper>
  );
};
