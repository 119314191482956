import { EolasMainSection } from "@eolas-medical/core";
import { CheckIcon } from "Assets";
import { useIcons } from "Hooks";
import { useTranslation } from "react-i18next";

interface TemplateProps {
  isSelected?: boolean;
  title: string;
  description: string;
  onClick?: () => void;
  sections: EolasMainSection[];
}

export const TemplateCard: React.FC<TemplateProps> = ({
  isSelected = false,
  title,
  description,
  onClick,
  sections,
}) => {
  const { t } = useTranslation();
  const { getMainSectionIcon } = useIcons();

  const mapMainSectionsToFeatures = (sections: EolasMainSection[]) => {
    return sections.map((section) => {
      const IconComponent = getMainSectionIcon(section);
      const IconDiv = () => (
        <div className="flex items-center justify-center rounded-full w-6 h-6 lg:h-10 lg:w-10  bg-grey-100">
          <IconComponent className="h-3 w-3 lg:w-6 lg:h-6" />
        </div>
      );
      return {
        icon: <IconDiv />,
        label: t(section),
      };
    });
  };

  const features = mapMainSectionsToFeatures(sections);

  return (
    <div
      onClick={onClick}
      className={`relative p-6 pt-12 lg:p-6 cursor-pointer hover:border-blue-100 lg:h-72 3xl:h-96  border-2  shadow-sm rounded-lg mb-6 ${
        isSelected ? "bg-blue-50 border-blue-200" : "border-grey-200"
      }`}
    >
      <div className="absolute top-6 right-6 w-6 h-6 rounded-full border border-grey-500">
        {isSelected && <CheckIcon className="text-green-500" />}
      </div>

      <div className="lg:text-lg font-bold mb-2">{title}</div>
      <div className="text-sm lg:text-md text-gray-600">{description}</div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-4 mt-12 mb-2 lg:overflow-y-auto lg:h-24">
        {features.map((feature, index) => (
          <div key={index} className="flex items-center">
            <div>{feature.icon}</div>
            <div className="text-2xs lg:text-xs font-semibold ml-3">{feature.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
