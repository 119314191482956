import { useEffect } from "react";

const useBlockScreenScroll = (blockScroll: boolean) => {
  useEffect(() => {
    const mainTag = document.getElementsByTagName("main");
    const mainTagHtmlItem = mainTag.item(0);

    if (mainTagHtmlItem) {
      if (blockScroll) {
        mainTagHtmlItem.style.overflow = "hidden"; // Disable scroll
        document.body.style.overflow = "scroll";
      } else {
        mainTagHtmlItem.style.overflow = "auto"; // Enable scroll
        document.body.style.overflow = "auto";
      }
    }

    return () => {
      if (mainTagHtmlItem) {
        mainTagHtmlItem.style.overflow = "auto";
        document.body.style.overflow = "auto";
      }
    };
  }, [blockScroll]);
};

export default useBlockScreenScroll;
