import "react-app-polyfill/stable";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import allSettled from "promise.allsettled";

import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { LoadingOverlay } from "UIKit";
import { TermlyCookies } from "Components";
import { EolasProviders, Sentry } from "Contexts";
import { smartLookStartRecording, startup } from "Utilities/appStartup";

import "./i18n";
import "./index.css";

import App from "./App";
import { WhatsNewModal } from "Components/WhatsNewModal/WhatsNewModal";
import { SelfSetup } from "Pages/AppTour";
import CompleteProfileModal from "Components/CompleteProfileModal/CompleteProfileModal";

startup();
allSettled.shim();

Sentry.addBreadcrumb({
  category: "Init",
  message: "imports added, calling amplify configure",
});

ReactDOM.render(
  <React.StrictMode>
    <EolasProviders>
      <Suspense fallback={<LoadingOverlay />}>
        <Router>
          <App />
          <TermlyCookies />
          <WhatsNewModal />
          <SelfSetup />
          <CompleteProfileModal />
        </Router>
      </Suspense>
    </EolasProviders>
  </React.StrictMode>,
  document.getElementById("root"),
  () => {
    if (process.env.REACT_APP_SENTRY_ENABLED !== "false") {
      smartLookStartRecording();
    }
  },
);
