import { Route } from "react-router-dom";

import { BaseAppRoute, LDFlagNames } from "Utilities/types";

import { OrganisationRoute } from "../../types";
import { OrganisationLevelSection } from "@eolas-medical/core";
import Organisation from "./Organisation";
import { DrugMonitoring } from "./pages/DrugMonitoring";
import { MedusaFilesPage, MedusaSubSections } from "./pages/MiniApp/MedusaMedications";
import {
  LearningManagement,
  LearningManagementSection,
} from "./pages/MiniApp/LearningManagementSystem";
import { CommunicationsPortal } from "./pages/MiniApp/CommunicationsPortal";
import { OrganisationWellbeing, OrganisationWellbeingSection } from "./pages/OrganisationWellbeing";
import { GenericGuidelines, GenericGuidelinesSection } from "./pages/GenericGuidelines";
import { ClientContactsPage, ClientContactsSection } from "../Space/pages/MiniApp/Contacts";
import { Guidelines, GuidelinesSection } from "../Space/pages/Guidelines";
import { useLaunchDarkly } from "Contexts";
import ContentRepository from "./pages/ContentRepository/ContentRepository";

export const organisationRoutes: Record<OrganisationRoute, string> = {
  drugMonitoring: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.drugMonitoring}`,
  hospitalContacts: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.hospitalContacts}`,
  hospitalContactsSubsection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.hospitalContacts}/:sectionID`,
  hospitalGuidelines: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.hospitalGuidelines}`,
  hospitalGuidelinesSubsection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.hospitalGuidelines}/:sectionID`,
  medusaMedications: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.medusaMedications}`,
  medusaFiles: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.medusaMedications}/:sectionName/:id`,
  learningManagement: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.learningManagement}`,
  learningManagementSubsection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.learningManagement}/:sectionID`,
  communicationPortal: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.communicationPortal}`,
  organisationWellbeing: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.organisationWellbeing}`,
  organisationWellbeingSection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.organisationWellbeing}/:sectionID`,
  genericOrganisationGuidelines: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.genericOrganisationGuidelines}`,
  genericOrganisationGuidelinesSection: `/${BaseAppRoute.organisation}/:organisationName/${OrganisationLevelSection.genericOrganisationGuidelines}/:sectionID`,
  contentManagement: "",
  expiredGuidelines: "",
  contentRepository: `/${BaseAppRoute.organisation}/:organisationName/content-repository/:contentRepositoryId`,
};

export const OrganisationRoutes = () => {
  const { flags } = useLaunchDarkly();
  const showContentRepository = !!flags[LDFlagNames.GENERIC_CONTENT_REPOSITORY];

  return (
    <>
      <Route
        exact
        path={`/${BaseAppRoute.organisation}/:organisationName`}
        component={Organisation}
      />

      {showContentRepository && (
        <Route path={organisationRoutes.contentRepository} component={ContentRepository} />
      )}

      <Route exact component={ClientContactsPage} path={organisationRoutes.hospitalContacts} />
      <Route
        exact
        component={ClientContactsSection}
        path={organisationRoutes.hospitalContactsSubsection}
      />

      <Route exact component={Guidelines} path={organisationRoutes.hospitalGuidelines} />
      <Route
        exact
        component={GuidelinesSection}
        path={organisationRoutes.hospitalGuidelinesSubsection}
      />

      <Route exact component={DrugMonitoring} path={organisationRoutes.drugMonitoring} />

      <Route exact component={MedusaSubSections} path={organisationRoutes.medusaMedications} />
      <Route exact component={MedusaFilesPage} path={organisationRoutes.medusaFiles} />

      <Route exact component={LearningManagement} path={organisationRoutes.learningManagement} />
      <Route
        exact
        component={LearningManagementSection}
        path={organisationRoutes.learningManagementSubsection}
      />

      <Route exact component={CommunicationsPortal} path={organisationRoutes.communicationPortal} />

      <Route
        exact
        component={OrganisationWellbeing}
        path={organisationRoutes.organisationWellbeing}
      />

      <Route
        exact
        component={OrganisationWellbeingSection}
        path={organisationRoutes.organisationWellbeingSection}
      />

      <Route
        exact
        component={GenericGuidelines}
        path={organisationRoutes.genericOrganisationGuidelines}
      />

      <Route
        exact
        component={GenericGuidelinesSection}
        path={organisationRoutes.genericOrganisationGuidelinesSection}
      />
    </>
  );
};
