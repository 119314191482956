import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Button, FormElement, Input, theme } from "UIKit";
import { EyeIcon, InvisibleIcon, PadlockIcon } from "Assets";

interface PasswordInputValidatorProps {
  id?: string;
  label?: string;
  testID?: string;
  className?: string;
  placeholder?: string;
}

export const PasswordInput: React.FC<PasswordInputValidatorProps> = ({
  label,
  testID,
  id = "password",
  className,
  placeholder,
}) => {
  const { control } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();
  const showHideIcon = { width: 18, height: 18, fill: theme.colors.grey.darker };

  return (
    <FormElement
      id={id}
      required
      control={control}
      className={`relative ${className}`}
      label={label || t("createAccount_password")}
    >
      {(field) => (
        <>
          <Button
            size="sm"
            color="grey"
            type="button"
            variant="ghost"
            weight="regular"
            className="absolute right-0 -top-3"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <>
                <InvisibleIcon className="mr-2" {...showHideIcon} />
                {t("createAccount_password_hide")}
              </>
            ) : (
              <>
                <EyeIcon className="mr-2" {...showHideIcon} />
                {t("createAccount_password_show")}
              </>
            )}
          </Button>
          <Input
            {...field}
            iconLeft={<PadlockIcon />}
            data-testid={testID}
            placeholder={placeholder || t("login_enterPassword")}
            type={showPassword ? "text" : "password"}
          />
        </>
      )}
    </FormElement>
  );
};

export default PasswordInput;
