import { observer } from "mobx-react-lite";
import { RouteProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppLevelSection } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { StarOutlineIcon, HighlightsIcon } from "Assets";
import { InnerPageWrapper, PageTitle, NavButton } from "UIKit";

export interface HighlightsAdminProps extends RouteProps {
  mainSectionID: AppLevelSection;
  match?: any;
}

export const HighlightsAdmin: React.FC<HighlightsAdminProps> = observer(
  ({ mainSectionID, match: { url } }) => {
    useMainSectionVisit({ mainSectionId: mainSectionID });

    const { t } = useTranslation();

    return (
      <InnerPageWrapper>
        <PageTitle title={t(t(AppLevelSection.highlights))} />
        <NavButton to={url.replace(`/${mainSectionID}`, "")} />
        <div className="flex flex-col self-center items-center w-10/12 xl:w-8-12 mb-12">
          <HighlightsIcon height={75} width={75} className="my-6" />
          <p className="text-lg text-center ">{t("highlights_admin_text")}</p>
          <div className="rounded-lg bg-grey-light w-20 h-20 my-6 flex items-center justify-center">
            <StarOutlineIcon height={45} width={45} />
          </div>
        </div>
      </InnerPageWrapper>
    );
  },
);
