import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeSupervision } from "../client/portfolio.client";
import portfolioKeys from "./portfolio.queryKeys";
import { SupervisorsList, SupervisionSummary, SuperviseeList } from "../client/portfolio.graphql";
import { removeObjectFromList } from "Utilities/helpers";
import { SupervisorRequestCreatedBy } from "../types";

const useRemoveSupervision = (createdBy: SupervisorRequestCreatedBy) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(removeSupervision, {
    onSuccess: (removeResult, supervisionId) => {
      if (removeResult.deleted) {
        if (createdBy === SupervisorRequestCreatedBy.SUPERVISEE) {
          queryClient.setQueryData(
            portfolioKeys.supervisorsList(),
            (actualRequests: SupervisorsList | undefined) => {
              if (actualRequests && actualRequests.supervisionRequestsSummary) {
                return {
                  ...actualRequests,
                  supervisorsSummary: removeObjectFromList<SupervisionSummary>(
                    actualRequests.supervisorsSummary as SupervisionSummary[],
                    "id",
                    supervisionId,
                  ),
                };
              }
            },
          );
        }
        if (createdBy === SupervisorRequestCreatedBy.SUPERVISOR) {
          queryClient.setQueryData(
            portfolioKeys.superviseesList(),
            (actualRequests: SuperviseeList | undefined) => {
              if (actualRequests) {
                return {
                  supervisionRequestsSummary: [...actualRequests.supervisionRequestsSummary],
                  superviseeSummary: removeObjectFromList<SupervisionSummary>(
                    actualRequests.superviseeSummary as SupervisionSummary[],
                    "id",
                    supervisionId,
                  ),
                };
              }
            },
          );
        }
      }
    },
  });

  return {
    removeSupervision: mutate,
    removingSupervision: isLoading,
    ...rest,
  };
};

export default useRemoveSupervision;
