export const convertFileToDataURL = async (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve((reader.result as string) ?? "");
    });
    reader.readAsDataURL(file);
  });
};

export const getMimeTypeFromImg = (imgNode: HTMLImageElement) => {
  if (!(imgNode instanceof HTMLImageElement)) {
    throw new Error("Provided node is not an HTML image element.");
  }

  const src = imgNode.src;

  return src.split(",")[0].split(":")[1].split(";")[0];
};
