import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { OrganisationLevelSection } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { SectionList, useSectionManagement } from "Pages/Spaces/components/SectionManagement";
import { AddButton, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const OrganisationWellbeing: React.FC<RouteComponentProps> = observer(
  ({ match: { url } }) => {
    const { isAdmin } = useSpacesContext();
    const { t } = useTranslation();
    useMainSectionVisit({ mainSectionId: OrganisationLevelSection.organisationWellbeing });

    const { spaceSection } = useSpaceSection(OrganisationLevelSection.organisationWellbeing);

    const {
      modal,
      onAddSection,
      onCloseModal,
      onEditSection,
      onDeleteSection,
    } = useSectionManagement(spaceSection.id);

    return (
      <>
        <InnerPageWrapper>
          <PageTitle
            title={spaceSection.name ?? t(OrganisationLevelSection.organisationWellbeing)}
          />

          <NavButton to={url.replace(`/${OrganisationLevelSection.organisationWellbeing}`, "")} />

          {isAdmin && (
            <AddButton onClick={onAddSection} data-testid="add-new-subSection">
              {t("addNewSection_button")}
            </AddButton>
          )}

          <SectionList
            isAdmin={isAdmin}
            sectionID={spaceSection.id}
            onEdit={onEditSection}
            onAddItem={onAddSection}
            onDelete={onDeleteSection}
          />

          <Modal open={!!modal} onClose={onCloseModal}>
            {modal}
          </Modal>
        </InnerPageWrapper>
      </>
    );
  },
);
