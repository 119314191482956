import React from "react";
import { useTranslation } from "react-i18next";
import { inviteClient, UserInvitationStatus } from "@eolas-medical/core";

import { Text, IconButton } from "UIKit";
import { useRequestStatus } from "Utilities";
import { MailSendIcon, XIcon } from "Assets";
import { useNotifications } from "Components/Notifications";
import { UserInvitation as IUserInvitation } from "Utilities/types";

interface UserInvitationProps {
  invitation: IUserInvitation;
  refetchInvitations(): any;
}

const useUserInvitation = (invitation: IUserInvitation, refetchInvitations: () => any) => {
  const { showNotification } = useNotifications();
  const { isLoading, setRequestStatus } = useRequestStatus();

  const onDeleteInvitation = React.useCallback(async () => {
    setRequestStatus({ status: "pending", error: "" });
    try {
      await inviteClient.deleteInvitation(invitation.id);
      await refetchInvitations();
    } catch (error: any) {
      showNotification({ type: "error", description: error.message });
    }
  }, [invitation.id, showNotification, setRequestStatus, refetchInvitations]);

  return {
    isLoading,
    onDeleteInvitation,
  };
};

export const UserInvitation: React.FC<UserInvitationProps> = ({
  invitation,
  refetchInvitations,
}) => {
  const { isLoading, onDeleteInvitation } = useUserInvitation(invitation, refetchInvitations);

  return (
    <div className="flex items-center space-x-4 p-4">
      <MailSendIcon className="w-6 h-6 text-grey-600" />

      <div className="grid grid-cols-1 md:grid-cols-3 flex-1 gap-2 items-center">
        <Text level={1} className="md:col-span-2">
          {invitation.email}
        </Text>

        <div className="flex justify-between">
          <InvitationStatus status={invitation.status} info={invitation.info} />
          <IconButton
            size="xs"
            color="blue"
            variant="rounded"
            icon={<XIcon />}
            isLoading={isLoading}
            onClick={onDeleteInvitation}
          />
        </div>
      </div>
    </div>
  );
};

const InvitationStatus: React.FC<{ status: UserInvitationStatus; info: string }> = ({
  info,
  status,
}) => {
  const { t } = useTranslation();
  const { className, label } = React.useMemo(() => {
    switch (status) {
      case "Accepted":
        return { className: "text-green-500", label: t("manageUsers_invite_invitation_accepted") };
      case "Pending":
        return { className: "text-yellow-600", label: t("manageUsers_invite_invitation_pending") };
      case "Sent":
        return { className: "text-quartz-600", label: "Sent" };
      case "Failed":
      default:
        return {
          className: "text-red-500",
          label: `${t("manageUsers_invite_invitation_error")}: ${info}`,
        };
    }
  }, [info, status, t]);

  return (
    <Text
      level={2}
      title={info}
      className={`font-semibold line-clamp-1 flex-1 text-center ${className}`}
    >
      {label}
    </Text>
  );
};
