import { useLocalSearch } from "shared/hooks/useLocalSearch";
import { RecentSearch } from "../types";
import { useMemo } from "react";
import { splitStringByRanges } from "Utilities/helpers";
import useMasterSearchContext from "../context/useMasterSearchContext";

interface UseRecentSearches {
  recentSearches: RecentSearch[];
  onSetSearchInput: (text: string) => void;
}

const useRecentSearches = (): UseRecentSearches => {
  const { recentSearches } = useMasterSearchContext();
  const { searchResultWithMatches, onSetSearchInput } = useLocalSearch({
    data: recentSearches,
    keysToSearch: ["searchText"],
  });

  const formattedSearches: RecentSearch[] = useMemo(() => {
    return searchResultWithMatches.map(({ result, matches }) => {
      const matchesIndices = matches.flatMap((m) => m.indices);
      const splittedText = splitStringByRanges(result.searchText, matchesIndices);
      return {
        searchText: result.searchText,
        searchTimestamp: result.searchTimestamp,
        highlightedText: splittedText,
      };
    });
  }, [searchResultWithMatches]);

  return {
    recentSearches: formattedSearches,
    onSetSearchInput,
  };
};

export default useRecentSearches;
