import { gql, useMutation } from "@apollo/client";
import { fetchAllData, sectionStore } from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { ChildReference } from "Utilities/types";

const REMOVE_SECTION = gql`
  mutation RemoveSection($parentID: String!, $id: String!) {
    removeSection(parentID: $parentID, id: $id)
  }
`;

export const useDeleteSection = (section: ChildReference) => {
  const [removeSection] = useMutation(REMOVE_SECTION);

  const { error, isLoading, setRequestStatus, isSuccessful } = useRequestStatus();

  const onDeleteSection = async () => {
    const { parentID, ownerID } = sectionStore.getSection(section.id)!;
    const isMainSection = section.type === "mainSection";

    setRequestStatus({ status: "pending", error: "" });
    const { data, errors } = await removeSection({
      variables: {
        id: section.id,
        parentID: isMainSection ? ownerID : parentID,
      },
    });

    if (data) {
      setRequestStatus({ status: "success", error: "" });
      await fetchAllData();
    }

    if (errors && errors[0]) {
      const [error] = errors;

      const errorMessage = errorStore.captureError({
        error,
        source: "user",
        retryCallback: removeSection,
        retryParameters: {
          variables: {
            id: section.id,
            parentID: isMainSection ? ownerID : parentID,
          },
        },
      });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  };

  return {
    error,
    isLoading,
    isSuccessful,
    onDeleteSection,
  };
};
