import { ComponentConfigOptionType } from "modules/portfolio/types";
import { ToggleColor } from "UIKit";

const usePortfolioTags = () => {
  const colorMap: Record<ComponentConfigOptionType, ToggleColor> = {
    positive: "green",
    neutral: "yellow",
    negative: "red",
    default: "blue",
  };

  const getNewValue = (tagValue: string, selectedValue: string) => {
    return tagValue !== selectedValue ? tagValue : "";
  };

  const getTagColor = (tagType: ComponentConfigOptionType): ToggleColor | undefined => {
    const tagColor = colorMap[tagType];

    // This warning is just to figure out if something was wrong when creating the template in the backend
    if (!tagColor) console.warn(`Invalid tagType ${tagType}`);

    return tagColor;
  };

  return { getNewValue, getTagColor };
};

export default usePortfolioTags;
