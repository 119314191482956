import React from "react";
import { useTranslation } from "react-i18next";

import { ArrowRightIcon, MailIcon } from "Assets";
import { Button, FormError, FormElement, Input, Title, Text } from "UIKit";

import "../styles.css";
import { useSendResetPasswordLink } from "../hooks";

interface PasswordFormProps {
  setStage: any;
  setUserEmail: any;
}

export const PasswordForm: React.FC<PasswordFormProps> = ({ setStage, setUserEmail }) => {
  const { t } = useTranslation();
  const { control, onSubmit, isLoading, error } = useSendResetPasswordLink(setStage, setUserEmail);

  // this needs to be done due to meaningless post migration cognito error messages
  const generateErrorMessage = (error: string) => {
    if (error.includes("UserMigration failed with error Bad credentials.")) {
      return t("error_forgotten_password");
    }
    return error;
  };

  return (
    <form onSubmit={onSubmit} className="flex flex-col justify-center flex-2 p-4 lg:p-16 space-y-4">
      <Title level={6}>{t("createAccount_forgotPassword_title")}</Title>
      <Text level={1}>{t("createAccount_forgotPassword_description")}</Text>

      <FormElement
        required
        control={control}
        id="emailForgotFlow"
        labelClassName="font-semibold"
        label={t("createAccount_forgotPassword_label")}
      >
        {(field) => (
          <Input
            {...field}
            iconLeft={<MailIcon />}
            data-testid="forgot-password-enterEmail"
            placeholder={t("createAccount_forgotPassword_placeholder")}
          />
        )}
      </FormElement>

      <Button
        type="submit"
        color={"blue"}
        className="self-end"
        isLoading={isLoading}
        data-testid="forgot-password-submit"
      >
        <ArrowRightIcon fill="white" height={24} width={24} />
      </Button>

      {error && <FormError error={generateErrorMessage(error)} />}
    </form>
  );
};
