import { observer } from "mobx-react-lite";

import { EditIcon } from "Assets";

interface EditableInputProps {
  label: string;
  value?: string;
  handleIconClick?: () => void;
}

export const ChangePasswordLabel: React.FC<EditableInputProps> = observer(
  ({ label, value, handleIconClick }) => {
    return (
      <div className="flex justify-between items-end p-4">
        <div>
          <label className="font-semibold">{label}</label>
          <span className="block font-semibold">{value}</span>
        </div>
        <EditIcon
          width={24}
          height={24}
          onClick={handleIconClick}
          className="cursor-pointer ml-4"
        />
      </div>
    );
  },
);
