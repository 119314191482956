import { EolasRegions } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useBrowserCountry } from "../useBrowserCountry";
import { regionLookup } from "./regionLookup";

export const useSupportedRegion = () => {
  const { browserCountry } = useBrowserCountry();
  const { flags } = useLaunchDarkly();

  let supportedEolasRegion: EolasRegions | null = null;

  const foundLookup = Object.entries(regionLookup).find(
    ([, { region, firstLanguage }]) =>
      browserCountry === region || browserCountry === firstLanguage,
  );

  if (foundLookup) {
    [supportedEolasRegion] = foundLookup as [EolasRegions, {}];
  } else if (flags?.[LDFlagNames.US_COMMUNITY_CONTENT]) {
    supportedEolasRegion = EolasRegions.Us;
  }

  return { supportedEolasRegion };
};
