import { useTranslation } from "react-i18next";
import { success } from "Assets";
import { InnerModalWrapper, LottieWithHeader } from "UIKit";

export const EventSavedModal = ({ onCloseModal }: { onCloseModal: () => void }) => {
  const { t } = useTranslation();

  return (
    <InnerModalWrapper>
      <LottieWithHeader
        animationSize="50%"
        animation={success}
        onComplete={onCloseModal}
        text={t("portfolio_event_saved")}
      />
    </InnerModalWrapper>
  );
};
