import { useCallback, useEffect, MutableRefObject } from "react";

export const useClickOutside = <T extends HTMLElement | null>(
  ref: MutableRefObject<T>,
  onClick: () => void,
) => {
  const onClickOutside = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const target = e.target as T;
      if (ref.current && !ref.current.contains(target)) {
        onClick();
      }
    },
    [onClick, ref],
  );

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    document.addEventListener("touchstart", onClickOutside);

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
      document.removeEventListener("touchstart", onClickOutside);
    };
  }, [onClickOutside]);
};
