import { useMemo } from "react";
import { ContentItem, ContentRepositoryId } from "../types";
import useContentRepository from "./useContentRepository";
import useContentSectionItems from "./useContentSectionItems";
import useContentSections from "./useContentSections";
import { fileStore, sectionStore } from "@eolas-medical/core";
import { addMonths } from "date-fns";
import { NO_EXPIRY_DATE } from "Utilities/types";
import { mapToContentItem } from "../helpers";

export type ExpiringPeriod = 1 | 3 | 6 | 12;

interface UseExpiringContentItemsProps {
  contentRepositoryId: ContentRepositoryId;
  expiringPeriod: ExpiringPeriod;
}

interface UseExpiringContentItems {
  expiredItems: ContentItem[];
  itemsExpiringSoon: ContentItem[];
}

const useExpiringContentItems = ({
  contentRepositoryId,
  expiringPeriod,
}: UseExpiringContentItemsProps): UseExpiringContentItems => {
  const { contentRepository } = useContentRepository(contentRepositoryId);
  const { contentSections } = useContentSections(contentRepositoryId);
  const { contentSectionItems } = useContentSectionItems({ contentRepositoryId });

  const allRepositoryItems = useMemo(() => {
    if (contentRepository.childrenType !== "section") {
      return contentSectionItems;
    }

    const combinedItems: any = [];
    contentSections.forEach((child: any) => {
      combinedItems.push(sectionStore.getChildrenOrder(child.id));
    });
    return fileStore.getFiles(combinedItems.flat());
  }, [contentSectionItems, contentSections, contentRepository]);

  const expiredItems = useMemo(() => {
    const today = new Date();
    return allRepositoryItems
      .filter((file) => {
        if (!file.expiryDate) return false;
        return new Date(file.expiryDate).valueOf() < today.valueOf();
      })
      .map(mapToContentItem);
  }, [allRepositoryItems]);

  const itemsExpiringSoon = useMemo(() => {
    const today = new Date();
    const expiresIn = addMonths(new Date(), expiringPeriod);

    return allRepositoryItems
      .filter((file) => {
        if (!file.expiryDate || file.expiryDate === NO_EXPIRY_DATE) return false;
        const fileExpiryDate = new Date(file.expiryDate);

        return (
          fileExpiryDate.valueOf() >= today.valueOf() &&
          fileExpiryDate.valueOf() < expiresIn.valueOf()
        );
      })
      .map(mapToContentItem);
  }, [allRepositoryItems, expiringPeriod]);

  return {
    expiredItems,
    itemsExpiringSoon,
  };
};

export default useExpiringContentItems;
