import { useTranslation } from "react-i18next";
import { Text } from "../../Typography";
import { appConfig } from "AppTypeConfig";

export interface FooterProps {
  commitId: string;
  version?: string;
}

export const Footer: React.FC<FooterProps> = ({ commitId, version }) => {
  const { t } = useTranslation();

  return (
    <footer className="bg-transparent flex flex-col justify-evenly lg:items-start w-full mt-auto">
      <a
        className="text-sm font-extralight"
        href={`mailto:support@eolasmedical.com?subject=${t("general_supportFor", {
          appType: appConfig.appType,
        })}`}
      >
        <Text level={2} className="text-grey-700 font-semibold">
          {t("general_supportEmail")}
        </Text>
      </a>

      <Text level={2} className="text-grey-700 font-normal">
        {t("copyright_version", { date: new Date().getFullYear() })}
        {version && (
          <span>
            —{" "}
            <a href={`https://bitbucket.org/eolas-medical/eolas-web/commits/${commitId}`}>
              v{version}
            </a>
          </span>
        )}
        {/* this button and function are generated by Termly* */}
      </Text>
      <Text level={2} className="text-grey-700 font-normal">
        <button
          className="termly-cookie-preference-button"
          type="button"
          // @ts-ignore
          onClick={() => displayPreferenceModal()}
          id="termly-consent-preferences"
        >
          {t("cookies_consent_button")}
        </button>
      </Text>
    </footer>
  );
};
