import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { InfoIcon } from "Assets";
import { useIsScrollable, useAllowedFileTypes } from "Hooks";

import {
  Input,
  Title,
  Button,
  FormError,
  ModalBody,
  FilePicker,
  FormElement,
  ProgressBar,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
  theme,
} from "UIKit";
import { useAddEolasFile } from "Pages/Spaces/components/AddEolasFile";

interface AddPatientLeafletModalProps {
  leaflet?: EolasFile;
  onCloseModal(): void;
}

export const AddPatientLeafletModal: React.FC<AddPatientLeafletModalProps> = ({
  leaflet,
  onCloseModal,
}) => {
  const { t } = useTranslation();
  const { ref, ScrollHint } = useIsScrollable();
  const allowedFileTypes = useAllowedFileTypes();

  const {
    error,
    control,
    onSubmit,
    progress,
    isLoading,
    formErrors,
    isSuccessful,
    filePickerType,
    filePickerValue,
    onSelectFileType,
    onFilePickerChange,
  } = useAddEolasFile({
    eolasFile: leaflet,
    mainSectionID: AppLevelSection.patientLeaflets,
  });

  if (isSuccessful) {
    return (
      <SuccessModal
        onComplete={onCloseModal}
        text={
          leaflet ? "patientLeaflets_edit_file_success" : "patientLeaflets_add_new_file_success"
        }
      />
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <div className="flex items-center">
          <Title level={5}>
            {t(leaflet ? "patientLeaflets_edit_modal_title" : "patientLeaflets_add_modal_title")}
          </Title>
          <InfoIcon className="ml-2 h-5 w-5" data-tip data-for="info" />
          <ReactTooltip
            id="info"
            border
            type="light"
            place="bottom"
            effect="solid"
            className={"tooltip"}
            backgroundColor={theme.colors.white}
            borderColor={theme.colors.grey.darker}
          >
            <span className="block w-48 font-normal">{t("patientLeaflets_info_tooltip")}</span>
          </ReactTooltip>
        </div>
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="add-file__modal-body">
        <form onSubmit={onSubmit} className="add-file-form">
          <div className="add-file-form__content max-h-60vh" ref={ref}>
            <FormElement
              required
              id="name"
              className="mb-4"
              control={control}
              label={t("patientLeaflets_file_name_label")}
            >
              {(fieldProps) => (
                <Input
                  {...fieldProps}
                  size="lg"
                  disabled={isLoading}
                  placeholder={t("patientLeaflets_file_name_placeholder")}
                />
              )}
            </FormElement>

            <FilePicker
              required
              isDisabled={isLoading}
              value={filePickerValue}
              fileType={filePickerType}
              onChange={onFilePickerChange}
              error={formErrors.key?.message}
              onSelectType={onSelectFileType}
              allowedFileTypes={allowedFileTypes}
            />

            {isLoading && progress > 0 && <ProgressBar progress={progress} />}
          </div>

          <Button
            size="lg"
            type="submit"
            isLoading={isLoading}
            style={{ minHeight: "3rem" }}
            className="add-file__submit-button"
          >
            {t("general_submit")}
          </Button>

          <FormError error={error} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
