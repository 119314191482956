import { useCallback, useEffect, useMemo, useState } from "react";
import { userStore } from "@eolas-medical/core";
import { objectToArray } from "Utilities/helpers";
import MasterSearchContext, { MasterSearchContextValue } from "./masterSearch.context";
import usePersistState from "Hooks/usePersistState/usePersistState";
import { observer } from "mobx-react-lite";

interface PersistedSearch {
  [searchText: string]: number;
}

const MasterSearchProvider: React.FC = observer(({ children }) => {
  const { getPersistedState, persistState } = usePersistState<{
    [userId: string]: PersistedSearch;
  }>();
  const [persistedRecentSearches, setPersistedRecentSearches] = useState<PersistedSearch>({});
  const { userID = "" } = userStore;

  useEffect(() => {
    const getPersistedSearches = async () => {
      const persistedSearches = await getPersistedState("masterSearch");
      if (persistedSearches) {
        setPersistedRecentSearches(persistedSearches[userID]);
      }
    };

    getPersistedSearches();
  }, [getPersistedState, userID]);

  const handleSaveSearch = useCallback(
    async (searchText: string) => {
      const persistedSearches = await getPersistedState("masterSearch");
      setPersistedRecentSearches((actualSearches) => {
        if (searchText.length > 0 && userID.length > 0) {
          const newPersistedSearch = { [searchText.trim()]: Date.now(), ...actualSearches };
          persistState("masterSearch", { ...persistedSearches, [userID]: newPersistedSearch });
          return newPersistedSearch;
        }
        return actualSearches;
      });
    },
    [persistState, getPersistedState, userID],
  );

  const recentSearches = useMemo(() => {
    if (!persistedRecentSearches) return [];

    return objectToArray<PersistedSearch>(persistedRecentSearches)
      .sort((a, b) => {
        return b[1] - a[1];
      })
      .map((r) => ({
        searchText: r[0] as string,
      }));
  }, [persistedRecentSearches]);

  const masterSearchValue: MasterSearchContextValue = {
    recentSearches,
    onSaveSearch: handleSaveSearch,
  };

  return (
    <MasterSearchContext.Provider value={masterSearchValue}>
      {children}
    </MasterSearchContext.Provider>
  );
});

export default MasterSearchProvider;
