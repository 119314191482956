import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { InnerPageWrapper, MainSectionTile, NavButton, PageTitle } from "UIKit";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";
import { SuperviseesIcon, SupervisorsIcon } from "Pages/MeTab/pages/Portfolio/icons";

export const Supervision: React.FC<RouteComponentProps> = ({ match: { url } }) => {
  const { t } = useTranslation();

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-center">
        <PageTitle className="my-4" title={t("portfolio_supervision")} />
        <NavButton to={url.replace(`/${PortfolioConstants.SUPERVISION_URL}`, "")} />
      </div>
      <div className="flex flex-col space-y-6 justify-center items-center lg:flex-row lg:mx-auto lg:space-x-8 lg:space-y-0">
        <div className="w-full">
          <MainSectionTile
            icon={<SupervisorsIcon />}
            title={t("portfolio_supervisors")}
            description={t("portfolio_supervisors_description")}
            to={`${url}/${PortfolioConstants.SUPERVISORS_URL}`}
          />
        </div>

        <div className="w-full">
          <MainSectionTile
            icon={<SuperviseesIcon />}
            title={t("portfolio_supervisees")}
            description={t("portfolio_supervisees_description")}
            to={`${url}/${PortfolioConstants.SUPERVISEES_URL}`}
          />
        </div>
      </div>
    </InnerPageWrapper>
  );
};
