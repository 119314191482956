import React from "react";
import { InformationModal } from "UIKit";
import { useTranslation } from "react-i18next";

type RequestAssistanceModalProps = {
  onRequestAssistance(e: React.MouseEvent<HTMLButtonElement>): void;
};

function RequestAssistanceModal({ onRequestAssistance }: RequestAssistanceModalProps) {
  const { t } = useTranslation();
  return (
    <InformationModal
      iconType="NotReceivedCodeIcon"
      title={t("user_not_received_verification_code")}
      description={t("user_not_received_verification_code_description")}
      doneLabel={t("general_request_assistance")}
      onDone={onRequestAssistance}
    />
  );
}

export default RequestAssistanceModal;
