import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";

import {
  sectionStore,
  EolasFile,
  AppLevelSection,
  MajorIncidentSubSection,
  OrganisationLevelSection,
  CommunityLevelSection,
} from "@eolas-medical/core";

import { generateS3PublicImageURL, fromAwsJSON } from "Utilities";
import { useHighlightFile, useDownloadPDF, useCompleteFile } from "Hooks";

import {
  HowToFileTile,
  ContactFileTile,
  RegularFileTile,
  IncidentFileTile,
  NewsFeedFileTile,
  HospitalFileTile,
  GuidelineFileTile,
  WellbeingFileTile,
  CompletedChecklistTile,
  IncidentReportFileTile,
  EducationPortalFileTile,
  IncidentDocumentFileTile,
  EmergencyActionCardFileTile,
  PatientLeafletFileTile,
} from "UIKit/FileTile";
import { MedusaFileTile } from "UIKit/FileTile/MedusaFileTile";
import { NationalGuidelineFileTile } from "UIKit/FileTile/NationalGuidelineFileTile";

export interface EolasFileListItemProps {
  isAdmin: boolean;
  canDrag?: boolean;
  isDragging?: boolean;
  eolasFile: EolasFile;
  className?: string;
  onEdit?(file: EolasFile): void;
  onDelete?(file: EolasFile): void;
  filterFn?(file: EolasFile): boolean;
  onSelectFile?(file: EolasFile): void;
}

export const EolasFileListItem: React.FC<EolasFileListItemProps> = observer(
  ({ isDragging, canDrag, onEdit, isAdmin, onDelete, eolasFile, onSelectFile, className = "" }) => {
    const { highlightLoading, toggleHighlight } = useHighlightFile();

    const {
      onCompleteFile,
      getCompletedFileProps,
      isLoading: completeFileLoading,
    } = useCompleteFile();

    const { downloadPDF, isLoading } = useDownloadPDF();

    const { icon: mainSectionType } = sectionStore.getMainSectionChildReference(
      eolasFile.mainSectionID!,
    )!;

    const Component = useMemo(() => {
      switch (mainSectionType) {
        case AppLevelSection.incidentReporting:
          return <IncidentReportFileTile isAdmin eolasFile={eolasFile} />;
        case AppLevelSection.majorIncidents:
          const { icon: majorIncidentType } = sectionStore
            .getChildrenOrder(eolasFile.mainSectionID as string)
            .find((ref) => ref!.id === eolasFile.parentID)!;
          if (majorIncidentType === MajorIncidentSubSection.incidents) {
            return <IncidentFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
          } else if (majorIncidentType === MajorIncidentSubSection.documents) {
            return <IncidentDocumentFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
          } else {
            return null;
          }
        case AppLevelSection.hospitalList:
          return <HospitalFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case AppLevelSection.patientLeaflets:
          return <PatientLeafletFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case OrganisationLevelSection.communicationPortal:
        case AppLevelSection.newsFeed:
          return (
            <NewsFeedFileTile
              isAdmin={isAdmin}
              eolasFile={eolasFile}
              {...getCompletedFileProps(eolasFile)}
            />
          );
        case AppLevelSection.clinicalPathways:
        case AppLevelSection.guidelines:
        case OrganisationLevelSection.hospitalGuidelines:
          return (
            <GuidelineFileTile
              isAdmin={isAdmin}
              eolasFile={eolasFile}
              organisationImageURL={
                eolasFile.moreInfo ? generateS3PublicImageURL(eolasFile.moreInfo!) : undefined
              }
            />
          );
        case AppLevelSection.admissionGuides:
        case AppLevelSection.howTo:
        case AppLevelSection.equipmentLocations:
          return <HowToFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case AppLevelSection.contacts:
        case OrganisationLevelSection.hospitalContacts:
          return <ContactFileTile eolasFile={eolasFile} isAdmin={isAdmin} />;
        case OrganisationLevelSection.learningManagement:
        case AppLevelSection.educationPortal:
          return (
            <EducationPortalFileTile
              isAdmin={isAdmin}
              eolasFile={eolasFile}
              loading={completeFileLoading}
              onMarkAsComplete={onCompleteFile}
              {...getCompletedFileProps(eolasFile)}
            />
          );
        case AppLevelSection.researchAndAudit:
        case AppLevelSection.wellbeing:
          return <WellbeingFileTile eolasFile={eolasFile} isAdmin={isAdmin} />;
        case AppLevelSection.emergencyActionCards:
          return <EmergencyActionCardFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case AppLevelSection.checklists:
          const { completedChecklist } = fromAwsJSON(eolasFile.metadata ?? '"{}"');

          const isComplete = completedChecklist.checklistSections.every((section: any) => {
            return section.sectionItems.every((item: any) => {
              if (item.itemType === "checkbox") return item.checked;
              return !!item.inputInfo;
            });
          });

          return (
            <CompletedChecklistTile
              isAdmin={isAdmin}
              eolasFile={eolasFile}
              isLoading={isLoading}
              onClickDownload={downloadPDF}
              hasIssues={completedChecklist.checklistIssues}
              status={isComplete ? "completed" : "incomplete"}
            />
          );
        case OrganisationLevelSection.medusaMedications:
          return <MedusaFileTile isMedusaFile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case CommunityLevelSection.nationalGuidelines:
        case CommunityLevelSection.communityPatientLeaflets:
          return <NationalGuidelineFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
        case AppLevelSection.rotas:
        case AppLevelSection.surveys:
        case AppLevelSection.importantLinks:
        default:
          return <RegularFileTile isAdmin={isAdmin} eolasFile={eolasFile} />;
      }
    }, [
      mainSectionType,
      eolasFile,
      isAdmin,
      getCompletedFileProps,
      completeFileLoading,
      onCompleteFile,
      isLoading,
      downloadPDF,
    ]);

    if (!Component) return null;

    return React.cloneElement(Component, {
      canDrag,
      className,
      isDragging,
      highlightLoading,
      onEdit,
      onDelete,
      onSelectFile,
      onHighlight: toggleHighlight,
    });
  },
);
