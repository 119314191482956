import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createSupervisionRequest } from "../client/portfolio.client";
import portfolioKeys from "./portfolio.queryKeys";
import { SupervisorRequestCreatedBy } from "../types";
import { SupervisorsList, SupervisionSummary } from "../client/portfolio.graphql";
import { replaceObject } from "Utilities/helpers";

const useSendReminderSupervision = (createdBy: SupervisorRequestCreatedBy) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(createSupervisionRequest, {
    onSuccess: (newSupervisionRequest) => {
      if (createdBy === SupervisorRequestCreatedBy.SUPERVISEE) {
        queryClient.setQueryData(
          portfolioKeys.supervisorsList(),
          (actualRequests: SupervisorsList | undefined) => {
            if (actualRequests) {
              return {
                ...actualRequests,
                supervisionRequestsSummary: replaceObject(
                  actualRequests.supervisionRequestsSummary as SupervisionSummary[],
                  newSupervisionRequest,
                  "id",
                ),
              };
            }
          },
        );
      }
    },
  });

  return {
    sendReminderSupervision: mutate,
    sendingReminderSupervision: isLoading,
    ...rest,
  };
};

export default useSendReminderSupervision;
