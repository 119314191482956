import useSpaceUsers from "./useSpaceUsers";

export const useSpaceAdmins = (departmentId: string) => {
  const { spaceUsers, isLoadingSpaceUsers } = useSpaceUsers(departmentId);

  return {
    spaceAdmins: spaceUsers.filter(
      ({ accessLevel }: { accessLevel: string }) => accessLevel === "admin",
    ),
    isLoadingSpaceAdmins: isLoadingSpaceUsers,
  };
};
