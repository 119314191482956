import React, { useCallback, useEffect, useRef, useState } from "react";
import { useClickOutside } from "Hooks";

export interface DropdownOption {
  value: string;
  label: string;
  disabled?: boolean;
  icon?: JSX.Element;
}

interface DropdownProps {
  dropdownToggle: React.ReactElement;
  options: DropdownOption[];
  onSelect: (option: DropdownOption) => void;
  disabled?: boolean;
}

export const Dropdown = ({
  dropdownToggle,
  options,
  disabled = false,
  onSelect,
  ...rest
}: DropdownProps) => {
  const [on, setOn] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setOn(false));

  const handleClick = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    setOn((prev) => !prev);
  };

  const handleSelect = useCallback(
    (event: React.MouseEvent, option: DropdownOption) => {
      event.preventDefault();
      event.stopPropagation();
      onSelect(option);
      setOn(false);
    },
    [onSelect],
  );

  const handleMouseLeave = () => {
    setOn(false);
  };

  useEffect(() => {
    if (on && disabled) setOn(false);
  }, [disabled, on]);

  return (
    <div className="" {...rest}>
      {React.cloneElement(dropdownToggle, {
        ...dropdownToggle.props,
        onClick: handleClick,
        onMouseLeave: handleMouseLeave,
        disabled,
        "data-testid": "dropdown-toggle",
      })}
      {on && (
        <div
          className="overflow-hidden absolute right-0 top-100 rounded-md bg-grey drop-shadow-dropdown"
          ref={dropdownRef}
          onMouseLeave={() => setOn(false)}
          onMouseEnter={() => setOn(true)}
        >
          {options.map((option, index) => (
            <button
              className={`flex items-center px-4 py-2 w-full text-left hover:bg-grey-hover ${
                disabled ? "bg-grey-pale text-grey-light" : index % 2 === 0 ? "bg-white" : ""
              }`}
              disabled={option.disabled}
              key={option.value}
              onClick={(event) => handleSelect(event, option)}
              data-testid={option.value}
            >
              {option.icon}
              <span className="ml-4" />
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
