import { AnalyticsEvents, fetchAllData, userStore } from "@eolas-medical/core";
import { identifyUser, trackEvent } from "API/Analytics";
import { useGetRelevantMeAndCommunityData } from "Hooks/useGetRelevantMeAndCommunityData";
import { errorStore } from "Stores/ErrorStore";

export const useAppSync = () => {
  const { fetchRegionalMeAndCommunityData } = useGetRelevantMeAndCommunityData();
  const sync = async () => {
    try {
      await fetchRegionalMeAndCommunityData();
      await fetchAllData();
      identifyUser(userStore.userData);
      trackEvent(AnalyticsEvents.EOLAS_SESSION_START);
    } catch (error: any) {
      errorStore.captureError({ error, source: "network", retryCallback: fetchAllData });
    }
  };
  return { sync };
};
