import { Editor } from "@tinymce/tinymce-react";
import envConfig from "env-config";
import { Loader } from "UIKit/Loader";
import { Editor as TinyMCEEditor } from "tinymce";
import { useState } from "react";
import { FormError } from "UIKit";
import { useTranslation } from "react-i18next";
import { ContentRepositoryId } from "modules/contentRepository/types";
import { useHandleImageUpload } from "./hooks/useHandleImageUpload";

interface FlashcardCreatorStepProps {
  flashcardTitle?: string;
  editorContent?: string;
  flashcardDescription?: string;
  onEditorInit: (evt: any, editor: TinyMCEEditor) => void;
  isLoading: boolean;
  contentRepositoryId: ContentRepositoryId;
}

const getEditorConfig = (handleImageUpload: (blobInfo: any, progress: any) => Promise<string>) => ({
  height: 300,
  menubar: false,
  plugins: ["lists", "preview", "table", "link", "image"],
  toolbar: "link| image | blocks | bold italic | bullist numlist | preview | table tabledelete ", // Add the image and preview buttons
  content_style: `
    body { font-family: "Nunito",Helvetica,Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-size:14px }
    h1 { font-size: 1.75rem; font-weight: 700; margin: 0.5rem 0;}
    h2 { font-size: 1.5rem; font-weight: 700; margin: 0.5rem 0;  }
    h3 { font-size: 1.25rem; font-weight: 700; margin: 0.5rem 0;}
    p { font-size: 1rem;}
    ul { list-style-type: disc; padding-left: 1.25rem; }
    ol { list-style-type: decimal; padding-left: 1.25rem; }
    li { margin-bottom: 0.5rem; }
    table { width: 100%; border-collapse: collapse; }
    th, td { border: 1px solid #e2e8f0; padding: 0.25rem 1rem; }
    thead { background-color: #edf2f7; }
    tbody tr:nth-child(odd) { background-color: #f7fafc; }
    a { color: #3B82F6; text-decoration: underline; }
    a:hover { color: #1E40AF; }
    img { display: block; margin-left: auto; margin-right: auto; }
    .w-full { width: 100%; height: auto; }
  `,
  paste_data_images: false,
  paste_as_text: true,
  paste_block_drop: false,
  paste_tab_spaces: 2,
  block_formats: "Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3",
  default_link_target: "_blank",
  link_assume_external_targets: true,
  images_upload_handler: handleImageUpload,
  image_class_list: [
    { title: "None", value: "" },
    { title: "Full Width", value: "w-full" },
  ],
});

const MAX_SIZE_IN_BYTES = 400 * 1024; // 400KB

export const FlashcardCreatorStep = ({
  isLoading,
  editorContent,
  flashcardTitle,
  contentRepositoryId,
  flashcardDescription,
  onEditorInit,
}: FlashcardCreatorStepProps) => {
  const [isSizeExceeded, setIsSizeExceeded] = useState(false);
  const { t } = useTranslation();
  const handleImageUpload = useHandleImageUpload(contentRepositoryId);
  const editorConfiguration = getEditorConfig(handleImageUpload);

  const handleContentChange = (_: any, editor: TinyMCEEditor) => {
    const content = editor.getContent();
    const encodedContent = encodeURIComponent(content);
    const byteSize = new Blob([encodedContent]).size;

    setIsSizeExceeded(byteSize > MAX_SIZE_IN_BYTES);
  };
  const getInitialValue = () => {
    if (editorContent) {
      return editorContent;
    } else {
      return `<div>
      <h1 style='text-align: left;'>${flashcardTitle}</h1>
      <p style='text-align: left;'>${flashcardDescription}</p>
    </div>`;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Editor
        apiKey={envConfig.REACT_APP_TINYCLOUND_KEY}
        onInit={onEditorInit}
        onKeyUp={handleContentChange}
        onChange={handleContentChange}
        initialValue={getInitialValue()}
        init={editorConfiguration}
      />
      {isSizeExceeded && <FormError error={t("flashcard_editor_size_exceeded")} />}
    </>
  );
};
