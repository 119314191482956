import { SignInStep } from "Pages/Authentication/types";
import { Step } from "UIKit";
import EmailNotVerifiedModal from "./EmailNotVerifiedModal";
import { MailIcon } from "Assets";
import { useTranslation } from "react-i18next";

interface UseEmailNotVerifiedStepProps {
  email: string;
  isLoading: boolean;
  canProceed: boolean;
  error?: string;
  onSubmit: () => void;
}
const useEmailNotVerifiedStep = ({
  email,
  isLoading,
  canProceed,
  error,
  onSubmit,
}: UseEmailNotVerifiedStepProps): Step<SignInStep> => {
  const { t } = useTranslation();
  return {
    name: "email-not-verified",
    title: t("user_email_not_verified"),
    subtitle: email,
    component: <EmailNotVerifiedModal />,
    onlySubmitStep: true,
    onSubmit: onSubmit,
    isLoading,
    canProceed,
    submitError: !!error,
    nextLabel: t("user_request_code"),
    topIcon: <MailIcon />,
  };
};

export default useEmailNotVerifiedStep;
