import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import { ProfileVerificationStatus, jobTitleOptions, userStore } from "@eolas-medical/core";

import { InnerPageWrapper, MainSectionTile } from "UIKit";
import { MyFilesIcon, PortfolioHomepageIcon } from "Assets";
import { LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts";
import ProfileDetails from "./pages/MyProfile/components/ProfileDetails/ProfileDetails";
import { useMemo } from "react";
import { useShowProfileVerification } from "Hooks/useShowProfileVerification/useShowProfileVerification";

const containerVariants: Variants = {
  out: {
    opacity: 0.5,
  },
  in: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const childrenVariants: Variants = {
  out: { x: -50, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

export const MeTab: React.FC<RouteComponentProps> = ({ match: { url } }) => {
  const { t } = useTranslation();
  const { userData: user } = userStore;
  const { showProfileVerification } = useShowProfileVerification();

  const { givenName, familyName, jobTitle, photoUrl, location, profileVerification } = user;

  const { flags } = useLaunchDarkly();
  const isPortfolioActive = flags[LDFlagNames.TEACHING_DELIVERY];
  const isMyFilesActive = flags[LDFlagNames.MY_FILES];
  const { push } = useHistory();

  const preloadedJobTitle = useMemo(() => {
    return jobTitleOptions.find((option) => option.value === jobTitle);
  }, [jobTitle]);

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-center items-center">
        <ProfileDetails
          firstName={givenName || ""}
          lastName={familyName || ""}
          jobTitle={preloadedJobTitle?.label || ""}
          imageUrl={photoUrl || ""}
          location={location}
          verificationStatus={
            showProfileVerification
              ? profileVerification?.status || ProfileVerificationStatus.UNVERIFIED
              : undefined
          }
          onClick={() => push(`${url}/personal-details`)}
        />
      </div>
      <motion.div className="flex flex-col" variants={containerVariants} initial="out" animate="in">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6 lg:gap-8">
          {isPortfolioActive && (
            <motion.div
              variants={childrenVariants}
              whileHover={{ scale: 1.01 }}
              className="mt-4 flex flex-col divide-y divide-grey-light"
            >
              <MainSectionTile
                icon={<PortfolioHomepageIcon className="h-8 w-8 lg:h-12 lg:w-12" />}
                title={t("portfolio_homepage")}
                description={t("portfolio_homepage_description")}
                to={`${url}/portfolio`}
              />
            </motion.div>
          )}

          {isMyFilesActive && (
            <motion.div
              variants={childrenVariants}
              whileHover={{ scale: 1.01 }}
              className="mt-4 flex flex-col divide-y divide-grey-light"
            >
              <MainSectionTile
                icon={<MyFilesIcon className="h-8 w-8 lg:h-12 lg:w-12" />}
                title={t("my_files_homepage")}
                description={t("my_files_description")}
                to={`${url}/my-files`}
              />
            </motion.div>
          )}
        </div>
      </motion.div>
    </InnerPageWrapper>
  );
};
