import { EolasMultipleList } from "Components/EolasFileList/EolasMultipleList";
import useLocalMultipleSearch from "Hooks/useLocalMultipleSearch/useLocalMultipleSearch";
import { SupervisionSummary } from "modules/portfolio/types";
import { Text } from "UIKit";
import { useCallback, useMemo } from "react";

interface SupervisionListProps {
  items: SupervisionSummary[][];
  isLoading: boolean;
  renderSupervisionRequest: (item: SupervisionSummary) => React.ReactNode;
  pendingRequestTitle: string;
  approvedRequestTitle: string;
  emptySupervisionText: string;
}

const SupervisionList = ({
  items,
  isLoading,
  renderSupervisionRequest,
  pendingRequestTitle,
  approvedRequestTitle,
  emptySupervisionText,
}: SupervisionListProps) => {
  const { searchResult, onSetSearchInput } = useLocalMultipleSearch<SupervisionSummary>({
    data: items,
    keysToSearch: ["name", "email"],
  });

  const supervisorListItems = useMemo(() => {
    return [
      { subtitle: pendingRequestTitle, items: searchResult[0] },
      { subtitle: approvedRequestTitle, items: searchResult[1] },
    ];
  }, [searchResult, pendingRequestTitle, approvedRequestTitle]);

  const renderSubtitle = useCallback((subtitle: string) => {
    return (
      <div className="flex justify-center mb-8">
        <Text level={2}>{subtitle}</Text>
      </div>
    );
  }, []);

  const NoSupervisionContent = () => {
    return (
      <div
        data-testid="no-supervision-text"
        className=" w-full flex justify-center items-center py-9"
      >
        <Text className="text-center text-grey-600 font-bold" level={1}>
          {emptySupervisionText}
        </Text>
      </div>
    );
  };

  return (
    <EolasMultipleList<SupervisionSummary>
      isSearchable
      onSearchInputChange={onSetSearchInput}
      items={supervisorListItems}
      renderItem={(item) => {
        return renderSupervisionRequest(item);
      }}
      renderSubtitle={renderSubtitle}
      isLoading={isLoading}
      emptyItemsComponent={<NoSupervisionContent />}
    />
  );
};

export default SupervisionList;
