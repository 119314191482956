import { FC, useCallback } from "react";
import {
  FilterElementArray,
  FilterFieldNames,
  EventType,
  EventStatus,
  TimelineEventStatus,
  FeedbackStatus,
} from "modules/portfolio/types";
import {
  Control,
  useController,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import { Text, SimpleButton } from "UIKit";
import useTimelineButtonConfig from "./hooks/useTimelineButtonConfig";
import { theme } from "UIKit/theme";
import { isEventStatus, isFeedbackStatus } from "Pages/MeTab/pages/Portfolio/helpers";

interface FilterSectionElementProps {
  sectionTitle: string;
  control: Control<any>;
  fieldName: FilterFieldNames;
  fieldList: (EventStatus | FeedbackStatus | EventType)[];
  className?: string;
  errors?: FieldErrors;
  getValues?: UseFormGetValues<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  trigger?: UseFormTrigger<{
    startDate: string;
    endDate: string;
    eventType: string[];
    eventStatus: string[];
  }>;
  type: FilterFieldNames.eventType | FilterFieldNames.eventStatus;
}

export const FilterSectionElement: FC<FilterSectionElementProps> = ({
  className,
  control,
  fieldList,
  fieldName,
  sectionTitle,
  type,
}) => {
  const {
    eventTypeButtonConfig,
    eventStatusButtonConfig,
    feedbackStatusButtonConfig,
  } = useTimelineButtonConfig();
  const {
    field: { onChange, value },
  } = useController({ control, name: fieldName });

  const filterElementArray: FilterElementArray = value;

  const handleFilterSelection = useCallback(
    ({
      filterType,
      eventName,
    }: {
      filterType: FilterFieldNames.eventType | FilterFieldNames.eventStatus;
      eventName: EventType | EventStatus | FeedbackStatus;
    }) => {
      if (filterType === FilterFieldNames.eventType) {
        const eventType = filterElementArray as EventType[];
        const typeItem = eventType.find((elementName) => elementName === eventName);
        let newEventTypeArray: FilterElementArray;
        if (typeItem) {
          newEventTypeArray = eventType.filter((element) => element !== typeItem);
        } else {
          newEventTypeArray = [...eventType, eventName as EventType];
        }
        onChange(newEventTypeArray);
      }
      if (filterType === FilterFieldNames.eventStatus) {
        const eventStatuses = filterElementArray as TimelineEventStatus[];
        const statusItem = eventStatuses.find((elementName) => elementName === eventName);
        let newEventStatusArray: FilterElementArray;
        if (statusItem) {
          newEventStatusArray = eventStatuses.filter((element) => element !== statusItem);
        } else {
          newEventStatusArray = [...eventStatuses, eventName as EventStatus];
        }
        onChange(newEventStatusArray);
      }
    },
    [onChange, filterElementArray],
  );

  return (
    <div className={className}>
      <Text className="pb-4" level={1}>
        {sectionTitle}
      </Text>
      <div className="my-2">
        {fieldList.map((item) => {
          let badgeText = "";
          let textColor = "";
          let backgroundColor = "";

          if (isEventStatus(item)) {
            const castedFilterArray = filterElementArray as TimelineEventStatus[];
            const filterSelected = castedFilterArray.find((elementName) => elementName === item);
            badgeText = eventStatusButtonConfig[item]?.badgeText;
            textColor = filterSelected
              ? eventStatusButtonConfig[item]?.badgeTextColor
              : "text-black";
            backgroundColor = filterSelected
              ? eventStatusButtonConfig[item]?.backgroundColor
              : "bg-grey-100";
          }

          if (isFeedbackStatus(item)) {
            const castedFilterArray = filterElementArray as TimelineEventStatus[];
            const filterSelected = castedFilterArray.find((elementName) => elementName === item);
            badgeText = feedbackStatusButtonConfig[item]?.badgeText;
            textColor = filterSelected
              ? feedbackStatusButtonConfig[item]?.badgeTextColor
              : "text-black";
            backgroundColor = filterSelected
              ? feedbackStatusButtonConfig[item]?.backgroundColor
              : "bg-grey-100";
          }

          if (!isEventStatus(item) && !isFeedbackStatus(item)) {
            const castedFilterArray = filterElementArray as EventType[];
            const filterSelected = castedFilterArray.find((elementName) => elementName === item);
            badgeText = eventTypeButtonConfig[item as EventType]?.badgeText;
            textColor = filterSelected ? "text-white" : "text-black";
            backgroundColor = filterSelected ? "bg-blue-500" : "bg-grey-100";
          }

          return (
            <SimpleButton
              backgroundColor={backgroundColor}
              key={item}
              text={badgeText}
              textColor={textColor as keyof typeof theme.colors}
              onClick={() =>
                handleFilterSelection({
                  filterType: type,
                  eventName: item,
                })
              }
            />
          );
        })}
      </div>
    </div>
  );
};
