export const medusaIconsMapper = (iconName: string) => {
  switch (iconName) {
    case "paediatric":
    case "paediatricIntravenous":
      return "IntraVenousPaediatricIcon";
    case "adult":
      return "AdultIcon";
    case "adultIntravenous":
      return "IntraVenousAdultIcon";
    case "subcutaneous":
      return "SubcutaneousIcon";
    case "intramuscular":
      return "IntraMuscularIcon";
    case "ocular":
      return "IntraOcularIcon";
    case "intrathecal":
      return "IntrathecalIcon";
    default:
      return "MedusaIcon";
  }
};
