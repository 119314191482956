import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { fetchAllData, sectionStore } from "@eolas-medical/core";

import { useSentry } from "Contexts";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { ChildReference } from "Utilities/types";
import type { IconPickerIconsType } from "Assets/Icons/iconPickerIcons";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { Icon } from "UIKit/IconPicker/types";

export const PUBLISH_SECTION = gql`
  mutation PublishSection($parentID: String!, $input: PublishSectionInput!) {
    publishSection(parentID: $parentID, input: $input) {
      id
      childrenOrder {
        id
        type
        icon
        name
        disabled
        description
      }
    }
  }
`;

export const UPDATE_SECTION_INFO = gql`
  mutation UpdateSectionInfo($parentID: String!, $input: UpdateSectionInfoInput!) {
    updateSectionInfo(parentID: $parentID, input: $input) {
      id
      childrenOrder {
        id
        type
        icon
        name
        disabled
        description
      }
    }
  }
`;

type SectionFormShape = {
  name: string;
  icon: string;
  description: string;
};

const mapToDefaultValues = (sectionReference?: ChildReference): SectionFormShape => {
  return {
    name: sectionReference?.name ?? "",
    description: sectionReference?.description ?? "",
    icon: (sectionReference?.icon ?? "") as IconPickerIconsType,
  };
};

export const useAddSection = (parentID: string, sectionReference?: ChildReference) => {
  const { addBreadcrumb } = useSentry();
  const { getIconUrl } = useGetIconUrl();

  const [publishSection] = useMutation(PUBLISH_SECTION);
  const [updateSectionInfo] = useMutation(UPDATE_SECTION_INFO);

  const [isAddingIcon, setIsAddingIcon] = useState(false);
  const { error, isLoading, isSuccessful, setRequestStatus } = useRequestStatus();

  const { control, handleSubmit, setValue, watch } = useForm<SectionFormShape>({
    defaultValues: mapToDefaultValues(sectionReference),
  });

  const [name, iconFileName] = watch(["name", "icon", "description"]);

  const isFormComplete = useMemo(() => {
    return !!name && !!iconFileName;
  }, [name, iconFileName]);

  const iconURL = useMemo(() => getIconUrl(iconFileName), [iconFileName, getIconUrl]);

  const selectIcon = (icon: Icon) => {
    setValue("icon", icon.fileName);
  };

  const onPublishSection = ({ name, description, icon }: SectionFormShape) => {
    addBreadcrumb({
      category: "Publish Section",
      message: "publishing section",
      data: {
        name,
      },
    });

    setRequestStatus({ status: "pending", error: "" });
    publishSection({
      variables: {
        parentID,
        input: {
          name,
          icon,
          description,
          type: "section",
        },
      },
    })
      .then(async () => {
        setRequestStatus({ status: "success", error: "" });
        await fetchAllData();
      })
      .catch((error: any) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          retryCallback: publishSection,
          retryParameters: {
            variables: {
              parentID,
              input: {
                name,
                icon,
                description,
                type: "section",
              },
            },
          },
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  };

  const onEditSection = async ({ name, description, icon }: SectionFormShape) => {
    addBreadcrumb({
      category: "Edit Section",
      message: "editing section",
      data: {
        name,
        id: sectionReference?.id,
      },
    });

    const { parentID, ownerID } = sectionStore.getSection(sectionReference!.id)!;

    setRequestStatus({ status: "pending", error: "" });

    updateSectionInfo({
      variables: {
        parentID: sectionReference?.type === "mainSection" ? ownerID : parentID,
        input: {
          name,
          icon,
          description,
          id: sectionReference?.id,
        },
      },
    })
      .then(async (response) => {
        if (!isEmpty(response.errors)) {
          throw new Error("general_error");
        }

        await fetchAllData();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error: any) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          retryParameters: {
            variables: {
              parentID: sectionReference?.type === "mainSection" ? ownerID : parentID,
              input: {
                name,
                icon,
                description,
                id: sectionReference?.id,
              },
            },
          },
          retryCallback: updateSectionInfo,
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  };

  const onSubmit = handleSubmit(({ name, description, icon }) => {
    if (name && !icon) {
      setIsAddingIcon(true);
      return;
    }

    if (!isFormComplete) return;

    if (sectionReference) {
      onEditSection({ name, description, icon });
    } else {
      onPublishSection({ name, description, icon });
    }
  });

  return {
    name,
    error,
    iconURL,
    control,
    onSubmit,
    isLoading,
    selectIcon,
    isAddingIcon,
    isSuccessful,
    setIsAddingIcon,
    isFormComplete: true,
  };
};
