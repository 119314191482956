import { useTranslation } from "react-i18next";

import { EolasFile } from "Utilities/types";
import { Title, ModalHeader, InnerModalWrapper, ModalBody } from "UIKit";

import { handleContact } from "../helper";

export interface ContactDetailsModalProps {
  contact: EolasFile;
}

export const ContactDetailsModal: React.FC<ContactDetailsModalProps> = ({ contact }) => {
  const { t } = useTranslation();

  const isClickable = contact?.type !== "pager";

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("contacts_modal_addNew_details")}</Title>
      </ModalHeader>

      <ModalBody className="grid grid-cols-1 gap-4 md:gap-0 md:grid-cols-2">
        <div className="flex flex-col">
          <span className="font-semibold">Name</span>
          <span className="">{contact.name}</span>
        </div>

        <div className="flex flex-col">
          <span className="font-semibold">{t(`contacts_modal_addNew_${contact.type}`)}</span>
          <a href={handleContact(contact)} target="_blank" rel="noreferrer">
            <span className={isClickable ? "underline cursor-pointer " : "cursor-not-allowed"}>
              {contact.description}
            </span>
          </a>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};
