import { URL_REGEX } from "Utilities/helpers";
import { EolasFileType } from "Utilities/types";
import * as yup from "yup";

export const useChooseFileTypeStepSchema = (selectedType: EolasFileType) => {
  if (selectedType === "blob") {
    return yup.object().shape({
      blob: yup
        .mixed()
        .required("A file is required")
        .test("fileSize", "Please ensure file size is less than 2 GB", (value) => {
          return value && value.size <= 2147483648; // Example size limit: 2GB. Adjust as needed.
        }),
    });
  }

  if (selectedType === "link") {
    return yup.object().shape({
      link: yup.object().shape({
        name: yup.string().required("Name is required").notOneOf([""], "Name cannot be empty"),
        url: yup.string().matches(URL_REGEX, "Enter valid URL!").required("Url is required"),
      }),
    });
  }

  return yup.object().shape({});
};
