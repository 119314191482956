import { useLaunchDarkly } from "Contexts";
import { useNotifications } from "Components/Notifications";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import { LDFlagNames } from "Utilities/types";

const disableWhenInPrtialMainananceMode = [
  "majorIncidents",
  "checklists",
  "incidentReporting",
  "personal-details",
  "signup",
  "invitation",
];

export const usePartialMaintenanceModeRedirect = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const { flags } = useLaunchDarkly();
  const { showNotification } = useNotifications();

  useEffect(() => {
    if (flags[LDFlagNames.READ_ONLY_ACTIVATED]) {
      const restricted = disableWhenInPrtialMainananceMode.some((route) =>
        pathname.includes(route),
      );
      if (restricted) {
        // show notificatons banner and redirect to previous page
        showNotification({
          type: "error",
          description: t("read_only_section_unavalible"),
        });
        history.goBack();
      }
    }
  }, [flags, history, pathname, showNotification, t]);
};
