import { gql } from "@apollo/client";
import { fetchAllData } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { makeApolloClient } from "API/functions/makeApolloClient";
import { mutateApollo } from "API/functions/mutateApollo";
import envConfig from "env-config";

const REMOVE_SECTION = gql`
  mutation RemoveSection($parentID: String!, $id: String!) {
    removeSection(parentID: $parentID, id: $id)
  }
`;

const apiUrl = `https://${envConfig.REACT_APP_API_BASE_URL}/proxy/graphql`;

const sectionClient = makeApolloClient(apiUrl);

const deleteSection = async ({ parentID, sectionId }: { parentID: string; sectionId: string }) => {
  await mutateApollo(sectionClient, {
    mutation: REMOVE_SECTION,
    variables: { parentID, id: sectionId },
  });
};

const useDeleteContentSection = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(deleteSection, {
    onSuccess: async () => {
      await fetchAllData();
    },
  });

  return {
    deleteContentSection: mutate,
    deletingContentSection: isLoading,
    deleteContentSectionSuccess: isSuccess,
    deleteContentSectionError: error as string,
  };
};

export default useDeleteContentSection;
