import useRemoteIcons from "Hooks/useRemoteIcons";
import { Step, Wizard, useConfigureSectionStep, useWizard } from "UIKit/Wizard";
import useAddContentSection from "modules/contentRepository/data/useAddContentSection";
import { ContentRepositoryId } from "modules/contentRepository/types";

interface AddContentSectionModalProps {
  contentRepositoryId: ContentRepositoryId;
  onClose: () => void;
}

const AddContentSectionModal = ({ contentRepositoryId, onClose }: AddContentSectionModalProps) => {
  const { remoteIcons } = useRemoteIcons();
  const { wizardControl, submitWizard } = useWizard();
  const {
    addContentSection,
    addingContentSection,
    addContentSectionError,
  } = useAddContentSection();

  const handleSubmit = ({ name, iconName }: { name: string; iconName: string }) => {
    addContentSection(
      {
        contentRepositoryId,
        section: {
          name,
          iconName,
          type: "section",
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  const createSectionStep = useConfigureSectionStep<"section">({
    title: "Add new Section",
    stepName: "section",
    iconsList: remoteIcons,
    isLoading: addingContentSection,
    error: addContentSectionError,
    onSubmit: handleSubmit,
    onEnter: submitWizard,
    onCancel: onClose,
  });

  const steps: Step<"section">[] = [createSectionStep];

  return <Wizard steps={steps} wizardControl={wizardControl} />;
};

export default AddContentSectionModal;
