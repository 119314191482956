import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";

export const NationalGuidelineFileTile: React.FC<FileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  isDragging,
  onSelectFile = () => {},
  ...handlers
}) => {
  return (
    <FileTileWrapper
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col justify-between flex-3 h-32">
        <span className="text-lg font-semibold line-clamp-2" title={eolasFile.name ?? ""}>
          {eolasFile.name}
        </span>
        <span className="text-md text-grey-dark line-clamp-2">{eolasFile.description}</span>
      </div>

      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
