import { Dispatch, useCallback, useState } from "react";
import Fuse from "fuse.js";
export interface UseLocalSearchProps<T> {
  data: T[];
  keysToSearch: string[];
}
interface ResultWithMatches<T> {
  result: T;
  matches: readonly Fuse.FuseResultMatch[];
}
export interface UseLocalSearch<T> {
  searchInput: string;
  searchResult: T[];
  searchResultWithMatches: ResultWithMatches<T>[];
  onSetSearchInput: Dispatch<string>;
}
const useLocalSearch = <T extends any>({
  data,
  keysToSearch,
}: UseLocalSearchProps<T>): UseLocalSearch<T> => {
  const [searchText, setSearchText] = useState<any>("");
  const fuseData = useCallback(() => {
    if (searchText.length === 0) {
      return {
        results: data,
        resultsWithMatches: data.map((d) => ({ result: d, matches: [] })),
      };
    }

    const options = {
      keys: keysToSearch,
      ignoreLocation: true,
      minMatchCharLength: 2,
      distance: 90,
      threshold: 0.15,
      includeMatches: true,
    };
    const fuse = new Fuse(data, options);

    const fuseSearch = fuse.search(searchText);

    return {
      results: fuseSearch.map(({ item }) => item),
      resultsWithMatches: fuseSearch.map(({ item, matches }) => ({
        result: item,
        matches: matches as readonly Fuse.FuseResultMatch[],
      })),
    };
  }, [data, searchText, keysToSearch]);

  const { results, resultsWithMatches } = fuseData();

  return {
    searchInput: searchText,
    searchResult: results,
    searchResultWithMatches: resultsWithMatches,
    onSetSearchInput: setSearchText,
  };
};
export default useLocalSearch;
