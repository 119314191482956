import { useCallback, useMemo } from "react";
import { regionToCountry } from "./regionToCountry";
import { countries } from "./countries";

const useBrowserCountry = () => {
  const browserTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const browserCountry = regionToCountry[browserTimezone];
  const isBrowserCountry = useCallback(
    (countries: string[]) => {
      return countries.some((c) => c === browserCountry);
    },
    [browserCountry],
  );

  const browserCountryCode = useMemo(() => {
    return Object.keys(countries).find((key: string) => countries[key] === browserCountry) || "GB";
  }, [browserCountry]);

  return { browserCountry, browserCountryCode, isBrowserCountry };
};

export default useBrowserCountry;
