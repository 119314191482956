import {
  PortfolioHomepageIcon,
  PersonalDetailsIcon,
  JPGIcon,
  OfficeFileIcon,
  PNGIcon,
  SendIcon,
  TimeIcon,
  CheckedIcon,
  MyFilesIcon,
  PPTFileIcon,
  TXTIcon,
  WeblinkIcon,
  XLSIcon,
  PDFIcon,
  MP4Icon,
  FolderIcon,
  MyPortfolioIcon,
} from "./colored";

import {
  AttachIcon,
  CloseCircleFilledIcon,
  CommentIcon,
  DeleteSectionIcon,
  FileIcon,
  FilterIcon,
  FormIcon,
  AddCircleIcon,
  EyeIcon,
  AddIcon,
  CloseIcon,
  QrCode,
  CommentBubbleIcon,
  HeartIcon,
  ExportIcon,
} from "./monocolored";

export const meTabIcons = {
  AddIcon,
  AddCircleIcon,
  AttachIcon,
  CheckedIcon,
  CloseIcon,
  CloseCircleFilledIcon,
  CommentBubbleIcon,
  CommentIcon,
  DeleteSectionIcon,
  EyeIcon,
  FileIcon,
  FilterIcon,
  FolderIcon,
  FormIcon,
  HeartIcon,
  JPGIcon,
  MyFilesIcon,
  MP4Icon,
  OfficeFileIcon,
  PNGIcon,
  PDFIcon,
  PersonalDetailsIcon,
  PPTFileIcon,
  PortfolioHomepageIcon,
  QrCode,
  SendIcon,
  TimeIcon,
  TXTIcon,
  WeblinkIcon,
  XLSIcon,
  ExportIcon,
  MyPortfolioIcon,
};

export type MeTabIconsType = keyof typeof meTabIcons;
