import { useForm } from "react-hook-form";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import SpaceDescription from "./SpaceDescription";

interface useSpaceDescriptionStepProps {
  onSubmit: ({ description }: { description: string }) => void;
  onEnter: () => void;
}

export const useSpaceDescriptionStep = ({
  onSubmit,
  onEnter,
}: useSpaceDescriptionStepProps): Step<"description"> => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    description: yup.string().required("Description is required"),
  });

  const { control, handleSubmit, formState, getValues, reset } = useForm({
    defaultValues: {
      description: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitDescriptionStep = async () => {
    const description = getValues("description");
    onSubmit({ description });
  };

  const handlePrev = () => {
    reset();
  };

  return {
    name: "description",
    title: t("space_create_general_title"),
    component: <SpaceDescription control={control} onSubmit={onEnter} />,
    onSubmit: handleSubmit(handleSubmitDescriptionStep),
    onPrev: handlePrev,
    canProceed: formState.isSubmitSuccessful,
    isLoading: formState.isSubmitting,
    submitError: !!formState.errors.description,
  };
};
