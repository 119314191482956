import { motion } from "framer-motion";
import { Tag, Title } from "UIKit";
import { Text } from "UIKit";
import { CaretRight } from "Assets/Icons";
import { Link } from "react-router-dom";
import { LocationDescriptor } from "history";

interface MainSectionTileProps {
  icon?: React.ReactNode;
  to: LocationDescriptor;
  title?: string;
  description?: string;
  tagLabel?: string;
  disabled?: boolean;
  attention?: boolean;
  "data-testid"?: string;
}
export const MainSectionTile = ({
  icon = null,
  to,
  title = "",
  description = "",
  tagLabel,
  disabled,
  attention,
  "data-testid": dataTestId = "section-tile",
}: MainSectionTileProps) => {
  const tileContainerClass = `flex lg:flex-col items-center justify-center rounded-lg lg:rounded-xl
  space-x-4 lg:space-x-0
  px-4
  bg-white group h-24 lg:h-72`;

  const tileIconClass =
    "flex items-center justify-center rounded-full h-14 w-14 lg:h-24 lg:w-24 bg-grey-100";

  const attentionClass = attention ? "bg-red-50" : "";

  const renderTile = () => (
    <>
      <div className={`${tileIconClass} ${!disabled ? "group-hover:bg-blue-50" : ""}`}>{icon}</div>
      <div className="hidden lg:flex flex-col items-center mb-4 mt-4 text-center">
        <Title level={5} className="line-clamp-2" title={title}>
          {title}
        </Title>
        <Text level={1} className="line-clamp-2 text-grey-700 font-semibold" title={description}>
          {description}
        </Text>
      </div>
      <div className="flex lg:hidden flex-col justify-center flex-1 space-y-1">
        <Text level={1} className="line-clamp-1 font-bold">
          {title}
        </Text>

        <Text level={3} className="line-clamp-2 text-grey-700 font-normal">
          {description}
        </Text>
      </div>
      {tagLabel && <Tag label={tagLabel} />}

      <div className="flex lg:hidden justify-center items-center h-7 w-7 rounded-full bg-grey-100">
        <CaretRight className="h-6 w-6 text-blue-500" />
      </div>
    </>
  );

  if (!disabled) {
    return (
      <Link to={to}>
        <motion.div
          whileHover={{ scale: 1.03 }}
          data-testid={dataTestId}
          transition={{ type: "spring", stiffness: 98, damping: 10 }}
          className={`${tileContainerClass} tile-border hover:shadow-md ${attentionClass}`}
        >
          {renderTile()}
        </motion.div>
      </Link>
    );
  }

  return <div className={tileContainerClass}>{renderTile()}</div>;
};
