import { useQueryParams } from "Hooks/useQueryParams";

const useRedirectToStore = () => {
  const queryParams = useQueryParams();

  const redirectToStore = queryParams.get("redirectToStore");
  if (redirectToStore === "true") {
    if (/Android/i.test(navigator.userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.eolasmedical.eolas&pcampaignid=web_share";
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = "https://apps.apple.com/app/id6447954935";
    }
  }
};

export default useRedirectToStore;
