import { useState } from "react";
import { AppLevelSection, sectionStore, OrganisationLevelSection } from "@eolas-medical/core";
import { useSendPushNotifications } from "./useNotification";

import { useGetNewsItemStats } from "./useGetNewsItemStats";

export type StatsStep = "stats" | "confirmation";

export type ReminderNotificationType =
  | AppLevelSection.newsFeed
  | OrganisationLevelSection.communicationPortal;

const orgSectionTypes = Object.values(OrganisationLevelSection) as string[];

const getRoute = (type: ReminderNotificationType) => {
  if (type === AppLevelSection.newsFeed) {
    return "department/newsFeed";
  }

  if (type === OrganisationLevelSection.communicationPortal) {
    return "hospital/communicationPortal";
  }
  return "";
};

const getContent = (type: ReminderNotificationType, name: string) => {
  if (type === AppLevelSection.newsFeed) {
    return `Reminder: ${name}`;
  }

  if (type === OrganisationLevelSection.communicationPortal) {
    return `Before it expires: ${name}`;
  }
  return name;
};

export const useNewsItemStats = (
  newsItemId: string,
  newsItemName: string,
  sectionType: ReminderNotificationType,
) => {
  const { onSendPN, isSuccess, isLoading, error } = useSendPushNotifications();

  const [step, setStep] = useState<StatsStep>("stats");

  const {
    users,
    userIds,
    unopenedUsers,
    error: analyticsError,
    isLoading: analyticsLoading,
  } = useGetNewsItemStats(newsItemId);

  const metadata = {
    fileID: newsItemId,
    sectionType,
  };

  const title = sectionStore.organisationName;
  const body = getContent(sectionType, newsItemName);
  const organisationId = orgSectionTypes.includes(sectionType)
    ? sectionStore.organisationID
    : undefined;

  const onConfirmSendingReminders = () => {
    onSendPN({
      userIds,
      body,
      title,
      metadata,
      type: sectionType,
      departmentId: sectionStore.appID,
      route: getRoute(sectionType),
      organisationId,
    });
  };
  const onCancel = () => {
    setStep("stats");
  };
  const onSendReminders = () => {
    setStep("confirmation");
  };

  return {
    step,
    error,
    onCancel,
    isLoading,
    isSuccess,
    onSendReminders,
    analyticsError,
    analyticsLoading,
    onConfirmSendingReminders,
    unopenedItems: unopenedUsers,
    openedItems: users - unopenedUsers,
  };
};
