import { CheckIcon } from "Assets";
import React, { useState } from "react";

export interface ToggleListOption {
  key: string;
  title: string;
  description: string;
}

interface ToggleListProps {
  options: ToggleListOption[];
  defaultOptionKey?: string;
  onChange: (selectedOptionKey: string) => void;
}

export const ToggleList: React.FC<ToggleListProps> = ({ options, defaultOptionKey, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(defaultOptionKey);

  const handleOptionChange = (optionKey: string) => {
    setSelectedOption(optionKey);
    onChange(optionKey);
  };

  return (
    <div className="flex flex-col divide-y divide-grey-300">
      {options.map((option) => (
        <div
          key={option.key}
          className="flex items-center justify-between py-4"
          onClick={() => handleOptionChange(option.key)}
        >
          <label htmlFor={option.key} className="w-3/4 cursor-pointer">
            <strong>{option.title}</strong>
            <p>{option.description}</p>
          </label>

          {selectedOption === option.key ? (
            <CheckIcon className="text-green w-6 h-6" />
          ) : (
            <span className="w-6 h-6 border border-grey-400 bg-grey-300 rounded-full" />
          )}
        </div>
      ))}
    </div>
  );
};
