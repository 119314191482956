import { useCallback } from "react";
import { portfolioService } from "modules/portfolio/client/PortfolioService";
import { useFileDownload, OnDownloadActions, GetFileAsync } from "shared/hooks/useFileDownload";

interface UseExportPdfEvent {
  exportPdfEvent: (eventId: string, actions?: OnDownloadActions) => void;
  exportingPdfEvent: boolean;
}

const useExportPdfEvent = (eventTitle: string): UseExportPdfEvent => {
  const getFileUrl: GetFileAsync = useCallback(
    async (eventId: string) => {
      const response = await portfolioService.exportEvent(eventId, "pdf");
      return { fileName: eventTitle, fileUrl: response.s3DownloadUrl, fileExt: "pdf" };
    },
    [eventTitle],
  );

  const { downloadFile, preparingDownload } = useFileDownload({
    getFileAsync: getFileUrl,
  });

  const exportPdfEvent = useCallback(
    async (eventId: string, actions?: OnDownloadActions) => {
      downloadFile(eventId, { onSuccess: actions?.onSuccess, onError: actions?.onError });
    },
    [downloadFile],
  );

  return {
    exportPdfEvent,
    exportingPdfEvent: preparingDownload,
  };
};

export default useExportPdfEvent;
