import React from "react";
import { useTranslation } from "react-i18next";

import { LDFlagNames } from "Utilities/types";
import { AttentionIcon, OrganisationIcon } from "Assets/Icons";
import { ModalHeader, Title, Text, Button } from "UIKit";

import { SpaceItem } from "../../../SpaceItem/SpaceItem";
import { useLaunchDarkly } from "Contexts";
import { SearchSpaceResult } from "Pages/Spaces/pages/SelectSpace/types";

interface SelectSpaceStepProps {
  error?: string;
  loadingAppID: string | null;
  apps: SearchSpaceResult[];
  onAddSpace(): void;
  onSelectSpace(app: SearchSpaceResult): void;
}

export const SelectSpaceStep: React.FC<SelectSpaceStepProps> = ({
  apps,
  error,
  loadingAppID,
  onAddSpace,
  onSelectSpace,
}) => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();

  const showCreatOrganisation =
    flags[LDFlagNames.SHOW_CREATE_ORG_BUTTON] && !flags[LDFlagNames.READ_ONLY_ACTIVATED];

  return (
    <>
      <ModalHeader className="flex-col space-y-4">
        <OrganisationIcon className="w-10 h-10 sm:h-14 sm:w-14" />
        <Title level={5}>{t("selectSpace_selectSpace")}</Title>
        {error && (
          <Text level={2} className="text-red">
            {error}
          </Text>
        )}
      </ModalHeader>

      {apps.length === 0 ? (
        <div className="flex flex-col items-center space-y-6 p-8">
          <AttentionIcon className="text-red" width={32} height={32} />
          <span className="text-lg font-semibold" data-testid="selectOrganisation-noneFound">
            {t("selectSpace_no_organisations_found")}
          </span>
          {showCreatOrganisation && (
            <Button data-testid="add-new-organisation" onClick={onAddSpace}>
              {t("selectSpace_create_button")}
            </Button>
          )}
        </div>
      ) : (
        <>
          <div className={`flex flex-col divide-y divide-grey-200 overflow-y-auto`}>
            {apps.map((app) => {
              return (
                <SpaceItem
                  app={app}
                  key={app.appID}
                  className="sm:px-12"
                  testID="search-organisation-result-item"
                  loadingAppID={loadingAppID}
                  onClick={() => {
                    onSelectSpace(app);
                  }}
                />
              );
            })}
          </div>
          {flags[LDFlagNames.SHOW_CREATE_ORG_BUTTON] ? (
            <div className="flex flex-col space-y-2 sm:space-y-4 py-4 sm:py-6">
              <Title level={7} className="text-center">
                {t("selectSpace_no_org_found")}
              </Title>
              <Button className="no-underline" onClick={onAddSpace} variant="link">
                {t("selectSpace_setup_org")}
              </Button>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
