import React from "react";
import { Virtuoso } from "react-virtuoso";
import { useTranslation } from "react-i18next";
import { motion, Variants } from "framer-motion";
import {
  Droppable,
  Draggable,
  DropResult,
  DragDropContext,
  DraggableProvided,
} from "react-beautiful-dnd";

import { CaretUpIcon, noFilesFound } from "Assets";

import { IconButton } from "../Button";
import { useScrollToTop } from "./useScrollToTop";
import { LottieWithHeader } from "../LottieWithHeader";

interface VirtualSortableListItem {
  id: string;
  isDragDisabled?: boolean;
}

interface VirtualSortableListProps<T extends VirtualSortableListItem> {
  items: T[];
  droppableId: string;
  scrollParentId?: string;
  emptyListLabel?: string;
  isDragDisabled?: boolean;
  onDragEnd?(dropResult: DropResult): void;
  renderItem(item: any, isDragging: boolean): any;
}

const containerVariants: Variants = {
  out: {
    opacity: 0.5,
  },
  in: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

window.addEventListener("error", (e) => {
  if (
    e.message === "ResizeObserver loop completed with undelivered notifications." ||
    e.message === "ResizeObserver loop limit exceeded"
  ) {
    e.stopImmediatePropagation();
  }
});

export function VirtualSortableList<T extends VirtualSortableListItem>({
  items,
  droppableId,
  emptyListLabel,
  isDragDisabled = false,
  scrollParentId = "page-container",
  onDragEnd,
  renderItem,
}: VirtualSortableListProps<T>) {
  const { t } = useTranslation();

  const scrollParent = document.getElementById(scrollParentId);

  const { scrollToTop, goToTop } = useScrollToTop();

  if (items?.length === 0) {
    return (
      <LottieWithHeader
        animationSize="125px"
        animation={noFilesFound}
        lottieOptions={{ loop: true }}
        text={emptyListLabel ?? t("general_no_subsection")}
      />
    );
  }

  return (
    <DragDropContext onDragEnd={(props) => onDragEnd && onDragEnd(props)}>
      <Droppable
        mode="virtual"
        droppableId={droppableId}
        renderClone={(provided, snapshopt, rubric) => {
          return (
            <DraggableItem
              provided={provided}
              renderItem={renderItem}
              isDragging={snapshopt.isDragging}
              item={items[rubric.source.index]}
            />
          );
        }}
      >
        {(provided) => {
          return (
            <>
              <motion.div
                animate="in"
                initial="out"
                ref={provided.innerRef}
                variants={containerVariants}
                className="overflow-visible pt-1 px-3 -mx-3"
              >
                <Virtuoso
                  totalCount={items?.length}
                  data={items}
                  useWindowScroll={!scrollParent}
                  customScrollParent={scrollParent ?? undefined}
                  itemContent={(index, item) => {
                    return (
                      <Draggable
                        index={index}
                        key={item.id}
                        draggableId={item.id}
                        isDragDisabled={isDragDisabled || item.isDragDisabled}
                      >
                        {(provided) => (
                          <DraggableItem provided={provided} renderItem={renderItem} item={item} />
                        )}
                      </Draggable>
                    );
                  }}
                />
              </motion.div>

              {scrollToTop && (
                <IconButton
                  color="blue"
                  onClick={goToTop}
                  icon={<CaretUpIcon />}
                  className="fixed bottom-2 right-2"
                />
              )}
            </>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}

interface DraggableItemProps {
  item: any;
  renderItem: any;
  isDragging?: boolean;
  provided: DraggableProvided;
}

const DraggableItem: React.FC<DraggableItemProps> = ({
  item,
  provided,
  renderItem,
  isDragging,
}) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={provided.draggableProps.style}
      className={"pb-4"}
    >
      {renderItem(item, isDragging)}
    </div>
  );
};
