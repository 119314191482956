import { RouteComponentProps } from "react-router-dom";

export interface ClinicalCalculatorsRouteParams {
  id: string;
}

export const EOLAS_CALCULATOR = "Eolas Calculator";

export enum CommunitySectionType {
  NATIONAL_RESOURCE = "NATIONAL_RESOURCE",
  BODY = "BODY",
}

export type ClinicalCalculatorRouteProps = RouteComponentProps<ClinicalCalculatorsRouteParams>;
