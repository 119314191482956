import { memo } from "react";

import { ClinicalCalculatorsSubSection } from "@eolas-medical/clinical-calculators";

import { Text } from "UIKit";

export const ExplanationComponents = {
  [ClinicalCalculatorsSubSection.creatinineClearance]: () => (
    <>
      <CreatinineClearance />
      <IdealBodyWeight />
      <AdjustedBodyWeight />
      <Text level={1} children="References" className="font-bold mt-4" />
      <Text
        level={1}
        children="Brown DL, Masselink AJ, Lalla CD. Functional range of creatinine clearance for renal drug dosing: a practical solution to the controversy of which weight to use in the Cockcroft-Gault equation. Ann Pharmacother. 2013 Jul-Aug;47(7-8): 1039-44. doi: 10.1345/aph.15176. Epub 2013 Jun 11. Review. PubMed MID: 23757387"
      />
      <Text
        level={1}
        children="Cockcroft DW, Gault MH. Prediction of creatinine clearance from serum creatinine. Nephron. 1976;16(1):31-41. PubMed PMID: 1244564."
      />
    </>
  ),
  [ClinicalCalculatorsSubSection.gentamicinDose]: () => (
    <>
      <CreatinineClearance />
      <IdealBodyWeight />
      <AdjustedBodyWeight />
      <GentDose />
    </>
  ),
  [ClinicalCalculatorsSubSection.idealBodyWeight]: () => (
    <>
      <IdealBodyWeight top />
      <AdjustedBodyWeight />
      <Text
        level={1}
        children="Adjusted body weight is only calculated if actual body weight is more than 20% of ideal body weight."
      />
      <Text level={1} children="References" className="font-bold mt-4" />
      <Text
        level={1}
        children="Devine BJ. Gentamicin therapy. Drug Intell Clin Pharm. 1974;8:650-655."
      />
    </>
  ),
};

const AdjustedBodyWeight = memo(() => (
  <>
    <Text level={1} children="Adjusted Body Weight" className="font-bold mt-4" />
    <Text level={1} children="IBW + (0.4 x (weight - IBW))" />
  </>
));

const IdealBodyWeight = memo(({ top = false }: { top?: boolean }) => (
  <>
    <Text
      level={1}
      children="Ideal Body Weight (IBW)"
      className={`font-bold ${top ? "" : "mt-4"}`}
    />
    <Text level={1} children="Female:" />
    <Text level={1} children="45.5 + 2.3 x (inches - 60)" />
    <Text level={1} children="Male:" />
    <Text level={1} children="50 + 2.3 x (inches - 60)" />
  </>
));

const CreatinineClearance = memo(() => (
  <>
    <Text level={1} children="Creatinine Clearance" className="font-bold" />
    <Text level={1} children="Female:" />
    <Text level={1} children="1.04 x (140-age) x kg / Cr (umol/L)" />
    <Text level={1} children="Male:" />
    <Text level={1} children="1.23 x (140-age) x kg / Cr (umol/L)" />
  </>
));

const GentDose = memo(() => (
  <>
    <Text level={1} children="Dose (mg)" className="font-bold mt-4" />
    <Text level={1} children="CrCl >= 30:" />
    <Text
      level={1}
      children="5 x actual weight (or Adjusted Body Weight), up to a max dose of 500mg"
    />
    <Text level={1} children="CrCl > 20:" />
    <Text
      level={1}
      children="3 x actual weight (or Adjusted Body Weight), up to a max dose of 300mg "
    />
    <Text level={1} children="CrCl < 20:" />
    <Text level={1} children="Single daily dosing likely inappropriate, seek alternative advice." />
    <Text
      level={1}
      children="This calculator uses the patient's actual weight or adjusted body weight (ideal body weight + 40% of excess body weight) if they are overweight (> 20% IBW)."
    />
    <Text
      level={1}
      children="It also implements cut offs based on estimated creatinine clearance (CrCl)."
    />
    <Text
      level={1}
      children="The Cockcroft and Gault Creatinine Clearance has been validated in Gentamicin dosing when an adult patient's creatinine, ideal body weight and age are known."
    />
    <Text
      level={1}
      children="This calculator is only for initial dosing and subsequent dosing should be based on peak and trough gentamicin levels."
    />
    <Text level={1} children="References" className="font-bold mt-4" />
    <Text
      level={1}
      children="Devine BJ. Gentamicin therapy. Drug Intell Clin Pharm. 1974;8:650-655."
    />
    <Text
      level={1}
      children="Cockcroft DW, Gault MH. Prediction of creatinine clearance from serum creatinine. Nephron. 1976;16(1):31-41. PubMed PMID: 1244564."
    />
  </>
));
