import { AttachIcon } from "Assets";
import { Text } from "UIKit";

interface PortfolioAttachmentButtonProps {
  label: string;
  onClick: () => void;
}

const PortfolioAttachmentButton = ({ label, onClick }: PortfolioAttachmentButtonProps) => {
  return (
    <div
      className="flex items-center justify-center w-full p-3 space-x-2 cursor-pointer"
      onClick={onClick}
    >
      <AttachIcon width={24} height={24} />
      <Text level={1}>{label}</Text>
    </div>
  );
};

export default PortfolioAttachmentButton;
