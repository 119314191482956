import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { removeLastUrlPath } from "Utilities";

import { InnerPageWrapper, NavButton, PageTitle } from "UIKit";

import { ChecklistRouteParams } from "../../types";
import { useGetCompletedChecklists } from "../hooks";
import { CompletedChecklistsButtons, SubmittedChecklists } from "../components";

export const CompletedChecklists: React.FC<RouteComponentProps<ChecklistRouteParams>> = observer(
  ({
    match: {
      url,
      params: { checklistID: encodedChecklistID },
    },
  }) => {
    const checklistID = decodeURIComponent(encodedChecklistID);
    const { checklist, hasIssues, isDisabled, checklistName } = useGetCompletedChecklists(
      checklistID,
    );

    return (
      <InnerPageWrapper className="space-y-8">
        <PageTitle title={checklistName} />
        <NavButton to={removeLastUrlPath(url, 2)} />

        <CompletedChecklistsButtons
          hasIssues={hasIssues}
          isDisabled={isDisabled}
          checklist={checklist as any}
        />

        <SubmittedChecklists checklistID={checklistID} />
      </InnerPageWrapper>
    );
  },
);
