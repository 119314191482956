import React from "react";
import { Control } from "react-hook-form";
import { Input, FormElement, Textarea } from "UIKit";
import { InputType, TitleAndDescriptionValues } from "./types";

interface TitleAndDescriptionStepProps {
  control: Control<TitleAndDescriptionValues>;
  errorMessage: string | undefined;
  titleLabel: string;
  titlePlaceholder: string;
  descriptionLabel: string;
  descriptionPlaceholder: string;
  descriptionInputType: InputType;
}

export const TitleAndDescriptionStep: React.FC<TitleAndDescriptionStepProps> = ({
  control,
  errorMessage,
  titleLabel,
  titlePlaceholder,
  descriptionLabel,
  descriptionPlaceholder,
  descriptionInputType,
}) => {
  return (
    <form>
      <FormElement required id="title" control={control} label={titleLabel}>
        {(field) => <Input {...field} size="lg" placeholder={titlePlaceholder} />}
      </FormElement>
      <FormElement
        errorMessage={errorMessage}
        required
        id="description"
        control={control}
        label={descriptionLabel}
      >
        {(field) =>
          descriptionInputType === "input" ? (
            <Input {...field} size="lg" placeholder={descriptionPlaceholder} />
          ) : (
            <Textarea {...field} placeholder={descriptionPlaceholder} />
          )
        }
      </FormElement>
    </form>
  );
};

export default TitleAndDescriptionStep;
