import useEditFlashcardItem from "modules/contentRepository/data/useEditFlashcardItem";
import { ContentItem } from "modules/contentRepository/types";
import { useState } from "react";
import { TitleAndDescriptionValues } from "UIKit/Wizard";
import { EolasFileType } from "Utilities/types";

interface UseAddContentItemProps {
  subSectionId: string;
  mainSectionId: string;
  onClose: () => void;
  flashcard: ContentItem;
}

export interface EditItemModalState {
  selectedType: EolasFileType;
  flashcard?: {
    title?: string;
    description?: string;
    content?: string;
  };
}

export const useEditContentItemActions = ({
  subSectionId,
  mainSectionId,
  flashcard,
  onClose,
}: UseAddContentItemProps) => {
  const [wizardState, setWizardState] = useState<EditItemModalState>({
    selectedType: "flashcard",
  });

  const {
    editFlashcardItem,
    editingFlashcardItem,
    editFlashcardItemSuccess,
    editFlashcardItemError,
    editFlashcardItemProgress,
  } = useEditFlashcardItem(subSectionId || mainSectionId);

  const handleEditFlashcardDetails = (data: TitleAndDescriptionValues) => {
    setWizardState((prevState) => {
      return {
        ...prevState,
        flashcard: {
          ...data,
        },
      };
    });
  };

  const handleEditFlashcardContent = (content: string) => {
    setWizardState((prevState) => ({
      ...prevState,
      flashcard: {
        ...prevState.flashcard,
        content,
      },
    }));
  };

  const handleSubmitFlashcardImageStep = async ({
    image,
    imageUrl,
  }: {
    image?: File;
    imageUrl?: string;
  }) => {
    setWizardState((prevState) => ({
      ...prevState,
      blob: image,
      heroImageUrl: imageUrl || "",
    }));

    const editItem = {
      name: wizardState.flashcard?.title!,
      description: wizardState.flashcard?.description,
      flashcardContent: wizardState.flashcard?.content,
      imageUrl: imageUrl,
    };

    editFlashcardItem({
      flashcard,
      editItem: editItem,
      file: image,
    });
  };

  return {
    wizardState,
    editingFlashcardItem,
    editFlashcardItemSuccess,
    editFlashcardItemError,
    editFlashcardItemProgress,
    onEditFlashcardDetails: handleEditFlashcardDetails,
    onEditFlashcardContent: handleEditFlashcardContent,
    onSubmitFlashcardImage: handleSubmitFlashcardImageStep,
  };
};
