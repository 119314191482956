import { QueryEnvironment, useEolasQuery } from "Hooks";
import { getFeedbackSummary } from "../client/portfolio.client";
import { FeedbackSummary } from "../types";
import portfolioKeys from "./portfolio.queryKeys";

interface useEventListProps {
  feedbackList: FeedbackSummary[];
  feedbackListLoading: boolean;
}

export const useFeedbackList = (): useEventListProps => {
  const { isLoading, data } = useEolasQuery({
    queryFn: () => getFeedbackSummary(),
    queryKey: portfolioKeys.feedbackList(),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 30000,
      staleTime: 15 * (60 * 1000), //15 mins
    },
  });

  return { feedbackListLoading: isLoading, feedbackList: data ?? [] };
};

export default useFeedbackList;
