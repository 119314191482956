export enum PortfolioError {
  EVENT_NOT_FOUND = "Event not found",
  EVENT_STATUS_NOT_FOUND = "Request failed with status code 404",
  FEEDBACK_NOT_FOUND = "Feedback not found",
  EVENT_OWNER = "The user creating the feedback is the owner of the event",
  FEEDBACK_ALREADY_EXIST = "Feedback already submitted",
  EVENT_ALREADY_COMPLETED = "Action not possible because EVENT is in the following status: COMPLETE",
  NOT_THE_ASSESSOR = "User is not the current assessor of this event",
}

export enum PortfolioErrorMessages {
  SUBMIT_EVENT_ERROR = "Error submitting event.",
  SAVE_EVENT_ERROR = "Error saving event.",
  COMPLETE_EVENT_ERROR = "Error completing event.",
  DELETE_EVENT_ERROR = "Error deleting event.",
  SAVE_FEEDBACK_ERROR = "Error saving feedback.",
  SUBMIT_FEEDBACK_ERROR = "Error submitting feedback.",
  DECLINE_FEEDBACK_ERROR = "Error declining feedback.",
  DOWNLOAD_ATTACHMENT_ERROR = "Error downloading attachment",
  EXPORT_EVENT_PDF_ERROR = "Error exporting pdf",
  ADD_SUPERVISOR_ERROR = "Error adding supervisor.",
  REMOVE_SUPERVISION_ERROR = "Error removing supervision.",
  SENDING_REMINDER_ERROR = "Error sending reminder",
  RESENDING_REQUEST_ERROR = "Error resending request",
  APPROVE_SUPERVISION_ERROR = "Error approving request.",
  DECLINE_SUPERVISION_ERROR = "Error declining request.",
  CANCEL_SUPERVISION_ERROR = "Error cancelling request.",
}
