import { useEolasQuery } from "Hooks";
import { myFilesService } from "../client/myFiles.client";
import myFilesKeys from "./myFiles.queryKeys";
import { QueryEnvironment } from "Hooks";

const useAllFilesBySection = () => {
  const { isLoading, data } = useEolasQuery({
    queryFn: () => myFilesService.getAllFilesBySection(),
    queryKey: myFilesKeys.filesBySectionList(),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 30000,
      staleTime: 15 * (60 * 1000), //15 mins
    },
  });
  return { filesBySection: data ?? [], filesBySectionLoading: isLoading };
};

export default useAllFilesBySection;
