import { contentClient, fetchAllData } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { FileSectionUpdateDto } from "modules/myFiles/types";

interface EditContentSectionProps {
  parentID: string;
  input: FileSectionUpdateDto;
}

const useEditContentSection = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async ({ parentID, input }: EditContentSectionProps) => {
      await contentClient.updateSection({ parentID, input });
    },
    {
      onSuccess: async () => {
        await fetchAllData();
      },
    },
  );

  return {
    editContentSection: mutate,
    editingContentSection: isLoading,
    editContentSectionSuccess: isSuccess,
    editContentSectionError: error as string,
  };
};

export default useEditContentSection;
