import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createSupervisionRequest } from "../client/portfolio.client";
import portfolioKeys from "./portfolio.queryKeys";
import { SupervisionStatus, SupervisorRequestCreatedBy } from "../types";
import { SupervisorsList } from "../client/portfolio.graphql";

const useCreateSupervisionRequest = (createdBy: SupervisorRequestCreatedBy) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(createSupervisionRequest, {
    onSuccess: (newSupervisionRequest) => {
      if (createdBy === SupervisorRequestCreatedBy.SUPERVISEE) {
        queryClient.setQueryData(
          portfolioKeys.supervisorsList(),
          (actualRequests: SupervisorsList | undefined) => {
            if (actualRequests?.supervisionRequestsSummary.length) {
              const pendingRequests = actualRequests.supervisionRequestsSummary.filter(
                (supervisionRequest) =>
                  supervisionRequest?.status !== SupervisionStatus.DECLINED ||
                  supervisionRequest?.email !== newSupervisionRequest.email,
              );
              return {
                ...actualRequests,
                supervisionRequestsSummary: [...pendingRequests, newSupervisionRequest],
              };
            }
            return {
              supervisionRequestsSummary: [newSupervisionRequest],
              supervisorsSummary: actualRequests?.supervisorsSummary || [],
            };
          },
        );
      }
    },
  });

  return {
    createRequest: mutate,
    creatingRequest: isLoading,
    ...rest,
  };
};

export default useCreateSupervisionRequest;
