import { useTranslation } from "react-i18next";
import { Button, Text } from "UIKit";
import useExportPdfPortfolio from "modules/portfolio/data/useExportPdfPortfolio";

interface ExportPortfolioButtonProps {
  ownerId: string;
  ownerName: string;
  onSuccess?: () => void;
}
const ExportPortfolioButton = ({ ownerId, ownerName, onSuccess }: ExportPortfolioButtonProps) => {
  const { t } = useTranslation();
  const { exportPdfPortfolio, exportingPdfPortfolio } = useExportPdfPortfolio(ownerName);

  return (
    <Button
      iconLeft="ExportIcon"
      variant="outline"
      size="lg"
      weight="bold"
      color="white"
      onClick={() => exportPdfPortfolio(ownerId, { onSuccess })}
      isLoading={exportingPdfPortfolio}
      data-testid="export-portfolio-pdf"
    >
      <Text className="ml-2 text-sm sm:text-base" level={1}>
        {t("portfolio_all_export_pdf")}
      </Text>
    </Button>
  );
};

export default ExportPortfolioButton;
