import { format } from "date-fns";
import { ContentItem } from "modules/contentRepository/types";
import { FileTypeItemIcon, Text } from "UIKit";

interface ExpiringItemTileProps {
  contentItem: ContentItem;
}

const ExpiringItemTile = ({ contentItem }: ExpiringItemTileProps) => {
  return (
    <div className="flex justify-between items-center bg-white rounded-lg border border-grey-300 p-5 cursor-pointer">
      <div className="flex items-center space-x-2">
        <FileTypeItemIcon type={contentItem.type} className="w-10 h-10" />
        <Text level={1}>{contentItem.name}</Text>
      </div>
      {contentItem.expiryDate && (
        <Text level={2} className="text-grey-600">
          {format(new Date(contentItem.expiryDate), "do MMMM yyyy")}
        </Text>
      )}
    </div>
  );
};

export default ExpiringItemTile;
