import React, { useState } from "react";
import { Section, fetchAllData } from "@eolas-medical/core";
import { useTranslation } from "react-i18next";
import { useMutation, gql } from "@apollo/client";

import { useRequestStatus } from "Utilities";
import { INameable } from "Utilities/helpers";
import { errorStore } from "Stores/ErrorStore";

const UPDATE_SECTION = gql`
  mutation UpdateForSection($parentID: String!, $input: ModifySectionInput!) {
    updateForSection(parentID: $parentID, input: $input) {
      id
    }
  }
`;

export const useHighlightChecklist = (checklist: Section & INameable) => {
  const [updateForSection] = useMutation(UPDATE_SECTION);
  const { isLoading, setRequestStatus } = useRequestStatus();
  const [toggleText, setToggleText] = useState<any>(null);

  const { t } = useTranslation();

  const notHighlighted = checklist.highlighted === "false" || !checklist.highlighted;

  const toggleHighlight = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setToggleText(null);
    setRequestStatus({ status: "pending" });

    updateForSection({
      variables: {
        input: {
          id: checklist.id,
          highlighted: notHighlighted ? new Date().toISOString() : "false",
        },
        parentID: checklist.parentID,
      },
    })
      .then(async () => {
        await fetchAllData();
        setToggleText(t(notHighlighted ? "highlights_pages_added" : "highlights_pages_removed"));
        setRequestStatus({ status: "success" });
      })
      .catch((error: any) => {
        errorStore.captureError({
          error,
          source: "user",
          retryCallback: updateForSection,
          retryParameters: {
            variables: {
              input: {
                id: checklist.id,
                highlighted: notHighlighted ? new Date().toISOString() : "false",
              },
              parentID: checklist.parentID,
            },
          },
        });
        setToggleText(t(notHighlighted ? "highlights_pages_added" : "highlights_pages_removed"));
        setRequestStatus({ status: "error" });
      })
      .finally(() =>
        setTimeout(() => {
          setToggleText(null);
        }, 2000),
      );
  };

  return {
    isLoading,
    toggleText,
    notHighlighted,
    toggleHighlight,
  };
};
