import { inviteClient, userStore } from "@eolas-medical/core";
import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { BaseAppRoute } from "Utilities/types";
import { InvitationPayload } from "../../types";

type LoginFlowStatus = "SameUser" | "LoginRequired";

export type LoginFlowState = {
  error?: string;
  status: LoginFlowStatus;
  invitation: InvitationPayload;
};

const useLoginFlow = (invitation: InvitationPayload) => {
  const { push } = useHistory();
  const loggedInUserId = userStore.userID;
  const { userID = "", invitationID } = invitation;

  const [loginState, setLoginState] = useState<LoginFlowState>({
    invitation,
    status: "SameUser",
  });

  const handleLoginFlow = useCallback(async () => {
    if (loggedInUserId === userID) {
      setLoginState((prevState) => ({
        ...prevState,
        status: "SameUser",
      }));

      await inviteClient.createAppUser(invitationID, userID, userStore.userData.email as string);
      push(`/${BaseAppRoute.selectSpace}`);
    } else {
      setLoginState((prevState) => ({
        ...prevState,
        status: "LoginRequired",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationID, userID, push]);

  useEffect(() => {
    handleLoginFlow();
  }, [handleLoginFlow]);

  return {
    ...loginState,
  };
};

export default useLoginFlow;
