import { useQueryClient, useMutation } from "@tanstack/react-query";
import { completeEventForm, completeNewEventForm } from "../client/portfolio.client";
import { mapEventForm } from "../helpers";
import portfolioKeys from "./portfolio.queryKeys";
import { EventDto } from "../types";

interface CompleteEventMutation {
  eventId?: string;
  eventBody?: EventDto;
}

const useCompleteEvent = () => {
  const queryClient = useQueryClient();

  const completeEventMutation = async ({ eventId, eventBody }: CompleteEventMutation) => {
    if (eventId) {
      return completeEventForm({ eventId, eventBody });
    }

    if (!eventId && eventBody) {
      return completeNewEventForm({ eventBody });
    }
  };

  const { mutate, isLoading, ...rest } = useMutation(completeEventMutation, {
    onSuccess: (completedEvent) => {
      if (completedEvent) {
        queryClient.invalidateQueries({
          queryKey: portfolioKeys.lists(),
        });
        queryClient.setQueryData(portfolioKeys.detail(completedEvent.id), () =>
          mapEventForm(completedEvent),
        );
      }
    },
  });

  return {
    completeEvent: mutate,
    completingEvent: isLoading,
    ...rest,
  };
};

export default useCompleteEvent;
