import { useForm } from "react-hook-form";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import SpaceSpecialty from "./SpaceSpecialty";
import { Option } from "UIKit/Select/Select";

interface useSpaceSpecialtyStepProps {
  onSubmit: ({ specialty }: { specialty: Option[] }) => void;
  onEnter: () => void;
}

export const useSpaceSpecialtyStep = ({
  onSubmit,
  onEnter,
}: useSpaceSpecialtyStepProps): Step<"specialty"> => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    specialty: yup.array().min(1, "Specialty is required").nullable(),
  });

  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: {
      specialty: [],
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitSpaceName = async () => {
    const specialty = getValues("specialty");
    onSubmit({ specialty });
  };

  return {
    name: "specialty",
    title: t("space_create_general_title"),
    component: <SpaceSpecialty control={control} onSubmit={onEnter} />,
    onSubmit: handleSubmit(handleSubmitSpaceName),
    canProceed: formState.isSubmitSuccessful,
    isLoading: formState.isSubmitting,
  };
};
