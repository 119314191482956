import { Route, Redirect, Switch } from "react-router-dom";

import { LDFlagNames } from "Utilities/types";
import { MaintenancePage } from "Pages/Maintenance";
import { Login, SignUp } from "Pages/Authentication/pages";
import { useLaunchDarkly } from "Contexts/LaunchDarklyContext";
import { useCallbackUrl } from "shared/hooks/useCallbackUrl";
import Authentication from "Pages/Authentication/Authentication";

export const AuthenticationRoutes: React.FC = () => {
  const { flags } = useLaunchDarkly();
  const { isNewUser, userEmail, callbackUrl } = useCallbackUrl();

  const showNewLogin = flags[LDFlagNames.NEW_SIGN_IN];

  if (flags[LDFlagNames.WEB_MAINTENANCE]) {
    // allow access to the login page
    return (
      <Switch>
        <Route exact path="/" component={MaintenancePage} />
        <Route exact path="/login" component={Login} />
        <Redirect to="/" />
      </Switch>
    );
  }

  if (isNewUser) {
    return (
      <Switch>
        <Redirect to={{ pathname: "/signup", search: callbackUrl, state: { userEmail } }} />
      </Switch>
    );
  }

  return (
    <Switch>
      {!showNewLogin && <Route exact path="/" component={Login} />}
      {showNewLogin && <Route exact path="/" component={Authentication} />}
      <Route exact path={"/signup"} component={SignUp} />
      <Redirect to={{ pathname: "/", search: callbackUrl, state: { userEmail } }} />
    </Switch>
  );
};
