import envConfig from "env-config";
import { useCallback } from "react";

const useGetIconUrl = () => {
  const remoteStaticPath = `https://${envConfig.REACT_APP_STATIC_BASE_URL}/SectionImages/svgIcons`;

  const getIconUrl = useCallback(
    (icon: string) => {
      const iconURL = /.svg/.test(icon) ? `${remoteStaticPath}/${icon}` : icon;

      return iconURL;
    },
    [remoteStaticPath],
  );

  return { getIconUrl };
};

export default useGetIconUrl;
