import React, { useState, useCallback, useEffect } from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";
import { SearchBox } from "UIKit/SearchBox/SearchBox";
import { Loader, VirtualSortableList } from "UIKit";
import { EolasListItem } from "./EolasList";
import { isEmpty } from "Utilities/helpers";

interface ItemsList<T> {
  subtitle: string;
  items: T[];
}

export interface EolasMultipleListProps<T extends EolasListItem> {
  items: ItemsList<T>[];
  renderItem: (item: T, isDragging?: boolean) => React.ReactNode;
  renderSubtitle: (subtitle: string) => React.ReactNode | string;
  className?: string;
  isSearchable?: boolean;
  emptyItemsLabel?: string;
  emptyItemsComponent?: React.ReactNode;
  infoText?: string;
  isLoading?: boolean;
  onSearchInputChange?: (inputText: string) => void;
}

export const EolasMultipleList = <T extends EolasListItem>({
  items,
  renderItem,
  renderSubtitle,
  className,
  isSearchable = false,
  emptyItemsLabel = "Nothing found",
  emptyItemsComponent = null,
  infoText,
  isLoading,
  onSearchInputChange = () => {},
}: EolasMultipleListProps<T>) => {
  const [eolasMultipleList, setEolasMultipleList] = useState(items);

  /**
   * Sets the eolasList list every time the items change
   */
  useEffect(() => {
    setEolasMultipleList(items);
  }, [items]);

  /**
   * Calls the onSearchInputChange callback
   */
  const handleSearchInputChanged = useCallback(
    (text: string) => {
      if (isSearchable && onSearchInputChange) {
        onSearchInputChange(text);
      }
    },
    [onSearchInputChange, isSearchable],
  );

  const renderList = () => {
    const areItemsEmpty = eolasMultipleList.every((obj) => isEmpty(obj.items));

    if (areItemsEmpty && emptyItemsComponent) {
      return <>{emptyItemsComponent}</>;
    }

    return eolasMultipleList.map((item) => {
      const { subtitle, items } = item;

      if (items.length === 0) return null;

      return (
        <div key={subtitle}>
          {renderSubtitle(subtitle)}
          <VirtualSortableList
            items={items}
            droppableId="eolas-multiple-list"
            emptyListLabel={emptyItemsLabel}
            renderItem={renderItem}
            isDragDisabled
          />
        </div>
      );
    });
  };

  if (isLoading) return <Loader className="bg-transparent h-30vh" />;

  return (
    <div
      data-testid="eolas-multiple-list"
      className={tw(`flex flex-col space-y-6 pb-8 ${className}`)}
    >
      {isSearchable && <SearchBox onChangeText={handleSearchInputChanged} />}
      <div data-testid="eolas-multiple-list-items-container">
        {infoText && <span className="text-center text-grey-darker mb-6">{infoText}</span>}
        {renderList()}
      </div>
    </div>
  );
};
