import * as yup from "yup";

export const useFileDetailsValidationSchema = () => {
  return yup.object().shape({
    hasExpiryDate: yup.boolean().nullable().notRequired(),
    expiryDate: yup.mixed().when("hasExpiryDate", {
      is: (value: any) => !!value,
      then: yup.mixed().test({
        test: (v) => !!v,
        message: "fieldError_expiry_date_required",
      }),
      otherwise: yup.mixed().nullable().notRequired(),
    }),
    name: yup.string().required("Name is required").notOneOf([""], "Name cannot be empty"),
    keywords: yup
      .string()
      .required("Keywords are required")
      .notOneOf([""], "Keywords cannot be empty"),
  });
};
