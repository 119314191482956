import { FormElement, Input } from "UIKit";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "Assets";
import { Control } from "react-hook-form";

interface SpaceNameProps {
  control: Control<any>;
  autoFocus?: boolean;
  isNameValid?: boolean;
  onSubmit: () => void;
}
const SpaceName = ({ control, onSubmit, autoFocus = false, isNameValid }: SpaceNameProps) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit}>
      <div className="col-span-2">
        <FormElement required id="name" control={control} label={t("space_create_name")}>
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Input
                {...rest}
                autoFocus={autoFocus}
                size="lg"
                iconRight={isNameValid ? <CheckIcon className="text-green" /> : undefined}
                placeholder={t("edit_space_details_name_placeholder")}
                value={value}
              />
            );
          }}
        </FormElement>
      </div>
    </form>
  );
};

export default SpaceName;
