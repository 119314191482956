import { useTranslation } from "react-i18next";

import { errorStore } from "Stores/ErrorStore";
import { SuccessModal, DeleteModal } from "UIKit";
import { useDeleteAppUser } from "Hooks/usersService";
import { AppUser } from "API";

interface DeactivateUserModalProps {
  appUser: AppUser;
  onCloseModal(): void;
}

export const DeactivateUserModal: React.FC<DeactivateUserModalProps> = ({
  appUser,
  onCloseModal,
}) => {
  const { t } = useTranslation();

  const { error, isLoading, isSuccess, onDeleteAppUser } = useDeleteAppUser();

  const onDelete = () => {
    onDeleteAppUser(
      { departmentId: appUser.appID, userId: appUser.userID },
      {
        onError: (error: Error) => {
          errorStore.captureError({ error: error.message, source: "user" });
        },
      },
    );
  };

  if (isSuccess) {
    return <SuccessModal text="manageUsers_deactivate_success" onComplete={onCloseModal} />;
  }

  return (
    <DeleteModal
      error={error}
      onDelete={onDelete}
      isLoading={isLoading}
      onCloseModal={() => {}}
      iconType="RemoveUserIcon"
      title={t("manageUsers_deactivate_user_title")}
      description={"manageUsers_deactivate_user_description"}
    />
  );
};
