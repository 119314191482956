import { useState } from "react";
import { AnalyticsEvents, SpaceUpdateDto, userStore } from "@eolas-medical/core";
import { InnerPageWrapper, GridContainer, Modal } from "UIKit";

import { trackEvent } from "API/Analytics";

import MasterSearch from "Components/MasterSearch/MasterSearch";
import ViewSpaceDetailsModal from "./components/ViewSpaceDetailsModal/ViewSpaceDetailsModal";
import AccessQRCode from "./components/AccessQRCode/AccessQRCode";
import SpaceDetails from "./components/SpaceDetails/SpaceDetails";
import EditSpaceDetailsWizard from "./components/EditSpaceDetailsWizard/EditSpaceDetailsWizard";

import useSpaceMainSections from "modules/spaces/data/useSpaceMainSections";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useUpdateSpace from "modules/spaces/data/useUpdateSpace";
import MainSectionList from "Pages/Spaces/pages/Space/components/MainSectionList/MainSectionList";

type SpaceDetailsModalState = "editSpace" | "viewSpace" | "shareSpace";

const Space = () => {
  const { appUserData: appUser } = userStore;
  const { selectedSpace } = useSpacesContext();

  const { updateSpace, updatingSpace, updateSpaceSuccess } = useUpdateSpace();

  const currentImageUrl = selectedSpace?.image?.url;

  const accessLevel = appUser.accessLevel;

  const isAdminUser = accessLevel === "admin";

  const isPublic = selectedSpace?.accessType === "public";

  const { activeMainSections } = useSpaceMainSections();

  const [modalState, setModalState] = useState<SpaceDetailsModalState | null>(null);

  const handleCloseModal = () => {
    setModalState(null);
  };

  const handleSpaceUpdated = (updatedSpace: SpaceUpdateDto) => {
    if (updatedSpace.url && updatedSpace.url !== currentImageUrl) {
      trackEvent(AnalyticsEvents.SPACE_GALLERY_IMAGE_UPDATED);
    }
    if (updatedSpace.file) {
      trackEvent(AnalyticsEvents.SPACE_CUSTOM_IMAGE_UPDATED);
    }

    if (selectedSpace) {
      updateSpace(
        { spaceId: selectedSpace?.id, spaceDto: updatedSpace },
        {
          onSuccess: () => {
            handleCloseModal();
            trackEvent(AnalyticsEvents.SPACE_UPDATED);
          },
        },
      );
    }
  };

  if (!selectedSpace) {
    return null;
  }

  return (
    <InnerPageWrapper>
      <div className="space-y-5 -mx-6 -mt-3">
        <SpaceDetails
          name={selectedSpace?.name || ""}
          description={selectedSpace?.description || ""}
          imageUrl={selectedSpace?.image?.url}
          onClickEditSpace={() => setModalState("editSpace")}
          onClickViewSpace={() => setModalState("viewSpace")}
          onClickShareSpace={() => setModalState("shareSpace")}
          isAdminUser={isAdminUser}
          isPublic={isPublic}
          usersCount={selectedSpace?.userCount || 1}
        />

        <MasterSearch />
      </div>

      <GridContainer>
        <MainSectionList mainSectionList={activeMainSections} />
      </GridContainer>
      <Modal open={modalState !== null} onClose={handleCloseModal}>
        {modalState === "editSpace" && (
          <EditSpaceDetailsWizard
            onClose={handleCloseModal}
            onUpdate={handleSpaceUpdated}
            isSuccess={updateSpaceSuccess}
            isLoading={updatingSpace}
          />
        )}
        {modalState === "viewSpace" && (
          <ViewSpaceDetailsModal
            onClose={() => handleCloseModal()}
            imageUrl={selectedSpace?.image?.url}
          />
        )}
        {modalState === "shareSpace" && <AccessQRCode onClose={handleCloseModal} />}
      </Modal>
    </InnerPageWrapper>
  );
};

export default Space;
