import { gql, useMutation } from "@apollo/client";

import { useSelectFile } from "Hooks";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { useSentry } from "Contexts/SentryContext";
import { fileStore } from "@eolas-medical/core";

const GET_CHECKLIST_PDF = gql`
  mutation CreateChecklistPdf($fileID: String!) {
    createChecklistPdf(fileID: $fileID)
  }
`;

export const useDownloadPDF = () => {
  const { addBreadcrumb } = useSentry();
  const { onSelectFile: selectFile } = useSelectFile("requestStatus");
  const { isLoading, error, setRequestStatus } = useRequestStatus();

  const [createChecklistPdf] = useMutation(GET_CHECKLIST_PDF);

  const downloadPDF = async (checklistID: string) => {
    addBreadcrumb({
      category: "useDownloadPdf",
      message: "requesting pdf download",
    });

    setRequestStatus({ status: "pending", error: "" });

    const file = fileStore.getFile(checklistID);

    let fileKey = file.key;
    try {
      if (!file.key) {
        const {
          data: { createChecklistPdf: key },
        } = await createChecklistPdf({ variables: { fileID: checklistID } });
        fileKey = key;
        file.key = key;
      }
      await selectFile({ key: fileKey, type: "pdf", id: checklistID });
    } catch (error: any) {
      const errorMessage = errorStore.captureError({
        error,
        source: "user",
      });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  };

  return {
    error,
    isLoading,
    downloadPDF,
    setRequestStatus,
  };
};
