import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { formatDate } from "Utilities";
import { CheckCircleIcon } from "Assets";
import { EolasChecklistSection } from "Utilities/types";

import {
  Text,
  Title,
  Button,
  ModalBody,
  SuccessModal,
  ModalHeader,
  FormFieldError,
  InnerModalWrapper,
} from "UIKit";

import { EolasSubmittedChecklist } from "../../types";
import { useChecklistIssues, useResolveIssue } from "../hooks";

interface UnresolvedIssuesModalProps {
  onCloseModal(): void;
  checklist: EolasChecklistSection;
}

export const UnresolvedIssuesModal: React.FC<UnresolvedIssuesModalProps> = observer(
  ({ checklist, onCloseModal }) => {
    const { t } = useTranslation();
    const { checklistsWithIssues } = useChecklistIssues(checklist.id);

    if (checklistsWithIssues.length === 0) {
      return (
        <SuccessModal text={t("submittedChecklists_no_active_issues")} onComplete={onCloseModal} />
      );
    }

    return (
      <InnerModalWrapper>
        <ModalHeader>
          <Title level={5}>{t("submittedChecklists_active_issues_title")}</Title>
        </ModalHeader>

        <ModalBody className="overflow-y-auto">
          {checklistsWithIssues.map((checklist) => {
            return <ChecklistIssues key={checklist.id} checklist={checklist} />;
          })}
        </ModalBody>
      </InnerModalWrapper>
    );
  },
);

interface ChecklistIssuesProps {
  checklist: EolasSubmittedChecklist;
}

const ChecklistIssues: React.FC<ChecklistIssuesProps> = ({ checklist }) => {
  const { t } = useTranslation();
  const { isLoading, error, onResolve } = useResolveIssue(checklist);
  const { checklistName, secondTeamMember, additionalInfo } = checklist.metadata;

  return (
    <>
      <div
        className={`
        flex flex-col bg-white rounded-md border p-6
        ${error ? "border-red-300 mb-2" : "border-grey-300 mb-4"}
      `}
      >
        <div className="flex items-center justify-between mb-6">
          <Title level={7} className="line-clamp-2">
            {checklistName}
          </Title>
          <Button color="green" variant="ghost" isLoading={isLoading} onClick={onResolve}>
            <CheckCircleIcon className="h-5 w-5 mr-2" />
            {t("submittedChecklists_active_issues_resolve_button")}
          </Button>
        </div>

        <div className="grid grid-cols-3 gap-y-6 gap-x-4">
          <div className="flex flex-col space-y-2">
            <Text level={1} className="font-bold">
              {t("submittedChecklists_active_issues_completed_by")}
            </Text>
            <Text level={2} className="font-semibold">
              {checklist.info}
            </Text>
          </div>

          <div className="flex flex-col space-y-2">
            <Text level={1} className="font-bold">
              On
            </Text>
            <Text level={2} className="font-semibold">
              {formatDate(checklist.createdAt as string)}
            </Text>
          </div>

          {secondTeamMember && (
            <div className="flex flex-col space-y-2">
              <Text level={1} className="font-bold">
                {t("submittedChecklists_active_issues_second_member")}
              </Text>
              <Text level={2} className="font-semibold">
                {secondTeamMember}
              </Text>
            </div>
          )}

          {additionalInfo && (
            <div className="flex flex-col space-y-2 col-span-3">
              <Text level={1} className="font-bold">
                {t("submittedChecklists_active_issues_issue_details")}
              </Text>
              <Text level={2} className="font-semibold">
                {additionalInfo}
              </Text>
            </div>
          )}
        </div>
      </div>
      {error && <FormFieldError className="mb-4">{error}</FormFieldError>}
    </>
  );
};
