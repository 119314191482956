import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  allDefaultValues,
  BodyWeightCalcArgs,
  allValidationSchema,
  gentamicinDoseCalcArgs,
  CreatinineClearanceArgs,
} from "@eolas-medical/clinical-calculators";

import { CalculatorIcon, FemaleIcon, MaleIcon, RefreshCWIcon } from "Assets";
import { Button, FormElement, Input, SelectCard, Prompt, WarningModal } from "UIKit";

interface FormValue {
  id: string;
  label?: string;
  inputProps?: {
    type: string;
    iconLeft: JSX.Element;
    placeholder: string;
  };
}

export type CalculatorArgs = BodyWeightCalcArgs & gentamicinDoseCalcArgs & CreatinineClearanceArgs;

export interface CalculatorFormProps {
  values: FormValue[];
  withPrompt?: boolean;
  onSubmit: (values: CalculatorArgs) => void;
  schemaName: "idealBodyWeight" | "gentamicinDose" | "creatinineClearance";
}

export const CalculatorForm: React.FC<CalculatorFormProps> = ({
  schemaName,
  values,
  withPrompt = true,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => allValidationSchema[schemaName], [schemaName]);
  const defaultValues = useMemo(() => allDefaultValues[schemaName], [schemaName]);
  const [warningModal, toggleModal] = useState(false);
  const [sex, setSex] = useState<"male" | "female">("female");

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty, isSubmitted },
  } = useForm<CalculatorArgs>({
    defaultValues,
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema as any),
  });

  const handleReset = () => {
    setSex("female");
    reset();
  };

  const submitForm = handleSubmit((formValues) => {
    const values = { ...formValues, sex };
    onSubmit(values);
  });

  return (
    <form onSubmit={submitForm}>
      <SelectCard
        selected={sex}
        className="my-4"
        onChange={setSex}
        label={t("clinicalCalculators_sex_label")}
        options={[
          {
            value: "female",
            icon: <FemaleIcon className="w-8 h-8" />,
            label: t("clinicalCalculators_female_label"),
          },
          {
            value: "male",
            icon: <MaleIcon className="w-6 h-8" />,
            label: t("clinicalCalculators_male_label"),
          },
        ]}
      />
      {values.map(({ id, label, inputProps }) => (
        <FormElement
          id={id}
          key={id}
          required
          label={label}
          className="mb-4"
          control={control}
          data-testid="calculator-from-element"
        >
          {(field) => <Input size="lg" {...field} {...inputProps} />}
        </FormElement>
      ))}

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
        <Button
          size="lg"
          type="reset"
          color="grey"
          variant="outline"
          disabled={!isDirty}
          onClick={() => toggleModal(!warningModal)}
        >
          <RefreshCWIcon className="w-4 h-4 mr-2" />
          {t("form_reset")}
        </Button>

        <Button size="lg" type="submit">
          <CalculatorIcon className="w-4 h-4 mr-2" />
          {t("form_calculate")}
        </Button>
      </div>

      <WarningModal
        isOpen={warningModal}
        onConfirm={handleReset}
        text={t("form_reset_warning_text")}
        handleClose={() => {
          toggleModal(false);
        }}
      />

      {withPrompt && (
        <Prompt
          onCancel={() => false}
          onConfirm={() => true}
          when={isDirty && !isSubmitted}
          title={t("form_unsaved_changes_prompt_title")}
          description={t("form_unsaved_changes_prompt_body")}
          cancelText={t("form_unsaved_changes_prompt_cancel")}
          confirmText={t("form_unsaved_changes_prompt_leave", { page: "" })}
        />
      )}
    </form>
  );
};
