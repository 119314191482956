import { UserLocation } from "@eolas-medical/core";
import { LocationIcon } from "Assets";
import React from "react";
import { twMerge } from "tailwind-merge";

interface ProfileLocationProps {
  location: UserLocation;
  className?: string;
}

const ProfileLocation = ({ location, className }: ProfileLocationProps) => (
  <div className={twMerge("flex justify-center items-center mt-5", className)}>
    <LocationIcon />
    <p className="text-base font-semibold text-grey-700 ml-2">
      {location.city}, {location.country}
    </p>
  </div>
);

export default ProfileLocation;
