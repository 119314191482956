import { AnalyticsEvents } from "@eolas-medical/core";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { trackEvent } from "API/Analytics";
import { myFilesService } from "../client/myFiles.client";
import { FileSection, FileSectionDto } from "../types";
import myFilesKeys from "./myFiles.queryKeys";

const useCreateSection = (userId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, error } = useMutation<FileSection, Error, FileSectionDto>(
    (fileSection: FileSectionDto) => myFilesService.createSection({ ...fileSection, userId }),
    {
      onSuccess: (newSection) => {
        trackEvent(AnalyticsEvents.MYFILES_SECTION_ADDED, { subSectionId: newSection.id });

        queryClient.setQueryData(myFilesKeys.sectionLists(), (actualList: any) => {
          return [newSection, ...actualList];
        });
      },
    },
  );

  return {
    createSection: mutate,
    creatingSection: isLoading,
    createSectionSuccessful: isSuccess,
    createSectionError: error?.message,
  };
};

export default useCreateSection;
