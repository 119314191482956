import { useState, useCallback, useEffect } from "react";
import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";

interface QueryResult {
  id: string;
}
export interface UseQuerySearchFilesProps<T> {
  key: string;
  queryFn: (query: string) => Promise<T[]>;
  minInputLength?: number;
  queryDisabled?: boolean;
  options?: Omit<UseQueryOptions<T[], Error, T[], QueryKey>, "queryKey" | "queryFn">;
}

export const useQuerySearch = <T extends QueryResult>({
  key,
  queryFn,
  minInputLength = 3,
  queryDisabled = false,
  options,
}: UseQuerySearchFilesProps<T>) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const { data = [], isFetching } = useQuery<T[], Error>(
    [key, { searchValue }],
    () => queryFn(searchValue),
    {
      ...options,
      enabled: !queryDisabled && searchValue.length >= minInputLength,
    },
  );

  useEffect(() => {
    if (searchInput.length < minInputLength) setSearchValue("");
  }, [searchInput, setSearchValue, minInputLength]);

  const onSearchInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(e.target.value.toLowerCase());
    },
    [setSearchInput],
  );

  const onSearch = useCallback(
    (searchText?: string) => {
      if (searchText && searchText.length >= minInputLength) {
        setSearchValue(searchText);
        return;
      }
      if (searchInput.length >= minInputLength) setSearchValue(searchInput);
    },
    [setSearchValue, searchInput, minInputLength],
  );

  const onClearSearch = useCallback(() => {
    setSearchValue("");
    setSearchInput("");
  }, [setSearchInput]);

  return {
    files: data || [],
    isLoading: isFetching,
    searchInput,
    searchValue,
    onSearchInputChange,
    onSearch,
    onClearSearch,
  };
};
