import { useState, useCallback } from "react";
import { ContentSection, ContentRepositoryId } from "modules/contentRepository/types";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Modal } from "UIKit";

import EditContentSectionModal from "../EditContentSectionWizard/EditContentSectionWizard";
import DeleteContentSectionModal from "../DeleteContentSectionModal/DeleteContentSectionModal";
import ContentSectionTile from "../ContentSectionTile/ContentSectionTile";

interface ContentSectionItemProps {
  contentRepositoryId: ContentRepositoryId;
  contentSection: ContentSection;
  "data-testid"?: string;
  isAdmin: boolean;
}

const ContentSectionItem = ({
  contentRepositoryId,
  contentSection,
  "data-testid": dataTestId,
  isAdmin,
}: ContentSectionItemProps) => {
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const handleCloseModal = () => {
    setModal(null);
  };

  const handleClickSection = useCallback(
    (section: ContentSection) => {
      push(`${url}/${encodeURIComponent(section.routeId)}`);
    },
    [push, url],
  );

  const handleEditSection = () => {
    setModal(
      <EditContentSectionModal
        contentRepositoryId={contentRepositoryId}
        contentSection={contentSection}
        onClose={handleCloseModal}
      />,
    );
  };

  const handleDeleteSection = () => {
    setModal(
      <DeleteContentSectionModal
        contentRepositoryId={contentRepositoryId}
        contentSection={contentSection}
        onClose={handleCloseModal}
      />,
    );
  };

  return (
    <>
      <Modal open={!!modal} onClose={handleCloseModal}>
        {modal}
      </Modal>
      <div id={contentSection.id} data-testid="content-section-list-item">
        <ContentSectionTile
          section={contentSection}
          onClick={handleClickSection}
          onEdit={isAdmin ? handleEditSection : undefined}
          onDelete={isAdmin ? handleDeleteSection : undefined}
          data-testid={dataTestId}
        />
      </div>
    </>
  );
};
export default ContentSectionItem;
