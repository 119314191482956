import React from "react";

export interface DocumentCacheContextValue {
  getDocumentUrl: (key?: string) => Promise<string>;
}

const DocumentCacheContext = React.createContext<DocumentCacheContextValue>({
  getDocumentUrl: (key?: string) => Promise.resolve(""),
});

export default DocumentCacheContext;
