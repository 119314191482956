import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";

import {
  Button,
  Title,
  ModalBody,
  FormError,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import PasswordInput from "../../../../Authentication/componentos/PasswordInput";
import PasswordInputValidator from "../../../../Authentication/componentos/PasswordInputValidator";

import { useChangePassword } from "../hooks/useChangePassword";

export interface ForgotPasswordModalProps {
  closeModal(): void;
}

export const ChangePasswordModal: React.FC<ForgotPasswordModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();

  const { formMethods, onSubmit, error, isLoading, isSuccessful } = useChangePassword();

  if (isSuccessful) {
    return <SuccessModal text="updated_password" onComplete={closeModal} />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("login_changePassword")}</Title>
      </ModalHeader>

      <ModalBody>
        <FormProvider {...formMethods}>
          <form className="flex flex-col space-y-2" onSubmit={onSubmit}>
            <PasswordInput
              id="oldPassword"
              label={t("login_old_password_label")}
              placeholder={t("login_enterOldPassword")}
            />
            <PasswordInputValidator
              id="newPassword"
              label={t("login_new_password_label")}
              placeholder={t("login_enterNewPassword")}
            />
            <PasswordInput
              id="confirmPassword"
              label={t("login_confirm_password_label")}
              placeholder={t("login_confirm_password_placeholder")}
            />
            <Button type="submit" isLoading={isLoading} className="self-end mt-8">
              {t("general_update")}
            </Button>

            <FormError error={error} />
          </form>
        </FormProvider>
      </ModalBody>
    </InnerModalWrapper>
  );
};
