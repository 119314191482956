import { ChildReference } from "@eolas-medical/core";

export type MainSectionIdentity = "genericContentRepository" | "miniApp" | null;

export interface MainSection
  extends Omit<ChildReference, "__typename" | "type" | "disabled" | "icon"> {
  id: string;
  routeId: string;
  name: string;
  description?: string;
  disabled?: boolean;
  identity: MainSectionIdentity;
  isIconEditable: boolean;
  isDeletable?: boolean;
  hasIssues?: boolean;
  iconUrl?: string;
  iconName?: string;
}

export enum MainSectionIdentityLabel {
  genericContentRepository = "Content Repository",
  miniApp = "Mini App",
}
