import { DateFilterType, FilterFieldNames } from "modules/portfolio/types";
import { FC, useState } from "react";
import { useController } from "react-hook-form";
import { Text, Toggle } from "UIKit";
import { dateOnly } from "Utilities/helpers";
import { FilterDatePicker } from "./FilterDatePicker";

export const DateFilter: FC<DateFilterType> = ({ className, control, sectionTitle, trigger }) => {
  const {
    field: { onChange: onChangeStartDate },
  } = useController({ control, name: "startDate" });

  const {
    field: { onChange: onChangeEndDate },
  } = useController({ control, name: "endDate" });

  const removeFilterSelection = ({ filterType }: { filterType: keyof typeof FilterFieldNames }) => {
    trigger();
    if (filterType === FilterFieldNames.startDate) {
      onChangeStartDate(dateOnly());
    }
    if (filterType === FilterFieldNames.endDate) {
      onChangeEndDate(dateOnly());
    }
  };

  const [isDateActive, setIsDateActive] = useState(false);

  const toggleDate = () => {
    if (!isDateActive) {
      return setIsDateActive(true);
    }

    setIsDateActive(false);
    removeFilterSelection({ filterType: FilterFieldNames.startDate });
    removeFilterSelection({ filterType: FilterFieldNames.endDate });
  };

  return (
    <div className={className}>
      <div className="flex flex-row mb-2">
        <Text level={1}>{sectionTitle}</Text>
        <button onClick={() => toggleDate()}>
          <Toggle className="mx-2" onChange={() => null} checked={isDateActive} />
        </button>
      </div>
      <div>
        {isDateActive ? (
          <div className={`flex flex-col lg:flex-row`}>
            <FilterDatePicker
              control={control}
              isActive={isDateActive}
              fieldName="startDate"
              title="From"
            />
            <FilterDatePicker
              control={control}
              isActive={isDateActive}
              fieldName="endDate"
              title="To"
            />
          </div>
        ) : (
          <div className="h-1" />
        )}
      </div>
    </div>
  );
};
