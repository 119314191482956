import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { AttentionIcon } from "Assets";
import { MajorIncidentType } from "Utilities/types";

import { DeleteButton } from "../Button";
import { FileTileWrapper, FileTileProps } from "./shared";

export const IncidentFileTile: React.FC<FileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  isDragging,
  onDelete = () => {},
  onSelectFile = () => {},
}) => {
  const { t } = useTranslation();

  const { createdAt, updatedAt, moreInfo } = eolasFile;

  return (
    <FileTileWrapper
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="w-8">
        <AttentionIcon
          width={24}
          height={24}
          data-testid="exclamationMarkIcon"
          className={eolasFile.moreInfoTwo === MajorIncidentType.active ? "text-red" : "hidden"}
        />
      </div>

      <div className="flex flex-col flex-1">
        <span className="font-semibold lg:text-lg mb-1 line-clamp-1">{eolasFile.name}</span>

        <span className="text-grey-dark text-sm line-clamp-1">
          {t("incidents_activated_by", {
            user: moreInfo,
          })}
        </span>

        <span className="text-grey-dark text-sm">
          {t("incidents_activated_on", {
            date: format(new Date(createdAt ?? ""), "do MMMM yyyy, HH:mm"),
          })}
        </span>

        {eolasFile.moreInfoTwo === MajorIncidentType.deactivated && (
          <span className="text-grey-dark text-sm">
            {t("incidents_deactivated_on", {
              date: format(new Date(updatedAt ?? ""), "do MMMM yyyy, HH:mm"),
            })}
          </span>
        )}
      </div>

      {isAdmin && (
        <DeleteButton
          className="self-start"
          onClick={() => onDelete(eolasFile)}
          data-testid={`deleteButtonFor-${eolasFile.name}`}
        />
      )}
    </FileTileWrapper>
  );
};
