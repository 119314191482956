import { useQueryClient } from "@tanstack/react-query";
import { portfolioService } from "modules/portfolio/client/PortfolioService";
import { useRequestStatus } from "Utilities";
import portfolioKeys from "./portfolio.queryKeys";

const useDeleteEvent = ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) => {
  const queryClient = useQueryClient();
  const { error, setRequestStatus, isSuccessful, isLoading } = useRequestStatus();

  const deleteEvent = (eventId: string) => {
    setRequestStatus({ status: "pending", error: "" });
    portfolioService
      .deleteEvent(eventId)
      .then(() => {
        setRequestStatus({ status: "success", error: "" });
        queryClient.invalidateQueries({
          queryKey: portfolioKeys.lists(),
        });
        queryClient.refetchQueries({
          queryKey: portfolioKeys.detail(eventId),
        });
        onSuccess();
      })
      .catch((error) => {
        setRequestStatus({ status: "error", error });
        onError();
      });
  };

  return {
    deleteEvent,
    deleteError: error,
    deletingEvent: isLoading,
    deleteSuccess: isSuccessful,
  };
};

export default useDeleteEvent;
