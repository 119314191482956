import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AnalyticsEvents } from "@eolas-medical/core";

import { ArrowRightIcon, MailIcon } from "Assets";
import { trackEvent, timeEvent } from "API/Analytics";
import { Button, FormElement, Input, Title, Text, FormError } from "UIKit";

import "../styles.css";
import { useResendVerificationCode } from "../hooks";

interface ResendCodeEnterEmailProps {
  setStage: any;
  setUserEmail: any;
}

export const ResendCodeEnterEmail: React.FC<ResendCodeEnterEmailProps> = ({
  setStage,
  setUserEmail,
}) => {
  const { t } = useTranslation();
  const { control, onSubmit, isLoading, error } = useResendVerificationCode(setStage, setUserEmail);

  useEffect(() => {
    trackEvent(AnalyticsEvents.VERIFY_EMAIL_STARTED);
    timeEvent(AnalyticsEvents.VERIFY_EMAIL_DURATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={onSubmit} className="flex flex-col flex-2 justify-center space-y-6 p-4 lg:p-16">
      <Title level={6}>{t("createAccount_verifyEmail_title")}</Title>
      <Text level={1}>{t("createAccount_forgotPassword_text")}</Text>

      <FormElement required id="verifyEmail" control={control} label={t("createAccount_email")}>
        {(field) => (
          <Input
            {...field}
            iconLeft={<MailIcon />}
            placeholder={t("login_enterEmail")}
            data-testid="forgot-password-enterEmail"
          />
        )}
      </FormElement>

      <Button
        type="submit"
        isLoading={isLoading}
        className="self-end"
        data-testid="forgot-password-submit"
      >
        <ArrowRightIcon fill="white" height={25} width={25} />
      </Button>

      {error && <FormError error={error} />}
    </form>
  );
};
