import { get } from "lodash";
import { useForm } from "react-hook-form";
import { sectionStore } from "@eolas-medical/core";

import { useUpdateApp } from "Hooks";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";

interface AddTrustedEmailForm {
  emailDomain: string;
}

const mapToDefaultValues = (): AddTrustedEmailForm => {
  return {
    emailDomain: "",
  };
};

const mapToInput = (values: AddTrustedEmailForm): string => {
  const trustedEmail = sectionStore.data.app?.trustedEmail ?? "";

  if (trustedEmail === "adminAuthentication" || trustedEmail === "publicAccess") {
    // if no values provided fallback to adminAuthentication
    return values.emailDomain.trim().toLowerCase().replace(/@/g, "") || "adminAuthentication";
  } else {
    const mails = trustedEmail.split(";");
    mails.push(values.emailDomain);

    const emailAccess = mails.map((e) => e.trim().toLowerCase().replace(/@/g, "")).join(";");

    return emailAccess || "adminAuthentication";
  }
};

export const useAddTrustedEmail = () => {
  const appID = sectionStore.appID;
  const { updateApp } = useUpdateApp();
  const { isLoading, isSuccessful, error, setRequestStatus } = useRequestStatus();

  const { control, handleSubmit, formState } = useForm<AddTrustedEmailForm>({
    defaultValues: mapToDefaultValues(),
  });

  const onSubmit = handleSubmit(async (values) => {
    setRequestStatus({ status: "pending", error: "" });

    try {
      const response = await updateApp({
        id: appID,
        trustedEmail: mapToInput(values),
      });

      const updatedData = get(response, "data.updateApp", {});
      sectionStore.setData({ app: updatedData });
      setRequestStatus({ status: "success", error: "" });
    } catch (err: any) {
      errorStore.captureError({ error: err.message, source: "user" });
      setRequestStatus({ status: "error", error: err.message });
    }
  });

  return {
    error,
    control,
    isLoading,
    isSuccessful,
    isDirty: formState.isDirty,
    onSubmit,
  };
};
