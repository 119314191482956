import React from "react";
import { Text } from "UIKit";
import { CheckAll } from "Assets";

const OwnerNotification = ({ text }: { text: string }) => {
  return (
    <div className="flex items-center ml-2">
      <CheckAll color="blue" className="w-5 h-5 m-auto" />
      <Text level={2} className="ml-1 m-auto font-bold">
        {text}
      </Text>
    </div>
  );
};

export default OwnerNotification;
