import { AttentionIcon, success } from "Assets";
import * as coloredIcons from "Assets/Icons/colored";

import { LottieWithHeader } from "UIKit/LottieWithHeader";

import type { EolasColoredIcons } from "Assets/Icons/colored";

import { Button } from "../../Button";
import { Title, Text } from "../../Typography";
import { InnerModalWrapper } from "../components";

export interface DeleteFileModalProps {
  title: string;
  error?: string;
  isLoading?: boolean;
  description?: string;
  successLabel?: string;
  isSuccessful?: boolean;
  iconType?: EolasColoredIcons;
  onDelete(): void;
  onCloseModal(): void;
}

export const DeleteFileModal: React.FC<DeleteFileModalProps> = ({
  error,
  title,
  isLoading,
  isSuccessful,
  successLabel = "Success!",
  iconType = "TrashCan",
  description = "deleteModal_delete_file_description",
  onDelete,
  onCloseModal,
}) => {
  if (isSuccessful) {
    return (
      <InnerModalWrapper>
        <LottieWithHeader
          animation={success}
          text={successLabel}
          animationSize="150px"
          onComplete={onCloseModal}
          data-testid="success-animation"
        />
      </InnerModalWrapper>
    );
  }

  const Icon = coloredIcons[iconType];

  return (
    <InnerModalWrapper>
      <div className="flex flex-col items-center p-6 px-12 sm:p-12" data-testid="delete-modal">
        <Icon className="w-12 h-12 sm:w-16 sm:h-16" />

        <Title level={5} className="line-clamp-2 text-center mt-4">
          {title}
        </Title>

        <Text level={2} className="text-center text-grey-700 font-bold text-sm mt-2">
          {description}
        </Text>
        <div className="flex flex-col flex-1 w-full">
          {error && (
            <div className="flex justify-center items-center text-red my-2 sm:my-4">
              <AttentionIcon className="w-6 h-6 mr-2" />
              <Text level={2} className="font-semibold">
                {error}
              </Text>
            </div>
          )}
          <Button
            size="lg"
            color="red"
            variant="solid"
            onClick={onDelete}
            isLoading={isLoading}
            className="w-1/2 self-center sm:w-full mt-2 sm:mt-4"
            data-testid="delete-modal-confirm-button"
          >
            Delete
          </Button>
          <Button
            size="lg"
            color="grey"
            variant="outline"
            onClick={onCloseModal}
            disabled={isLoading}
            className="w-1/2 self-center sm:w-full mt-2 sm:mt-4"
            data-testid="delete-modal-cancel-button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </InnerModalWrapper>
  );
};
