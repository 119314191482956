import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

import { Text, IconButton, theme } from "UIKit";
import { TrustedEmailIcon, CopyIcon, DeleteIcon } from "Assets";

import { useTrustedEmail } from "./useTrustedEmail";

interface TrustedEmailProps {
  email: string;
}

export const TrustedEmail: React.FC<TrustedEmailProps> = ({ email }) => {
  const { t } = useTranslation();
  const {
    isLoading,
    tooltipText,
    onDeleteEmail,
    onCopyClicked,
    afterTooltipHide,
  } = useTrustedEmail(email);

  return (
    <div className="flex items-center p-4">
      <TrustedEmailIcon className="w-10 h-10" />

      <Text className="flex-1 text-black font-bold ml-4" level={1}>
        {email}
      </Text>

      <ReactTooltip
        border
        type="light"
        place="bottom"
        effect="solid"
        id={`copy-${email}`}
        className={"tooltip"}
        afterHide={afterTooltipHide}
        backgroundColor={theme.colors.white}
        borderColor={theme.colors.grey.darker}
      >
        <span className="block font-normal">{t(tooltipText)}</span>
      </ReactTooltip>

      <IconButton
        size="sm"
        data-tip
        className="mx-2"
        variant="rounded"
        icon={<CopyIcon />}
        onClick={onCopyClicked}
        data-for={`copy-${email}`}
      />

      <IconButton
        isLoading={isLoading}
        size="sm"
        variant="rounded"
        icon={<DeleteIcon />}
        onClick={onDeleteEmail}
      />
    </div>
  );
};
