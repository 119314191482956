import { useContext } from "react";
import DocumentCacheContext from "./documentCache.context";

const useDocumentCache = () => {
  const context = useContext(DocumentCacheContext);

  if (!context) {
    throw new Error("useSpacesContext must be used within a SpacesProvider");
  }

  return context;
};

export default useDocumentCache;
