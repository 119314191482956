import React from "react";
import { ConfirmationModal } from "UIKit";
import { useTranslation } from "react-i18next";

type ReassignAssessorModalProps = {
  onAssign(e: React.MouseEvent<HTMLButtonElement>): void;
  onCancel(e: React.MouseEvent<HTMLButtonElement>): void;
  isLoading?: boolean;
};

function ReassignAssessorModal({ onAssign, onCancel, isLoading }: ReassignAssessorModalProps) {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      title={t("portfolio_reassign_assessor_title")}
      iconType="WarningIcon"
      acceptButtonProps={{ isLoading }}
      onAccept={onAssign}
      onDecline={onCancel}
      description={t("portfolio_reassign_assessor_subtitle")}
      acceptLabel={t("portfolio_reassign_assessor_reassign_button")}
      declineLabel={t("portfolio_reassign_assessor_cancel_button")}
    />
  );
}

export default ReassignAssessorModal;
