import { useState } from "react";
import { Step, Wizard, useWizard } from "UIKit/Wizard";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import { Option } from "UIKit/Select/Select";
import { useBrowserCountry } from "Hooks/useBrowserCountry";
import { trackEvent } from "API/Analytics";
import { useSpaceNameStep } from "./components/SpaceName/useSpaceNameStep";
import { useSpaceDescriptionStep } from "./components/SpaceDescription/useSpaceDescriptionStep";
import { useSpaceSpecialtyStep } from "./components/SpaceSpecialty/useSpaceSpecialty";
import { useSpaceImageStep } from "./components/SpaceImage/useSpaceImageStep";
import { useSpaceAccessTypeStep } from "Pages/Spaces/components/AccessTypeForm/useSpaceAccessTypeStep";
import useCreateSpace from "modules/spaces/data/useCreateSpace";
import { useSpaceTemplateSelectorStep } from "Pages/Spaces/components/TemplateSelector/useSpaceTemplateSelectorStep";

type CreateSpaceSteps =
  | "name"
  | "description"
  | "specialty"
  | "image"
  | "access"
  | "templateSelector";
interface WizardState {
  name: string;
  description: string;
  specialty: Option[];
  url: string;
  spaceTemplateId: string;
}
interface CreateSpaceModalProps {
  onCreate: (space: any) => void;
}

const CreateSpaceModal = ({ onCreate }: CreateSpaceModalProps) => {
  const { createSpace, creatingSpace, errorCreatingSpace } = useCreateSpace();
  const { browserCountryCode } = useBrowserCountry();
  const { wizardControl, submitWizard } = useWizard<CreateSpaceSteps>();
  const [isPublic, setIsPublic] = useState(true);
  const [wizardState, setWizardState] = useState<WizardState>({
    name: "",
    description: "",
    specialty: [],
    url: "",
    spaceTemplateId: "",
  });

  const { userID = "" } = userStore;

  const handleCreateSpace = () => {
    const spaceSpecialties = wizardState.specialty.map(({ value }: Option) => value);

    createSpace(
      {
        ...wizardState,
        accessType: isPublic ? "public" : "private",
        specialty: spaceSpecialties,
        countryCode: browserCountryCode,
        userId: userID,
      },
      {
        onSuccess: (createdSpace: any) => {
          onCreate(createdSpace);
        },
      },
    );
  };

  const handleSubmitImageStep = ({ image, imageUrl }: { image?: File; imageUrl?: string }) => {
    setWizardState((prev: WizardState) => ({
      ...prev,
      file: image,
      url: imageUrl || "",
    }));
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_IMAGE_COMPLETED);
  };

  const handleSubmitAccessTypeStep = () => {
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_ACCESS_TYPE_COMPLETED);
  };

  const handleSubmitSpaceName = ({ name }: { name: string }) => {
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_NAME_COMPLETED);
    setWizardState((prev: WizardState) => ({
      ...prev,
      name,
    }));
  };

  const handleSubmitSpaceDescription = ({ description }: { description: string }) => {
    setWizardState((prev: WizardState) => ({
      ...prev,
      description,
    }));
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_DESCRIPTION_COMPLETED);
  };

  const handleSubmitSpaceSpecialty = ({ specialty }: { specialty: Option[] }) => {
    setWizardState((prev: WizardState) => ({
      ...prev,
      specialty,
    }));
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_KEYWORD_TAGS_COMPLETED);
  };

  const handleChangeAccessType = ({ isPublic }: { isPublic: boolean }) => {
    setIsPublic(isPublic);
  };

  const handleChangeTemplate = ({ spaceTemplateId }: { spaceTemplateId: string }) => {
    setWizardState((prev: WizardState) => ({
      ...prev,
      spaceTemplateId,
    }));
  };

  const spaceNameStep = useSpaceNameStep({
    onSubmit: handleSubmitSpaceName,
    onEnter: submitWizard,
  });

  const spaceDescriptionStep = useSpaceDescriptionStep({
    onSubmit: handleSubmitSpaceDescription,
    onEnter: submitWizard,
  });

  const spaceSpecialtyStep = useSpaceSpecialtyStep({
    onSubmit: handleSubmitSpaceSpecialty,
    onEnter: submitWizard,
  });

  const spaceImageStep = useSpaceImageStep({
    onSubmit: handleSubmitImageStep,
  });

  const spaceAccessTypeStep = useSpaceAccessTypeStep({
    onSubmit: handleSubmitAccessTypeStep,
    spaceName: wizardState.name,
    onChange: handleChangeAccessType,
    isPublic,
  });

  const spaceTemplateSelectorStep = useSpaceTemplateSelectorStep({
    onSubmit: handleCreateSpace,
    spaceTemplateId: wizardState.spaceTemplateId,
    isLoading: creatingSpace,
    onChange: handleChangeTemplate,
    errorCreatingSpace: errorCreatingSpace,
  });

  const steps: Step<CreateSpaceSteps>[] = [
    spaceNameStep,
    spaceDescriptionStep,
    spaceSpecialtyStep,
    spaceImageStep,
    spaceAccessTypeStep,
    spaceTemplateSelectorStep,
  ];

  return <Wizard wizardControl={wizardControl} steps={steps} showProgress showSteps />;
};

export default CreateSpaceModal;
