import { useCallback, useState } from "react";
import { ImageBankItem } from "Hooks/useImageBank";
import ImageWithCheck from "UIKit/ImageWithCheck/ImageWithCheck";

type ImageGalleryProps = {
  images: any;
  onChange: (image: string) => void;
  defaultChecked: string | null;
};

const ImageGallery = ({ onChange, images, defaultChecked }: ImageGalleryProps) => {
  const [activeImage, setActiveImage] = useState<string | null>(defaultChecked || null);

  const handleImageClick = useCallback(
    (image: ImageBankItem) => {
      setActiveImage(activeImage === image.smallImageUrl ? null : image.smallImageUrl);
      onChange(image.imageUrl);
    },
    [activeImage, onChange],
  );

  const emptyImage = {
    name: "",
    smallImageUrl: null,
    imageUrl: null,
  };

  return (
    <div className="grid grid-cols-2 gap-4 max-h-40vh overflow-y-scroll px-2">
      {[emptyImage, ...images].map((image: ImageBankItem) => (
        <div key={image.name}>
          <ImageWithCheck
            key={image.name}
            image={image.smallImageUrl}
            showCheck={image.smallImageUrl === activeImage}
            onClick={() => handleImageClick(image)}
          />
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
