import { useMediaQuery } from "Hooks";
import { useTranslation } from "react-i18next";
import { Button, Loader, Text } from "UIKit";
import { defaultSpace } from "Assets";

interface SpaceDetailsProps {
  name: string;
  description: string;
  imageUrl?: string;
  onClickViewSpace: () => void;
  onClickEditSpace: () => void;
  onClickShareSpace: () => void;
  isAdminUser: boolean;
  usersCount: number;
  isLoading?: boolean;
  isPublic: boolean;
}

const SpaceDetails = ({
  name,
  description,
  imageUrl = defaultSpace,
  onClickViewSpace,
  onClickEditSpace,
  onClickShareSpace,
  isAdminUser,
  usersCount = 1,
  isLoading = false,
  isPublic,
}: SpaceDetailsProps) => {
  const { t } = useTranslation();
  const media = useMediaQuery();
  const isMobile = media === "xs";
  const isSmallDevice = media === "sm";

  const renderSpacesMobile = () => {
    return (
      <div className="flex justify-center items-center space-x-4">
        <Button
          color="white"
          iconLeft="InformationIcon"
          className="flex items-center justify-center h-12 w-40 rounded-3xl hover:bg-grey-50"
          onClick={isAdminUser ? onClickEditSpace : onClickViewSpace}
        >
          <Text level={2} className="text-black font-bold ml-2">
            {t("space_details_space_info")}
          </Text>
        </Button>
        {isPublic && (
          <Button
            color="white"
            iconLeft="ShareIcon"
            className="flex items-center justify-center h-12 w-40 rounded-3xl hover:bg-grey-50"
          >
            <Text level={2} className="text-black font-bold ml-2" onClick={onClickShareSpace}>
              {t("space_details_share_space")}
            </Text>
          </Button>
        )}
      </div>
    );
  };

  const renderSpacesDesktop = () => {
    return (
      <div className="w-full bg-white rounded-3xl relative p-4">
        <div
          className="relative w-full h-40  overflow-hidden mb-20"
          style={{
            borderRadius: "2.2rem 2.2rem 0 0",
          }}
        >
          <img
            data-testid="space-image"
            className="w-full h-full object-cover"
            src={imageUrl}
            alt=""
          />
          <div className="absolute bottom-0 w-full h-2/5">
            <div
              className="w-full h-full"
              style={{
                backgroundImage: `
                  linear-gradient(transparent, rgba(255, 255, 255, 0.8) 50%, white 100%)
                `,
              }}
            ></div>
          </div>
        </div>
        <div className="absolute inset-0 flex flex-col justify-end items-center z-10 p-6 ml-4 mr-4">
          {isLoading ? (
            <Loader className="bg-transparent" />
          ) : (
            <div className="flex flex-col w-full">
              <div className="text-2xl font-extrabold md:text-4xl line-clamp-1">{name}</div>
              <div className="text-base font-semibold text-left text-grey-600">
                {usersCount}
                {usersCount > 1 ? t("space_details_members") : t("space_details_member")}
              </div>
              <div className="flex justify-between items-center h-12 gap-4">
                <div className="font-semibold text-grey-700 text-left line-clamp-2 mt-4">
                  {description}
                </div>
                <div className="flex gap-x-4 mt-4">
                  {isAdminUser && (
                    <Button
                      iconLeft={isSmallDevice ? null : "EditMonoIcon"}
                      color="grey"
                      size="sm"
                      className="w-32 rounded-3xl"
                      onClick={onClickEditSpace}
                    >
                      <Text level={3} className="ml-2">
                        {t("space_details_edit_space")}
                      </Text>
                    </Button>
                  )}
                  {isPublic && (
                    <Button
                      iconLeft={isSmallDevice ? null : "ShareIcon"}
                      variant="solid"
                      size="sm"
                      className="w-32 rounded-3xl"
                      onClick={onClickShareSpace}
                    >
                      <Text level={3} className="ml-2">
                        {t("space_details_share_space")}
                      </Text>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return isMobile ? renderSpacesMobile() : renderSpacesDesktop();
};

export default SpaceDetails;
