import { AnalyticsEvents } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { DeleteIcon, EditMonoIcon, DownloadIcon } from "Assets";
import { useNotifications } from "Components/Notifications";
import useEditFile from "modules/myFiles/data/useEditFile";
import useMyFileDownload from "modules/myFiles/data/useMyFileDownload";
import { MyFile } from "modules/myFiles/types";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DropdownOption } from "UIKit";
import { Action } from "Utilities/types";
import { FileTile } from "./FileTile";

export type MenuActionType = "edit" | "delete" | "download";

interface FileListItemProps {
  file: MyFile;
  onActionSelect?: (action: string) => void;
  isDraggable?: boolean;
  isMenuDisabled?: boolean;
}

const FileListItem = ({
  file,
  isDraggable = false,
  isMenuDisabled = false,
  onActionSelect,
}: FileListItemProps) => {
  const { t } = useTranslation();
  const { showNotification, hideNotification } = useNotifications();
  const { editFile, editingFile, editFileError, editFileSuccessful } = useEditFile();
  const { download, preparingDownload, downloadError } = useMyFileDownload();

  const handleActionMenuSelect = useCallback(
    (option: DropdownOption) => {
      if (option.value === "download") {
        download(file.id);
        trackEvent(AnalyticsEvents.MYFILES_FILE_DOWNLOAD, {
          fileId: file.id,
          subSectionId: file.sectionId,
        });
      } else {
        if (onActionSelect) onActionSelect(option.value);
      }
    },
    [onActionSelect, download, file],
  );

  useEffect(() => {
    const error = editFileError || downloadError;
    if (error) {
      showNotification({ type: "error", title: error, autoHideTimeout: 3000 });
    }
  }, [editFileError, downloadError, showNotification]);

  useEffect(() => {
    if (editFileSuccessful) {
      showNotification({ type: "success", title: "Successfully updated", autoHideTimeout: 3000 });
    }
  }, [editFileSuccessful, showNotification]);

  useEffect(() => {
    if (preparingDownload) {
      showNotification({ type: "loading", title: "Getting file", id: "download-loading" });
    } else {
      hideNotification("download-loading");
    }
  }, [preparingDownload, showNotification, hideNotification]);

  const menuOptions = useMemo(() => {
    const sharedOptions: DropdownOption[] = [
      {
        label: Action.DELETE,
        value: "delete",
        icon: <DeleteIcon width={16} height={16} />,
        disabled: file.referencesCount > 0,
      },
      {
        label: Action.EDIT,
        value: "edit",
        icon: <EditMonoIcon width={16} height={16} />,
        disabled: file.referencesCount > 0,
      },
    ];

    if (file.type === "BLOB") {
      sharedOptions.push({
        label: Action.DOWNLOAD,
        value: "download",
        icon: <DownloadIcon width={16} height={16} />,
      });
    }
    return sharedOptions;
  }, [file.referencesCount, file.type]);

  const handleClickViewFile = useCallback(() => {
    trackEvent(AnalyticsEvents.MYFILES_FILE_OPENED, { fileId: file.id });
  }, [file.id]);

  const Wrapper = useCallback(
    ({ children }) => {
      return (
        <Link
          to={file.link ? { pathname: file.link.url } : `file/${file.id}`}
          target={file.link ? "_blank" : ""}
          rel="noreferrer"
          data-testid="file-list-item"
          onClick={handleClickViewFile}
        >
          {children}
        </Link>
      );
    },
    [file.id, file.link, handleClickViewFile],
  );

  const handleFavouriteClick = useCallback(
    (isFavourite: boolean) => {
      editFile({ id: file.id, isFavourite });
    },
    [editFile, file.id],
  );

  return (
    <Wrapper>
      <FileTile
        file={file}
        menuOptions={menuOptions}
        updatingFavourites={editingFile}
        isDraggable={isDraggable}
        isMenuDisabled={isMenuDisabled}
        onActionMenuSelect={handleActionMenuSelect}
        onFavouriteClick={handleFavouriteClick}
        tooltipText={t("my_files_disabled_menu_tooltip")}
      />
    </Wrapper>
  );
};

export default FileListItem;
