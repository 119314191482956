import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { AppLevelSection, fetchAllData, sectionStore } from "@eolas-medical/core";
import { publishFile } from "clients/files/files.client";
import useUploadTeamMemberPhoto from "./useUploadTeamMemberPhoto";

interface AddTeamMemberInput {
  name: string;
  role: string;
  bio: string;
  photo: any;
}

const useAddTeamMember = () => {
  const { uploadTeamMemberPhoto } = useUploadTeamMemberPhoto();

  const handleAddTeamMember = useCallback(
    async ({ name, role, bio, photo }: AddTeamMemberInput) => {
      const parentID = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.meetTheTeam)!
        .id;

      const s3Key = await uploadTeamMemberPhoto(photo);

      return publishFile({
        parentID,
        input: { name, description: role, infoTwo: bio, key: s3Key },
      });
    },
    [uploadTeamMemberPhoto],
  );

  const { mutate, isLoading, isSuccess, error } = useMutation(handleAddTeamMember, {
    onSuccess: () => {
      fetchAllData();
    },
  });

  return {
    addTeamMember: mutate,
    addingTeamMember: isLoading,
    addTeamMemberSuccessful: isSuccess,
    addTeamMemberError: error as string,
  };
};

export default useAddTeamMember;
