import { ApiClient, ApiClientParams } from "@eolas-medical/core";
import envConfig from "env-config";
import { API_ENV_PATH_PREFIXS } from "Utilities";

export interface LoginTokenItem {
  id: string;
  email: string;
  appID: string;
  userID: string;
}

export interface LoginWithTokenResponse {
  AuthenticationResult: {
    AccessToken: string;
    ExpiresIn: number;
    IdToken: string;
    RefreshToken: string;
  };
  userID: string;
  appID: string;
  ChallengeParameters: any;
  email: string;
  password: string;
}

class LoginTokenService extends ApiClient {
  private path: string = `/auth-service-${API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV]}`;

  public async loginWithToken(token: string) {
    return this.http.post<LoginWithTokenResponse>(`${this.path}/token/login`, { token });
  }

  public async listTokens(appID: string) {
    return this.http.post<Array<LoginTokenItem>>(`${this.path}/token/list`, { appID });
  }

  public async createToken(appID: string, given_name: string, family_name: string) {
    return this.http.post<{ token: string }>(`${this.path}/token/create`, {
      appID,
      given_name,
      family_name,
    });
  }

  public async deleteToken(token: string) {
    return this.http.delete(`${this.path}/token/delete`, { data: { token } });
  }
}

export let loginTokenService: LoginTokenService;

export const initialiseLoginTokenService = (params: ApiClientParams) => {
  loginTokenService = new LoginTokenService(params);
};
