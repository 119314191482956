import React from "react";
import { Title } from "UIKit";
import { getVerificationStatusPill } from "Pages/MeTab/helpers";
import { ProfileVerification } from "@eolas-medical/core";

interface VerificationValueProps {
  profileVerification?: ProfileVerification;
}

const VerificationValue = ({ profileVerification }: VerificationValueProps) => {
  return (
    <div className="flex flex-col">
      {profileVerification ? (
        <div>
          <Title level={9} className="text-grey-700 inline-flex mb-2">
            {profileVerification.professionalBody}
          </Title>
          {": "}
          <Title level={9} className="inline-flex">
            {profileVerification.registrationNumber}
          </Title>
        </div>
      ) : null}
      <div className="self-start">{getVerificationStatusPill(profileVerification?.status)}</div>
    </div>
  );
};

export default VerificationValue;
