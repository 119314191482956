import { ProfileVerificationStatus, UserLocation } from "@eolas-medical/core";
import { getVerificationStatusPill } from "Pages/MeTab/helpers";
import { Button, Text } from "UIKit";
import React from "react";
import { useTranslation } from "react-i18next";
import ProfileLocation from "../ProfileLocation/ProfileLocation";
import { UserProfileIcon } from "Assets";

type ProfileDetailsProps = {
  firstName: string;
  lastName: string;
  jobTitle: string;
  imageUrl: string;
  location?: UserLocation;
  verificationStatus?: ProfileVerificationStatus;
  onClick: () => void;
};

const ProfileDetails = ({
  firstName,
  lastName,
  jobTitle,
  imageUrl,
  location,
  verificationStatus,
  onClick,
}: ProfileDetailsProps) => {
  const { t } = useTranslation();
  return (
    <div className="space-y-4 relative">
      <div className="relative space-y-4">
        <div className="h-40 w-40 md:h-56 md:w-56 rounded-lg flex justify-center items-center mx-auto  border-8 border-white bg-white">
          {imageUrl ? (
            <img src={imageUrl} alt="" className="h-full w-full object-cover rounded-md" />
          ) : (
            <UserProfileIcon className="w-5/6 h-5/6" />
          )}
        </div>
        <Button
          color="grey"
          variant="outline"
          className="absolute left-1/2 transform -translate-x-1/2 -bottom-5 p-5 border-none cursor-pointer whitespace-nowrap"
          onClick={onClick}
          iconLeft="EditPencilIcon"
        >
          <Text level={1} className="ml-2 font-bold">
            {t("my-medical_personal_details_edit_profile")}
          </Text>
        </Button>
      </div>
      <p className="text-3xl md:text-4xl font-extrabold text-center ">{`${firstName} ${lastName}`}</p>
      {verificationStatus ? getVerificationStatusPill(verificationStatus) : null}

      {jobTitle ? (
        <p className="text-base md:text-lg font-semibold text-center">{jobTitle}</p>
      ) : null}

      {location ? <ProfileLocation location={location} /> : null}
    </div>
  );
};

export default ProfileDetails;
