import { QueryEnvironment, useEolasQuery } from "Hooks";
import { getSupervisorsList } from "../client/portfolio.client";
import { SupervisionSummary } from "../types";
import portfolioKeys from "./portfolio.queryKeys";
import { useMemo } from "react";

interface UseSupervisorsList {
  supervisorsList: SupervisionSummary[];
  supervisorsRequestList: SupervisionSummary[];
  supervisorsListLoading: boolean;
}

export const useSupervisorsList = (ownerId: string): UseSupervisorsList => {
  const { isLoading, data, ...rest } = useEolasQuery({
    queryFn: () => getSupervisorsList(ownerId),
    queryKey: portfolioKeys.supervisorsList(),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 300000,
      staleTime: 60000,
      enabled: ownerId.length > 0,
    },
  });

  const [supervisorsRequestList, supervisorsList] = useMemo(() => {
    if (!data) return [[], []];

    const { supervisionRequestsSummary = [], supervisorsSummary = [] } = data;

    const supervisionRequests = supervisionRequestsSummary as SupervisionSummary[];
    const supervision = supervisorsSummary as SupervisionSummary[];

    return [supervisionRequests, supervision];
  }, [data]);

  return { supervisorsListLoading: isLoading, supervisorsRequestList, supervisorsList, ...rest };
};

export default useSupervisorsList;
