import { FC } from "react";

import { format } from "date-fns";
import { Control, useController } from "react-hook-form";

import { CalendarMonoIcon } from "Assets";
import { DatePicker, Text, Title } from "UIKit";

interface DatePickerProps {
  control?: Control<any>;
  fieldName: string;
  isActive: boolean;
  onClick?: () => void;
  title: string;
  placeholder?: string;
}

export const FilterDatePicker: FC<DatePickerProps> = ({
  control,
  fieldName,
  isActive,
  onClick,
  title,
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: fieldName });

  return (
    <div className="mt-2 lg:mb-10">
      <DatePicker
        className="self-start align-top"
        onChange={(selectedDate) => {
          onChange(new Date(selectedDate || value));
        }}
        customWrapper={
          <button
            className={`rounded-md h-24 w-52 sm:w-80 mt-0 self-start border-grey-300 ${
              isActive ? `bg-white` : `bg-grey-300`
            } border px-4 pt-2 sm:pb-8 mx-2 content-center`}
            onClick={onClick}
          >
            <Title level={7} className="my-2 text-base sm:text-lg w-min justify-self-start">
              {title}
            </Title>
            <div className="flex flex-row">
              <CalendarMonoIcon className="w-5 h-5" fill={isActive ? "black" : "grey600"} />
              <Text
                className={`mx-3 mb-4 sm:mb-2 text-sm sm:text-base ${
                  isActive ? "text-black" : "text-grey-600"
                }`}
                level={1}
              >
                {format(new Date(value), "do MMMM yyyy") ?? "Select Date"}
              </Text>
            </div>
          </button>
        }
      />
      <Text level={3} className="text-red-700 justify-center mx-auto">
        {error?.message || " "}
      </Text>
    </div>
  );
};
