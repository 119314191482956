import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { InnerPageWrapper, MainSectionTile, NavButton, PageTitle, GridContainer } from "UIKit";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";
import { FeedbackIcon, MyPortfolioIcon, SupervisionIcon } from "../icons";
import { useMainSectionVisit } from "Hooks";
import { MeLevelSection } from "@eolas-medical/core";

export const PortfolioAndFeedback: React.FC<RouteComponentProps> = ({ match: { url } }) => {
  const { t } = useTranslation();

  useMainSectionVisit({
    mainSectionId: MeLevelSection.myPortfolio,
  });

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-center">
        <PageTitle className="my-4" title={t("portfolio_homepage")} />
        <NavButton to={url.replace(`/${PortfolioConstants.PORTFOLIO_BASE_URL}`, "")} />
      </div>
      <GridContainer>
        <MainSectionTile
          icon={<MyPortfolioIcon className="h-8 w-8 lg:h-12 lg:w-12" />}
          title={t("my_portfolio")}
          description={t("my_portfolio_description")}
          to={`${url}/${PortfolioConstants.MY_PORTFOLIO_URL}`}
        />

        <MainSectionTile
          icon={<FeedbackIcon className="h-8 w-8 lg:h-12 lg:w-12" />}
          title={t("feedback_and_forms")}
          description={t("feedback_and_forms_description")}
          to={`${url}/${PortfolioConstants.FEEDBACK_URL}`}
        />

        <MainSectionTile
          icon={<SupervisionIcon className="h-12 w-12 lg:h-20 lg:w-20" />}
          title={t("portfolio_supervision")}
          description={t("portfolio_supervision_description")}
          to={`${url}/${PortfolioConstants.SUPERVISION_URL}`}
        />
      </GridContainer>
    </InnerPageWrapper>
  );
};
