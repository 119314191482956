import { ClinicalCalculatorsSubSection } from "@eolas-medical/clinical-calculators";
import localforage from "localforage";
import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";

type SeenCalculatorsMap = Record<string, string>;
class WebStore {
  constructor() {
    makeObservable(this);
    this.isHydrated = new Promise((resolve, reject) => {
      makePersistable(this, {
        stringify: false,
        debugMode: false,
        name: "webStore",
        storage: localforage,
        properties: ["viewedWhatsNew", "seenCalculatorsWarning"],
      })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public isHydrated: Promise<boolean>;

  @observable public viewedWhatsNew: any = [];
  @observable public seenCalculatorsWarning: SeenCalculatorsMap = {};

  @action hasSeenCurrentWhatsNew(url: string) {
    return this.viewedWhatsNew.includes(url);
  }

  @action setWhatsNewSeenStatus(url: string) {
    this.viewedWhatsNew.push(url);
  }

  @action hasSeenCalculatorsWarning(calculatorName: ClinicalCalculatorsSubSection) {
    return this.seenCalculatorsWarning[calculatorName];
  }

  @action setCalculatorsWarningAsSeen(calculatorName: ClinicalCalculatorsSubSection) {
    const newDate = new Date();
    this.seenCalculatorsWarning[calculatorName] = newDate.toISOString();
  }
}

export let webStore: WebStore;

export const initialiseWebStore = async () => {
  webStore = new WebStore();
  await webStore.isHydrated;
};
