import { AppLevelSection } from "@eolas-medical/core";

const templates = [
  {
    id: "1",
    title: "Healthcare Department or Team",
    description:
      "This template gives an initial structure applicable for any hospital department, primary care practice or healthcare team. This can be easily edited in the admin panel, with the features listed being easily amended.",
    mainSections: [
      AppLevelSection.channels,
      AppLevelSection.newsFeed,
      AppLevelSection.guidelines,
      AppLevelSection.checklists,
      AppLevelSection.emergencyActionCards,
      AppLevelSection.rotas,
      AppLevelSection.contacts,
      AppLevelSection.meetTheTeam,
      AppLevelSection.howTo,
      AppLevelSection.patientLeaflets,
      AppLevelSection.theDepartment,
      AppLevelSection.importantLinks,
      AppLevelSection.researchAndAudit,
    ],
  },
  {
    id: "2",
    title: "Pre-hospital or EMS",
    description:
      "This template gives an initial structure applicable for any pre-hospital team, EMS service or retrieval service. This can be easily edited in the admin panel, with the features listed being easily amended.",
    mainSections: [
      AppLevelSection.channels,
      AppLevelSection.newsFeed,
      AppLevelSection.checklists,
      AppLevelSection.guidelines,
      AppLevelSection.emergencyActionCards,
      AppLevelSection.educationPortal,
      AppLevelSection.rotas,
      AppLevelSection.contacts,
      AppLevelSection.meetTheTeam,
      AppLevelSection.equipmentLocations,
      AppLevelSection.importantLinks,
    ],
  },
  {
    id: "3",
    title: "Conference or Course",
    description:
      "This template gives an initial structure applicable for any healthcare course, conference or training event. This can be easily edited in the admin panel, with the features listed being easily amended.",
    mainSections: [
      AppLevelSection.channels,
      AppLevelSection.guidelines,
      AppLevelSection.rotas,
      AppLevelSection.newsFeed,
      AppLevelSection.contacts,
      AppLevelSection.meetTheTeam,
    ],
  },
  {
    id: "4",
    title: "Medical Education",
    description:
      "This template gives an initial structure applicable for any medical education team, department or education content creators. This can be easily edited in the admin panel, with the features listed being easily amended.",
    mainSections: [
      AppLevelSection.channels,
      AppLevelSection.newsFeed,
      AppLevelSection.guidelines,
      AppLevelSection.educationPortal,
      AppLevelSection.rotas,
      AppLevelSection.contacts,
      AppLevelSection.meetTheTeam,
      AppLevelSection.importantLinks,
      AppLevelSection.howTo,
    ],
  },
];

export const useGetTemplates = () => {
  // todo - replace this with fetch when endpoint ready
  return templates;
};
