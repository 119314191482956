import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { EolasFile, AppLevelSection, OrganisationLevelSection } from "@eolas-medical/core";

import { SearchIcon, noFilesFound } from "Assets";
import { GuidelineFileTile } from "UIKit/FileTile";
import { generateS3PublicImageURL } from "Utilities";
import { useMainSectionVisit, useSelectFile } from "Hooks";
import { SectionList, useSectionManagement } from "Pages/Spaces/components/SectionManagement";

import {
  Input,
  Modal,
  Button,
  NavButton,
  PageTitle,
  AddButton,
  InnerPageWrapper,
  LottieWithHeader,
} from "UIKit";

import { useSearchMainSectionFiles } from "Hooks";
import { useEolasNavigation } from "Components/Navigation/hooks";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import useSpacesContext from "modules/spaces/context/useSpacesContext";

export const Guidelines: React.FC<RouteComponentProps> = observer(
  ({ history: { push }, match: { url }, location: { pathname } }) => {
    const { t } = useTranslation();
    const { onSelectFile: selectFile } = useSelectFile();
    const { activeTab } = useEolasNavigation();

    const { isAdmin } = useSpacesContext();

    const isOrganisationTab = activeTab === "organisation";

    const mainSectionID = isOrganisationTab
      ? OrganisationLevelSection.hospitalGuidelines
      : AppLevelSection.guidelines;

    const { spaceSection } = useSpaceSection(mainSectionID);

    useMainSectionVisit({ mainSectionId: mainSectionID });

    const { filteredFiles: guidelines, onSearchChange, searchValue } = useSearchMainSectionFiles(
      mainSectionID,
    );

    const {
      modal,
      onCloseModal,
      onAddSection,
      onEditSection,
      onDeleteSection,
    } = useSectionManagement(spaceSection.id);

    const onSelectFile = useCallback(
      (file: EolasFile) => {
        selectFile(file);
      },
      [selectFile],
    );

    const noGuidelinesFound = searchValue && guidelines.length === 0;

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection.name ?? t(mainSectionID)} />
          <NavButton to={url.replace(`/${mainSectionID}`, "")} />

          {!isAdmin && (
            <Input
              size="lg"
              value={searchValue}
              onChange={onSearchChange}
              iconLeft={<SearchIcon />}
              data-testid="contact-search-all-contacts"
              placeholder={t("guidelines_search_all_placeholder")}
              className="bg-white border-grey-200"
            />
          )}

          {isAdmin && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <AddButton onClick={onAddSection} data-testid="add-new-subSection">
                {t("addNewSection_button")}
              </AddButton>

              <Button
                size="lg"
                color="grey"
                weight="bold"
                variant="outline"
                onClick={() => push("guidelines-expired")}
              >
                {t("guidelines_manage_expired_guidelines")}
              </Button>
            </div>
          )}

          {noGuidelinesFound ? (
            <LottieWithHeader
              animation={noFilesFound}
              lottieOptions={{ loop: true }}
              text={t("contacts_no_contacts")}
            />
          ) : guidelines.length > 0 ? (
            <div className="flex flex-col items-stretch ">
              {guidelines.map((guideline) => (
                <GuidelineFileTile
                  isAdmin={false}
                  key={guideline.id}
                  eolasFile={guideline}
                  onSelectFile={onSelectFile}
                  organisationImageURL={generateS3PublicImageURL(guideline.moreInfo!)}
                />
              ))}
            </div>
          ) : (
            <SectionList
              isAdmin={isAdmin}
              sectionID={spaceSection.id}
              onEdit={onEditSection}
              onAddItem={onAddSection}
              onDelete={onDeleteSection}
            />
          )}
        </InnerPageWrapper>

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </>
    );
  },
);
