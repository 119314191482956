import { useFileOperations } from "Hooks";
import { EolasFile } from "Utilities/types";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { DeleteModal, DeleteModalProps } from "UIKit";
import { fetchAllData } from "@eolas-medical/core";

export interface DeleteFileModalProps extends Partial<DeleteModalProps> {
  file: EolasFile;
  onCloseModal(): void;
}

export const DeleteFileModal: React.FC<DeleteFileModalProps> = ({
  file,
  onCloseModal,
  ...props
}) => {
  const { isLoading, isSuccessful, error, setRequestStatus } = useRequestStatus();
  const { removeFile } = useFileOperations();

  const onDelete = () => {
    setRequestStatus({ status: "pending", error: "" });

    removeFile({
      variables: {
        id: file.id,
        parentID: file.parentID,
      },
    })
      .then(async () => {
        await fetchAllData();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          retryCallback: removeFile,
          retryParameters: {
            variables: {
              id: file.id,
              parentID: file.parentID,
            },
          },
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  };

  return (
    <DeleteModal
      error={error}
      onDelete={onDelete}
      isLoading={isLoading}
      isSuccessful={isSuccessful}
      onCloseModal={onCloseModal}
      title={file.name as string}
      {...props}
    />
  );
};
