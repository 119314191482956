import { Button, SuccessModal, Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import { MainSection } from "modules/mainSections/types";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";
import useToggleMainSection from "modules/mainSections/data/useToggleMainSection";

interface DeactivatedSectionProps {
  onCloseModal(): void;
  section: MainSection;
}

const DeactivatedSection: React.FC<DeactivatedSectionProps> = ({ section, onCloseModal }) => {
  const { t } = useTranslation();
  const { getIconComponent } = useIconComponent();

  const {
    toggleMainSectionError,
    togglingMainSection,
    toggleMainSection,
    toggleMainSectionSuccess,
  } = useToggleMainSection();

  if (toggleMainSectionSuccess) {
    return (
      <SuccessModal onComplete={onCloseModal} text="contentManagement_editFeature_activated" />
    );
  }

  return (
    <div
      key={section.id}
      className="flex flex-col border-b border-grey px-4 lg:px-12 py-6 lg:py-10"
    >
      <div className="grid grid-cols-5 gap-4">
        <div className="flex items-center justify-center">
          {section.iconUrl && getIconComponent(section.iconUrl)}
        </div>

        <div className="flex flex-col ml-4 col-span-4">
          <Title level={8}>{section.name}</Title>
          <Text level={1} className="font-normal">
            {section.description}
          </Text>
        </div>
      </div>
      <Button
        variant="outline"
        disabled={togglingMainSection}
        isLoading={togglingMainSection}
        onClick={() => toggleMainSection({ id: section.id, disabled: false })}
        className="self-center mt-6 sm:mt-8 w-full sm:w-1/2"
      >
        {t("contentManagement_activateSection_button")}
      </Button>

      {toggleMainSectionError && (
        <span className="text-red text-center mt-4">{toggleMainSectionError}</span>
      )}
    </div>
  );
};

export default DeactivatedSection;
