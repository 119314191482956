export class DocumentCache {
  timeoutCache: Record<string, NodeJS.Timeout>;
  cache: Record<string, string>;
  // clear the cache after 5 minutes
  CACHE_TIME = 1000 * 60 * 5;

  constructor() {
    this.cache = {};
    this.timeoutCache = {};
  }

  getItem(key: string) {
    return this.cache[key];
  }

  deleteEntry(key: string) {
    delete this.cache[key];
  }

  setItem(key: string, value: string) {
    this.cache[key] = value;

    const previousTimeout = this.timeoutCache[key];

    if (previousTimeout) {
      clearTimeout(previousTimeout);
    }

    const timeout = setTimeout(() => {
      this.deleteEntry(key);
    }, this.CACHE_TIME);

    this.timeoutCache[key] = timeout;
  }
}

export const documentCache = new DocumentCache();
