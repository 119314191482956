import { EolasRegions, fetchMeAndCommunityData } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";

import { LDFlagNames } from "Utilities/types";
import { useSupportedRegion } from "./useSupportedRegion/useSupportedRegion";

export const useGetRelevantMeAndCommunityData = () => {
  const { supportedEolasRegion } = useSupportedRegion();
  const { flags } = useLaunchDarkly();

  const region = flags?.[LDFlagNames.REGION_SPECIFIC_TARGETING]
    ? supportedEolasRegion
    : EolasRegions.Uk;

  const fetchRegionalMeAndCommunityData = async () => {
    await fetchMeAndCommunityData({ region });
  };
  return { fetchRegionalMeAndCommunityData };
};
