import { Space, spacesClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";

interface UseGetSpace {
  space?: Space;
  isLoadingSpace: boolean;
}
const useSpace = (spaceId: string): UseGetSpace => {
  const { data, isLoading } = useQuery(
    spacesKeys.detail(spaceId),
    () => spacesClient.getSpace(spaceId),
    {
      enabled: !!spaceId,
    },
  );

  return {
    space: data,
    isLoadingSpace: isLoading,
  };
};

export default useSpace;
