import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useDownloadPDF } from "Hooks";
import { EolasFile } from "Utilities/types";
import { formatDate } from "Utilities/general";
import { Text, Title, Input, Button, FormError, ModalBody, InnerModalWrapper } from "UIKit";
import { SkipIcon, DownloadIcon, AttentionIcon, CheckSlimIcon, MajorIncidentIcon } from "Assets";

import { CompletedChecklist, CompletedChecklistItem, CompletedChecklistSection } from "../../types";

export interface SubmittedChecklistModalProps {
  checklist: EolasFile;
  completedChecklist: CompletedChecklist;
}

type SectionStatus = "skipped" | "complete" | "incomplete";

const getSectionStatus = (section: CompletedChecklistSection): SectionStatus => {
  if (section.skipped) return "skipped";

  const isComplete = section.sectionItems.every((item) => {
    if (item.itemType === "checkbox") return item.checked;
    return !!item.inputInfo;
  });

  return isComplete ? "complete" : "incomplete";
};

const SectionStatusPill: React.FC<{ status: SectionStatus }> = ({ status }) => {
  const { t } = useTranslation();

  const { Icon, label, pillClassname } = useMemo(() => {
    switch (status) {
      case "skipped":
        return {
          Icon: SkipIcon,
          label: "checklistSubmission_skipped_label",
          pillClassname: "bg-grey-100 text-grey-700",
        };
      case "incomplete":
        return {
          Icon: AttentionIcon,
          label: "checklistSubmission_incomplete_label",
          pillClassname: "bg-yellow-100 text-yellow-600",
        };
      default:
        return {
          Icon: CheckSlimIcon,
          label: "checklistSubmission_complete_label",
          pillClassname: "bg-green-100 text-green-300",
        };
    }
  }, [status]);

  return (
    <div
      className={`
        flex items-center px-2 h-8 rounded-full space-x-1
        ${pillClassname}
      `}
    >
      <Icon className="h-4 w-4 hidden sm:inline" />
      <Text level={3}>{t(label)}</Text>
    </div>
  );
};

export const SubmittedChecklistModal: React.FC<SubmittedChecklistModalProps> = ({
  checklist,
  completedChecklist,
}) => {
  const hasIssue = completedChecklist.checklistIssues;
  const { t } = useTranslation();
  const { isLoading, downloadPDF, error } = useDownloadPDF();

  const hasAdditionalInfo = !!completedChecklist.additionalInfo;
  const hasSecondTeamMember = !!completedChecklist.secondTeamMember;

  return (
    <InnerModalWrapper>
      <ModalBody className="p-4 sm:px-6">
        <div className="flex items-center justify-between sm:pl-10 pr-8 sm:pr-0">
          <Title level={5} className="text-center">
            {checklist.info}
          </Title>
          <Button
            variant="ghost"
            isLoading={isLoading}
            childrenContainerClassname="space-x-2"
            onClick={() => downloadPDF(checklist.id)}
          >
            <DownloadIcon className="h-5 w-5" />
            <Text level={1} className="hidden sm:inline font-bold">
              {t("checklist_download_button")}
            </Text>
          </Button>
        </div>

        <Text level={1} className="font-normal sm:pl-10">
          {formatDate(new Date(checklist.createdAt!))}
        </Text>

        <div
          className={`
            flex flex-col mt-4 space-y-4 overflow-y-auto max-h-70vh
            -mr-4 sm:-mr-6 pr-4 sm:pr-6
          `}
        >
          <div className="grid grid-cols-3 gap-4 sm:pl-10">
            {hasIssue && (
              <div className="flex items-center space-x-2 col-span-3">
                <MajorIncidentIcon className="w-6 h-6" />
                <Text level={2}>Issues were flagged</Text>
              </div>
            )}

            {hasAdditionalInfo && (
              <div className={`flex flex-col ${hasSecondTeamMember ? "col-span-2" : "col-span-3"}`}>
                <Text level={1} className="font-bold">
                  Additional info
                </Text>
                <Text level={2} className="text-grey-600 font-semibold">
                  {completedChecklist.additionalInfo}
                </Text>
              </div>
            )}

            {hasSecondTeamMember && (
              <div className={`flex flex-col ${hasAdditionalInfo ? "col-span-1" : "col-span-3"}`}>
                <Text level={1} className="font-bold">
                  Second team member
                </Text>
                <Text level={2} className="text-grey-600 font-semibold">
                  {completedChecklist.secondTeamMember}
                </Text>
              </div>
            )}
          </div>

          {completedChecklist.checklistSections?.map((section, sectionIndex) => {
            if (!section) return null;

            const status = getSectionStatus(section);
            const key = `${section.checklistSectionName}-${sectionIndex}`;

            return (
              <div
                key={key}
                className={`
                  flex flex-col p-4 rounded-md border-2 shadow-sm
                  ${status === "skipped" && "filter grayscale bg-grey-100 border-grey-300"}
                  ${status === "complete" && "border-green-100"}
                  ${status === "incomplete" && "border-yellow-100"}
                `}
              >
                <div className="flex items-center justify-between">
                  <Title level={8} className="font-bold sm:pl-6 line-clamp-2">
                    {section.checklistSectionName}
                  </Title>
                  <SectionStatusPill status={status} />
                </div>

                <div className="flex flex-col divide-y divide-grey-300 space-y-4">
                  {section.sectionItems.map((sectionItem, index) => {
                    const key = `${sectionItem.itemTitle}-${index}`;

                    return <SectionItem key={key} sectionItem={sectionItem} index={index} />;
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <FormError error={error} />
      </ModalBody>
    </InnerModalWrapper>
  );
};

const SectionItem: React.FC<{ sectionItem: CompletedChecklistItem; index: number }> = ({
  index,
  sectionItem,
}) => {
  return (
    <div className={`flex items-baseline space-x-2 sm:space-x-4 pt-4`}>
      <Text level={1} className="text-grey-600">
        {index + 1}
      </Text>

      <div className="flex flex-col flex-1">
        <div className="flex justify-between items-center">
          <div className="flex flex-col flex-1 mr-8">
            <Text level={1} className="font-bold">
              {sectionItem.itemTitle}
            </Text>
            <Text level={2} className="font-semibold">
              {sectionItem.itemInfo}
            </Text>
          </div>

          {sectionItem.checked || !!sectionItem.inputInfo ? (
            <div className="bg-green-100 text-green-300 h-6 w-6 sm:h-8 sm:w-8 rounded-full flex items-center justify-center">
              <CheckSlimIcon className="h-4 w-4 sm:w-5 sm:h-5" />
            </div>
          ) : (
            <div className="bg-yellow-100 text-yellow-600 h-6 w-6 sm:h-8 sm:w-8 rounded-full flex items-center justify-center">
              <AttentionIcon className="h-4 w-4 sm:w-5 sm:h-5 " />
            </div>
          )}
        </div>

        {sectionItem.inputInfo && (
          <Input
            disabled
            value={sectionItem.inputInfo}
            className="mt-4 border-2 border-green-100"
            onChange={() => {}}
          />
        )}
      </div>
    </div>
  );
};
