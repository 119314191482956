import { EolasFilePicker } from "UIKit/FilePicker";
import { EolasFileType } from "Utilities/types";
import TextWithLottie from "./components/TextWithLottie";
import { LinkForm } from "./components/LinkForm";
import { Control, FieldErrors } from "react-hook-form";
import { AllowedChooseFileTypes, ChooseFileTypeValues } from "./types";
import { InputTypeButton } from "UIKit/InputTypeButton";
import { Text } from "UIKit/Typography";

interface ChooseFileTypeStepProps {
  control: Control<ChooseFileTypeValues>;
  selectedType: EolasFileType;
  allowedTypes: AllowedChooseFileTypes[];
  fileFormLabel: string;
  fileUploadLabel: string;
  fileChangeLabel: string;
  fileDescriptionText: string;
  linkNameLabel: string;
  linkNamePlaceholder: string;
  linkUrlLabel: string;
  linkUrlPlaceholder: string;
  flashcardTitle: string;
  flashcardSubtitle: string;
  flashcardDescription: string;
  blobName?: string;
  errors?: FieldErrors<ChooseFileTypeValues>;
  onSelect: (type: EolasFileType) => void;
  onAddBlob: (blob: File) => void;
}

export const ChooseFileTypeStep = ({
  control,
  allowedTypes,
  selectedType,
  fileFormLabel,
  fileUploadLabel,
  fileChangeLabel,
  fileDescriptionText,
  linkNameLabel,
  linkNamePlaceholder,
  linkUrlLabel,
  linkUrlPlaceholder,
  flashcardTitle,
  flashcardSubtitle,
  flashcardDescription,
  blobName,
  errors,
  onSelect,
  onAddBlob,
}: ChooseFileTypeStepProps) => {
  const handleBlobChange = (file: File) => {
    onAddBlob(file);
  };

  return (
    <div data-testid="upload-file-step">
      <div className="flex flex-col items-center justify-center mb-4 gap-2 md:flex-row">
        {allowedTypes.map(
          ({ type, disabled, label, icon: Icon }) =>
            !disabled && (
              <InputTypeButton
                key={type}
                isSelected={selectedType === type}
                icon={<Icon width={32} height={32} />}
                onClick={() => onSelect(type)}
                className={`w-full ${selectedType === type ? "shadow-md" : ""}`}
              >
                <Text level={1}>{label}</Text>
              </InputTypeButton>
            ),
        )}
      </div>

      {selectedType === "blob" && (
        <EolasFilePicker
          error={errors?.blob?.message}
          onChange={handleBlobChange}
          value={blobName}
          fileType={selectedType}
          formLabel={fileFormLabel}
          uploadLabel={fileUploadLabel}
          changeLabel={fileChangeLabel}
          descriptionText={fileDescriptionText}
        />
      )}

      {selectedType === "link" && (
        <LinkForm
          control={control}
          errors={errors}
          nameLabel={linkNameLabel}
          namePlaceholder={linkNamePlaceholder}
          urlLabel={linkUrlLabel}
          urlPlaceholder={linkUrlPlaceholder}
          onEnter={() => {}}
        />
      )}

      {selectedType === "flashcard" && (
        <TextWithLottie
          title={flashcardTitle}
          subtitle={flashcardSubtitle}
          descriptionText={flashcardDescription}
        />
      )}
    </div>
  );
};
