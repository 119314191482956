import { useQueryClient, useMutation } from "@tanstack/react-query";
import { myFilesService } from "../client/myFiles.client";
import { DEFAULT_SECTION_ID } from "../constants";
import { FileSectionPatchDto } from "../types";
import myFilesKeys from "./myFiles.queryKeys";

const useDragSection = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<{}, Error, FileSectionPatchDto>(
    async ({ sectionId, prevSectionId }: FileSectionPatchDto) => {
      const newPrevSectionId = prevSectionId === DEFAULT_SECTION_ID ? "null" : prevSectionId; // "null" is needed by the BE
      return myFilesService.patchSection({ sectionId, prevSectionId: newPrevSectionId });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: myFilesKeys.sectionLists(),
        });
      },
    },
  );

  return {
    dragSection: mutate,
    draggingSection: isLoading,
  };
};

export default useDragSection;
