import React from "react";

export type MediaQueryBreakpoints = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

export const useMediaQuery = () => {
  const [mediaBreakpoint, setMediaBreakpoint] = React.useState<MediaQueryBreakpoints>("xs");

  const smHandler = React.useCallback(({ matches }: MediaQueryListEvent) => {
    if (matches) {
      setMediaBreakpoint("sm");
    } else {
      setMediaBreakpoint("xs");
    }
  }, []);

  const mdHandler = React.useCallback(({ matches }: MediaQueryListEvent) => {
    if (matches) {
      setMediaBreakpoint("md");
    } else {
      setMediaBreakpoint("sm");
    }
  }, []);

  const lgHandler = React.useCallback(({ matches }: MediaQueryListEvent) => {
    if (matches) {
      setMediaBreakpoint("lg");
    } else {
      setMediaBreakpoint("md");
    }
  }, []);

  const xlHandler = React.useCallback(({ matches }: MediaQueryListEvent) => {
    if (matches) {
      setMediaBreakpoint("xl");
    } else {
      setMediaBreakpoint("lg");
    }
  }, []);

  const xxlHandler = React.useCallback(({ matches }: MediaQueryListEvent) => {
    if (matches) {
      setMediaBreakpoint("2xl");
    } else {
      setMediaBreakpoint("xl");
    }
  }, []);

  React.useLayoutEffect(() => {
    const smMedia = window.matchMedia("(min-width: 640px)");
    if (smMedia.matches) {
      setMediaBreakpoint("sm");
    }

    const mdMedia = window.matchMedia("(min-width: 768px)");
    if (mdMedia.matches) {
      setMediaBreakpoint("md");
    }

    const lgMedia = window.matchMedia("(min-width: 1024px)");
    if (lgMedia.matches) {
      setMediaBreakpoint("lg");
    }
    const xlMedia = window.matchMedia("(min-width: 1280px)");
    if (xlMedia.matches) {
      setMediaBreakpoint("xl");
    }

    const xxlMedia = window.matchMedia("(min-width: 1536px)");
    if (xxlMedia.matches) {
      setMediaBreakpoint("2xl");
    }

    smMedia.addEventListener("change", smHandler);
    mdMedia.addEventListener("change", mdHandler);
    lgMedia.addEventListener("change", lgHandler);
    xlMedia.addEventListener("change", xlHandler);
    xxlMedia.addEventListener("change", xxlHandler);

    return () => {
      smMedia.removeEventListener("change", smHandler);
      mdMedia.removeEventListener("change", mdHandler);
      lgMedia.removeEventListener("change", lgHandler);
      xlMedia.removeEventListener("change", xlHandler);
      xxlMedia.removeEventListener("change", xxlHandler);
    };
  }, [smHandler, mdHandler, lgHandler, xlHandler, xxlHandler]);

  return mediaBreakpoint;
};
