import { Control, Controller, useWatch, useFormState } from "react-hook-form";
import { Toggle } from "UIKit";
import { ActionButton, ActionButtonType, PortfolioFieldNames } from "modules/portfolio/types";
import PortfolioActionButton from "Pages/MeTab/pages/Portfolio/components/PortfolioActionButton/PortfolioActionButton";
import useEventFormButtons from "../../hooks/useEventFormButtons";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface EventFormButtonsProps {
  formControl: Control<any>;
  actionButtons: ActionButton[];
  isNew: boolean;
  isDirty: boolean;
  exportingPdfEvent: boolean;
  onSave: () => void;
  onSubmit: () => void;
  onComplete: () => void;
  onDelete: () => void;
  onContinue: () => void;
  onAssignAssessor: () => void;
  onViewQRCode: () => void;
  onViewFeedback: () => void;
  onExportPdfEvent: () => void;
}
const EventFormButtons = ({
  formControl,
  actionButtons,
  isNew,
  isDirty,
  exportingPdfEvent,
  onSave,
  onSubmit,
  onComplete,
  onDelete,
  onContinue,
  onAssignAssessor,
  onViewQRCode,
  onViewFeedback,
  onExportPdfEvent,
}: EventFormButtonsProps) => {
  const { t } = useTranslation();

  const attachedValues = useWatch({
    control: formControl,
    name: [PortfolioFieldNames.attachEvidence],
  });

  const formHasAttachments = useMemo(() => attachedValues.some((av) => av.length > 0), [
    attachedValues,
  ]);

  const formButtons = useEventFormButtons({
    actionButtons,
    isNew,
    isDirty,
    formHasAttachments,
    exportingPdfEvent,
    onSave,
    onSubmit,
    onComplete,
    onDelete,
    onContinue,
    onAssignAssessor,
    onViewFeedback,
    onViewQRCode,
    onExportPdfEvent,
  });

  const { errors } = useFormState({ control: formControl });

  const ShareAttachmentsToggle = ({ button }: { button: ActionButton }) => (
    <Controller
      control={formControl}
      name={PortfolioFieldNames.shareAttachments}
      render={({ field: { value, onChange } }) => {
        return <Toggle label={button.text} checked={value} onChange={onChange} />;
      }}
    />
  );

  return (
    <div className="mt-4" data-testid="event-form-buttons">
      {formButtons.map((b) => {
        if (formHasAttachments && b.type === ActionButtonType.SHARE_ATTACHMENTS_TOGGLE) {
          return (
            <div key={b.type} className="flex justify-center mb-8">
              <ShareAttachmentsToggle button={b} />
            </div>
          );
        }

        return (
          <div key={b.type}>
            <PortfolioActionButton
              isLoading={b.isLoading || false}
              type={b.type}
              onClick={b.onClick}
              errorMessage={
                Object.keys(errors).length !== 0 ? t("portfolio_mandatory_fields_error") : undefined
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default EventFormButtons;
