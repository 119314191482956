import { useRouteMatch } from "react-router-dom";
import { InnerPageWrapper, PageTitle, Loader } from "UIKit";

import { eventTypeLabels } from "modules/portfolio/types";
import EventForm from "../../components/EventForm/EventForm";
import StatusBadge from "Pages/MeTab/pages/Portfolio/components/StatusBadge/StatusBadge";

import usePortfolioParams from "Pages/MeTab/pages/Portfolio/hooks/usePortfolioParams";
import useEventForm from "modules/portfolio/data/useEventForm";

export const EventDetail = () => {
  const { url } = useRouteMatch();
  const { eventId, eventType, superviseeId } = usePortfolioParams();
  const { eventForm, eventFormLoading } = useEventForm(eventType, eventId, superviseeId);

  const eventTitle = eventTypeLabels[eventType] || "";

  if (eventFormLoading) {
    return (
      <InnerPageWrapper>
        <div className="m-auto px-2 flex flex-col items-center">
          <Loader className="bg-transparent" />
        </div>
      </InnerPageWrapper>
    );
  }

  if (!eventForm) {
    return null;
  }

  const toRemovePath = eventId
    ? `/${eventType.toLowerCase()}/${eventId}`
    : `/${eventType.toLowerCase()}`;
  const backUrl = `${url.replace(toRemovePath, "")}`;

  return (
    <>
      {!eventFormLoading && (
        <div className="mx-auto mt-12">
          <StatusBadge status={eventForm?.status} hasBorder />
        </div>
      )}
      <PageTitle className="my-4" title={eventTitle} />
      <InnerPageWrapper>
        <EventForm eventId={eventId} backUrl={backUrl} eventForm={eventForm} />
      </InnerPageWrapper>
    </>
  );
};
