import { useTranslation } from "react-i18next";

import { Title, Text, IconButton } from "UIKit";
import { AdminIcon, ArrowRightIcon, LoaderIcon, SpacesIcon } from "Assets";
import { SearchSpaceResult } from "../../types";
export interface SpaceItemProps {
  testID?: string;
  isAdmin?: boolean;
  className?: string;
  loadingAppID: string | null;
  app: SearchSpaceResult;
  onClick(): void;
}

export const SpaceItem: React.FC<SpaceItemProps> = ({
  app,
  testID,
  isAdmin,
  loadingAppID,
  className = "",
  onClick,
}) => {
  const { t } = useTranslation();
  const isLoading = loadingAppID === app.appID;

  return (
    <div
      onClick={loadingAppID ? undefined : onClick}
      className={`
        flex items-center space-x-4 px-4 lg:px-8 py-4 lg:py-6 group relative
        ${loadingAppID ? "cursor-not-allowed" : "cursor-pointer"}
        ${isLoading ? "bg-grey-50 hover:bg-grey-50" : "hover:bg-grey-200"}
        ${className}
      `}
    >
      {isLoading ? (
        <LoaderIcon className="w-6 h-6 lg:w-8 md:h-8 text-grey-600 animate-spin-slow" />
      ) : (
        <SpacesIcon className="w-6 h-6 lg:w-8 md:h-8 text-grey-600" />
      )}

      <Title data-testid={testID} level={7} className="text-black flex-1">
        {app.name}
      </Title>

      {isAdmin && (
        <div
          className={`
            transition-all duration-200
            opacity-100 group-hover:opacity-0 flex items-center space-x-1
          `}
        >
          <AdminIcon className="w-6 h-6" />
          <Text level={2} className="text-grey-700 font-semibold">
            {t("selectSpace_is_admin")}
          </Text>
        </div>
      )}

      {!loadingAppID && (
        <IconButton
          size="sm"
          variant="rounded"
          icon={<ArrowRightIcon />}
          className={`
            absolute right-14 group-hover:right-10
            transition-all duration-200
            opacity-0 group-hover:opacity-100
            flex bg-darkblue-500 hover:bg-darkblue-400 text-white
          `}
        />
      )}
    </div>
  );
};
