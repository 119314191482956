import { useRef } from "react";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";

const useExportToImage = () => {
  const elementRef = useRef<HTMLDivElement>(null);

  const exportToImage = async (imageName: string): Promise<void> => {
    if (elementRef.current) {
      const dataUrl = await htmlToImage.toPng(elementRef.current);
      download(dataUrl, imageName);
    }
  };

  return { parentRef: elementRef, exportToImage };
};

export default useExportToImage;
