import { ButtonColorScheme } from "UIKit/Button/types";
import { Control, FieldErrors, UseFormTrigger } from "react-hook-form";
import { MyFile } from "modules/myFiles/types";

// Event
export enum EventType {
  AUDIT_QI_PROJECT = "AUDIT_QI_PROJECT",
  CASE_DISCUSSION = "CASE_DISCUSSION",
  CLINICAL_EVENT = "CLINICAL_EVENT",
  MEETING = "MEETING",
  MULTI_SOURCE_FEEDBACK = "MULTI_SOURCE_FEEDBACK",
  PDP = "PDP",
  PROCEDURAL_SKILL = "PROCEDURAL_SKILL",
  REFLECTION = "REFLECTION",
  TEACHING_DELIVERY = "TEACHING_DELIVERY",
  ULTRASOUND_LOG = "ULTRASOUND_LOG",
  ULTRASOUND_VASCULAR_ACCESS = "ULTRASOUND_VASCULAR_ACCESS",
  ULTRASOUND_CARDIAC = "ULTRASOUND_CARDIAC",
  ULTRASOUND_DVT = "ULTRASOUND_DVT",
  ULTRASOUND_THORACIC = "ULTRASOUND_THORACIC",
  ULTRASOUND_ABDOMINAL_RENAL = "ULTRASOUND_ABDOMINAL_RENAL",
  ACUTE_CARE_ASSESSMENT_TOOL = "ACUTE_CARE_ASSESSMENT_TOOL",
  DIRECT_OBSERVED_PROCEDURAL_SKILL = "DIRECT_OBSERVED_PROCEDURAL_SKILL",
  ADVANCED_PRACTICE_ANNUAL_APPRAISAL = "ADVANCED_PRACTICE_ANNUAL_APPRAISAL",
  EXTENDED_SUPERVISED_LEARNING_EVENT = "EXTENDED_SUPERVISED_LEARNING_EVENT",
}

export enum EventStatus {
  DECLINED = "DECLINED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING_REVIEW = "PENDING_REVIEW",
  COMPLETE = "COMPLETE",
}

export enum FeedbackStatus {
  REVIEW_IN_PROGRESS = "REVIEW_IN_PROGRESS",
  COMPLETE = "COMPLETE",
  DECLINED = "DECLINED",
}

export enum SupervisionStatus {
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
  PENDING = "PENDING",
}

export enum SupervisorRequestCreatedBy {
  SUPERVISEE = "SUPERVISEE",
  SUPERVISOR = "SUPERVISOR",
}
export interface Event {
  id: string;
  status: EventStatus;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  eventValues: EventValue[];
  areAttachmentsShared: boolean;
  assignAssessorsType: AssignAssessorsType | null;
}

export const eventTypeLabels: { [key in EventType]: string } = {
  [EventType.AUDIT_QI_PROJECT]: "Audit/QI Project",
  [EventType.CASE_DISCUSSION]: "Case Discussion",
  [EventType.CLINICAL_EVENT]: "Clinical Event",
  [EventType.MEETING]: "Meeting",
  [EventType.MULTI_SOURCE_FEEDBACK]: "Multi-Source Feedback",
  [EventType.REFLECTION]: "Reflection",
  [EventType.PDP]: "Personal Development Plan",
  [EventType.PROCEDURAL_SKILL]: "Procedural Skill",
  [EventType.TEACHING_DELIVERY]: "Teaching Delivery",
  [EventType.ULTRASOUND_LOG]: "Ultrasound Log",
  [EventType.ULTRASOUND_VASCULAR_ACCESS]: "Ultrasound - Vascular Access",
  [EventType.ULTRASOUND_CARDIAC]: "Ultrasound - Cardiac",
  [EventType.ULTRASOUND_DVT]: "Ultrasound - DVT",
  [EventType.ULTRASOUND_THORACIC]: "Ultrasound - Thoracic",
  [EventType.ULTRASOUND_ABDOMINAL_RENAL]: "Ultrasound - Abdominal/Renal",
  [EventType.ACUTE_CARE_ASSESSMENT_TOOL]: "Acute Care Assessment Tool",
  [EventType.DIRECT_OBSERVED_PROCEDURAL_SKILL]: "Directly observed procedural Skills (DoPs)",
  [EventType.ADVANCED_PRACTICE_ANNUAL_APPRAISAL]: "Advanced Practice Annual Appraisal",
  [EventType.EXTENDED_SUPERVISED_LEARNING_EVENT]: "Extended Supervised Learning Events (ESLE)",
};

export const eventStatusLabels: {
  [key in EventStatus]: string;
} = {
  [EventStatus.IN_PROGRESS]: "In Progress",
  [EventStatus.PENDING_REVIEW]: "Pending Review",
  [EventStatus.COMPLETE]: "Complete",
  [EventStatus.DECLINED]: "Declined",
};

export interface EventSummary {
  id: string;
  type: EventType;
  title: string;
  status: EventStatus;
  date: string;
}

export interface FeedbackSummary {
  id: string;
  type: EventType;
  title: string;
  date: string;
  status: FeedbackStatus;
  eventId: string;
}

export interface SupervisionSummary {
  id: string;
  status: SupervisionStatus;
  email: string;
  canRemind: boolean;
  name?: string;
  userId?: string;
}

export interface AttachmentItem extends Omit<MyFile, "required" | "sectionId" | "isFavourite"> {}

// Event Form
export enum PortfolioNativeComponent {
  DATE_PICKER = "DATE_PICKER",
  TEXT_INPUT = "TEXT_INPUT",
  RADIO_BUTTON = "RADIO_BUTTON",
  NUMBER_FEEDBACK = "NUMBER_FEEDBACK",
  BINARY_SELECTOR = "BINARY_SELECTOR",
  BEST_ANSWER_SELECTOR = "BEST_ANSWER_SELECTOR",
}

export enum PortfolioComplexComponent {
  ATTACH_BUTTON = "ATTACH_BUTTON",
}
export type PortfolioComponentType = PortfolioNativeComponent | PortfolioComplexComponent;

export enum PortfolioFieldNames {
  date = "date",
  title = "title",
  description = "description",
  procedure = "procedure",
  traineeComments = "traineeComments",
  assignAssessor = "assignAssessor",
  assessorRole = "assessorRole",
  assessorComments = "assessorComments",
  suggestedAreasForDevelopement = "suggestedAreasForDevelopment",
  actionPlan = "actionPlan",
  attachEvidence = "attachEvidence",
  projectDescription = "projectDescription",
  furtherActions = "furtherActions",
  assessmentOfEngagement = "assessmentOfEngagement",
  supervisorComments = "supervisorComments",
  perceptionsAndFeelings = "perceptions",
  analysis = "analysis",
  conclusion = "conclusion",
  timescale = "timescale",
  identifyLearningNeeds = "identifyLearningNeeds",
  addressObjectives = "addressObjectives",
  achievementStatus = "achievementStatus",
  learningAudience = "learningAudience",
  teachingComments = "teachingComments",
  reflection = "reflection",
  facilitiesAndSetting = "facilitiesAndSetting",
  objectiveFulfillment = "objectiveFulfillment",
  delivery = "delivery",
  overall = "overall",
  approachability = "approachability",
  clinicalKnowledge = "clinicalKnowledge",
  proceduralSkills = "proceduralSkills",
  respectsPatients = "respectsPatients",
  teamworking = "teamworking",
  additionalComments = "additionalComments",
  assignReviewers = "assignReviewers",
  declineReview = "declineReview",
  shareAttachments = "shareAttachments",
}

export enum ActionButtonType {
  ADD_SECTION = "ADD_SECTION",
  SAVE_DRAFT = "SAVE_DRAFT",
  DELETE_FORM = "DELETE_FORM",
  DECLINE_FEEDBACK = "DECLINE_FEEDBACK",
  VIEW_QR_CODE = "VIEW_QR_CODE",
  COMPLETE_EVENT = "COMPLETE_EVENT",
  GENERATE_FEEDBACK = "GENERATE_FEEDBACK",
  VIEW_FEEDBACK = "VIEW_FEEDBACK",
  SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK",
  SHARE_ATTACHMENTS_TOGGLE = "SHARE_ATTACHMENTS_TOGGLE",
  OPTIONAL_ASSIGN_ASSESSOR_AND_SUBMIT = "OPTIONAL_ASSIGN_ASSESSOR_AND_SUBMIT",
  MANDATORY_ASSIGN_ASSESSOR_AND_SUBMIT = "MANDATORY_ASSIGN_ASSESSOR_AND_SUBMIT",
  COMPLETE_EVENT_WITHOUT_FEEDBACK = "COMPLETE_EVENT_WITHOUT_FEEDBACK",
  EXPORT_EVENT_PDF = "EXPORT_EVENT_PDF",
}

export type ComponentConfigOptionType = "positive" | "neutral" | "negative" | "default";

export interface ComponentConfigOption {
  value: string;
  type: ComponentConfigOptionType;
}

interface ComponentConfig {
  type: PortfolioComponentType;
  title: string;
  inputSize?: TextInputSizes;
  options?: ComponentConfigOption[];
  warningText?: string;
}

export enum FormElementType {
  STRING = "STRING",
  NUMERIC = "NUMERIC",
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
  EVENT_TITLE = "EVENT_TITLE",
  EVENT_DATE = "EVENT_DATE",
  ATTACHMENTS = "ATTACHMENTS",
}

export type EventValue = string | number | boolean | Date | AttachmentItem[] | null;
export interface FormElement {
  id: string;
  fieldName: PortfolioFieldNames;
  type: FormElementType;
  required: boolean;
  value?: EventValue;
  componentConfig: ComponentConfig;
}

export interface ActionButton {
  id: string;
  text: string;
  backgroundColor?: ButtonColorScheme;
  icon?: string;
  iconColor?: string;
  textColor?: string;
  type: ActionButtonType;
  isLoading?: boolean;
  onClick?: (args?: any) => void;
}

export interface EventFormTemplate {
  id: string;
  type: EventType;
  userTitle: string;
  templateTitle: string;
  formElements: FormElement[];
  actionButtons: ActionButton[];
  areAttachmentsShared?: boolean;
  needFeedback?: boolean;
  assignAssessorsType?: AssignAssessorsType | null;
  isWizardCompatible?: boolean | null;
  info: string | null;
}

export interface EventForm extends EventFormTemplate {
  status: EventStatus;
  createdBy?: string;
}

export interface EventFormTemplateSummary {
  id: string;
  eventType: EventType;
  description?: string;
  eventTypeLabel: string;
}

export interface EventDescription extends FormElement {}

export interface EventOwner {
  name: string;
  email?: string;
}

export enum AssignAssessorsType {
  OPTIONAL_SINGLE_ASSIGN = "OPTIONAL_SINGLE_ASSIGN",
  MANDATORY_SINGLE_ASSIGN = "MANDATORY_SINGLE_ASSIGN",
}

export interface Assessor {
  email: string;
  reviewStatus?: FeedbackStatus | null;
  canRemind?: boolean;
  givenName?: string;
  familyName?: string;
  reminderSentAt?: string;
}
export interface EventValueDto {
  componentId: string;
  stringValue?: string;
  dateValue?: string;
  intValue?: number;
  booleanValue?: boolean;
  attachmentsIds?: string[];
}
export interface EventDto {
  title: string;
  date: string;
  type: EventType;
  ownerId: string;
  eventValues: EventValueDto[];
  areAttachmentsShared?: boolean;
  eventRequest?: {
    appType: string;
    userEmail: string;
  };
}

export interface FeedbackForm {
  id: string;
  eventId: string;
  status: FeedbackStatus;
  type: EventType;
  formElements: FormElement[];
  actionButtons: ActionButton[];
  createdAt: string;
  eventDescription: EventDescription[];
  eventOwner: EventOwner;
  assignAssessorsType: AssignAssessorsType | null;
}

export interface FeedbackAggregatedValue {
  type: FormElementType;
  fieldName: PortfolioFieldNames;
  value: string[];
  componentConfig: ComponentConfig;
}

// Timeline & Filters
export enum FilterFieldNames {
  endDate = "endDate",
  startDate = "startDate",
  eventType = "eventType",
  eventStatus = "eventStatus",
}

export type TimelineData = FeedbackSummary | EventSummary;

export type TimelineEventStatus = Exclude<EventStatus | FeedbackStatus, "unfilled">;
export type FilterElementArray = EventType[] | TimelineEventStatus[];

export type FilterObjectType = {
  [FilterFieldNames.endDate]: string;
  [FilterFieldNames.eventStatus]: TimelineEventStatus[];
  [FilterFieldNames.eventType]: EventType[];
  [FilterFieldNames.startDate]: string;
};

export type DateFilterType = {
  className?: string;
  control: Control<any>;
  errors: FieldErrors;
  sectionTitle: string;
  trigger: UseFormTrigger<{
    startDate: string;
    endDate: string;
    eventType: string[];
    eventStatus: TimelineEventStatus[];
  }>;
};

// Components
export enum TextInputSizes {
  SINGLE = "single",
  MEDIUM = "medium",
  LARGE = "large",
}
export interface TextInputConfig {
  title: string;
  inputSize?: TextInputSizes;
  required?: boolean;
  titleIconName?: TitleIconName;
}

export type TitleIconName = "delete";

export type FormValues = {
  [key in PortfolioFieldNames]?: any;
};

export interface WizardFormElements {
  componentId: string;
  title: string;
}
