import React from "react";
import { useTranslation } from "react-i18next";

import { EolasFile } from "Utilities/types";

import { Title, ModalHeader, InnerModalWrapper, IncidentReportFileTile } from "UIKit";

export interface UnresolvedReportsModalProps {
  unresolvedIncidents: EolasFile[];
  onSelectReport(incidentReport: EolasFile): void;
}

export const UnresolvedReportsModal: React.FC<UnresolvedReportsModalProps> = ({
  onSelectReport,
  unresolvedIncidents,
}) => {
  const { t } = useTranslation();

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("incident_reporting_unresolved_reports_modal_title")}</Title>
      </ModalHeader>

      <div className="divide-y divide-grey-light space-y-4 p-4 h-full overflow-y-auto">
        {unresolvedIncidents.map((incidentReport) => {
          return (
            <IncidentReportFileTile
              hideIcon
              isAdmin
              key={incidentReport.id}
              eolasFile={incidentReport}
              onSelectFile={() => onSelectReport(incidentReport)}
            />
          );
        })}
      </div>
    </InnerModalWrapper>
  );
};
