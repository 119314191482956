import { ConfirmationModal } from "UIKit";
import { useTranslation } from "react-i18next";

export interface EditVerificationModalProps {
  onConfirm: () => void;
  onClose: () => void;
}

const EditVerificationModal = ({ onClose, onConfirm }: EditVerificationModalProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      onDecline={onClose}
      onAccept={onConfirm}
      iconType="ProfileVerificationIcon"
      title={t("profile_verification_title")}
      description={t("profile_verification_intro_text")}
      acceptLabel={t("profile_verification_edit_code")}
      declineLabel={t("profile_verification_change_professional_body")}
    />
  );
};

export default EditVerificationModal;
