import { useMemo } from "react";
import { sectionStore, userStore } from "@eolas-medical/core";
import { differenceInSeconds } from "date-fns";
import useWebNotifications from "modules/webNotifications/data/useWebNotifications";

const usePortfolioNotifications = () => {
  const { userID: userId = "" } = userStore;
  const { appID: departmentId, organisationID: organisationId } = sectionStore;

  const { webNotifications, refetchWebNotifications } = useWebNotifications({
    userId,
    departmentId,
    organisationId,
  });

  const lastNotification = useMemo(() => {
    const notification = webNotifications.find((notification) => {
      const currentTime = new Date();
      const notificationTime = new Date(notification.createdAt);

      const secondsDifference = differenceInSeconds(currentTime, notificationTime);
      return secondsDifference <= 15 && notification.notificationType === "myPortfolioWizard";
    });

    return notification;
  }, [webNotifications]);

  return {
    lastNotification,
    refetchWebNotifications,
  };
};

export default usePortfolioNotifications;
