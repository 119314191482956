import { FormElement, Textarea } from "UIKit";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";

interface SpaceDescriptionProps {
  control: Control<any>;
  onSubmit: () => void;
}
const SpaceDescription = ({ control, onSubmit }: SpaceDescriptionProps) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit}>
      <div className="col-span-2">
        <FormElement
          required
          id="description"
          control={control}
          label={t("space_create_description")}
        >
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Textarea
                {...rest}
                autoFocus
                placeholder={t("edit_space_details_description_placeholder")}
                value={value}
                className="resize-none"
                characterLimit={350}
                spellCheck
              />
            );
          }}
        </FormElement>
      </div>
    </form>
  );
};

export default SpaceDescription;
