import React from "react";
import { get } from "lodash";
import { sectionStore } from "@eolas-medical/core";

import { useUpdateApp } from "Hooks";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";

export const useTrustedEmail = (email: string) => {
  const { updateApp } = useUpdateApp();
  const { isLoading, setRequestStatus } = useRequestStatus();
  const [tooltipText, setTooltipText] = React.useState<string>("manageUsers_copy_email");

  const onCopyClicked = () => {
    navigator.clipboard.writeText(email);
    setTooltipText("manageUsers_email_copied");
  };

  const afterTooltipHide = () => setTooltipText("manageUsers_copy_email");

  const onDeleteEmail = React.useCallback(async () => {
    const trustedEmail = sectionStore.data.app?.trustedEmail ?? "";
    const mails = trustedEmail.split(";");
    const updatedMails = mails.filter((m) => m !== email).join(";");

    const newEmail = updatedMails.length === 0 ? "adminAuthentication" : updatedMails;

    setRequestStatus({ status: "pending", error: "" });
    try {
      const response = await updateApp({
        id: sectionStore.appID,
        trustedEmail: newEmail,
      });
      const updatedData = get(response, "data.updateApp", {});
      sectionStore.setData({ app: updatedData });
      setRequestStatus({ status: "success", error: "" });
    } catch (err: any) {
      errorStore.captureError({ error: err.message, source: "user" });
      setRequestStatus({ status: "error", error: err.message });
    }
  }, [email, setRequestStatus, updateApp]);

  return {
    isLoading,
    tooltipText,
    onDeleteEmail,
    onCopyClicked,
    afterTooltipHide,
  };
};
