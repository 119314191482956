import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DeactivateIcon } from "Assets";
import { Text, Button, InnerModalWrapper, Modal, ModalHeader, Title } from "UIKit";

export interface DisableMedusaModalProps {
  open: boolean;
  handleClose: () => void;
  handleDisable: () => Promise<void>;
}

export const DisableMedusaModal = ({
  open,
  handleClose,
  handleDisable,
}: DisableMedusaModalProps) => {
  const { t } = useTranslation();
  const [disabling, toggleDisabling] = useState(false);

  const onDisable = async () => {
    try {
      toggleDisabling(true);
      await handleDisable();
    } finally {
      toggleDisabling(false);
      handleClose();
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <InnerModalWrapper>
        <div className="px-16 pb-16">
          <ModalHeader>
            <DeactivateIcon className="h-20 w-20 mb-2" />

            <Title level={5} className="my-4">
              {t("contentManagement_medusa_remove_title")}
            </Title>

            <Text level={2} className="text-center text-sm font-bold text-grey-700">
              {t("contentManagement_medusa_remove_body")}
            </Text>
          </ModalHeader>

          <Button onClick={onDisable} color="blue" className="mt-12" isLoading={disabling}>
            {t("contentManagement_medusa_remove")}
          </Button>
          <Button onClick={handleClose} color="grey" className="mt-4">
            {t("general_cancel")}
          </Button>
        </div>
      </InnerModalWrapper>
    </Modal>
  );
};
