import { useTranslation } from "react-i18next";

import { Button } from "UIKit";
import { ArrowLeftIcon, ArrowRightIcon } from "Assets";

import { Text } from "../../Typography";

const arrowSize = { width: 18, height: 18 };

interface IPaginationProps {
  currentPage: number;
  hasMorePages: boolean;
  title: string;
  config?: {
    from: number;
    to: number;
    total: number;
  };
  testId?: string;
  changePage: (nextPage: number) => void;
}

export const Pagination = ({
  currentPage,
  hasMorePages,
  title,
  config,
  testId = "pagination",
  changePage,
}: IPaginationProps) => {
  const { t } = useTranslation();
  return (
    <div data-testid={testId} className="flex items-center justify-center space-x-4 my-8">
      <Button
        size="sm"
        data-testid="prev-page"
        disabled={currentPage === 0}
        onClick={() => changePage(currentPage - 1)}
      >
        <ArrowLeftIcon {...arrowSize} />
      </Button>

      <Text level={2}>{t(title, config)}</Text>

      <Button
        size="sm"
        data-testid="next-page"
        disabled={!hasMorePages}
        onClick={() => changePage(currentPage + 1)}
      >
        <ArrowRightIcon {...arrowSize} />
      </Button>
    </div>
  );
};
