import {
  AppLevelSection,
  ChildReference,
  OrganisationLevelSection,
  fileStore,
  sectionStore,
  userStore,
} from "@eolas-medical/core";
import { MainSectionIdentity, MainSection } from "./types";

export const mapToMainSection = (childReference: ChildReference): MainSection => {
  const sectionSummary = sectionStore.getChildReferenceByMainSectionType(
    childReference.identity === "genericContentRepository"
      ? childReference.id
      : (childReference.icon as any),
  );
  const commsFileChildrenOrder = sectionSummary && sectionStore.getChildrenOrder(sectionSummary.id);

  const getHasIssues = () => {
    const isCommsTile = [
      AppLevelSection.newsFeed,
      OrganisationLevelSection.communicationPortal,
    ].includes(childReference.icon as any);

    let allItemsRead = true;
    if (isCommsTile && commsFileChildrenOrder) {
      allItemsRead = commsFileChildrenOrder.every(
        (element) => userStore.isFileCompleted(element.id) && fileStore.getFile(element.id),
      );
    }

    return !allItemsRead;
  };

  const isIconEditable = childReference.identity === "genericContentRepository";

  const isDeletable = childReference.identity === "genericContentRepository";

  return {
    id: childReference.id,
    routeId: childReference.id.split("#")[1],
    name: childReference.name || "",
    iconName: childReference.icon || undefined,
    identity: identityMapper(childReference),
    disabled: childReference.disabled === "true",
    description: childReference.description || "",
    isIconEditable,
    isDeletable,
    hasIssues: getHasIssues(),
  };
};

const identityMapper = (childReference: ChildReference): MainSectionIdentity => {
  if (childReference.identity !== null && childReference.identity !== undefined)
    return childReference.identity as MainSectionIdentity;

  const mapper: Record<AppLevelSection, MainSectionIdentity> = {
    admissionGuides: null,
    clinicalDocuments: null,
    clinicalPathways: null,
    emergencyActionCards: null,
    equipmentLocations: null,
    guidelines: null,
    howTo: null,
    importantLinks: null,
    policyDocuments: null,
    rotas: null,
    surveys: null,
    wellbeing: null,
    checklists: "miniApp",
    channels: "miniApp",
    theDepartment: "miniApp",
    newsFeed: "miniApp",
    educationPortal: "miniApp",
    meetTheTeam: "miniApp",
    contacts: "miniApp",
    highlights: "miniApp",
    incidentReporting: "miniApp",
    patientLeaflets: "miniApp",
    completedChecklist: "miniApp",
    hospitalList: "miniApp",
    majorIncidentDocuments: "miniApp",
    majorIncidents: "miniApp",
    researchAndAudit: "miniApp",
    activityFeed: "miniApp",
  };

  return mapper[childReference.icon as AppLevelSection] || null;
};

export const mapToChildReference = (mainSection: MainSection): ChildReference => {
  const {
    iconName,
    iconUrl,
    disabled,
    isIconEditable,
    isDeletable,
    hasIssues,
    ...rest
  } = mainSection;

  return {
    ...rest,
    // eslint-disable-next-line prettier/prettier
    __typename: "ChildReference",
    icon: iconName,
    type: "mainSection",
    disabled: disabled ? "true" : "false",
  };
};

export const getMainSectionIdentity = (mainSectionID: string) => {
  const mainSectionType = mainSectionID
    ? sectionStore.getMainSectionTypeFromMainSectionID(mainSectionID)
    : null;
  const mainSectionIdentity = mainSectionID
    ? sectionStore.getMainSectionIdentityByMainSectionId(mainSectionID)
    : null;
  const childReference = mainSectionID
    ? sectionStore.getMainSectionChildReference(mainSectionID)
    : null;

  return { mainSectionType, mainSectionIdentity, childReference };
};
