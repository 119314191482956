import { useFileOperations } from "Hooks";
import { EolasFile } from "Utilities/types";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";

export const useMajorIncident = (incident: EolasFile) => {
  const { updateFile } = useFileOperations();
  const { error, isLoading, setRequestStatus, isSuccessful } = useRequestStatus();

  const onStandDown = async () => {
    setRequestStatus({ status: "pending", error: "" });
    try {
      const { errors } = await updateFile({
        variables: {
          parentID: incident.parentID,
          input: {
            id: incident.id,
            moreInfoTwo: "deactivated",
          },
        },
      });
      if (errors && errors.length) {
        const [error] = errors;
        setRequestStatus({ status: "error", error: error.message });
      } else {
        setRequestStatus({ status: "success", error: "" });
      }
    } catch (error: any) {
      const errorMesssage = errorStore.captureError({
        error,
        source: "user",
        retryCallback: updateFile,
        retryParameters: {
          variables: {
            parentID: incident.parentID,
            input: {
              id: incident.id,
              moreInfoTwo: "deactivated",
            },
          },
        },
      });

      setRequestStatus({ status: "error", error: errorMesssage });
    }
  };

  return {
    error,
    isLoading,
    onStandDown,
    isSuccessful,
  };
};
