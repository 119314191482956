import { QueryEnvironment, useEolasQuery } from "Hooks";
import { getEventsSummary } from "../client/portfolio.client";
import { EventSummary } from "../types";
import portfolioKeys from "./portfolio.queryKeys";

interface useEventListProps {
  eventList: EventSummary[];
  eventListLoading: boolean;
}

export const useEventList = (ownerId?: string): useEventListProps => {
  const { isLoading, data, ...rest } = useEolasQuery({
    queryFn: () => getEventsSummary(ownerId),
    queryKey: portfolioKeys.lists(ownerId),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 300000,
      staleTime: 200000,
    },
  });

  return { eventListLoading: isLoading, eventList: data ?? [], ...rest };
};

export default useEventList;
