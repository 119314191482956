import { useTranslation } from "react-i18next";

import { InnerModalWrapper } from "UIKit";
import { AlertTwoIcon } from "Assets/Icons";

export const NoTextEnteredModal = () => {
  const { t } = useTranslation();

  return (
    <InnerModalWrapper>
      <div className="justify-center items-center space-y-4 p-4 sm:p-12">
        <AlertTwoIcon className="text-red h-10 w-10 sm:h-16 sm:w-16" />

        <span className="text-lg font-semibold text-center">
          {t("selectSpace_errors_noTextEntered")}
        </span>
      </div>
    </InnerModalWrapper>
  );
};
