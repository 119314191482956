import React, { useCallback } from "react";

import { DeleteIcon, EditMonoIcon, StarIcon, StarOutlineIcon } from "Assets";
import { IconButton } from "UIKit/Button";

interface BaseContent {
  id: string;
  isHighlighted?: boolean;
}

interface TileControlsProps<F> {
  content: F;
  onEdit?: (content: F) => void;
  onDelete?: (content: F) => void;
  onHighlight?: (content: F) => void;
  direction?: "row" | "column";
  isHighlighting?: boolean;
  "data-testid"?: string;
}

export const TileControls = <F extends BaseContent>({
  content,
  onEdit,
  onDelete,
  onHighlight,
  direction = "column",
  isHighlighting,
  "data-testid": dataTestId = "tile-controls",
}: TileControlsProps<F>) => {
  const _onEdit = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onEdit && onEdit(content);
    },
    [content, onEdit],
  );

  const _onDelete = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onDelete && onDelete(content);
    },
    [content, onDelete],
  );

  const _onHightlight = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onHighlight && onHighlight(content);
    },
    [content, onHighlight],
  );

  return (
    <div
      className={`
        flex items-center h-full content-between justify-between space-y-2
        ${direction === "row" ? "flex-row" : "flex-col"}
        self-start md:self-center pt-1
      `}
      data-testid={dataTestId}
    >
      {onHighlight && (
        <IconButton
          size="sm"
          onClick={_onHightlight}
          isLoading={isHighlighting}
          data-testid={`highlightButtonFor-${content.id}`}
          icon={content.isHighlighted ? <StarIcon /> : <StarOutlineIcon />}
        />
      )}

      {onDelete && (
        <IconButton
          size="sm"
          onClick={_onDelete}
          icon={<DeleteIcon />}
          data-testid={`deleteButtonFor-${content.id}`}
        />
      )}

      {onEdit && (
        <IconButton
          size="sm"
          onClick={_onEdit}
          icon={<EditMonoIcon />}
          data-testid={`editButtonFor-${content.id}`}
        />
      )}
    </div>
  );
};
