import React, { useMemo } from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

import * as icons from "Assets/Icons";
import { EolasIcons, LoaderIcon } from "Assets/Icons";

import { ButtonVariant, ButtonSize, ButtonType, ButtonWeight, ButtonColorScheme } from "./types";

interface ButtonStyleProps extends Omit<React.HTMLProps<HTMLButtonElement>, "size" | "ref"> {
  size?: ButtonSize;
  disabled?: boolean;
  weight?: ButtonWeight;
  variant?: ButtonVariant;
  color?: ButtonColorScheme;
}

export interface ButtonProps extends ButtonStyleProps {
  type?: ButtonType;
  "data-testid"?: string;
  isLoading?: boolean;
  className?: string;
  iconLeft?: EolasIcons | null;
  iconRight?: EolasIcons | null;
  children?: React.ReactNode;
  childrenContainerClassname?: string;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
}

export const Button = React.forwardRef<any, ButtonProps>(
  (
    {
      onClick,
      children,
      className,
      isLoading,
      size = "md",
      color = "blue",
      type = "button",
      variant = "solid",
      disabled = false,
      weight = "semibold",
      childrenContainerClassname,
      iconLeft,
      iconRight,
      ...props
    },
    ref,
  ) => {
    const [buttonStyles, iconClass] = useMemo(
      () =>
        generateStyle({
          size,
          color,
          variant,
          disabled: disabled,
        }),
      [size, color, variant, disabled],
    );

    const LeftIcon = iconLeft ? icons[iconLeft] : null;
    const RightIcon = iconRight ? icons[iconRight] : null;

    return (
      <button
        ref={ref}
        type={type}
        onClick={onClick}
        disabled={disabled || isLoading}
        className={tw(`
          ${buttonStyles} font-${weight} relative flex items-center justify-center rounded-xl ${className}
        `)}
        {...props}
      >
        <span className={`absolute ${!isLoading ? "invisible" : "block"}`}>
          <LoaderIcon
            data-testid="loader-icon"
            className={`${iconClass.shared} animate-spin-slow`}
          />
        </span>
        <div
          className={tw(`
            ${isLoading ? "invisible" : "flex"}
            font-bold flex justify-center items-center w-full
            ${childrenContainerClassname}
          `)}
        >
          {LeftIcon && <LeftIcon className={`${iconClass.shared} ${iconClass.left}`} />}
          {children}
          {RightIcon && <RightIcon className={`${iconClass.shared} ${iconClass.right}`} />}
        </div>
      </button>
    );
  },
);

type IconClass = { left: string; right: string; shared: string };

const iconSizes: Record<ButtonSize, IconClass> = {
  xs: { left: "", right: "", shared: "h-4 w-4" },
  sm: { left: "mr-1", right: "ml-1", shared: "h-4 w-4" },
  md: { left: "", right: "", shared: "h-5 w-5" },
  lg: { left: "", right: "", shared: "h-6 w-6" },
  xl: { left: "", right: "", shared: "h-7 w-7" },
};

const buttonSizes: Record<ButtonSize, string> = {
  xs: "min-h-8 h-8 px-3 text-sm",
  sm: "min-h-10 h-10 px-3 text-sm",
  md: "min-h-10 h-10 px-4 text-base",
  lg: "min-h-12 h-12 px-5 text-base",
  xl: "min-h-14 h-14 px-6 text-lg",
};

type ColorMap = Record<ButtonColorScheme, string>;
type ButtonStyles = Record<ButtonVariant, ColorMap>;

const makeButtonStyles = (isDisabled?: boolean): ButtonStyles => {
  return {
    link: {
      blue: isDisabled
        ? "bg-transparent border-transparent text-grey-500 cursor-not-allowed"
        : "bg-transparent border-transparent text-blue-500 hover:text-blue-300 active:text-blue-600",
      red: isDisabled
        ? "bg-transparent border-transparent text-grey-500 cursor-not-allowed"
        : "bg-transparent border-transparent text-red-500 hover:text-red-300 active:text-red-600",
      green: isDisabled
        ? "bg-transparent border-transparent text-grey-500 cursor-not-allowed"
        : "bg-transparent border-transparent text-green-500 hover:text-green-300 active:text-green-600",
      yellow: isDisabled
        ? "bg-transparent border-transparent text-grey-500 cursor-not-allowed"
        : "bg-transparent border-transparent text-yellow-500 hover:text-yellow-300 active:text-yellow-600",
      grey: isDisabled
        ? "bg-transparent border-transparent text-grey-300 cursor-not-allowed"
        : "bg-transparent border-transparent text-black hover:text-grey-700 active:text-grey-600",
      black: "",
      white: "",
    },
    ghost: {
      blue: isDisabled
        ? "text-blue-200 cursor-not-allowed"
        : `
        border-transparent bg-white text-blue-500
        hover:bg-grey-100 active:bg-blue-50
      `,
      red: isDisabled
        ? "text-red-200 cursor-not-allowed"
        : `
        border-transparent bg-white text-red-500
        hover:bg-grey-100 active:bg-red-50
      `,
      green: isDisabled
        ? "text-green-200 cursor-not-allowed"
        : `
        border-transparent bg-white text-green-500
        hover:bg-grey-100 active:bg-green-50
      `,
      yellow: isDisabled
        ? "text-yellow-200 cursor-not-allowed"
        : `
        border-transparent bg-white text-yellow-500
        hover:bg-grey-100 active:bg-yellow-50
      `,
      grey: isDisabled
        ? "border-transparent text-grey-300 cursor-not-allowed"
        : "border-transparent bg-white text-black hover:bg-grey-100 active:bg-grey-50",
      black: "",
      white: "",
    },
    outline: {
      blue: isDisabled
        ? "bg-white text-blue-50 border-blue-200 cursor-not-allowed"
        : `
        bg-white border-blue-500 text-blue-500
        hover:border-blue-300 hover:bg-blue-50 active:bg-blue-100
      `,
      red: isDisabled
        ? "bg-white text-red-50 border-red-200 cursor-not-allowed"
        : `
        bg-white border-red-500 text-red-500
        hover:border-red-300 hover:bg-red-50 active:bg-red-100
      `,
      green: isDisabled
        ? "bg-white text-green-50 border-green-200 cursor-not-allowed"
        : `
        bg-white border-green-500 text-green-500
        hover:border-green-300 hover:bg-green-50 active:bg-green-100
      `,
      yellow: isDisabled
        ? "bg-white text-yellow-50 border-yellow-200 cursor-not-allowed"
        : `
        bg-white border-yellow-500 text-yellow-500
        hover:border-yellow-300 hover:bg-yellow-50 active:bg-yellow-100
      `,
      grey: isDisabled
        ? "bg-white text-grey-400 border-grey-100 cursor-not-allowed"
        : `
        bg-white border-grey-400 text-black
        hover:border-grey-500 hover:bg-grey-50 active:bg-grey-100
      `,
      black: "",
      white: "bg-white border-grey-400 text-black",
    },
    solid: {
      blue: isDisabled
        ? "bg-blue-200 border-blue-200 text-blue-50 cursor-not-allowed"
        : `
        bg-blue-500 border-blue-500 text-white
        hover:bg-blue-300 hover:border-blue-300
        active:bg-blue-600 active:border-blue-600
      `,
      red: isDisabled
        ? "bg-red-200 border-red-200 text-red-50 cursor-not-allowed"
        : `
        bg-red-500 border-red-500 text-white
        hover:bg-red-300 hover:border-red-300
        active:bg-red-600 active:border-red-600
      `,
      green: isDisabled
        ? "bg-green-200 border-green-200 text-green-50 cursor-not-allowed"
        : `
        bg-green-500 border-green-500 text-white
        hover:bg-green-300 hover:border-green-300
        active:bg-green-600 active:border-green-600
      `,
      yellow: isDisabled
        ? "bg-yellow-200 border-yellow-200 text-yellow-50 cursor-not-allowed"
        : `
        bg-yellow border-yellow text-white
        hover:bg-yellow-300 hover:border-yellow-300
        active:bg-yellow-600 active:border-yellow-600
      `,
      grey: isDisabled
        ? "bg-grey-100 border-grey-100 text-grey-300 cursor-not-allowed"
        : `
        bg-grey-300 border-grey-300 text-black
        hover:bg-grey-100 hover:border-grey-100
        active:bg-grey-200 active:border-grey-200
      `,
      black: "bg-black border-black text-white hover:bg-grey-700 hover:border-grey-700",
      white: "bg-white border-grey-400 text-black",
    },
  };
};

// https://v2.tailwindcss.com/docs/optimizing-for-production#writing-purgeable-html
// can't use string interpolations (e.g: `bg-${color}) due to purging`

function generateStyle({
  disabled,
  size = "md",
  color = "blue",
  variant = "solid",
}: ButtonStyleProps): [string, IconClass] {
  const btnSize = buttonSizes[size];
  const iconSize = iconSizes[size];

  const buttonStyles = makeButtonStyles(disabled);

  return [`${btnSize} border ${buttonStyles[variant][color]}`, iconSize];
}
