import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import {
  EolasFile,
  sectionStore,
  AppLevelSection,
  MajorIncidentSubSection,
} from "@eolas-medical/core";

import { useMainSectionVisit, useSelectFile } from "Hooks";
import { DeleteFileModal, EolasFileList } from "Components";
import { AddButton, NavButton, Modal, PageTitle, InnerPageWrapper } from "UIKit";
import { observer } from "mobx-react-lite";
import { AddItemWithAttachmentModal } from "Pages/Spaces/components/AddEolasFile";

export const MajorIncidentsDocuments: React.FC<RouteComponentProps> = observer(
  ({ match: { url } }) => {
    const { t } = useTranslation();
    const { onSelectFile: selectFile } = useSelectFile();

    const mainId = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.majorIncidents)
      ?.id;

    const childId = sectionStore
      .getChildrenOrder(mainId as string)!
      .find((ref) => ref!.icon === MajorIncidentSubSection.documents)?.id;

    useMainSectionVisit({
      mainSectionId: AppLevelSection.majorIncidents,
      subSectionId: MajorIncidentSubSection.documents,
    });

    const isAdmin = sectionStore.isAdmin;
    const [modal, setModal] = useState<React.ReactNode>(null);

    const onCloseModal = useCallback(() => {
      setModal(null);
    }, [setModal]);

    const onAddNewDocument = () => {
      setModal(
        <AddItemWithAttachmentModal
          hideDescription
          subSectionId={MajorIncidentSubSection.documents}
          mainSectionID={AppLevelSection.majorIncidents}
          fileTypes={["link", "pdf", "ms-office"]}
          onCloseModal={onCloseModal}
        />,
      );
    };

    const onEditDocument = (file: EolasFile) => {
      setModal(
        <AddItemWithAttachmentModal
          hideDescription
          subSectionId={MajorIncidentSubSection.documents}
          mainSectionID={AppLevelSection.majorIncidents}
          onCloseModal={onCloseModal}
          fileTypes={["link", "pdf", "ms-office"]}
          file={file}
        />,
      );
    };

    const onDeleteDocument = useCallback(
      (file: EolasFile) => {
        setModal(<DeleteFileModal file={file} onCloseModal={onCloseModal} />);
      },
      [setModal, onCloseModal],
    );

    const onSelectDocument = useCallback(
      (file: EolasFile) => {
        selectFile(file);
      },
      [selectFile],
    );

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={t("incidents_admin_documents_title")} />
          <NavButton to={url.replace("/documents", "")} />

          {isAdmin && (
            <AddButton data-testid="add-new-file" onClick={onAddNewDocument}>
              {t("incidents_admin_add_document_button")}
            </AddButton>
          )}

          <EolasFileList
            isAdmin={isAdmin}
            subSectionId={childId}
            onEdit={onEditDocument}
            onDelete={onDeleteDocument}
            onAddItem={onAddNewDocument}
            onSelectFile={onSelectDocument}
            mainSectionId={AppLevelSection.majorIncidents}
          />
        </InnerPageWrapper>

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </>
    );
  },
);
