import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CommunityLevelSection } from "@eolas-medical/core";

import {
  bodyWeightCalc,
  BodyWeightCalcArgs,
  BodyWeightCalcResults,
  ClinicalCalculatorsSubSection,
} from "@eolas-medical/clinical-calculators";

import { useMainSectionVisit } from "Hooks";
import { HeightIcon, WeightIcon } from "Assets";
import { InnerPageWrapper, Modal, NavButton, PageTitle, WarningModal } from "UIKit";

import { useCalculatorWarning } from "../hooks";
import { ClinicalCalculatorRouteProps } from "../types";
import { ResultsModal } from "../components/ResultsModal";
import { CalculatorForm } from "../components/CalculatorForm";
import { AttentionModal } from "../components";

interface BodyWeightCalcState {
  values?: BodyWeightCalcArgs;
  result?: BodyWeightCalcResults;
}

export const BodyWeightCalculator: React.FC<ClinicalCalculatorRouteProps> = ({
  history,
  match: { params },
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<BodyWeightCalcState>({});
  const { warning, setWarningMessage } = useCalculatorWarning(params.id);

  useMainSectionVisit({
    mainSectionId: CommunityLevelSection.clinicalCalculators,
    subSectionId: "body weight",
  });

  const handleSubmit = (values: BodyWeightCalcArgs) => {
    const response = bodyWeightCalc(values);
    setState({ result: response, values });
  };

  return (
    <InnerPageWrapper>
      <PageTitle title={t("clinicalCalculators_body_weight_title")} />
      <NavButton onClick={history.goBack} />

      <CalculatorForm
        onSubmit={handleSubmit}
        schemaName="idealBodyWeight"
        values={[
          {
            id: "weight",
            label: t("clinicalCalculators_weight_label"),
            inputProps: {
              type: "number",
              iconLeft: <WeightIcon className="w-4" />,
              placeholder: t("clinicalCalculators_weight_placeholder"),
            },
          },
          {
            id: "height",
            label: t("clinicalCalculators_height_label"),
            inputProps: {
              type: "number",
              iconLeft: <HeightIcon className="w-4" />,
              placeholder: t("clinicalCalculators_height_placeholder"),
            },
          },
        ]}
      />
      <AttentionModal calculatorType={ClinicalCalculatorsSubSection.idealBodyWeight} />
      <Modal open={!!(state.result && state.values)} onClose={() => setState({})}>
        {state.result && state.values && (
          <ResultsModal
            handleClose={() => setState({})}
            result={state.result}
            values={state.values}
            type={ClinicalCalculatorsSubSection.idealBodyWeight}
          />
        )}
      </Modal>

      <WarningModal
        text={warning}
        isOpen={!!warning}
        confrimTitle="Got it"
        title="Warning Message"
        onConfirm={() => setWarningMessage("")}
      />
    </InnerPageWrapper>
  );
};
