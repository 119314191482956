import QRCode from "qrcode.react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { EolasFile } from "Utilities/types";
import { Button, InnerModalWrapper, FormError, Text } from "UIKit";

import { useQRLeaflet } from "../hooks/useQRLeaflet";

interface PatientLeafletQRModalProps {
  file: EolasFile;
}

export const PatientLeafletQRModal: React.FC<PatientLeafletQRModalProps> = ({ file }) => {
  const { t } = useTranslation();
  const {
    error,
    QRValue,
    videoURL,
    isLoading,
    setVideoURL,
    onSelectFile,
    shouldShowPlayer,
  } = useQRLeaflet(file);

  return (
    <InnerModalWrapper>
      {shouldShowPlayer ? (
        <div className="flex flex-col mt-4 sm:mt-8">
          <div className="flex flex-col">
            <ReactPlayer
              playing
              controls
              width="100%"
              height="100%"
              stopOnUnmount
              url={videoURL}
              style={{ width: "100%" }}
              data-testid="videoPlayer"
            />

            <Button
              color="grey"
              weight="regular"
              className="self-center my-4"
              onClick={() => setVideoURL("")}
            >
              {t("EdPortal_videoPlayer_button")}
            </Button>
          </div>
        </div>
      ) : (
        <QRCode
          data-testid="leaflet-QR-code"
          value={QRValue}
          size={320}
          className="self-center mb-8"
        />
      )}

      {!shouldShowPlayer && (
        <>
          {file.type === "pdf" || file.type === "ms-office" ? (
            <Link
              className="mx-auto"
              to={{ pathname: `/documents/viewer/${encodeURIComponent(file.id)}` }}
            >
              <Button color="blue" className="self-center mb-4">
                {t("patientLeaflets_preview_leaflet_button")}
              </Button>
            </Link>
          ) : (
            <Button
              color="blue"
              isLoading={isLoading}
              className="self-center mb-4"
              onClick={onSelectFile}
            >
              {t("patientLeaflets_preview_leaflet_button")}
            </Button>
          )}
        </>
      )}

      <FormError error={error} />

      <div className="flex flex-col space-y-2">
        <Text level={2} className="text-sm text-center">
          {t("patientLeaflets_QR_iphone")}
        </Text>
        <Text level={2} className="text-sm text-center">
          {t("patientLeaflets_QR_android")}
        </Text>
      </div>
    </InnerModalWrapper>
  );
};
