import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import { appConfig } from "AppTypeConfig";

import { GridContainer, InnerPageWrapper, PageTitle } from "UIKit";
import MasterSearch from "Components/MasterSearch/MasterSearch";

import useOrganisation from "modules/spaces/data/useOrganisation";
import useOrganisationMainSections from "modules/spaces/data/useOrganisationMainSections";
import MainSectionList from "Pages/Spaces/pages/Space/components/MainSectionList/MainSectionList";

const Organisation = observer(() => {
  const { t } = useTranslation();
  const { organisation } = useOrganisation();
  const { activeMainSections } = useOrganisationMainSections();

  return (
    <InnerPageWrapper>
      <PageTitle
        subTitle={t("pageSubTitle")}
        title={organisation?.name ?? appConfig.appName}
        data-testid="client-hospital-dashboard-title"
      />

      <MasterSearch />

      <GridContainer>
        <MainSectionList mainSectionList={activeMainSections} />
      </GridContainer>
    </InnerPageWrapper>
  );
});

export default Organisation;
