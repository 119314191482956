import { useTranslation } from "react-i18next";
import { overrideTailwindClasses as tw } from "tailwind-override";

import { Text } from "../../Typography";
import { Button, ButtonProps } from "../../Button";

export interface ModalWizardButtonsProps {
  onBack(): void;
  onNext?(): void;
  backLabel: string;
  nextLabel: string;
  className?: string;
  isLoading?: boolean;
  backDisabled?: boolean;
  nextDisabled?: boolean;
  size?: ButtonProps["size"];
  currentStep?: number;
  maxSteps?: number;
  showSteps?: boolean;
}

export const ModalWizardButtons: React.FC<ModalWizardButtonsProps> = ({
  onBack,
  onNext,
  className,
  backLabel,
  nextLabel,
  isLoading,
  size = "lg",
  nextDisabled,
  backDisabled,
  currentStep,
  maxSteps,
  showSteps = true,
}) => {
  const { t } = useTranslation();
  const hasSteps = typeof currentStep !== "undefined" && typeof maxSteps !== "undefined";

  return (
    <div className={tw(`flex flex-col sm:flex-row sm:items-center justify-end mt-6 ${className}`)}>
      {hasSteps && showSteps && (
        <Text
          level={1}
          className="font-bold text-grey-700 text-center sm:text-left flex-1 order-1 sm:order-0 mt-4 sm:mt-0"
        >
          {t("wizard_step", { currentStep: currentStep! + 1, maxSteps })}
        </Text>
      )}
      <div className="flex items-center space-x-4 sm:space-x-6 order-0 sm:order-1">
        <Button
          size={size}
          color="grey"
          type="button"
          onClick={onBack}
          variant="outline"
          className="w-full sm:w-32"
          disabled={backDisabled || isLoading}
          data-testid="modal-wizard-back-button"
        >
          {backLabel}
        </Button>

        <Button
          size={size}
          onClick={onNext}
          isLoading={isLoading}
          disabled={nextDisabled}
          className="w-full sm:w-32"
          type={onNext ? "button" : "submit"}
          data-testid="modal-wizard-next-button"
        >
          {nextLabel}
        </Button>
      </div>
    </div>
  );
};
