import { observer } from "mobx-react-lite";

import { EditIcon, FloppyDiskIcon, SpinningDotsIcon } from "Assets";

import { usePersonalDetails, NameProperty } from "../hooks";

interface EditableNameInputProps {
  id: NameProperty;
  label: string;
  placeholder: string;
}

export const EditableNameInput: React.FC<EditableNameInputProps> = observer(
  ({ id, label, placeholder }) => {
    const {
      value,
      onSave,
      disabled,
      inputRef,
      editable,
      loading,
      inputValue,
      makeEditable,
      onChangeInput,
    } = usePersonalDetails(id);

    return (
      <div className="flex flex-col items-stretch p-4">
        <label htmlFor={id} className="font-semibold">
          {label}
        </label>
        <div className="flex items-center">
          {editable ? (
            <input
              id={id}
              ref={inputRef}
              value={inputValue ?? ""}
              placeholder={placeholder}
              onChange={onChangeInput}
              style={{ marginLeft: "-1rem" }}
              className="h-12 px-4 bg-white border-2 flex-1 rounded-lg border-grey-100 focus:border-blue-100"
            />
          ) : (
            <span className="flex-1 h-10 flex items-center">{value}</span>
          )}
          {loading ? (
            <SpinningDotsIcon width={24} height={24} className="animate-spin cursor-pointer ml-4" />
          ) : editable ? (
            <FloppyDiskIcon
              width={24}
              height={24}
              onClick={onSave}
              className="cursor-pointer ml-4"
            />
          ) : (
            !disabled && (
              <EditIcon
                width={24}
                height={24}
                className="cursor-pointer ml-4"
                onClick={makeEditable}
              />
            )
          )}
        </div>
      </div>
    );
  },
);
