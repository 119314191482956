import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { EolasMainSection, EolasFile } from "@eolas-medical/core";
import { overrideTailwindClasses as tw } from "tailwind-override";

import { useSortOrder } from "Hooks/useSortOrder";
import { SearchIcon, DragIcon } from "Assets/Icons";
import { noFilesFound, upload } from "Assets/LottieAnimations";
import { Input, LottieWithHeader, VirtualGrid, VirtualSortableList } from "UIKit";

import { ListOrderByOption } from "./ListOrderBy";
import { EolasFileListItem } from "./EolasFileListItem";
import { useEolasFileList } from "./hooks/useEolasFileList";

export interface EolasFileListProps {
  isAdmin: boolean;
  infoText?: string;
  className?: string;
  draggable?: boolean;
  subSectionId?: string;
  listClassName?: string;
  adminNoFiles?: React.ReactNode;
  clientNoFiles?: React.ReactNode;
  mainSectionId: EolasMainSection;
  defaultSort?: ListOrderByOption;
  displayMode?: "list" | "grid";
  sortDateBy?: "createdAt" | "updatedAt";
  onAddItem?(): void;
  onEdit?(file: EolasFile): void;
  onDelete?(file: EolasFile): void;
  onSelectFile?(file: EolasFile): void;
  sortFn?(first: EolasFile, second: EolasFile): number;
  filterFn?(searchInput: string): (file: EolasFile) => boolean;
}

export const EolasFileList: React.FC<EolasFileListProps> = observer(
  ({
    isAdmin,
    infoText,
    adminNoFiles,
    subSectionId,
    clientNoFiles,
    mainSectionId,
    className = "",
    draggable = true,
    displayMode = "list",
    sortDateBy = "updatedAt",
    defaultSort = "dragAndDrop",
    onEdit,
    sortFn,
    filterFn,
    onDelete,
    onAddItem,
    onSelectFile,
  }) => {
    const { t } = useTranslation();

    const [SortComponent, { orderBy, sortMethod }] = useSortOrder({
      initialOrder: defaultSort,
      isDraggable: draggable,
      sortDateBy,
      sortFn,
    });

    const {
      onDragEnd,
      hasNoFiles,
      sectionName,
      searchInput,
      filteredFiles,
      onSearchInputChange,
    } = useEolasFileList({
      sortFn: sortMethod,
      isAdmin,
      filterFn,
      sectionID: subSectionId,
      mainSectionID: mainSectionId,
    });

    const canDrag = useMemo(
      () =>
        draggable &&
        filteredFiles.length > 1 &&
        isAdmin &&
        searchInput === "" &&
        orderBy === "dragAndDrop",
      [draggable, filteredFiles, isAdmin, searchInput, orderBy],
    );

    if (isAdmin && hasNoFiles) {
      if (adminNoFiles) {
        return <>{adminNoFiles}</>;
      }

      return (
        <LottieWithHeader
          animation={upload}
          onClick={onAddItem}
          animationSize="125px"
          lottieOptions={{ loop: true }}
          text={t("fileWithSearch_upload_new_file")}
        />
      );
    }

    if (!isAdmin && hasNoFiles) {
      if (clientNoFiles) {
        return <>{clientNoFiles}</>;
      }

      return (
        <LottieWithHeader
          animationSize="125px"
          animation={noFilesFound}
          lottieOptions={{ loop: true }}
          text={t("fileList_noFiles", {
            mainSection: sectionName?.toLocaleLowerCase(),
          })}
        />
      );
    }

    return (
      <div className={tw(`flex flex-col space-y-6 pb-8 ${className}`)}>
        <Input
          size="lg"
          value={searchInput}
          iconLeft={<SearchIcon />}
          onChange={onSearchInputChange}
          data-testid="search-list-input"
          placeholder={t("SearchBarPlaceholder")}
          className="input bg-white border-grey-200"
        />

        {filteredFiles.length > 1 && SortComponent}

        {canDrag && (
          <div className="flex items-center justify-center space-x-2 ">
            <DragIcon width={16} height={16} className="text-grey-500" />
            <span className="text-center text-grey-mid">{t("contentManagement_info")}</span>
          </div>
        )}

        {infoText && <span className="text-center text-grey-darker">{infoText}</span>}

        {displayMode === "grid" ? (
          <VirtualGrid
            items={filteredFiles}
            renderItem={(eolasFile) => {
              return (
                <EolasFileListItem
                  onEdit={onEdit}
                  canDrag={canDrag}
                  isAdmin={isAdmin}
                  onDelete={onDelete}
                  eolasFile={eolasFile}
                  onSelectFile={onSelectFile}
                />
              );
            }}
          />
        ) : (
          <VirtualSortableList
            items={filteredFiles}
            onDragEnd={onDragEnd}
            droppableId="file-list"
            isDragDisabled={!canDrag}
            renderItem={(eolasFile, isDragging) => {
              return (
                <EolasFileListItem
                  onEdit={onEdit}
                  canDrag={canDrag}
                  isAdmin={isAdmin}
                  onDelete={onDelete}
                  eolasFile={eolasFile}
                  isDragging={isDragging}
                  onSelectFile={onSelectFile}
                />
              );
            }}
          />
        )}
      </div>
    );
  },
);
