import { AppLevelSection, EolasFile, sectionStore } from "@eolas-medical/core";
import fileDownload from "js-file-download";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { generateS3PublicImageURL } from "Utilities";
import { getPDFUrl } from "Hooks/useDocumentCache";
import { DOWNLOADABLE_TYPES } from "Utilities/helpers";
import { EolasFileType } from "Utilities/types";

export const useDownloadFile = (eolasFile: EolasFile) => {
  const [downloadUrl, setDownloadUrl] = useState("");
  const mainSection = eolasFile.mainSectionID
    ? sectionStore.getMainSectionTypeFromMainSectionID(eolasFile.mainSectionID)
    : null;
  const isDownloadable = DOWNLOADABLE_TYPES.has(eolasFile.type as EolasFileType);
  const downloadFileUrl = useCallback(
    async (key: string) => {
      if (mainSection === AppLevelSection.patientLeaflets) {
        return await generateS3PublicImageURL(key);
      } else {
        return await getPDFUrl(key);
      }
    },
    [mainSection],
  );

  useEffect(() => {
    let isCancelled = false;

    if (eolasFile.key && isDownloadable) {
      downloadFileUrl(eolasFile.key!).then((key) => {
        if (!isCancelled) {
          setDownloadUrl(key);
        }
      });
    }

    // Cleanup function - needed to remove ops on unmounted components error
    return () => {
      isCancelled = true;
    };
  }, [downloadFileUrl, eolasFile.key, isDownloadable]);

  const download = (url: string, filename: string) => {
    isDownloadable &&
      axios
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, filename);
        });
  };

  const saveFile = (e: any) => {
    if (!eolasFile.key) return;
    const extension = eolasFile?.key?.split(".")?.pop();
    const fileName = `${eolasFile.name}.${extension}`;

    e.stopPropagation();
    e.preventDefault();
    download(downloadUrl!, fileName);
  };
  return {
    saveFile,
  };
};
