import { useMutation } from "@tanstack/react-query";
import { UpdateMainSectionDto, contentClient, fetchAllData } from "@eolas-medical/core";

const useEditMainSection = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async (mainSection: UpdateMainSectionDto) => {
      return contentClient.updateMainSection(mainSection);
    },
    {
      onSuccess: async () => {
        await fetchAllData();
      },
    },
  );

  return {
    editMainSection: mutate,
    editingMainSection: isLoading,
    editMainSectionSuccess: isSuccess,
    editMainSectionError: error as string,
  };
};

export default useEditMainSection;
