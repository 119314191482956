import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AnalyticsEvents, EolasFile, sectionStore } from "@eolas-medical/core";
import { InnerPageWrapper, NavButton, Text } from "UIKit";
import { trackEvent } from "API/Analytics";
import { fromAwsJSON, getImageUrl } from "Utilities";
import { useParseHtmlToReactNodes } from "./hooks/useParseHtmlToReactNodes";
import styles from "./FlashcardViewerPage.module.css";
import { format } from "date-fns";

interface FlashcardViewerProps {
  flashcard: EolasFile;
}

const FlashcardViewer = ({ flashcard }: FlashcardViewerProps) => {
  const history = useHistory();
  const flashcardMetadata = flashcard.metadata ? fromAwsJSON(flashcard.metadata) : {};

  const parsedFlashcardContent = useParseHtmlToReactNodes(flashcardMetadata.flashcardContent);

  const mainSectionType = flashcard.mainSectionID
    ? sectionStore.getMainSectionTypeFromMainSectionID(flashcard.mainSectionID)
    : null;

  const mainSectionIdentity = flashcard.mainSectionID
    ? sectionStore.getMainSectionIdentityByMainSectionId(flashcard.mainSectionID)
    : null;

  // the order is important here as if the mainSection is null, we want to use the mainSectionIdentity
  const idForTracking = mainSectionType ?? mainSectionIdentity;

  useEffect(() => {
    if (idForTracking) {
      trackEvent(AnalyticsEvents.OPEN_FILE, {
        mainSectionId: idForTracking,
        fileId: flashcard.id,
        fileType: flashcard.type || "",
      });
    }
  }, [flashcard.id, flashcard.type, idForTracking]);

  return (
    <InnerPageWrapper>
      <div className="py-1">
        <div className="flex justify-center space-x-3">
          <NavButton onClick={history.goBack} />
        </div>
      </div>
      <div className={`bg-white rounded-lg pb-12 ${styles.flashcard}`}>
        {flashcard.key && (
          <img
            src={getImageUrl(flashcard.key)}
            alt="Flashcard Hero"
            className="w-full object-cover max-h-80 rounded-t-lg mb-8"
          />
        )}
        <Text level={2} className="text-grey-600 px-5">
          {format(new Date(flashcard.createdAt as string), "do MMMM yyyy")}
        </Text>
        <div className="p-5">{parsedFlashcardContent}</div>
      </div>
    </InnerPageWrapper>
  );
};

export default FlashcardViewer;
