import { useCallback } from "react";

import { useFileOperations } from "Hooks";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { EolasChecklistSection } from "Utilities/types";
import { fetchAllData } from "@eolas-medical/core";
import { useHistory } from "react-router-dom";

export const useDeleteChecklist = (checklist: EolasChecklistSection) => {
  const { error, isSuccessful, isLoading, setRequestStatus } = useRequestStatus();
  const { removeFile } = useFileOperations();
  const { goBack } = useHistory();

  const onDelete = useCallback(() => {
    setRequestStatus({ status: "pending", error: "" });
    removeFile({
      variables: {
        id: checklist.id,
        parentID: checklist.parentID,
      },
    })
      .then(async () => {
        await fetchAllData();
        setRequestStatus({ status: "success", error: "" });
        goBack();
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  }, [setRequestStatus, checklist.id, checklist.parentID, removeFile, goBack]);

  return {
    error,
    isLoading,
    isSuccessful,
    onDelete,
  };
};
