import { CommunityLevelSection, sectionStore } from "@eolas-medical/core";
import { MainSection } from "../types";
import { useMainSectionFlags } from "Hooks";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { mapToMainSection } from "../helpers";

interface UseKnowledgeSections {
  knowledgeSections: MainSection[];
}

const useKnowledgeSections = (): UseKnowledgeSections => {
  const { isMainSectionEnabled } = useMainSectionFlags();
  const { getIconUrl } = useGetIconUrl();

  const knowledgeSections = sectionStore
    .getMainSectionList("community")
    .filter(({ id }) => {
      const section = sectionStore.getSection(id);

      if (!section) return false;

      return (
        section.type in CommunityLevelSection &&
        isMainSectionEnabled(section.type as CommunityLevelSection)
      );
    })
    .map(mapToMainSection)
    .map((s) => ({ ...s, iconUrl: s.iconName ? getIconUrl(s.iconName) : undefined }));

  return {
    knowledgeSections,
  };
};

export default useKnowledgeSections;
