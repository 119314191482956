import React from "react";
import { Control, FieldErrors, UseFormWatch } from "react-hook-form";
import { isValid, parseISO } from "date-fns";
import { Input, Toggle, FormElement, DatePicker, Textarea } from "UIKit";
import { ConfigureFileStepFormValues } from "./types";

interface ConfigureFileProps {
  control: Control<ConfigureFileStepFormValues>;
  toggleExpiryLabel: string;
  toggleExpiryCheckText: string;
  expiryDatePickerLabel: string;
  nameInputLabel: string;
  nameInputPlaceholder: string;
  keyWordsLabel: string;
  keyWordsPlaceholder: string;
  onEnter: () => void;
  watch: UseFormWatch<ConfigureFileStepFormValues>;
  item?: Partial<ConfigureFileStepFormValues>;
  errors?: FieldErrors<ConfigureFileStepFormValues>;
}

export const ConfigureFileStep: React.FC<ConfigureFileProps> = ({
  control,
  toggleExpiryLabel,
  toggleExpiryCheckText,
  expiryDatePickerLabel,
  nameInputLabel,
  nameInputPlaceholder,
  keyWordsLabel,
  keyWordsPlaceholder,
  onEnter,
  watch,
  errors,
  item,
}) => {
  const hasExpiryDate = watch("hasExpiryDate");

  const dateToString = (onChange: any) => (date: Date) => {
    onChange(date.toISOString());
  };

  return (
    <form onSubmit={onEnter}>
      <FormElement
        required={!!item?.expiryDate}
        control={control}
        id="hasExpiryDate"
        label={toggleExpiryLabel}
      >
        {({ value, onChange, ...field }) => {
          return (
            <Toggle {...field} checked={value} onChange={onChange} label={toggleExpiryCheckText} />
          );
        }}
      </FormElement>

      {hasExpiryDate && (
        <FormElement
          required={!!item?.expiryDate}
          id="expiryDate"
          control={control}
          label={expiryDatePickerLabel}
        >
          {({ value, onChange }, { error }) => {
            const parsedValue = parseISO(value);
            return (
              <DatePicker
                error={error}
                onChange={dateToString(onChange)}
                selected={isValid(parsedValue) ? parsedValue : undefined}
              />
            );
          }}
        </FormElement>
      )}

      <FormElement
        required
        id="name"
        control={control}
        label={nameInputLabel}
        errorMessage={errors?.name?.message}
      >
        {(field) => (
          <Input
            {...field}
            value={field.value || ""}
            size="lg"
            placeholder={nameInputPlaceholder}
          />
        )}
      </FormElement>
      <FormElement
        required
        id="keywords"
        control={control}
        label={keyWordsLabel}
        errorMessage={errors?.keywords?.message}
      >
        {(field) => <Textarea {...field} placeholder={keyWordsPlaceholder} />}
      </FormElement>
    </form>
  );
};

export default ConfigureFileStep;
