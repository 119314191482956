import { Route } from "react-router-dom";
import { AppLevelSection } from "@eolas-medical/core";

import { BaseAppRoute } from "Utilities/types";
import { Checklists, ChecklistSection, ChecklistSubmission } from ".";

export type UserChecklistRoute = "checklists" | "checklistSection" | "checklistDetails";

const baseRouteSlug = `/${BaseAppRoute.spaces}/:spaceName/${AppLevelSection.checklists}`;

const userChecklistRoutes: Record<UserChecklistRoute, string> = {
  checklists: baseRouteSlug,
  checklistSection: `${baseRouteSlug}/:sectionID`,
  checklistDetails: `${baseRouteSlug}/:sectionID/details/:checklistID`,
};

export const ChecklistsRoutes = () => {
  return (
    <>
      <Route exact path={userChecklistRoutes.checklists} component={Checklists} />

      <Route exact path={userChecklistRoutes.checklistSection} component={ChecklistSection} />

      <Route exact path={userChecklistRoutes.checklistDetails} component={ChecklistSubmission} />
    </>
  );
};
