import React from "react";
import OtpInput from "react-otp-input";

type OneTimePasswordInputProps = {
  numInputs?: number;
  otpStyle?: React.CSSProperties;
  inputClassName?: string;
  otp: string;
  onOTPChange: (otp: string) => void;
  shouldAutoFocus?: boolean;
  "data-testid"?: string;
};

export const OneTimePasswordInput = ({
  numInputs = 6,
  otpStyle = { margin: "4px", width: "" },
  inputClassName = "border w-10 h-12 w rounded-md bg-transparent font-semibold text-xl border-grey-500 focus:border-grey-600 focus:text-grey-600 text-grey-500 sm:w-12 md:h-16",
  otp,
  onOTPChange,
  shouldAutoFocus = true,
  "data-testid": dataTestId,
}: OneTimePasswordInputProps) => {
  return (
    <OtpInput
      shouldAutoFocus={shouldAutoFocus}
      value={otp}
      onChange={onOTPChange}
      numInputs={numInputs}
      inputStyle={otpStyle}
      renderInput={(props) => (
        <input {...props} data-testid={dataTestId} className={inputClassName} />
      )}
    />
  );
};
