import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useRouteMatch } from "react-router-dom";

import {
  AnalyticsEvents,
  ProfileVerificationStatus,
  jobTitleOptions,
  seniorityOptions,
  specialtyOptions,
  userStore,
} from "@eolas-medical/core";

import {
  ChangePasswordIcon,
  DeleteUserIcon,
  EmailIcon,
  ProfileVerificationIcon,
  UserProfileIcon,
} from "Assets";
import { NavButton, PageTitle, InnerPageWrapper, Modal, InformationModal } from "UIKit";

import { EditEmailsModal, ChangePasswordModal } from "./components";
import ProfileActionCard from "Pages/MeTab/pages/MyProfile/components/ProfileActionCard/ProfileActionCard";
import useUpdateUserProfile from "Pages/MeTab/pages/MyProfile/hooks/useUpdateUserProfile";
import useUpdateUserPhoto from "Pages/MeTab/pages/MyProfile/hooks/useUpdateUserPhoto";
import DeleteAccountModal from "./components/DeleteAccountModal";
import useDeleteUserAccount from "Hooks/useDeleteUserAccount";
import { logout } from "Pages/Authentication/auth.actions";
import { trackEvent } from "API/Analytics";
import EditProfileModal from "./components/EditProfileModal/EditProfileModal";
import ProfileVerificationModal from "./components/ProfileVerificationModal/ProfileVerificationModal";
import { observer } from "mobx-react-lite";
import { useShowProfileVerification } from "Hooks/useShowProfileVerification/useShowProfileVerification";
import VerificationValue from "./components/ProfileActionCard/VerificationValue/VerificationValue";
import ProfileValue from "./components/ProfileActionCard/ProfileValue/ProfileValue";

type PersonalDetailsModalState =
  | "editProfile"
  | "changePassword"
  | "changeEmail"
  | "deleteAccount"
  | "confirmDeletion"
  | "profileVerification"
  | "sendVerification"
  | null;

export const PersonalDetails = observer(() => {
  const {
    email,
    id,
    jobTitle,
    seniority,
    specialty,
    photoUrl,
    profileVerification,
    aboutMe,
    location,
  } = userStore.userData;

  const { state } = useLocation<{ openEditProfile?: boolean }>();

  const { url } = useRouteMatch();

  const { userData } = userStore;
  const { t } = useTranslation();

  const { updateUserPhoto, updatingUserPhoto } = useUpdateUserPhoto(id || "");

  const { showProfileVerification } = useShowProfileVerification();

  const { deleteUserAccount, deletingUserAccount } = useDeleteUserAccount({
    onSuccess: () => {
      if (id) trackEvent(AnalyticsEvents.PROFILE_ACCOUNT_DELETED, { userId: id });
    },
  });

  const { updateUser, updatingUser } = useUpdateUserProfile({
    onSuccess: (user) => {
      if (id) {
        userStore.updateData({ user });
        setModalState(null);
        trackEvent(AnalyticsEvents.PROFILE_DETAILS_UPDATED, { userId: id });
      }
    },
  });

  const [modalState, setModalState] = useState<PersonalDetailsModalState>(null);

  const handleCloseModal = useCallback(() => {
    if (modalState === "confirmDeletion") logout();
    setModalState(null);
  }, [modalState]);

  const handleUpdateUserProfile = useCallback(
    (userData, file) => {
      if (file.blob) updateUserPhoto({ userId: id, ...file });
      updateUser(id as string, { ...userData });
    },
    [id, updateUserPhoto, updateUser],
  );

  const handleDeleteAccount = useCallback(
    (deletionReason) => {
      deleteUserAccount(
        { queryParams: { email, deletionReason } },
        {
          onSuccess: () => {
            setModalState("confirmDeletion");
            if (id) trackEvent(AnalyticsEvents.PROFILE_ACCOUNT_DELETED, { userId: id });
          },
        },
      );
    },
    [deleteUserAccount, email, id],
  );

  const preloadedSpecialties = useMemo(() => {
    return specialtyOptions.filter((option) => {
      return specialty?.some((spec: string) => {
        return spec === option.value;
      });
    });
  }, [specialty]);

  const preloadedJobTitle = useMemo(() => {
    return jobTitleOptions.find((option) => option.value === jobTitle);
  }, [jobTitle]);

  const preloadedSeniority = useMemo(() => {
    return seniorityOptions.find((option) => option.value === seniority);
  }, [seniority]);

  const renderVerificationValue = () => {
    if (showProfileVerification) {
      return <VerificationValue profileVerification={profileVerification} />;
    }
  };

  useEffect(() => {
    if (id) {
      trackEvent(AnalyticsEvents.PROFILE_PAGE_VISITED, { userId: id });
    }
  }, [id]);

  useEffect(() => {
    if (state?.openEditProfile) {
      setModalState("editProfile");
    }
  }, [state]);

  return (
    <InnerPageWrapper>
      <PageTitle title={t("my-medical_dashboard_personal_details")} />
      <NavButton to={url.replace("/personal-details", "")} />
      <div className="flex flex-col divide-y divide-grey-300 gap-y-4">
        <ProfileActionCard
          title={`${userData.givenName} ${userData.familyName}`}
          leftIcon={UserProfileIcon}
          imageIcon={photoUrl || ""}
          status={
            showProfileVerification
              ? profileVerification?.status || ProfileVerificationStatus.UNVERIFIED
              : undefined
          }
          onClick={() => setModalState("editProfile")}
          renderValueSection={() => (
            <ProfileValue
              seniority={seniority || ""}
              aboutMe={aboutMe}
              specialty={specialty || []}
              jobTitle={jobTitle || ""}
              location={location}
            />
          )}
        />
        {showProfileVerification ? (
          <ProfileActionCard
            title={t("Profile Verification")}
            leftIcon={ProfileVerificationIcon}
            onClick={() => setModalState("profileVerification")}
            renderValueSection={() => renderVerificationValue()}
          />
        ) : null}

        <ProfileActionCard
          title={t("my-medical_personal_details_change_password_label")}
          value={t("my-medical_personal_details_change_password_subtitle")}
          leftIcon={ChangePasswordIcon}
          onClick={() => setModalState("changePassword")}
        />

        <ProfileActionCard
          title={t("my-medical_personal_details_emails_label")}
          value={email as string}
          leftIcon={EmailIcon}
          onClick={() => setModalState("changeEmail")}
        />

        <ProfileActionCard
          title={t("my-medical_personal_details_delete_account_label")}
          value={t("my-medical_personal_details_delete_account_subtitle")}
          leftIcon={DeleteUserIcon}
          onClick={() => setModalState("deleteAccount")}
          action="view"
        />
      </div>
      <Modal open={modalState !== null} onClose={handleCloseModal}>
        {modalState === "editProfile" && (
          <EditProfileModal
            onClose={handleCloseModal}
            onSubmit={handleUpdateUserProfile}
            isLoading={updatingUser || updatingUserPhoto}
            defaultJobTitle={preloadedJobTitle}
            defaultSeniority={preloadedSeniority}
            defaultSpecialties={preloadedSpecialties}
          />
        )}
        {modalState === "profileVerification" && (
          <ProfileVerificationModal
            onClose={handleCloseModal}
            profileVerification={profileVerification}
          />
        )}
        {modalState === "changePassword" && <ChangePasswordModal closeModal={handleCloseModal} />}
        {modalState === "changeEmail" && <EditEmailsModal closeModal={handleCloseModal} />}
        {modalState === "deleteAccount" && (
          <DeleteAccountModal
            titleText={t("my-medical_personal_details_delete_account_title")}
            subtitleText={t("my-medical_personal_details_delete_account_description")}
            confirmText={t("my-medical_personal_details_delete_account_confirm")}
            cancelText={t("my-medical_personal_details_delete_account_cancel")}
            placeholderText={t("my-medical_personal_details_delete_account_placeholder")}
            onSubmit={handleDeleteAccount}
            onClose={handleCloseModal}
            isLoading={deletingUserAccount}
          />
        )}
        {modalState === "confirmDeletion" && (
          <InformationModal
            iconType="DeleteUserIcon"
            description={t("my-medical_personal_details_confirm_delete_account_title")}
            doneLabel={t("my-medical_personal_details_confirm_delete_account_understand")}
            onDone={() => logout()}
          />
        )}
      </Modal>
    </InnerPageWrapper>
  );
});
