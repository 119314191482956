import { MutableRefObject } from "react";
import { ChecklistRecord, MasterSearchFile, sectionStore } from "@eolas-medical/core";
import { KnowledgeResult, LocalResult, MeResult, SearchResult } from "./types";

export const makeChecklistMasterSearchFile = ({
  checklist,
}: {
  checklist: ChecklistRecord;
}): MasterSearchFile => {
  let checklistTemplate = checklist;

  const { updatedAt, mainSectionID, highlighted: isHighlighted, ownerID, parentID } =
    sectionStore.getSection(checklistTemplate.id!) ?? {};
  return {
    id: checklistTemplate.id,
    name: checklistTemplate.checklistName,
    searchField: `checklist ${checklistTemplate.checklistName}`,
    singleChecklist: checklistTemplate,
    updatedAt,
    mainSectionID,
    highlighted: isHighlighted,
    ownerID,
    parentID: parentID!,
  };
};

export const getResultsPosition = (dropdownRef: MutableRefObject<HTMLDivElement | null>) => {
  const searchboxOffset = dropdownRef.current?.getBoundingClientRect();

  if (searchboxOffset) {
    const top = searchboxOffset.top + searchboxOffset.height - 20;
    const left = searchboxOffset.left;
    const width = searchboxOffset.width;

    return { top, left, width };
  }

  return { top: 0, left: 0, width: 100 };
};

export const isLocalResult = (result: SearchResult): result is LocalResult => {
  return (result as LocalResult).file !== undefined;
};

export const isKnowledgeResult = (result: SearchResult): result is KnowledgeResult => {
  return (result as KnowledgeResult).knowledgeFile !== undefined;
};

export const isMeResult = (result: SearchResult): result is MeResult => {
  return (result as MeResult).myFile !== undefined || (result as MeResult).event !== undefined;
};
