import { useForm } from "react-hook-form";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import SpaceName from "./SpaceName";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";
import useValidateSpaceName from "modules/spaces/data/useValidateSpace";

interface useSpaceNameStepProps {
  isLoading?: boolean;
  error?: string;
  onSubmit: ({ name }: { name: string }) => void;
  onEnter: () => void;
}

export const useSpaceNameStep = ({
  isLoading,
  error,
  onSubmit,
  onEnter,
}: useSpaceNameStepProps): Step<"name"> => {
  const { t } = useTranslation();

  const { validateSpace } = useValidateSpaceName();

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const { control, handleSubmit, formState, getValues, setError } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitSpaceName = async () => {
    const spaceName = getValues("name");

    const { name: spaceNameIsValid } = await validateSpace({ spaceName });
    if (!spaceNameIsValid) {
      setError("name", { message: t("space_name_exists_error") });
      trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_ALREADY_EXISTS);
    } else {
      trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_NAME_COMPLETED);
      onSubmit({ name: spaceName });
    }
  };

  return {
    name: "name",
    title: t("space_create_general_title"),
    component: (
      <SpaceName
        control={control}
        autoFocus
        onSubmit={onEnter}
        isNameValid={!formState.errors.name && formState.dirtyFields.name}
      />
    ),
    onSubmit: handleSubmit(handleSubmitSpaceName),
    canProceed: formState.isSubmitSuccessful,
    isLoading: formState.isSubmitting,
    backLabel: t("general_cancel"),
  };
};
