import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { AddButton, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";

import { AddDepartmentAreaModal, DepartmentAreaList, DepartmentTourPicker } from "../components";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const TheDepartment: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  const { isAdmin } = useSpacesContext();
  const { spaceSection } = useSpaceSection(AppLevelSection.theDepartment);

  const { t } = useTranslation();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const onCloseModal = () => setModal(null);

  const onAddDepartmentArea = () => {
    setModal(<AddDepartmentAreaModal onCloseModal={onCloseModal} />);
  };

  const onEditDepartmentArea = (departmentArea: EolasFile) => {
    setModal(
      <AddDepartmentAreaModal departmentArea={departmentArea} onCloseModal={onCloseModal} />,
    );
  };

  useMainSectionVisit({ mainSectionId: AppLevelSection.theDepartment });

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t(AppLevelSection.theDepartment)} />
        <NavButton to={url.replace(`/${AppLevelSection.theDepartment}`, "")} />

        <DepartmentTourPicker />

        <div className="flex flex-col border-t border-b border-grey space-y-4 py-4">
          <h2 className="text-center">{t("theDepartment_department_areas")}</h2>
          {isAdmin && (
            <AddButton className="w-full sm:w-1/2 xl:w-1/3" onClick={onAddDepartmentArea}>
              {t("theDepartment_add_new_area_button")}
            </AddButton>
          )}
        </div>

        <DepartmentAreaList onEdit={onEditDepartmentArea} />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});
