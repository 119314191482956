import { useCallback, useState } from "react";
import { Control, Controller, useController } from "react-hook-form";
import { Modal } from "UIKit";
import { AttachmentItem, PortfolioComplexComponent } from "modules/portfolio/types";
import AttachEvidenceModal from "Pages/MeTab/pages/Portfolio/components/AttachEvidenceModal/AttachEvidenceModal";
import PortfolioAttachmentList from "./PortfolioAttachmentList";
import PortfolioAttachmentButton from "./PortfolioAttachmentButton";
import useFormElementConfig from "Pages/MeTab/pages/Portfolio/hooks/useFormElementConfig";

interface AttachmentsFormData {
  [key: string]: AttachmentItem[];
}
interface PortfolioAttachmentsProps {
  fieldName: string;
  control: Control<AttachmentsFormData>;
  onDownload?: (attachment: AttachmentItem) => void;
  onClickAttachment?: (attachment: AttachmentItem) => void;
}

export const PortfolioAttachments = ({
  fieldName,
  control,
  onDownload = () => {},
  onClickAttachment = () => {},
}: PortfolioAttachmentsProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { getFormElementConfig } = useFormElementConfig();
  const { text } = getFormElementConfig({ componentName: PortfolioComplexComponent.ATTACH_BUTTON });

  const { field } = useController({
    name: fieldName,
    control,
    rules: { required: true },
  });

  const handleOpen = () => setOpen(true);

  const handleAttachEvidence = (attachment: AttachmentItem) => {
    field.onChange([attachment, ...field.value]);
    setOpen(false);
  };

  const handleRemove = (attachmentIndex: any) => {
    const actualValues = [...field.value];
    actualValues.splice(attachmentIndex, 1);
    field.onChange(actualValues);
  };

  const handleDownload = useCallback(
    (attachment: AttachmentItem) => {
      onDownload(attachment);
    },
    [onDownload],
  );

  const renderAttachmentsList = (items: AttachmentItem[]) => {
    return (
      <div className="flex flex-col bg-white border px-3 border-grey-400 rounded-lg items-center divide-y divide-grey-300">
        <PortfolioAttachmentList
          attachments={items}
          onDownload={handleDownload}
          onRemove={handleRemove}
          onClick={onClickAttachment}
        />
        <PortfolioAttachmentButton label={text} onClick={handleOpen} />
      </div>
    );
  };

  return (
    <div data-testid="portfolio-attachment-element">
      <Modal open={open} onClose={() => setOpen(false)}>
        <AttachEvidenceModal attachedFiles={field.value} onAttachEvidence={handleAttachEvidence} />
      </Modal>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { value } }) => {
          return <div data-testid="attachments-list">{renderAttachmentsList(value)}</div>;
        }}
      />
    </div>
  );
};
