import { sectionStore } from "@eolas-medical/core";
import { ContentSection, ContentRepositoryId } from "../types";
import { useMemo } from "react";
import { mapToContentSection } from "../helpers";

interface UseContentSections {
  contentSections: ContentSection[];
  contentSectionsLoading: boolean;
  contentSectionsError?: string;
}

const useContentSections = (contentRepositoryId: ContentRepositoryId): UseContentSections => {
  const mainSectionOrder = sectionStore.getChildrenOrder(contentRepositoryId);

  const contentSections = useMemo(() => {
    return mainSectionOrder.map(mapToContentSection);
  }, [mainSectionOrder]);

  return {
    contentSections,
    contentSectionsLoading: false,
  };
};

export default useContentSections;
