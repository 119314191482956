import { accessClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import spacesKeys from "./spaces.queryKeys";

const useGetAccessToken = (spaceId: string) => {
  const { data, isLoading } = useQuery(spacesKeys.spaceAccessToken(spaceId), () =>
    accessClient.getDepartmentAccessToken(spaceId),
  );

  const accessToken = data ? data.accessTokens[0]?.id : undefined;

  return {
    accessToken,
    gettingAccessToken: isLoading,
  };
};

export default useGetAccessToken;
