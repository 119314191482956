import { DeleteModal } from "UIKit";
import { EolasFile } from "Utilities/types";
import { useRequestStatus } from "Utilities";

import { useFileOperations } from "Hooks";
import { errorStore } from "Stores/ErrorStore";

export interface DeleteMajorIncidentModalProps {
  file: EolasFile;
  onCloseModal(): void;
}

export const DeleteMajorIncidentModal: React.FC<DeleteMajorIncidentModalProps> = ({
  file,
  onCloseModal,
}) => {
  const { removeFile } = useFileOperations();
  const { isLoading, isSuccessful, error, setRequestStatus } = useRequestStatus();

  const onDelete = async () => {
    setRequestStatus({ status: "pending", error: "" });
    try {
      await removeFile({
        variables: {
          id: file.id,
          parentID: file.parentID,
        },
      });
      setRequestStatus({ status: "success", error: "" });
    } catch (error: any) {
      const errorMessage = errorStore.captureError({
        error,
        source: "user",
        retryCallback: removeFile,
        retryParameters: {
          variables: {
            id: file.id,
            parentID: file.parentID,
          },
        },
      });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  };

  return (
    <DeleteModal
      error={error}
      onDelete={onDelete}
      isLoading={isLoading}
      isSuccessful={isSuccessful}
      onCloseModal={onCloseModal}
      title={file.name as string}
      description="deleteModal_delete_majorIncident_description"
    />
  );
};
