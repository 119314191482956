import { useMemo } from "react";
import { MyFile, FilesBySection, FileSection } from "modules/myFiles/types";
import { filterFilesBySection } from "Pages/MeTab/pages/MyFiles/helpers";
import { DEFAULT_SECTION_ID } from "modules/myFiles/constants";
import { useTranslation } from "react-i18next";

interface UseSectionListItemsProps {
  searchInput: string;
  filesBySection: FilesBySection[];
  filesList: MyFile[];
  searchResult: MyFile[];
  sectionsList: FileSection[];
}

const useSectionListItems = ({
  searchInput,
  filesBySection,
  filesList,
  sectionsList,
  searchResult,
}: UseSectionListItemsProps) => {
  const { t } = useTranslation();
  const items: FileSection[] | FilesBySection[] = useMemo(() => {
    if (searchInput.length > 0) {
      return filterFilesBySection(filesBySection, searchResult);
    }

    const defaultSection: FileSection = {
      id: DEFAULT_SECTION_ID,
      name: t("my_files_default_section"),
      icon: "FolderIcon",
      iconUrl: "FolderIcon",
      isDefault: true,
      isDragDisabled: true,
      fileCount: filesList.filter(({ sectionId }) => !sectionId).length,
    };

    const sections = sectionsList.map((sec) => ({
      ...sec,
      fileCount: filesList.filter(({ sectionId }) => sectionId === sec.id).length,
    }));

    return [defaultSection, ...sections] as FileSection[];
  }, [t, searchInput, filesList, searchResult, sectionsList, filesBySection]);

  return items;
};

export default useSectionListItems;
