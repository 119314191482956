import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch, useHistory } from "react-router-dom";
import { InnerPageWrapper, PageTitle, NavButton, AddButton } from "UIKit";
import { FileList } from "Pages/MeTab/pages/MyFiles/pages/Files/components";
import { SectionFilesRouteParams } from "modules/myFiles/types";
import { AddMyFileModal } from "./components/AddMyFileModal";
import { DEFAULT_SECTION_ID } from "modules/myFiles/constants";

const Files = () => {
  const { t } = useTranslation();
  const { url, params } = useRouteMatch<SectionFilesRouteParams>();
  const { replace } = useHistory();

  const [showModal, setShowModal] = useState(false);
  const { sectionId } = params;
  const backUrl = url.replace(`/${sectionId}`, "");

  const handleAddSection = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <AddMyFileModal
        handleClose={handleCloseModal}
        open={showModal}
        sectionId={sectionId !== DEFAULT_SECTION_ID ? sectionId : undefined}
      />
      <InnerPageWrapper>
        <div className="flex flex-col justify-center">
          <PageTitle className="my-4" title={"My Files"} />
          <NavButton onClick={() => replace(backUrl)} />
        </div>

        <AddButton onClick={handleAddSection} data-testid="add-file">
          {t("Add File")}
        </AddButton>

        <FileList />
      </InnerPageWrapper>
    </>
  );
};

export default Files;
