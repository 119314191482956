import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { EolasFileType } from "Utilities/types";
import { ListOrderByOption } from "Components/EolasFileList/ListOrderBy";

type FileContentRoute =
  | "rotas"
  | "emergencyActionCards"
  | "admissionGuides"
  | "importantLinks"
  | "surveys";

export type DynamicPageOrderFn = (a: EolasFile, b: EolasFile) => any;

export type DynamicPageSectionData = {
  isDraggable?: boolean;
  withKeywords?: boolean;
  orderBy?: ListOrderByOption;
  mainSectionID: AppLevelSection;
  allowedFileTypes?: EolasFileType[];
  displayMode?: "list" | "grid";
};

export const adminDynamicPageData: Record<FileContentRoute, DynamicPageSectionData> = {
  rotas: {
    isDraggable: true,
    displayMode: "list",
    allowedFileTypes: ["pdf", "link", "ms-office"],
    mainSectionID: AppLevelSection.rotas,
  },
  emergencyActionCards: {
    isDraggable: true,
    displayMode: "grid",
    allowedFileTypes: ["pdf", "ms-office", "link"],
    mainSectionID: AppLevelSection.emergencyActionCards,
  },
  admissionGuides: {
    isDraggable: true,
    withKeywords: true,
    displayMode: "list",
    allowedFileTypes: ["pdf", "ms-office"],
    mainSectionID: AppLevelSection.admissionGuides,
  },
  importantLinks: {
    isDraggable: true,
    displayMode: "list",
    allowedFileTypes: ["link"],
    mainSectionID: AppLevelSection.importantLinks,
  },
  surveys: {
    isDraggable: true,
    displayMode: "list",
    allowedFileTypes: ["link"],
    mainSectionID: AppLevelSection.surveys,
  },
};

export const clientDynamicPageData: Record<FileContentRoute, DynamicPageSectionData> = {
  surveys: { mainSectionID: AppLevelSection.surveys, displayMode: "list" },
  importantLinks: { mainSectionID: AppLevelSection.importantLinks, displayMode: "list" },
  admissionGuides: { mainSectionID: AppLevelSection.admissionGuides, displayMode: "list" },
  rotas: { mainSectionID: AppLevelSection.rotas, orderBy: "recent", displayMode: "list" },
  emergencyActionCards: {
    displayMode: "grid",
    mainSectionID: AppLevelSection.emergencyActionCards,
  },
};
