import { MainSectionTile } from "UIKit";
import { MainSection, MainSectionIdentityLabel } from "modules/mainSections/types";
import { Variants, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";

interface MainSectionListProps {
  mainSectionList: MainSection[];
}

const childrenVariants: Variants = {
  out: { x: -50, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

const MainSectionList: React.FC<MainSectionListProps> = ({
  mainSectionList,
}: MainSectionListProps) => {
  const { getIconComponent } = useIconComponent();

  const { pathname } = useLocation();

  const getSectionRoute = (mainSection: MainSection) => {
    switch (mainSection.identity) {
      case "miniApp": {
        return `${pathname}/${mainSection.iconName}`;
      }
      case "genericContentRepository": {
        return `${pathname}/content-repository/${encodeURIComponent(mainSection.routeId)}`;
      }
      default:
        return `${pathname}/${mainSection.iconName}`;
    }
  };

  return (
    <>
      {mainSectionList.map((mainSectionListItem: MainSection) => {
        const {
          id,
          iconUrl,
          name,
          description,
          hasIssues,
          disabled,
          identity,
        } = mainSectionListItem;

        const link = getSectionRoute(mainSectionListItem);

        return (
          <motion.div
            variants={childrenVariants}
            whileHover={{ scale: 1.01 }}
            className="mt-4 flex flex-col divide-y divide-grey-light"
            key={id}
          >
            <MainSectionTile
              icon={iconUrl && getIconComponent(iconUrl)}
              title={name || ""}
              description={description || ""}
              tagLabel={MainSectionIdentityLabel[identity ?? "genericContentRepository"]}
              attention={hasIssues}
              disabled={disabled}
              to={link}
              data-testid={`space-main-section-${id}`}
            />
          </motion.div>
        );
      })}
    </>
  );
};

export default MainSectionList;
