import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthFunctionNames } from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";
import { BaseAppRoute } from "Utilities/types";
import { MailIcon, PadlockIcon } from "Assets";
import { errorStore } from "Stores/ErrorStore";
import { Button, FormElement, Input, FormError, Title, ModalBody } from "UIKit";
import { login } from "Pages/Authentication/auth.actions";

interface DifferentUserStepProps {
  email: string;
  invitationID: string;
}

const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required().label("Email"),
  password: yup.string().required().label("Password"),
});

const useLoginAndAcceptStep = (email: string, invitationID: string) => {
  const { push } = useHistory();
  const { isLoading, setRequestStatus, error } = useRequestStatus();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: { email, password: "" },
  });

  const onSubmit = handleSubmit(async (values) => {
    setRequestStatus({ status: "pending", error: "" });
    try {
      await login(values);
      setRequestStatus({ status: "success", error: "" });
      push(`/${BaseAppRoute.spaces}`);
    } catch (error: any) {
      const errorMessage = errorStore.captureError({
        error,
        source: "user",
        authFunctionName: AuthFunctionNames.login,
      });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  });

  return {
    error,
    control,
    isLoading,
    onSubmit,
  };
};

export const LoginAndAcceptStep: React.FC<DifferentUserStepProps> = ({ email, invitationID }) => {
  const { t } = useTranslation();
  const { isLoading, error, control, onSubmit } = useLoginAndAcceptStep(email, invitationID);

  return (
    <ModalBody>
      <Title className="text-center mb-8" level={6}>
        Please login to accept the invitation
      </Title>

      <form onSubmit={onSubmit} className="flex flex-col space-y-2">
        <FormElement id="email" control={control} className="mb-4" label="Email">
          {(field) => (
            <Input
              {...field}
              size="lg"
              disabled
              iconLeft={<MailIcon />}
              placeholder={t("login_enterEmail")}
              data-testid="sign-in-username-input"
            />
          )}
        </FormElement>

        <FormElement id="password" control={control} className="mb-4" label="Password">
          {(field) => (
            <Input
              {...field}
              size="lg"
              type="password"
              iconLeft={<PadlockIcon />}
              data-testid="sign-in-password-input"
              placeholder={t("login_enterPassword")}
            />
          )}
        </FormElement>

        <Button
          size="lg"
          type="submit"
          isLoading={isLoading}
          data-testid="accept-invitation-login"
          className="self-center w-full md:w-1/2"
        >
          Login & Accept
        </Button>

        <FormError error={error} />
      </form>
    </ModalBody>
  );
};
