import { Space, spacesClient } from "@eolas-medical/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { changeAccessType } from "../helpers";
import spacesKeys from "./spaces.queryKeys";

interface UpdateSpaceAccessTypeProps {
  space: Space;
  isPublic: boolean;
}

const useUpdateSpaceAccessType = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ({ isPublic, space }: UpdateSpaceAccessTypeProps) =>
      spacesClient.updateSpace(space.id, changeAccessType(isPublic)),
    {
      onSuccess: (space: Space) => {
        queryClient.refetchQueries(spacesKeys.detail(space.id));
      },
      onError: (err, variables, context: any) => {
        if (context && context.previousSpace) {
          queryClient.setQueryData(
            spacesKeys.detail(context.previousSpace.id),
            context.previousSpace,
          );
        }
      },
    },
  );

  return {
    updateSpaceAccessType: mutate,
    updatingSpaceAccessType: isLoading,
    errorUpdateSpaceAccessType: error,
  };
};

export default useUpdateSpaceAccessType;
