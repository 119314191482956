import localforage from "localforage";
import { handleApiErrors, handleAuthErrors, AuthFunctionNames } from "@eolas-medical/core";

import { logout } from "Pages/Authentication/auth.actions";
import { isDev } from "Utilities/helpers";
import { Sentry } from "Contexts";

type sourceType = "user" | "network";

type CaptureErrorParams = {
  error: Error | string;
  source: sourceType;
  retryCallback?: (args?: any) => any;
  retryParameters?: any;
  authFunctionName?: AuthFunctionNames;
};

// Errors from our graphql api:
export const apiErrorsToIgnore = [
  "File already seen!",
  "An account with the given email already exists.",
  "Bad verification code!",
  "Connection closed",
  "Subscription timeout",
  "Connection handshake error",
  "subscriptionFailedCallback",
];

export const sessionExpired = () => {
  Sentry.addBreadcrumb({
    category: "Sentry",
    message: "Forced logout on networkError 401 - auto logout",
  });

  // TODO: required to relog users after the new auth change

  localforage.clear().catch();
  sessionStorage.clear();

  logout();
};

class ErrorStore {
  public captureError = ({
    error,
    source,
    retryCallback,
    retryParameters,
    authFunctionName,
  }: CaptureErrorParams) => {
    if (!error) {
      return;
    }

    if (retryCallback) {
      Sentry.addBreadcrumb({
        category: "Function Name",
        message: retryCallback.name || retryCallback.toString(),
      });
    }
    if (retryParameters) {
      Sentry.addBreadcrumb({
        category: "Function Params",
        message: retryParameters,
      });
    }

    const { sendErrorToSentry, errorMessageForDisplay } = authFunctionName
      ? handleAuthErrors(error, authFunctionName)
      : handleApiErrors(error);

    if (isDev()) {
      console.log(
        sendErrorToSentry
          ? "Error (will be sent to sentry):"
          : "Error (should not be sent to sentry):",
        error,
      );
    }

    if (sendErrorToSentry) {
      Sentry.captureException(error, { extra: { source } });
    }

    return errorMessageForDisplay;
  };
}

export const errorStore = new ErrorStore();
