import { Route } from "react-router-dom";

import { KnowledgeRoute } from "./types";
import { BaseAppRoute, CalculatorSections } from "Utilities/types";
import { Knowledge } from "./Knowledge";

import NationalGuidelines from "./pages/NationalGuidelines/NationalGuidelines";
import NationalGuidelinesBody from "./pages/NationalGuidelines/pages/NationalGuidelinesBody";
import ClinicalCalculatorsBody from "./pages/ClinicalCalculators/pages/ClinicalCalculatorsBody";
import ClinicalCalculators from "./pages/ClinicalCalculators/ClinicalCalculators";
import PatientLeaflets from "./pages/CommunityPatientLeaflets/PatientLeaflets";
import PatientLeafletsBody from "./pages/CommunityPatientLeaflets/pages/PatientLeafletsBody";
import {
  BodyWeightCalculator,
  CreatinineClearanceCalculator,
  GentamicinCalculator,
} from "./pages/ClinicalCalculators/pages";

export const knowledgeRoutes: Record<KnowledgeRoute, string> = {
  nationalGuidelines: `/${BaseAppRoute.knowledge}/nationalGuidelines`,
  nationalGuidelinesBody: `/${BaseAppRoute.knowledge}/nationalGuidelines/:body`,
  clinicalCalculators: `/${BaseAppRoute.knowledge}/clinicalCalculators`,
  clinicalCalculatorsBody: `/${BaseAppRoute.knowledge}/clinicalCalculators/:body`,
  bodyWeightCalculator: `/${BaseAppRoute.knowledge}/clinicalCalculators/eolas_calculator/${CalculatorSections.bodyWeightCalculator}/:id`,
  creatinineCalculator: `/${BaseAppRoute.knowledge}/clinicalCalculators/eolas_calculator/${CalculatorSections.creatinineCalculator}/:id`,
  gentamicinCalculator: `/${BaseAppRoute.knowledge}/clinicalCalculators/eolas_calculator/${CalculatorSections.gentamicinCalculator}/:id`,
  communityPatientLeaflets: `/${BaseAppRoute.knowledge}/communityPatientLeaflets`,
  communityPatientLeafletsBody: `/${BaseAppRoute.knowledge}/communityPatientLeaflets/:body`,
};

export const KnowledgeRoutes = () => {
  return (
    <>
      <Route exact path={`/${BaseAppRoute.knowledge}`} component={Knowledge} />

      <Route exact path={knowledgeRoutes.nationalGuidelines} component={NationalGuidelines} />

      <Route
        exact
        path={knowledgeRoutes.nationalGuidelinesBody}
        component={NationalGuidelinesBody}
      />

      <Route exact path={knowledgeRoutes.clinicalCalculators} component={ClinicalCalculators} />

      <Route
        exact
        path={knowledgeRoutes.clinicalCalculatorsBody}
        component={ClinicalCalculatorsBody}
      />

      <Route
        exact
        component={CreatinineClearanceCalculator}
        path={knowledgeRoutes.creatinineCalculator}
      />
      <Route exact component={BodyWeightCalculator} path={knowledgeRoutes.bodyWeightCalculator} />
      <Route exact component={GentamicinCalculator} path={knowledgeRoutes.gentamicinCalculator} />

      <Route exact path={knowledgeRoutes.communityPatientLeaflets} component={PatientLeaflets} />

      <Route
        exact
        path={knowledgeRoutes.communityPatientLeafletsBody}
        component={PatientLeafletsBody}
      />
    </>
  );
};
