import { useState, useCallback } from "react";
import { EolasFileType } from "Utilities/types";
import { ConfigureFileValues, LinkFormValues, TitleAndDescriptionValues } from "UIKit/Wizard";
import { AddItemModalState, AddContentItemWizardStep } from "../types";
import { isFile, isLink } from "Pages/Spaces/helpers";
import useAddContentItem from "modules/contentRepository/data/useAddContentItem";

interface UseAddContentItemProps {
  subSectionId: string;
  mainSectionId: string;
  onClose: () => void;
}

export const useAddContentItemActions = ({
  subSectionId,
  mainSectionId,
  onClose,
}: UseAddContentItemProps) => {
  const [wizardState, setWizardState] = useState<AddItemModalState>({
    selectedType: "blob",
  });

  const {
    addContentItem,
    addingContentItem,
    addContentItemSuccess,
    addContentItemError,
    addContentItemProgress,
  } = useAddContentItem(subSectionId || mainSectionId);

  const handleSelectFileType = (type: EolasFileType) => {
    setWizardState((prevState) => ({
      ...prevState,
      selectedType: type,
    }));
  };

  const handleSubmitFileType = (type: EolasFileType, result?: File | LinkFormValues) => {
    if (type === "blob" && isFile(result)) {
      setWizardState((prevState) => ({
        ...prevState,
        selectedType: type,
        file: {
          ...prevState?.file,
          blob: result,
        },
      }));
    }

    if (type === "link" && isLink(result)) {
      setWizardState((prevState) => ({
        ...prevState,
        selectedType: type,
        link: {
          name: result.name,
          url: result.url,
        },
      }));

      addContentItem(
        {
          contentItemDto: {
            name: result.name,
          },
          itemType: "link",
          linkUrl: result.url,
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    }
  };

  const handleAddFileDetails = useCallback(
    (data: ConfigureFileValues) => {
      setWizardState((prevState) => ({
        ...prevState,
        file: {
          ...prevState.file,
          name: data.name,
          expiryDate: data.expiryDate,
          keywords: data.keywords,
        },
      }));

      addContentItem({
        contentItemDto: {
          name: data.name,
          expiryDate: data.expiryDate,
          description: data.keywords,
        },
        itemType: "blob",
        file: wizardState.file?.blob,
      });
    },
    [addContentItem, wizardState],
  );

  const handleAddFlashcardDetails = (data: TitleAndDescriptionValues) => {
    setWizardState((prevState) => {
      return {
        ...prevState,
        flashcard: {
          ...data,
        },
      };
    });
  };

  const handleAddFlashcardContent = (content: string) => {
    setWizardState((prevState) => ({
      ...prevState,
      flashcard: {
        ...prevState.flashcard,
        content,
      },
    }));
  };

  const handleSubmitFlashcardImageStep = async ({
    image,
    imageUrl,
  }: {
    image?: File;
    imageUrl?: string;
  }) => {
    setWizardState((prevState) => ({
      ...prevState,
      blob: image,
      heroImageUrl: imageUrl || "",
    }));
    addContentItem({
      contentItemDto: {
        name: wizardState.flashcard?.title!,
        description: wizardState.flashcard?.description,
        flashcardContent: wizardState.flashcard?.content,
        imageUrl: imageUrl,
      },
      itemType: "flashcard",
      file: image,
    });
  };

  const getNextStep = (currentStep: AddContentItemWizardStep) => {
    if (currentStep === "upload-item" && wizardState.selectedType === "blob") {
      return "add-item-details";
    }

    if (currentStep === "add-item-details" && wizardState.selectedType === "blob") {
      return "upload-progress";
    }

    if (currentStep === "upload-item" && wizardState.selectedType === "flashcard") {
      return "flashcard-title-and-description";
    }

    if (
      currentStep === "flashcard-title-and-description" &&
      wizardState.selectedType === "flashcard"
    ) {
      return "flashcard-creator";
    }

    if (currentStep === "flashcard-creator" && wizardState.selectedType === "flashcard") {
      return "flashcard-hero-image";
    }

    if (currentStep === "flashcard-hero-image" && wizardState.selectedType === "flashcard") {
      return "upload-progress";
    }

    return "upload-item";
  };

  const getPrevStep = (currentStep: AddContentItemWizardStep) => {
    if (currentStep === "add-item-details" && wizardState.selectedType === "blob") {
      return "upload-item";
    }

    if (
      currentStep === "flashcard-title-and-description" &&
      wizardState.selectedType === "flashcard"
    ) {
      return "upload-item";
    }

    if (currentStep === "upload-progress" && wizardState.selectedType === "blob") {
      return "add-item-details";
    }

    if (currentStep === "flashcard-creator" && wizardState.selectedType === "flashcard") {
      return "flashcard-title-and-description";
    }

    if (currentStep === "flashcard-hero-image" && wizardState.selectedType === "flashcard") {
      return "flashcard-creator";
    }

    return "upload-item";
  };

  return {
    wizardState,
    addContentItemError,
    addingContentItem,
    addContentItemSuccess,
    addContentItemProgress,
    getNextStep,
    getPrevStep,
    onSelectType: handleSelectFileType,
    onSubmitFileType: handleSubmitFileType,
    onAddFileDetails: handleAddFileDetails,
    onAddFlashcardDetails: handleAddFlashcardDetails,
    onAddFlashcardContent: handleAddFlashcardContent,
    onSubmitFlashcardImage: handleSubmitFlashcardImageStep,
  };
};
