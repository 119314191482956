import { get } from "lodash";
import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { EolasFileType } from "Utilities/types";
import {
  PDFIcon,
  PPTIcon,
  MP4Icon,
  WordIcon,
  ExcelIcon,
  DomainIcon,
  AddFileIcon,
  MSOfficeIcon,
  AddFileCheckIcon,
} from "Assets";

import { theme } from "../theme";
import { Button } from "../Button";
import { Text } from "../Typography";
import { InputTypeButton } from "../InputTypeButton";
import { Input, FormLabel, FormFieldError } from "../FormElements";

export interface FilePickerProps {
  error?: string;
  value?: string;
  required?: boolean;
  isDisabled?: boolean;
  fileType?: EolasFileType;
  allowedFileTypes?: EolasFileType[];
  onChange(value: File | string): void;
  onSelectType?(fileType: EolasFileType): void;
}

export const FilePicker: React.FC<FilePickerProps> = ({
  error,
  value,
  onChange,
  required,
  isDisabled,
  fileType = "mp4",
  onSelectType = () => {},
  allowedFileTypes = ["mp4", "pdf", "link", "ms-office"],
}) => {
  const { t } = useTranslation();

  const accept = useMemo(() => {
    switch (fileType) {
      case "ms-office":
        return [".xls", ".xlsx", ".doc", ".docx", ".ppt", ".pptx"];
      case "mp4":
        return [".mp4", ".mov"];
      case "pdf":
        return `.${fileType}`;
      default:
        return undefined;
    }
  }, [fileType]);

  const canUploadFile = useMemo(() => {
    return ["ms-office", "mp4", "pdf"].includes(fileType);
  }, [fileType]);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: accept,
    onDrop: (files) => {
      onChange(files[0]);
    },
  });

  const dropzoneStyle = useMemo(() => {
    return isDragActive ? { backgroundColor: get(theme, "colors.grey.hover") } : {};
  }, [isDragActive]);

  const withPDF = allowedFileTypes.includes("pdf");
  const withMP4 = allowedFileTypes.includes("mp4");
  const withLink = allowedFileTypes.includes("link");
  const withMSOffice = allowedFileTypes.includes("ms-office");

  return (
    <div className="flex flex-col space-y-2">
      {allowedFileTypes.length > 1 && (
        <div className="flex flex-col space-y-2 pb-4">
          <FormLabel htmlFor="" required={required}>
            {t("filePicker_select_type_label")}
          </FormLabel>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {withPDF && (
              <InputTypeButton
                isDisabled={isDisabled}
                isSelected={fileType === "pdf"}
                icon={<PDFIcon width={32} height={32} />}
                onClick={() => {
                  fileType === "pdf" ? onSelectType("") : onSelectType("pdf");
                }}
              >
                <Text level={1}>{t("filePicker_pdf")}</Text>
              </InputTypeButton>
            )}

            {withMP4 && (
              <InputTypeButton
                isDisabled={isDisabled}
                isSelected={fileType === "mp4"}
                icon={<MP4Icon width={32} height={32} />}
                onClick={() => {
                  fileType === "mp4" ? onSelectType("") : onSelectType("mp4");
                }}
              >
                <Text level={1}>{t("filePicker_mp4")}</Text>
              </InputTypeButton>
            )}

            {withMSOffice && (
              <InputTypeButton
                isDisabled={isDisabled}
                isSelected={fileType === "ms-office"}
                icon={<MSOfficeIcon width={32} height={32} />}
                onClick={() => {
                  onSelectType("ms-office");
                }}
              >
                <div className="flex flex-col items-start">
                  <Text level={1}>{t("filePicker_ms_office_files")}</Text>
                  <div className="flex xl:items-center space-x-1">
                    <WordIcon className="h-4 w-4 hidden xl:block" />
                    <Text level={3} className="text-grey-700 pr-1">
                      {t("filePicker_word")}
                    </Text>

                    <ExcelIcon className="h-4 w-4 hidden xl:block" />
                    <Text level={3} className="text-grey-700 pr-1">
                      {t("filePicker_excel")}
                    </Text>

                    <PPTIcon className="h-4 w-4 hidden xl:block" />
                    <Text level={3} className="text-grey-700">
                      {t("filePicker_ppt")}
                    </Text>
                  </div>
                </div>
              </InputTypeButton>
            )}

            {withLink && (
              <InputTypeButton
                isDisabled={isDisabled}
                isSelected={fileType === "link"}
                icon={<DomainIcon width={32} height={32} />}
                onClick={() => {
                  fileType === "link" ? onSelectType("") : onSelectType("link");
                }}
              >
                <Text level={1}>{t("filePicker_link")}</Text>
              </InputTypeButton>
            )}
          </div>
        </div>
      )}

      {fileType === "link" && (
        <div className="flex flex-col space-y-2">
          <FormLabel htmlFor="url-input" required={required}>
            {t("filePicker_url_label")}
          </FormLabel>
          <Input
            size="lg"
            id="url-input"
            value={value}
            disabled={isDisabled}
            placeholder={t("filePicker_url_placeholder")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value);
            }}
            className={error ? "border-red-focus input-error" : "input"}
          />
        </div>
      )}

      {canUploadFile && (
        <div className="flex flex-col space-y-2">
          <FormLabel htmlFor="" required={required}>
            {t("filePicker_file_to_upload_label")}
          </FormLabel>

          <div
            className={`
                flex flex-col justify-center items-center rounded-md h-56 space-y-4 px-4
                ${isDisabled && "bg-grey"}
                ${error ? "border-red-focus" : "border-grey-400"}
                ${value ? "bg-white border-solid border-2" : "bg-grey-50 border-2 border-dashed"}
            `}
            {...getRootProps({ style: dropzoneStyle })}
          >
            {value ? (
              <AddFileCheckIcon className="fill-current" width={40} height={40} />
            ) : (
              <AddFileIcon className="fill-current" width={40} height={40} />
            )}

            {value ? (
              <span className="text-center font-semibold">{value ?? ""}</span>
            ) : (
              <div className="flex flex-col items-center space-y-1">
                <span className="text-grey-600 font-semibold">{t("filePicker_helper_text")}</span>
                <span className="text-grey-600 font-semibold">{t("filePicker_or")}</span>
              </div>
            )}

            <Button
              color="grey"
              variant="outline"
              className="self-center w-full sm:w-min whitespace-nowrap"
            >
              {t(value ? "filePicker_change_file" : "filePicker_upload_file_button")}
            </Button>

            <input {...getInputProps()} data-testid="file-picker-input" />
          </div>
        </div>
      )}

      <FormFieldError>{error}</FormFieldError>
    </div>
  );
};
