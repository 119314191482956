import * as yup from "yup";

export const checklistBuilderSchema = yup.object().shape({
  checklistName: yup.string().required("checklistBuilder_checklist_name_required"),
  checklistSections: yup
    .array()
    .min(1, "checklistBuilder_checklist_sections_required")
    .of(
      yup.object().shape({
        checklistSectionName: yup.string().required("checklistBuilder_section_name_required"),
        sectionItems: yup.array().min(1, "checklistBuilder_section_item_required"),
      }),
    ),
});
