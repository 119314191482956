import React from "react";

export const useTrustedEmails = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const onCloseModal = () => setIsModalOpen(false);
  const onOpenModal = () => setIsModalOpen(true);

  return {
    isModalOpen,
    onOpenModal,
    onCloseModal,
  };
};
