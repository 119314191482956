import React from "react";
import { InformationModal } from "UIKit";
import { useTranslation } from "react-i18next";

type AssistanceSentModalProps = {
  onDone(e: React.MouseEvent<HTMLButtonElement>): void;
};

function AssistanceSentModal({ onDone }: AssistanceSentModalProps) {
  const { t } = useTranslation();
  return (
    <InformationModal
      iconType="GreenTickIcon"
      title={t("user_verification_request_sent")}
      description={t("user_not_received_verification_code_description")}
      doneLabel={t("general_i_understand")}
      onDone={onDone}
    />
  );
}

export default AssistanceSentModal;
