import { useTranslation } from "react-i18next";

import { MP4Icon, PDFIcon, DomainIcon } from "Assets";

import {
  Input,
  Title,
  Button,
  Toggle,
  Textarea,
  FormError,
  ModalBody,
  FilePicker,
  FormElement,
  ProgressBar,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";
import { useIsScrollable, useAllowedFileTypes } from "Hooks";

import { useAddEolasFile } from "../useAddEolasFile";

import { AddItemModalProps } from "../types";

interface AddItemWithExpirationModalProps extends AddItemModalProps {
  expirationType?: "date" | "months";
}

export const AddItemWithExpirationModal: React.FC<AddItemWithExpirationModalProps> = ({
  file,
  onCloseModal,
  subSectionId,
  mainSectionID,
}) => {
  const { t } = useTranslation();
  const allowedFileTypes = useAllowedFileTypes();

  const {
    error,
    control,
    onSubmit,
    progress,
    isLoading,
    formErrors,
    isSuccessful,
    hasExpiryDate,
    filePickerType,
    filePickerValue,
    onSelectFileType,
    onFilePickerChange,
  } = useAddEolasFile({
    subSectionId,
    mainSectionID,
    eolasFile: file,
  });

  const { ref, ScrollHint } = useIsScrollable();

  if (isSuccessful) {
    return <SuccessModal onComplete={onCloseModal} />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        {!file ? (
          <Title level={5}>{t("general_add_file_modal_title")}</Title>
        ) : (
          <div className="flex items-center">
            {file.type === "mp4" && <MP4Icon className="mr-2" height={24} width={24} />}
            {file.type === "pdf" && <PDFIcon className="mr-2" height={24} width={24} />}
            {file.type === "link" && <DomainIcon className="mr-2" height={24} width={24} />}
            <Title level={5}>{t("educationPortal_file_edit_title")}</Title>
          </div>
        )}
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="add-file__modal-body">
        <form onSubmit={onSubmit} className="add-file-form">
          <div className="space-y-2 add-file-form__content max-h-60vh" ref={ref}>
            <FormElement
              control={control}
              id="hasExpiryDate"
              label={t("educationPortal_file_addExpiryCheckbox")}
            >
              {({ value, ...field }) => {
                return (
                  <Toggle
                    checked={value}
                    {...field}
                    label={t(`educationPortal_file_addExpiryCheckbox_text`)}
                  />
                );
              }}
            </FormElement>

            {hasExpiryDate && (
              <FormElement
                required
                id="moreInfo"
                control={control}
                label={t("educationPortal_file_expiryDate_label")}
              >
                {({ onChange, ...field }) => (
                  <Input
                    {...field}
                    size="lg"
                    type="number"
                    disabled={isLoading}
                    placeholder={t("educationPortal_file_expiryDate_placeholder")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(Math.max(1, Number(e.target.value)));
                    }}
                  />
                )}
              </FormElement>
            )}

            <FormElement
              required
              id="name"
              control={control}
              label={t("educationPortal_file_name_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  size="lg"
                  disabled={isLoading}
                  data-testid="fileName-input"
                  placeholder={t("educationPortal_file_name_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              id="description"
              control={control}
              label={t("educationPortal_file_description_label")}
            >
              {(field) => (
                <Textarea
                  {...field}
                  disabled={isLoading}
                  data-testid="fileDescription-input"
                  placeholder={t("educationPortal_file_description_placeholder")}
                />
              )}
            </FormElement>

            <FilePicker
              required
              isDisabled={isLoading}
              value={filePickerValue}
              fileType={filePickerType}
              onChange={onFilePickerChange}
              error={formErrors.key?.message}
              onSelectType={onSelectFileType}
              allowedFileTypes={allowedFileTypes}
            />

            {isLoading && progress > 0 && <ProgressBar progress={progress} />}
          </div>

          <Button
            size="lg"
            type="submit"
            isLoading={isLoading}
            style={{ minHeight: "3rem" }}
            className="add-file__submit-button"
          >
            {t("newsFeed_add_item_modal_button")}
          </Button>

          <FormError error={error} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
