import React from "react";
import { VirtuosoGrid } from "react-virtuoso";
import { useTranslation } from "react-i18next";

import { CaretUpIcon, noFilesFound } from "Assets";

import { IconButton } from "../Button";
import { useScrollToTop } from "./useScrollToTop";
import { LottieWithHeader } from "../LottieWithHeader";

interface VirtualGridProps {
  items: any[];
  emptyListLabel?: string;
  renderItem(item: any): any;
}

export const VirtualGrid: React.FC<VirtualGridProps> = ({ items, emptyListLabel, renderItem }) => {
  const { t } = useTranslation();
  const { scrollToTop, goToTop } = useScrollToTop();

  if (items.length === 0) {
    return (
      <LottieWithHeader
        animationSize="125px"
        animation={noFilesFound}
        lottieOptions={{ loop: true }}
        text={emptyListLabel ?? t("general_no_subsection")}
      />
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-1">
        <VirtuosoGrid
          useWindowScroll
          overscan={{ main: 1800, reverse: 3000 }}
          totalCount={items.length}
          itemContent={(index: number) => {
            return renderItem(items[index]);
          }}
          components={{
            List: ListContainer as any,
          }}
        />
      </div>

      {scrollToTop && (
        <IconButton
          color="blue"
          onClick={goToTop}
          icon={<CaretUpIcon />}
          className="fixed bottom-2 right-2"
        />
      )}
    </div>
  );
};

const ListContainer = React.forwardRef(({ className, ...props }: any, ref) => {
  return (
    <div
      ref={ref}
      className={`
        ${className}
        grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4
      `}
      {...props}
      style={{
        paddingTop: 0,
        paddingBottom: 0,
      }}
    />
  );
});
