import { useForm } from "react-hook-form";
import CreateAccountModal from "./CreateAccountModal";
import { SignUpStep } from "Pages/Authentication/types";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

interface UseCreateAccountStep {
  backDisabled: boolean;
  onSubmit: ({ firstName, surname }: { firstName: string; surname: string }) => void;
  onEnter: () => void;
  onPrev: () => void;
}

const useCreateAccountStep = ({
  backDisabled,
  onSubmit,
  onEnter,
  onPrev,
}: UseCreateAccountStep): Step<SignUpStep> => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    firstName: yup.string().required(t("createAccount_name_required")),
    surname: yup.string().required(t("createAccount_surname_required")),
  });

  const { control, handleSubmit, formState, getValues, clearErrors } = useForm({
    defaultValues: {
      firstName: "",
      surname: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitAccount = async () => {
    const { firstName, surname } = getValues();

    onSubmit({ firstName, surname });
  };

  const handlePrev = () => {
    clearErrors();
    onPrev();
  };

  return {
    name: "create-account",
    title: t("createAccount_title"),
    subtitle: t("createAccount_subtitle"),
    component: (
      <CreateAccountModal control={control} onSubmit={onEnter} errors={formState.errors} />
    ),
    onSubmit: handleSubmit(handleSubmitAccount),
    onPrev: handlePrev,
    canProceed: formState.isSubmitSuccessful,
    isLoading: formState.isSubmitting,
    backDisabled,
  };
};

export default useCreateAccountStep;
