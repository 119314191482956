import React, { useReducer, useEffect, ReactNode } from "react";
import SpacesContext from "./spaces.context";
import { Space, sectionStore } from "@eolas-medical/core";
import useSpace from "../data/useSpace";

interface SpacesState {
  isAdmin: boolean;
  selectedSpaceId: string;
}

type SpacesActionType = "SELECT_SPACE" | "CLEAR_SPACE" | "SWITCH_TO_ADMIN";

type SpacesAction = { type: SpacesActionType; payload?: any };

const spacesReducer = (state: SpacesState, action: SpacesAction): SpacesState => {
  switch (action.type) {
    case "SELECT_SPACE":
      return { ...state, selectedSpaceId: action.payload || "", isAdmin: false };
    case "CLEAR_SPACE":
      return { ...state, selectedSpaceId: "", isAdmin: false };
    case "SWITCH_TO_ADMIN":
      return { ...state, isAdmin: action.payload };
    default:
      return state;
  }
};

export const SpacesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const storedState = localStorage.getItem("spacesContext");
  const initialState: SpacesState = storedState
    ? JSON.parse(storedState)
    : { isAdmin: false, selectedSpaceId: "" };

  const appId = sectionStore.appID;

  const [state, dispatch] = useReducer(spacesReducer, initialState);

  const { space } = useSpace(state.selectedSpaceId);

  const handleSelectSpace = (space: Space) => {
    dispatch({ type: "SELECT_SPACE", payload: space.id });
  };

  const handleClearSpace = () => {
    dispatch({ type: "CLEAR_SPACE" });
  };

  const handleSwitchToAdmin = (isAdmin: boolean) => {
    dispatch({ type: "SWITCH_TO_ADMIN", payload: isAdmin });
  };

  useEffect(() => {
    localStorage.setItem("spacesContext", JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    if (!appId) {
      dispatch({ type: "CLEAR_SPACE" });
    }
  }, [appId]);

  const isInSpace = state.selectedSpaceId !== "";

  const isPublic = space?.accessType === "public";

  return (
    <SpacesContext.Provider
      value={{
        ...state,
        selectedSpace: space,
        isInSpace,
        isPublic,
        onSelectSpace: handleSelectSpace,
        onSwitchToAdmin: handleSwitchToAdmin,
        onClearSpace: handleClearSpace,
      }}
    >
      {children}
    </SpacesContext.Provider>
  );
};
