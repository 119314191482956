import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import envConfig from "env-config";

const useNewAppRedirect = () => {
  const { flags } = useLaunchDarkly();

  const isRedirectActivated = flags[LDFlagNames.NEW_APP_REDIRECT];

  const eolasAppDomain = `${envConfig.REACT_APP_PROTOCOL}://${envConfig.REACT_APP_WEB_DOMAIN}`;
  if (window.origin !== eolasAppDomain && isRedirectActivated) {
    window.location.href = eolasAppDomain;
  }
};

export default useNewAppRedirect;
