import React from "react";
import { Variants, motion } from "framer-motion";
import { overrideTailwindClasses as tw } from "tailwind-override";
import { DragIcon } from "Assets";

const childrenVariants: Variants = {
  out: { x: -25, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

export interface TileContainerProps<F> {
  children: React.ReactNode;
  data?: F;
  "data-testid"?: string;
  className?: string;
  canDrag?: boolean;
  isDragging?: boolean;
  onClick?(e: React.MouseEvent): void;
}

export const TileContainer = <F extends { id: string }>({
  canDrag,
  isDragging,
  className,
  children,
  onClick,
  "data-testid": dataTestId,
}: TileContainerProps<F>) => {
  const wrapperUI = (
    <motion.div
      variants={childrenVariants}
      whileHover={{ scale: 1.01 }}
      className={tw(`
        flex flex-row items-center transition-all bg-white
        space-x-4 space-y-0 rounded-lg p-4 shadow-sm
        ${onClick ? "cursor-pointer" : ""}
        ${isDragging ? "tile-border__dragging" : "tile-border"}
        ${className}
      `)}
      data-testid={dataTestId}
    >
      {canDrag && <DragIcon className="text-grey-500 self-start h-4 w-4" />}
      {children}
    </motion.div>
  );

  return React.cloneElement(wrapperUI, { onClick });
};
