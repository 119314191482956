import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppLevelSection } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { InnerPageWrapper, PageTitle, NavButton } from "UIKit";

import { HighlightsItemList } from "../components";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export interface HighlightsProps extends RouteComponentProps {
  mainSectionID: AppLevelSection;
}

export const Highlights: React.FC<HighlightsProps> = observer(
  ({ mainSectionID, match: { url } }) => {
    useMainSectionVisit({ mainSectionId: mainSectionID });

    const { t } = useTranslation();

    const { spaceSection } = useSpaceSection(mainSectionID);

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection.name ?? t(mainSectionID)} />
          <NavButton to={url.replace(`/${mainSectionID}`, "")} />

          <HighlightsItemList />
        </InnerPageWrapper>
      </>
    );
  },
);
