import { IconButton, theme, SimpleButton } from "UIKit";
import { format } from "date-fns";
import { CloseIcon } from "Assets";
import { dateOnly, localeDateConverter } from "Utilities/helpers";
import { FilterElementArray, FilterObjectType, TimelineEventStatus } from "modules/portfolio/types";
import useTimelineButtonConfig from "./hooks/useTimelineButtonConfig";
import { useMemo } from "react";
import { isFeedbackStatus } from "Pages/MeTab/pages/Portfolio/helpers";
import { useMediaQuery } from "Hooks";

interface FiltersAppliedListProps {
  filterObject: FilterObjectType;
  onUpdateFilters: (filterObject: FilterObjectType) => void;
}

const FiltersAppliedList = ({ filterObject, onUpdateFilters }: FiltersAppliedListProps) => {
  const {
    eventStatusButtonConfig,
    feedbackStatusButtonConfig,
    eventTypeButtonConfig,
  } = useTimelineButtonConfig();

  const media = useMediaQuery();
  const isDesktop = media === "lg" || media === "xl" || media === "2xl";

  const dateFilterArray = useMemo(
    () =>
      filterObject.startDate === dateOnly() && filterObject.endDate === dateOnly()
        ? []
        : [
            isDesktop
              ? format(new Date(filterObject.startDate), "do MMMM yyyy")
              : localeDateConverter(filterObject.startDate),
            isDesktop
              ? format(new Date(filterObject.endDate), "do MMMM yyyy")
              : localeDateConverter(filterObject.endDate),
          ],
    [isDesktop, filterObject],
  );

  return (
    <div className="flex flex-row mt-3 content-center  ">
      <IconButton
        className=" bg-white rounded-full mt-2 flex-shrink-0 "
        icon={<CloseIcon fill="black" className="w-12 h-10 flex-shrink-0" />}
        onClick={() => {
          onUpdateFilters({
            endDate: dateOnly(),
            eventStatus: [],
            eventType: [],
            startDate: dateOnly(),
          });
        }}
      />

      <div className="flex flex-no-wrap overflow-x-scroll scrolling-touch items-start">
        {dateFilterArray.map((item) => {
          return (
            <SimpleButton
              textColor="text-black"
              backgroundColor="bg-white"
              key={item}
              text={item}
              onClick={() => {
                return onUpdateFilters({
                  ...filterObject,
                  endDate: dateOnly(),
                  startDate: dateOnly(),
                });
              }}
            />
          );
        })}
        {filterObject.eventType.map((item) => {
          const badgeText = eventTypeButtonConfig[item].badgeText;

          return (
            <SimpleButton
              textColor="black"
              backgroundColor="bg-white"
              key={badgeText}
              text={badgeText}
              onClick={() => {
                const eventType = filterObject.eventType;
                const typeItem = eventType.find((elementName) => elementName === item);
                let newEventTypeArray: FilterElementArray;
                if (typeItem) {
                  newEventTypeArray = eventType.filter((element) => element !== typeItem);
                } else {
                  newEventTypeArray = [...eventType, item];
                }
                onUpdateFilters({ ...filterObject, eventType: newEventTypeArray });
              }}
            />
          );
        })}
        {filterObject.eventStatus.map((item) => {
          let badgeText;
          let textColor;
          let backgroundColor;

          if (isFeedbackStatus(item)) {
            badgeText = feedbackStatusButtonConfig[item].badgeText;
            textColor = feedbackStatusButtonConfig[item].badgeTextColor;
            backgroundColor = feedbackStatusButtonConfig[item].backgroundColor;
          } else {
            badgeText = eventStatusButtonConfig[item].badgeText;
            textColor = eventStatusButtonConfig[item].badgeTextColor;
            backgroundColor = eventStatusButtonConfig[item].backgroundColor;
          }

          return (
            <SimpleButton
              backgroundColor={backgroundColor}
              key={badgeText}
              text={badgeText}
              textColor={textColor as keyof typeof theme.colors}
              onClick={() => {
                const eventStatus = filterObject.eventStatus;
                const typeItem = eventStatus.find((elementName) => elementName === item);
                let newEventStatusArray: TimelineEventStatus[];
                if (typeItem) {
                  newEventStatusArray = eventStatus.filter((element) => element !== typeItem);
                } else {
                  newEventStatusArray = [...eventStatus, item];
                }
                onUpdateFilters({ ...filterObject, eventStatus: newEventStatusArray });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FiltersAppliedList;
