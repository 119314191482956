import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import { AddFileModal, AttachFileModal, AttachmentTile } from "UIKit";
import { MyFile } from "modules/myFiles/types";
import { useAddFile } from "modules/myFiles/data/useAddFile";
import useAllFilesBySection from "modules/myFiles/data/useAllFilesBySection";
import useAttachableFiles from "./useAttachableFiles";
import { trackEvent } from "API/Analytics";
import { AttachmentItem } from "modules/portfolio/types";

interface AttachEvidenceModalProps {
  attachedFiles: AttachmentItem[];
  onAttachEvidence: (file: MyFile) => void;
}

const AttachEvidenceModal = ({ attachedFiles, onAttachEvidence }: AttachEvidenceModalProps) => {
  const { t } = useTranslation();
  const { userID = "" } = userStore;
  const { filesBySection, filesBySectionLoading } = useAllFilesBySection();
  const {
    addFile,
    uploadProgress,
    addFileSuccessful,
    addFileError,
    isLoading,
    reset,
  } = useAddFile();

  const [isAddingFile, setIsAddingFile] = useState<boolean>();

  const { attachableFiles, onSetSearchInput } = useAttachableFiles({
    attachedFiles: attachedFiles as MyFile[],
    filesBySection,
  });

  const handleAttachEvidence = useCallback(
    (file: MyFile) => {
      onAttachEvidence(file);
    },
    [onAttachEvidence],
  );

  const handleAddFileAndAttach = useCallback(
    (file) => {
      addFile(
        { ...file, userId: userID },
        {
          onSuccess: (newFile) => {
            onAttachEvidence(newFile);
            trackEvent(AnalyticsEvents.NEW_FILE_ADDED_EVENT_CREATION, { fileId: file.id });
          },
        },
      );
    },
    [userID, addFile, onAttachEvidence],
  );

  const handleCloseAddFileModal = useCallback(() => {
    setIsAddingFile(false);
    reset();
  }, [reset]);

  const defaultSectionFileNames = useMemo(() => filesBySection[0]?.files.map(({ name }) => name), [
    filesBySection,
  ]);

  const renderFile = (file: MyFile, isSelected: boolean) => {
    return <AttachmentTile attachment={file} isSelected={isSelected} />;
  };

  const renderModal = () =>
    isAddingFile ? (
      <AddFileModal
        onCloseModal={handleCloseAddFileModal}
        onBlobChange={() => {}}
        onSubmit={handleAddFileAndAttach}
        progress={uploadProgress}
        isSuccessful={addFileSuccessful}
        isLoading={isLoading}
        errorMessage={addFileError}
        existingFileNames={defaultSectionFileNames}
        title={t(`my_files_add_file_title`)}
        pickerFormLabel={t("filePicker_file_to_upload_label")}
        pickerUploadLabel={t("filePicker_upload_file_button")}
        pickerChangeLabel={t("filePicker_change_file")}
        pickerDescriptionText={t("filePicker_helper_text")}
        pickerSubDescriptionText={t("filePicker_or")}
        showDisclaimerText
        disclaimerText={t("filePicker_disclaimer")}
      />
    ) : (
      <AttachFileModal<MyFile>
        attachableFiles={attachableFiles}
        filesLoading={filesBySectionLoading}
        renderFile={renderFile}
        onAttachFile={handleAttachEvidence}
        onAddNewFile={() => setIsAddingFile(true)}
        onSearchInputChange={onSetSearchInput}
      />
    );

  return renderModal();
};

export default AttachEvidenceModal;
