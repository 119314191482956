import { ContentItem } from "modules/contentRepository/types";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "UIKit";

import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

import EditContentItemModal from "../EditContentItemModal/EditContentItemModal";
import DeleteContentItemModal from "../DeleteContentItemModal/DeleteContentItemModal";
import ContentItemTile from "../ContentItemTile/ContentItemTile";
import EditFlashcardModal from "../EditFlashcardItemModal/EditFlashcardItemModal";
import useMarkAsFavorite from "modules/contentRepository/data/useMarkAsFavorite";
import useDownloadContentFile from "modules/contentRepository/data/useDownloadContentFile";

interface ContentItemInstanceProps {
  contentItem: ContentItem;
  isAdmin: boolean;
  "data-testid"?: string;
}

const ContentItemInstance = ({
  contentItem,
  isAdmin,
  "data-testid": dataTestId,
}: ContentItemInstanceProps) => {
  const { flags } = useLaunchDarkly();
  const { t } = useTranslation();
  const { push } = useHistory();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const isMarkFavouriteEnabled = !!flags[LDFlagNames.MY_FAVOURITES];

  const { markAsFavorite, isMarkingAsFavorite } = useMarkAsFavorite();

  const { downloadContentFile } = useDownloadContentFile(contentItem);

  const handleCloseModal = () => {
    setModal(null);
  };

  const handleClickItem = useCallback(() => {
    if (contentItem.type !== "link") {
      push(`/documents/viewer/${encodeURIComponent(contentItem.id)}`);
      return;
    }

    window.open(contentItem.linkUrl, "_blank");
  }, [push, contentItem]);

  const handleEditSection = () => {
    if (contentItem.type === "flashcard") {
      setModal(<EditFlashcardModal flashcard={contentItem} onClose={handleCloseModal} />);
    } else {
      setModal(<EditContentItemModal contentItem={contentItem} onClose={handleCloseModal} />);
    }
  };

  const handleDeleteSection = () => {
    setModal(<DeleteContentItemModal contentItem={contentItem} onClose={handleCloseModal} />);
  };

  const handleClickFavourite = (isFavourite: boolean) => {
    markAsFavorite(contentItem, isFavourite);
  };

  const handleDownloadFile = () => {
    downloadContentFile();
  };

  const isFlashcard = contentItem.type === "flashcard";

  return (
    <>
      <Modal open={!!modal} onClose={handleCloseModal}>
        {modal}
      </Modal>
      <div id={contentItem.id} data-testid="content-section-list-item">
        <ContentItemTile
          id={contentItem.id}
          contentType={contentItem.type}
          title={contentItem.name}
          description={contentItem.type === "flashcard" ? contentItem.description : undefined}
          date={isFlashcard ? contentItem.createdAt : contentItem.expiryDate}
          dateLabel={isFlashcard ? t("general_date") : t("repository_item_expiry")}
          imageUrl={contentItem.imageUrl}
          onClick={handleClickItem}
          onEdit={isAdmin ? handleEditSection : undefined}
          onDelete={isAdmin ? handleDeleteSection : undefined}
          onDownload={handleDownloadFile}
          onClickFavourite={isMarkFavouriteEnabled ? handleClickFavourite : undefined}
          isLoading={isMarkingAsFavorite}
          data-testid={dataTestId}
          isDraggable={isAdmin}
        />
      </div>
    </>
  );
};

export default ContentItemInstance;
