import { parseISO } from "date-fns";
import { NO_EXPIRY_DATE, MajorIncidentSubSection, EolasFileType } from "Utilities/types";

import { DefaultValuesMapper } from "./types";

export const majorIncidentsMapper: DefaultValuesMapper = ({
  eolasFile,
  subSectionId,
  contentType,
}) => {
  return {
    name: eolasFile?.name ?? "",
    description: eolasFile?.description ?? "",
    key: eolasFile?.key ?? "",
    type:
      subSectionId === MajorIncidentSubSection.incidents
        ? ""
        : ((eolasFile?.type ?? contentType ?? "pdf") as EolasFileType),
    info:
      subSectionId === MajorIncidentSubSection.incidents
        ? ({
            access: "",
            hazards: "",
            location: "",
            typeOfIncident: "",
            emergencyServices: "",
            numberOfCasualties: "",
          } as any)
        : eolasFile?.info ?? "",
    moreInfo: eolasFile?.moreInfo ?? "",
    moreInfoTwo: eolasFile?.moreInfoTwo ?? "",
    expiryDate: NO_EXPIRY_DATE,
    //
    selectedFile: null,
    progress: 0,
    hasExpiryDate: false,
  };
};

export const defaultMapper: DefaultValuesMapper = ({ eolasFile, contentType }) => {
  const hasExpiryDate = (eolasFile?.expiryDate &&
    eolasFile?.expiryDate !== NO_EXPIRY_DATE) as boolean;

  return {
    name: eolasFile?.name ?? "",
    description: eolasFile?.description ?? "",
    key: eolasFile?.key ?? "",
    type: (eolasFile?.type ?? contentType ?? "pdf") as EolasFileType,
    info: eolasFile?.info ?? "",
    moreInfo: eolasFile?.moreInfo ?? "",
    moreInfoTwo: eolasFile?.moreInfoTwo ?? "",
    expiryDate: hasExpiryDate ? parseISO(eolasFile?.expiryDate!) : "",
    //
    selectedFile: null,
    progress: 0,
    hasExpiryDate,
  };
};

export const newsItemMapper: DefaultValuesMapper = ({ eolasFile, contentType }) => {
  const hasExpiryDate = (eolasFile?.expiryDate &&
    eolasFile?.expiryDate !== NO_EXPIRY_DATE) as boolean;

  return {
    name: eolasFile?.name ?? "",
    description: eolasFile?.description ?? "",
    key: eolasFile?.key ?? "",
    type: (contentType ?? eolasFile?.type ?? "") as EolasFileType,
    info: eolasFile?.info ?? "",
    moreInfo: eolasFile?.moreInfo ?? "",
    moreInfoTwo: eolasFile?.moreInfoTwo ?? "",
    expiryDate: hasExpiryDate ? parseISO(eolasFile?.expiryDate!) : null,
    //
    selectedFile: null,
    progress: 0,
    hasExpiryDate,
  };
};

export const defaultValuesForItemWithExpirationInMonths: DefaultValuesMapper = ({ eolasFile }) => {
  return {
    description: eolasFile?.description ?? "",
    key: eolasFile?.key ?? "",
    name: eolasFile?.name ?? "",
    type: (eolasFile?.type ?? "pdf") as EolasFileType,
    moreInfo: eolasFile?.moreInfo ?? "",
    moreInfoTwo: eolasFile?.moreInfoTwo ?? "",
    info: eolasFile?.info ?? "",
    expiryDate: null,
    //
    hasExpiryDate: !!eolasFile?.moreInfo,
    progress: 0,
    selectedFile: null,
  };
};

export const hospitalDefaultValuesMapper: DefaultValuesMapper = ({ eolasFile }) => {
  return {
    name: eolasFile?.name ?? "",
    description: eolasFile?.description ?? "",
    key: eolasFile?.key ?? "",
    type: (eolasFile?.type ?? "") as EolasFileType,
    expiryDate: null,
    //
    hasExpiryDate: !!eolasFile?.moreInfo,
    progress: 0,
    selectedFile: null,
    //
    info: !!eolasFile?.info ? JSON.parse(eolasFile.info) : [],
    moreInfo: !!eolasFile?.moreInfo
      ? JSON.parse(eolasFile.moreInfo)
      : {
          coordinates: "",
          lighting: "",
          transfer: "",
        },
    moreInfoTwo: !!eolasFile?.moreInfoTwo ? JSON.parse(eolasFile.moreInfoTwo) : [],
  };
};

export const meetTheTeamDefaulValuesMapper: DefaultValuesMapper = ({ eolasFile }) => {
  const hasExpiryDate = (eolasFile?.expiryDate &&
    eolasFile?.expiryDate !== NO_EXPIRY_DATE) as boolean;

  return {
    name: eolasFile?.name ?? "",
    description: eolasFile?.description ?? "",
    key: eolasFile?.key ?? "",
    type: "" as EolasFileType,
    info: eolasFile?.info ?? "",
    moreInfo: eolasFile?.moreInfo ?? "",
    moreInfoTwo: eolasFile?.moreInfoTwo ?? "",
    expiryDate: hasExpiryDate ? parseISO(eolasFile?.expiryDate!) : NO_EXPIRY_DATE,
    //
    selectedFile: null,
    progress: 0,
    hasExpiryDate,
  };
};

export const contactDefaultValuesMapper: DefaultValuesMapper = ({ eolasFile }) => {
  return {
    description: eolasFile?.description ?? "",
    key: eolasFile?.key ?? "",
    name: eolasFile?.name ?? "",
    type: (eolasFile?.type ?? "") as EolasFileType,
    moreInfo: eolasFile?.moreInfo ?? "",
    moreInfoTwo: eolasFile?.moreInfoTwo ?? "",
    info: eolasFile?.info ?? "",
    expiryDate: null,
    //
    progress: 0,
    selectedFile: null,
    hasExpiryDate: eolasFile?.info ? true : false,
  };
};

export const incidentReportingDefaultValuesMapper: DefaultValuesMapper = ({ eolasFile }) => {
  return {
    name: eolasFile?.name ?? "",
    info: !!eolasFile?.info
      ? JSON.parse(eolasFile.info)
      : {
          date: null,
          location: "",
          details: "",
          immediateActions: "",
          contributingFactors: "",
          critical: false,
        },

    description: "",
    key: "",
    type: "" as EolasFileType,
    moreInfo: "",
    moreInfoTwo: "",
    expiryDate: null,
    //
    progress: 0,
    selectedFile: null,
    hasExpiryDate: false,
  };
};
