import { useTranslation } from "react-i18next";
import { Button, Checkbox, CircleIcon, ModalBody, Title } from "UIKit";

import { useCallback } from "react";

import useSpaceMainSections from "modules/spaces/data/useSpaceMainSections";
import { MainSection } from "modules/mainSections/types";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";

interface Props {
  selectedSections: string[];
  isLoading?: boolean;
  action?: "set" | "update";
  onSubmit: () => void;
  onChange: (sectionId: string, checked: boolean) => void;
}

export const ManageSectionsModal: React.FC<Props> = ({
  selectedSections,
  isLoading,
  action = "set",
  onSubmit,
  onChange,
}) => {
  const { t } = useTranslation();
  const { getIconComponent } = useIconComponent();
  const { activeMainSections } = useSpaceMainSections();

  const renderItem = useCallback(
    (section: MainSection) => {
      return (
        <Checkbox
          key={section.id}
          id={section.id}
          disabled={isLoading}
          checked={selectedSections.includes(section.id)}
          className="border-grey-300 border rounded-md p-3 mb-2"
          onChange={(checked) => onChange(section.id, checked)}
        >
          <CircleIcon
            className="mr-3"
            width="w-8"
            height="h-8"
            icon={section.iconUrl && getIconComponent(section.iconUrl)}
            backgroundColor="grey"
          />
          <span className="text-black text-semibold text-base lg:text-lg">{section.name}</span>
        </Checkbox>
      );
    },
    [onChange, getIconComponent, selectedSections, isLoading],
  );

  return (
    <ModalBody className="space-y-4">
      <div className="flex space-x-2 items-baseline">
        <Title level={7} className="font-bold">
          {t("manageUsers_accessRequest_manage_sections_title", { count: selectedSections.length })}
        </Title>
      </div>

      <div className="max-h-30vh overflow-auto overflow-x-hidden">
        {activeMainSections.map((section) => renderItem(section))}
      </div>

      <div className="flex justify-center w-100">
        <Button
          size="lg"
          variant="solid"
          disabled={isLoading}
          color={"blue"}
          isLoading={isLoading}
          className={`rounded-full`}
          onClick={onSubmit}
        >
          {action === "set"
            ? t("manageUsers_accessRequest_manage_sections_submit")
            : t("manageUsers_accessRequest_manage_sections_update")}
        </Button>
      </div>
    </ModalBody>
  );
};
