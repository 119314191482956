import { uniq } from "lodash";
import { useEffect } from "react";
import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, FormElement, Input } from "UIKit";

const labelStyle = "text-lg font-semibold py-1";
const smallLabelStyles = "text-md font-semibold";

const defaultFacilities: string[] = [
  "hospitalsAdmin_facilities_emergency_dept",
  "hospitalsAdmin_facilities_ICU",
  "hospitalsAdmin_facilities_anaesthetics",
  "hospitalsAdmin_facilities_orthopaedics",
  "hospitalsAdmin_facilities_neurosurgery",
  "hospitalsAdmin_facilities_cardiothoracic",
  "hospitalsAdmin_facilities_primary_pci",
  "hospitalsAdmin_facilities_maxillofacial",
  "hospitalsAdmin_facilities_vascular",
  "hospitalsAdmin_facilities_burns",
  "hospitalsAdmin_facilities_plastics",
  "hospitalsAdmin_facilities_emco-bypass",
  "hospitalsAdmin_facilities_interventional_radiology",
  "hospitalsAdmin_facilities_paediatrics",
  "hospitalsAdmin_facilities_obstetrics-maternity",
  "hospitalsAdmin_facilities_blood",
  "hospitalsAdmin_facilities_247-ct",
];

export interface SelectFacilitiesSectionProps {
  customSections?: string[];
}

export const SelectFacilitiesSection: React.FC<SelectFacilitiesSectionProps> = ({
  customSections = [],
}) => {
  const { t } = useTranslation();

  const { control, setValue, watch, register } = useFormContext();

  const [facility, setFacility] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [facilities, setFacilities] = useState<string[]>(() =>
    uniq([...customSections, ...defaultFacilities]),
  );
  const onShowInput = useCallback(() => {
    setShowInput(true);
  }, [setShowInput]);

  const onSubmit = useCallback(() => {
    setFacilities((s) => [facility, ...s]);
    setShowInput(false);
    setFacility("");
  }, [facility, setFacilities, setShowInput, setFacility]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFacility(e.target.value);
    },
    [setFacility],
  );

  useEffect(() => {
    register("moreInfoTwo");
  }, [register]);

  const selectedFacilities = watch("moreInfoTwo");

  const handleCheckboxChange = useCallback(
    (checkboxFacility: string) => () => {
      if (selectedFacilities.includes(checkboxFacility)) {
        setValue(
          "moreInfoTwo",
          selectedFacilities.filter((facility: string) => facility !== checkboxFacility),
          { shouldValidate: true },
        );
      } else {
        setValue("moreInfoTwo", [...selectedFacilities, checkboxFacility], {
          shouldValidate: true,
        });
      }
    },
    [selectedFacilities, setValue],
  );

  return (
    <>
      <span className={labelStyle}>{t("hospitalsAdmin_facilities_section_title")}</span>

      {showInput ? (
        <>
          <div
            className={`
              flex flex-col items-stretch p-4 space-y-4
              bg-grey-100 rounded-lg shadow-sm
            `}
          >
            <FormElement
              id="facility"
              control={control}
              labelClassName={smallLabelStyles}
              label={t("hospitalsAdmin_facilities_label")}
            >
              {() => (
                <Input
                  id="facility"
                  value={facility}
                  onChange={onChange}
                  className="bg-white"
                  placeholder={t("hospitalsAdmin_facilities_placeholder")}
                />
              )}
            </FormElement>

            {facility && (
              <Button
                color="blue"
                weight="regular"
                variant="outline"
                onClick={onSubmit}
                className="self-center w-full sm:w-1/3"
              >
                {t("hospitalsAdmin_facilities_add_facility_button")}
              </Button>
            )}
          </div>
        </>
      ) : (
        <Button
          color="grey"
          weight="regular"
          variant="outline"
          onClick={onShowInput}
          className="self-center w-full sm:w-1/3"
        >
          {t("hospitalsAdmin_facilities_add_new_facility")}
        </Button>
      )}

      <div
        data-testid="hospitalFacilitiesList"
        className={`
          grid grid-cols-2 xl:grid-cols-3 gap-4 p-4
          bg-grey-100 rounded-lg shadow-sm
        `}
      >
        {facilities.map((facility, index) => {
          const isChecked = selectedFacilities.includes(facility);
          return (
            <Checkbox
              id={facility}
              checked={isChecked}
              key={`${facility}-${index}`}
              onChange={handleCheckboxChange(facility)}
            >
              <p className="ml-2 text-sm lg:text-base">{t(facility)}</p>
            </Checkbox>
          );
        })}
      </div>
    </>
  );
};
