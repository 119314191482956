import { useState } from "react";
import { useTranslation } from "react-i18next";

import { MailSecureIcon } from "Assets/Icons";
import { emailSent } from "Assets/LottieAnimations";
import { InnerModalWrapper, LottieWithHeader, SuccessModal } from "UIKit";

import { VerifyCode } from "./VerifyCode";
import { ResendCodeEnterEmail } from "./ResendCodeEnterEmail";
import { useRegistrationStage } from "../hooks";
import "../styles.css";

export interface VerifyEmailModalProps {
  closeModal(): void;
}

type Steps = "enterEmail" | "verificationAnimation" | "enterCode" | "complete";

export const VerifyEmailModal: React.FC<VerifyEmailModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState();
  const { currentStage, setStage } = useRegistrationStage<Steps>("enterEmail");

  if (currentStage === "complete") {
    return <SuccessModal onComplete={closeModal} text={t("createAccount_verifyEmail_success")} />;
  }

  if (currentStage === "verificationAnimation") {
    return (
      <InnerModalWrapper>
        <LottieWithHeader
          className="py-24"
          animationSize="20%"
          animation={emailSent}
          onComplete={setStage("enterCode")}
          text={t("createAccount_verifyAnimationTitle")}
        />
      </InnerModalWrapper>
    );
  }

  return (
    <InnerModalWrapper>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col flex-1 items-center justify-center lg:bg-grey-light lg:rounded-l-2xl">
          <MailSecureIcon className="mt-4" height={55} width={55} />
        </div>

        {currentStage === "enterEmail" && (
          <ResendCodeEnterEmail
            setUserEmail={setUserEmail}
            setStage={setStage("verificationAnimation")}
          />
        )}

        {currentStage === "enterCode" && (
          <VerifyCode
            userEmail={userEmail}
            onFormSubmit={setStage("complete")}
            onResendCode={setStage("verificationAnimation")}
          />
        )}
      </div>
    </InnerModalWrapper>
  );
};
