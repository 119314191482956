import { profilesClient, UserData } from "@eolas-medical/core";
import { useRequestStatus } from "Utilities";

const useUpdateUserProfile = ({ onSuccess }: { onSuccess: (userData: UserData) => void }) => {
  const { error, setRequestStatus, isSuccessful, isLoading } = useRequestStatus();

  const updateUser = (userId: string, userData: UserData) => {
    setRequestStatus({ status: "pending", error: "" });
    profilesClient
      .updateUserProfile(userId, userData)
      .then((userData) => {
        setRequestStatus({ status: "success", error: "" });
        onSuccess(userData);
      })
      .catch((error) => {
        setRequestStatus({ status: "error", error });
      });
  };

  return {
    updateUser,
    updateUserError: error,
    updatingUser: isLoading,
    updateUserSuccess: isSuccessful,
  };
};

export default useUpdateUserProfile;
