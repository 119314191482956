import { Title, Text } from "UIKit";
import { FormElement } from "modules/portfolio/types";
import { formatEventValue } from "Pages/MeTab/pages/Portfolio/helpers";

interface PortfolioReadOnlyPrimitiveProps {
  element: FormElement;
}

const PortfolioReadOnlyPrimitive = ({ element }: PortfolioReadOnlyPrimitiveProps) => {
  return (
    <>
      <Title level={7} className="mb-2 text-base sm:text-lg">
        {element.componentConfig.title}
      </Title>
      <Text level={1}>{formatEventValue(element)}</Text>
    </>
  );
};

export default PortfolioReadOnlyPrimitive;
