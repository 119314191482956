import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { userStore, AnalyticsEvents, fetchAllData } from "@eolas-medical/core";
import { useCallback, useState, useMemo, useEffect } from "react";
import { fileNameRegex } from "@eolas-medical/core/src/utils/models";

import { trackEvent } from "API/Analytics";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { generateS3FileKey } from "Utilities/general";
import { useFileOperations, useS3FileUpload } from "Hooks";
import { OnPickFileFunction, EolasFileType, LDFlagNames } from "Utilities/types";

import { sectionConfigurations } from "./sectionConfigurations";
import { IAddEolasFileForm, IAddFileConfig, InputMapperResult } from "./types";
import { useLaunchDarkly } from "Contexts";
import { ensureFileType, getFileExtension } from "Utilities/fileHelpers";
import { AxiosProgressEvent } from "axios";

export const useAddEolasFile = (config: IAddFileConfig) => {
  const { publishFile, updateFile } = useFileOperations();
  const { flags } = useLaunchDarkly();
  const isGuidelineExpiryMandatory = flags[LDFlagNames.MAKE_GUIDELINE_EXPIRY_MANDATORY];
  const uploadFile = useS3FileUpload();
  const [step, setStep] = useState(0);

  const { error, setRequestStatus, isSuccessful, isLoading } = useRequestStatus();

  const { validationSchema, defaultValuesMapper, inputMapper } = useMemo(() => {
    const cfg = sectionConfigurations[config.mainSectionID];
    const _schema = cfg.validationSchema({
      user: userStore.userData,
      sectionID: config.subSectionId,
      mainSectionID: config.mainSectionID,
    });

    const validationSchema = Array.isArray(_schema) ? _schema[step] : _schema;

    const defaultValuesMapper = cfg.defaultValuesMapper;

    const inputMapper = cfg.createInputMapper({
      user: userStore.userData,
      sectionID: config.subSectionId,
      mainSectionID: config.mainSectionID,
    });

    return {
      inputMapper,
      validationSchema,
      defaultValuesMapper,
    };
  }, [config.mainSectionID, config.subSectionId, step]);

  const formMethods = useForm<IAddEolasFileForm>({
    mode: "all",
    defaultValues: defaultValuesMapper(config),
    resolver: yupResolver(validationSchema as any),
  });

  const { control, handleSubmit, watch, setValue, setError, formState } = formMethods;
  const { isValid, errors } = formState;

  useEffect(() => {
    if (isGuidelineExpiryMandatory) {
      setValue("hasExpiryDate", isGuidelineExpiryMandatory);
    }
  }, [isGuidelineExpiryMandatory, setValue]);

  const [fileKey, fileType, hasExpiryDate, progress, selectedFile] = watch([
    "key",
    "type",
    "hasExpiryDate",
    "progress",
    "selectedFile",
  ]);

  const setProgress = useCallback(
    (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.progress) {
        setValue("progress", Math.round(progressEvent.progress * 100));
      }
    },
    [setValue],
  );

  const onURLChange = (url: string) => {
    setValue("key", url.trim(), { shouldValidate: true });
  };

  const onPickFile: OnPickFileFunction = useCallback(
    (selectedFile, { isPublic, addExtension }) => {
      if (!selectedFile) {
        setValue("key", "", { shouldValidate: true });
        setValue("selectedFile", null);
        return;
      }

      const s3Key = generateS3FileKey({
        isPublic,
        addExtension,
        fileName: selectedFile.name,
        fileFormat: selectedFile.type,
        mainSectionId: config.mainSectionID,
      });

      setValue("key", s3Key, { shouldValidate: true });
      setValue("selectedFile", selectedFile);
    },
    [config.mainSectionID, setValue],
  );

  const onFilePickerChange = async (value: File | string, isPublic = false) => {
    if (value instanceof File) {
      const ext = getFileExtension(value);
      const isValidFileType = await ensureFileType(value, ext);
      if (!isValidFileType) {
        return setError("key", { message: "error_file_type_does_not_match_extension" });
      }

      if (value.size > 2_000_000_000) {
        return setError("key", { message: "error_file_too_big" });
      }

      if (fileNameRegex.test(value.name)) {
        onPickFile(value, {
          isPublic,
        });
      } else {
        setError("key", { message: "error_invalid_file_name" });
      }
    } else {
      onURLChange(value);
    }
  };

  const onSelectFileType = (fileType: EolasFileType) => {
    setValue("type", fileType, { shouldValidate: true });
    setValue("selectedFile", null, { shouldValidate: true });
    setValue("key", "", { shouldValidate: true });
  };

  const onAddFile = async (payload: InputMapperResult, values: IAddEolasFileForm) => {
    const { afterSubmitFunction } = config;

    try {
      setRequestStatus({ status: "pending", error: "" });

      if (values.selectedFile) {
        await uploadFile(values.key, values.selectedFile, setProgress);
      }

      const {
        data: { publishFile: publishFileResponse },
        errors,
      } = await publishFile({
        variables: payload,
      });

      trackEvent(AnalyticsEvents.FILE_UPLOADED);

      if (typeof afterSubmitFunction === "function") {
        await afterSubmitFunction(publishFileResponse);
      }

      if (errors && errors[0]) {
        const [{ message = "Unexpected error" }] = errors;
        throw new Error(message);
      }
      await fetchAllData();
      setRequestStatus({ status: "success", error: "" });
    } catch (error: any) {
      const errorMessage = errorStore.captureError({
        error,
        source: "user",
        retryCallback: publishFile,
        retryParameters: {
          variables: payload,
        },
      });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  };

  const onEditFile = async (payload: InputMapperResult, values: IAddEolasFileForm) => {
    const { afterSubmitFunction } = config;

    try {
      setRequestStatus({ status: "pending", error: "" });

      if (values.selectedFile) {
        await uploadFile(values.key, values.selectedFile, setProgress);
      }

      const {
        data: { updateFile: updateFileResponse },
        errors,
      } = await updateFile({
        variables: payload,
      });

      if (typeof afterSubmitFunction === "function") {
        await afterSubmitFunction(updateFileResponse);
      }
      if (errors && errors[0]) {
        const [{ message = "Unexpected error" }] = errors;
        setRequestStatus({ status: "error", error: message });
      } else {
        await fetchAllData();
        setRequestStatus({ status: "success", error: "" });
      }
    } catch (error: any) {
      const errorMessage = errorStore.captureError({
        error,
        source: "user",
        retryCallback: updateFile,
        retryParameters: {
          variables: payload,
        },
      });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  };

  const onStepBack = useCallback(() => {
    setStep((step) => Math.max(0, step - 1));
  }, [setStep]);

  const onSubmit = handleSubmit(async (values) => {
    const { eolasFile, maxSteps } = config;

    if (maxSteps) {
      if (step < maxSteps - 1) {
        setStep((step) => step + 1);
      } else {
        const inputMapperResult = inputMapper(values, selectedFile as File);

        if (eolasFile) {
          inputMapperResult.input.id = eolasFile.id;
          await onEditFile(inputMapperResult, values);
        } else {
          await onAddFile(inputMapperResult, values);
        }
      }
    } else {
      const inputMapperResult = inputMapper(values, selectedFile as File);

      if (eolasFile) {
        inputMapperResult.input.id = eolasFile.id;
        await onEditFile(inputMapperResult, values);
      } else {
        await onAddFile(inputMapperResult, values);
      }
    }
  });

  const filePickerValue = useMemo(() => {
    if (!fileKey) return "";

    if (fileType === "mp4" || fileType === "pdf" || fileType === "ms-office") {
      const slashIndex = fileKey.lastIndexOf("/");
      return fileKey.slice(slashIndex + 1);
    }
    return fileKey;
  }, [fileKey, fileType]);

  return {
    step,
    error,
    control,
    onSubmit,
    progress,
    onStepBack,
    onPickFile,
    formMethods,
    hasExpiryDate,
    filePickerValue,
    formErrors: errors,
    onSelectFileType,
    onFilePickerChange,
    isLoading: isLoading,
    isFormComplete: isValid,
    filePickerType: fileType,
    isSuccessful: isSuccessful,
  };
};
