import { useTranslation } from "react-i18next";
import SearchResultItem from "../SearchResultItem/SearchResultItem";
import { SearchResult } from "../../types";
import useSearchResultConfig from "../../hooks/useSearchResultConfig";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";

interface SearchResultListProps {
  results: SearchResult[];
  onClickResult: (result: SearchResult) => void;
}

const SearchResultList = ({ onClickResult, results }: SearchResultListProps) => {
  const { t } = useTranslation();
  const { getSearchResultProps } = useSearchResultConfig();
  const { getIconComponent } = useIconComponent();

  return (
    <div
      className="flex flex-col bg-grey-50 rounded-xl p-2 space-y-2 overflow-y-auto max-h-40vh"
      data-testid="master-search-results-list"
    >
      {results.map((result) => {
        const {
          searchTypeSection,
          mainSectionName,
          pillTextColor,
          pillBgColor,
          color,
          pillIcon,
        } = getSearchResultProps(result);

        const PillIcon = pillIcon;

        const Icon = result.iconName ? getIconComponent(result.iconName) : undefined;

        return (
          <div key={result.id} onClick={() => onClickResult(result)}>
            <SearchResultItem
              icon={Icon}
              title={result.title}
              subtitle={result.subtitle}
              mainSectionName={mainSectionName}
              searchTypeSection={t(searchTypeSection)}
              pillBgColor={`${color}-${pillBgColor}`}
              pillTextColor={`${color}-${pillTextColor}`}
              pillIcon={PillIcon && <PillIcon className={`w-4 h-4 text-${pillTextColor}`} />}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SearchResultList;
