import { userStore } from "@eolas-medical/core";
import { useCallback, useEffect, useState } from "react";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { loginTokenService } from "API/LoginTokenService";

export const useLoginTokens = () => {
  const appID = userStore.appUserData.appID!;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginTokens, setLoginTokens] = useState<string[]>([]);

  const { error, isLoading, requestStatus, setRequestStatus } = useRequestStatus();

  const onOpenModal = () => setIsModalOpen(true);
  const onCloseModal = () => setIsModalOpen(false);

  const createTokenReqStatus = useRequestStatus();

  const fetchLoginTokens = useCallback(async () => {
    setRequestStatus({ status: "pending", error: "" });

    try {
      const { data } = await loginTokenService.listTokens(appID);
      setLoginTokens(data.map((token) => token.id));

      setRequestStatus({ status: "success", error: "" });
    } catch (err: any) {
      errorStore.captureError({ error: err.message, source: "user" });
      setRequestStatus({ status: "error", error: err.message });
    }
  }, [appID, setRequestStatus]);

  useEffect(() => {
    fetchLoginTokens();
  }, [fetchLoginTokens]);

  return {
    error,
    isLoading,
    loginTokens,
    isModalOpen,
    requestStatus,
    createTokenReqStatus,
    onOpenModal,
    onCloseModal,
    setLoginTokens,
  };
};
