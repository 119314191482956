import AccessTypeForm from "Pages/Spaces/components/AccessTypeForm/AccessTypeForm";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useUpdateSpaceAccessType from "modules/spaces/data/useUpdateSpaceAccessType";

const AccessType = () => {
  const { selectedSpace, isPublic } = useSpacesContext();
  const { updateSpaceAccessType } = useUpdateSpaceAccessType();

  const handleChangeAccessType = (isPublic: boolean) => {
    if (selectedSpace) {
      updateSpaceAccessType({
        space: selectedSpace,
        isPublic,
      });
    }
  };

  return <AccessTypeForm defaultIsPublic={isPublic} onChange={handleChangeAccessType} />;
};

export default AccessType;
