import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import OneSignal from "react-onesignal";
import {
  Button,
  FormElement,
  InnerModalWrapper,
  Input,
  ModalBody,
  ModalHeader,
  Title,
} from "UIKit";
import { AssignUserIcon } from "Assets/Icons";
import useValidateAccessToken, {
  ResponseValidateAccessToken,
} from "../../data/useValidateAccessToken";
import { Redirect, useParams } from "react-router";
import { LoginFlow } from "../../components/LoginFlow";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import { useTranslation } from "react-i18next";
import { trackEvent } from "API/Analytics";

export type AccessLinkToken = `${string}-${string}-${string}-${string}`;

interface TokenParams {
  token: AccessLinkToken;
}

type AccessLinkStep = "accessLink" | "login" | "signup";

const AccessLink = () => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    email: yup.string().email("Invalid email address").required("Email is required"),
  });

  const { token } = useParams<TokenParams>();

  const [inviteStep, setInviteStep] = useState<AccessLinkStep | null>("accessLink");
  const [invitationDetails, setInvitationDetails] = useState({
    email: "",
    invitationID: token,
  });

  const {
    validateAccessToken,
    validatingAccessToken,
    validateAccessTokenError,
  } = useValidateAccessToken();

  const { control, handleSubmit, setFocus, register, setError } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitAccess = useCallback(
    ({ email }) => {
      validateAccessToken(
        { email, token },
        {
          onSuccess: ({ userExists }: ResponseValidateAccessToken) => {
            setInvitationDetails((prev) => ({ ...prev, email: email }));
            if (userExists) {
              setInviteStep("login");
            } else {
              setInviteStep("signup");
            }
          },
        },
      );
    },
    [validateAccessToken, token, setInvitationDetails, setInviteStep],
  );

  useEffect(() => {
    userStore.clearStore(); // Clear existing session
    if (token) userStore.setAccessLinkId(token);
    trackEvent(AnalyticsEvents.INVITE_VIA_ACCESS_LINK_STARTED);
    OneSignal.sendTag("startedQuickDepartmentAccessDate", new Date().toISOString());
  }, [token]);

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  useEffect(() => {
    if (validateAccessTokenError) {
      setError("email", {
        message: t("accessLink_error_old_token"),
      });
    }
  }, [validateAccessTokenError, setError, t]);

  if (inviteStep === "login" && invitationDetails) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoginFlow invitation={invitationDetails} />
      </div>
    );
  }
  if (inviteStep === "signup") {
    return (
      <Redirect
        to={{
          pathname: "/signup",
          state: {
            userEmail: invitationDetails.email,
          },
        }}
      />
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <InnerModalWrapper>
        <div className="flex flex-col">
          <ModalHeader className="flex flex-col items-center">
            <Title level={5}>{t("accessLink_title")}</Title>
          </ModalHeader>

          <ModalBody>
            <div className="flex flex-col overflow-y-auto" style={{ maxHeight: "60vh" }}>
              <form onSubmit={handleSubmit(handleSubmitAccess)} className="overflow-y-auto p-2">
                <FormElement required id="email" control={control}>
                  {(fieldProps) => {
                    const { value, ...rest } = fieldProps;
                    return (
                      <Input
                        {...register("email")}
                        {...rest}
                        data-testid="email-input"
                        size="lg"
                        className="input pl-4 sm:h-12 box-content rounded-3xl"
                        iconLeft={
                          <AssignUserIcon
                            className={`h-8 w-8 mr-4 hidden sm:inline text-blue-500`}
                          />
                        }
                        placeholder={t("accessLink_placeholder")}
                      />
                    );
                  }}
                </FormElement>
                <div className="flex justify-center mt-4">
                  <Button
                    isLoading={validatingAccessToken}
                    type="submit"
                    className="w-full"
                    data-testid="done-button"
                  >
                    {t("accessLink_button_confirm")}
                  </Button>
                </div>
              </form>
            </div>
          </ModalBody>
        </div>
      </InnerModalWrapper>
    </div>
  );
};

export default AccessLink;
