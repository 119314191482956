import { Route, Switch, useRouteMatch } from "react-router-dom";
import ContentSections from "./pages/ContentSections/ContentSections";
import ContentItems from "./pages/ContentItems/ContentItems";
import useContentRepository from "modules/contentRepository/data/useContentRepository";
import ManageExpiredItems from "./pages/ManageExpiredItems/ManageExpiredItems";
import useContentRepositoryParams from "./hooks/useContentRepositoryParams";

const ContentRepository = () => {
  const { path } = useRouteMatch();
  const { contentRepositoryId } = useContentRepositoryParams();

  const { contentRepository } = useContentRepository(contentRepositoryId);

  if (contentRepository.childrenType === "section") {
    return (
      <Switch>
        <Route exact path={`${path}`} component={ContentSections} />
        <Route exact path={`${path}/expired-items`} component={ManageExpiredItems} />
        <Route exact path={`${path}/:subSectionId`} component={ContentItems} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path={`${path}`} component={ContentItems} />
      <Route exact path={`${path}/expired-items`} component={ManageExpiredItems} />
    </Switch>
  );
};

export default ContentRepository;
