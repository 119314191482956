import { get } from "lodash";
import { sectionStore } from "@eolas-medical/core";
import { gql, useQuery } from "@apollo/client";

const GetFileAnalytics = gql`
  query GetFileAnalytics($appID: String!, $fileID: String!) {
    getFileAnalytics(appID: $appID, fileID: $fileID) {
      userTokens
      users
      unopenedUsers
      userIds
    }
  }
`;

export const useGetNewsItemStats = (fileID: string) => {
  const { data, loading: isLoading, error } = useQuery(GetFileAnalytics, {
    variables: {
      appID: sectionStore.appID,
      fileID,
    },
  });

  return {
    userIds: get(data, "getFileAnalytics.userIds", []),
    users: get(data, "getFileAnalytics.users", 0),
    unopenedUsers: get(data, "getFileAnalytics.unopenedUsers", 0),
    isLoading,
    error,
  };
};
