import { Step } from "UIKit";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ValidatePasswordModal from "./ValidatePasswordModal";
import { AuthenticationWizard } from "Pages/Authentication/types";

interface UseValidateEmailStepProps {
  error?: string;
  isLoading: boolean;
  email: string;
  backDisabled?: boolean;
  onSubmit: (password: string) => void;
  onEnter: () => void;
  onPrev: () => void;
  onChangeWizard: (wizard: AuthenticationWizard, email: string) => void;
}

const useValidatePasswordStep = ({
  email,
  error,
  isLoading,
  backDisabled,
  onSubmit,
  onEnter,
  onPrev,
  onChangeWizard,
}: UseValidateEmailStepProps): Step<"validate-password"> => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    password: yup.string().required(t("login_requiredPassword")),
  });

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitPassword = async () => {
    const password = getValues("password");

    onSubmit(password);
  };

  const handleChangeToForgotPassword = () => {
    onChangeWizard("forgot-password-wizard", email);
  };

  return {
    name: "validate-password",
    title: t("login_enterPassword_title"),
    subtitle: email,
    component: (
      <ValidatePasswordModal
        control={control}
        error={error}
        onSubmit={onEnter}
        onClickForgotPassword={handleChangeToForgotPassword}
      />
    ),
    onSubmit: handleSubmit(handleSubmitPassword),
    onPrev,
    canProceed: false,
    isLoading,
    submitError: !!error,
    backDisabled,
  };
};

export default useValidatePasswordStep;
