import { EolasRegions, NationalResourceEntityType, searchClient } from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { useQuery } from "@tanstack/react-query";
import communityKeys from "./community.queryKeys";
import { useMemo } from "react";

export interface NationalBodiesState {
  name: string;
  id: string;
}

const useGetNationalBodies = (entityType: NationalResourceEntityType) => {
  const { supportedEolasRegion } = useSupportedRegion();

  const { data, isLoading } = useQuery(communityKeys.bodies(entityType), () =>
    searchClient.searchNationalBodies(entityType, supportedEolasRegion as EolasRegions),
  );

  const filteredNationalBodies = useMemo(() => {
    if (!data) return [];

    return [...data]
      .sort((a, b) => a.localeCompare(b))
      .map((name, index) => ({
        id: index.toString(),
        name,
      }));
  }, [data]);

  return {
    nationalBodies: filteredNationalBodies,
    gettingNationalBodies: isLoading,
  };
};

export default useGetNationalBodies;
