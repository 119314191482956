import { QueryEnvironment, useEolasQuery } from "Hooks";
import { getSuperviseesList } from "../client/portfolio.client";
import { SupervisionSummary } from "../types";
import portfolioKeys from "./portfolio.queryKeys";
import { useMemo } from "react";

interface UseSuperviseesList {
  superviseesList: SupervisionSummary[];
  superviseesRequestList: SupervisionSummary[];
  superviseesListLoading: boolean;
}

const useSuperviseesList = (ownerId: string): UseSuperviseesList => {
  const { isLoading, data, ...rest } = useEolasQuery({
    queryFn: () => getSuperviseesList(ownerId),
    queryKey: portfolioKeys.superviseesList(),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 300000,
      staleTime: 60000,
      enabled: ownerId.length > 0,
    },
  });

  const [superviseesRequestList, superviseesList] = useMemo(() => {
    if (!data) return [[], []];

    const { supervisionRequestsSummary = [], superviseeSummary = [] } = data;

    return [
      supervisionRequestsSummary as SupervisionSummary[],
      superviseeSummary as SupervisionSummary[],
    ];
  }, [data]);

  return {
    superviseesListLoading: isLoading,
    superviseesRequestList,
    superviseesList,
    ...rest,
  };
};

export default useSuperviseesList;
