import { Step } from "./types";

export const getStepIndexFromSteps = <T extends string>(steps: Step<T>[], stepName: T) =>
  steps.findIndex((s) => s.name === stepName);

export const defaultNextStep = <T extends string>(steps: Step<T>[], currentStep: T) => {
  const stepIndex = getStepIndexFromSteps(steps, currentStep);
  const nextStepIndex = stepIndex + 1;

  if (nextStepIndex >= 0 && nextStepIndex < steps.length) {
    return steps[nextStepIndex].name;
  }
};

export const defaultPrevStep = <T extends string>(steps: Step<T>[], currentStep: T) => {
  const stepIndex = getStepIndexFromSteps(steps, currentStep);
  const prevStepIndex = stepIndex - 1;

  if (prevStepIndex >= 0 && prevStepIndex < steps.length) {
    return steps[prevStepIndex].name;
  }
};
