import { useQueryClient, useMutation } from "@tanstack/react-query";
import { submitEventForReview, submitNewEventForReview } from "../client/portfolio.client";
import { mapEventForm } from "../helpers";
import portfolioKeys from "./portfolio.queryKeys";
import { EventDto } from "../types";

interface SubmitEventMutation {
  eventId?: string;
  eventBody: EventDto;
}
const useSubmitEvent = () => {
  const queryClient = useQueryClient();

  const submitEventMutation = async ({ eventId, eventBody }: SubmitEventMutation) => {
    if (eventId) {
      return submitEventForReview({ eventId, eventBody });
    }

    if (!eventId && eventBody) {
      return submitNewEventForReview({ eventBody });
    }
  };

  const { mutate, isLoading, ...rest } = useMutation(submitEventMutation, {
    onSuccess: (submittedEvent) => {
      if (submittedEvent) {
        queryClient.invalidateQueries({
          queryKey: portfolioKeys.lists(),
        });
        queryClient.setQueryData(portfolioKeys.detail(submittedEvent.id), () =>
          mapEventForm(submittedEvent),
        );
      }
    },
  });

  return {
    submitEvent: mutate,
    submitingEvent: isLoading,
    ...rest,
  };
};

export default useSubmitEvent;
