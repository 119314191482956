import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch, useHistory } from "react-router-dom";
import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import { MyFilesConstants } from "../../constants";
import SectionList from "./components/SectionList/SectionList";
import AddFileSectionModal from "./components/AddFileSectionModal/AddFileSectionModal";

const Sections = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { replace } = useHistory();
  const [showModal, setShowModal] = useState(false);
  const backUrl = url.replace(`/${MyFilesConstants.MY_FILES_BASE_URL}`, "");

  const handleAddSection = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal open={!!showModal} onClose={handleCloseModal}>
        <AddFileSectionModal onClose={handleCloseModal} />
      </Modal>
      <InnerPageWrapper>
        <div className="flex flex-col justify-center">
          <PageTitle className="my-4" title="My Files" />
          <NavButton onClick={() => replace(backUrl)} />
        </div>

        <AddButton onClick={handleAddSection} data-testid="add-file-section">
          {t("my_files_add_section")}
        </AddButton>

        <SectionList />
      </InnerPageWrapper>
    </>
  );
};

export default Sections;
