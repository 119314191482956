import { GraphQLError } from "graphql";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useIcons, ClinicalCalculatorsIconType } from "Hooks";
import { useActivateSections } from "Hooks/useSectionActivation";
import { Text, Title, Button, SuccessModal, ModalHeader, InnerModalWrapper } from "UIKit";
import { ChildReference } from "@eolas-medical/core";

export interface ActivateSectionsModalProps {
  onCloseModal(): void;
  onActivate: (id: string) => Promise<{ data: any; errors: readonly GraphQLError[] | undefined }>;
  deactivatedSections: ChildReference[];
}

export const ActivateSectionsModal: React.FC<ActivateSectionsModalProps> = observer(
  ({ deactivatedSections, onCloseModal, onActivate }) => {
    const { t } = useTranslation();

    if (deactivatedSections.length === 0) {
      return <SuccessModal onComplete={onCloseModal} text="contentManagement_allFeaturesActive" />;
    }

    return (
      <InnerModalWrapper>
        <ModalHeader>
          <Title level={5}>{t("contentManagement_activateSection")}</Title>
        </ModalHeader>

        <div className="flex flex-col h-full divide-y divide-y-grey-300 overflow-y-auto">
          {deactivatedSections.map((section) => (
            <DeactivatedSection
              key={section.id}
              section={section}
              handleActivate={onActivate}
              onCloseModal={onCloseModal}
            />
          ))}
        </div>
      </InnerModalWrapper>
    );
  },
);

interface DeactivatedSectionProps {
  onCloseModal(): void;
  handleActivate(input: any): Promise<{ data: any; errors: readonly GraphQLError[] | undefined }>;
  section: ChildReference;
}

const DeactivatedSection: React.FC<DeactivatedSectionProps> = ({
  section,
  handleActivate,
  onCloseModal,
}) => {
  const { t } = useTranslation();
  const { getClinicalCalculatorSectionIcon } = useIcons();

  const { error, isLoading, onActivate, isSuccessful } = useActivateSections({
    updateSectionHandler: handleActivate,
  });

  const Icon = getClinicalCalculatorSectionIcon(section.icon as ClinicalCalculatorsIconType);

  if (isSuccessful) {
    return (
      <SuccessModal onComplete={onCloseModal} text="contentManagement_editFeature_activated" />
    );
  }

  return (
    <div
      key={section.id}
      className="flex flex-col border-b border-grey px-4 lg:px-12 py-6 lg:py-10"
    >
      <div className="grid grid-cols-5 gap-4">
        <div className="flex items-center justify-center">
          {Icon && <Icon className="h-10 w-10 sm:h-16 sm:w-16" />}
        </div>

        <div className="flex flex-col ml-4 col-span-4">
          <Title level={8}>{section.name}</Title>
          <Text level={1} className="font-normal">
            {section.description}
          </Text>
        </div>
      </div>

      <Button
        variant="outline"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={onActivate(section)}
        className="self-center mt-6 sm:mt-8 w-full sm:w-1/2"
      >
        {t("contentManagement_activateSection_button")}
      </Button>

      {error && <span className="text-red text-center mt-4">{error}</span>}
    </div>
  );
};
