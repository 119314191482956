import React from "react";
import useActionButtonConfig from "./useActionButtonConfig";
import { Button, Text } from "UIKit";
import { ActionButtonType } from "modules/portfolio/types";

interface PortfolioActionButtonsProps {
  type: ActionButtonType;
  isLoading: boolean;
  onClick?: () => void;
  testId?: string | undefined;
  errorMessage?: string;
}

const PortfolioActionButton = ({
  type,
  isLoading,
  onClick = () => {},
  testId,
  errorMessage,
}: PortfolioActionButtonsProps) => {
  const actionButtonConfig = useActionButtonConfig();
  if (!actionButtonConfig[type]) return null;

  const {
    icon,
    text,
    variant,
    buttonColor,
    textColor,
    shouldShowErrorMessage,
  } = actionButtonConfig[type];
  const className = "";

  return (
    <React.Fragment>
      {shouldShowErrorMessage && errorMessage ? (
        <Text level={1} className={"text-red-600 font-bold"}>
          {errorMessage}
        </Text>
      ) : null}
      <div className={`${className} justify-center mx-auto mt-4 mb-8`}>
        <Button
          className={`rounded-3xl justify-center mx-auto w-full my-3 ${textColor}`}
          iconLeft={icon ? icon : undefined}
          color={buttonColor}
          variant={variant}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          isLoading={isLoading}
          data-testid={`portfolio-action-button${testId ? testId : ""}`}
        >
          <Text className="mx-3 text-sm sm:text-base" level={1}>
            {text}
          </Text>
        </Button>
      </div>
    </React.Fragment>
  );
};

export default PortfolioActionButton;
