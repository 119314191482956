import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { InnerModalWrapper } from "UIKit";
import { EolasFile } from "Utilities/types";
import { AmbulanceIcon, TickIcon, LightingIcon, MapPinIcon } from "Assets/Icons";

import { IHospitalContact } from "../types";

const iconSizes = { height: 32, width: 32 };

interface HospitalPreviewProps {
  hospital: EolasFile;
}

const sectionClass = "flex flex-col items-stretch py-4 lg:py-6 px-4 lg:px-8 border-b border-grey";

export const HospitalPreviewModal: React.FC<HospitalPreviewProps> = ({ hospital }) => {
  const { t } = useTranslation();

  const { name, address, contacts, landingSiteInfo, facilities } = useMemo(() => {
    return {
      name: hospital.name,
      address: hospital.description,
      contacts: JSON.parse(hospital.info!),
      facilities: JSON.parse(hospital.moreInfoTwo!),
      landingSiteInfo: JSON.parse(hospital.moreInfo!),
    };
  }, [hospital]);

  return (
    <InnerModalWrapper>
      <div className={sectionClass}>
        <span data-testid="hospitalPreviewModal" className="font-semibold text-2xl">
          {name}
        </span>
        <a
          className="flex items-center underline hover:bg-grey-hover rounded-lg p-2 mt-3"
          target="_blank"
          rel="noreferrer"
          href={`https://www.google.com.sa/maps/search/${encodeURIComponent(address!)}`}
        >
          <MapPinIcon {...iconSizes} />
          <span className="ml-4 text-lg">{address}</span>
        </a>
      </div>

      <div className={sectionClass}>
        <span className="font-semibold text-xl">{t("hospitalList_modal_keyContacts")}</span>
        <div className="flex flex-col items-stretch mt-2 divide-y divide-grey-light">
          {contacts.length &&
            contacts.map((item: IHospitalContact) => (
              <div key={`${item.name}${item.number}`} className="flex justify-between py-2">
                <span className="text-lg">{item.name}</span>
                <span className="text-lg">{item.number}</span>
              </div>
            ))}
        </div>
      </div>

      <div className={sectionClass}>
        <span className="font-semibold text-xl mb-2">
          {t("hospitalList_modal_landingSiteInfo")}
        </span>

        <div className="grid grid-cols-1 gap-2 py-4">
          {landingSiteInfo.coordinates && (
            // TODO consolidate link styling / Link component in UIKit
            <a
              className="flex items-center mb-2 underline hover:bg-grey-hover rounded-lg p-2"
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com.sa/maps/search/${encodeURIComponent(
                landingSiteInfo.coordinates,
              )}`}
            >
              <MapPinIcon {...iconSizes} />
              <span className="ml-4 text-lg">{landingSiteInfo.coordinates}</span>
            </a>
          )}
          {landingSiteInfo.lighting && (
            <div className="flex items-center mb-2 p-2">
              <LightingIcon {...iconSizes} />
              <span className="ml-4 text-lg">{landingSiteInfo.lighting}</span>
            </div>
          )}
          {landingSiteInfo.transfer && (
            <div className="flex items-center mb-2 p-2">
              <AmbulanceIcon {...iconSizes} />
              <span className="ml-4 text-lg">{landingSiteInfo.transfer}</span>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col items-stretch py-4 px-4 lg:px-8">
        <span className="font-semibold text-xl">{t("hospitalList_modal_facilities")}</span>

        <div className="grid grid-cols-1 gap-2 lg:grid-cols-3 py-4">
          {facilities.length &&
            facilities.map((item: string) => (
              <div className="flex items-center" key={item}>
                <TickIcon className="text-green" width={20} height={20} />
                <span className="text-lg ml-2">{t(item)}</span>
              </div>
            ))}
        </div>
      </div>
    </InnerModalWrapper>
  );
};
