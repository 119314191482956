import { CheckIcon, defaultSpace } from "Assets";

interface ImageWithCheckProps {
  image: string;
  showCheck: boolean;
  className?: string;
  onClick: () => void;
}

const ImageWithCheck = ({ image, showCheck, className, onClick }: ImageWithCheckProps) => {
  const imageClass = `w-full h-32 cursor-pointer rounded-md border border-grey-300 object-cover ${
    showCheck ? "shadow-md" : ""
  } ${className ? className : ""}`;

  return (
    <div className="relative" onClick={onClick}>
      {image ? (
        <img src={image} alt="space" className={imageClass} />
      ) : (
        <img src={defaultSpace} alt="default-space" className={imageClass} />
      )}
      <div className="absolute top-2 left-2 bg-white w-6 h-6 rounded-full flex items-center justify-center cursor-pointer">
        {showCheck && <CheckIcon className="text-green-500" />}
      </div>
    </div>
  );
};

export default ImageWithCheck;
