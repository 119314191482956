import * as Yup from "yup";
import { eventTypeLabels, FilterFieldNames, FilterObjectType } from "modules/portfolio/types";
import { useEolasForm } from "Hooks/useEolasForm";
import { EventStatus } from "modules/portfolio/types";

const validationSchema: any = {
  startDate: Yup.date().max(
    Yup.ref("endDate"),
    "Your chosen start date must be earlier than your end date",
  ),
  endDate: Yup.date().when("startDate", (startDate) => {
    const endOfToday = new Date(new Date().setUTCHours(23, 59, 59, 998));
    return Yup.date()
      .min(startDate, "Your end date must be after your start date")
      .max(endOfToday, "Your chosen date cannot be in the future.");
  }),
};

const useTimelineFilterForm = (filterObject: FilterObjectType) => {
  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useEolasForm({
    validationSchema: Yup.object().shape(validationSchema),
    defaultValues: {
      [FilterFieldNames.startDate]: filterObject.startDate || null,
      [FilterFieldNames.endDate]: filterObject.endDate || null,
      [FilterFieldNames.eventType]: filterObject.eventType || ([] as typeof eventTypeLabels[]),
      [FilterFieldNames.eventStatus]: filterObject.eventStatus || ([] as EventStatus[]),
    },
    mode: "all",
  });

  return { control, getValues, errors, handleSubmit, trigger };
};

export default useTimelineFilterForm;
