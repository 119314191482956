import { RetryLink } from "@apollo/client/link/retry";
import { SentryLink } from "apollo-link-sentry";
import envConfig from "env-config";
import ApolloLinkTimeout from "apollo-link-timeout-dynamic";
import { authenticationClient, userStore } from "@eolas-medical/core";
import { Sentry } from "Contexts";
import { onError } from "@apollo/client/link/error";
import { errorStore } from "Stores/ErrorStore";
import { logout } from "Pages/Authentication/auth.actions";
import { DefaultOptions } from "@apollo/client";

type AWS_AUTH_TYPE = "AMAZON_COGNITO_USER_POOLS";

interface authConfig {
  url: string;
  region: string;
  auth: {
    type: AWS_AUTH_TYPE;
    jwtToken: () => Promise<string | undefined>;
  };
}

export const apolloClientDefaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
  mutate: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  watchQuery: {
    fetchPolicy: "network-only",
  },
};

export const apolloConfig: authConfig = {
  url: envConfig.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  region: envConfig.REACT_APP_AWS_REGION,
  auth: {
    type: envConfig.REACT_APP_AUTH_TYPE as AWS_AUTH_TYPE,
    jwtToken: async () => {
      const token = await authenticationClient.getToken();
      return `Bearer ${token}`;
    },
  },
};

const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeQuery: true,
    includeError: true,
  },
});

const retryLink = new RetryLink({
  attempts: async (count, operation, error) => {
    if (!userStore.userSession.isLoggedIn) {
      Sentry.addBreadcrumb({
        category: "apollo-client",
        message: "Retry link resolved false",
      });
      return false;
    }

    if ([401, 403].includes(error.statusCode)) {
      const token = await authenticationClient.getToken({ forceRefresh: true });
      return count < 2 && token.length > 0;
    }

    const isRecoverableStatusCode =
      error.statusCode === 429 || error.statusCode < 300 || error.statusCode > 499;
    return count < 3 && isRecoverableStatusCode;
  },
  delay: () => 800 * Math.random() + 500,
});

const timeoutLink = new ApolloLinkTimeout({ timeout: 20000 });

const fatalErrorKeywords = ["not authorized", "unauthorized"];
const relevantQueries = [
  "GetAppUserByID",
  "GetOrganisation",
  "GetApp",
  "FileBySharedIDAndUpdatedAt",
];

let shouldSignUserOutOnFatalError = true;

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  let forwardOperation = true;
  if (graphQLErrors && !networkError) {
    graphQLErrors.forEach(({ message }) => {
      if (userStore?.userSession.isInOrganisation) {
        const isFatalError = fatalErrorKeywords.some((errorKeyword) =>
          message.toLowerCase().includes(errorKeyword),
        );
        const isRelevantQuery = relevantQueries.some((query) => query === operation.operationName);
        if (isFatalError && isRelevantQuery && shouldSignUserOutOnFatalError) {
          Sentry.addBreadcrumb({
            category: "Graphql error",
            message: "Fatal graphql error",
          });
          errorStore.captureError({
            error: `User signed out of org with error: ${message}`,
            source: "network",
          });
          shouldSignUserOutOnFatalError = false;
          logout().then(() => {
            shouldSignUserOutOnFatalError = true;
          });
          forwardOperation = false;
        }
      }
    });
  }
  forwardOperation && forward(operation);
});

export const apolloLinks: any[] = [sentryLink, timeoutLink, errorLink, retryLink];
