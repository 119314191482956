import { useTranslation } from "react-i18next";
import { XIcon, apple, google } from "Assets";
import { appConfig } from "AppTypeConfig";

import { InnerModalWrapper, IconButton, Text } from "UIKit";

export interface DownloadAppsModalProps {
  onCloseModal(): void;
}

export const DownloadAppsModal: React.FC<DownloadAppsModalProps> = ({ onCloseModal }) => {
  const { t } = useTranslation();

  return (
    <InnerModalWrapper>
      <IconButton
        size="sm"
        type="button"
        icon={<XIcon />}
        onClick={onCloseModal}
        variant="rounded"
        data-testid="modal-close-button"
        className="absolute right-4 top-4 z-10"
      />

      <div className="flex flex-col items-center space-y-6 py-12 px-4 sm:px-20">
        <div className="flex flex-col items-center space-y-4">
          <Text level={1} className="text-center">
            {t("videoTour_line_1")}
          </Text>
          <Text level={1} className="text-center">
            {t("videoTour_line_2")} <span className="font-bold">{appConfig.appName}</span>
          </Text>
          <Text level={1} className="text-center">
            {t("videoTour_line_3")}
          </Text>
        </div>

        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 space-x-0 md:space-x-6">
          <a href={appConfig.appleAppStore} target="_blank" rel="noreferrer noopener">
            <img src={apple} className="h-12 rounded-md" alt="app-store" />
          </a>

          <a href={appConfig.googlePlay} target="_blank" rel="noreferrer noopener">
            <img src={google} className="h-12 rounded-md" alt="play-store" />
          </a>
        </div>
      </div>
    </InnerModalWrapper>
  );
};
