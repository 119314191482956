import Fuse from "fuse.js";
import { EolasFileType } from "./types";

export interface INameable {
  name?: string | null;
}

export const sortByName = <T extends INameable>(a: T, b: T) => {
  if (!a.name || !b.name) {
    return 0;
  }
  return a.name.localeCompare(b.name);
};

export interface ICreateable {
  createdAt?: string | null;
}

export const sortByCreatedAt = <T extends ICreateable>(a: T, b: T) => {
  if (!a.createdAt || !b.createdAt) {
    return 0;
  }
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
};

export interface IUpdateable {
  updatedAt?: string | null;
}

export const sortByUpdated = <T extends IUpdateable>(a: T, b: T) => {
  if (!a.updatedAt || !b.updatedAt) {
    return 0;
  }
  return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
};

export interface IFavorable {
  isFavourite: boolean;
}

export const sortByFavourites = <T extends IFavorable>(a: T, b: T) => {
  return b.isFavourite ? 1 : -1;
};

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
};

export const isDev = () => process.env.NODE_ENV === "development";

export const wait = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export const addRetries = <T>(callback: () => Promise<T>, retries = 3, delayInMs = 500) => {
  return () =>
    new Promise<T>((resolve, reject) => {
      (async () => {
        let retriesRemaining = retries;
        while (retriesRemaining > 0) {
          try {
            const result = await callback();
            retriesRemaining = 0;
            resolve(result);
          } catch (error) {
            retriesRemaining--;
            if (!retriesRemaining) {
              reject(error);
              return;
            }
            await wait(delayInMs + 100 * Math.random());
          }
        }
      })();
    });
};

export type Timeout = ReturnType<typeof setTimeout>;

export async function resolvePromiseWithTimeout<T>(asyncFunc: () => Promise<T>, timeout = 15000) {
  let timeoutRef: Timeout | null = null;

  const mainPromise = async () => {
    try {
      const returnValue = await asyncFunc();
      timeoutRef && clearTimeout(timeoutRef);
      return returnValue;
    } catch (error) {
      timeoutRef && clearTimeout(timeoutRef);
      throw error;
    }
  };

  let promises: Promise<any>[];

  promises = [mainPromise()];

  const result = await new Promise(function (fulfil, reject) {
    promises.forEach(function (promise) {
      promise.then(fulfil, reject);
    });
  });

  if (result === "Timeout promise resolved first") {
    throw new Error("Timeout exceeded");
  }

  return result;
}

export const stringifyNumber = (inputNumber: number) =>
  inputNumber < 10 ? `0${inputNumber}` : `${inputNumber}`;

export const deepEquals = (x: any, y: any) => JSON.stringify(x) === JSON.stringify(y);

export const localeDateConverter = (isoStringDate: string | undefined) => {
  if (!isoStringDate) {
    return "";
  }
  return new Date(isoStringDate).toLocaleDateString();
};

export const dateOnly = (inputDate = new Date()) => {
  const year = inputDate.getFullYear();
  const month = inputDate.getMonth();
  const day = inputDate.getDate();

  return new Date(year, month, day).toISOString();
};

export const bytesToMb = (bytes: number) => (bytes / 1024 / 1024).toFixed(1);

export const bytesToKb = (bytes: number) => (bytes / 1024).toFixed(1);

export const formatBytes = (bytes: number) => {
  if (bytes > 1024 * 1024) return `${bytesToMb(bytes)}MB`;

  return `${bytesToKb(bytes)}KB`;
};

export const fileIsImage = (type: string) => {
  return type.startsWith("image/");
};

export const monthLookup = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const reorderList = (items: any[], startIndex: number, endIndex: number): any[] => {
  if (startIndex === endIndex) return items;

  const result = [...items];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Given two list of elements of the same type, it returns a list of elements that are in both
 * @param A List of elements of type T
 * @param B List of elements of type T
 * @returns The elements that are on A and B
 */
export const findIntersection = <T extends { id: string }>(A: T[], B: T[]): T[] =>
  A.filter((a) => B.some((b) => b.id === a.id));

/**
 * Given two list A and B of elements of the same type, it return the elements A
 * that does not exist B.
 * @param A List of elements of type T
 * @param B List of elements of type T
 * @returns The elements that are on A but not on B
 */
export const findElementsNoInList = <T extends { id: string }>(A: T[], B: T[]): T[] =>
  A.filter((obj1) => !B.some((obj2) => obj1.id === obj2.id));

/**
 * Given a list objects of type T and a objectKey,
 * replaces the element in the list with the same objectKey as the new object.
 * @param list The list of objects to modify
 * @param newObj The new object that should replace an existing object in the list
 * @returns A new list with the replaced object
 */
export const replaceObject = <T extends { [key: string]: any }>(
  list: T[],
  newObj: T,
  objectKey: keyof T,
): T[] => {
  const index = list.findIndex((obj: any) => obj[objectKey] === newObj[objectKey]);
  const newList = [...list];
  newList[index] = newObj;
  return newList;
};

/**
 * Removes an object from an array based on a specified object key.
 * @param list The array of objects.
 * @param objectKey The key of the object property to use for filtering.
 * @param value The value of the object property to match for removal.
 * @returns A new array without the object(s) matching the specified property value.
 */
export const removeObjectFromList = <T extends { [key: string]: any }>(
  list: T[],
  objectKey: keyof T,
  value: T[keyof T],
): T[] => list.filter((item) => item[objectKey] !== value);

export const isEmpty = <T>(arr: T[]): boolean => {
  return arr.length === 0;
};

/**
 * Converts a key to title format by capitalizing the first letter of each word and separating them with spaces.
 * @param key The key to convert to title format.
 * @returns The key in title format.
 */
export const toTitleFormat = (key: string): string => {
  // Split the key into words based on uppercase letters
  const words = key.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together with spaces
  return capitalizedWords.join(" ");
};

/**
 * Splits a string by the specified ranges and returns an array of substrings.
 * Each substring is represented by an object with the substring text and a flag
 * indicating if it is part of one of the ranges.
 *
 * @param str - The input string to be split.
 * @param ranges - The numeric ranges to split the string.
 * @returns An array of substrings with corresponding match flags.
 */
export const splitStringByRanges = (
  str: string,
  ranges: readonly Fuse.RangeTuple[],
): { text: string; isMatch: boolean }[] => {
  let substrings: { text: string; isMatch: boolean }[] = [];
  let currentIndex = 0;

  ranges.forEach((range) => {
    const [start, end] = range;

    // Add the characters before the current range
    if (currentIndex < start) {
      substrings.push({
        text: str.slice(currentIndex, start),
        isMatch: false,
      });
    }

    // Add the characters in the current range
    substrings.push({
      text: str.slice(start, end + 1),
      isMatch: true,
    });

    currentIndex = end + 1;
  });

  // Add any remaining characters after the last range
  if (currentIndex < str.length) {
    substrings.push({
      text: str.slice(currentIndex),
      isMatch: false,
    });
  }

  return substrings;
};

/**
 * Converts an object with key/value pairs to an array of arrays,
 * where each inner array contains the key and value.
 *
 * @param obj - The object to convert to an array.
 * @returns An array of arrays with key-value pairs.
 */
export const objectToArray = <T extends Record<string, any>>(obj: T): [keyof T, T[keyof T]][] => {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
};

/**
 * Removes any () and % before encoding. Related to: https://github.com/remix-run/react-router/issues/8300
 * @param inputString
 * @returns
 */
export const removeBracketsAndPercent = (inputString?: string) => {
  if (!inputString) return;
  // Use a regular expression to match and remove '(', ')', and '%' characters
  const stringWithoutBracketsAndPercent = inputString.replace(/[()%]/g, "");

  return stringWithoutBracketsAndPercent;
};

export const DOWNLOADABLE_TYPES: Set<EolasFileType> = new Set([
  "pdf",
  "png",
  "mp4",
  "mov",
  "ms-office",
  "blob",
  "image",
  "video/quicktime",
  "jpeg",
  "jpg",
  "ppt",
  "pptx",
  "doc",
  "docx",
  "xlsx",
  "xls",
  "apng",
  "heic",
]);

export const URL_REGEX = /^(https?|http):\/\/([a-z\d.-]+)(\/[a-z\d.-]+)*(\/?[a-z\d.-]+)?(\?[^\s]*)?(#[^\s]*)?$/i;
