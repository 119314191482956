import { useCallback, useMemo, useState } from "react";
import { sectionStore, fileStore, EolasMainSection, EolasFile } from "@eolas-medical/core";

import { useReorder } from "Hooks";
import { formatDate } from "Utilities/general";

export interface UseEolasFileListParams {
  isAdmin: boolean;
  sectionID?: string;
  mainSectionID: EolasMainSection;
  sortFn?(first: EolasFile, second: EolasFile): number;
  filterFn?(searchInput: string): (file: EolasFile) => boolean;
}

export const useEolasFileList = ({
  sortFn,
  sectionID,
  mainSectionID,
  filterFn = () => () => true,
}: UseEolasFileListParams) => {
  const parentID = sectionID ?? sectionStore.getChildReferenceByMainSectionType(mainSectionID)!.id;

  const { onDragEnd } = useReorder(parentID);

  const mainSectionReference = sectionStore.getChildReferenceByMainSectionType(mainSectionID)!;

  const childrenOrder = sectionStore.getChildrenOrder(sectionID ?? mainSectionReference.id);
  const files = fileStore.getFiles(childrenOrder ?? []);
  const [searchInput, setSearchInput] = useState("");
  const hasNoFiles = files.length === 0;

  const onSearchInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(e.target.value.toLowerCase());
    },
    [setSearchInput],
  );

  const filteredFiles: any[] = useMemo(() => {
    const _filteredFiles = files
      .filter(({ searchField, updatedAt }) => {
        if (searchField === null) return true;

        const searchableDate = formatDate(
          updatedAt as string,
          "PPPP dd.MM.yyyy dd/MM/yyyy",
        ).toLowerCase();
        return (
          searchableDate.includes(searchInput) || searchField?.toLowerCase().includes(searchInput)
        );
      })
      .filter(filterFn(searchInput));

    if (sortFn) {
      _filteredFiles.sort(sortFn);
      return _filteredFiles;
    }

    return _filteredFiles;
  }, [files, filterFn, sortFn, searchInput]);

  return {
    onDragEnd,
    hasNoFiles,
    searchInput,
    filteredFiles,
    onSearchInputChange,
    sectionName: mainSectionReference.name,
  };
};
