import { contentClient, fetchAllData } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { ContentItem } from "../types";
import { mapToFileUpdateDto } from "../helpers";

const useEditContentItem = () => {
  const editContentItem = async (contentItem: ContentItem, editItem: Partial<ContentItem>) => {
    const fileMapped = mapToFileUpdateDto(contentItem, editItem);
    await contentClient.updateFile({ parentID: contentItem.parentID, input: fileMapped });
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    ({ contentItem, editItem }: { contentItem: ContentItem; editItem: Partial<ContentItem> }) =>
      editContentItem(contentItem, editItem),
    {
      onSuccess: async () => {
        await fetchAllData();
      },
    },
  );

  return {
    editContentItem: mutate,
    editingContentItem: isLoading,
    editContentItemSuccess: isSuccess,
    editContentItemError: error as string,
  };
};

export default useEditContentItem;
