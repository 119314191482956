import * as yup from "yup";

export const useTitleAndDescriptionStepValidationSchema = () => {
  return yup.object().shape({
    title: yup.string().required("Title is required").notOneOf([""], "Title cannot be empty"),
    description: yup
      .string()
      .required("Description are required")
      .notOneOf([""], "Description cannot be empty"),
  });
};
