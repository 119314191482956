import { ChangeEvent, useCallback, useMemo } from "react";
import {
  EolasFile,
  EolasMainSection,
  EolasRegions,
  searchClient,
  sectionStore,
} from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { useQuerySearch } from "Hooks/useQuerySearch";
import { KnowledgeResult } from "../types";

interface UseKnowledgeSearch {
  searchInput: string;
  searchValue: string;
  knowledgeFiles: KnowledgeResult[];
  knowledgeFilesLoading: boolean;
  onSearchCommunity: (searchText?: string) => void;
  onSetSearchInput: (text: string) => void;
  onClearSearch: () => void;
}
const useKnowledgeSearch = (searchDisabled?: boolean): UseKnowledgeSearch => {
  const { supportedEolasRegion } = useSupportedRegion();

  const {
    files,
    searchInput,
    searchValue,
    isLoading,
    onSearchInputChange,
    onSearch,
    onClearSearch,
  } = useQuerySearch({
    key: "knowledgeSearch",
    queryFn: (searchInput: string): Promise<EolasFile[]> =>
      searchClient.searchNationalResources({
        query: searchInput,
        mode: "searchbyInput",
        region: supportedEolasRegion as EolasRegions,
      }),
    minInputLength: 2,
    queryDisabled: searchDisabled,
    options: {
      staleTime: 60000,
    },
  });

  const handleSearch = useCallback(
    (searchText?: string) => {
      onSearch(searchText);
    },
    [onSearch],
  );

  const handleSetInput = useCallback(
    (text: string) => {
      const syntheticEvent = {
        target: { value: text },
      } as ChangeEvent<HTMLInputElement>;

      onSearchInputChange(syntheticEvent);
    },
    [onSearchInputChange],
  );

  const knowledgeFiles: KnowledgeResult[] = useMemo(() => {
    return files.map((f) => {
      const type = (() => {
        return sectionStore.getMainSectionTypeFromMainSectionID(
          f.mainSectionID!,
        )! as EolasMainSection;
      })();

      const childRef = sectionStore.getChildReferenceOfSection(f.mainSectionID!);

      return {
        id: f.id,
        title: f.name || "",
        subtitle: f.description || "",
        mainSectionName: childRef?.name || "",
        searchTypeSection: "community",
        searchSubType: type,
        iconName: type ? type : undefined,
        knowledgeFile: f,
      };
    });
  }, [files]);

  return {
    searchInput,
    searchValue,
    knowledgeFiles,
    knowledgeFilesLoading: isLoading,
    onSearchCommunity: handleSearch,
    onSetSearchInput: handleSetInput,
    onClearSearch,
  };
};

export default useKnowledgeSearch;
