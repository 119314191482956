import { userStore } from "@eolas-medical/core";
import { AdminDashboardIconType } from "Hooks";

type AdminDashboardSection = {
  title: string;
  route: string;
  description: string;
  id: AdminDashboardIconType;
  disabled?: boolean;
};

const limitedAdminSections: AdminDashboardSection[] = [
  {
    id: "contentManagement",
    title: "adminHome_cm_card_title",
    description: "adminHome_cm_card_description",
    route: `content-management`,
  },
];

const adminSections: AdminDashboardSection[] = [
  ...limitedAdminSections,
  {
    id: "manageUsers",
    title: "adminHome_manage_users_card_title",
    description: "adminHome_manage_users_card_description",
    route: `manageUsers`,
  },
];

const useSpaceAdminSections = () => {
  const { fullAccessLevel } = userStore;

  if (fullAccessLevel === "limitedAdmin") {
    return { activeAdminMainSections: limitedAdminSections };
  }

  return { activeAdminMainSections: adminSections };
};

export default useSpaceAdminSections;
