import { useTranslation } from "react-i18next";

import { formatDate } from "Utilities";
import { CheckIcon, AttentionIcon, IssuesIcon, MailIcon } from "Assets";
import { Button, InnerModalWrapper, ModalHeader, Title, SuccessModal, ModalBody } from "UIKit";

import { useIncidentReport } from "../hooks";

export interface IncidentReportModalProps {
  incidentReport: any;
  onCloseModal(): void;
}

export const IncidentReportModal: React.FC<IncidentReportModalProps> = ({
  onCloseModal,
  incidentReport = {},
}) => {
  const { t } = useTranslation();
  const info = JSON.parse(incidentReport.info);
  const author = JSON.parse(incidentReport.moreInfo);

  const { error, isLoading, onResolveReport, isSuccessful } = useIncidentReport();

  if (isSuccessful) {
    return <SuccessModal onComplete={onCloseModal} text="incident_reporting_resolved_successful" />;
  }

  return (
    <InnerModalWrapper>
      <ModalHeader className="items-start">
        <Title level={5}>{info.title}</Title>

        <span className="text-sm mb-2 text-grey-dark">{`${t("incident_reporting_reported_by")}: ${
          author.name
        }`}</span>

        <span className="text-sm text-grey-dark">{`${t(
          "incident_reporting_event_date",
        )}: ${formatDate(info.date, "do MMM y, HH:mm")}`}</span>
        <span className="text-sm text-grey-dark">
          {`${t("incident_reporting_date_submitted")}: ${formatDate(incidentReport.createdAt)}`}
        </span>
        {info.critical && (
          <div className="flex items-center mt-2 text-grey-dark">
            <AttentionIcon className="mr-2 text-red" height={16} width={16} />
            <span className="text-sm">{t("incident_reporting_critical_event")}</span>
          </div>
        )}
      </ModalHeader>

      <ModalBody className="h-full overflow-y-auto">
        <div className="flex flex-col md:flex-row items-center justify-center mb-6">
          <Button
            color="grey"
            weight="regular"
            className="mb-4 w-full md:mb-0 md:mr-4"
            onClick={() => {
              const newWin = window.open(`mailto:${author.email}`);
              if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
                alert(t("popip_blocked_message"));
              }
            }}
          >
            {`${t("createAccount_email")} ${author.name}`}
            <MailIcon className="ml-2" height={24} width={24} />
          </Button>

          {incidentReport.moreInfoTwo === "resolved" ? (
            <div
              className="px-4 h-12 rounded-lg bg-grey-light flex justify-center items-center w-full md:ml-4"
              data-testid="incidentResolved"
            >
              {t("incident_reporting_incident_resolved")}
              <CheckIcon className="ml-2 text-green" height={24} width={24} />
            </div>
          ) : (
            <Button
              color="grey"
              weight="regular"
              data-testid="markResolvedButton"
              isLoading={isLoading}
              className="w-full md:ml-4"
              onClick={onResolveReport(incidentReport)}
            >
              {t("incidentsReporting_resolved_button")}
              <IssuesIcon className="ml-2 text-red" height={24} width={24} />
            </Button>
          )}
        </div>

        {error && <span className="text-red text-center">{error}</span>}

        <span className="font-semibold mb-1">{t("incident_reporting_location_label")}</span>
        <span className="mb-4 text-grey-dark">{info.location}</span>

        <span className="font-semibold mb-1">{t("incident_reporting_details_label")}</span>
        <span className="mb-4 text-grey-dark text-justify">{info.details}</span>

        <span className="font-semibold mb-1">
          {t("incident_reporting_immediate_actions_label")}
        </span>
        <span className="mb-4 text-grey-dark text-justify">{info.immediateActions}</span>

        <span className="font-semibold mb-1">
          {t("incident_reporting_contributing_factors_label")}
        </span>
        <span className="text-grey-dark text-justify">{info.contributingFactors}</span>
      </ModalBody>
    </InnerModalWrapper>
  );
};
