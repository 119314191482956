import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import { Button, Text } from "UIKit";
import { appConfig } from "AppTypeConfig";
import { MenuIcon, XIcon } from "Assets/Icons";

import { useMobileDrawer } from "../../hooks";
import { EolasIcon } from "../../assets/appLogo";
import { customLogos } from "../../assets/customLogos";
import { CopyrightAndVersion } from "Components/CopyrightAndVersion/CopyrightAndVersion";
import { useCustomTheme } from "../../hooks/useCustomTheme";
import NotificationCenter from "Components/NotificationCenter/NotificationCenter";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

export const MobileDrawer = () => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();
  const {
    isOpen,
    isAdmin,
    drawerRef,
    showSwitcher,
    drawerMotionProps,
    isLoggingOut,
    onOpen,
    onClose,
    onLogout,
    switchToUser,
    switchToAdmin,
    onSwitchOrganisation,
  } = useMobileDrawer();
  const { customLogo } = useCustomTheme();
  const isNotificationCenterActive = !!flags[LDFlagNames.NOTIFICATION_CENTER];

  const AppIcon = (customLogo && customLogos[customLogo]) || EolasIcon;

  return (
    <>
      {isNotificationCenterActive && (
        <div className="fixed top-2 right-14 z-30">
          <NotificationCenter />
        </div>
      )}
      {!isOpen && (
        <button
          onClick={onOpen}
          className={`
            flex items-center justify-center
            fixed top-2 right-4 w-8 h-8 bg-white rounded-full z-50
          `}
        >
          <MenuIcon className="w-5 h-5" />
        </button>
      )}

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            ref={drawerRef}
            {...drawerMotionProps}
            className={`
              flex flex-col
              fixed h-full w-4/5 sm:w-1/2 md:w-1/3 bg-white rounded-l-xl z-50 shadow-lg px-4 pb-4
            `}
          >
            <button
              onClick={onClose}
              className={`
                flex items-center justify-center
                fixed top-2 right-4 w-8 h-8 bg-white rounded-full
              `}
            >
              <XIcon className="w-5 h-5" />
            </button>

            <div className="flex flex-col items-center space-y-2 my-8">
              <AppIcon className="h-12 w-12" />
              <Text level={1} className="text-darkblue-500 font-bold text-center">
                {appConfig.appName}
              </Text>
            </div>

            {showSwitcher && (
              <div className="flex flex-col space-y-4">
                <Button
                  variant="outline"
                  onClick={switchToUser}
                  iconLeft="HomeMonoIcon"
                  color={isAdmin ? "grey" : "blue"}
                  className="h-10 w-full"
                >
                  <Text level={1} className="font-bold ml-4 text-left flex-1">
                    {t("navigation_home")}
                  </Text>
                </Button>

                <Button
                  variant="outline"
                  onClick={switchToAdmin}
                  iconLeft="AdminMonoIcon"
                  color={isAdmin ? "blue" : "grey"}
                  className="h-10 w-full"
                >
                  <Text level={1} className="font-bold ml-4 text-left flex-1">
                    {t("navigation_admin_panel")}
                  </Text>
                </Button>
              </div>
            )}

            <div className="flex flex-col justify-end flex-1 space-y-4">
              <a target="_blank" rel="noreferrer" href="https://support.eolasmedical.com/">
                <Button
                  color="grey"
                  variant="outline"
                  iconLeft="SearchFileIcon"
                  className="h-10 text-grey-600 w-full"
                >
                  <Text level={1} className="font-bold ml-4 text-left flex-1">
                    {t("navigation_user_guide")}
                  </Text>
                </Button>
              </a>

              <a target="_blank" rel="noreferrer" href={appConfig.termlyPrivacyPolicy}>
                <Button
                  color="grey"
                  variant="outline"
                  iconLeft="PolicyIcon"
                  className="h-10 text-grey-600 w-full"
                >
                  <Text level={1} className="font-bold ml-4 text-left flex-1">
                    {t("navigation_privacy_policy")}
                  </Text>
                </Button>
              </a>

              <Button
                color="grey"
                variant="outline"
                iconLeft="CookiesIcon"
                // @ts-ignore
                onClick={() => displayPreferenceModal()}
                className="h-10 text-grey-600 w-full termly-cookie-preference-button"
                id="termly-consent-preferences"
              >
                <Text level={1} className="font-bold ml-4 text-left flex-1">
                  {t("navigation_manage_cookies")}
                </Text>
              </Button>

              <Button
                color="grey"
                variant="outline"
                iconLeft="ShuffleMonoIcon"
                onClick={onSwitchOrganisation}
                className="h-10 w-full"
              >
                <Text level={1} className="font-bold ml-4 text-left flex-1">
                  {t("navigation_switch_organisation")}
                </Text>
              </Button>

              <Button
                onClick={onLogout}
                iconLeft={"LogoutIcon"}
                data-testid="logoutButton"
                className="h-10 w-full"
                isLoading={isLoggingOut}
              >
                <Text level={1} className="font-bold ml-4 text-left flex-1">
                  {t("navigation_logout")}
                </Text>
              </Button>

              <CopyrightAndVersion />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
