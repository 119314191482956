import Lottie from "react-lottie-player";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FallbackProps } from "react-error-boundary";

import { error as errorAnimation } from "Assets/LottieAnimations";
import { BaseAppRoute } from "Utilities/types";
import { sectionStore } from "@eolas-medical/core";

export interface ErrorFallbackProps extends FallbackProps {}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-start items-center flex-1 pt-16">
      <Lottie style={{ width: "12rem", height: "12rem" }} animationData={errorAnimation} />
      <span className="font-semibold text-lg">{t("error_fallback_title")}</span>
      <span className="mt-4 mb-8 text-grey-dark">{t("error_fallback_description")}</span>
      <Link
        to={`/${
          sectionStore.activeDepartmentTab ? BaseAppRoute.spaces : BaseAppRoute.organisation
        }`}
        onClick={resetErrorBoundary}
        className="font-semibold text-blue border-blue rounded-lg border-2 px-6 py-2"
      >
        {t("error_fallback_button")}
      </Link>
    </div>
  );
};
