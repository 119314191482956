import { createContext } from "react";
import { RecentSearch } from "../types";

export interface MasterSearchContextValue {
  recentSearches: RecentSearch[];
  onSaveSearch: (searchText: string) => Promise<void>;
}
const MasterSearchContext = createContext<MasterSearchContextValue>({
  recentSearches: [],
  onSaveSearch: () => Promise.resolve(),
});

export default MasterSearchContext;
