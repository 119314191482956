import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { useMainSectionVisit, useSelectFile } from "Hooks";
import { DeleteFileModal, EolasFileList } from "Components";
import { AddButton, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";

import { DynamicPageSectionData } from "../dynamicPageData";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { AddItemWithFileModal } from "Pages/Spaces/components/AddEolasFile";

interface DynamicPageProps {
  data: DynamicPageSectionData;
}

export const DynamicPage: React.FC<DynamicPageProps> = observer(({ data }) => {
  const {
    orderBy,
    isDraggable,
    withKeywords,
    mainSectionID,
    allowedFileTypes = ["pdf"],
  } = data as DynamicPageSectionData;
  const { url } = useRouteMatch();
  useMainSectionVisit({ mainSectionId: mainSectionID });

  const { onSelectFile } = useSelectFile();

  const { t } = useTranslation();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { isAdmin } = useSpacesContext();
  const { spaceSection } = useSpaceSection(mainSectionID);

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const onAddFile = useCallback(() => {
    setModal(
      <AddItemWithFileModal
        withKeywords={withKeywords}
        onCloseModal={onCloseModal}
        mainSectionID={mainSectionID}
        allowedFileTypes={allowedFileTypes}
      />,
    );
    //eslint-disable-next-line
  }, [mainSectionID, onCloseModal]);

  const onEditFile = useCallback(
    (file: EolasFile) => {
      setModal(
        <AddItemWithFileModal
          file={file}
          withKeywords={withKeywords}
          onCloseModal={onCloseModal}
          mainSectionID={mainSectionID}
          allowedFileTypes={allowedFileTypes}
        />,
      );
    },
    //eslint-disable-next-line
    [mainSectionID, setModal, onCloseModal],
  );

  const onDeleteFile = useCallback(
    (file: EolasFile) => {
      setModal(<DeleteFileModal file={file} onCloseModal={onCloseModal} />);
    },
    [setModal, onCloseModal],
  );

  const displayMode: "grid" | "list" = useMemo(() => {
    if (!isAdmin && mainSectionID === AppLevelSection.emergencyActionCards) return "grid";

    return "list";
  }, [isAdmin, mainSectionID]);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t(mainSectionID)} />
        <NavButton to={url.replace(`/${mainSectionID}`, "")} />
        {isAdmin && (
          <AddButton onClick={onAddFile} data-testid="add-new-file">
            {t("fileWithSearch_addNew_file")}
          </AddButton>
        )}
        <EolasFileList
          isAdmin={isAdmin}
          onEdit={onEditFile}
          onAddItem={onAddFile}
          defaultSort={orderBy}
          onDelete={onDeleteFile}
          displayMode={displayMode}
          draggable={!!isDraggable}
          onSelectFile={onSelectFile}
          mainSectionId={mainSectionID}
        />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});
