import { Notification, NotificationStatus, NotificationType } from "@eolas-medical/core";
import { NotificationTypeMapResult, WebNotification } from "./types";

export const mapToWebNotification = (
  notificationMapper: Record<NotificationType, NotificationTypeMapResult>,
  notification: Notification,
): WebNotification => {
  const mapResult: NotificationTypeMapResult =
    notificationMapper[notification.type as NotificationType];

  return {
    id: notification.id,
    title: notification.title,
    body: notification.body,
    icon: mapResult ? mapResult.icon : null,
    isRead: notification.status === NotificationStatus.READ,
    createdAt: notification.createdAt,
    route: notification.route || "",
    metadata: notification.metadata || {},
    notificationType: notification?.type,
    notificationSection: mapResult ? mapResult.notificationSection : "generic",
    notificationSubSection: mapResult ? mapResult.notificationSubSection : null,
  };
};
