import { EventType } from "modules/portfolio/types";

const portfolioKeys = {
  all: ["portfolio"],
  template: (eventType: EventType) => [...portfolioKeys.all, "template", eventType],
  event: (eventId: string) => [...portfolioKeys.all, "event", eventId],
  detail: (eventId: string, ownerId?: string) => [...portfolioKeys.all, "detail", eventId, ownerId],
  lists: (ownerId?: string) => [...portfolioKeys.all, "list", ownerId],
  list: (filters: any) => [...portfolioKeys.lists(), { filters }],
  feedbackDetail: (eventId: string) => [...portfolioKeys.all, "feedbackDetail", eventId],
  feedbackList: () => [...portfolioKeys.all, "feedbackList"],
  reviewersList: (eventId: string) => [...portfolioKeys.all, "reviwersList", eventId],
  feedbackSummary: (eventId: string) => [...portfolioKeys.all, "feedbackSummary", eventId],
  templatesSummary: () => [...portfolioKeys.all, "templatesSummary"],
  assessorFeedback: (eventId: string) => [...portfolioKeys.all, "assessorFeedback", eventId],
  assignedAssessors: (eventId: string) => [...portfolioKeys.all, "assignedAssessors", eventId],
  recentlyAssigned: (ownerId: string) => [...portfolioKeys.all, "recentlyAssigned", ownerId],
  supervisorsList: () => [...portfolioKeys.all, "supervisorsList"],
  superviseesList: () => [...portfolioKeys.all, "superviseesList"],
};

export default portfolioKeys;
