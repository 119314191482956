import { SearchIcon } from "Assets";
import { ModalBody, Title, Text, Input } from "UIKit";
import { AdminEntry } from "./AdminEntry";
import { MakeAdminEntry } from "./MakeAdminEntry";
import { useTranslation } from "react-i18next";
import { AppUser } from "API";
import { userStore } from "@eolas-medical/core";
import { useCallback, useState } from "react";
import { useManageAdmins } from "./useManageAdmins";
import { UpdateAccessLevelParams } from "./useAdminEntry";

export interface AdminAccessModal {
  departmentUsers: AppUser[];
  setUserAccess: (params: UpdateAccessLevelParams) => void;
  onManageSections: (params: {
    userId: string;
    userFullName: string;
    selectedSections: string[];
    action: "set" | "update";
  }) => void;
}

export const ManageAdminAccessModal = ({
  departmentUsers,
  setUserAccess,
  onManageSections,
}: AdminAccessModal) => {
  const { t } = useTranslation();
  const {
    adminList,
    searchInput,
    filteredUsers: readOnlyUsers,
    onInputChange: onSearchChange,
  } = useManageAdmins(departmentUsers);
  const { appUserData: loggedInAppUser } = userStore;
  const [loading, setLoading] = useState({ userId: "" });

  const handleAccessUpdate = useCallback(
    async ({ userId, accessLevel, limitedAccess }: UpdateAccessLevelParams) => {
      try {
        setLoading((prev) => ({ userId }));
        await setUserAccess({ userId, accessLevel, limitedAccess });
      } finally {
        setLoading({ userId: "" });
      }
    },
    [setUserAccess],
  );

  return (
    <ModalBody className="space-y-4">
      <div className="flex space-x-2 items-baseline">
        <Title level={7} className="font-bold">
          {t("manageUsers_accessRequest_currentAdminUsers")}
        </Title>

        {adminList.length > 3 && (
          <Text level={3} className="text-grey-600">
            ({t("manageUsers_scroll_for_more")})
          </Text>
        )}
      </div>

      <div className="flex flex-col space-y-4 max-h-30vh overflow-y-auto">
        {adminList.map(({ user: userValues, userID, limitedAccess }) => {
          const user = {
            ...userValues,
            id: userID,
          };
          return (
            <AdminEntry
              isAdmin
              isLimitedAccess={!!limitedAccess}
              user={user}
              key={user?.id}
              onUpdateUser={handleAccessUpdate}
              onManageSections={() =>
                limitedAccess &&
                onManageSections({
                  userId: userID,
                  selectedSections: limitedAccess,
                  action: "update",
                  userFullName: `${user.given_name} ${user.family_name}`,
                })
              }
              isLoading={loading.userId === userID}
              showButton={user?.id !== loggedInAppUser?.userID}
              buttonText={t("manageUsers_accessRequest_removeAdmin")}
            />
          );
        })}
      </div>

      <div className="flex space-x-2 items-baseline">
        <Title level={7} className="font-bold">
          {t("manageUsers_accessRequest_addNew")}
        </Title>

        {searchInput.length > 2 && readOnlyUsers.length > 2 && (
          <Text level={3} className="text-grey-600">
            ({t("manageUsers_scroll_for_more")})
          </Text>
        )}
      </div>

      <Input
        className="input"
        value={searchInput}
        onChange={onSearchChange}
        iconLeft={<SearchIcon height={32} width={32} />}
        placeholder={t("manageUsers_search_admins_placeholder")}
      />
      <div className="flex flex-col space-y-4 max-h-20vh overflow-y-auto">
        {readOnlyUsers.length > 0 && searchInput.length > 2 ? (
          readOnlyUsers.map(({ user: userValues, userID }) => {
            const user = {
              ...userValues,
              id: userID,
            };
            return (
              <MakeAdminEntry
                user={user!}
                key={user?.id}
                onUpdateUser={handleAccessUpdate}
                isLoading={loading.userId === userID}
                onMakeLimitedAccess={() =>
                  onManageSections({
                    userId: userID,
                    selectedSections: [],
                    action: "set",
                    userFullName: `${user.given_name} ${user.family_name}`,
                  })
                }
              />
            );
          })
        ) : (
          <span className="text-center">{t("manageUsers_no_users_found")}</span>
        )}
      </div>
    </ModalBody>
  );
};
