import {
  AuthErrorCodes,
  AuthFunctionNames,
  authenticationClient,
  handleAuthErrors,
} from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { errorStore } from "Stores/ErrorStore";
import { LDFlagNames } from "Utilities/types";
import { useState } from "react";

interface LoginUser {
  email: string;
  password: string;
  isFederated?: boolean;
  isFederatedFeatureEnabled?: boolean;
}
const useSignInUser = ({ onSuccess = () => {}, onError = () => {} } = {}) => {
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signInError, setSignInError] = useState<string>();
  const { flags } = useLaunchDarkly();

  const isFeatureEnabled = flags[LDFlagNames.FEDERATED_USERS];

  const signInUser = async ({ email, password }: LoginUser) => {
    setIsSigningIn(true);
    try {
      await authenticationClient.signIn({
        email,
        password,
        isFederated: false,
        isFederatedFeatureEnabled: isFeatureEnabled,
      });
      onSuccess && onSuccess();
    } catch (error: any) {
      let errorMessageForDisplay = "";
      if (error.code === AuthErrorCodes.federated_domain_on_enterprise_login_error) {
        errorStore.captureError(error);
        try {
          await authenticationClient.signIn({
            email,
            password,
            isFederated: true,
            isFederatedFeatureEnabled: isFeatureEnabled,
          });
        } catch (secondError: any) {
          setIsSigningIn(false);
          ({ errorMessageForDisplay } = handleAuthErrors(secondError, AuthFunctionNames.login));
          setSignInError(errorMessageForDisplay);
        }
      } else {
        setIsSigningIn(false);
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          retryCallback: () =>
            authenticationClient.signIn({
              email,
              password,
              isFederated: false,
              isFederatedFeatureEnabled: isFeatureEnabled,
            }),
          authFunctionName: AuthFunctionNames.login,
        });
        setSignInError(errorMessage);
        onError && onError();
      }
    }
  };

  return {
    signInUser,
    isSigningIn,
    signInError,
  };
};

export default useSignInUser;
