import { getFeedbackAggregatedSummary } from "../client/portfolio.client";
import { QueryEnvironment, useEolasQuery } from "Hooks";
import portfolioKeys from "./portfolio.queryKeys";
import { FeedbackAggregatedValue } from "../types";

export interface UseFeedbackSummaryResponse {
  feedbackSummary: FeedbackAggregatedValue[];
  feedbackSummaryLoading: boolean;
}

const useFeedbackSummary = (eventId: string): UseFeedbackSummaryResponse => {
  const { isLoading, data } = useEolasQuery({
    queryFn: () => getFeedbackAggregatedSummary(eventId),
    queryKey: portfolioKeys.feedbackSummary(eventId),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 30000,
      staleTime: 15 * (60 * 1000), //15 mins
    },
  });

  return { feedbackSummaryLoading: isLoading, feedbackSummary: data ?? [] };
};

export default useFeedbackSummary;
