import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { AppLevelSection, fetchAllData, sectionStore } from "@eolas-medical/core";
import useUploadTeamMemberPhoto from "./useUploadTeamMemberPhoto";
import { updateFile } from "clients/files/files.client";

interface EditTeamMemberInput {
  id: string;
  name: string;
  role: string;
  bio: string;
  photo: string | File | null;
}

const useEditTeamMember = () => {
  const { uploadTeamMemberPhoto } = useUploadTeamMemberPhoto();

  const handleEditTeamMember = useCallback(
    async ({ id, name, role, bio, photo }: EditTeamMemberInput) => {
      const parentID = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.meetTheTeam)!
        .id;

      const s3Key = await uploadTeamMemberPhoto(photo);

      return updateFile({
        parentID,
        input: { id, name, description: role, infoTwo: bio, key: s3Key },
      });
    },
    [uploadTeamMemberPhoto],
  );

  const { mutate, isLoading, isSuccess, error } = useMutation(handleEditTeamMember, {
    onSuccess: () => {
      fetchAllData();
    },
  });

  return {
    editTeamMember: mutate,
    editingTeamMember: isLoading,
    editTeamMemberSuccessful: isSuccess,
    editTeamMemberError: error as string,
  };
};

export default useEditTeamMember;
