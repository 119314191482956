import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AnalyticsEvents, AuthFunctionNames } from "@eolas-medical/core";

import { trackEvent } from "API/Analytics";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { resetPasswordSchema } from "Utilities/authValidations";
import { forgotPasswordSubmit } from "../auth.actions";

export interface ResetPasswordFormValues {
  resetPassword: string;
  verificationCode: string;
  resetConfirmPassword: string;
}

const defaultValues: ResetPasswordFormValues = {
  resetPassword: "",
  verificationCode: "",
  resetConfirmPassword: "",
};

export const useResetPassword = (userEmail: string, onFormSubmit: any) => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();

  const formMethods = useForm<ResetPasswordFormValues>({
    defaultValues,
    resolver: yupResolver(resetPasswordSchema),
  });
  const { control, handleSubmit } = formMethods;

  const onSubmit = handleSubmit(({ resetPassword, verificationCode }) => {
    setRequestStatus({ status: "pending", error: "" });
    forgotPasswordSubmit(userEmail, resetPassword, verificationCode)
      .then(() => {
        setRequestStatus({ status: "success", error: "" });
        trackEvent(AnalyticsEvents.FORGOT_PASSWORD_COMPLETED);
        trackEvent(AnalyticsEvents.FORGOT_PASSWORD_DURATION);
        onFormSubmit();
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          authFunctionName: AuthFunctionNames.confirmForgotPassword,
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  });

  return {
    error,
    control,
    isLoading,
    formMethods,
    onSubmit,
  };
};
