import { useMutation, useQueryClient } from "@tanstack/react-query";
import { declineSupervisionRequest } from "../client/portfolio.client";
import { SuperviseeList } from "../client/portfolio.graphql";
import portfolioKeys from "./portfolio.queryKeys";

const useDeclineSupervisionRequest = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(declineSupervisionRequest, {
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        portfolioKeys.superviseesList(),
        (actualRequests: SuperviseeList | undefined) => {
          if (actualRequests?.supervisionRequestsSummary) {
            const pendingRequests = actualRequests.supervisionRequestsSummary.filter(
              (supervision) => supervision?.id !== variables.supervisionRequestId,
            );

            return {
              supervisionRequestsSummary: pendingRequests,
              superviseeSummary: [...actualRequests.superviseeSummary],
            };
          }
        },
      );
    },
  });

  return {
    declineRequest: mutate,
    decliningRequest: isLoading,
    ...rest,
  };
};

export default useDeclineSupervisionRequest;
