import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "UIKit";
import { theme } from "UIKit";
import { useMediaQuery } from "Hooks";
import { FilterIcon } from "Assets";

const FilterButton = ({
  onClick,
  areFiltersApplied,
}: {
  areFiltersApplied: boolean;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  const media = useMediaQuery();
  const isDesktop = media === "lg" || media === "xl" || media === "2xl";

  return (
    <button onClick={() => onClick()}>
      {isDesktop ? (
        <div
          className={`${
            areFiltersApplied ? `bg-blue-500 border-blue-500` : `bg-white border-grey-300`
          } border w-28 h-12 p-1 rounded-full sm:rounded-lg flex flex-row mx-2 justify-self-center self-center`}
        >
          <FilterIcon
            color={areFiltersApplied ? theme.colors.blue[500] : theme.colors.white}
            height="24"
            width="24"
            className=" ml-2 self-center"
            fill={areFiltersApplied ? theme.colors.white : theme.colors.black}
          />
          <Text
            level={1}
            className={`text-sm ${
              areFiltersApplied ? `text-white` : `text-black`
            } sm:text-base self-center mx-auto`}
          >
            {t("portfolio_filter")}
          </Text>
        </div>
      ) : (
        <div
          className={`${
            areFiltersApplied ? `bg-blue-500` : `bg-white border-grey-300`
          } w-12 h-12 p-1 rounded-full sm:rounded-lg mx-2 justify-self-center content-center self-center`}
        >
          <FilterIcon
            color={areFiltersApplied ? theme.colors.blue[500] : theme.colors.white}
            height="24"
            width="24"
            className=" ml-2 self-center mt-2"
            fill={areFiltersApplied ? theme.colors.white : theme.colors.black}
          />
        </div>
      )}
    </button>
  );
};

export default FilterButton;
