import { useLaunchDarkly } from "Contexts";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LDFlagNames } from "Utilities/types";

export const useNotificationsBanner = () => {
  const [bannerNotSeen, setBannerNotSeen] = useState<boolean>(true);
  const { flags } = useLaunchDarkly();
  const { t } = useTranslation();

  const isReadOnlyActivated = flags[LDFlagNames.READ_ONLY_ACTIVATED];
  const notificationText = isReadOnlyActivated
    ? t("read_only_banner_text")
    : flags[LDFlagNames.NOTIFICATIONS_BANNER];

  return {
    bannerNotSeen,
    setBannerNotSeen,
    notificationText,
  };
};
