import {
  Wizard,
  useTitleAndDescriptionStep,
  useWizard,
  Step,
  useUploadProgressAndSuccessStep,
} from "UIKit";
import { ContentItem } from "modules/contentRepository/types";
import { useTranslation } from "react-i18next";
import {
  FlashcardCreator,
  FlashcardHeroImage,
  FlashcardTitleAndDescription,
  UploadProgress,
} from "../AddContentItemWizard/types";
import { useFlashcardHeroImageStep } from "../AddContentItemWizard/FlashcardHeroImgStep";
import { useFlashcardCreatorStep } from "../AddContentItemWizard/FlashcardCreatorStep";
import { useEditContentItemActions } from "./hooks/useEditFlashcardItemActions";
import { fromAwsJSON } from "Utilities";

interface EditFlashcardModalProps {
  flashcard: ContentItem;
  onClose: () => void;
}

export type EditContentItemWizardStep =
  | FlashcardTitleAndDescription
  | FlashcardCreator
  | FlashcardHeroImage
  | UploadProgress;

const EditFlashcardModal = ({ flashcard, onClose }: EditFlashcardModalProps) => {
  const { t } = useTranslation();
  const { wizardControl } = useWizard<EditContentItemWizardStep>();

  const flashcardMetadata = flashcard.metadata ? fromAwsJSON(flashcard.metadata) : {};

  const {
    onEditFlashcardDetails,
    onEditFlashcardContent,
    onSubmitFlashcardImage,
    editingFlashcardItem,
    editFlashcardItemSuccess,
    editFlashcardItemError,
    editFlashcardItemProgress,
  } = useEditContentItemActions({
    flashcard,
    onClose,
    subSectionId: flashcard.parentID,
    mainSectionId: flashcard.mainSectionID || "",
  });

  const flashcardTitleAndDescription = useTitleAndDescriptionStep<FlashcardTitleAndDescription>({
    stepName: "flashcard-title-and-description",
    onSubmit: onEditFlashcardDetails,
    title: t("flashcards_edit_page_title"),
    titleLabel: t("flashcards_title_label"),
    titlePlaceholder: t("flashcards_title_placeholder"),
    descriptionLabel: t("flashcards_description_label"),
    descriptionPlaceholder: t("flashcards_description_placeholder"),
    initialTitle: flashcard.name,
    initialDescription: flashcard.description,
  });

  const flashcardCreator = useFlashcardCreatorStep<FlashcardCreator>({
    pageTitle: t("flashcards_page_title"),
    flashcardTitle: flashcard.name,
    flashcardDescription: flashcard.description,
    editorContent: decodeURIComponent(flashcardMetadata.flashcardContent),
    onSubmit: onEditFlashcardContent,
    contentRepositoryId: flashcard.mainSectionID!,
  });

  const flashcardHeroImage = useFlashcardHeroImageStep({
    title: t("flashcards_page_title_hero_image"),
    onSubmit: onSubmitFlashcardImage,
    initialImage: flashcard.imageUrl,
    errorDraggedImageText: t("imagePicker_too_large_error"),
  });

  const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<UploadProgress>({
    stepName: "upload-progress",
    fileUploading: editingFlashcardItem,
    uploadProgress: editFlashcardItemProgress,
    fileUploadError: editFlashcardItemError,
    fileUploadedSuccess: editFlashcardItemSuccess,
    onFinishUpload: onClose,
  });

  const steps: Step<EditContentItemWizardStep>[] = [
    flashcardTitleAndDescription,
    flashcardCreator,
    flashcardHeroImage,
    uploadProgressAndSuccessStep,
  ];

  return <Wizard steps={steps} wizardControl={wizardControl} />;
};

export default EditFlashcardModal;
