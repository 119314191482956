import { StarIcon, StarOutlineIcon } from "Assets";

import { IconButton } from "../Button";
import { FileTileWrapper, FileTileProps } from "./shared";

export const HospitalFileTile: React.FC<FileTileProps> = ({
  isAdmin,
  eolasFile,
  isDragging,
  highlightLoading,
  onHighlight = () => {},
  onSelectFile = () => {},
}) => {
  const isHighlighted = !!eolasFile.highlighted && eolasFile.highlighted !== "false";

  return (
    <FileTileWrapper
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-1">
        <span className="text-lg font-semibold line-clamp-2">{eolasFile.name}</span>
        <span className="text-sm text-grey-darker capitalize line-clamp-2">
          {eolasFile.description}
        </span>
      </div>

      {isAdmin && (
        <IconButton
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            onHighlight(eolasFile);
          }}
          isLoading={highlightLoading}
          data-testid={`deleteButtonFor-${eolasFile.name}`}
          icon={isHighlighted ? <StarIcon /> : <StarOutlineIcon />}
        />
      )}
    </FileTileWrapper>
  );
};
