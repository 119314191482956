import { useState } from "react";

import { Button } from "UIKit";
import { CaretDownIcon } from "Assets";

export interface CollapsibleProps {
  title?: string;
  className?: string;
  children: React.ReactNode;
}

export const Collapsible = ({ children, title = "More", className = "" }: CollapsibleProps) => {
  const [state, setState] = useState({ collapse: false });

  const toggleCollapse = () => {
    setState((prevState) => ({ ...prevState, collapse: !prevState.collapse }));
  };

  return (
    <div className={`mt-2 ${className}`}>
      <div>
        <Button onClick={toggleCollapse}>
          {title}
          <CaretDownIcon
            className={`w-6 h-6 text-white transition-all ${
              state.collapse ? "transform rotate-180" : ""
            }`}
          />
        </Button>
      </div>
      {state.collapse && <div className={`p-6 bg-grey-light rounded-lg mt-2`}>{children}</div>}
    </div>
  );
};
