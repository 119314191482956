import { useTranslation } from "react-i18next";

import { AboutIcon, RefreshCWIcon } from "Assets";
import { Button, Loader, Title, Text } from "UIKit";

import { useSpaceList } from "modules/spaces/data";
import { SpaceItem } from "../SpaceItem/SpaceItem";
import { userStore } from "@eolas-medical/core";
import { useSelectSpaceActions } from "../../hooks/useSelectSpaceActions";

export const SpaceList = () => {
  const { t } = useTranslation();
  const { userID = "" } = userStore;
  const { spaceList, spacesLoading, onRefreshSpaces } = useSpaceList(userID);

  const { loadingSpaceId, onSelectSpace, error } = useSelectSpaceActions();

  if (spacesLoading) {
    return <Loader className="bg-transparent h-30vh" />;
  }

  if (spaceList.length === 0) {
    return (
      <div className="flex flex-col px-4 sm:px-40 text-center items-center space-y-8">
        <a href="https://support.eolasmedical.com/" target="_blank" rel="noreferrer">
          <AboutIcon className="w-12 h-12 sm:w-16 sm:h-16" />
        </a>

        <Text level={1} className="text-grey-700">
          {t("selectSpace_no_member_of_orgs")}
        </Text>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-center sm:justify-start space-x-2">
        <Title level={6}>{t("selectSpace_your_orgs")}</Title>

        <a href="https://support.eolasmedical.com/" target="_blank" rel="noreferrer">
          <AboutIcon className="w-8 h-8" />
        </a>
      </div>

      <div
        className={`
          flex flex-col bg-white relative mt-4
          max-h-50vh overflow-y-auto shadow-sm rounded-md divide-y divide-grey-200
        `}
      >
        {spaceList.map((org: any) => {
          return (
            <SpaceItem
              app={org.app}
              key={org.app.appID}
              loadingAppID={loadingSpaceId}
              isAdmin={org.accessLevel === "admin"}
              onClick={() => {
                onSelectSpace(org.app);
              }}
            />
          );
        })}
      </div>

      {error && <span className="text-red font-semibold text-center mt-4">{t(error)}</span>}

      <Button
        color="grey"
        weight="bold"
        variant="outline"
        onClick={onRefreshSpaces}
        style={{ borderRadius: 44 }}
        className="self-center px-3 mt-4 sm:mt-8"
      >
        <RefreshCWIcon className="h-4 w-4 mr-2" />
        {t("selectSpace_refresh")}
      </Button>
    </div>
  );
};
