import { useCallback } from "react";
import { IconButton, Text } from "UIKit";
import { FileTypeIcon } from "Pages/MeTab/pages/MyFiles/pages/Files/components";
import { formatBytes } from "Utilities/helpers";
import { DeleteBinIcon, DownloadIcon } from "Assets";
import { AttachmentItem } from "modules/portfolio/types";

interface PortfolioAttachmentItemProps {
  attachment: AttachmentItem;
  onDownload?: (attachment: AttachmentItem) => void;
  onRemove?: () => void;
}
const PortfolioAttachmentItem = ({
  attachment,
  onDownload,
  onRemove,
}: PortfolioAttachmentItemProps) => {
  const handleDownload = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onDownload && onDownload(attachment);
    },
    [onDownload, attachment],
  );

  const handleRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onRemove && onRemove();
    },
    [onRemove],
  );

  return (
    <div className="w-full py-3 grid grid-cols-4 md:grid-cols-8 xl:grid-cols-12">
      <div className="row-start-1 col-start-1 flex items-center cursor-pointer">
        <FileTypeIcon type={attachment.blob?.type} className="w-5 h-5" />
      </div>
      <div className="row-start-1 col-start-1 col-end-3 ml-8 flex items-center cursor-pointer md:col-end-7 xl:col-end-11">
        <Text level={2}>{attachment.name}</Text>
      </div>
      <div className="row-start-1 col-start-3 col-end-5 flex items-center justify-end space-x-2 md:col-start-7 md:col-end-9 xl:col-start-11 xl:col-end-13">
        {attachment.blob && (
          <p className="text-sm text-grey-dark font-semibold">
            {formatBytes(attachment.blob.size || 0)}
          </p>
        )}
        {onDownload && !attachment.link && (
          <IconButton
            icon={<DownloadIcon className="w-5 h-5" />}
            variant="rounded"
            onClick={handleDownload}
            size="sm"
          />
        )}
        {onRemove && (
          <IconButton
            icon={<DeleteBinIcon className="w-5 h-5" />}
            variant="rounded"
            onClick={handleRemove}
            size="sm"
          />
        )}
      </div>
    </div>
  );
};

export default PortfolioAttachmentItem;
