import { Step } from "UIKit";
import { useForm } from "react-hook-form";
import TermsAndCondModal from "./TermsAndCondModal";
import { useTranslation } from "react-i18next";

interface UseTermsAndCondStepProps {
  error?: string;
  isLoading: boolean;
  onSubmit: () => void;
  onEnter: () => void;
}

const useTermsAndCondStep = ({
  error,
  isLoading,
  onSubmit,
  onEnter,
}: UseTermsAndCondStepProps): Step<"terms-and-conditions"> => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      termsAndCond: false,
      disclaimer: false,
    },
  });

  const termsAndConditions = watch("termsAndCond");
  const disclaimer = watch("disclaimer");
  const isFormComplete = termsAndConditions && disclaimer;

  const handleUpdateCompletedTour = () => {
    localStorage.setItem("acceptedTermsAndCond", "true");
    onSubmit();
  };

  return {
    name: "terms-and-conditions",
    title: "",
    component: <TermsAndCondModal control={control} isLoading={isLoading} onSubmit={onEnter} />,
    onSubmit: handleSubmit(handleUpdateCompletedTour),
    isLoading,
    onlySubmitStep: true,
    submitError: !!error,
    nextLabel: t("departmentTour_TC_button"),
    nextDisabled: !isFormComplete,
  };
};

export default useTermsAndCondStep;
