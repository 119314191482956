import { ConfigureFileStep } from "./ConfigureStep";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFileDetailsValidationSchema } from "./useFileDetailsValidationSchema";
import { ConfigureFileStepFormValues, ConfigureFileValues } from "./types";
import { Step } from "../../types";

interface UseAddFileDetailsProps<T> {
  stepName: T;
  title: string;
  toggleExpiryCheckText?: string;
  toggleExpiryLabel?: string;
  expiryDatePickerLabel?: string;
  nameInputLabel?: string;
  nameInputPlaceholder?: string;
  keyWordsLabel?: string;
  keyWordsPlaceholder?: string;
  onSubmit: (data: ConfigureFileValues) => void;
  onEnter: () => void;
  isLoading?: boolean;
  nextLabel?: string;
  item: Partial<ConfigureFileValues>;
  backDisabled?: boolean;
}

export const useConfigureFileStep = <T extends string>({
  stepName,
  title,
  toggleExpiryCheckText = "",
  toggleExpiryLabel = "",
  expiryDatePickerLabel = "",
  nameInputLabel = "",
  nameInputPlaceholder = "",
  keyWordsLabel = "",
  keyWordsPlaceholder = "",
  nextLabel,
  item,
  isLoading,
  onSubmit,
  onEnter,
  backDisabled = false,
}: UseAddFileDetailsProps<T>): Step<T> => {
  const validationSchema = useFileDetailsValidationSchema();

  const {
    handleSubmit,
    formState,
    control,
    getValues,
    watch,
  } = useForm<ConfigureFileStepFormValues>({
    defaultValues: {
      name: item.name,
      expiryDate: item.expiryDate,
      keywords: item.keywords,
      hasExpiryDate: !!item.expiryDate,
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const { errors } = formState;

  const canProceed = formState.isValid && !formState.isSubmitting && !isLoading;

  const handleSubmitDetails = () => {
    const { name, expiryDate, keywords, hasExpiryDate } = getValues();
    const toSubmitExpiryDate = hasExpiryDate ? expiryDate : undefined;
    onSubmit({ name, expiryDate: toSubmitExpiryDate, keywords });
  };

  return {
    name: stepName,
    title,
    submitError: !!errors,
    canProceed: canProceed,
    onSubmit: handleSubmit(handleSubmitDetails),
    isLoading,
    nextLabel,
    component: (
      <ConfigureFileStep
        control={control}
        toggleExpiryCheckText={toggleExpiryCheckText}
        toggleExpiryLabel={toggleExpiryLabel}
        expiryDatePickerLabel={expiryDatePickerLabel}
        nameInputLabel={nameInputLabel}
        nameInputPlaceholder={nameInputPlaceholder}
        keyWordsLabel={keyWordsLabel}
        keyWordsPlaceholder={keyWordsPlaceholder}
        item={item}
        onEnter={onEnter}
        watch={watch}
        errors={errors}
      />
    ),
    backDisabled,
  };
};
