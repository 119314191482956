import {
  ChildReference,
  CommunityLevelSection,
  EolasMainSection,
  OrganisationLevelSection,
  isDefined,
  sectionStore,
} from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { useMainSectionFlags } from "Hooks";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { parseMedusaMetadata } from "Utilities";
import { LDFlagNames } from "Utilities/types";
import { mapToMainSection } from "modules/mainSections/helpers";
import { MainSection } from "modules/mainSections/types";

interface UseOrganisationMainSections {
  activeMainSections: MainSection[];
  deactivatedMainSections: MainSection[];
  inactiveMainSections: MainSection[];
  hasDeactivatedSections: boolean;
}

const useOrganisationMainSections = (): UseOrganisationMainSections => {
  const { flags } = useLaunchDarkly();
  const { isMainSectionEnabled } = useMainSectionFlags();
  const { getIconUrl } = useGetIconUrl();

  const mapSectionList = (sections: ChildReference[]) => {
    return sections
      .map(mapToMainSection)
      .map((s) => ({ ...s, iconUrl: s.iconName ? getIconUrl(s.iconName) : undefined }));
  };

  const activeMainSections: ChildReference[] = sectionStore
    .getMainSectionList("hospital")
    .filter(({ id, identity, name }) => {
      const { mainSectionType, metadata } = sectionStore.getSection(id);
      if (mainSectionType === CommunityLevelSection.clinicalCalculators) {
        return false;
      }
      let sectionEnabled = true;
      if (mainSectionType === "medusaMedications") {
        if (typeof metadata === "string" || isDefined(metadata?.medusa?.status)) {
          if (!flags[LDFlagNames.MEDUSA_SECTIONS]) return false;

          const data =
            typeof metadata === "string" ? parseMedusaMetadata(metadata) : metadata?.medusa;

          sectionEnabled = data?.status === "enabled";
        }
      }

      return (
        sectionEnabled &&
        (mainSectionType in OrganisationLevelSection || identity === "genericContentRepository") &&
        isMainSectionEnabled(mainSectionType as EolasMainSection)
      );
    });

  const deactivatedMainSections: ChildReference[] = sectionStore
    .getMainSectionList("hospital", (child) => child.disabled === "true")
    .filter(({ id }) => {
      const { mainSectionType } = sectionStore.getSection(id);

      if (mainSectionType === "medusaMedications") {
        if (flags[LDFlagNames.DISABLE_MEDUSA_ACTIVATION]) return false;
      }

      return (
        mainSectionType in OrganisationLevelSection &&
        isMainSectionEnabled(mainSectionType as EolasMainSection)
      );
    });

  const inactiveMainSections: ChildReference[] = sectionStore
    .getMainSectionList(
      "hospital",
      (child) => child.disabled === "true" || child.disabled === "false", // Needs to be like this if not filter fails
    )
    .filter(
      (section) =>
        !activeMainSections.includes(section) && !deactivatedMainSections.includes(section),
    );

  const hasDeactivatedSections = deactivatedMainSections.length > 0;

  return {
    activeMainSections: mapSectionList(activeMainSections),
    deactivatedMainSections: mapSectionList(deactivatedMainSections),
    inactiveMainSections: mapSectionList(inactiveMainSections),
    hasDeactivatedSections,
  };
};

export default useOrganisationMainSections;
