import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { EolasFile, AppLevelSection } from "@eolas-medical/core";

import { DeleteFileModal, EolasFileList } from "Components";
import { useMainSectionVisit, useSelectFile } from "Hooks";
import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";

import { SubSectionRouteParams } from "Utilities/types";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSubSection from "modules/spaces/data/useSpaceSubSection";
import { AddItemWithOrganisationModal } from "Pages/Spaces/components/AddEolasFile";

export const ClinicalDocumentSections: React.FC<
  RouteComponentProps<SubSectionRouteParams>
> = observer(({ match: { url }, location: { pathname } }) => {
  const { t } = useTranslation();
  const { onSelectFile: selectFile } = useSelectFile();

  const { isAdmin } = useSpacesContext();
  const mainSectionID = AppLevelSection.clinicalDocuments;

  const { spaceSubSection } = useSpaceSubSection(mainSectionID);

  useMainSectionVisit({
    mainSectionId: mainSectionID,
    subSectionId: spaceSubSection.subSectionId,
  });

  const [modal, setModal] = useState<React.ReactNode>(null);
  const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] = useState<boolean>(true);

  const onSelectFile = useCallback(
    (file: EolasFile) => {
      selectFile(file);
    },
    [selectFile],
  );

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, []);

  const onAddFile = useCallback(() => {
    setModal(
      <AddItemWithOrganisationModal
        subSectionId={spaceSubSection.subSectionId}
        onCloseModal={onCloseModal}
        mainSectionID={mainSectionID}
        setShouldCloseOnOverlayClick={setShouldCloseOnOverlayClick}
      />,
    );
  }, [mainSectionID, onCloseModal, spaceSubSection]);

  const onEditItem = useCallback(
    (file: EolasFile) => {
      setModal(
        <AddItemWithOrganisationModal
          file={file}
          subSectionId={spaceSubSection.subSectionId}
          onCloseModal={onCloseModal}
          mainSectionID={mainSectionID}
          setShouldCloseOnOverlayClick={setShouldCloseOnOverlayClick}
        />,
      );
    },
    [onCloseModal, spaceSubSection, mainSectionID],
  );

  const onDeleteItem = useCallback(
    (file: EolasFile) => {
      setModal(<DeleteFileModal onCloseModal={onCloseModal} file={file} />);
    },
    [onCloseModal],
  );

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSubSection.subSectionName ?? ""} />
        <NavButton to={url.replace(`/${spaceSubSection.encodedSubSectionId}`, "")} />

        {isAdmin && <AddButton onClick={onAddFile}>{t("document_add_file")}</AddButton>}

        <EolasFileList
          isAdmin={isAdmin}
          onEdit={onEditItem}
          onAddItem={onAddFile}
          onDelete={onDeleteItem}
          subSectionId={spaceSubSection.subSectionId}
          onSelectFile={onSelectFile}
          mainSectionId={mainSectionID}
        />
      </InnerPageWrapper>

      <Modal
        open={!!modal}
        onClose={onCloseModal}
        // When cropping an image, and dragging outside of the modal and releasing the click
        // it would have closed the model, this fixes that
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        {modal}
      </Modal>
    </>
  );
});
