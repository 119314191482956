import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, FormElement, Textarea } from "UIKit";

type RequestAccessFormProps = {
  isLoading: boolean;
  onSubmit: (requestText?: string) => void;
};

const RequestAccessForm = ({ isLoading, onSubmit }: RequestAccessFormProps) => {
  const { t } = useTranslation();
  const { control, register, handleSubmit, setFocus } = useForm({
    defaultValues: {
      requestText: "",
    },
  });

  const handleSendRequestAccess = useCallback(
    ({ requestText }) => {
      onSubmit(requestText.trim());
    },
    [onSubmit],
  );

  useEffect(() => {
    setFocus("requestText");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(handleSendRequestAccess)}>
      <div className={`flex flex-col p-12 space-y-8 sm:space-y-12 h-50vh sm:h-auto`}>
        <span className="text-xl sm:text-2xl text-center font-semibold">
          {t("selectSpace_requestNotGranted")}
        </span>
        <FormElement
          id="requestText"
          label={t("selectSpace_request_information_label")}
          control={control}
        >
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Textarea
                {...register("requestText")}
                {...rest}
                placeholder={t("selectSpace_request_information_placeholder")}
                value={value}
                className="resize-none"
              />
            );
          }}
        </FormElement>
        <Button
          variant="outline"
          type="submit"
          disabled={isLoading}
          className="self-center w-full sm:w-1/2"
        >
          {t("selectSpace_request_access_button")}
        </Button>
      </div>
    </form>
  );
};

export default RequestAccessForm;
