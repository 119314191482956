import { uploadFileToS3 } from "API/app.actions";
import { getPreSignUpload } from "@eolas-medical/core";

export const useS3FileUpload = () => async (key: string, file: File, onUploadProgress?: any) => {
  if (!key.startsWith("public")) {
    key = "public/" + key;
  }
  const preSignUploadData = await getPreSignUpload(key);
  await uploadFileToS3(preSignUploadData, file, onUploadProgress);
};
