import { useQueryClient } from "@tanstack/react-query";
import { useRequestStatus } from "Utilities";
import { portfolioService } from "modules/portfolio/client/PortfolioService";
import portfolioKeys from "./portfolio.queryKeys";

const useDeleteFeedback = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const queryClient = useQueryClient();
  const { error, setRequestStatus, isSuccessful, isLoading } = useRequestStatus();

  const deleteFeedback = ({ eventId, feedbackId }: { eventId: string; feedbackId: string }) => {
    setRequestStatus({ status: "pending", error: "" });

    portfolioService
      .deleteFeedback(feedbackId)
      .then(() => {
        setRequestStatus({ status: "success", error: "" });
        queryClient.invalidateQueries({
          queryKey: portfolioKeys.feedbackList(),
        });
        queryClient.removeQueries({
          queryKey: portfolioKeys.feedbackDetail(eventId),
        });
        onSuccess();
      })
      .catch((error) => {
        setRequestStatus({ status: "error", error });
        onError();
      });
  };

  return {
    deleteFeedback,
    deletingError: error,
    deletingFeedback: isLoading,
    deletingSuccessful: isSuccessful,
  };
};

export default useDeleteFeedback;
