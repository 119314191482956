import React from "react";
import { get } from "lodash";
import { gql, useQuery } from "@apollo/client";
import { inviteClient, sectionStore, AnalyticsEvents } from "@eolas-medical/core";

import { trackEvent } from "API/Analytics";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { UserInvitation } from "Utilities/types";
import { useNotifications } from "Components/Notifications";

// #region Graphql Operations
const LIST_INVITATIONS = gql`
  query InvitationsByApp($appID: ID!) {
    invitationsByApp(appID: $appID) {
      items {
        id
        appID
        userID
        appUserID
        email
        status
        info
      }
      nextToken
    }
  }
`;
// #endregion

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const useInviteUsers = () => {
  const appID = sectionStore.appID;
  const { showNotification } = useNotifications();
  const [emails, setEmails] = React.useState<string[]>([]);
  const [validationError, setValidationError] = React.useState("");

  const {
    error: refreshError,
    isLoading: isRefreshLoading,
    setRequestStatus: setRefreshRequestStatus,
  } = useRequestStatus();

  const { isLoading, error, setRequestStatus } = useRequestStatus();

  const { data, loading: invitationsLoading, refetch: refetchInvitations } = useQuery(
    LIST_INVITATIONS,
    {
      variables: { appID },
    },
  );

  const invitations: UserInvitation[] = get(data, "invitationsByApp.items", []);

  const onSendInvites = React.useCallback(async () => {
    setRequestStatus({ status: "pending", error: "" });

    try {
      const { items } = await inviteClient.inviteUsers(
        appID,
        emails.map((e) => e.toLowerCase()),
      );

      const pendingUsers = items
        .filter((invitation) => invitation.status === "Pending")
        .map((invitation) => invitation.email);

      const existingUsers = items
        .filter((invitation) => invitation.status === "AlreadyExists")
        .map((invitation) => invitation.email);

      const alreadyAcceptedUsers = items
        .filter((invitation) => invitation.status === "AlreadyAccepted")
        .map((invitation) => invitation.email);

      trackEvent(AnalyticsEvents.SEND_INVITATIONS);
      setEmails([]);

      if (pendingUsers.length > 0) {
        showNotification({
          autoHideTimeout: 5000,
          description: pendingUsers.join(", "),
          title: `Invitation${pendingUsers.length > 1 ? "s" : ""} sent.`,
        });
      }

      if (existingUsers.length > 0) {
        showNotification({
          autoHideTimeout: 5000,
          description: existingUsers.join(", "),
          title: `User${existingUsers.length > 1 ? "s" : ""} already part of the organisation.`,
        });
      }

      if (alreadyAcceptedUsers.length > 0) {
        showNotification({
          autoHideTimeout: 5000,
          description: alreadyAcceptedUsers.join(", "),
          title: `User${
            alreadyAcceptedUsers.length > 1 ? "s" : ""
          } already accepted the invitation.`,
        });
      }

      await refetchInvitations();
      setRequestStatus({ status: "success", error: "" });
    } catch (err: any) {
      errorStore.captureError({ error: err.message, source: "user" });
      setRequestStatus({ status: "error", error: err.message });
    }
  }, [appID, emails, showNotification, refetchInvitations, setRequestStatus]);

  const emailValidator = React.useCallback(
    (value: string) => {
      const isValid = emailRegex.test(value);

      setValidationError(isValid ? "" : "manageUsers_invite_email_validation_error");

      return isValid;
    },
    [setValidationError],
  );

  const onRefreshInvitations = React.useCallback(async () => {
    setRefreshRequestStatus({ status: "pending", error: "" });
    try {
      await refetchInvitations();
      setRefreshRequestStatus({ status: "success", error: "" });
    } catch (err: any) {
      setRefreshRequestStatus({ status: "error", error: err.message });
      errorStore.captureError({ source: "user", error: err });
    }
  }, [refetchInvitations, setRefreshRequestStatus]);

  React.useEffect(() => {
    const tagInputElement = document.getElementsByClassName("react-tag-input__input")[0];
    if (!tagInputElement) return;

    if (emails.length === 0) {
      tagInputElement.classList.add("ml-12");
    } else {
      tagInputElement.classList.remove("ml-12");
    }
  }, [emails]);

  return {
    error,
    emails,
    isLoading,
    invitations,
    refreshError,
    validationError,
    isRefreshLoading,
    invitationsLoading,
    isDisabled: emails.length === 0,
    invitationsError: error || refreshError,
    setEmails,
    onSendInvites,
    emailValidator,
    refetchInvitations,
    onRefreshInvitations,
  };
};
