import { SpecialtyOptions, specialtyOptions } from "@eolas-medical/core";
import { FormElement, Input, Textarea } from "UIKit";
import Select from "UIKit/Select/Select";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "Assets";

interface SpaceFormProps {
  control: any;
  defaultSpecialties?: SpecialtyOptions[];
  isNameValid?: boolean;
}
const SpaceForm = ({ control, defaultSpecialties, isNameValid }: SpaceFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="col-span-2">
        <FormElement required id="name" control={control} label={t("space_create_name")}>
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Input
                {...rest}
                size="lg"
                iconRight={isNameValid ? <CheckIcon className="text-green" /> : undefined}
                placeholder={t("edit_space_details_name_placeholder")}
                value={value}
              />
            );
          }}
        </FormElement>
      </div>

      <div className="col-span-2">
        <FormElement required id="specialty" control={control} label={t("space_create_keyword")}>
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Select
                {...rest}
                isMulti
                options={specialtyOptions}
                defaultValue={defaultSpecialties}
                isSearchable
              />
            );
          }}
        </FormElement>
      </div>

      <div className="col-span-2">
        <FormElement
          required
          id="description"
          control={control}
          label={t("space_create_description")}
        >
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Textarea
                {...rest}
                placeholder={t("edit_space_details_description_placeholder")}
                value={value}
                className="resize-none"
                characterLimit={350}
                spellCheck
              />
            );
          }}
        </FormElement>
      </div>
    </>
  );
};

export default SpaceForm;
