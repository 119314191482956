import React from "react";

import { Title } from "../../Typography";

export interface PageTitleProps {
  title: string;
  subTitle?: string;
  className?: string;
  titleClass?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  subTitle,
  titleClass,
  className = "",
  ...props
}) => {
  return (
    <div className={`justify-center text-center space-y-3 w-full ${className}`} {...props}>
      {subTitle && (
        <Title level={7} className="text-black">
          {subTitle}
        </Title>
      )}

      <Title level={3} className={titleClass} data-testid="pageTitle">
        {title}
      </Title>
    </div>
  );
};
