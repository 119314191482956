import React from "react";

import * as iconPickerIcons from "Assets/Icons/iconPickerIcons";

import { s3UrlToIconMap } from "./iconMappers";
import SVG from "react-inlinesvg";

export interface SectionIconProps {
  size?: number;
  iconURL?: string;
  iconClassname?: string;
  fallbackImageClassname?: string;
}

export const SectionIcon: React.FC<SectionIconProps> = ({
  size,
  iconURL,
  iconClassname,
  fallbackImageClassname,
}) => {
  if (!iconURL) return null;

  if (/.svg/.test(iconURL)) {
    return <SVG src={iconURL} width={size} height={size} className={iconClassname} />;
  }

  const iconType = s3UrlToIconMap[iconURL];
  const Icon = iconPickerIcons[iconType];

  if (Icon) return <Icon width={size} height={size} className={iconClassname} />;

  return (
    <img
      src={iconURL}
      alt="Section Icon"
      className={fallbackImageClassname}
      data-testid="section-fallback-img"
    />
  );
};
