import React from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

import { loader } from "Assets";
import Lottie from "react-lottie-player";

export interface LoadingOverlayProps {
  className?: string;
  label?: string;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  className = "",
  label,
  ...props
}) => {
  return (
    <div
      data-testid="loading-overlay"
      className={tw(`
        flex justify-center items-center z-100 absolute opacity-90 rounded-lg
        inset-0 bg-white overflow-hidden ${className}
      `)}
      {...props}
    >
      <div className={`flex flex-col justify-center items-center py-8 ${className}`} {...props}>
        <Lottie
          style={{
            width: "500px",
            height: "500px",
          }}
          loop={true}
          play={true}
          animationData={loader}
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice",
          }}
        />
        <span className={`text-2xl font-semibold mb-4 text-center`}>{label}</span>
      </div>
    </div>
  );
};
