import { useCallback } from "react";
import { portfolioService } from "modules/portfolio/client/PortfolioService";
import { useFileDownload, OnDownloadActions, GetFileAsync } from "shared/hooks/useFileDownload";

interface UseExportPdfPortfolio {
  exportPdfPortfolio: (ownerId: string, actions?: OnDownloadActions) => void;
  exportingPdfPortfolio: boolean;
}

const useExportPdfPortfolio = (ownerName: string): UseExportPdfPortfolio => {
  const getFileUrl: GetFileAsync = useCallback(
    async (ownerId: string) => {
      const response = await portfolioService.exportPortfolio(ownerId, "pdf");
      return { fileName: ownerName, fileExt: "pdf", fileUrl: response.s3DownloadUrl };
    },
    [ownerName],
  );

  const { downloadFile, preparingDownload } = useFileDownload({
    getFileAsync: getFileUrl,
  });

  const exportPdfPortfolio = useCallback(
    async (ownerId: string, actions?: OnDownloadActions) => {
      downloadFile(ownerId, { onSuccess: actions?.onSuccess, onError: actions?.onError });
    },
    [downloadFile],
  );

  return {
    exportPdfPortfolio,
    exportingPdfPortfolio: preparingDownload,
  };
};

export default useExportPdfPortfolio;
