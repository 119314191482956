import { useLaunchDarkly } from "Contexts";
import { useEffect, useState } from "react";
import { webStore } from "Stores/WebStore";
import { LDFlagNames } from "Utilities/types";

export const useWhatsNew = () => {
  const [whatsNewURL, setWhatsNewURL] = useState("");
  const { flags } = useLaunchDarkly();

  const whatsNewURLValue = flags?.[LDFlagNames.WHATS_NEW];

  const onCloseModal = () => {
    webStore.setWhatsNewSeenStatus(whatsNewURL);
    setWhatsNewURL("");
  };

  useEffect(() => {
    if (whatsNewURLValue) {
      setWhatsNewURL(whatsNewURLValue);
    }
  }, [whatsNewURLValue]);

  return {
    whatsNewURL,
    onCloseModal,
  };
};
