import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthFunctionNames, AnalyticsEvents } from "@eolas-medical/core";

import { trackEvent } from "API/Analytics";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { resendVerificationCode } from "../auth.actions";

export interface SendResendVerificationCode {
  verifyEmail: string;
}

const defaultValues: SendResendVerificationCode = {
  verifyEmail: "",
};

const schema = yup.object().shape({
  verifyEmail: yup
    .string()
    .required("createAccount_forgotPassword_email_required")
    .email("createAccount_forgotPassword_email_invalid"),
});

export const useResendVerificationCode = (setStage: any, setUserEmail: any) => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();
  const { control, handleSubmit } = useForm<SendResendVerificationCode>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(({ verifyEmail }) => {
    setRequestStatus({ status: "pending", error: "" });
    resendVerificationCode(verifyEmail)
      .then(() => {
        trackEvent(AnalyticsEvents.VERIFY_EMAIL_COMPLETED);
        trackEvent(AnalyticsEvents.VERIFY_EMAIL_DURATION);
        setRequestStatus({ status: "success", error: "" });
        setStage();
        setUserEmail(verifyEmail);
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          authFunctionName: AuthFunctionNames.resendConfirmationCode,
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  });

  return {
    error,
    control,
    onSubmit,
    isLoading,
  };
};
