import React from "react";
import { InnerModalWrapper } from "UIKit";
import { InvitationPayload } from "../types";
import { SameUserStep } from "./SameUserStep";
import { LoginAndAcceptStep } from "./LoginAndAcceptStep";
import useLoginFlow from "../pages/hooks/useLoginFlow";

interface LoginFlowProps {
  invitation: InvitationPayload;
}

export const LoginFlow: React.FC<LoginFlowProps> = ({ invitation }: LoginFlowProps) => {
  const { status } = useLoginFlow(invitation);

  return (
    <InnerModalWrapper>
      {status === "SameUser" && <SameUserStep />}

      {status === "LoginRequired" && (
        <LoginAndAcceptStep email={invitation.email} invitationID={invitation.invitationID} />
      )}
    </InnerModalWrapper>
  );
};
