import { useRouteMatch } from "react-router-dom";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";
import { InnerPageWrapper, NavButton, PageTitle } from "UIKit";
import { EolasList } from "Components/EolasFileList/EolasList";

import { EventStatus, TimelineData } from "modules/portfolio/types";
import { FilterSection } from "Pages/MeTab/pages/Portfolio/components/FilterSection/FilterSection";
import TimelineCard from "Pages/MeTab/pages/Portfolio/components/TimelineItem/TimelineCard";
import NoEventsMessage from "Pages/MeTab/pages/Portfolio/components/NoEventsMessage/NoEventsMessage";

import useEventList from "modules/portfolio/data/useEventList";
import usePortfolioParams from "Pages/MeTab/pages/Portfolio/hooks/usePortfolioParams";
import useFilteredEventList from "Pages/MeTab/pages/Portfolio/hooks/useFilteredEventList";
import ExportPortfolioButton from "Pages/MeTab/pages/Portfolio/components/ExportPortfolioButton/ExportPortfolioButton";

export const SuperviseeEventList = () => {
  const { superviseeId, superviseeName } = usePortfolioParams();
  const { url } = useRouteMatch();
  const { eventList, eventListLoading } = useEventList(superviseeId);

  const {
    filteredEventList,
    filterObject,
    areFiltersApplied,
    searchInput,
    onUpdateFilters,
    onSearchInputChange,
  } = useFilteredEventList(eventList);

  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-center">
        <PageTitle className="my-4" title={superviseeName} />
        <NavButton to={url.replace(`/${superviseeId}/${superviseeName}`, "")} />
      </div>

      <div className="flex space-x-4 justify-center">
        <ExportPortfolioButton
          ownerId={superviseeId}
          ownerName={superviseeName}
          onSuccess={() => trackEvent(AnalyticsEvents.SUPERVISOR_EXPORT_SUPERVISEES_PORTFOLIO)}
        />
      </div>

      <div>
        <FilterSection
          filterObject={filterObject}
          eventStatuses={Object.values(EventStatus)}
          searchInput={searchInput}
          areFiltersApplied={areFiltersApplied}
          onUpdateFilters={onUpdateFilters}
          onSearchInputChange={onSearchInputChange}
        />

        <EolasList<TimelineData>
          items={filteredEventList}
          renderItem={(item) => {
            return (
              <TimelineCard
                date={item.date!}
                title={item.title}
                type={item.type}
                status={item.status!}
                url={`${url}/${item.type.toLowerCase()}/${item.id}`}
              />
            );
          }}
          isLoading={eventListLoading}
          emptyItemsComponent={
            <NoEventsMessage searchText={searchInput} areFiltersApplied={areFiltersApplied} />
          }
        />
      </div>
    </InnerPageWrapper>
  );
};
