import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { UpdateFileInput } from "@eolas-medical/core/src/graphql";

import { formatDate } from "Utilities";
import { Button, InnerModalWrapper, Loader, SuccessModal } from "UIKit";

import { useNewsItemStats } from "Hooks";
import { AppLevelSection, OrganisationLevelSection } from "@eolas-medical/core";

interface NewsItem extends UpdateFileInput {
  createdAt: string;
}

export interface NewsItemStatsProps {
  newsItem: NewsItem;
  sectionType: AppLevelSection.newsFeed | OrganisationLevelSection.communicationPortal;
  onCloseModal(): void;
}

export const NewsItemStatsModal: React.FC<NewsItemStatsProps> = ({
  newsItem,
  onCloseModal,
  sectionType,
}) => {
  const { t } = useTranslation();
  const {
    step,
    error,
    onCancel,
    isLoading,
    isSuccess,
    openedItems,
    unopenedItems,
    onSendReminders,
    analyticsError,
    analyticsLoading,
    onConfirmSendingReminders,
  } = useNewsItemStats(newsItem.id, newsItem.name as string, sectionType);

  const data = {
    type: "",
    labels: ["Opened", "Not Opened"],
    datasets: [
      {
        data: [openedItems, unopenedItems],
        backgroundColor: ["#3CA2E4", "#DAEDFA"],
        borderColor: ["#3CA2E4", "#DAEDFA"],
        borderWidth: 2,
      },
    ],
  };

  if (isSuccess) {
    return <SuccessModal onComplete={onCloseModal} text={`${sectionType}_reminders_sent`} />;
  }

  return (
    <InnerModalWrapper>
      <div className="flex flex-col px-8 py-4 lg:py-6 border-b border-grey">
        <span className="font-semibold text-lg">{newsItem.name}</span>
        <span className="text-sm text-grey-dark">{newsItem.info}</span>
        <span className="text-sm text-grey-dark">{formatDate(new Date(newsItem.createdAt))}</span>
      </div>

      {analyticsError ? (
        <div className="h-20 flex items-center justify-center">
          <span className="text-red text-center">{analyticsError}</span>
        </div>
      ) : analyticsLoading ? (
        <Loader className="h-64" />
      ) : step === "confirmation" ? (
        <div className="flex flex-col p-8">
          <span className="text-lg mb-8 text-center">
            {t(`${sectionType}_send_reminders_confirmation`)}
          </span>
          <div className="flex items-center justify-center">
            <Button
              color="grey"
              weight="regular"
              variant="outline"
              onClick={onCancel}
              className="mr-2 w-40"
            >
              {t("general_cancel")}
            </Button>
            <Button
              color="blue"
              weight="regular"
              variant="outline"
              isLoading={isLoading}
              className="ml-2 w-40"
              onClick={onConfirmSendingReminders}
            >
              {t("Send")}
            </Button>
          </div>
          {error && <span className="text-red text-center mt-4">{error}</span>}
        </div>
      ) : (
        <div className="flex flex-col p-4 lg:p-8">
          <div className="self-center h-64 w-64 mb-8 relative">
            <div className="flex flex-col items-center absolute -bottom-6 -right-6">
              <span data-testid="totalOpens" className="font-semibold text-lg">
                {openedItems}
              </span>
              <span className="text-sm">Total Opens</span>
            </div>

            <Doughnut data={data} type="line" />
          </div>

          <Button
            weight="regular"
            variant="outline"
            onClick={onSendReminders}
            className="self-center mt-6"
          >
            {t(`${sectionType}_send_read_reminder`)}
          </Button>
        </div>
      )}
    </InnerModalWrapper>
  );
};
