import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { SubSectionRouteParams } from "Utilities/types";
import { DeleteFileModal, EolasFileList, VideoPlayer } from "Components";
import { useMainSectionVisit, useSelectSimpleFileWithVideoPlayer } from "Hooks";
import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSubSection from "modules/spaces/data/useSpaceSubSection";
import { AddItemWithAttachmentModal } from "Pages/Spaces/components/AddEolasFile";

export const WellbeingSection: React.FC<RouteComponentProps<SubSectionRouteParams>> = observer(
  ({ match: { url } }) => {
    const { t } = useTranslation();
    const { isAdmin } = useSpacesContext();

    const { spaceSubSection } = useSpaceSubSection(AppLevelSection.wellbeing);

    useMainSectionVisit({
      subSectionId: spaceSubSection.subSectionId,
      mainSectionId: AppLevelSection.wellbeing,
    });

    const {
      modal,
      video,
      videoRef,
      setModal,
      onCloseModal,
      onSelectFile,
      onCloseVideo,
      onVideoProgress,
    } = useSelectSimpleFileWithVideoPlayer();

    const onAddFile = () =>
      setModal(
        <AddItemWithAttachmentModal
          subSectionId={spaceSubSection.subSectionId}
          onCloseModal={onCloseModal}
          mainSectionID={AppLevelSection.wellbeing}
        />,
      );

    const onEditFile = (file: EolasFile) => {
      setModal(
        <AddItemWithAttachmentModal
          file={file}
          subSectionId={spaceSubSection.subSectionId}
          onCloseModal={onCloseModal}
          mainSectionID={AppLevelSection.wellbeing}
        />,
      );
    };

    const onDeleteFile = (file: EolasFile) => {
      setModal(<DeleteFileModal onCloseModal={onCloseModal} file={file} />);
    };

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSubSection.subSectionName ?? ""} />
          <NavButton to={url.replace(`/${spaceSubSection.encodedSubSectionId}`, "")} />

          {isAdmin && <AddButton onClick={onAddFile}>{t("general_add_new_file")}</AddButton>}

          {video.url && (
            <div ref={videoRef}>
              <VideoPlayer
                url={video.url}
                title={video.name}
                closeVideo={onCloseVideo}
                onVideoProgress={onVideoProgress}
              />
            </div>
          )}

          <EolasFileList
            isAdmin={isAdmin}
            onEdit={onEditFile}
            onAddItem={onAddFile}
            onDelete={onDeleteFile}
            subSectionId={spaceSubSection.subSectionId}
            onSelectFile={onSelectFile}
            mainSectionId={AppLevelSection.wellbeing}
          />
        </InnerPageWrapper>

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </>
    );
  },
);
