import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AddButton, Button, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import ContentItemList from "./components/ContentItemList/ContentItemList";
import { removeLastUrlPath } from "Utilities";
import useContentRepository from "modules/contentRepository/data/useContentRepository";
import useContentSection from "modules/contentRepository/data/useContentSection";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import AddContentItemWizard from "./components/AddContentItemWizard/AddContentItemWizard";
import useContentRepositoryParams from "../../hooks/useContentRepositoryParams";

const ContentItems = () => {
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const { replace, push, location } = useHistory();
  const { contentRepositoryId, subSectionId } = useContentRepositoryParams();

  const { isAdmin } = useSpacesContext();
  const { contentRepository } = useContentRepository(contentRepositoryId);
  const { contentSection } = useContentSection(subSectionId);

  const isSubSection = !!subSectionId;

  const [showModal, setShowModal] = useState(false);
  const backUrl = removeLastUrlPath(url, isSubSection ? 1 : 2);

  const handleAddItem = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleManageExpired = () => {
    push(`${location.pathname}/expired-items`);
  };

  const pageTitle = isSubSection ? contentSection?.name : contentRepository?.name;

  return (
    <>
      <Modal open={!!showModal} onClose={handleCloseModal}>
        <AddContentItemWizard
          onClose={handleCloseModal}
          mainSectionId={contentRepositoryId}
          subSectionId={subSectionId}
        />
      </Modal>
      <InnerPageWrapper>
        <div className="flex flex-col justify-center">
          <PageTitle className="my-4" title={pageTitle || ""} />
          <NavButton onClick={() => replace(backUrl)} />
        </div>

        {isAdmin && (
          <div className="flex justify-center items-center space-x-4">
            <AddButton onClick={handleAddItem} data-testid="add-content-item-button">
              {t("Add Item")}
            </AddButton>
            {!isSubSection && (
              <Button variant="outline" color="white" size="lg" onClick={handleManageExpired}>
                {t("repository_manage_expired")}
              </Button>
            )}
          </div>
        )}

        <ContentItemList
          contentRepositoryId={contentRepositoryId}
          subSectionId={subSectionId}
          isAdmin={isAdmin}
        />
      </InnerPageWrapper>
    </>
  );
};

export default ContentItems;
