import { get } from "lodash";
import { useMemo } from "react";
import { sectionStore, AppUser } from "@eolas-medical/core";
import { gql, useMutation, useQuery, ApolloQueryResult } from "@apollo/client";

const updateFileMutation = gql`
  mutation updateFile($input: UpdateFileInput!) {
    updateFile(input: $input) {
      id
      appID
      organisationID
      fileName
      fileDescription
      fileType
      fileKey
      fileSize
      mainSection
      subSection
      deleted
      expiryDate
      info
      moreInfo
      moreInfoTwo
      createdAt
      updatedAt
      searchField
      highlighted
    }
  }
`;

const UPDATE_APP = gql`
  mutation UpdateApp($input: UpdateAppInput!, $condition: ModelAppConditionInput) {
    updateApp(input: $input, condition: $condition) {
      name
      id
      parentID
      organisationID
      appType
      guidelineOrganisations {
        name
        logo
      }
      licenceStatus {
        licenceType
        expirationDate
        signedBy
        updatedAt
        createdAt
      }
      organisation {
        id
        parentID
        name
        metadata
        location
        info
        infoTwo
        createdAt
        updatedAt
        readGroups
        updateGroups
      }
      childrenOrder {
        id
        type
        description
        icon
        name
        disabled
      }
      createdAt
      updatedAt

      trustedEmail
      metadata
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      given_name
      family_name
      email
      verifiedEmails {
        email
        updatedAt
        createdAt
      }
      jobTitle
      levelOfTraining
      specialtyInterests
      completedTour
      acceptedTerms
      metadata
      createdAt
      updatedAt
    }
  }
`;

export const updateOrganisationMutation = gql`
  mutation updateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      id
      mainSections {
        id
        filesOrder
        sectionName
        deactivated
        sectionDescription
        longDescription
        subSections {
          id
          subSectionName
          deleted
          subSectionDescription
          subSectionImage
          filesOrder
          tracked
          longDescription
        }
      }
    }
  }
`;

export const appUserByApp = gql`
  query AppUserByApp(
    $appID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appUserByApp(
      appID: $appID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appID
        userID
        given_name
        family_name
        email
        accessLevel
        hospitalLevelAdmin
        pnTokens
        completedFiles {
          id
          markedAsComplete
          completedDate
        }
        pnTokens
      }
      nextToken
    }
  }
`;

export const useUpdateFile = () => {
  const [_updateFile] = useMutation(updateFileMutation);

  const updateFile = (input: any) => {
    return _updateFile({ variables: { input } });
  };

  return updateFile;
};

export const useUpdateApp = () => {
  const [_updateApp, { called, error }] = useMutation(UPDATE_APP);

  const updateApp = (input: any) => {
    return _updateApp({ variables: { input } });
  };

  return { updateApp, called, error };
};

export const useUpdateOrganisation = () => {
  const [_updateOrganisation] = useMutation(updateOrganisationMutation);

  const updateOrganisation = (input: any) => {
    return _updateOrganisation({ variables: { input } });
  };

  return updateOrganisation;
};

export const useUpdateUser = () => {
  const [_updateUser] = useMutation(UPDATE_USER);

  const updateUser = (input: any) => {
    return _updateUser({ variables: { input } });
  };

  return updateUser;
};

interface GetAppUsersReturnType {
  users: AppUser[];
  usersError?: string;
  userTokens: string[];
  usersLoading: boolean;

  refetchUsers(input: any): Promise<ApolloQueryResult<any>>;
}

export const useGetAppUsers = (): GetAppUsersReturnType => {
  const { data, error, loading: usersLoading, refetch: refetchUsers } = useQuery(appUserByApp, {
    variables: { appID: sectionStore.appID, filter: { deleted: { eq: "false" } } },
  });

  const users: AppUser[] = get(data, "appUserByApp.items", []);

  const userTokens: any = useMemo(() => {
    return users
      .flatMap(({ pnTokens }) => {
        return pnTokens ?? [];
      })
      .filter(Boolean);
  }, [users]);

  return {
    users,
    userTokens,
    usersLoading,
    refetchUsers,
    usersError: error?.message,
  };
};
