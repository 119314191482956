import { useState } from "react";
import { dateOnly } from "Utilities/helpers";
import { useLocalSearch } from "shared/hooks/useLocalSearch";

import { FilterObjectType, TimelineData } from "modules/portfolio/types";
import { applySelectedFilters } from "../helpers";

const useFilteredEventList = (eventList: TimelineData[]) => {
  const [filterObject, setFilterObject] = useState<FilterObjectType>({
    endDate: dateOnly(),
    eventStatus: [],
    eventType: [],
    startDate: dateOnly(),
  });

  const filteredTimeline = applySelectedFilters({
    timelineEvents: eventList,
    dateFilters: { startDate: filterObject.startDate, endDate: filterObject.endDate },
    statusFilters: filterObject.eventStatus,
    typeFilters: filterObject.eventType,
  });

  const { searchInput, searchResult, onSetSearchInput } = useLocalSearch({
    data: filteredTimeline,
    keysToSearch: ["title", "type"],
  });

  const areFiltersApplied = Boolean(
    !(filterObject.startDate === dateOnly() && filterObject.endDate === dateOnly()) ||
      filterObject.eventStatus.length ||
      filterObject.eventType.length,
  );

  return {
    filteredEventList: searchResult,
    filterObject,
    areFiltersApplied,
    searchInput,
    onSearchInputChange: onSetSearchInput,
    onUpdateFilters: setFilterObject,
  };
};

export default useFilteredEventList;
