import { AppLevelSection, OrganisationLevelSection, sectionStore } from "@eolas-medical/core";
import { SpaceSection } from "../../../Pages/Spaces/types";

interface UseSpaceSection {
  spaceSection: SpaceSection;
}
const useSpaceSection = (
  sectionId: AppLevelSection | OrganisationLevelSection,
): UseSpaceSection => {
  const section = sectionStore.getChildReferenceByMainSectionType(sectionId)!;

  return { spaceSection: section };
};

export default useSpaceSection;
