import {
  Button,
  FormElement,
  InnerModalWrapper,
  Input,
  ModalBody,
  ModalHeader,
  Text,
  Title,
} from "UIKit";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useUpdateUserProfile from "../../../hooks/useUpdateUserProfile";
import {
  ProfessionalBody,
  ProfileVerification,
  ProfileVerificationStatus,
  userStore,
} from "@eolas-medical/core";
import { ProfessionalBodyLabel } from "Pages/MeTab/types";
import { ProfileFormElements, SelectedVerification } from "../ProfileVerificationModal";

export interface VerificationFormProps {
  selectedVerification?: SelectedVerification;
  formElements: ProfileFormElements[];
  profileVerification?: ProfileVerification;
  onClose: () => void;
}

const VerificationForm = ({
  selectedVerification,
  formElements,
  profileVerification,
  onClose,
}: VerificationFormProps) => {
  const { t } = useTranslation();
  const { id } = userStore.userData;
  const hasProfessionalBody = !selectedVerification?.type;

  const { updateUser, updatingUser } = useUpdateUserProfile({
    onSuccess: (user) => {
      userStore.updateData({ user });
      onClose();
    },
  });
  const schema = yup.object().shape({
    professionalNumber: yup
      .string()
      .required(t("profile_verification_professional_number_required")),
    professionalBody: hasProfessionalBody
      ? yup.string().required(t("profile_verification_professional_body_required"))
      : yup.string().nullable(),
  });

  const defaultValues = {
    professionalNumber: profileVerification?.registrationNumber || "",
    professionalBody: hasProfessionalBody
      ? ProfessionalBodyLabel[profileVerification?.professionalBody as ProfessionalBody]
      : undefined,
  };

  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleSubmitVerification = ({
    professionalNumber,
    professionalBody,
  }: {
    professionalNumber: string;
    professionalBody?: string;
  }) => {
    if (id) {
      const profileVerificationData = {
        status: ProfileVerificationStatus.PENDING,
        registrationNumber: professionalNumber,
        professionalBody: professionalBody || selectedVerification?.type || "",
      };
      updateUser(id, { profileVerification: profileVerificationData });
    }
  };

  return (
    <InnerModalWrapper>
      <form onSubmit={handleSubmit(handleSubmitVerification)}>
        <ModalHeader className="flex flex-col space-y-3">
          <Title level={5}>{selectedVerification?.label}</Title>
        </ModalHeader>
        <ModalBody>
          <div className="overflow-y-auto w-full" style={{ maxHeight: "60vh" }}>
            {formElements.map(
              ({ id, label, placeholder }: { id: string; label: string; placeholder: string }) => (
                <FormElement key={id} required id={id} control={control} label={label}>
                  {(field) => <Input {...field} placeholder={placeholder} />}
                </FormElement>
              ),
            )}
            <Button
              iconLeft="ShareIcon"
              className="flex items-center justify-center w-full h-12 rounded-3xl"
              type="submit"
              isLoading={updatingUser}
            >
              <Text level={2} className="font-bold ml-2">
                {t("profile_verification_send_verification_button")}
              </Text>
            </Button>
          </div>
        </ModalBody>
      </form>
    </InnerModalWrapper>
  );
};

export default VerificationForm;
