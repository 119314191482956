import { EolasFileType } from "Utilities/types";
import { FileTypeOption } from "./FileTypeOption";

export interface FileTypeSelectorProps {
  allowedTypes: EolasFileType[];
  selectedType: EolasFileType;
  onSelect: (type: EolasFileType) => void;
  isDisabled?: boolean;
  className?: string;
}

export const FileTypeSelector = ({
  allowedTypes,
  selectedType,
  onSelect,
  isDisabled = false,
  className,
}: FileTypeSelectorProps) => {
  return (
    <div className={className}>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {allowedTypes.map((type) => (
          <FileTypeOption
            key={`file_type_${type}`}
            type={type}
            onSelect={onSelect}
            isSelected={type === selectedType}
            isDisabled={isDisabled}
          />
        ))}
      </div>
    </div>
  );
};
