import { AppLevelSection, MedusaStatus, UserInvitationStatus } from "@eolas-medical/core";
import {
  Section,
  Checklist,
  File as _File,
  PublishFileInput,
  ChildReference as ApiChildRef,
} from "@eolas-medical/core/src/graphql";
import { App } from "API";
import { ContentRepositoryId } from "modules/contentRepository/types";

export interface SubSectionRouteParams {
  sectionID: string;
}

export type EolasChecklist = Omit<Checklist, "__typename">;

export enum AppEnv {
  DEVELOPMENT = "development",
  STAGING = "staging",
  PRODUCTION = "production",
}

export type Hierarchy = "parent" | "child";

export enum UserRole {
  user = "user",
  admin = "admin",
}

export type AllLevelAppIDs =
  | "departmentLevelAppID"
  | "departmentLevelMainOrganisationID"
  | "hospitalLevelOrganisationID"
  | "hospitalLevelOrganisationTrustID";

export enum BaseAppRoute {
  myProfile = "my-profile",
  department = "department",
  hospital = "hospital",
  userGuide = "userGuide",
  selectOrganisation = "select-organisation",
  spaces = "spaces",
  organisation = "organisation",
  selectSpace = "spaces/select-space",
  knowledge = "knowledge",
  chat = "chat",
}

export enum CalculatorSections {
  creatinineCalculator = "creatinine_clearance_calculator",
  bodyWeightCalculator = "ideal_body_weight_calculator",
  gentamicinCalculator = "gentamicin_dosage_calculator",
}

export enum MedusaMainSections {
  medusaFiles = "medusaFiles",
}

export const sectionsExcludedFromTracking = [
  AppLevelSection.howTo,
  AppLevelSection.wellbeing,
  AppLevelSection.equipmentLocations,
];

export const NO_EXPIRY_DATE = "function not used";

// enum for each section's subsections

export enum MajorIncidentType {
  active = "active",
  deactivated = "deactivated",
}

export enum MajorIncidentSubSection {
  documents = "majorIncidentDocuments",
  incidents = "incidents",
}

export enum IncidentReportingResolveStatus {
  resolved = "resolved",
  notResolved = "notResolved",
}

// S3 related values

export enum AppUserAccessLevels {
  ADMIN = "admin",
  READ_ONLY = "readonly",
}

export type EolasFileType =
  | ""
  | "pdf"
  | "png"
  | "mp4"
  | "mov"
  | "link"
  | "ms-office"
  | "extension"
  | "blob"
  | "image"
  | "video/quicktime"
  | "flashcard"
  | "jpeg"
  | "jpg"
  | "ppt"
  | "pptx"
  | "doc"
  | "docx"
  | "xlsx"
  | "xls"
  | "apng"
  | "heic"
  | "mov";

export type EolasFile = Omit<_File, "__typename">;

export type EolasChecklistSection = Omit<Section, "__typename" | "metadata"> & {
  metadata: Checklist;
};

export type ChildReference = Omit<ApiChildRef, "__typename">;

export type PublishFileDTO = PublishFileInput & { id?: string };

export declare type AccessRequest = {
  id: string;
  appID: string;
  userId: string;
  familyName?: string | null;
  givenName?: string | null;
  family_name?: string | null;
  given_name?: string | null;
  email?: string | null;
  deleted?: string | null;
  app: App;
  readGroups?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
  requestText?: string;
};

export const LANDING_PAGE_SLUG = "landing-page";

// stores and services types

export enum LDFlagNames {
  // features
  HOSPITAL_TAB = "hospital-tab",
  WEB_MAINTENANCE = "web-maintenance",
  ADMIN_INVITE_USERS = "admin-invite-users",
  PDF_TRON_ENABLED = "pdf-tron-rollout",
  MS_OFFICE_FILES_ENABLED = "ms-office-files",
  UPDATED_SEARCH = "updated-search",
  COMMUNITY_TAB = "community-tab",
  MY_PORTFOLIO = "my-portfolio",
  MY_FILES = "my-files",
  NOTIFICATION_CENTER = "notification-center",
  CHECKLIST_OWNERS = "checklist-owners",
  PORTFOLIO_WIZARD = "portfolio-wizard",
  NEW_SIGN_IN = "new-sign-in",
  GENERIC_CONTENT_REPOSITORY = "generic-content-repository",
  FLASHCARDS = "flashcards",

  // department tab sections
  CLINICAL_PATHWAYS_SECTION = "clinical-pathways-section",
  SURVEYS_SECTION = "surveys-section",
  WELLBEING_SECTION = "wellbeing-section",
  EQUIPMENT_LOCATIONS_SECTION = "equipment-locations-section",
  HOW_TO_SECTION = "how-to-section",
  ADMISSION_GUIDES_SECTION = "admission-guides-section",
  PATIENT_LEAFLETS_SECTION = "patient-leaflets-section",
  THE_DEPARTMENT_SECTION = "the-department-section",
  HIGHLIGHTS_SECTION = "highlights-section",
  NEWS_FEED_SECTION = "news-feed-section",
  CHECKLIST_SECTION = "checklists-section",
  GUIDELINES_SECTION = "guidelines-section",
  CLINICAL_DOCUMENTS_SECTION = "clinical-documents-section",
  POLICY_DOCUMENTS_SECTION = "policy-documents-section",
  EMERGENCY_ACTION_CARDS_SECTION = "emergency-action-cards-section",
  EDUCATION_PORTAL_SECTION = "education-portal-section",
  ROTA_SECTION = "rota-section",
  CONTACTS_SECTION = "contacts-section",
  INCIDENT_REPORTING_SECTION = "incident-reporting-section",
  MEET_THE_TEAM_SECTION = "meet-the-team-section",
  HOSPITAL_LISTS_SECTION = "hospital-lists-section",
  MAJOR_INCIDENT_SECTION = "major-incident-section",
  IMPORTANT_LINKS_SECTION = "important-links-section",
  RESEARCH_AND_AUDIT = "research-and-audit-section",
  NOT_USED = "section-not-used",
  CHAT_SECTION = "chat-section",

  // hospital tab sections
  DRUG_MONITORING_SECTION = "drug-monitoring-section",
  HOSPITAL_GUIDELINES_SECTION = "hospital-guidelines-section",
  HOSPITAL_CONTACTS_SECTION = "hospital-contacts-section",
  HOSPITAL_CLINICAL_CALCULATORS_SECTION = "hospital-clinical-calculators-section",
  MEDUSA_SECTIONS = "medusa-medications-section",
  LEARNING_MANAGEMENT = "learning-management",
  COMMUNICATION_PORTAL = "communication-portal",
  GENERIC_ORGANISATION_GUIDELINES_SECTION = "generic-organisation-guidelines-section",
  ORGANISATION_WELLBEING_SECTION = "organisation-wellbeing-section",

  // community tab section
  NATIONAL_GUIDELINES = "national-guidelines-section",
  CT_CLINICAL_CALCULATORS = "community-tab-clinical-calculators",
  COMMUNITY_PATIENT_LEAFLETS = "community-patient-leaflets",
  COMMUNITY_BACKGROUND_SEARCH = "community-background-search",
  US_COMMUNITY_CONTENT = "us-community-content",
  COMMUNITY_MEDICATIONS_SECTION = "community-medications-section",

  // me tab section
  TEACHING_DELIVERY = "teaching-delivery",
  MY_FAVOURITES = "my-favourites",

  // utils
  FORCE_COOKIES = "force-cookies-modal",
  MEDUSA_WEB_VIEW = "medusa-web-viewer",
  SHOW_HOSPITAL_ADMIN = "show-hospital-admin",
  SHOW_CREATE_ORG_BUTTON = "show-create-organisation",
  DISABLE_MEDUSA_ACTIVATION = "disable-medusa-activation",
  NOTIFICATIONS_BANNER = "notification-banner",
  FEDERATED_USERS = "federated-users",
  REGION_SPECIFIC_TARGETING = "region-specific-targeting",
  WHATS_NEW = "new-feature-modal",
  MAKE_GUIDELINE_EXPIRY_MANDATORY = "make-guideline-expiry-mandatory",
  READ_ONLY_ACTIVATED = "readonly-mode-activated",
  CHAT_FEATURE = "chat-feature", // Chat is not used in web
  NEW_APP_REDIRECT = "new-app-redirect",
  WEB_PUSH_NOTIFICATIONS = "web-push-notifications",

  // Belfast Trust Portfolio Events
  BELFAST_TRUST_PORTFOLIO_EVENTS = "belfast-trust-portfolio-events",
}

export interface LDFlags {
  // features
  [LDFlagNames.HOSPITAL_TAB]?: boolean;
  [LDFlagNames.WEB_MAINTENANCE]?: boolean;
  [LDFlagNames.ADMIN_INVITE_USERS]?: boolean;
  [LDFlagNames.PDF_TRON_ENABLED]?: boolean;
  [LDFlagNames.MS_OFFICE_FILES_ENABLED]?: boolean;
  [LDFlagNames.COMMUNITY_TAB]?: boolean;
  [LDFlagNames.MY_PORTFOLIO]?: boolean;
  [LDFlagNames.MY_FILES]?: boolean;
  [LDFlagNames.CHECKLIST_OWNERS]?: boolean;
  [LDFlagNames.PORTFOLIO_WIZARD]?: boolean;
  [LDFlagNames.NEW_SIGN_IN]?: boolean;
  [LDFlagNames.CHAT_FEATURE]?: "enabled" | "disabled" | "registration-only" | "requires-update";
  [LDFlagNames.GENERIC_CONTENT_REPOSITORY]?: boolean;
  [LDFlagNames.FLASHCARDS]?: boolean;

  // department tab sections
  [LDFlagNames.CLINICAL_PATHWAYS_SECTION]?: boolean;
  [LDFlagNames.SURVEYS_SECTION]?: boolean;
  [LDFlagNames.WELLBEING_SECTION]?: boolean;
  [LDFlagNames.EQUIPMENT_LOCATIONS_SECTION]?: boolean;
  [LDFlagNames.HOW_TO_SECTION]?: boolean;
  [LDFlagNames.ADMISSION_GUIDES_SECTION]?: boolean;
  [LDFlagNames.PATIENT_LEAFLETS_SECTION]?: boolean;
  [LDFlagNames.THE_DEPARTMENT_SECTION]?: boolean;
  [LDFlagNames.HIGHLIGHTS_SECTION]?: boolean;
  [LDFlagNames.NEWS_FEED_SECTION]?: boolean;
  [LDFlagNames.CHECKLIST_SECTION]?: boolean;
  [LDFlagNames.GUIDELINES_SECTION]?: boolean;
  [LDFlagNames.CLINICAL_DOCUMENTS_SECTION]?: boolean;
  [LDFlagNames.POLICY_DOCUMENTS_SECTION]?: boolean;
  [LDFlagNames.EMERGENCY_ACTION_CARDS_SECTION]?: boolean;
  [LDFlagNames.EDUCATION_PORTAL_SECTION]?: boolean;
  [LDFlagNames.ROTA_SECTION]?: boolean;
  [LDFlagNames.CONTACTS_SECTION]?: boolean;
  [LDFlagNames.INCIDENT_REPORTING_SECTION]?: boolean;
  [LDFlagNames.MEET_THE_TEAM_SECTION]?: boolean;
  [LDFlagNames.HOSPITAL_LISTS_SECTION]?: boolean;
  [LDFlagNames.MAJOR_INCIDENT_SECTION]?: boolean;
  [LDFlagNames.IMPORTANT_LINKS_SECTION]?: boolean;
  [LDFlagNames.RESEARCH_AND_AUDIT]?: boolean;
  [LDFlagNames.NOT_USED]?: boolean;
  [LDFlagNames.CHAT_SECTION]?: boolean;

  // hospital tab sections
  [LDFlagNames.DRUG_MONITORING_SECTION]?: boolean;
  [LDFlagNames.HOSPITAL_GUIDELINES_SECTION]?: boolean;
  [LDFlagNames.HOSPITAL_CONTACTS_SECTION]?: boolean;
  [LDFlagNames.HOSPITAL_CLINICAL_CALCULATORS_SECTION]?: boolean;
  [LDFlagNames.MEDUSA_SECTIONS]?: boolean;
  [LDFlagNames.LEARNING_MANAGEMENT]?: boolean;
  [LDFlagNames.COMMUNICATION_PORTAL]?: boolean;
  [LDFlagNames.GENERIC_ORGANISATION_GUIDELINES_SECTION]?: boolean;
  [LDFlagNames.ORGANISATION_WELLBEING_SECTION]?: boolean;

  // community tab section
  [LDFlagNames.NATIONAL_GUIDELINES]?: boolean;
  [LDFlagNames.CT_CLINICAL_CALCULATORS]?: boolean;
  [LDFlagNames.COMMUNITY_PATIENT_LEAFLETS]?: boolean;
  [LDFlagNames.COMMUNITY_BACKGROUND_SEARCH]?: boolean;
  [LDFlagNames.US_COMMUNITY_CONTENT]?: boolean;
  [LDFlagNames.COMMUNITY_MEDICATIONS_SECTION]?: boolean;

  // me tab section
  [LDFlagNames.TEACHING_DELIVERY]?: boolean;
  [LDFlagNames.MY_FAVOURITES]?: boolean;

  //utils
  [LDFlagNames.FORCE_COOKIES]?: boolean;
  [LDFlagNames.UPDATED_SEARCH]?: boolean;
  [LDFlagNames.SHOW_HOSPITAL_ADMIN]?: boolean;
  [LDFlagNames.SHOW_CREATE_ORG_BUTTON]?: boolean;
  [LDFlagNames.MEDUSA_WEB_VIEW]?: boolean;
  [LDFlagNames.DISABLE_MEDUSA_ACTIVATION]?: boolean;
  [LDFlagNames.NOTIFICATIONS_BANNER]?: string;
  [LDFlagNames.FEDERATED_USERS]?: boolean;
  [LDFlagNames.REGION_SPECIFIC_TARGETING]?: boolean;
  [LDFlagNames.WHATS_NEW]?: string;
  [LDFlagNames.MAKE_GUIDELINE_EXPIRY_MANDATORY]?: boolean;
  [LDFlagNames.READ_ONLY_ACTIVATED]?: boolean;
  [LDFlagNames.NEW_APP_REDIRECT]?: boolean;
  [LDFlagNames.WEB_PUSH_NOTIFICATIONS]?: boolean;
  [LDFlagNames.NOTIFICATION_CENTER]?: boolean;

  // Belfast Trust Portfolio Events
  [LDFlagNames.BELFAST_TRUST_PORTFOLIO_EVENTS]?: boolean;
}

export type S3GeneratorParams = {
  fileName: string;
  fileFormat: string;
  isPublic?: boolean;
  addExtension?: boolean;
  mainSectionId?: ContentRepositoryId;
};

export type OnPickFileConfig = {
  isPublic?: boolean;
  filePreview?: string;
  addExtension?: boolean;
};

export type OnPickFileFunction = (file: File | null, config: OnPickFileConfig) => void;

export interface MedusaData {
  apiKey?: string;
  status?: MedusaStatus;
  errorMessage?: string;
  cached?: boolean;
}

export interface UserInvitation {
  appID: string;
  appUserID: string;
  email: string;
  id: string;
  info: string;
  status: UserInvitationStatus;
  userID: string;
}

export interface DragEndResult {
  sourceIndex: number;
  targetIndex?: number;
}

export enum NewsItemFileType {
  newsFeed = "newsFeed",
  newsFeedPDF = "pdf",
  newsFeedMp4 = "mp4",
  newsFeedLink = "link",
}

export interface INewsItem {
  fileName: string;
  fileDescription: string;
  fileKey: string;
  fileType: NewsItemFileType | "";
  tracked?: boolean;
  fileID?: string;
  title?: string;
  info?: string;
  createdAt?: any;
  id?: string;
}

export interface CompletedFileProps {
  isChecked: boolean;
  isCompleted: boolean;
  completionLabel: string;
  isButtonDisabled: boolean;
}

export enum DateFormat {
  DD_MM_YYY = "do MMMM yyyy",
}

export type ActiveTab =
  | "my-profile"
  | "department"
  | "hospital"
  | "select-organisation"
  | "knowledge"
  | "spaces"
  | "organisation"
  | "select-space";

export enum Action {
  DELETE = "Delete",
  EDIT = "Edit",
  DOWNLOAD = "Download",
}

export enum ValidVideoExtension {
  MP4 = "mp4",
  MOV = "mov",
}
