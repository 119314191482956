import { DownloadFileButton } from "Components";
import { DefaultFileControls, FileTileWrapper, FileTileProps } from "./shared";

export interface GuidelineFileTileProps extends FileTileProps {
  organisationImageURL?: string;
}

export const GuidelineFileTile: React.FC<GuidelineFileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  isDragging,
  organisationImageURL,
  onSelectFile = () => {},
  ...handlers
}) => {
  return (
    <FileTileWrapper
      className="h-40"
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col self-start flex-3">
        <span
          className="text-lg font-semibold line-clamp-1 leading-10"
          title={eolasFile.name ?? ""}
        >
          {eolasFile.name}
        </span>
        <span className="text-md text-grey-dark line-clamp-2">{eolasFile.info}</span>
      </div>

      <div className="w-fit self-start flex flex-col items-end justify-between h-full">
        <DownloadFileButton eolasFile={eolasFile} />
        {organisationImageURL && (
          <div className="h-20">
            <img
              alt="guideline-icon"
              src={organisationImageURL}
              className="h-full w-36 lg:w-48 rounded-sm object-cover"
            />
          </div>
        )}
      </div>

      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
