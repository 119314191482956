import React from "react";

import {
  AppUser,
  fileStore,
  EolasFile,
  sectionStore,
  AppLevelSection,
  OrganisationLevelSection,
} from "@eolas-medical/core";

import { daysLeftForCompetency, hasExpirationLength } from "Utilities";
import { CompletedFiles } from "@eolas-medical/core/src/graphql";

export type TrackedFile = {
  id: string;
  name: string;
  isCompleted: boolean;
};

export const useTrackedFiles = (appUser: AppUser) => {
  const userCompletedFiles = React.useMemo(() => appUser.completedFiles ?? [], [
    appUser.completedFiles,
  ]) as CompletedFiles[];

  const { id: educationPortalSectionId } =
    sectionStore.getChildReferenceByMainSectionType(AppLevelSection.educationPortal)! ?? {};

  const { id: learningManagementSectionId } =
    sectionStore.getChildReferenceByMainSectionType(OrganisationLevelSection.learningManagement)! ??
    {};

  const getFileCompletionStatus = React.useCallback(
    (file: EolasFile) => {
      return userCompletedFiles.some((completedFileInfo: CompletedFiles) => {
        const daysLeft = daysLeftForCompetency({
          expirationInMonths: file.moreInfo ?? "",
          completedDate: completedFileInfo.markedAsComplete!,
        });
        const doesExpire = hasExpirationLength(file.moreInfo ?? "");

        if (doesExpire) {
          return completedFileInfo.id === file.id && completedFileInfo.markedAsComplete && daysLeft;
        }

        return completedFileInfo.id === file.id && completedFileInfo.markedAsComplete;
      });
    },
    [userCompletedFiles],
  );

  const trackedFiles: TrackedFile[] = React.useMemo(() => {
    const educationPortalFileOrder = sectionStore
      .getChildrenOrder(educationPortalSectionId)
      .flatMap((child) => {
        return sectionStore.getChildrenOrder(child.id);
      });

    const learningManagementSections = sectionStore
      .getChildrenOrder(learningManagementSectionId)
      .flatMap((child) => {
        return sectionStore.getChildrenOrder(child.id);
      });

    const educationPortalFiles = fileStore.getFiles(educationPortalFileOrder);
    const learningManagementFiles = fileStore.getFiles(learningManagementSections);

    return educationPortalFiles.concat(learningManagementFiles).map((file) => {
      const isCompleted = getFileCompletionStatus(file);

      return {
        isCompleted,
        id: file.id,
        name: file.name as string,
      };
    });
  }, [educationPortalSectionId, learningManagementSectionId, getFileCompletionStatus]);

  return trackedFiles;
};
