import { TitleAndDescriptionStep } from "./TitleAndDescriptionStep";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTitleAndDescriptionStepValidationSchema } from "./useTitleAndDescriptionValidationSchema";
import { InputType, TitleAndDescriptionValues } from "./types";
import { Step } from "../../types";

interface UseTitleAndDescriptionStepProps<T> {
  stepName: T;
  onSubmit: (data: TitleAndDescriptionValues) => void;
  title: string;
  titleLabel: string;
  titlePlaceholder: string;
  descriptionLabel: string;
  descriptionPlaceholder: string;
  initialTitle?: string;
  initialDescription?: string;
  isLoading?: boolean;
  descriptionInputType?: InputType;
  nextLabel?: string;
  backLabel?: string;
}

export const useTitleAndDescriptionStep = <T extends string>({
  stepName,
  onSubmit,
  isLoading,
  title,
  titleLabel,
  initialTitle,
  titlePlaceholder,
  descriptionLabel,
  initialDescription,
  descriptionPlaceholder,
  descriptionInputType = "text-area",
  nextLabel,
  backLabel,
}: UseTitleAndDescriptionStepProps<T>): Step<T> => {
  const validationSchema = useTitleAndDescriptionStepValidationSchema();

  const { handleSubmit, formState, control } = useForm<TitleAndDescriptionValues>({
    defaultValues: {
      title: initialTitle || "",
      description: initialDescription || "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;
  const errorMessage = errors?.title?.message;

  const canProceed = formState.isValid && !formState.isSubmitting;

  return {
    name: stepName,
    title,
    submitError: !!errors,
    canProceed: canProceed,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    nextLabel,
    backLabel,
    component: (
      <TitleAndDescriptionStep
        control={control}
        errorMessage={errorMessage}
        titleLabel={titleLabel}
        titlePlaceholder={titlePlaceholder}
        descriptionLabel={descriptionLabel}
        descriptionPlaceholder={descriptionPlaceholder}
        descriptionInputType={descriptionInputType}
      />
    ),
  };
};
