import { ActiveTab } from "Utilities/types";
import { createContext } from "react";

export interface NavigationContextValue {
  activeTab: ActiveTab;
  drawerExpanded: boolean;
  hasSpacesTab: boolean;
  hasOrganisationTab: boolean;
  hasSelectedSpace: boolean;
  hasKnowledgeTab: boolean;
  goMeTab: () => void;
  goToOrganisationTab: () => void;
  goToSpaceTab: () => void;
  goToSelectSpace: () => void;
  goToKnowledgeTab: () => void;
  toggleDrawerExpanded: (expanded: boolean) => void;
}

const NavigationContext = createContext<NavigationContextValue>({
  activeTab: "select-space",
  drawerExpanded: false,
  hasSpacesTab: true,
  hasOrganisationTab: false,
  hasSelectedSpace: false,
  hasKnowledgeTab: false,
  goMeTab: () => {},
  goToOrganisationTab: () => {},
  goToSpaceTab: () => {},
  goToSelectSpace: () => {},
  goToKnowledgeTab: () => {},
  toggleDrawerExpanded: () => {},
});

export default NavigationContext;
