import { useTranslation } from "react-i18next";

import { TrashCanIcon } from "Assets";
import { EolasChecklistSection } from "Utilities/types";
import {
  Text,
  Title,
  Button,
  ModalBody,
  FormError,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import { useDeleteChecklist } from "../hooks";

export interface DeleteChecklistModalProps {
  checklist: EolasChecklistSection;
  closeModal(): void;
}

export const DeleteChecklistModal: React.FC<DeleteChecklistModalProps> = ({
  checklist,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { isSuccessful, isLoading, error, onDelete } = useDeleteChecklist(checklist);

  if (isSuccessful) {
    return (
      <SuccessModal text="submittedChecklists_delete_checklist_success" onComplete={closeModal} />
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col space-y-6">
        <TrashCanIcon className="w-16 h-16" />
        <Title level={5}>{t("submittedChecklists_delete_checklist_title")}</Title>
      </ModalHeader>

      <ModalBody className="pt-0 sm:pt-0">
        <Text level={2} className="text-center text-grey-700 font-semibold">
          {t("submittedChecklists_delete_checklist_description")}
        </Text>

        <Button color="red" onClick={onDelete} isLoading={isLoading} className="mt-16 mb-4">
          {t("general_delete")}
        </Button>

        <Button color="grey" variant="outline" onClick={closeModal}>
          Cancel
        </Button>

        <FormError error={error} />
      </ModalBody>
    </InnerModalWrapper>
  );
};
