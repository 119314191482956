import { useCallback, useRef, useState } from "react";
import { myFilesService } from "../client/myFiles.client";
import myFilesKeys from "./myFiles.queryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FileDto, MyFile } from "../types";
import { uploadFileToPresignedS3 } from "API/app.actions";
import { ensureFileType } from "Utilities/fileHelpers";
import { AnalyticsEvents } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";

export const useAddFile = () => {
  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState(0);
  const abortControllerRef = useRef<AbortController | null>(null);

  const handleUploadProgress = useCallback((progress: number) => setUploadProgress(progress), []);

  const { isLoading, data, mutate, error, isSuccess, reset } = useMutation<
    MyFile,
    Error,
    FileDto & { file?: File }
  >(
    async (fileDto) => {
      if (fileDto.file && fileDto.blobType) {
        const correctFileType = await ensureFileType(fileDto.file, fileDto.blobType);
        if (!correctFileType) throw new Error("error_file_type_does_not_match_extension");
      }

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      const signal = abortController.signal;
      const file = await myFilesService.addFile({ ...fileDto });

      try {
        if (file.blob && file.blob.uploadURL && fileDto.file) {
          await uploadFileToPresignedS3(
            file.blob.uploadURL,
            fileDto.file,
            handleUploadProgress,
            signal,
          );
        }
      } catch (error) {
        setUploadProgress(0);
      }

      return file;
    },
    {
      onSuccess: (newFile) => {
        trackEvent(AnalyticsEvents.MYFILES_FILE_ADDED, {
          fileId: newFile.id,
          subSectionId: newFile.sectionId,
        });

        if (!abortControllerRef.current?.signal.aborted) {
          queryClient.setQueriesData(myFilesKeys.allFilesList(), (files: any) => {
            return [newFile, ...files];
          });
          queryClient.setQueryData(myFilesKeys.filesBySectionList(), (sections: any) => {
            if (sections) {
              const [defaultSection, ...rest] = sections;
              return [{ ...defaultSection, files: [newFile, ...defaultSection.files] }, ...rest];
            }
          });
        }
      },
    },
  );

  const abortAddFile = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }, []);

  return {
    isLoading: isLoading,
    newFile: data,
    addFileSuccessful: isSuccess,
    addFileError: error?.message,
    uploadProgress,
    addFile: mutate,
    reset,
    abortAddFile,
  };
};

export default useAddFile;
