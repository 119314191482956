import React from "react";
import { SearchType } from "Components/MasterSearch/types";
import { useTranslation } from "react-i18next";
import { Title, Text } from "UIKit";

interface NoResultsMessageProps {
  searchValue: string;
  searchType: SearchType;
  onClickMessage: (searchType: SearchType) => void;
}

const NoResultsMessage: React.FC<NoResultsMessageProps> = ({
  searchType,
  searchValue,
  onClickMessage,
}) => {
  const { t } = useTranslation();

  const showKnowledgeMessage = searchType !== "community" && searchType !== "all";

  return (
    <div
      className="flex flex-col items-center justify-center space-y-2 mt-10 mb-24"
      data-testid="master-search-no-results"
    >
      <Title level={7} className="text-grey-700">
        {t("master_search_no_results", { searchText: `"${searchValue}"` })}
      </Title>
      {showKnowledgeMessage && (
        <Text level={1} className="text-grey-700" data-testid="knowledge-message">
          {t("master_search_no_recent_results_try_knowledge")}
          <span
            onClick={() => onClickMessage("community")}
            className="text-green-600 cursor-pointer underline"
          >
            {t("master_search_no_recent_results_try_knowledgeLink")}
          </span>
          {t("master_search_no_recent_results_try_option")}
        </Text>
      )}
    </div>
  );
};

export default NoResultsMessage;
