import { useCallback, useMemo, useState } from "react";
import { EolasList, SortFn } from "Components/EolasFileList/EolasList";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import { MyFile } from "modules/myFiles/types";
import { useAllFiles } from "modules/myFiles/data/useAllFiles";
import { userStore } from "@eolas-medical/core";
import { useParams } from "react-router-dom";
import FileListItem, { MenuActionType } from "../FileListItem/FileListItem";
import useDragFile from "modules/myFiles/data/useDragFile";
import { DeleteMyFileModal } from "../DeleteMyFileModal";
import { EditMyFileModal } from "../EditMyFileModal";

export const FileList = () => {
  const { userID = "" } = userStore;
  const { sectionId } = useParams<{ sectionId: string }>();
  const { filesList, filesListLoading } = useAllFiles(userID, sectionId);
  const { dragFile } = useDragFile();

  const [state, setState] = useState<{ activeModal: MenuActionType | ""; file?: MyFile }>({
    activeModal: "",
  });
  const [sortMethod, setSortMethod] = useState<SortFn>();

  const { searchInput, searchResult, onSetSearchInput } = useLocalSearch({
    data: filesList,
    keysToSearch: ["name"],
  });

  const items = useMemo(() => (searchInput ? searchResult : filesList).slice().sort(sortMethod), [
    sortMethod,
    searchInput,
    searchResult,
    filesList,
  ]);

  const isSearching = useMemo(() => searchInput?.length > 0, [searchInput]);

  const handleDragFile = useCallback(
    ({ newPrevItem, reorderedItem }) => {
      dragFile({ fileId: reorderedItem.id, prevFileId: newPrevItem?.id });
    },
    [dragFile],
  );

  const renderFile = useCallback(
    (file: MyFile) => (
      <FileListItem
        file={file}
        isDraggable={!isSearching}
        isMenuDisabled={isSearching || file.referencesCount > 0}
        onActionSelect={(action) => setState({ activeModal: action as MenuActionType, file })}
      />
    ),
    [isSearching],
  );

  return (
    <div data-testid="files-list">
      {state.file && (
        <EditMyFileModal
          open={state.activeModal === "edit"}
          handleClose={() => setState({ activeModal: "" })}
          file={state.file}
        />
      )}
      {state.file && (
        <DeleteMyFileModal
          open={state.activeModal === "delete"}
          handleClose={() => setState({ activeModal: "" })}
          fileId={state.file.id}
        />
      )}
      <EolasList
        items={items}
        renderItem={renderFile}
        onSearchInputChange={onSetSearchInput}
        onSortMethodChange={(sortMethod) => setSortMethod(() => sortMethod)}
        isDragable={!isSearching}
        isSortable
        isSearchable
        hasFavourites
        isLoading={filesListLoading}
        onDragEnd={handleDragFile}
      />
    </div>
  );
};
