import React, { ReactNode } from "react";

interface CircleIconProps {
  icon: ReactNode;
  backgroundColor?: string;
  height?: string;
  width?: string;
  className?: string;
}

export const CircleIcon: React.FC<CircleIconProps> = ({
  icon,
  height = "h-16",
  width = "w-16",
  backgroundColor = "grey-100",
  className = "",
}) => {
  return (
    <div
      className={`flex items-center justify-center ${height} ${width} rounded-full bg-${backgroundColor} ${className}`}
      data-testid="circle-icon"
    >
      {icon}
    </div>
  );
};
