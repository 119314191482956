import { useEffect, useState } from "react";
import { Button, InnerModalWrapper, Modal, ModalBody, ModalHeader, Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import { appConfig } from "AppTypeConfig";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

export const TermlyCookies: React.FC = () => {
  const [modal, setModal] = useState<React.ReactNode>(null);
  const [cookiesPermited, setCookiesPermited] = useState(false);
  const { flags } = useLaunchDarkly();

  useEffect(() => {
    const cookies = localStorage.getItem("TERMLY_API_CACHE");

    if (cookies) {
      const { TERMLY_COOKIE_CONSENT } = JSON.parse(cookies);

      const { value } = TERMLY_COOKIE_CONSENT || {};

      setCookiesPermited(
        value?.advertising &&
          value?.analytics &&
          value?.essential &&
          value?.performance &&
          value?.social_networking &&
          value?.unclassified,
      );
    }
  }, []);

  useEffect(() => {
    if (!cookiesPermited) {
      setModal(<CookiesModal />);
    } else {
      setModal(null);
    }
  }, [cookiesPermited]);

  if (!flags[LDFlagNames.FORCE_COOKIES]) return null;

  return (
    <Modal
      open={!!modal}
      onClose={() => {}}
      parentSelector={() => document.querySelector("#cookie-modal") as HTMLElement}
    >
      {modal}
    </Modal>
  );
};

const CookiesModal: React.FC = () => {
  const { t } = useTranslation();
  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <Title level={5}>{t("Please accept cookies to continue")}</Title>
      </ModalHeader>

      <ModalBody className="space-y-4 sm:space-y-8">
        <Text level={1} className="text-center">
          {t("cookies_consent_message", { appName: appConfig.appName })}
        </Text>
        <div className="flex flex-col space-y-2 items-center">
          <a
            className="underline"
            href={appConfig.termlyTermsOfService}
            target="_blank"
            rel="noreferrer"
          >
            {t("departmentTour_TC_confirmTC")}
          </a>
          <a
            className="underline"
            href={appConfig.termlyDisclaimer}
            target="_blank"
            rel="noreferrer"
          >
            {t("departmentTour_TC_confirmDisclaimer")}
          </a>
          <a
            className="underline"
            href={appConfig.termlyPrivacyPolicy}
            target="_blank"
            rel="noreferrer"
          >
            {t("departmentTour_TC_confirmPrivacyPolicy")}
          </a>
        </div>
        {/* this button and function are generated by Termly* */}
        <Button
          className="termly-cookie-preference-button"
          type="button"
          // @ts-ignore
          onClick={() => displayPreferenceModal()}
          id="termly-consent-preferences"
        >
          {t("cookies_consent_button")}
        </Button>
        <a className="self-center" href="mailto:support@eolasmedical.com">
          {t("general_contactSupport")}
        </a>
      </ModalBody>
    </InnerModalWrapper>
  );
};
