import { AssignAssessorsType, EventStatus, EventType } from "modules/portfolio/types";
import AssessorsList from "../AssessorsList/AssessorsList";
import AssessorFeedback from "../AssessorFeedback/AssessorFeedback";
import AssignedAssessors from "../AssignedAssessors/AssignedAssessors";

interface EventAssessorsProps {
  eventId: string;
  status: EventStatus;
  eventType: EventType;
  assignAssessorsType: AssignAssessorsType | null;
  needFeedback: boolean;
  eventOwnerEmail: string;
  eventOwnerId: string;
  isEventOwner: boolean;
}
const EventAssessors = ({
  eventId,
  status,
  eventType,
  assignAssessorsType,
  needFeedback,
  eventOwnerEmail,
  eventOwnerId,
  isEventOwner,
}: EventAssessorsProps) => {
  const showAssessorsList = assignAssessorsType === null && needFeedback;
  const showAssignedAssessor = assignAssessorsType !== null && status !== EventStatus.COMPLETE;
  const showAssessorFeedback = assignAssessorsType !== null && status === EventStatus.COMPLETE;

  if (status === EventStatus.IN_PROGRESS) {
    return null;
  }

  if (showAssessorsList) {
    return <AssessorsList eventId={eventId} eventStatus={status} />;
  }

  if (showAssignedAssessor) {
    return (
      <AssignedAssessors
        eventId={eventId}
        eventType={eventType}
        eventOwnerId={eventOwnerId}
        eventStatus={status}
        eventOwnerEmail={eventOwnerEmail}
        isEventOwner={isEventOwner}
      />
    );
  }

  if (showAssessorFeedback) {
    return <AssessorFeedback eventId={eventId} />;
  }

  return null;
};

export default EventAssessors;
