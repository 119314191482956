import { specialtyOptions } from "@eolas-medical/core";
import { FormElement } from "UIKit";
import Select from "UIKit/Select/Select";
import { Control } from "react-hook-form";

interface SpaceSpecialtyProps {
  control: Control<any>;
  onSubmit: () => void;
}
const SpaceSpecialty = ({ control, onSubmit }: SpaceSpecialtyProps) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="col-span-2">
        <FormElement required id="specialty" control={control} label={"Add keyword tags"}>
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return <Select {...rest} isMulti options={specialtyOptions} isSearchable />;
          }}
        </FormElement>
      </div>
    </form>
  );
};

export default SpaceSpecialty;
