import { useCallback, useEffect, useMemo, useState } from "react";

import {
  sectionStore,
  fileStore,
  AppLevelSection,
  EolasFile,
  getPreSignUrl,
  fetchAllData,
} from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { PublishFileDTO } from "Utilities/types";
import { parseFileKey } from "Utilities/fileHelpers";
import { generateS3FileKey } from "Utilities/general";
import { useFileOperations, useS3FileUpload } from "Hooks";
import { AxiosProgressEvent } from "axios";

export const useDepartmentFilePicker = () => {
  const uploadFile = useS3FileUpload();
  const { publishFile, updateFile, removeFile } = useFileOperations();

  const { id } = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.theDepartment)!;
  const childrenOrder = sectionStore.getChildrenOrder(id);
  const files = fileStore.getFiles(childrenOrder);

  const [progress, setProgress] = useState(0);
  const [state, setState] = useState({
    url: "",
    type: "",
  });
  const [showTour, setShowTour] = useState(false);
  const [file, setFile] = useState<File | null>();

  const { error, isLoading, setRequestStatus, requestStatus, isSuccessful } = useRequestStatus();

  const {
    error: removeError,
    isLoading: isRemoveLoading,
    setRequestStatus: setRemoveStatus,
  } = useRequestStatus();

  const { id: parentID } = sectionStore.getChildReferenceByMainSectionType(
    AppLevelSection.theDepartment,
  )!;

  const tourFile = files.find((file) => !!file.key) as EolasFile;

  const tourFileKey = tourFile?.key ?? "";

  const fetchUrl = useCallback(() => {
    if (!tourFileKey) return;

    getPreSignUrl(tourFileKey.replace("public/", "")).then((url) => {
      const { fileType } = parseFileKey(tourFileKey);
      setState({ url: url.toString(), type: fileType });
    });
  }, [tourFileKey]);

  useEffect(() => {
    fetchUrl();
  }, [fetchUrl]);

  useEffect(() => {
    if (requestStatus.status === "initial") {
      setFile(null);
      setProgress(0);
    }
  }, [requestStatus]);

  const onAddFile = async () => {
    if (!file) return;

    const key = generateS3FileKey({
      fileName: file.name,
      fileFormat: file.type,
      mainSectionId: AppLevelSection.theDepartment,
    });

    const input: PublishFileDTO = {
      key,
      type: file.type,
      name: "Department tour",
    };

    try {
      setRequestStatus({ status: "pending", error: "" });
      setProgress(0);
      await uploadFile(key, file, (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.progress) {
          setProgress(Math.round(progressEvent.progress * 100));
        }
      });
      await publishFile({
        variables: {
          input,
          parentID,
        },
      });

      await fetchAllData();
      setRequestStatus({ status: "success", error: "" });
    } catch (err: any) {
      const errorMessage = errorStore.captureError({
        error: err,
        source: "user",
        retryCallback: publishFile,
        retryParameters: {
          variables: {
            input,
            parentID,
          },
        },
      });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  };

  const onEditFile = async () => {
    if (!file) return;

    const key = generateS3FileKey({
      fileName: file.name,
      fileFormat: file.type,
      mainSectionId: AppLevelSection.theDepartment,
    });

    const input: PublishFileDTO = {
      key,
      type: file.type,
      id: tourFile.id,
      name: "Department tour",
    };

    try {
      setRequestStatus({ status: "pending", error: "" });
      setProgress(0);
      await uploadFile(key, file, (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.progress) {
          setProgress(Math.round(progressEvent.progress * 100));
        }
      });
      await updateFile({ variables: { input, parentID } });
      await fetchAllData();
      setRequestStatus({ status: "success", error: "" });
    } catch (err: any) {
      errorStore.captureError({
        error: err,
        source: "user",
        retryCallback: updateFile,
        retryParameters: { variables: { input, parentID } },
      });
      setRequestStatus({ status: "error", error: err.message });
    }
  };

  const onRemoveFile = async () => {
    setRemoveStatus({ status: "pending", error: "" });
    try {
      await removeFile({ variables: { id: tourFile.id, parentID: tourFile.parentID } });

      setState({ url: "", type: "" });
      await fetchAllData();
      setRemoveStatus({ status: "success", error: "" });
    } catch (err: any) {
      errorStore.captureError({
        error: err,
        source: "user",
        retryCallback: removeFile,
        retryParameters: { variables: { id: tourFile.id, parentID: tourFile.parentID } },
      });
      setRemoveStatus({ status: "error", error: err.message });
    }
  };

  const filePickerValue = useMemo(() => {
    if (tourFileKey && !file) {
      return parseFileKey(tourFileKey).fileName;
    }
    if (!file) return "";
    return file.name;
  }, [file, tourFileKey]);

  return {
    tourFile,
    error,
    url: state.url,
    fileType: state.type,
    progress,
    isSuccessful,
    showTour,
    isLoading,
    removeError,
    isRemoveLoading,
    filePickerValue,
    onChange: setFile,
    onAddFile,
    onEditFile,
    setShowTour,
    onRemoveFile,
    setRequestStatus,
    setRemoveStatus,
  };
};
