import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection, EolasFile, OrganisationLevelSection } from "@eolas-medical/core";

import { EolasFileList } from "Components";
import { SubSectionRouteParams } from "Utilities/types";
import { InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import { useMainSectionVisit } from "Hooks";
import { ContactDetailsModal } from "../components";
import { useEolasNavigation } from "Components/Navigation/hooks";
import useSpaceSubSection from "modules/spaces/data/useSpaceSubSection";

export const ClientContactsSection: React.FC<RouteComponentProps<SubSectionRouteParams>> = observer(
  ({ location: { pathname }, match: { url } }) => {
    const { activeTab } = useEolasNavigation();
    const [modal, setModal] = useState<React.ReactNode>(null);
    const { t } = useTranslation();
    const { isOrganisationTab, mainSectionId } = useMemo(() => {
      const isOrganisationTab = activeTab === "organisation";
      return {
        isOrganisationTab,
        mainSectionId: isOrganisationTab
          ? OrganisationLevelSection.hospitalContacts
          : AppLevelSection.contacts,
      };
    }, [activeTab]);

    const onCloseModal = useCallback(() => {
      setModal(null);
    }, [setModal]);

    const onSelectFile = useCallback((contact: EolasFile) => {
      setModal(<ContactDetailsModal contact={contact} />);
    }, []);

    const { spaceSubSection } = useSpaceSubSection(mainSectionId);

    useMainSectionVisit({ mainSectionId, subSectionId: spaceSubSection.subSectionId });

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSubSection.subSectionName ?? ""} />
          <NavButton to={url.replace(`/${spaceSubSection.encodedSubSectionId}`, "")} />

          <EolasFileList
            isAdmin={false}
            defaultSort="alphabetical"
            draggable={false}
            subSectionId={spaceSubSection.subSectionId}
            infoText={t("contacts_contact_order")}
            onSelectFile={onSelectFile}
            mainSectionId={
              isOrganisationTab
                ? OrganisationLevelSection.hospitalContacts
                : AppLevelSection.contacts
            }
          />

          <Modal open={!!modal} onClose={onCloseModal}>
            {modal}
          </Modal>
        </InnerPageWrapper>
      </>
    );
  },
);
