import { useContext } from "react";
import SpacesContext from "./spaces.context";

const useSpacesContext = () => {
  const context = useContext(SpacesContext);

  if (!context) {
    throw new Error("useSpacesContext must be used within a SpacesProvider");
  }

  return context;
};

export default useSpacesContext;
