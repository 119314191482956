import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { formatDate } from "Utilities";
import { EolasFile } from "Utilities/types";
import { FormError, Button, Title, ModalHeader, InnerModalWrapper, ModalBody } from "UIKit";

import { useNewsItem } from "Hooks";

export interface NewsItemViewModalProps {
  newsItem: EolasFile;
}

export const NewsItemViewModal: React.FC<NewsItemViewModalProps> = ({ newsItem }) => {
  const { t } = useTranslation();
  const { info, name, createdAt = "", description } = newsItem;

  const { onSelectFile, videoURL, shouldShowPlayer, isLoading, error, setVideoURL } = useNewsItem(
    newsItem,
  );

  return (
    <InnerModalWrapper>
      <ModalHeader className="items-start">
        <Title level={5} className="line-clamp-4" data-testid="newsItemModalTitle">
          {name}
        </Title>
        <span className="text-sm text-grey-dark">{info}</span>
        <span className="text-sm text-grey-dark">{formatDate(new Date(createdAt as string))}</span>
      </ModalHeader>

      <ModalBody className="overflow-y-auto">
        <div className="max-h-50vh overflow-y-auto">{description}</div>

        {shouldShowPlayer ? (
          <div className="flex flex-col mt-4 sm:mt-8">
            <div className="flex flex-col">
              <ReactPlayer
                playing
                controls
                width="100%"
                height="100%"
                stopOnUnmount
                url={videoURL}
                style={{ width: "100%" }}
                data-testid="videoPlayer"
              />

              <Button
                color="grey"
                weight="regular"
                className="self-center my-4"
                onClick={() => setVideoURL("")}
              >
                {t("EdPortal_videoPlayer_button")}
              </Button>
            </div>
          </div>
        ) : (
          <NewsAttachmentButton
            newsItem={newsItem}
            isLoading={isLoading}
            onSelectFile={onSelectFile}
          />
        )}

        <FormError error={error} />
      </ModalBody>
    </InnerModalWrapper>
  );
};

interface NewsAttachmentButtonProps {
  newsItem: EolasFile;
  isLoading: boolean;
  onSelectFile(): void;
}

const NewsAttachmentButton: React.FC<NewsAttachmentButtonProps> = ({
  isLoading,
  newsItem,
  onSelectFile,
}) => {
  const { t } = useTranslation();

  if (newsItem.type === "pdf" || newsItem.type === "ms-office") {
    return (
      <Link
        className="mx-auto"
        to={{ pathname: `/documents/viewer/${encodeURIComponent(newsItem.id)}` }}
      >
        <Button
          color="blue"
          weight="regular"
          variant="outline"
          isLoading={isLoading}
          className="self-center my-4"
          data-testid="attachment-button"
        >
          {t("newsFeed_view_attachement")}
        </Button>
      </Link>
    );
  }

  if (!!newsItem.type && newsItem.type !== "pdf" && newsItem.type !== "ms-office") {
    return (
      <Button
        color="blue"
        weight="regular"
        variant="outline"
        isLoading={isLoading}
        onClick={onSelectFile}
        className="self-center my-4"
        data-testid="attachment-button"
      >
        {t("newsFeed_view_attachement")}
      </Button>
    );
  }

  return null;
};
