import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  authenticationClient,
  AuthErrorCodes,
  AuthFunctionNames,
  handleAuthErrors,
} from "@eolas-medical/core";

import { errorStore } from "Stores/ErrorStore";
import { login } from "../auth.actions";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

type ApiRequestStatus = "success" | "pending" | "error";

interface ApiRequest {
  status: ApiRequestStatus;
  error?: string;
}

export interface LoginFormShape {
  email: string;
  password: string;
}

const loginSchema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
  password: yup.string().label("Password"),
});

export const useLogin = (existingEmail?: string) => {
  const [requestStatus, setRequestStatus] = useState<ApiRequest>({ status: "success" });
  const { flags } = useLaunchDarkly();
  const { control, handleSubmit, watch } = useForm<LoginFormShape>({
    defaultValues: { email: existingEmail || "", password: "" },
    resolver: yupResolver(loginSchema),
  });

  const isFeatureEnabled = flags[LDFlagNames.FEDERATED_USERS];

  const email = watch("email");

  const isFederatedDomain = authenticationClient.isEmailDomainFederated({
    email,
    isFeatureEnabled,
  });

  const onSubmit = handleSubmit(async (values) => {
    setRequestStatus({ status: "pending", error: "" });

    try {
      await login(values);
    } catch (error: any) {
      let errorMessageForDisplay = "";
      if (error.code === AuthErrorCodes.federated_domain_on_enterprise_login_error) {
        errorStore.captureError(error);
        try {
          await login({ ...values, isFederated: true });
        } catch (secondError: any) {
          ({ errorMessageForDisplay } = handleAuthErrors(secondError, AuthFunctionNames.login));
          setRequestStatus({ status: "error", error: errorMessageForDisplay });
        }
      } else {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
          retryCallback: login,
          authFunctionName: AuthFunctionNames.login,
        });
        setRequestStatus({ status: "error", error: errorMessage });
      }
    }
  });

  const showPasswordInput = !isFederatedDomain;

  return {
    control,
    onSubmit,
    requestStatus,
    isFederatedDomain,
    showPasswordInput,
  };
};
