import React from "react";
import { format } from "date-fns";

import { FileTileWrapper, FileTileProps, DefaultFileControls } from "./shared";

import { DownloadFileButton } from "Components";

export const EmergencyActionCardFileTile: React.FC<FileTileProps> = ({
  isAdmin,
  canDrag,
  eolasFile,
  className,
  isDragging,
  onSelectFile = () => {},
  ...controlProps
}) => {
  return (
    <FileTileWrapper
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
      className={`${className} items-stretch h-40`}
    >
      <div
        style={{ minHeight: "6rem" }}
        title={eolasFile.name as string}
        className="flex flex-col justify-between flex-1"
      >
        <span className="text-lg font-semibold line-clamp-2" title={eolasFile.name ?? ""}>
          {eolasFile.name}
        </span>
        <span className="text-md text-grey-dark line-clamp-2">
          {format(new Date(eolasFile.updatedAt!), "io LLLL yyyy, kk:mm")}
        </span>
      </div>
      <DownloadFileButton eolasFile={eolasFile} textClass="lg:hidden" />
      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...controlProps} />}
    </FileTileWrapper>
  );
};
