import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { sectionStore } from "@eolas-medical/core";

import { useS3FileUpload, useUpdateApp } from "Hooks";
import { useRequestStatus, generateS3FileKey } from "Utilities";
import { errorStore } from "Stores/ErrorStore";

interface AddGuidelineOrganisationForm {
  file: any;
  id: string;
  key: string;
  name: string;
  progress: number;
  abbreviation: string;
}

const defaultValues: AddGuidelineOrganisationForm = {
  id: "",
  key: "",
  name: "",
  file: null,
  progress: 0,
  abbreviation: "string",
};

const schema = yup.object().shape({
  name: yup.string().required("error_guidelines_organisation_name_required"),
});

export const useAddGuidelineOrganisation = (onClose: () => void) => {
  const { updateApp } = useUpdateApp();
  const uploadFile = useS3FileUpload();
  const { isLoading, error, setRequestStatus } = useRequestStatus();

  const formMethods = useForm<AddGuidelineOrganisationForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, setValue, formState } = formMethods;

  const onPickImage = (image: File | null) => {
    setValue("file", image, { shouldValidate: true });
  };

  const onSubmit = handleSubmit(async ({ name, file }) => {
    let key = "";
    if (file) {
      key = generateS3FileKey({
        isPublic: true,
        fileName: name,
        addExtension: true,
        fileFormat: file.type,
      });
    }
    // @ts-ignore
    sectionStore.addGuidelinesOrganisations({
      name,
      logo: key,
    });

    const input = {
      id: sectionStore.data.app?.id,
      guidelineOrganisations: sectionStore.guidelinesOrganisations,
    };

    setRequestStatus({ status: "pending", error: "" });
    try {
      if (file) {
        await uploadFile(key, file);
      }
      await updateApp(input);

      setRequestStatus({ status: "success", error: "" });
      onClose();
    } catch (error: any) {
      const errorMessage = errorStore.captureError({ error, source: "network" });
      setRequestStatus({ status: "error", error: errorMessage });
    }
  });

  return {
    error,
    control,
    onSubmit,
    isLoading,
    formMethods,
    onPickImage,
    formErrors: formState.errors,
  };
};
