import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Smartlook from "smartlook-client";

import {
  fileStore,
  userStore,
  sectionStore,
  profilesClient,
  AnalyticsEvents,
  getAllFilesByMainSections,
} from "@eolas-medical/core";

import { useSentry } from "Contexts";
import { trackEvent } from "API/Analytics";

import { AccessRequestStatus, FindSpaceStep, SearchSpaceResult } from "../types";

import { useRequestAccess, useShouldAllowAccess } from "modules/spaces/data";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { BaseAppRoute } from "Utilities/types";
import { removeBracketsAndPercent } from "Utilities/helpers";

export const useSelectSpaceActions = (initialState?: FindSpaceStep) => {
  const { userID = "" } = userStore;
  const { push } = useHistory();
  const { addBreadcrumb } = useSentry();
  const { onSelectSpace: onSelectSpaceAction } = useSpacesContext();

  const { shouldAllowUserAccess, shouldAllowAccessLoading, error } = useShouldAllowAccess();
  const { requestAccess, requestingAccess } = useRequestAccess(userID);

  const [loadingSpaceId, setLoadingSpaceId] = useState<string | null>(null);

  const [selectSpaceStep, setSelectSpaceStep] = useState<FindSpaceStep>(
    initialState ?? "select-space",
  );

  const [selectSpaceMessage, setSelectSpaceMessage] = useState("");

  const selectedAppRef = useRef<SearchSpaceResult>({ appID: "", name: "", orgID: "" });

  const onAllowAccessSuccess = async ({ status, data, message }: any) => {
    if (
      status === AccessRequestStatus.EXISTING_APP_USER ||
      status === AccessRequestStatus.APP_USER_CREATED ||
      status === AccessRequestStatus.VERIFIED_EMAIL_APP_USER_CREATED
    ) {
      const { appUser, sections, app, organisation } = data;

      addBreadcrumb({
        category: "Select Organisation",
        message: "Selecting the app and updating the store...",
      });

      try {
        sectionStore.setStoreFromApi({ app, organisation, sections }, true);

        onSelectSpaceAction(app);

        const [refreshedProfile, files] = await Promise.all([
          profilesClient.getUserProfile(userStore.userID!),
          getAllFilesByMainSections(),
        ]);

        userStore.signInOrganisation({ appUser, user: refreshedProfile });

        fileStore.upsertFiles(files, true);

        if (process.env.REACT_APP_SENTRY_ENABLED !== "false" && refreshedProfile.id) {
          Smartlook.identify(refreshedProfile.id, { displayName: appUser.id });
        }

        const formattedName = removeBracketsAndPercent(app.name);
        push(
          `/${
            sectionStore.activeDepartmentTab
              ? `${BaseAppRoute.spaces}/${encodeURIComponent(formattedName || "")}`
              : BaseAppRoute.organisation
          }`,
          {
            shouldAllowUserAccess: true,
          },
        );
        trackEvent(AnalyticsEvents.EOLAS_SESSION_START);

        return;
      } catch (error: any) {}
    }

    if (status === AccessRequestStatus.NO_APP_ACCESS) {
      addBreadcrumb({
        category: "Select Organisation",
        message: "No app access",
      });

      setSelectSpaceStep("request-access");
      return;
    }

    if (status === AccessRequestStatus.ACCESS_REQUESTED) {
      addBreadcrumb({
        category: "Select Organisation",
        message: "App request access sent",
      });

      setSelectSpaceStep("request-sent");
      return;
    }

    if (status === AccessRequestStatus.NO_TRUSTED_EMAIL) {
      addBreadcrumb({
        category: "Select Organisation",
        message: "App request access sent",
      });

      setSelectSpaceStep("no-trusted-email");
      setSelectSpaceMessage(message);
      return;
    }
  };

  const onSelectSpace = async (app: SearchSpaceResult) => {
    addBreadcrumb({
      category: "Select Organisation",
      message: "Selecting organisation",
    });

    setLoadingSpaceId(app.appID);
    selectedAppRef.current = app;

    shouldAllowUserAccess(app.appID, {
      onSuccess: onAllowAccessSuccess,
      onError: (error: string | Error) => {
        setLoadingSpaceId(null);
      },
    });
  };

  const onRequestAccess = async (requestText?: string) => {
    addBreadcrumb({
      category: "Select Organisation",
      message: `Requesting access to organisation: ${selectedAppRef.current.appID}`,
    });

    trackEvent(AnalyticsEvents.ORGANISATION_REQUEST_ACCESS);
    if (userID.length) {
      requestAccess(
        { requestText, spaceId: selectedAppRef.current.appID },
        {
          onSuccess: () => {
            setSelectSpaceStep("request-sent");
          },
        },
      );
    }
  };

  return {
    error,
    requestingAccess,
    shouldAllowAccessLoading,
    loadingSpaceId,
    selectedAppRef,
    selectSpaceStep,
    selectSpaceMessage,
    setSelectSpaceStep,
    onSelectSpace,
    onRequestAccess,
    setSelectSpaceMessage,
  };
};
