import { SectionIcon, SectionSubSectionIcon } from "Assets";
import { CardCheck } from "UIKit";
import { useTranslation } from "react-i18next";

interface SectionTypeOption {
  key: string;
  title: string;
  icon: React.ReactNode;
}

const SectionTypeForm: React.FC<any> = ({ onSubmit, onChangeSectionType, selectedType }) => {
  const { t } = useTranslation();

  const sectionTypeOptions: SectionTypeOption[] = [
    {
      key: "section",
      title: t("create_section_type_section"),
      icon: <SectionSubSectionIcon />,
    },
    {
      key: "file",
      title: t("create_section_type_file"),
      icon: <SectionIcon />,
    },
  ];

  const handleOptionClick = (sectionType: string) => {
    onChangeSectionType(sectionType);
  };

  return (
    <form className="flex flex-col space-y-4">
      <div className="flex justify-center space-x-4">
        {sectionTypeOptions.map((sectionType) => {
          const isSelected = selectedType === sectionType.key;

          return (
            <CardCheck
              key={sectionType.key}
              isSelected={isSelected}
              onClick={() => handleOptionClick(sectionType.key)}
            >
              {sectionType.icon}
              <div className="text-sm font-bold md:text-base">{sectionType.title}</div>
            </CardCheck>
          );
        })}
      </div>
    </form>
  );
};

export default SectionTypeForm;
