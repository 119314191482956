import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { SearchIcon, SearchMonoIcon } from "Assets";
import { AddButton, Button, Modal, PageTitle, InnerPageWrapper, Input } from "UIKit";

import { useFindSpace } from "./hooks/useFindSpace";
import { SpaceList } from "./components/SpaceList/SpaceList";

export const SelectSpace = () => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();

  const history = useHistory();
  const { state } = useLocation<{ shouldCreateSpace?: boolean }>();

  const {
    modal,
    error,
    onSearch,
    onChange,
    isLoading,
    onKeyDown,
    searchInput,
    onCloseModal,
    setSearchInput,
    onCreateSpace,
  } = useFindSpace();

  useEffect(() => {
    if (state?.shouldCreateSpace) {
      onCreateSpace();
      history.replace({ ...history.location, state: undefined });
    }
  }, [state, history, onCreateSpace]);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle data-testid="find-organisation-title" title={t("selectSpace_selectSpace")} />

        {flags[LDFlagNames.SHOW_CREATE_ORG_BUTTON] && !flags[LDFlagNames.READ_ONLY_ACTIVATED] ? (
          <AddButton onClick={onCreateSpace}>{t("selectSpace_create_button")}</AddButton>
        ) : null}

        <Input
          value={searchInput}
          onChange={onChange}
          onKeyDown={onKeyDown}
          data-testid="searchbar-input"
          style={{ borderRadius: "3rem" }}
          className="input pl-4 h-12 sm:h-14 box-content"
          iconLeft={<SearchIcon className="hidden sm:block" />}
          placeholder={t("selectSpace_searchPlaceholder")}
          rightSection={
            <div className="flex flex-row items-center h-10 sm:h-14" style={{ marginRight: 4 }}>
              {searchInput.length > 0 && (
                <Button
                  color="grey"
                  variant="ghost"
                  onClick={() => setSearchInput("")}
                  className="text-grey-600 px-2 h-10 sm:h-12"
                >
                  {t("master_search_clear")}
                </Button>
              )}

              <Button
                onClick={onSearch}
                isLoading={isLoading}
                data-testid="searchbar-button"
                disabled={searchInput.length < 2}
                style={{
                  borderRadius: "3rem",
                  minWidth: "3.5rem",
                }}
                className="ml-1 px-3 sm:px-8 h-10 sm:h-12"
              >
                <span className="hidden sm:inline">{t("selectSpace_search_button")}</span>
                <SearchMonoIcon className="h-6 w-6 inline sm:hidden" />
              </Button>
            </div>
          }
        />
        {error && <span className="text-red text-center">{error}</span>}

        <SpaceList />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
};
