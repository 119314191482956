import { EolasRegions, NationalResourceEntityType, searchClient } from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { useMutation } from "@tanstack/react-query";

interface NationalBodyProps {
  entityType: NationalResourceEntityType;
  query: string;
  mode: "searchbyInput" | "searchByNationalBody";
}

interface SearchParams {
  query: string;
  mode: "searchbyInput" | "searchByNationalBody";
  nationalResourceType?: NationalResourceEntityType;
  region: EolasRegions;
}

const useSearchNationalResources = () => {
  const { supportedEolasRegion } = useSupportedRegion();

  const { mutate, isLoading } = useMutation(
    async ({ entityType, query, mode = "searchbyInput" }: NationalBodyProps) => {
      const searchParams: SearchParams = {
        query,
        mode,
        region: supportedEolasRegion as EolasRegions,
      };

      if (entityType) {
        searchParams.nationalResourceType = entityType;
      }

      return searchClient.searchNationalResources(searchParams);
    },
  );

  return {
    searchNationalResources: mutate,
    searchingNationalResources: isLoading,
  };
};

export default useSearchNationalResources;
