import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormLabel, ImageUploader } from "UIKit";
import { generateS3PublicImageURL } from "Utilities";

export interface AddTeamMemberPhotoProps {
  currentPhoto?: any;
  onPickPhoto: (photo: File | null) => void;
}

const AddTeamMemberPhoto: React.FC<AddTeamMemberPhotoProps> = ({ onPickPhoto, currentPhoto }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const fileName = watch("name");

  const handleFileChange = useCallback(
    (file: File | null) => {
      onPickPhoto(file);
    },
    [onPickPhoto],
  );

  return (
    <div className="flex flex-col space-y-2">
      <FormLabel htmlFor="">Select staff picture</FormLabel>
      <ImageUploader
        croppingEnabled
        fileName={fileName}
        onChange={handleFileChange}
        defaultValue={currentPhoto}
        getRemotePreview={generateS3PublicImageURL}
        fileToLargeError={t("imagePicker_too_large_error")}
      />
    </div>
  );
};

export default AddTeamMemberPhoto;
