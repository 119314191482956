import { useMutation } from "@tanstack/react-query";
import { CreateMainSectionDto, contentClient, fetchAllData } from "@eolas-medical/core";

const useCreateMainSection = () => {
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async (mainSection: CreateMainSectionDto) => {
      return contentClient.createMainSection(mainSection);
    },
    {
      onSuccess: async () => {
        await fetchAllData();
      },
    },
  );

  return {
    createCreateMainSection: mutate,
    creatingMainSection: isLoading,
    errorCreatingMainSection: error,
    createContentMainSection: isSuccess,
  };
};

export default useCreateMainSection;
