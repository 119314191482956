import { FileTileWrapper, FileTileProps, DefaultFileControls } from "./shared";

export const ContactFileTile: React.FC<FileTileProps> = ({
  canDrag,
  isAdmin,
  eolasFile,
  isDragging,
  onSelectFile = () => {},
  ...handlers
}) => {
  return (
    <FileTileWrapper
      canDrag={canDrag}
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-1 justify-around">
        <span className="font-semibold text-lg line-clamp-2">{eolasFile.name}</span>
        <span className="text-sm line-clamp-2">{eolasFile.description}</span>
      </div>

      {isAdmin && <DefaultFileControls eolasFile={eolasFile} {...handlers} />}
    </FileTileWrapper>
  );
};
