import { gql, useMutation } from "@apollo/client";
import { Space, sectionStore } from "@eolas-medical/core";
import { errorStore } from "Stores/ErrorStore";
import useContentManagementSections from "modules/spaces/data/useContentManagementSections";
import { MainSection } from "../types";
import { mapToChildReference } from "../helpers";

const REORDER = gql`
  mutation Reorder($input: ReorderInput!) {
    reorder(input: $input) {
      id
      childrenOrder {
        id
        type
        description
        icon
        name
        disabled
      }
    }
  }
`;

interface UseReorderMainSections {
  space: Space;
  isSpaceMainSection: boolean;
}

const useReorderMainSections = ({ space, isSpaceMainSection }: UseReorderMainSections) => {
  const [reorder] = useMutation(REORDER);
  const { deactivatedMainSections, inactiveMainSections } = useContentManagementSections({
    isSpaceContent: isSpaceMainSection,
  });

  const reorderId = isSpaceMainSection ? space.id : space.organisationID;

  const reorderSections = (reorderedSections: MainSection[]) => {
    sectionStore.setMainSectionsList(
      [
        ...inactiveMainSections.map(mapToChildReference),
        ...reorderedSections.map(mapToChildReference),
        ...deactivatedMainSections.map(mapToChildReference),
      ],
      isSpaceMainSection ? "department" : "hospital",
    );

    reorder({
      variables: {
        input: {
          id: reorderId,
          listOrder: [
            ...inactiveMainSections.map((item) => item.id),
            ...reorderedSections.map((item) => item.id),
            ...deactivatedMainSections.map((item) => item.id),
          ],
        },
      },
    }).catch((error) => {
      errorStore.captureError({
        error,
        source: "user",
        retryParameters: {
          variables: {
            input: {
              id: reorderId,
              listOrder: [
                ...inactiveMainSections.map((item) => item.id),
                ...reorderedSections.map((item) => item.id),
                ...deactivatedMainSections.map((item) => item.id),
              ],
            },
          },
        },
        retryCallback: reorder,
      });
    });
  };

  return {
    reorderSections,
  };
};

export default useReorderMainSections;
