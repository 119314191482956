import { Text } from "UIKit";
import { CheckIcon, AttentionIcon } from "Assets/Icons";

interface PasswordValidatorProps {
  condition: string;
  conditionMet: boolean;
}

export const PasswordValidator: React.FC<PasswordValidatorProps> = ({
  condition,
  conditionMet,
}) => (
  <div className="flex items-center">
    {conditionMet ? (
      <CheckIcon className="mr-2 text-green w-4 h-4" />
    ) : (
      <AttentionIcon className="mr-2 text-red w-4 h-4" />
    )}
    <Text level={3} className="font-semibold">
      {condition}
    </Text>
  </div>
);
