import { Text, Title, Loader, ModalBody, InnerModalWrapper } from "UIKit";

export const LoggingInStep = () => {
  return (
    <InnerModalWrapper>
      <ModalBody className="flex-col items-center">
        <Title level={5}>Logging in</Title>
        <Text level={1} className="my-4">
          Please wait while we log you in...
        </Text>
        <Loader size={40} />
      </ModalBody>
    </InnerModalWrapper>
  );
};
