import mixpanel from "mixpanel-browser";

import { userStore, EventPayload, sectionStore, AnalyticsEvents } from "@eolas-medical/core";

import envConfig from "../env-config";
import { appConfig } from "AppTypeConfig";

export const initMixpanel = async () => {
  if (envConfig.REACT_APP_MIXPANEL_ENABLED) {
    mixpanel.init(envConfig.REACT_APP_MIXPANEL_TOKEN, {
      debug: envConfig.REACT_APP_APP_ENV === "development",
      api_host: "https://api-eu.mixpanel.com",
    });
    mixpanel.register({
      appType: appConfig.appType,
      environment: envConfig.REACT_APP_APP_ENV,
    });
  }
};

export const trackEvent = (eventName: AnalyticsEvents, eventPayload?: EventPayload) => {
  if (!envConfig.REACT_APP_MIXPANEL_ENABLED) {
    return;
  }

  const fallbackOrganisationValue = userStore.userSession.isLoggedIn
    ? "no_org_selected"
    : "not_logged_in";

  mixpanel.track(eventName, {
    organisationID: sectionStore.organisationID || fallbackOrganisationValue,
    organisationName: sectionStore.organisationName || fallbackOrganisationValue,
    appID: sectionStore.appID || fallbackOrganisationValue,
    userSub: userStore.userID || "no_sub_available",
    ...eventPayload,
  });
};

export const timeEvent = (eventName: AnalyticsEvents) => {
  if (!envConfig.REACT_APP_MIXPANEL_ENABLED) {
    return;
  }

  mixpanel.time_event(eventName);
};

// call this after the user logs in so we can track their actions thru the app
export const identifyUser = (user: any) => {
  if (!envConfig.REACT_APP_MIXPANEL_ENABLED) {
    return;
  }

  mixpanel.identify(user.id);
};

export const resetAnalytics = () => {
  if (!envConfig.REACT_APP_MIXPANEL_ENABLED) {
    return;
  }

  mixpanel.reset();
};
