import { FormConfig, useEolasForm } from "Hooks/useEolasForm";
import { FieldValues } from "react-hook-form";
import { FormValues, FormElement } from "modules/portfolio/types";
import { makeValidationSchema } from "../../MyPortfolio/hooks/helpers";

interface UseFeedbackFormProps<T> extends Omit<FormConfig<T>, "validationSchema"> {
  formElements: FormElement[];
}

const useFeedbackForm = ({ formElements, defaultValues }: UseFeedbackFormProps<FormValues>) => {
  const validationSchema = makeValidationSchema({ formElements });
  const formData = useEolasForm<FieldValues>({ validationSchema, defaultValues });
  return formData;
};
export default useFeedbackForm;
