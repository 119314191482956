import { AddButton, Button, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import AddContentSectionWizard from "./components/AddContentSectionWizard/AddContentSectionWizard";
import ContentSectionList from "./components/ContentSectionList/ContentSectionList";
import { observer } from "mobx-react-lite";
import { removeLastUrlPath } from "Utilities";
import useContentRepository from "modules/contentRepository/data/useContentRepository";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useContentRepositoryParams from "../../hooks/useContentRepositoryParams";

const ContentSections = observer(() => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { replace, push, location } = useHistory();
  const { contentRepositoryId } = useContentRepositoryParams();

  const { isAdmin } = useSpacesContext();
  const { contentRepository } = useContentRepository(contentRepositoryId);
  const [showModal, setShowModal] = useState(false);
  const backUrl = removeLastUrlPath(url, 2);

  const handleAddSection = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleManageExpired = () => {
    push(`${location.pathname}/expired-items`);
  };

  return (
    <>
      <Modal open={!!showModal} onClose={handleCloseModal}>
        <AddContentSectionWizard
          contentRepositoryId={contentRepositoryId}
          onClose={handleCloseModal}
        />
      </Modal>
      <InnerPageWrapper>
        <div className="flex flex-col justify-center">
          <PageTitle className="my-4" title={contentRepository?.name || ""} />
          <NavButton onClick={() => replace(backUrl)} />
        </div>

        {isAdmin && (
          <div className="flex justify-center items-center space-x-4">
            <AddButton onClick={handleAddSection} data-testid="add-file-section">
              {t("my_files_add_section")}
            </AddButton>
            <Button variant="outline" color="white" size="lg" onClick={handleManageExpired}>
              {t("repository_manage_expired")}
            </Button>
          </div>
        )}

        <ContentSectionList contentRepositoryId={contentRepositoryId} isAdmin={isAdmin} />
      </InnerPageWrapper>
    </>
  );
});

export default ContentSections;
