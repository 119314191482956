import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authenticationClient, AuthFunctionNames } from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { acceptInvitationSchema } from "Utilities/authValidations";

import { LoginCredentials } from "../types";

interface AcceptInvitationForm {
  email: string;
  password: string;
  given_name: string;
  family_name: string;
  confirmPassword: string;
}

const mapToDefaultValues = (email?: string): AcceptInvitationForm => {
  return {
    password: "",
    given_name: "",
    family_name: "",
    email: email ?? "",
    confirmPassword: "",
  };
};

export const useCreateAccountStep = (
  onCreateAccountSuccess: (loginCredentials: LoginCredentials) => void,
  email?: string,
) => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();

  const formMethods = useForm<AcceptInvitationForm>({
    defaultValues: mapToDefaultValues(email),
    resolver: yupResolver(acceptInvitationSchema),
  });
  const { control, handleSubmit } = formMethods;

  const onSubmit = handleSubmit(async ({ password, email, family_name, given_name }) => {
    setRequestStatus({ status: "pending", error: "" });
    try {
      await authenticationClient.signUp({
        email: email.toLowerCase(),
        password,
        givenName: given_name,
        familyName: family_name,
      });

      onCreateAccountSuccess({ email, password, firstName: given_name, lastName: family_name });
    } catch (error: any) {
      const errorMessage = errorStore.captureError({
        error,
        source: "user",
        authFunctionName: AuthFunctionNames.signUp,
      });

      setRequestStatus({ status: "error", error: errorMessage });
    }
  });

  return {
    error,
    control,
    isLoading,
    formMethods,
    onSubmit,
  };
};
