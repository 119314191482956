import { AppUser } from "API";
import React from "react";

export const useManageAdmins = (appUsers: AppUser[]) => {
  const [searchInput, setSearchInput] = React.useState("");

  const { adminList, readOnlyUsers } = React.useMemo(() => {
    const adminList = appUsers.filter((user) => user.accessLevel === "admin");
    const readOnlyUsers = appUsers.filter((user) => user.accessLevel?.toLowerCase() === "user");

    return { adminList, readOnlyUsers };
  }, [appUsers]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const filteredUsers = React.useMemo(() => {
    if (searchInput === "") {
      return readOnlyUsers;
    }

    return readOnlyUsers.filter(
      ({ user }) =>
        user?.given_name?.toLowerCase().includes(searchInput) ||
        user?.family_name?.toLowerCase().includes(searchInput) ||
        user?.email?.toLowerCase().includes(searchInput),
    );
  }, [readOnlyUsers, searchInput]);

  return {
    adminList,
    searchInput,
    filteredUsers,
    onInputChange,
  };
};
