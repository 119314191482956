import { DeleteModal } from "UIKit";
import { ChildReference } from "Utilities/types";

import { useDeleteSection } from "./hooks";

export interface DeleteSectionModalProps {
  sectionReference: ChildReference;
  onCloseModal(): void;
}

export const DeleteSectionModal: React.FC<DeleteSectionModalProps> = ({
  sectionReference,
  onCloseModal,
}) => {
  const { error, isLoading, isSuccessful, onDeleteSection } = useDeleteSection(sectionReference);

  return (
    <DeleteModal
      error={error}
      isLoading={isLoading}
      onDelete={onDeleteSection}
      onCloseModal={onCloseModal}
      isSuccessful={isSuccessful}
      title={sectionReference.name ?? ""}
      description="deleteModal_delete_section_description"
    />
  );
};
