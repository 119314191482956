import { useTranslation } from "react-i18next";
import {
  Step,
  Wizard,
  useChooseFileTypeStep,
  useConfigureFileStep,
  useWizard,
  useUploadProgressAndSuccessStep,
  useTitleAndDescriptionStep,
  AllowedChooseFileTypes,
} from "UIKit/Wizard";
import {
  AddContentItemWizardStep,
  UploadItem,
  AddItemDetails,
  UploadProgress,
  FlashcardTitleAndDescription,
  FlashcardCreator,
} from "./types";
import { useAddContentItemActions } from "./hooks/useAddContentItemActions";
import { ContentRepositoryId } from "modules/contentRepository/types";
import { useFlashcardHeroImageStep } from "Pages/Spaces/pages/Space/pages/ContentRepository/pages/ContentItems/components/AddContentItemWizard/FlashcardHeroImgStep";
import { useFlashcardCreatorStep } from "Pages/Spaces/pages/Space/pages/ContentRepository/pages/ContentItems/components/AddContentItemWizard/FlashcardCreatorStep";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { DomainIcon, FilesIcon, FlashcardIcon } from "Assets";

export interface AddContentItemWizardProps {
  subSectionId: string;
  mainSectionId: ContentRepositoryId;
  onClose: () => void;
}

const AddContentItemWizard = ({
  subSectionId,
  mainSectionId,
  onClose,
}: AddContentItemWizardProps) => {
  const { flags } = useLaunchDarkly();
  const { t } = useTranslation();
  const { wizardControl, submitWizard } = useWizard<AddContentItemWizardStep>();

  const {
    wizardState,
    addContentItemError,
    addingContentItem,
    addContentItemSuccess,
    addContentItemProgress,
    getNextStep,
    getPrevStep,
    onSubmitFileType,
    onSelectType,
    onAddFileDetails,
    onAddFlashcardDetails,
    onAddFlashcardContent,
    onSubmitFlashcardImage,
  } = useAddContentItemActions({ subSectionId, mainSectionId, onClose });

  const isFlashcardsActive = !!flags[LDFlagNames.FLASHCARDS];

  const allowedTypes: AllowedChooseFileTypes[] = [
    { type: "blob", disabled: false, icon: FilesIcon, label: t("filePicker_blob") },
    {
      type: "link",
      disabled: false,
      icon: DomainIcon,
      label: t("filePicker_link"),
    },
    {
      type: "flashcard",
      disabled: !isFlashcardsActive,
      icon: FlashcardIcon,
      label: t("filePicker_flashcard"),
    },
  ];

  const chooseFileType = useChooseFileTypeStep<UploadItem>({
    stepName: "upload-item",
    title: t("repository_add_item_title"),
    allowedTypes,
    onSubmit: onSubmitFileType,
    onSelectType,
    onClose,
    flashcardSubtitle: t("flashcard_lottie_subtitle"),
    flashcardDescription: t("flashcard_lottie_description"),
    flashcardTitle: t("flashcard_lottie_intro"),
    fileFormLabel: t("filePicker_file_to_upload_label"),
    fileUploadLabel: t("filePicker_upload_file_button"),
    fileChangeLabel: t("filePicker_change_file"),
    fileDescriptionText: t("filePicker_helper_text"),
    linkUrlLabel: t("filePicker_url_label"),
    linkUrlPlaceholder: t("filePicker_url_placeholder"),
    cancelLabel: t("general_cancel"),
    isLoading: addingContentItem,
  });

  const addItemDetails = useConfigureFileStep<AddItemDetails>({
    stepName: "add-item-details",
    title: t("repository_add_item_title"),
    onEnter: submitWizard,
    onSubmit: onAddFileDetails,
    isLoading: wizardState.selectedType === "link" ? addingContentItem : false, // TODO: check link flow if correct
    item: {
      ...wizardState.file,
    },
    toggleExpiryCheckText: t("repository_item_expiry_checkbox_text"),
    toggleExpiryLabel: t("repository_item_expiry_checkbox_label"),
    expiryDatePickerLabel: t("repository_item_expiry_date_picker_label"),
    nameInputLabel: t("repository_file_name_label"),
    nameInputPlaceholder: t("repository_file_name_placeholder"),
    keyWordsLabel: t("repository_file_keywords_label"),
    keyWordsPlaceholder: t("repository_file_keywords_placeholder"),
    nextLabel: t("general_done"),
  });

  const uploadProgressAndSuccessStep = useUploadProgressAndSuccessStep<UploadProgress>({
    stepName: "upload-progress",
    fileUploading: addingContentItem,
    uploadProgress: addContentItemProgress,
    fileUploadError: addContentItemError,
    fileUploadedSuccess: addContentItemSuccess,
    onFinishUpload: onClose,
  });

  const flashcardTitleAndDescription = useTitleAndDescriptionStep<FlashcardTitleAndDescription>({
    stepName: "flashcard-title-and-description",
    onSubmit: onAddFlashcardDetails,
    title: t("flashcards_page_title"),
    titleLabel: t("flashcards_title_label"),
    titlePlaceholder: t("flashcards_title_placeholder"),
    descriptionLabel: t("flashcards_description_label"),
    descriptionPlaceholder: t("flashcards_description_placeholder"),
  });

  const flashcardCreator = useFlashcardCreatorStep<FlashcardCreator>({
    pageTitle: t("flashcards_page_title"),
    flashcardTitle: wizardState?.flashcard?.title,
    flashcardDescription: wizardState?.flashcard?.description,
    editorContent: wizardState?.flashcard?.content,
    onSubmit: onAddFlashcardContent,
    contentRepositoryId: mainSectionId,
  });

  const flashcardHeroImage = useFlashcardHeroImageStep({
    title: t("flashcards_page_title_hero_image"),
    onSubmit: onSubmitFlashcardImage,
    errorDraggedImageText: t("imagePicker_too_large_error"),
  });

  const steps: Step<AddContentItemWizardStep>[] = [
    chooseFileType,
    addItemDetails,
    uploadProgressAndSuccessStep,
    flashcardCreator,
    flashcardTitleAndDescription,
    flashcardHeroImage,
  ];

  return (
    <Wizard
      steps={steps}
      wizardControl={wizardControl}
      getNextStep={getNextStep}
      getPrevStep={getPrevStep}
    />
  );
};

export default AddContentItemWizard;
