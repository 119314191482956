import { EolasRegions } from "@eolas-medical/core";

export const regionLookup: Record<EolasRegions, { firstLanguage: string; region: string }> = {
  Uk: {
    firstLanguage: "en-GB",
    region: "United Kingdom of Great Britain and Northern Ireland (the)",
  },
  Ireland: { firstLanguage: "en-IE", region: "Ireland" },
  Us: { firstLanguage: "en-US", region: "United States" },
};
