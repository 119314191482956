import { useTranslation } from "react-i18next";
import { ToggleList, ToggleListOption } from "UIKit";

interface AccessTypeFormProps {
  defaultIsPublic: boolean;
  onChange: (isPublic: boolean) => void;
}
const AccessTypeForm = ({ defaultIsPublic, onChange }: AccessTypeFormProps) => {
  const { t } = useTranslation();

  const accessOptions: ToggleListOption[] = [
    {
      key: t("general_public"),
      title: t("manageUsers_invite_users_anyone"),
      description: t("manageUsers_invite_users_anyone_description"),
    },
    {
      key: t("general_private"),
      title: t("manageUsers_invite_users_invite_only"),
      description: t("manageUsers_invite_users_invite_only_description"),
    },
  ];

  const handleAccessTypeChange = (optionKey: string) => {
    onChange(optionKey === "public");
  };

  return (
    <div className="border border-grey-300 rounded-lg px-4 bg-white">
      <ToggleList
        options={accessOptions}
        onChange={handleAccessTypeChange}
        defaultOptionKey={defaultIsPublic ? "public" : "private"}
      />
    </div>
  );
};

export default AccessTypeForm;
