import { noNotifications } from "Assets/LottieAnimations/NoNotifications";
import { Title } from "UIKit";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";

const NoNotifications = () => {
  const { t } = useTranslation();

  return (
    <div className="px-16 pb-12" data-testid="no-notifications-lottie">
      <Lottie loop={false} play animationData={noNotifications} />
      <Title level={7} className="text-grey-600 text-center">
        {t("notification_center_no_notifications")}
      </Title>
    </div>
  );
};

export default NoNotifications;
