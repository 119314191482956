import { InnerModalWrapper, ModalBody, Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import { Pill } from "UIKit/Pill";
import { Sizes } from "UIKit/types";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { defaultSpace } from "Assets";

interface ViewSpaceDetailsModalProps {
  onClose: () => void;
  imageUrl?: string;
}

const ViewSpaceDetailsModal = ({
  onClose,
  imageUrl = defaultSpace,
}: ViewSpaceDetailsModalProps) => {
  const { t } = useTranslation();
  const { selectedSpace } = useSpacesContext();

  const notSpecified = <span className="text-grey-600">{t("general_not_specified")}</span>;

  return (
    <InnerModalWrapper>
      <div className="relative w-full h-28 rounded-md overflow-hidden">
        <img
          className="w-full h-full object-cover"
          data-testid="space-image"
          src={imageUrl}
          alt=""
        />
        <div className="absolute bottom-0 w-full h-1/5">
          <div
            className="w-full h-full"
            style={{
              backgroundImage: `
            linear-gradient(transparent, rgba(255, 255, 255, 0.8) 50%, white 100%)
          `,
            }}
          ></div>
        </div>
      </div>
      <Title level={5} className="text-center">
        {t("space_details_space_info")}
      </Title>
      <ModalBody className="flex flex-col gap-y-8">
        <div>
          <Title level={6}>{t("general_name")}</Title>
          <Text level={2}>{selectedSpace?.name}</Text>
        </div>
        <div>
          <Title level={6}>{t("general_description")}</Title>
          <Text level={2}>{selectedSpace?.description || notSpecified}</Text>
        </div>
        <div
          style={{ maxHeight: "10rem" }}
          className="overflow-y-auto space-y-2 sm:overflow-y-hidden"
        >
          <Title level={7}>{t("general_specialty")}</Title>
          <Text level={1} className="flex flex-wrap">
            {selectedSpace?.specialty?.length
              ? selectedSpace?.specialty?.map((special, index) => {
                  return (
                    <Pill
                      key={index}
                      className="bg-grey-100 inline-flex mb-2 mr-3 items-center justify-center custom-pill-font"
                      size={Sizes.SMALL}
                      value={special}
                    />
                  );
                })
              : notSpecified}
          </Text>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default ViewSpaceDetailsModal;
