import React from "react";

interface PanelProps {
  children: React.ReactNode;
  className?: string;
  testId?: string;
}

function Panel({ children, className, testId = "panel-id" }: PanelProps) {
  return (
    <div
      className={`rounded-xl bg-white px-6 py-8 ${className ? className : ""}`}
      data-testid={testId}
    >
      {children}
    </div>
  );
}

export default Panel;
