import { useTranslation } from "react-i18next";
import { FilterAllIcon, KnowledgeHubIcon, LocalIcon, UserShadowIcon } from "Assets";
import { SearchType } from "Components/MasterSearch/types";
import { Pill } from "UIKit/Pill";
import { Sizes } from "UIKit/types";
import { useMediaQuery } from "Hooks";

interface FilterPillsProps {
  selectedPill: SearchType;
  knowledgeSearchDisabled?: boolean;
  localSearchDisabled?: boolean;
  onClickPill: (searchType: SearchType) => void;
}

const FilterPills = ({
  selectedPill,
  knowledgeSearchDisabled = false,
  localSearchDisabled = false,
  onClickPill,
}: FilterPillsProps) => {
  const { t } = useTranslation();
  const media = useMediaQuery();
  const isMobile = media === "xs";

  const commonClass = "w-32 text-center cursor-pointer";
  const inactiveClass = "bg-transparent border border-grey-400";
  const activeClass = "bg-blue-500 text-white";

  const pillSize = isMobile ? Sizes.EXTRA_SMALL : Sizes.MEDIUM;

  const pillWidth = isMobile ? "w-full" : "";

  const containerClass = isMobile
    ? "py-4 mt-2 grid grid-cols-2 gap-2"
    : "flex flex-1 space-x-4 justify-center p-6 mt-2";

  const handlePillClick = (searchType: SearchType) => {
    onClickPill(searchType);
  };

  const isPillActive = (searchType: SearchType) => {
    if (selectedPill) {
      return selectedPill === searchType;
    }
    return searchType === "all"; // All pill is selected by default when no selectedPill
  };

  return (
    <div className={containerClass} data-testid="master-search-filters">
      <Pill
        value={t("masterSearch_all")}
        className={`${commonClass} ${
          isPillActive("all") ? activeClass : inactiveClass
        } ${pillWidth}`}
        onClick={() => handlePillClick("all")}
        icon={<FilterAllIcon className="h-4 w-4" />}
        size={pillSize}
      />
      <Pill
        value={t("masterSearch_me")}
        className={`${commonClass} ${
          isPillActive("me") ? activeClass : inactiveClass
        } ${pillWidth}`}
        onClick={() => handlePillClick("me")}
        icon={<UserShadowIcon className="h-4 w-4" />}
        size={pillSize}
      />
      {!localSearchDisabled && (
        <Pill
          value={t("masterSearch_local")}
          className={`${commonClass} ${
            isPillActive("local") ? activeClass : inactiveClass
          } ${pillWidth}`}
          onClick={() => handlePillClick("local")}
          icon={<LocalIcon className="h-4 w-4" />}
          size={pillSize}
        />
      )}
      {!knowledgeSearchDisabled && (
        <Pill
          value={t("masterSearch_knowledge")}
          className={`${commonClass} ${
            isPillActive("community") ? activeClass : inactiveClass
          } ${pillWidth}`}
          onClick={() => handlePillClick("community")}
          icon={<KnowledgeHubIcon className="h-4 w-4" />}
          size={pillSize}
        />
      )}
    </div>
  );
};

export default FilterPills;
