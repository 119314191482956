import { overrideTailwindClasses as tw } from "tailwind-override";

import { SpinningDotsIcon } from "Assets/Icons";

export interface LoaderProps {
  size?: number;
  className?: string;
}

export const Loader: React.FC<LoaderProps> = ({ size = 60, className = "" }) => (
  <div
    data-testid="loader"
    className={tw(`opacity-50 bg-white flex items-center justify-center ${className}`)}
  >
    <span className="animate-spin">
      <SpinningDotsIcon height={size} width={size} />
    </span>
  </div>
);
