import { Button } from "UIKit";
import { useTranslation } from "react-i18next";
import ReactPlayer, { ReactPlayerProps } from "react-player";

import { XIcon } from "Assets/Icons";

interface VideoPlayerProps {
  url: string;
  title?: string;
  onLoad?: () => void;
  onError?: (e: Error) => void;
  closeVideo?: () => void;
  onVideoProgress?: ReactPlayerProps["onProgress"];
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  title,
  onLoad,
  onError,
  closeVideo,
  onVideoProgress,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col" data-testid="videoPlayer">
      <ReactPlayer
        playing
        controls
        url={url}
        width="100%"
        height="100%"
        stopOnUnmount
        onProgress={onVideoProgress}
        onLoad={onLoad}
        onStart={onLoad}
        onError={onError}
      />

      <div className="flex justify-between my-6 items-start">
        <span className="font-semibold text-sm lg:text-base w-1/2">{title}</span>
        {closeVideo && (
          <Button onClick={closeVideo} color="grey" data-testid="hide-video">
            <span className="font-normal text-sm">{t("EdPortal_videoPlayer_button")}</span>
            <XIcon className="ml-2" height={16} width={16} />
          </Button>
        )}
      </div>
    </div>
  );
};
