import React from "react";
import { useHistory } from "react-router-dom";

import { Section, sectionStore } from "@eolas-medical/core";

import { INameable } from "Utilities/helpers";
import { ControlsWrapper, FileTileWrapper, HighlightButton } from "UIKit";

import { useHighlightChecklist } from "../hooks";

export interface ChecklistTileProps {
  checklist: Section & INameable;
  url: string;
  isAdmin: boolean;
}

export const ChecklistTile: React.FC<ChecklistTileProps> = ({ checklist, url }) => {
  const history = useHistory();
  const { toggleHighlight, notHighlighted, isLoading, toggleText } = useHighlightChecklist(
    checklist,
  );
  const isAdmin = sectionStore.isAdmin;

  return (
    <FileTileWrapper
      eolasFile={{} as any}
      key={checklist?.id}
      data-testid={`checklistTile-${checklist.name}`}
      onClick={() => history.push(`${url}/details/${encodeURIComponent(checklist?.id)}`)}
    >
      <span className="flex-1 text-lg font-semibold line-clamp-2">{checklist.name}</span>

      {isAdmin && (
        <ControlsWrapper>
          <HighlightButton
            isLoading={isLoading}
            onClick={toggleHighlight}
            highlightText={toggleText}
            isHighlighted={!notHighlighted}
            data-testid={`highlightButtonFor-${checklist.name}`}
          />
        </ControlsWrapper>
      )}
    </FileTileWrapper>
  );
};
