import { Route, RouteComponentProps, Redirect, Switch } from "react-router-dom";

import { MeTab, PersonalDetails } from "Pages/MeTab";
import { BaseAppRoute, LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts";
import Portfolio from "./pages/Portfolio/Portfolio";
import MyFiles from "./pages/MyFiles/MyFiles";

export const MeTabRoutes: React.FC<RouteComponentProps> = ({ match: { path } }) => {
  const { flags } = useLaunchDarkly();
  const isPortfolioActive = flags[LDFlagNames.TEACHING_DELIVERY];
  const isMyFilesActive = flags[LDFlagNames.MY_FILES];

  return (
    <Switch>
      <Route exact path={path} component={MeTab} />

      <Route exact path={`${path}/personal-details`} component={PersonalDetails} />

      {isPortfolioActive && <Route path={`${path}/portfolio`} component={Portfolio} />}

      {isMyFilesActive && <Route path={`${path}/my-files`} component={MyFiles} />}

      <Redirect to={`/${BaseAppRoute.myProfile}`} />
    </Switch>
  );
};
