import { EyeIcon, InvisibleIcon } from "Assets";

interface ShowPasswordIconProps {
  showPassword: boolean;
  onClick: () => void;
}
const ShowPasswordIcon = ({ showPassword, onClick }: ShowPasswordIconProps) => {
  const iconsClass = "mr-2 w-4.5 h-4.5 cursor-pointer";
  return showPassword ? (
    <InvisibleIcon className={iconsClass} onClick={onClick} data-testid="invisible-icon" />
  ) : (
    <EyeIcon className={iconsClass} onClick={onClick} data-testid="eye-icon" />
  );
};

export default ShowPasswordIcon;
