import { isValid, subDays } from "date-fns";
import { useMemo, useState } from "react";

import {
  sectionStore,
  ChecklistRecord,
  AppLevelSection,
  OrganisationLevelSection,
} from "@eolas-medical/core";

import { useMasterSearch } from "Hooks";

import { FILTERS } from "../components";
import { sortByUpdated } from "Utilities/helpers";
import { isChecklist } from "Utilities";

export const useHighlightFileList = () => {
  const {
    filteredFiles,
    filteredChecklists,
    masterSearchValue,
    onMasterSearchChange,
  } = useMasterSearch(true);

  const [filter, setFilter] = useState(FILTERS.ALL);

  const activeSectionIDs = useMemo(
    () => [
      ...sectionStore.getMainSectionList("department").map((childRef) => childRef.id),
      ...sectionStore.getMainSectionList("hospital").map((childRef) => childRef.id),
    ],
    [],
  );

  const within30Days = subDays(new Date(), 30);

  const highlightedChecklists = useMemo(() => {
    return filteredChecklists
      .filter((template: ChecklistRecord) => {
        const sectionRef = sectionStore.getSection(template.id!);
        const childReference = sectionStore.getChildReferenceByMainSectionType(
          sectionRef.mainSectionType as AppLevelSection,
        );
        const updatedAt = sectionRef.updatedAt;

        if (!updatedAt) return false;

        if (childReference?.disabled === "true") return false;

        if (sectionRef.highlighted)
          return {
            ...template,
            updatedAt: sectionRef.updatedAt,
          };
        const lastUpdate = new Date(updatedAt!);
        // if highlighted return always else check if no older than 30 days
        return lastUpdate.valueOf() >= within30Days.valueOf();
      })
      .map((template: ChecklistRecord) => {
        const sectionRef = sectionStore.getSection(template.id!);
        return {
          ...template,
          updatedAt: sectionRef.updatedAt,
        };
      });
  }, [filteredChecklists, within30Days]);

  const highlightedFiles = useMemo(() => {
    return filteredFiles.filter((file) => {
      if (!activeSectionIDs.includes(file.mainSectionID!)) return false;
      if (!file.updatedAt) return false;
      const lastUpdate = new Date(file.updatedAt);
      if (file.highlighted) return file;
      const fileSectionType = sectionStore.getMainSectionTypeFromMainSectionID(file.mainSectionID!);
      if (fileSectionType === OrganisationLevelSection.medusaMedications) return false;
      return lastUpdate.valueOf() >= within30Days.valueOf();
    });
  }, [filteredFiles, within30Days, activeSectionIDs]);

  const allItems: any[] = useMemo(() => {
    return [...highlightedFiles, ...highlightedChecklists];
  }, [highlightedChecklists, highlightedFiles]);

  const isAdmin = sectionStore.isAdmin;
  const hasNoFiles = allItems.length === 0;

  const results = useMemo(() => {
    if (filter === FILTERS.ALL) {
      return allItems.sort(sortByUpdated);
    } else if (filter === FILTERS.DATE) {
      return allItems
        .filter((file) => !isValid(new Date(file?.highlighted!)) || file?.highlighted === null)
        .sort(sortByUpdated);
    } else if (filter === FILTERS.HIGHLIGHTED) {
      return allItems
        .filter((file) => {
          let highligthed;
          if (isChecklist(file)) {
            const sectionRef = sectionStore.getSection(file.id!);
            highligthed = sectionRef?.highlighted;
          } else {
            highligthed = file?.highlighted;
          }
          return typeof highligthed === "string" && highligthed !== "false";
        })
        .sort(sortByUpdated);
    }
    return [];
  }, [filter, allItems]);

  return {
    filter,
    results,
    isAdmin,
    setFilter,
    hasNoFiles,
    allItems,
    onSearch: onMasterSearchChange,
    searchInput: masterSearchValue,
  };
};
