import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection } from "@eolas-medical/core";

import { DeleteFileModal, EolasFileList } from "Components";
import { EolasFile, SubSectionRouteParams } from "Utilities/types";
import { useMainSectionVisit, useSelectFile } from "Hooks";
import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSubSection from "modules/spaces/data/useSpaceSubSection";
import { AddItemWithOrganisationModal } from "Pages/Spaces/components/AddEolasFile";

export const ClinicalPathwaySection: React.FC<
  RouteComponentProps<SubSectionRouteParams>
> = observer(({ match: { url } }) => {
  const { t } = useTranslation();
  const { onSelectFile: selectFile } = useSelectFile();

  const { isAdmin } = useSpacesContext();
  const { spaceSubSection } = useSpaceSubSection(AppLevelSection.clinicalPathways);

  useMainSectionVisit({
    mainSectionId: AppLevelSection.clinicalPathways,
    subSectionId: spaceSubSection.subSectionId,
  });

  const [modal, setModal] = useState<React.ReactNode>(null);
  const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] = useState(true);

  const onCloseModal = () => {
    setModal(null);
  };

  const onAddFile = () => {
    setModal(
      <AddItemWithOrganisationModal
        subSectionId={spaceSubSection.subSectionId}
        onCloseModal={onCloseModal}
        mainSectionID={AppLevelSection.clinicalPathways}
        setShouldCloseOnOverlayClick={setShouldCloseOnOverlayClick}
      />,
    );
  };

  const onEditItem = (file: EolasFile) => {
    setModal(
      <AddItemWithOrganisationModal
        file={file}
        subSectionId={spaceSubSection.subSectionId}
        onCloseModal={onCloseModal}
        mainSectionID={AppLevelSection.clinicalPathways}
        setShouldCloseOnOverlayClick={setShouldCloseOnOverlayClick}
      />,
    );
  };

  const onDeleteItem = (file: EolasFile) => {
    setModal(<DeleteFileModal file={file} onCloseModal={onCloseModal} />);
  };

  const onSelectFile = (file: EolasFile) => {
    selectFile(file);
  };

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSubSection.subSectionName ?? ""} />

        <NavButton to={url.replace(`/${spaceSubSection.encodedSubSectionId}`, "")} />

        {isAdmin && (
          <AddButton onClick={onAddFile} data-testid="add-new-file">
            {t("clinicalPathways_add_file")}
          </AddButton>
        )}

        <EolasFileList
          isAdmin={isAdmin}
          onEdit={onEditItem}
          onAddItem={onAddFile}
          onDelete={onDeleteItem}
          subSectionId={spaceSubSection.subSectionId}
          onSelectFile={onSelectFile}
          mainSectionId={AppLevelSection.clinicalPathways}
        />
      </InnerPageWrapper>

      <Modal
        open={!!modal}
        onClose={onCloseModal}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        {modal}
      </Modal>
    </>
  );
});
