import { AuthFunctionNames, authenticationClient } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { errorStore } from "Stores/ErrorStore";

const useResetPassword = () => {
  const { mutate, isLoading, error, isSuccess } = useMutation(
    authenticationClient.forgotPasswordSubmit,
    {
      onError: (error: Error) => {
        errorStore.captureError({
          error,
          source: "user",
          authFunctionName: AuthFunctionNames.confirmForgotPassword,
        });
      },
    },
  );

  return {
    resetPassword: mutate,
    resettingPassword: isLoading,
    resetPasswordError: error?.message || undefined,
    resetPasswordSuccess: isSuccess,
  };
};

export default useResetPassword;
