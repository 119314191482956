import { useCallback } from "react";
import { userStore } from "@eolas-medical/core";
import { Text } from "UIKit";
import { EolasList } from "Components/EolasFileList/EolasList";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import useAllFiles from "modules/myFiles/data/useAllFiles";
import useSections from "modules/myFiles/data/useSections";
import { isSection } from "Pages/MeTab/pages/MyFiles/helpers";
import FileListItem from "Pages/MeTab/pages/MyFiles/pages/Files/components/FileListItem/FileListItem";
import useDragSection from "modules/myFiles/data/useDragSection";
import { FilesBySection, FileSection, MyFile } from "modules/myFiles/types";
import useAllFilesBySection from "modules/myFiles/data/useAllFilesBySection";
import SectionListItem from "../SectionListItem/SectionListItem";
import useSectionListItems from "./useSectionListItems";

const SectionList = () => {
  const { userID = "" } = userStore;

  const { sectionsList, sectionsListLoading } = useSections(userID);
  const { filesList } = useAllFiles(userID);
  const { filesBySection } = useAllFilesBySection();
  const { dragSection } = useDragSection();

  const { searchInput, searchResult, onSetSearchInput } = useLocalSearch<MyFile>({
    data: filesList,
    keysToSearch: ["name"],
  });

  const items: FileSection[] | FilesBySection[] = useSectionListItems({
    searchInput,
    filesBySection,
    searchResult,
    filesList,
    sectionsList,
  });

  const handleDragSection = useCallback(
    ({ newPrevItem, reorderedItem }) => {
      dragSection({ sectionId: reorderedItem.id, prevSectionId: newPrevItem.id });
    },
    [dragSection],
  );

  const renderItem = useCallback((item: FilesBySection | FileSection) => {
    if (isSection(item)) {
      return (
        <div key={item.id}>
          <SectionListItem section={item} />
        </div>
      );
    }

    const { name, files } = item;
    return (
      <div>
        <Text level={1}>{name}</Text>
        <div>
          {files.map((f) => {
            return (
              <div key={f.id} className="mt-3">
                <FileListItem file={f} isMenuDisabled />
              </div>
            );
          })}
        </div>
      </div>
    );
  }, []);

  return (
    <div data-testid="sections-list">
      <EolasList<FilesBySection | FileSection>
        items={items}
        renderItem={renderItem}
        onSearchInputChange={onSetSearchInput}
        isSearchable
        isDragable={searchInput?.length === 0}
        isLoading={sectionsListLoading}
        onDragEnd={handleDragSection}
      />
    </div>
  );
};

export default SectionList;
