import { ArrowLeftIcon, CheckIcon } from "Assets";
import { IconButton } from "UIKit/Button";
import { FormElement, Input } from "UIKit/FormElements";
import { IconPicker } from "UIKit/IconPicker";
import { useEffect, useState } from "react";
import { Control } from "react-hook-form";
import { SectionData } from "./types";
import { Icon } from "UIKit/IconPicker/types";

interface ConfigureSectionStepProps {
  control: Control<{ name: string; description?: string; iconName: string }>;
  nameInputLabel: string;
  descriptionInputLabel?: string;
  nameInputPlaceholder: string;
  descriptionInputPlaceholder: string;
  onSetIcon: (icon: string) => void;
  onEnter: () => void;
  isLoading: boolean;
  section?: SectionData;
  iconsList: Icon[];
  iconPickerTitle: string;
  showIconPicker: boolean;
  showDescription: boolean;
  onAddingIcon?: (isAddingIcon: boolean) => void;
}

export const ConfigureSectionStep = ({
  control,
  nameInputPlaceholder,
  nameInputLabel,
  descriptionInputLabel,
  descriptionInputPlaceholder,
  isLoading,
  iconsList,
  section,
  iconPickerTitle,
  showIconPicker,
  showDescription = false,
  onSetIcon,
  onEnter,
  onAddingIcon,
}: ConfigureSectionStepProps) => {
  const [isAddingIcon, setIsAddingIcon] = useState<boolean>(showIconPicker);
  const [selectedIconUrl, setSelectedIconUrl] = useState<string | undefined>(section?.iconUrl);

  useEffect(() => {
    setIsAddingIcon(showIconPicker);
  }, [showIconPicker]);

  useEffect(() => {
    onAddingIcon && onAddingIcon(isAddingIcon);
  }, [isAddingIcon, onAddingIcon]);

  if (isAddingIcon) {
    return (
      <div className="-mt-12">
        <IconButton
          className="my-4 mx-auto"
          icon={<ArrowLeftIcon />}
          onClick={() => setIsAddingIcon(false)}
        />

        <IconPicker
          iconsList={iconsList}
          iconKeysToSearch={["name", "keywords"]}
          defaultShowIcons
          iconURL={selectedIconUrl}
          iconContainerClassname="max-h-50vh"
          onClick={(icon) => {
            setSelectedIconUrl(icon.url);
            setIsAddingIcon(false);
            onSetIcon(icon.fileName);
          }}
        />
      </div>
    );
  }

  return (
    <div data-testid="configure-section-modal">
      <form onSubmit={onEnter}>
        <FormElement required id="name" control={control} label={nameInputLabel}>
          {(fieldProps, { isValid }) => {
            const { value, ...rest } = fieldProps;
            return (
              <Input
                {...rest}
                value={value || ""}
                size="lg"
                disabled={isLoading}
                data-testid="configure-section-modal-name-input"
                placeholder={nameInputPlaceholder}
                iconRight={isValid ? <CheckIcon className="text-green" /> : undefined}
              />
            );
          }}
        </FormElement>

        {showDescription ? (
          <FormElement required id="description" control={control} label={descriptionInputLabel}>
            {(fieldProps, { isValid }) => {
              const { value, ...rest } = fieldProps;
              return (
                <Input
                  {...rest}
                  value={value || ""}
                  size="lg"
                  disabled={isLoading}
                  data-testid="configure-section-modal-description-input"
                  placeholder={descriptionInputPlaceholder}
                  iconRight={isValid ? <CheckIcon className="text-green" /> : undefined}
                />
              );
            }}
          </FormElement>
        ) : null}

        {selectedIconUrl && (
          <IconPicker
            iconURL={selectedIconUrl}
            disabled={isLoading}
            onClick={() => {}}
            iconContainerClassname="h-50vh"
            onOpen={() => setIsAddingIcon(true)}
            iconListClassname="pr-4 sm:pr-12 pb-4"
            label={iconPickerTitle}
          />
        )}
      </form>
    </div>
  );
};

export default ConfigureSectionStep;
