import * as yup from "yup";
import { useState } from "react";
import { userStore } from "@eolas-medical/core";
import { error401, MailIcon, PadlockIcon } from "Assets";
import { useTranslation } from "react-i18next";

import { InnerModalWrapper, Button, FormElement, Input, LottieWithHeader, Title } from "UIKit";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ApiRequest } from "Utilities";
import { errorStore } from "Stores/ErrorStore";

import "../styles.css";
import { login } from "../auth.actions";

export interface SoftLogoutModalProps {
  onClose(): void;
}

export interface LoginFormShape {
  password1: string;
}

const loginSchema = yup.object().shape({
  password1: yup.string().required().label("Password"),
});

const defaultValues: LoginFormShape = {
  password1: "",
};

export const SoftLogoutModal: React.FC<SoftLogoutModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const email = userStore.userData.email;

  const [requestStatus, setRequestStatus] = useState<ApiRequest>({ status: "success" });

  const { control, handleSubmit } = useForm<LoginFormShape>({
    defaultValues,
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = handleSubmit(async (values) => {
    setRequestStatus({ status: "pending", error: "" });
    if (email) {
      try {
        await login({ email, password: values.password1 });
      } catch (error: any) {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
        });
        setRequestStatus({ status: "error", error: errorMessage });
      }
    } else {
      onClose();
    }
  });

  return (
    <InnerModalWrapper>
      <div className="flex flex-col justify-center items-center p-4">
        <form onSubmit={onSubmit} className="flex flex-col self-center lg:px-4 w-full max-w-s">
          <LottieWithHeader
            animationSize="50%"
            text={""}
            animation={error401}
            lottieOptions={{ loop: true }}
          />
          <div className="text-center mb-6">
            <Title level={5}>{t("session_expired_title_admin")}</Title>
          </div>
          <span className="text-lg font-semibold">{t("session_expired_log_back_in")}</span>
          <Input
            size="lg"
            className="mb-2 border-0 "
            disabled
            type="email"
            value={userStore.userData.email ?? ""}
            inputClassName="font-semibold"
            iconLeft={<MailIcon />}
            data-testid="sign-in-password-input"
            placeholder={t("login_enterPassword")}
          />
          <FormElement id="password1" control={control} className="mb-4">
            {(field) => (
              <Input
                {...field}
                size="lg"
                type="password"
                iconLeft={<PadlockIcon />}
                data-testid="sign-in-password-input"
                placeholder={t("login_enterPassword")}
              />
            )}
          </FormElement>

          <Button
            size="lg"
            type="submit"
            data-testid="sign-in-sign-in-button"
            className="self-center w-full md:w-1/2"
            isLoading={requestStatus.status === "pending"}
          >
            Login
          </Button>

          <span
            className={`h-4 text-sm text-red text-center my-2 ${
              requestStatus.status !== "error" && "invisible"
            }`}
          >
            {requestStatus.error}
          </span>
        </form>
      </div>
    </InnerModalWrapper>
  );
};
