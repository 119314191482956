import { gql, useMutation } from "@apollo/client";

const PUBLISH_FILE = gql`
  mutation PublishFile($parentID: String!, $input: PublishFileInput!) {
    publishFile(parentID: $parentID, input: $input) {
      id
      name
      size
      description
      key
      type
      highlighted
      deleted
      info
      infoTwo
      moreInfo
      moreInfoTwo
      searchField
      expiryDate
      createdAt
      updatedAt
      metadata
    }
  }
`;

const UPDATE_FILE = gql`
  mutation UpdateForFile($parentID: String!, $input: ModifyFileInput!) {
    updateForFile(parentID: $parentID, input: $input) {
      id
      name
      size
      description
      key
      type
      highlighted
      deleted
      info
      infoTwo
      moreInfo
      moreInfoTwo
      searchField
      expiryDate
      createdAt
      updatedAt
      metadata
    }
  }
`;

const REMOVE_FILE = gql`
  mutation RemoveFile($parentID: String!, $id: String!) {
    removeFile(parentID: $parentID, id: $id)
  }
`;

export const useFileOperations = () => {
  const [publishFile, { data: publishData, error: publishError }] = useMutation(PUBLISH_FILE);
  const [updateFile, { data: updateData, error: updateError }] = useMutation(UPDATE_FILE);
  const [removeFile, { data: removeData, error: removeError }] = useMutation(REMOVE_FILE);

  return {
    updateFile,
    removeFile,
    publishFile,
    publishData,
    updateData,
    removeData,
    publishError,
    updateError,
    removeError,
  };
};
