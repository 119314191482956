import React from "react";

import { useSelectFile } from "Hooks";
import { EolasFile } from "Utilities/types";
import { generateS3PublicImageURL } from "Utilities/general";

export const useQRLeaflet = (file: EolasFile) => {
  const { type = "" } = file;
  const [videoURL, setVideoURL] = React.useState("");

  const { error, isLoading, onSelectFile: selectFile } = useSelectFile("requestStatus");

  const QRValue = React.useMemo(() => {
    if (!file.key) return "";

    if (file.type === "link") return file.key;

    return generateS3PublicImageURL(file.key);
  }, [file]);

  const onSelectFile = React.useCallback(() => {
    selectFile(file).then((url) => {
      if (type === "mp4") {
        setVideoURL(url);
      }
    });
  }, [type, selectFile, file]);

  const shouldShowPlayer = type === "mp4" && videoURL;

  return {
    error,
    QRValue,
    videoURL,
    isLoading,
    shouldShowPlayer,
    setVideoURL,
    onSelectFile,
  };
};
