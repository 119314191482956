import { ActionButton } from "modules/portfolio/types";
import PortfolioActionButton from "Pages/MeTab/pages/Portfolio/components/PortfolioActionButton/PortfolioActionButton";
import useFeedbackFormButtons from "../../hooks/useFeedbackActionButtons";

interface FeedbackFormButtonsProps {
  actionButtons: ActionButton[];
  onClickDecline: () => void;
  onClickDelete: () => void;
  onClickSubmit: () => void;
}
const FeedbackFormButtons = ({
  actionButtons,
  onClickDecline,
  onClickDelete,
  onClickSubmit,
}: FeedbackFormButtonsProps) => {
  const feedbackButtons = useFeedbackFormButtons({
    actionButtons,
    onSubmit: onClickSubmit,
    onDecline: onClickDecline,
    onDelete: onClickDelete,
  });
  return (
    <div className="flex flex-1 justify-between">
      {feedbackButtons.map((ab) => (
        <div key={ab.type} className="w-full m-2.5">
          <PortfolioActionButton
            testId={`-${ab.type}`}
            isLoading={ab.isLoading || false}
            type={ab.type}
            onClick={ab.onClick}
          />
        </div>
      ))}
    </div>
  );
};

export default FeedbackFormButtons;
