import React from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

import "./toggle.css";
import { Text } from "../../Typography";

export interface ToggleProps {
  id?: string;
  label?: string;
  checked: boolean;
  className?: string;
  labelClassName?: string;
  onChange(value: boolean): void;
}

export const Toggle: React.FC<ToggleProps> = ({
  id,
  label,
  checked,
  className,
  labelClassName,
  onChange,
}) => {
  return (
    <div className="flex items-center space-x-2">
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={() => {
          onChange(!checked);
        }}
        className={tw(`
          eolas-toggle-input bg-grey-200 checked:bg-blue-100 h-6 w-10 cursor-pointer ${className}
        `)}
      />

      {label && (
        <Text
          level={2}
          htmlFor={id}
          element="label"
          className={tw(`cursor-pointer ${labelClassName}`)}
        >
          {label}
        </Text>
      )}
    </div>
  );
};
