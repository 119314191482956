import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { AppLevelSection, OrganisationLevelSection } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { SectionList, useSectionManagement } from "Pages/Spaces/components/SectionManagement";
import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import { useEolasNavigation } from "Components/Navigation/hooks";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const AdminContactsPage: React.FC<RouteComponentProps> = observer(
  ({ location: { pathname }, match: { url } }) => {
    const { t } = useTranslation();
    const { activeTab } = useEolasNavigation();

    const { mainSectionID } = useMemo(() => {
      const isOrganisationTab = activeTab === "organisation";
      const mainSectionID = isOrganisationTab
        ? OrganisationLevelSection.hospitalContacts
        : AppLevelSection.contacts;

      return {
        mainSectionID,
      };
    }, [activeTab]);

    useMainSectionVisit({
      mainSectionId: mainSectionID,
    });

    const { spaceSection } = useSpaceSection(mainSectionID);

    const {
      modal,
      onAddSection,
      onCloseModal,
      onEditSection,
      onDeleteSection,
    } = useSectionManagement(spaceSection.id);

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection.name ?? t("contacts_page_title")} />
          <NavButton to={url.replace(`/${mainSectionID}`, "")} />

          <AddButton onClick={onAddSection} data-testid="add-new-subSection">
            {t("addNewSection_button")}
          </AddButton>

          <SectionList
            isAdmin
            sectionID={spaceSection.id}
            onEdit={onEditSection}
            onAddItem={onAddSection}
            onDelete={onDeleteSection}
          />
        </InnerPageWrapper>

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </>
    );
  },
);
