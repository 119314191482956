import { useTranslation } from "react-i18next";
import { userStore } from "@eolas-medical/core";
import { Modal, ConfirmationModal, NavButton } from "UIKit";

import { EventForm as EventFormTemplate, EventStatus, EventType } from "modules/portfolio/types";
import { EventSavedModal } from "./EventSavedModal";
import AssignAssessorModal from "../AssignAssessorModal/AssignAssessorModal";
import EventAssessors from "./EventAssessors";
import EventFormButtons from "./EventFormButtons";
import EventFormBody from "./EventFormBody";
import OptionalAssignAssessorModal from "Pages/MeTab/pages/Portfolio/components/OptionalAssignUserModal/OptionalAssignAssessorModal";
import ViewFeedbackModal from "../ViewFeedbackModal/ViewFeedbackModal";
import ViewQRCodeModal from "../ViewQRCodeModal/ViewQRCodeModal";

import useEventActions from "../../hooks/useEventActions";
import useEventForm from "../../hooks/useEventForm";
import useEventFormInitialValues from "../../hooks/useEventFormInitialValues";
import PortfolioWizardButton from "../PortfolioWizardButton/PortfolioWizardButton";
import PortfolioWizardModal from "../PortfolioWizardModal/PortfolioWizardModal";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

interface EventFormProps {
  eventId: string;
  backUrl: string;
  eventForm: EventFormTemplate;
}
const EventForm = ({ eventId, backUrl, eventForm }: EventFormProps) => {
  const { t } = useTranslation();
  const { userID, userData } = userStore;
  const { flags } = useLaunchDarkly();

  const {
    formElements = [],
    actionButtons = [],
    type = EventType.TEACHING_DELIVERY,
    areAttachmentsShared = true,
    needFeedback = false,
    assignAssessorsType = null,
    status,
    createdBy,
    isWizardCompatible,
    info,
  } = eventForm || {};

  const isEventOwner = createdBy === userID;

  const showPortfolioWizard =
    flags[LDFlagNames.PORTFOLIO_WIZARD] &&
    status === EventStatus.IN_PROGRESS &&
    !eventId &&
    isWizardCompatible;

  const initialFormValues = useEventFormInitialValues(formElements, areAttachmentsShared);

  const {
    control,
    formState: { isDirty },
    getValues,
    reset,
    handleSubmit,
  } = useEventForm({
    formElements,
    defaultValues: initialFormValues,
  });

  const {
    modalState,
    submitingEvent,
    deletingEvent,
    completingEvent,
    exportingPdfEvent,
    onCloseModal,
    onConfirmDelete,
    onConfirmExit,
    onConfirmSubmit,
    onConfirmSave,
    onLeaveEvent,
    onSaveAndExit,
    onClickSubmit,
    onClickContinue,
    onClickDelete,
    onDownloadAttachment,
    onClickAttachment,
    onClickComplete,
    onConfirmComplete,
    onAssignAssessor,
    onAssignAndSubmit,
    onViewFeedback,
    onViewQRCode,
    onExportPdfEvent,
    onClickWizardButton,
  } = useEventActions({
    eventId,
    eventType: type,
    ownerId: userID || "",
    formElements,
    getValues,
    initialFormValues,
    needFeedback,
    assignAssessorsType,
    isEventOwner,
  });

  const isWizardModal = modalState === "wizardPrompt";

  return (
    <>
      <NavButton onClick={() => onLeaveEvent(backUrl)} />
      <Modal
        open={modalState !== null}
        onClose={onCloseModal}
        hasCloseIcon={!isWizardModal}
        shouldCloseOnOverlayClick={!isWizardModal}
      >
        {modalState === "confirmExit" && (
          <ConfirmationModal
            onDecline={onConfirmExit}
            onAccept={onSaveAndExit}
            iconType="MajorIncidentIcon"
            acceptButtonProps={{ color: "blue" }}
            title={t("portfolio_navigate_from_unsaved_event_title")}
            description={t("portfolio_navigate_from_unsaved_event_main")}
            acceptLabel={t("portfolio_navigate_from_unsaved_event_submit")}
            declineLabel={t("portfolio_navigate_from_unsaved_event_exit")}
          />
        )}
        {modalState === "optionalAssessor" && (
          <OptionalAssignAssessorModal
            onAssign={onAssignAssessor}
            onSubmit={onConfirmComplete}
            isLoading={completingEvent}
          />
        )}
        {modalState === "assignAssessor" && (
          <AssignAssessorModal
            onCancel={onCloseModal}
            onSubmit={onAssignAndSubmit}
            isLoading={submitingEvent}
            ownerId={userID || ""}
            eventStatus={status}
            eventOwnerEmail={userData.email || ""}
          />
        )}
        {modalState === "confirmReview" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onConfirmSubmit}
            iconType="GreenTickIcon"
            title={t("portfolio_publish_event_title")}
            description={t("portfolio_publish_event_main")}
            acceptLabel={t("portfolio_submit_event_review_submit")}
            declineLabel={t("portfolio_submit_event_review_cancel")}
            declineButtonProps={{ disabled: submitingEvent }}
            acceptButtonProps={{ color: "blue", isLoading: submitingEvent }}
          />
        )}
        {modalState === "confirmDelete" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onConfirmDelete}
            iconType="MajorIncidentIcon"
            title={t("portfolio_delete_event_title")}
            description={t("portfolio_delete_event_main")}
            acceptLabel={t("portfolio_delete_event_submit")}
            declineLabel={t("portfolio_decline_event_title")}
            declineButtonProps={{ disabled: deletingEvent }}
            acceptButtonProps={{ color: "red", isLoading: deletingEvent }}
          />
        )}
        {modalState === "confirmSave" && <EventSavedModal onCloseModal={onCloseModal} />}
        {modalState === "confirmCompletionNoFeedback" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onConfirmComplete}
            iconType="GreenTickIcon"
            declineButtonProps={{ disabled: completingEvent }}
            acceptButtonProps={{ color: "blue", isLoading: completingEvent }}
            title={t("portfolio_complete_no_feedback_event_title")}
            description={t("portfolio_complete_no_feedback_event_main")}
            acceptLabel={t("portfolio_complete_no_feedback_submit")}
            declineLabel={t("portfolio_complete_no_feedback_cancel")}
          />
        )}
        {modalState === "confirmCompletion" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onConfirmComplete}
            iconType="MajorIncidentIcon"
            declineButtonProps={{ disabled: completingEvent }}
            acceptButtonProps={{ color: "blue", isLoading: completingEvent }}
            title={t("portfolio_complete_event_title")}
            description={t("portfolio_complete_event_main")}
            acceptLabel={t("portfolio_complete_submit")}
            declineLabel={t("portfolio_complete_cancel")}
          />
        )}

        {modalState === "viewFeedback" && (
          <ViewFeedbackModal eventId={eventId} onClose={onCloseModal} />
        )}
        {modalState === "viewQRCode" && (
          <ViewQRCodeModal onClose={onCloseModal} eventId={eventId} eventType={eventForm?.type!} />
        )}
        {modalState === "wizardPrompt" && (
          <PortfolioWizardModal
            ownerId={userID || ""}
            eventType={type}
            formElements={eventForm.formElements}
            onClose={onCloseModal}
          />
        )}
      </Modal>

      <div>
        {showPortfolioWizard && <PortfolioWizardButton onClick={onClickWizardButton} />}

        <EventFormBody
          formElements={formElements}
          control={control}
          status={status}
          areAttachmentsShared={areAttachmentsShared}
          info={info}
          onDownloadAttachment={onDownloadAttachment}
          onClickAttachment={onClickAttachment}
        />

        <EventAssessors
          eventId={eventId}
          eventType={type}
          status={status}
          isEventOwner={isEventOwner}
          needFeedback={needFeedback}
          assignAssessorsType={assignAssessorsType}
          eventOwnerId={userID || ""}
          eventOwnerEmail={userData.email || ""}
        />
      </div>

      <EventFormButtons
        formControl={control}
        actionButtons={actionButtons}
        isNew={eventId !== ""}
        isDirty={isDirty}
        onSave={() => {
          onConfirmSave();
          reset({}, { keepValues: true });
        }}
        exportingPdfEvent={exportingPdfEvent}
        onSubmit={handleSubmit(onClickSubmit)}
        onComplete={handleSubmit(onClickComplete)}
        onDelete={onClickDelete}
        onContinue={handleSubmit(onClickContinue)}
        onAssignAssessor={handleSubmit(onAssignAssessor)}
        onViewFeedback={onViewFeedback}
        onViewQRCode={onViewQRCode}
        onExportPdfEvent={onExportPdfEvent}
      />
    </>
  );
};

export default EventForm;
