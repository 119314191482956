import { QueryEnvironment, useEolasQuery } from "Hooks";
import { getRecentlyAssingedAssessors } from "../client/portfolio.client";
import { Assessor } from "../types";
import portfolioKeys from "./portfolio.queryKeys";

interface UseRecentlyAssingedAssessors {
  recentlyAssingedAssessors: Assessor[];
  recentlyAssignedLoading: boolean;
}
const useRecentlyAssingedAssessors = (ownerId: string): UseRecentlyAssingedAssessors => {
  const { isLoading, data } = useEolasQuery({
    queryFn: () => getRecentlyAssingedAssessors(ownerId),
    queryKey: portfolioKeys.recentlyAssigned(ownerId),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 300000,
      staleTime: 60000,
    },
  });

  return {
    recentlyAssingedAssessors: data || [],
    recentlyAssignedLoading: isLoading,
  };
};

export default useRecentlyAssingedAssessors;
