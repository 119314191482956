import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cancelSupervisionRequest } from "../client/portfolio.client";
import portfolioKeys from "./portfolio.queryKeys";
import { SupervisorsList, SupervisionSummary } from "../client/portfolio.graphql";
import { removeObjectFromList } from "Utilities/helpers";
import { SupervisorRequestCreatedBy } from "../types";

const useCancelSupervisionRequest = (createdBy: SupervisorRequestCreatedBy) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(cancelSupervisionRequest, {
    onSuccess: (cancelResult, supervisionRequestId) => {
      if (cancelResult.deleted) {
        if (createdBy === SupervisorRequestCreatedBy.SUPERVISEE) {
          queryClient.setQueryData(
            portfolioKeys.supervisorsList(),
            (actualRequests: SupervisorsList | undefined) => {
              if (actualRequests && actualRequests.supervisionRequestsSummary) {
                return {
                  ...actualRequests,
                  supervisionRequestsSummary: removeObjectFromList<SupervisionSummary>(
                    actualRequests.supervisionRequestsSummary as SupervisionSummary[],
                    "id",
                    supervisionRequestId,
                  ),
                };
              }
            },
          );
        }
      }
    },
  });

  return {
    cancelRequest: mutate,
    cancelingRequest: isLoading,
    ...rest,
  };
};

export default useCancelSupervisionRequest;
