import { RouteComponentProps, useLocation } from "react-router-dom";

import { Modal } from "UIKit";

import { useSignUp } from "../hooks";
import { CreateAccountStep, VerificationCodeStep, LoggingInStep } from "../components";

export const SignUp: React.FC<RouteComponentProps> = ({ history: { push } }) => {
  const { state } = useLocation<{ userEmail?: string }>();
  const {
    email,
    currentStage,
    loginCredentials,
    isFromInvitation,
    onVerificationSuccess,
    onCreateAccountSuccess,
  } = useSignUp();

  return (
    <Modal open onClose={() => push("/")}>
      {currentStage === "createAccount" && (
        <CreateAccountStep
          email={email || state?.userEmail}
          onCreateAccountSuccess={onCreateAccountSuccess}
        />
      )}

      {currentStage === "enterVerificationCode" && (
        <VerificationCodeStep
          isFromInvitation={isFromInvitation}
          loginCredentials={loginCredentials}
          onVerificationSuccess={onVerificationSuccess}
        />
      )}

      {currentStage === "complete" && <LoggingInStep />}
    </Modal>
  );
};
