import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AppLevelSection, OrganisationLevelSection } from "@eolas-medical/core";
import { SectionList } from "Pages/Spaces/components/SectionManagement";
import { SearchIcon } from "Assets/Icons";
import { noFilesFound } from "Assets/LottieAnimations";
import { useSearchMainSectionFiles, useMainSectionVisit } from "Hooks";

import { ContactFileTile } from "UIKit/FileTile";
import { Input, NavButton, PageTitle, InnerPageWrapper, LottieWithHeader } from "UIKit";
import { useEolasNavigation } from "Components/Navigation/hooks";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const ClientContactsPage: React.FC<RouteComponentProps> = observer(
  ({ match: { url }, location: { pathname } }) => {
    const { t } = useTranslation();

    const { activeTab } = useEolasNavigation();
    const isOrganisationTab = activeTab === "organisation";

    const mainSectionID = isOrganisationTab
      ? OrganisationLevelSection.hospitalContacts
      : AppLevelSection.contacts;

    useMainSectionVisit({ mainSectionId: mainSectionID });
    const { spaceSection } = useSpaceSection(mainSectionID);

    const { filteredFiles: contacts, onSearchChange, searchValue } = useSearchMainSectionFiles(
      mainSectionID,
    );

    const noContactsFound = searchValue && contacts.length === 0;

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection.name ?? t("contacts_page_title")} />
          <NavButton to={url.replace(`/${mainSectionID}`, "")} />

          <Input
            value={searchValue}
            iconLeft={<SearchIcon />}
            onChange={onSearchChange}
            data-testid="contact-search-all-contacts"
            placeholder={t("contacts_search_all_placeholder")}
          />

          {noContactsFound ? (
            <LottieWithHeader
              animation={noFilesFound}
              data-testid="no-files-found"
              lottieOptions={{ loop: true }}
              text={t("contacts_no_contacts_global")}
            />
          ) : contacts.length > 0 ? (
            <div className="flex flex-col items-stretch">
              {contacts.map((contact) => (
                <ContactFileTile key={contact.id} isAdmin={false} eolasFile={contact} />
              ))}
            </div>
          ) : (
            <SectionList isAdmin={false} sectionID={spaceSection.id} />
          )}
        </InnerPageWrapper>
      </>
    );
  },
);
