import { useTranslation } from "react-i18next";

import { AddIcon } from "Assets";
import { IconButton, Title, Modal, Loader } from "UIKit";

import { LoginToken } from "./LoginToken";
import { useLoginTokens } from "./useLoginTokens";
import { CreateLoginTokenModal } from "./CreateLoginTokenModal";

export const LoginTokens = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    isModalOpen,
    loginTokens,
    onOpenModal,
    onCloseModal,
    setLoginTokens,
  } = useLoginTokens();

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-2">
          <Title level={6}>{t("manageUsers_login_tokens_title")}</Title>

          <IconButton
            size="sm"
            variant="rounded"
            icon={<AddIcon />}
            onClick={onOpenModal}
            className="bg-blue hover:bg-blue-400 text-white"
          />
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center bg-grey-100 rounded-md h-24">
            <Loader size={32} />
          </div>
        ) : loginTokens.length === 0 ? (
          <div className="flex items-center justify-center bg-grey-100 rounded-md h-24">
            <Title className="text-grey-500" level={7}>
              {t("manageUsers_login_tokens_none_found")}
            </Title>
          </div>
        ) : (
          <div className="flex flex-col rounded-md bg-white divide-y divide-grey-300 border border-grey-300">
            {loginTokens.map((token) => (
              <LoginToken setTokens={setLoginTokens} key={token} token={token} />
            ))}
          </div>
        )}
      </div>

      <Modal open={isModalOpen} onClose={onCloseModal}>
        <CreateLoginTokenModal setLoginTokens={setLoginTokens} onCloseModal={onCloseModal} />
      </Modal>
    </>
  );
};
