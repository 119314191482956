import { useS3FileUpload } from "Hooks";
import { generateS3FileKey, generateS3PublicImageURL } from "Utilities"; // Removed unused import
import { ContentRepositoryId } from "modules/contentRepository/types";
import { useCallback } from "react";

export const useHandleImageUpload = (contentRepositoryId: ContentRepositoryId) => {
  const uploadFile = useS3FileUpload();

  const handleImageUpload = useCallback(
    // this plugs directly to tinymice SDK , hence any types
    async (blobInfo: any, _: any): Promise<string> => {
      try {
        const file = blobInfo.blob();
        const s3Params = {
          isPublic: true,
          fileName: file.name,
          fileFormat: file.type,
          mainSectionId: contentRepositoryId,
          addExtension: false,
        };
        const key = generateS3FileKey(s3Params);
        await uploadFile(key, file);

        // If successful, return the image URL.
        return generateS3PublicImageURL(key);
      } catch (error) {
        throw new Error("Image upload failed. Please try again.");
      }
    },
    [contentRepositoryId, uploadFile],
  );

  return handleImageUpload;
};
