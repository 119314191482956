import { UploadProgressAndSuccessStep } from "./UploadProgressAndSuccessStep";
import { Step } from "../../types";

interface useUploadProgressAndSuccessStepProps<T> {
  stepName: T;
  fileUploadingTitle?: string;
  fileUploadSuccessText?: string;
  fileUploading: boolean;
  uploadProgress: number;
  fileUploadError: string | undefined;
  fileUploadedSuccess: boolean;
  onFinishUpload: () => void;
}

export const useUploadProgressAndSuccessStep = <T extends string>({
  stepName,
  fileUploading,
  fileUploadingTitle = "File upload status",
  fileUploadSuccessText = "Congrats, you added a new item",
  uploadProgress,
  fileUploadError,
  fileUploadedSuccess,
  onFinishUpload,
}: useUploadProgressAndSuccessStepProps<T>): Step<T> => {
  const title = fileUploading ? fileUploadingTitle : fileUploadSuccessText;

  return {
    name: stepName,
    title,
    submitError: !!fileUploadError,
    backDisabled: true,
    nextDisabled: true,
    component: (
      <UploadProgressAndSuccessStep
        fileUploading={fileUploading}
        uploadProgress={uploadProgress}
        fileUploadError={fileUploadError}
        fileUploadedSuccess={fileUploadedSuccess}
        onFinishUpload={onFinishUpload}
      />
    ),
    hideButtons: true,
  };
};
