import { useTranslation } from "react-i18next";

import { AnalyticsEvents } from "@eolas-medical/core";

import { trackEvent } from "API/Analytics";
import { Button, FormElement, Input } from "UIKit";
import { ConfirmationCodeIcon, MailIcon } from "Assets/Icons";

import "../styles.css";
import { useVerifyCode } from "../hooks";
import { resendVerificationCode } from "../auth.actions";

interface VerifyCodeProps {
  userEmail?: string;
  onFormSubmit(): void;
  onResendCode(): void;
}

export const VerifyCode: React.FC<VerifyCodeProps> = ({
  onFormSubmit,
  onResendCode,
  userEmail = "",
}) => {
  const { t } = useTranslation();
  const { control, isLoading, onSubmit, error } = useVerifyCode(userEmail, onFormSubmit);

  return (
    <form onSubmit={onSubmit} className="flex flex-col justify-center flex-2 p-4 pb-16 lg:p-16 ">
      <h2 className="text-xl font-bold text-center md:text-left mb-4">
        {t("createAccount_verifyText")}
      </h2>

      {!userEmail && (
        <FormElement id="email" control={control} renderError>
          {(field) => (
            <Input
              {...field}
              autoComplete="off"
              iconLeft={<MailIcon />}
              placeholder={t("login_verifyEmail")}
            />
          )}
        </FormElement>
      )}

      <FormElement id="text" control={control} errorMessage={error} renderError>
        {(field) => (
          <Input
            {...field}
            autoComplete="off"
            iconLeft={<ConfirmationCodeIcon />}
            data-testid="sign-in-create-verifyInput"
            placeholder={t("createAccount_verifyPlaceholder")}
          />
        )}
      </FormElement>

      <Button
        color="grey"
        type="button"
        variant="link"
        className="self-start -ml-4"
        onClick={() => {
          trackEvent(AnalyticsEvents.RESEND_VERIFICATION_CODE);
          resendVerificationCode(userEmail);
          onResendCode();
        }}
      >
        {t("createAccount_verifyResend")}
      </Button>

      <Button
        type="submit"
        isLoading={isLoading}
        className="self-center w-full mt-4"
        data-testid="create-verifyCode-submit"
      >
        {t("general_submit")}
      </Button>
    </form>
  );
};
