import { useTranslation } from "react-i18next";
import { ArrowLeftIcon } from "Assets/Icons";
import { IconButton, InnerModalWrapper, ModalHeader, Title } from "UIKit";

import { AppUser } from "API";
import { useCallback, useState } from "react";
import { ManageSectionsModal } from "./ManageSectionsModal";
import { useAdminEntry } from "./useAdminEntry";
import { ManageAdminAccessModal } from "./ManageAdminAccessModal";

interface Props {
  appUsers: AppUser[];
  refetchUserList: () => Promise<any>;
}

export const ManageAdminsModal: React.FC<Props> = ({ appUsers, refetchUserList }) => {
  const { t } = useTranslation();
  const [state, setState] = useState<{
    step: "manageAdmins" | "manageSections";
    selectedSections?: string[];
    userId?: string;
    userFullName?: string;
    action?: "set" | "update";
  }>({ step: "manageAdmins" });

  const { onUpdateAccessLevel, onUpdateLimitedAccess, isLoading } = useAdminEntry(refetchUserList);

  const goBack = useCallback(() => setState({ step: "manageAdmins" }), []);

  const handleLimitedAccessChange = useCallback((sectionId, checked) => {
    if (checked) {
      setState((prevState) => ({
        ...prevState,
        selectedSections: prevState.selectedSections
          ? [...prevState.selectedSections, sectionId]
          : [sectionId],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedSections: prevState.selectedSections
          ? prevState.selectedSections.filter((id) => id !== sectionId)
          : [],
      }));
    }
  }, []);

  const handleLimitedAccessSet = useCallback(async () => {
    const { userId, selectedSections, action } = state;
    if (userId && action === "set") {
      await onUpdateAccessLevel({
        userId,
        accessLevel: "admin",
        limitedAccess: selectedSections,
      });
    } else if (userId && selectedSections && action === "update") {
      await onUpdateLimitedAccess(userId, selectedSections);
    }
    goBack();
  }, [state, goBack, onUpdateAccessLevel, onUpdateLimitedAccess]);

  return (
    <InnerModalWrapper>
      {state.step === "manageSections" && (
        <IconButton
          size="sm"
          type="button"
          icon={<ArrowLeftIcon />}
          variant="rounded"
          onClick={goBack}
          data-testid="modal-back-button"
          className="absolute top-4 left-4 z-10"
        />
      )}
      <ModalHeader>
        <Title level={5}> {t("manageUsers_accessRequest_manageAdmin")}</Title>
        {state.userFullName && (
          <Title className="text-grey-600" level={9}>
            {state.userFullName}
          </Title>
        )}
      </ModalHeader>

      {state.step === "manageSections" && state.userId ? (
        <ManageSectionsModal
          selectedSections={state.selectedSections || []}
          isLoading={isLoading}
          action={state.action}
          onSubmit={handleLimitedAccessSet}
          onChange={handleLimitedAccessChange}
        />
      ) : (
        <ManageAdminAccessModal
          departmentUsers={appUsers}
          setUserAccess={onUpdateAccessLevel}
          onManageSections={({ userId, selectedSections, action, userFullName }) => {
            setState({ userId, selectedSections, action, step: "manageSections", userFullName });
          }}
        />
      )}
    </InnerModalWrapper>
  );
};
