import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import { AppLevelSection, EolasFile } from "@eolas-medical/core";

import { EolasFileList } from "Components";
import { useMainSectionVisit } from "Hooks";
import { Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";

import { HospitalPreviewModal } from "../components";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const ClientHospitalsList: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  useMainSectionVisit({ mainSectionId: AppLevelSection.hospitalList });

  const { t } = useTranslation();
  const { spaceSection } = useSpaceSection(AppLevelSection.hospitalList);

  const [modal, setModal] = useState<React.ReactNode>(null);

  const onCloseModal = () => setModal(null);

  const openHospitalModal = useCallback(
    (hospital: EolasFile) => {
      setModal(<HospitalPreviewModal hospital={hospital} />);
    },
    [setModal],
  );

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={spaceSection.name ?? t("hospitalsAdmin_hospital_page_title")} />
        <NavButton to={url.replace("/hospitalList", "")} />

        <EolasFileList
          isAdmin={false}
          draggable={false}
          onSelectFile={openHospitalModal}
          mainSectionId={AppLevelSection.hospitalList}
        />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});
