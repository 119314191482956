import { CreateMainSectionDto, userStore } from "@eolas-medical/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

import { AddButton, Button, InnerPageWrapper, Modal, NavButton, PageTitle, Text } from "UIKit";
import { observer } from "mobx-react-lite";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useCreateMainSection from "modules/mainSections/data/useCreateMainSection";
import { useLaunchDarkly } from "Contexts";
import CreateContentRepositoryWizard from "./components/CreateContentRepositoryWizard/CreateContentRepositoryWizard";
import { ActivateSectionsModal } from "./components";
import { LDFlagNames } from "Utilities/types";
import { DragIcon } from "Assets";
import useContentManagementSections from "modules/spaces/data/useContentManagementSections";
import ContentManagementList from "./components/ContentManagementList/ContentManagementList";
import { useEolasNavigation } from "Components/Navigation/hooks";

type ContentManagementModal = "create-repository" | "manage-sections" | null;

export const ContentManagement = observer(() => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { flags } = useLaunchDarkly();
  const { activeTab } = useEolasNavigation();
  const [openedModal, setOpenedModal] = useState<ContentManagementModal>(null);
  const { selectedSpace } = useSpacesContext();
  const { fullAccessLevel } = userStore;

  const { createCreateMainSection, creatingMainSection } = useCreateMainSection();

  const isSpaceContent = activeTab === "spaces";

  const {
    activeMainSections,
    deactivatedMainSections,
    hasDeactivatedSections,
  } = useContentManagementSections({
    isSpaceContent,
  });

  const showCreateContentRepositoryButton = flags[LDFlagNames.GENERIC_CONTENT_REPOSITORY];

  const handleCloseModal = () => {
    setOpenedModal(null);
  };

  const handleAddContentRepository = () => {
    setOpenedModal("create-repository");
  };

  const handleManageSections = () => {
    setOpenedModal("manage-sections");
  };

  const handleCreateContentRepository = (mainSection: CreateMainSectionDto) => {
    if (selectedSpace?.id) {
      createCreateMainSection(
        {
          ...mainSection,
          ownerId: isSpaceContent ? selectedSpace.id : selectedSpace.organisationID,
          ownerType: isSpaceContent ? "space" : "organisation",
        },
        {
          onSuccess: () => {
            handleCloseModal();
          },
        },
      );
    }
  };

  if (!selectedSpace) {
    return null;
  }

  return (
    <>
      <InnerPageWrapper>
        <PageTitle data-testid="CMTitle" title={t("contentManagement_title")} />
        <NavButton to={match.url.replace("/content-management", "")} />

        {fullAccessLevel === "admin" && (
          <div className="flex justify-center items-center space-x-4">
            {showCreateContentRepositoryButton && (
              <AddButton onClick={handleAddContentRepository}>
                {t("create_content_repository_button")}
              </AddButton>
            )}
            <Button
              size="lg"
              onClick={handleManageSections}
              disabled={!hasDeactivatedSections}
              data-testid="contentManagement-activateSection"
              color={hasDeactivatedSections ? "blue" : "grey"}
              variant="outline"
            >
              <DragIcon width={16} height={16} />
              <Text level={1} className="ml-2 font-bold">
                {hasDeactivatedSections
                  ? t("contentManagement_manageSections")
                  : t("contentManagement_activateSection_button_disabled")}
              </Text>
            </Button>
          </div>
        )}

        <ContentManagementList
          mainSections={activeMainSections}
          selectedSpace={selectedSpace}
          canEdit={fullAccessLevel === "admin"}
          isSpaceContent={activeTab === "spaces"}
        />
      </InnerPageWrapper>

      <Modal open={!!openedModal} onClose={handleCloseModal}>
        {openedModal === "create-repository" && (
          <CreateContentRepositoryWizard
            onCreate={handleCreateContentRepository}
            isLoading={creatingMainSection}
            onClose={handleCloseModal}
          />
        )}

        {openedModal === "manage-sections" && (
          <ActivateSectionsModal
            deactivatedMainSections={deactivatedMainSections}
            onCloseModal={handleCloseModal}
          />
        )}
      </Modal>
    </>
  );
});
