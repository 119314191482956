import { QueryEnvironment, useEolasQuery } from "Hooks";
import portfolioKeys from "./portfolio.queryKeys";
import { getEventFeedback } from "../client/portfolio.client";

interface UseAssessorFeedback {
  assessorFeedback: any[];
  assessorFeedbackLoading: boolean;
}

const useAssessorFeedback = (eventId: string): UseAssessorFeedback => {
  const { isLoading, data } = useEolasQuery({
    queryFn: () => getEventFeedback(eventId),
    queryKey: portfolioKeys.assessorFeedback(eventId),
    environment: QueryEnvironment.loggedIn,
    options: {
      cacheTime: 30000,
      staleTime: 15 * (60 * 1000), //15 mins
    },
  });

  return {
    assessorFeedback: data || [],
    assessorFeedbackLoading: isLoading,
  };
};

export default useAssessorFeedback;
