import React from "react";
import { useTranslation } from "react-i18next";
import { InformationModal, Modal } from "UIKit";
import { observer } from "mobx-react-lite";
import useCompleteProfileModal from "./hooks/useCompleteProfileModal";

const CompleteProfileModal = observer(() => {
  const { t } = useTranslation();
  const { modalIsOpen, onCloseModal, onRedirect } = useCompleteProfileModal();

  return (
    <Modal
      open={modalIsOpen}
      onClose={onCloseModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <InformationModal
        iconType="InformationIcon"
        title={t("complete_profile_modal_title")}
        description={t("complete_profile_modal_subtitle")}
        doneLabel={t("complete_profile_modal_update_button")}
        onDone={onRedirect}
      />
    </Modal>
  );
});

export default CompleteProfileModal;
