import { ReactNode, useEffect, useState } from "react";
import SessionContext from "./session.context";
import { Sentry, useLaunchDarkly } from "Contexts";
import { useAppSync } from "Hooks";
import { useIdleTimer } from "react-idle-timer";
import { userStore } from "@eolas-medical/core";
import { SessionState } from "../types";
import { resetAnalytics } from "API/Analytics";
import { logout } from "Pages/Authentication/auth.actions";

const SESSION_TIMEOUT = 86400000;

export const SessionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { client, initClient } = useLaunchDarkly();
  const { sync } = useAppSync();
  const { userID, userSession, userData } = userStore;
  const { isLoggedIn } = userSession;

  const [sessionState, setSessionState] = useState<SessionState>("signed-out");

  const handleOnIdle = () => {
    if (isLoggedIn) {
      client?.close();
      userStore.setLoggedStatus(false);
      Sentry.configureScope((scope) => scope.setUser(null));
      resetAnalytics();
    }
  };

  const handleRefreshSession = async () => {
    setSessionState("signed-in");
    try {
      sync();
      initClient();
    } catch {
      setSessionState("signed-out");
      logout();
    }
  };

  const handleClearSession = () => {
    setSessionState("signed-out");
    logout();
  };

  useIdleTimer({
    timeout: SESSION_TIMEOUT,
    onIdle: handleOnIdle,
    debounce: 1000,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      if (userID && userData.email) {
        setSessionState("soft-sign-out");
      } else {
        setSessionState("signed-out");
      }
    }
  }, [isLoggedIn, userID, userData]);

  return (
    <SessionContext.Provider
      value={{
        sessionState,
        user: userData,
        refreshSession: handleRefreshSession,
        clearSession: handleClearSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
