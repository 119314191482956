import imageCompression from "browser-image-compression";

interface CompressionOptions {
  maxSizeMB: number;
  maxWidthOrHeight: number;
}

const defaultOptions: CompressionOptions = {
  maxSizeMB: 2,
  maxWidthOrHeight: 512,
};

const useImageCompression = (): {
  compressImage: (file: File, options?: CompressionOptions) => Promise<File>;
} => {
  const compressImage = async (
    file: File,
    options: CompressionOptions = defaultOptions,
  ): Promise<File> => {
    const compressedImage = await imageCompression(file, options);
    return compressedImage;
  };

  return {
    compressImage,
  };
};

export default useImageCompression;
