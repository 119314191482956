import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ApolloProvider } from "@apollo/client";
import { observer } from "mobx-react-lite";
import { apolloClient, userStore } from "@eolas-medical/core";
import ScrollToTop from "Components/ScrollTop/ScrollTop";
import { useLaunchDarkly, useSentry } from "Contexts";
import { usePartialMaintenanceModeRedirect } from "Hooks";
import { AutoLogin } from "Pages/Authentication";
import { MaintenancePage } from "Pages/Maintenance";
import { ErrorFallback, LoadingOverlay } from "UIKit";
import { LDFlagNames } from "Utilities/types";
import AppRoutes from "Router";
import useNewAppRedirect from "Hooks/useNewAppRedirect/useNewAppRedirect";
import AccessLink from "Pages/Authentication/pages/Invitations/pages/AccessLink/AccessLink";
import { AcceptInvitation } from "Pages/Authentication/pages/Invitations/pages/EmaiInvitation/AcceptInvitation";
import NavigationProvider from "Components/Navigation/context/NavigationProvider";
import ChatInvite from "Pages/Chat/pages/ChatInvite";
import useRedirectToStore from "Hooks/useRedirectToStore/useRedirectToStore";

declare global {
  interface Document {
    documentMode?: any;
  }
}

const App: React.FC = observer(() => {
  const { addBreadcrumb } = useSentry();
  const { flags, isLoading: isFlagsLoading } = useLaunchDarkly();
  const { userID } = userStore;

  useNewAppRedirect();
  useRedirectToStore();

  usePartialMaintenanceModeRedirect();

  useEffect(() => {
    if (window.document.documentMode) {
      alert("Internet Explorer is not supported. Please use another browser");
    }
  }, []);

  if (isFlagsLoading) {
    // TODO make this the same as the initial index.html html in root to reduce load 'flash'
    return <LoadingOverlay />;
  }

  if (userID && flags[LDFlagNames.WEB_MAINTENANCE]) {
    addBreadcrumb({
      category: "EolasAppRouter",
      message: "Rendering <MaintenancePage />",
    });
    return <MaintenancePage />;
  }

  return (
    <>
      <ScrollToTop />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ApolloProvider client={apolloClient}>
          <NavigationProvider>
            <Switch>
              <Route exact path="/auto-login/:token" component={AutoLogin} />
              <Route exact path="/invitation/:token" component={AcceptInvitation} />
              <Route exact path="/access-link/:token" component={AccessLink} />
              <Route exact path="/chat/invite/group/:groupToken" component={ChatInvite} />
              <Route exact path="/chat/invite/private/:privateToken" component={ChatInvite} />
              <AppRoutes />
            </Switch>
          </NavigationProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </>
  );
});

export default App;
