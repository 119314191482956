import type { IconPickerIconsType } from "Assets/Icons/iconPickerIcons";

// map S3 URL to local SVG files from the bundle
// needed to make section icons work between mobile and web
// maybe think of a better place to add these?

export const s3UrlToIconMap: Record<string, IconPickerIconsType> = {
  "https://static.eolas.click/SectionImages/x-ray.png": "XRayIcon",
  "https://static.eolas.click/SectionImages/wounded.png": "WoundedIcon",
  "https://static.eolas.click/SectionImages/wellbeing.png": "WellbeingIcon",
  "https://static.eolas.click/SectionImages/urology.png": "UrologyIcon",
  "https://static.eolas.click/SectionImages/trauma_and_orthopaedics.png":
    "TraumaAndOrthopaedicsIcon",
  "https://static.eolas.click/SectionImages/the_department.png": "TheDepartmentIcon",
  "https://static.eolas.click/SectionImages/thank_you.png": "ThankYouIcon",
  "https://static.eolas.click/SectionImages/teaching_and_training.png": "TeachingAndTrainingIcon",
  "https://static.eolas.click/SectionImages/tensiometer.png": "TensiometerIcon",
  "https://static.eolas.click/SectionImages/teacher.png": "TeacherIcon",
  "https://static.eolas.click/SectionImages/syringe.png": "SyringeIcon",
  "https://static.eolas.click/SectionImages/surgery.png": "SurgeryIcon",
  "https://static.eolas.click/SectionImages/stethoscope.png": "StethoscopeIcon",
  "https://static.eolas.click/SectionImages/sonography.png": "SonographyIcon",
  "https://static.eolas.click/SectionImages/skull.png": "SkullIcon",
  "https://static.eolas.click/SectionImages/shuffle-arrows.png": "ShuffleArrowsIcon",
  "https://static.eolas.click/SectionImages/screen.png": "ScreenIcon",
  "https://static.eolas.click/SectionImages/routine.png": "RoutineIcon",
  "https://static.eolas.click/SectionImages/ribs.png": "RibsIcon",
  "https://static.eolas.click/SectionImages/rheumatology.png": "RheumatologyIcon",
  "https://static.eolas.click/SectionImages/resuscitation.png": "ResuscitationIcon",
  "https://static.eolas.click/SectionImages/respiratory.png": "RespiratoryIcon",
  "https://static.eolas.click/SectionImages/punctuality.png": "PunctualityIcon",
  "https://static.eolas.click/SectionImages/psychiatry.png": "PsychiatryIcon",
  "https://static.eolas.click/SectionImages/presentation.png": "PresentationIcon",
  "https://static.eolas.click/SectionImages/podcast.png": "PodcastIcon",
  "https://static.eolas.click/SectionImages/plaster.png": "PlasterIcon",
  "https://static.eolas.click/SectionImages/planing.png": "PlanningIcon",
  "https://static.eolas.click/SectionImages/placeholder.png": "MapPinIcon",
  "https://static.eolas.click/SectionImages/physiology.png": "PhysiologyIcon",
  "https://static.eolas.click/SectionImages/pdf.png": "PDFIcon",
  "https://static.eolas.click/SectionImages/patient_leaflets.png": "PatientLeafletsIcon",
  "https://static.eolas.click/SectionImages/paediatrics.png": "PaediatricsIcon",
  "https://static.eolas.click/SectionImages/ophthalmoscope.png": "OphtalmoscopeIcon",
  "https://static.eolas.click/SectionImages/ophthalmology.png": "OphtalmologyIcon",
  "https://static.eolas.click/SectionImages/oncology.png": "OncologyIcon",
  "https://static.eolas.click/SectionImages/obs_and_gynae.png": "ObsAndGynaeIcon",
  "https://static.eolas.click/SectionImages/newspaper.png": "NewspaperIcon",
  "https://static.eolas.click/SectionImages/newspaper_2.png": "NewspaperTwoIcon",
  "https://static.eolas.click/SectionImages/neurology.png": "NeurologyIcon",
  "https://static.eolas.click/SectionImages/nephrology.png": "KidneysIcon",
  "https://static.eolas.click/SectionImages/microbiology.png": "MicrobiologyIcon",
  "https://static.eolas.click/SectionImages/medicine.png": "MedicineTwoIcon",
  "https://static.eolas.click/SectionImages/medicine+(1).png": "MedicineOneIcon",
  "https://static.eolas.click/SectionImages/medications.png": "MedicationsIcon",
  "https://static.eolas.click/SectionImages/medical-records.png": "HospitalGuidelinesIcon",
  "https://static.eolas.click/SectionImages/medical-record.png": "MedicalRecordIcon",
  "https://static.eolas.click/SectionImages/medical-instrument.png": "MedicalInstrumentIcon",
  "https://static.eolas.click/SectionImages/local_resources.png": "HospitalListIcon",
  "https://static.eolas.click/SectionImages/joint.png": "JointIcon",
  "https://static.eolas.click/SectionImages/iv-bag+(1).png": "IvBagIcon",
  "https://static.eolas.click/SectionImages/internet.png": "DomainIcon",
  "https://static.eolas.click/SectionImages/info.png": "InfoIcon",
  "https://static.eolas.click/SectionImages/important_links.png": "ImportantLinksIcon",
  "https://static.eolas.click/SectionImages/how_to.png": "HowToIcon",
  "https://static.eolas.click/SectionImages/hospital-bed+(1).png": "HospitalBedIcon",
  "https://static.eolas.click/SectionImages/hospital+(3).png": "HospitalThreeIcon",
  "https://static.eolas.click/SectionImages/highlights.png": "HighlightsIcon",
  "https://static.eolas.click/SectionImages/haematology.png": "HaematologyIcon",
  "https://static.eolas.click/SectionImages/guidelines.png": "GuidelinesIcon",
  "https://static.eolas.click/SectionImages/geris.png": "GerisIcon",
  "https://static.eolas.click/SectionImages/gastroenterology.png": "GastroenterologyIcon",
  "https://static.eolas.click/SectionImages/fungus.png": "FungusIcon",
  "https://static.eolas.click/SectionImages/fracture.png": "FractureIcon",
  "https://static.eolas.click/SectionImages/equipment_locations.png": "EquipmentLocationsIcon",
  "https://static.eolas.click/SectionImages/ent.png": "EntIcon",
  "https://static.eolas.click/SectionImages/endocrinology.png": "EndocrinologyIcon",
  "https://static.eolas.click/SectionImages/drugs+(1).png": "DrugsIcon",
  "https://static.eolas.click/SectionImages/donor.png": "DonorIcon",
  "https://static.eolas.click/SectionImages/doctor+(4).png": "DoctorThreeIcon",
  "https://static.eolas.click/SectionImages/doctor+(3).png": "DoctorTwoIcon",
  "https://static.eolas.click/SectionImages/doctor+(2).png": "SyringeBlueIcon",
  "https://static.eolas.click/SectionImages/directional-sign.png": "DirectionalSignIcon",
  "https://static.eolas.click/SectionImages/diet.png": "DietIcon",
  "https://static.eolas.click/SectionImages/dentist.png": "DentistIcon",
  "https://static.eolas.click/SectionImages/decision+(3).png": "DecisionIcon",
  "https://static.eolas.click/SectionImages/dashboard.png": "DashboardIcon",
  "https://static.eolas.click/SectionImages/cupboard.png": "CupboardIcon",
  "https://static.eolas.click/SectionImages/crutch.png": "CrutchIcon",
  "https://static.eolas.click/SectionImages/coronavirus.png": "CoronavirusIcon",
  "https://static.eolas.click/SectionImages/contacts.png": "ContactsIcon",
  "https://static.eolas.click/SectionImages/clinical_pathways.png": "ClinicalPathwaysIcon",
  "https://static.eolas.click/SectionImages/clamp.png": "ClampIcon",
  "https://static.eolas.click/SectionImages/cholesterol.png": "CholesterolIcon",
  "https://static.eolas.click/SectionImages/cardiology.png": "CardiologyIcon",
  "https://static.eolas.click/SectionImages/cannula.png": "CanullaIcon",
  "https://static.eolas.click/SectionImages/calendar+(2).png": "CalendarTwoIcon",
  "https://static.eolas.click/SectionImages/calculator.png": "ClinicalCalculatorsIcon",
  "https://static.eolas.click/SectionImages/brief_case.png": "BriefcaseIcon",
  "https://static.eolas.click/SectionImages/blood+(1).png": "BloodIcon",
  "https://static.eolas.click/SectionImages/biochemistry.png": "BiochemistryIcon",
  "https://static.eolas.click/SectionImages/backbone.png": "BackboneIcon",
  "https://static.eolas.click/SectionImages/anaesthetics.png": "AnaestethicsIcon",
  "https://static.eolas.click/SectionImages/ampoule.png": "AmpouleTwoIcon",
  "https://static.eolas.click/SectionImages/ambulance+(2).png": "AmbulanceIcon",
  "https://static.eolas.click/SectionImages/alert+(2).png": "AlertTwoIcon",
  "https://static.eolas.click/SectionImages/alert+(1).png": "AlertIcon",
  "https://static.eolas.click/SectionImages/admission_policies.png": "AdmissionPoliciesIcon",
  "https://static.eolas.click/SectionImages/about.png": "AboutIcon",
  "https://static.eolas.click/SectionImages/027-medical+assistance.png": "MedicalAssistanceIcon",
  "https://static.eolas.click/SectionImages/022-first+aid+kit.png": "FirstAidKitIcon",
  "https://static.eolas.click/SectionImages/020-disinfectant.png": "DesinfectantTwoIcon",
  "https://static.eolas.click/SectionImages/019-desinfectant.png": "DesinfectantIcon",
  "https://static.eolas.click/SectionImages/018-syringe.png": "SyringeTwoIcon",
  "https://static.eolas.click/SectionImages/016-test.png": "TestIcon",
  "https://static.eolas.click/SectionImages/014-hazmat.png": "HazmatIcon",
  "https://static.eolas.click/SectionImages/012-gown.png": "GownIcon",
  "https://static.eolas.click/SectionImages/009-face+mask.png": "FaceMaskIcon",
  "https://static.eolas.click/SectionImages/008-tissue+paper.png": "TissuePaperIcon",
  "https://static.eolas.click/SectionImages/007-alcohol.png": "AlcoholIcon",
  "https://static.eolas.click/SectionImages/006-hand+soap.png": "HandSoapIcon",
  "https://static.eolas.click/SectionImages/005-gloves.png": "GlovesIcon",
  "https://static.eolas.click/SectionImages/004-boots.png": "BootsIcon",
  "https://static.eolas.click/SectionImages/003-protection.png": "ProtectionIcon",
  "https://static.eolas.click/SectionImages/002-face+mask.png": "FaceMaskTwoIcon",
  "https://static.eolas.click/SectionImages/pager.png": "PagerIcon",
  "https://static.eolas.click/SectionImages/landline.png": "LandlineIcon",
  "https://static.eolas.click/SectionImages/hospital+(6).png": "HospitalSixIcon",
  "https://static.eolas.click/SectionImages/email+(5).png": "EmailOpenedIcon",
  "https://static.eolas.click/SectionImages/laryngoscope.png": "LaryngoscopeIcon",
  "https://static.eolas.click/SectionImages/call.png": "CallIcon",
  "https://static.eolas.click/SectionImages/rotation.png": "RotationIcon",
  "https://static.eolas.click/SectionImages/helicopter.png": "HelicopterIcon",
  IntraMuscularIcon: "IntraMuscularIcon",
  IntraOcularIcon: "IntraOcularIcon",
  IntrathecalIcon: "IntrathecalIcon",
  IntraVenousAdultIcon: "IntraVenousAdultIcon",
  IntraVenousPaediatricIcon: "IntraVenousPaediatricIcon",
  MedusaIcon: "MedusaIcon",
  SubcutaneousIcon: "SubcutaneousIcon",
  AdultIcon: "AdultIcon",
  FolderIcon: "FolderIcon",
};

export const iconToS3PublicUrlMap: Record<IconPickerIconsType, string> = {
  XRayIcon: "https://static.eolas.click/SectionImages/x-ray.png",
  WoundedIcon: "https://static.eolas.click/SectionImages/wounded.png",
  WellbeingIcon: "https://static.eolas.click/SectionImages/wellbeing.png",
  UrologyIcon: "https://static.eolas.click/SectionImages/urology.png",
  TraumaAndOrthopaedicsIcon: "https://static.eolas.click/SectionImages/trauma_and_orthopaedics.png",
  TheDepartmentIcon: "https://static.eolas.click/SectionImages/the_department.png",
  ThankYouIcon: "https://static.eolas.click/SectionImages/thank_you.png",
  TeachingAndTrainingIcon: "https://static.eolas.click/SectionImages/teaching_and_training.png",
  TensiometerIcon: "https://static.eolas.click/SectionImages/tensiometer.png",
  TeacherIcon: "https://static.eolas.click/SectionImages/teacher.png",
  SyringeIcon: "https://static.eolas.click/SectionImages/syringe.png",
  SurgeryIcon: "https://static.eolas.click/SectionImages/surgery.png",
  StethoscopeIcon: "https://static.eolas.click/SectionImages/stethoscope.png",
  SonographyIcon: "https://static.eolas.click/SectionImages/sonography.png",
  SkullIcon: "https://static.eolas.click/SectionImages/skull.png",
  ShuffleArrowsIcon: "https://static.eolas.click/SectionImages/shuffle-arrows.png",
  ScreenIcon: "https://static.eolas.click/SectionImages/screen.png",
  RoutineIcon: "https://static.eolas.click/SectionImages/routine.png",
  RibsIcon: "https://static.eolas.click/SectionImages/ribs.png",
  RheumatologyIcon: "https://static.eolas.click/SectionImages/rheumatology.png",
  ResuscitationIcon: "https://static.eolas.click/SectionImages/resuscitation.png",
  RespiratoryIcon: "https://static.eolas.click/SectionImages/respiratory.png",
  PunctualityIcon: "https://static.eolas.click/SectionImages/punctuality.png",
  PsychiatryIcon: "https://static.eolas.click/SectionImages/psychiatry.png",
  PresentationIcon: "https://static.eolas.click/SectionImages/presentation.png",
  PodcastIcon: "https://static.eolas.click/SectionImages/podcast.png",
  PlasterIcon: "https://static.eolas.click/SectionImages/plaster.png",
  PlanningIcon: "https://static.eolas.click/SectionImages/planing.png",
  MapPinIcon: "https://static.eolas.click/SectionImages/placeholder.png",
  PhysiologyIcon: "https://static.eolas.click/SectionImages/physiology.png",
  PDFIcon: "https://static.eolas.click/SectionImages/pdf.png",
  PatientLeafletsIcon: "https://static.eolas.click/SectionImages/patient_leaflets.png",
  PaediatricsIcon: "https://static.eolas.click/SectionImages/paediatrics.png",
  OphtalmoscopeIcon: "https://static.eolas.click/SectionImages/ophthalmoscope.png",
  OphtalmologyIcon: "https://static.eolas.click/SectionImages/ophthalmology.png",
  OncologyIcon: "https://static.eolas.click/SectionImages/oncology.png",
  ObsAndGynaeIcon: "https://static.eolas.click/SectionImages/obs_and_gynae.png",
  NewspaperIcon: "https://static.eolas.click/SectionImages/newspaper.png",
  NewspaperTwoIcon: "https://static.eolas.click/SectionImages/newspaper_2.png",
  NeurologyIcon: "https://static.eolas.click/SectionImages/neurology.png",
  KidneysIcon: "https://static.eolas.click/SectionImages/nephrology.png",
  MicrobiologyIcon: "https://static.eolas.click/SectionImages/microbiology.png",
  MedicineTwoIcon: "https://static.eolas.click/SectionImages/medicine.png",
  MedicineOneIcon: "https://static.eolas.click/SectionImages/medicine+(1).png",
  MedicationsIcon: "https://static.eolas.click/SectionImages/medications.png",
  HospitalGuidelinesIcon: "https://static.eolas.click/SectionImages/medical-records.png",
  MedicalRecordIcon: "https://static.eolas.click/SectionImages/medical-record.png",
  MedicalInstrumentIcon: "https://static.eolas.click/SectionImages/medical-instrument.png",
  HospitalListIcon: "https://static.eolas.click/SectionImages/local_resources.png",
  JointIcon: "https://static.eolas.click/SectionImages/joint.png",
  IvBagIcon: "https://static.eolas.click/SectionImages/iv-bag+(1).png",
  DomainIcon: "https://static.eolas.click/SectionImages/internet.png",
  InfoIcon: "https://static.eolas.click/SectionImages/info.png",
  ImportantLinksIcon: "https://static.eolas.click/SectionImages/important_links.png",
  HowToIcon: "https://static.eolas.click/SectionImages/how_to.png",
  HospitalBedIcon: "https://static.eolas.click/SectionImages/hospital-bed+(1).png",
  HospitalThreeIcon: "https://static.eolas.click/SectionImages/hospital+(3).png",
  HighlightsIcon: "https://static.eolas.click/SectionImages/highlights.png",
  HaematologyIcon: "https://static.eolas.click/SectionImages/haematology.png",
  GuidelinesIcon: "https://static.eolas.click/SectionImages/guidelines.png",
  GerisIcon: "https://static.eolas.click/SectionImages/geris.png",
  GastroenterologyIcon: "https://static.eolas.click/SectionImages/gastroenterology.png",
  FungusIcon: "https://static.eolas.click/SectionImages/fungus.png",
  FractureIcon: "https://static.eolas.click/SectionImages/fracture.png",
  EquipmentLocationsIcon: "https://static.eolas.click/SectionImages/equipment_locations.png",
  EntIcon: "https://static.eolas.click/SectionImages/ent.png",
  EndocrinologyIcon: "https://static.eolas.click/SectionImages/endocrinology.png",
  DrugsIcon: "https://static.eolas.click/SectionImages/drugs+(1).png",
  DonorIcon: "https://static.eolas.click/SectionImages/donor.png",
  DoctorThreeIcon: "https://static.eolas.click/SectionImages/doctor+(4).png",
  DoctorTwoIcon: "https://static.eolas.click/SectionImages/doctor+(3).png",
  SyringeBlueIcon: "https://static.eolas.click/SectionImages/doctor+(2).png",
  DirectionalSignIcon: "https://static.eolas.click/SectionImages/directional-sign.png",
  DietIcon: "https://static.eolas.click/SectionImages/diet.png",
  DentistIcon: "https://static.eolas.click/SectionImages/dentist.png",
  DecisionIcon: "https://static.eolas.click/SectionImages/decision+(3).png",
  DashboardIcon: "https://static.eolas.click/SectionImages/dashboard.png",
  CupboardIcon: "https://static.eolas.click/SectionImages/cupboard.png",
  CrutchIcon: "https://static.eolas.click/SectionImages/crutch.png",
  CoronavirusIcon: "https://static.eolas.click/SectionImages/coronavirus.png",
  ContactsIcon: "https://static.eolas.click/SectionImages/contacts.png",
  ClinicalPathwaysIcon: "https://static.eolas.click/SectionImages/clinical_pathways.png",
  ClampIcon: "https://static.eolas.click/SectionImages/clamp.png",
  CholesterolIcon: "https://static.eolas.click/SectionImages/cholesterol.png",
  CardiologyIcon: "https://static.eolas.click/SectionImages/cardiology.png",
  CanullaIcon: "https://static.eolas.click/SectionImages/cannula.png",
  CalendarTwoIcon: "https://static.eolas.click/SectionImages/calendar+(2).png",
  ClinicalCalculatorsIcon: "https://static.eolas.click/SectionImages/calculator.png",
  BriefcaseIcon: "https://static.eolas.click/SectionImages/brief_case.png",
  BloodIcon: "https://static.eolas.click/SectionImages/blood+(1).png",
  BiochemistryIcon: "https://static.eolas.click/SectionImages/biochemistry.png",
  BackboneIcon: "https://static.eolas.click/SectionImages/backbone.png",
  AnaestethicsIcon: "https://static.eolas.click/SectionImages/anaesthetics.png",
  AmpouleTwoIcon: "https://static.eolas.click/SectionImages/ampoule.png",
  AmbulanceIcon: "https://static.eolas.click/SectionImages/ambulance+(2).png",
  AlertTwoIcon: "https://static.eolas.click/SectionImages/alert+(2).png",
  AlertIcon: "https://static.eolas.click/SectionImages/alert+(1).png",
  AdmissionPoliciesIcon: "https://static.eolas.click/SectionImages/admission_policies.png",
  AboutIcon: "https://static.eolas.click/SectionImages/about.png",
  MedicalAssistanceIcon: "https://static.eolas.click/SectionImages/027-medical+assistance.png",
  FirstAidKitIcon: "https://static.eolas.click/SectionImages/022-first+aid+kit.png",
  DesinfectantTwoIcon: "https://static.eolas.click/SectionImages/020-disinfectant.png",
  DesinfectantIcon: "https://static.eolas.click/SectionImages/019-desinfectant.png",
  SyringeTwoIcon: "https://static.eolas.click/SectionImages/018-syringe.png",
  TestIcon: "https://static.eolas.click/SectionImages/016-test.png",
  HazmatIcon: "https://static.eolas.click/SectionImages/014-hazmat.png",
  GownIcon: "https://static.eolas.click/SectionImages/012-gown.png",
  FaceMaskIcon: "https://static.eolas.click/SectionImages/009-face+mask.png",
  TissuePaperIcon: "https://static.eolas.click/SectionImages/008-tissue+paper.png",
  AlcoholIcon: "https://static.eolas.click/SectionImages/007-alcohol.png",
  HandSoapIcon: "https://static.eolas.click/SectionImages/006-hand+soap.png",
  GlovesIcon: "https://static.eolas.click/SectionImages/005-gloves.png",
  BootsIcon: "https://static.eolas.click/SectionImages/004-boots.png",
  ProtectionIcon: "https://static.eolas.click/SectionImages/003-protection.png",
  FaceMaskTwoIcon: "https://static.eolas.click/SectionImages/002-face+mask.png",
  PagerIcon: "https://static.eolas.click/SectionImages/pager.png",
  LandlineIcon: "https://static.eolas.click/SectionImages/landline.png",
  HospitalSixIcon: "https://static.eolas.click/SectionImages/hospital+(6).png",
  EmailOpenedIcon: "https://static.eolas.click/SectionImages/email+(5).png",
  LaryngoscopeIcon: "https://static.eolas.click/SectionImages/laryngoscope.png",
  CallIcon: "https://static.eolas.click/SectionImages/call.png",
  RotationIcon: "https://static.eolas.click/SectionImages/rotation.png",
  HelicopterIcon: "https://static.eolas.click/SectionImages/helicopter.png",
  IntraMuscularIcon: "IntraMuscularIcon",
  IntraOcularIcon: "IntraOcularIcon",
  IntrathecalIcon: "IntrathecalIcon",
  IntraVenousAdultIcon: "IntraVenousAdultIcon",
  IntraVenousPaediatricIcon: "IntraVenousPaediatricIcon",
  MedusaIcon: "MedusaIcon",
  SubcutaneousIcon: "SubcutaneousIcon",
  AdultIcon: "AdultIcon",
  FolderIcon: "FolderIcon",
};
