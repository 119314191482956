import { InnerModalWrapper, ModalBody, Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import { GradientBackdrop } from "Components/GradientBackdrop/GradientBackdrop";
import { appConfig } from "AppTypeConfig";
import { apple, EolasIcon, google } from "Assets";
import BulletItem from "UIKit/BulletItem/BulletItem";
import { useMemo } from "react";

const ChatInvite = () => {
  const { t } = useTranslation();

  const chatInviteOptions = useMemo(() => {
    return [
      {
        title: t("chat_invite_download_app_title"),
        description: t("chat_invite_download_app_description"),
      },
      { title: t("chat_invite_sign_up_title"), description: t("chat_invite_sign_up_description") },
      { title: t("chat_invite_connect_title"), description: t("chat_invite_connect_description") },
    ];
  }, [t]);

  return (
    <GradientBackdrop className="flex flex-col items-center min-h-screen overflow-y-auto">
      <div className="flex flex-col justify-center items-center flex-1">
        <div className="flex flex-col justify-center items-center">
          <EolasIcon className="h-24 w-24" />
          <Title level={7} className="text-center">
            {t("pageSubTitle")}
          </Title>
          <Title level={3} className="text-center">
            {t("eolasMedical")}
          </Title>
          <Text level={1} className="mb-2 text-center">
            {t("chat_invite_main_description")}
          </Text>
          <InnerModalWrapper>
            <ModalBody>
              <div className="flex flex-col gap-y-2">
                {chatInviteOptions.map(({ title, description }, i) => (
                  <BulletItem key={title} number={i + 1} title={title} description={description} />
                ))}
              </div>
              <div className="flex flex-col gap-1 items-center justify-center space-y-6 md:space-y-0 md:gap-4 space-x-0 md:space-x-6">
                <div>
                  <Text level={2}>{t("chat_invite_download_now_title")}</Text>
                </div>
                <div className="flex col space-x-6">
                  <a href={appConfig.appleAppStore} target="_blank" rel="noreferrer noopener">
                    <img src={apple} className="h-12 rounded-md" alt="app-store" />
                  </a>

                  <a href={appConfig.googlePlay} target="_blank" rel="noreferrer noopener">
                    <img src={google} className="h-12 rounded-md" alt="play-store" />
                  </a>
                </div>
                <div className="text-center">
                  <Text level={2}>{t("chat_invite_download_now_description")}</Text>
                </div>
              </div>
            </ModalBody>
          </InnerModalWrapper>
        </div>
      </div>
    </GradientBackdrop>
  );
};

export default ChatInvite;
