import { EolasMainSection, contentClient, fetchAllData } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { AddContentItemDto } from "../types";
import { mapToFilePublishDto } from "../helpers";
import { getBlobType } from "Utilities/fileHelpers";
import { generateS3FileKey } from "Utilities";
import { useS3FileUpload } from "Hooks";
import { useState } from "react";
import { EolasFileType } from "Utilities/types";
import { AxiosProgressEvent } from "axios";

interface AddContentItem {
  contentItemDto: AddContentItemDto;
  itemType: EolasFileType;
  file?: File;
  linkUrl?: string;
}
const useAddContentItem = (contentRepositoryId: string) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadFile = useS3FileUpload();

  const setProgress = (progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 25) / progressEvent.total!); // set completion progress to 25%
    setUploadProgress(percentCompleted);
  };

  const addContentItem = async ({ contentItemDto, itemType, file, linkUrl }: AddContentItem) => {
    let fileType;
    let key = "";

    if (itemType === "blob" && file) {
      fileType = getBlobType(file.type);

      const s3Params = {
        isPublic: false,
        fileName: file.name,
        fileFormat: file.type,
        mainSectionId: contentRepositoryId as EolasMainSection,
        addExtension: true,
      };
      key = generateS3FileKey(s3Params);

      await uploadFile(key, file, setProgress);
    }

    if (itemType === "link" && linkUrl) {
      key = linkUrl;
    }

    if (itemType === "flashcard") {
      fileType = "flashcard";
      key = contentItemDto.imageUrl || "";

      if (!contentItemDto.imageUrl && file) {
        key = generateS3FileKey({
          isPublic: true,
          fileName: contentItemDto.name,
          fileFormat: file?.type,
          mainSectionId: contentRepositoryId as EolasMainSection,
          addExtension: true,
        });

        await uploadFile(key, file, setProgress);
      }
    }

    const fileMapped = await mapToFilePublishDto({
      contentItem: contentItemDto,
      itemType,
      fileType,
      key,
    });

    await contentClient.createFile({
      parentID: contentRepositoryId,
      input: fileMapped,
    });
    setUploadProgress(50);
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(
    ({ contentItemDto, itemType, file, linkUrl }: AddContentItem) =>
      addContentItem({ contentItemDto, itemType, linkUrl, file }),
    {
      onSuccess: async () => {
        await fetchAllData();
        setUploadProgress(100);
      },
    },
  );

  return {
    addContentItem: mutate,
    addingContentItem: isLoading,
    addContentItemSuccess: isSuccess,
    addContentItemError: error as string,
    addContentItemProgress: uploadProgress,
  };
};

export default useAddContentItem;
