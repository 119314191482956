import * as yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { errorStore } from "Stores/ErrorStore";
import { ConfirmationCodeIcon, MailIcon } from "Assets";
import {
  Input,
  Loader,
  Title,
  Button,
  ModalBody,
  FormElement,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

const verifyCodeSchema = yup.object().shape({
  code: yup.string().required().label("Code"),
});

interface Props {
  verifyEmail: (code: string) => void;
  verifying: boolean;
  resendVerificationCode: () => void;
  closeModal: () => void;
}

type ResendCodeStates = "NOT_SENT" | "SENDING" | "SENT" | "ERROR";

export const VerificationCodeModal: React.FC<Props> = ({
  verifyEmail,
  resendVerificationCode,
  verifying,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [resendCodeState, setResendCodeState] = useState<ResendCodeStates>("NOT_SENT");
  const { control, handleSubmit, formState } = useForm<{ code: string }>({
    defaultValues: { code: "" },
    mode: "onChange",
    resolver: yupResolver(verifyCodeSchema),
  });

  const errorMessage =
    !formState.isSubmitSuccessful && formState.submitCount > 0
      ? t("my-medical_personal_details_emails_ver_code_incorrect_code")
      : "";

  const onResendCode = async () => {
    setResendCodeState("SENDING");

    try {
      resendVerificationCode();
      setResendCodeState("SENT");

      setTimeout(() => {
        setResendCodeState("NOT_SENT");
      }, 3000);
    } catch (error: any) {
      errorStore.captureError({ error, source: "network", retryCallback: resendVerificationCode });

      setResendCodeState("ERROR");
    }
  };

  const onSubmit = handleSubmit((values) => verifyEmail(values.code));
  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>{t("my-medical_personal_details_emails_ver_code_header")}</Title>
      </ModalHeader>

      <ModalBody>
        <form onSubmit={onSubmit} className="flex flex-col self-center w-full">
          <FormElement id="code" control={control} required errorMessage={errorMessage} renderError>
            {(field) => (
              <Input
                {...field}
                iconLeft={<ConfirmationCodeIcon />}
                placeholder={t("my-medical_personal_details_emails_ver_code_placeholder")}
              />
            )}
          </FormElement>

          <div className="mt-7 flex items-center">
            <div className="text-blue text-lg cursor-pointer" onClick={onResendCode}>
              {t("my-medical_personal_details_emails_ver_code_resend")}
            </div>
            <div className="ml-4">
              {resendCodeState === "ERROR" && (
                <span className="h-4 text-sm text-red">
                  {t("my-medical_personal_details_emails_ver_code_error")}
                </span>
              )}
              {resendCodeState === "SENDING" && <Loader size={24} className="inline-block" />}
              {resendCodeState === "SENT" && (
                <div className="flex items-center">
                  <MailIcon width={24} height={24} />
                  <div className="ml-3 h-4 text-sm">
                    {t("my-medical_personal_details_emails_ver_code_check_inbox")}
                  </div>
                </div>
              )}
            </div>
          </div>

          <Button
            type="submit"
            disabled={!formState.isValid}
            isLoading={formState.isSubmitting || verifying}
            className="self-center mt-8 w-full lg:w-1/2"
          >
            {t("general_submit")}
          </Button>
          <Button
            color="grey"
            type="button"
            variant="outline"
            onClick={closeModal}
            className="self-center mt-4 w-full lg:w-1/2"
          >
            {t("general_cancel")}
          </Button>
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
