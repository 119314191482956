import React from "react";
import { useTranslation } from "react-i18next";
import { AddButton } from "UIKit";

export interface AddNewEventButtonProps {
  onShowModal: () => void;
}

const AddNewEventButton = ({ onShowModal }: AddNewEventButtonProps) => {
  const { t } = useTranslation();

  return (
    <AddButton onClick={onShowModal} data-testid="add-new-event-button">
      {t("portfolio_add_new_event")}
    </AddButton>
  );
};

export default AddNewEventButton;
