import { EolasFile } from "@eolas-medical/core";
import { AddContentItemDto, AddContentSectionDto, ContentItem, ContentSection } from "./types";
import * as DOMPurify from "dompurify";
import { convertToLink, createSearchField, toAwsJSON } from "Utilities";
import { ChildReference, ModifyFileInput, PublishFileInput } from "@eolas-medical/core/src/graphql";
import { EolasFileType } from "Utilities/types";
import { getIconUrl } from "modules/helpers";

export const mapToContentSection = (childReference: ChildReference): ContentSection => {
  return {
    ...childReference,
    routeId: childReference.id.split("#")[1],
    iconName: childReference.icon || "",
    iconUrl: getIconUrl(childReference.icon || ""),
  };
};

export const mapToCreateSectionDto = (contentSection: AddContentSectionDto) => {
  return {
    name: contentSection.name,
    icon: contentSection.iconName,
    type: contentSection.type,
  };
};

export const mapToContentItem = (file: EolasFile): ContentItem => {
  let imageUrl;
  let fileUrl;
  let linkUrl;

  if (file.key && file.type === "link") {
    linkUrl = file.key;
  }

  if (file.key && file.type === "flashcard") {
    imageUrl = file.key;
  }

  if (file.key && file.type !== "flashcard" && file.type !== "link") {
    fileUrl = file.key;
  }

  return {
    ...file,
    name: file.name ? file.name : "",
    description: file.description || undefined,
    type: (file.type ? file.type : "pdf") as EolasFileType,
    expiryDate:
      file?.expiryDate !== null && file?.expiryDate !== "null" ? file?.expiryDate : undefined,
    createdAt: file.createdAt ? file.createdAt : undefined,
    imageUrl,
    fileUrl,
    linkUrl,
  };
};

export const mapToFilePublishDto = async ({
  contentItem,
  itemType,
  key,
  fileType,
}: {
  contentItem: AddContentItemDto;
  itemType: EolasFileType;
  key: string;
  fileType?: string;
}): Promise<PublishFileInput> => {
  const searchField = createSearchField({
    name: contentItem.name,
    type: fileType,
    description: contentItem.description,
    // moreInfo: `${key} ${info}`, // TODO: check what is this
  });

  let flashcardContent;
  if (contentItem.flashcardContent) {
    // we first sanitise HTML input to make sure it's clean then encode it
    const cleanHtml = DOMPurify.sanitize(contentItem.flashcardContent);
    flashcardContent = await encodeURIComponent(cleanHtml);
  }

  const filePublishDto = {
    name: contentItem.name,
    description: contentItem.description,
    expiryDate: contentItem?.expiryDate ? contentItem?.expiryDate : "",
    searchField,
    type: itemType === "link" ? itemType : fileType,
    key: itemType === "link" ? convertToLink(key) : key,
    metadata: contentItem.flashcardContent ? toAwsJSON({ flashcardContent }) : undefined,
  };

  return filePublishDto;
};

export const mapToFileUpdateDto = (contentItem: ContentItem, editItem?: Partial<ContentItem>) => {
  const searchField = createSearchField({
    name: editItem?.name ? editItem.name : contentItem.name,
    type: contentItem.type,
    description: editItem?.description ? editItem.description : contentItem.description,
  });

  const fileUpdateDto: ModifyFileInput = {
    id: contentItem.id,
    name: editItem?.name ? editItem.name : undefined,
    description: editItem?.description ? editItem.description : undefined,
    searchField,
    expiryDate: editItem?.expiryDate ? editItem?.expiryDate : "null",
  };

  if (contentItem.type === "link" && contentItem.linkUrl) {
    fileUpdateDto.key = editItem?.linkUrl;
  }

  return fileUpdateDto;
};

export const mapToFlashcardUpdateDto = async (
  contentItem: ContentItem,
  editItem?: Partial<AddContentItemDto>,
) => {
  let flashcardContent;
  if (editItem?.flashcardContent) {
    const cleanHtml = DOMPurify.sanitize(editItem.flashcardContent);
    flashcardContent = await encodeURIComponent(cleanHtml);
  }

  const searchField = createSearchField({
    name: editItem?.name || contentItem.name,
    type: contentItem.type,
    description: editItem?.description || contentItem.description,
  });

  return {
    id: contentItem.id,
    name: editItem?.name || contentItem.name,
    description: editItem?.description || contentItem.description,
    key: editItem?.imageUrl || contentItem.key,
    metadata: flashcardContent ? toAwsJSON({ flashcardContent }) : contentItem.metadata,
    searchField,
  };
};
