import { useQuery } from "@tanstack/react-query";
import { sectionStore, accessClient, userStore } from "@eolas-medical/core";

export const useGetAllAccessRequests = () => {
  const query = useQuery(["access-requests"], () => {
    if (userStore.userID) {
      return accessClient.listAppAccessRequests(sectionStore.appID);
    }
  });

  return {
    count: query.data?.requests?.length ?? 0,
    accessRequests: query.data?.requests ?? [],
    allAccessRequestLoading: query.isLoading,
    refetch: query.refetch,
  };
};
