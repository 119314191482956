import { BlobTypes } from "modules/generic.types";
import { MyFile, FilesBySection, FileSection } from "modules/myFiles/types";
import { findIntersection } from "Utilities/helpers";

export const isFile = (sectionListItem: MyFile | FileSection): sectionListItem is MyFile =>
  (sectionListItem as MyFile).type !== undefined;

export const isSection = (
  sectionListItem: FilesBySection | FileSection,
): sectionListItem is FileSection => (sectionListItem as FileSection).fileCount !== undefined;

export const isVideoType = (type?: BlobTypes): boolean => type === "mp4" || type === "mov";

export const filterFilesBySection = (filesBySection: FilesBySection[], files: MyFile[]) => {
  return filesBySection.reduce<FilesBySection[]>((prev, curr) => {
    const sectionFiles: MyFile[] = [...curr.files];

    const newFiles: MyFile[] = findIntersection(sectionFiles, files);

    if (newFiles.length > 0) {
      return [...prev, { ...curr, files: newFiles }];
    }

    return prev;
  }, []);
};
