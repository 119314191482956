import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  UserData,
  specialtyOptions,
  seniorityOptions,
  jobTitleOptions,
  JobTitleOptions,
  SeniorityOptions,
  SpecialtyOptions,
  userStore,
  UserLocation,
} from "@eolas-medical/core";
import {
  Button,
  FormElement,
  ImageUploader,
  InnerModalWrapper,
  Input,
  ModalBody,
  ModalHeader,
  Textarea,
  Title,
} from "UIKit";
import { ProfileIcon } from "Assets/Icons";
import Select, { Option } from "UIKit/Select/Select";
import { useTranslation } from "react-i18next";
import useGetLocations from "Hooks/useGetLocations";
import { GroupBase, OptionsOrGroups } from "react-select";

interface BlobState {
  name: string;
}

interface ProfileModalState {
  blob: BlobState | null;
  error: string | undefined;
}

export interface EditProfileModalProps {
  onSubmit: (profileData: UserData, file: File) => void;
  onClose: () => void;
  isLoading: boolean;
  defaultJobTitle: JobTitleOptions | undefined;
  defaultSeniority: SeniorityOptions | undefined;
  defaultSpecialties: SpecialtyOptions[] | undefined;
  errorDraggedImageText?: string;
}

const EditProfileModal = ({
  onSubmit,
  onClose,
  isLoading = false,
  defaultJobTitle,
  defaultSeniority,
  defaultSpecialties,
  errorDraggedImageText,
}: EditProfileModalProps) => {
  const { t } = useTranslation();

  const { aboutMe, location, givenName, familyName, photoUrl } = userStore.userData;

  const { getLocations } = useGetLocations();

  const [state, setState] = useState<ProfileModalState>({
    blob: null,
    error: "",
  });

  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    surname: yup.string().required("Surname is required"),
    jobTitle: yup.object().required("Job Title is required"),
    seniority: yup.object().required("Seniority is required"),
    specialty: yup.array().min(1, "Specialty is required").nullable(),
    aboutMe: yup.string().required("About me is required"),
    location: yup.object().required("Location is required"),
  });

  const formatLocation = ({ location }: { location?: UserLocation }) => {
    const label = `${location?.city}, ${location?.country}`;
    const value = `${location?.city},${location?.country}`;
    return { label, value };
  };

  const preloadedProfileValues = {
    firstName: givenName,
    surname: familyName,
    jobTitle: defaultJobTitle,
    seniority: defaultSeniority,
    specialty: defaultSpecialties,
    aboutMe,
    userPhoto: photoUrl,
    location: formatLocation({ location }),
  };

  const { control, handleSubmit, formState } = useForm({
    defaultValues: preloadedProfileValues,
    resolver: yupResolver(schema),
  });

  const { isDirty } = formState;

  const handleEditProfile = useCallback(
    ({
      firstName,
      surname,
      jobTitle,
      seniority,
      specialty,
      aboutMe,
      location: selectedLocation,
    }) => {
      const [city, country] = selectedLocation?.value.split(",");
      const profileData = {
        givenName: firstName,
        familyName: surname,
        jobTitle: jobTitle.value,
        seniority: seniority.value,
        specialty: specialty.map(({ value }: Option) => value),
        aboutMe,
        location: { city, country },
      };
      onSubmit(profileData, state as any);
    },
    [onSubmit, state],
  );

  const handleBlobChange = useCallback((file: File | null) => {
    setState((prevState: ProfileModalState) => ({ error: "", blob: file }));
  }, []);

  const handleLoadOptions = async (
    inputValue: string,
    callback: (options: OptionsOrGroups<unknown, GroupBase<unknown>>) => void,
  ) => {
    const locations = await getLocations(inputValue);
    const options = locations.map((location) => {
      return formatLocation({ location });
    });
    callback(options);
  };

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <Title level={5}>{t("my-medical_personal_details_edit_profile_title")}</Title>
      </ModalHeader>

      <ModalBody>
        <div className="overflow-y-auto" style={{ maxHeight: "60vh" }}>
          <form
            onSubmit={handleSubmit(handleEditProfile)}
            className="overflow-y-auto grid grid-cols-2 gap-x-6 gap-y-2 p-2"
          >
            <FormElement
              required
              id="firstName"
              control={control}
              label={t("my-medical_personal_details_edit_profile_first_name")}
            >
              {(fieldProps) => {
                const { value, ...rest } = fieldProps;
                return (
                  <Input
                    {...rest}
                    size="lg"
                    className="col-start-1 col-end-2 input pl-4 box-content rounded-3xl"
                    iconLeft={
                      <ProfileIcon className={`h-8 w-8 mr-4 hidden sm:inline text-blue-500`} />
                    }
                    placeholder={t(
                      "my-medical_personal_details_edit_profile_first_name_placeholder",
                    )}
                    value={value}
                  />
                );
              }}
            </FormElement>
            <FormElement
              required
              id="surname"
              control={control}
              label={t("my-medical_personal_details_edit_profile_surname")}
            >
              {(fieldProps) => {
                const { value, ...rest } = fieldProps;
                return (
                  <Input
                    {...rest}
                    data-testid="second-input"
                    size="lg"
                    className="col-end-3 input pl-4 box-content rounded-3xl"
                    iconLeft={
                      <ProfileIcon className={`h-8 w-8 mr-4 hidden sm:inline text-red-500`} />
                    }
                    placeholder={t("my-medical_personal_details_edit_profile_surname_placeholder")}
                    value={value}
                  />
                );
              }}
            </FormElement>
            <div className="col-span-2">
              <FormElement
                required
                id="jobTitle"
                control={control}
                label={t("my-medical_personal_details_edit_profile_job_title")}
              >
                {(fieldProps) => {
                  const { value, ...rest } = fieldProps;
                  return (
                    <Select
                      {...rest}
                      defaultValue={defaultJobTitle}
                      options={jobTitleOptions}
                      isSearchable
                    />
                  );
                }}
              </FormElement>
            </div>
            <div className="col-span-2">
              <FormElement
                id={"aboutMe"}
                control={control}
                label={t("my-medical_personal_details_edit_profile_about_me")}
                required
              >
                {(field, { className }) => (
                  <Textarea
                    {...field}
                    className={`rounded-lg p-3 resize-none`}
                    placeholder={t("my-medical_personal_details_edit_profile_about_me_placeholder")}
                  />
                )}
              </FormElement>
            </div>

            <div className="col-span-2">
              <FormElement
                required
                id="seniority"
                control={control}
                label={t("my-medical_personal_details_edit_profile_seniority")}
              >
                {(fieldProps) => {
                  const { value, ...rest } = fieldProps;
                  return (
                    <Select
                      {...rest}
                      defaultValue={defaultSeniority}
                      options={seniorityOptions}
                      isSearchable
                    />
                  );
                }}
              </FormElement>
            </div>

            <div className="col-span-2">
              <FormElement
                required
                id="specialty"
                control={control}
                label={t("my-medical_personal_details_edit_profile_specialty")}
              >
                {(fieldProps) => {
                  const { value, ...rest } = fieldProps;
                  return (
                    <Select
                      {...rest}
                      isMulti
                      options={specialtyOptions}
                      isSearchable
                      defaultValue={defaultSpecialties}
                    />
                  );
                }}
              </FormElement>
              <FormElement
                required
                id="location"
                control={control}
                label={t("my-medical_personal_details_edit_profile_location")}
              >
                {(fieldProps) => {
                  const { value, ...rest } = fieldProps;
                  return (
                    <Select
                      {...rest}
                      placeholder={t(
                        "my-medical_personal_details_edit_profile_location_placeholder",
                      )}
                      isSearchable
                      loadOptions={handleLoadOptions}
                      defaultValue={location ? formatLocation({ location }) : undefined}
                    />
                  );
                }}
              </FormElement>
              <FormElement
                id="userPhoto"
                control={control}
                label={t("my-medical_personal_details_edit_profile_picture")}
              >
                {(fieldProps) => {
                  const { value, ...rest } = fieldProps;

                  return (
                    <ImageUploader
                      {...rest}
                      croppingEnabled
                      onChange={handleBlobChange}
                      defaultValue={photoUrl || ""}
                      fileToLargeError={t("imagePicker_too_large_error")}
                      error={state.error}
                    />
                  );
                }}
              </FormElement>
            </div>
            <div>
              <Button
                type="button"
                color="grey"
                onClick={onClose}
                variant="outline"
                className="w-full col-span-1"
                data-testid="cancel-button"
              >
                {t("my-medical_personal_details_edit_profile_cancel_button")}
              </Button>
            </div>
            <div>
              <Button
                isLoading={isLoading}
                type="submit"
                className="w-full col-span-2"
                data-testid="done-button"
                disabled={!isDirty && state.blob === null}
              >
                {t("my-medical_personal_details_edit_profile_confirm_button")}
              </Button>
            </div>
          </form>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default EditProfileModal;
