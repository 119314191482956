import { useTranslation } from "react-i18next";
import { Title } from "UIKit";

interface NoEventsMessageProps {
  searchText: string;
  areFiltersApplied: boolean;
  emptyTextKey?: string;
}

const NoEventsMessage = ({
  searchText,
  areFiltersApplied,
  emptyTextKey = "portfolio_events_empty",
}: NoEventsMessageProps) => {
  const { t } = useTranslation();

  let messageKey = "portfolio_overfiltered";

  if (!areFiltersApplied && searchText.length === 0) {
    messageKey = emptyTextKey;
  }

  return (
    <div className="justify-self-center center justify-center my-4">
      <Title className="text-center" level={6}>
        {t(messageKey)}
      </Title>
    </div>
  );
};

export default NoEventsMessage;
