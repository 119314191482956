import { useMemo } from "react";
import { FilesBySection, MyFile } from "modules/myFiles/types";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import { filterFilesBySection } from "Pages/MeTab/pages/MyFiles/helpers";
import { AttachableFiles } from "UIKit";
import { findElementsNoInList } from "Utilities/helpers";

interface UseAttachableFilesProps {
  attachedFiles: MyFile[];
  filesBySection: FilesBySection[];
}

interface UseAttachableFiles {
  attachableFiles: AttachableFiles<MyFile>[];
  onSetSearchInput: (input: string) => void;
}

const useAttachableFiles = ({
  attachedFiles,
  filesBySection,
}: UseAttachableFilesProps): UseAttachableFiles => {
  const allFiles = useMemo(() => filesBySection.flatMap((fs) => fs.files), [filesBySection]);

  const { searchResult, onSetSearchInput } = useLocalSearch<MyFile>({
    data: allFiles,
    keysToSearch: ["name"],
  });

  const attachableFiles: AttachableFiles<MyFile>[] = useMemo(() => {
    return filterFilesBySection(filesBySection, searchResult)
      .filter((section) => section.files.length > 0)
      .map((section) => ({
        label: section.name,
        fileList: findElementsNoInList<MyFile>(section.files, attachedFiles),
      }));
  }, [filesBySection, attachedFiles, searchResult]);

  return { attachableFiles, onSetSearchInput };
};

export default useAttachableFiles;
