import { useMutation } from "@tanstack/react-query";
import { AppLevelSection, fetchAllData, sectionStore } from "@eolas-medical/core";
import { removeFile } from "clients/files/files.client";

const useRemoveTeamMember = () => {
  const handleRemoveTeamMember = async (teamMemberId: string) => {
    const parentID = sectionStore.getChildReferenceByMainSectionType(AppLevelSection.meetTheTeam)!
      .id;

    return removeFile({
      parentID,
      fileId: teamMemberId,
    });
  };

  const { mutate, isLoading, isSuccess, error } = useMutation(handleRemoveTeamMember, {
    onSuccess: () => {
      fetchAllData();
    },
  });

  return {
    removeTeamMember: mutate,
    removingTeamMember: isLoading,
    removeTeamMemberError: error as string,
    removeTeamMemberSuccessful: isSuccess,
  };
};

export default useRemoveTeamMember;
