import { useForm } from "react-hook-form";
import ValidateEmailModal from "./ValidateEmailModal";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { AuthenticationWizard, ValidateEmailError } from "Pages/Authentication/types";

interface UseValidateEmailStepProps {
  isLoading: boolean;
  error?: string;
  submitError?: ValidateEmailError;
  canProceed: boolean;
  onChangeWizard: (wizard: AuthenticationWizard, email: string) => void;
  onSubmit: (email: string) => void;
  onEnter: () => void;
}

const useValidateEmailStep = ({
  isLoading,
  canProceed,
  error,
  submitError,
  onSubmit,
  onEnter,
  onChangeWizard,
}: UseValidateEmailStepProps): Step<"validate-email"> => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup.string().email(t("login_invalidEmail")).required(t("login_requiredEmail")),
  });

  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitEmail = async () => {
    const email = getValues("email");

    onSubmit(email);
  };

  const handleChangeToSignInWizard = () => {
    const email = getValues("email");
    onChangeWizard("sign-in-wizard", email);
  };

  const handleChangeToSignUpWizard = () => {
    const email = getValues("email");
    onChangeWizard("sign-up-wizard", email);
  };

  return {
    name: "validate-email",
    title: t("login_validate_title"),
    subtitle: t("login_validate_subtitle"),
    component: (
      <ValidateEmailModal
        control={control}
        onSubmit={onEnter}
        error={formState.errors.email?.message || error}
        submitError={submitError}
        onClickCreateAccount={handleChangeToSignUpWizard}
        onClickSignIn={handleChangeToSignInWizard}
      />
    ),
    onSubmit: handleSubmit(handleSubmitEmail),
    canProceed,
    isLoading,
    submitError: !!error && !!submitError,
    backDisabled: true,
  };
};

export default useValidateEmailStep;
