import { fetchAllData, fileStore, sectionStore } from "@eolas-medical/core";

import { useFileOperations } from "Hooks";
import { errorStore } from "Stores/ErrorStore";
import { fromAwsJSON, toAwsJSON, useRequestStatus } from "Utilities";

import { CompletedChecklist, EolasSubmittedChecklist } from "../../types";

export const useChecklistIssues = (checklistID: string) => {
  const childrenOrder = sectionStore.getChildrenOrder(checklistID);
  const checklistSubmissions = fileStore.getFiles(childrenOrder);

  const checklistsWithIssues = checklistSubmissions
    .map((submission) => {
      const { completedChecklist }: { completedChecklist: CompletedChecklist } = fromAwsJSON(
        submission?.metadata ?? '"{}"',
      );

      return { ...submission, metadata: completedChecklist } as EolasSubmittedChecklist;
    })
    .filter((item) => item.metadata.checklistIssues);

  return {
    checklistsWithIssues,
  };
};

export const useResolveIssue = (checklist: EolasSubmittedChecklist) => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();
  const { updateFile } = useFileOperations();

  const onResolve = () => {
    const input = {
      id: checklist.id,
      metadata: toAwsJSON({
        completedChecklist: {
          ...checklist.metadata,
          additionalInfo: null,
          checklistIssues: null,
        },
      }),
    };

    setRequestStatus({ status: "pending", error: "" });
    updateFile({ variables: { input, parentID: checklist.parentID } })
      .then(async () => {
        await fetchAllData();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error: any) => {
        const errorMessage = errorStore.captureError({ error, source: "user" });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  };

  return {
    error,
    isLoading,
    onResolve,
  };
};
