const spacesKeys = {
  all: ["spaces"],
  list: () => [...spacesKeys.all, "list"],
  detail: (spaceId: string) => [...spacesKeys.all, spaceId],
  usersList: (spaceId: string) => [...spacesKeys.all, "usersList", spaceId],
  spaceUsers: (spaceId: string) => ["spaceUsers", spaceId],
  spaceAccessToken: (spaceId: string) => ["spaceAccessToken", spaceId],
};

export default spacesKeys;
