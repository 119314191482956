import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";

import { Button, FormElement, FormFieldError, Input } from "UIKit";

import { useAddNewSection } from "../hooks";
import { ChecklistSection } from "../types";

import { BuilderSectionItemList } from "./BuilderSectionItemList";

interface AddNewSectionProps {
  checklistSectionsError: string;
  onCancel(): void;
  onSave(section: ChecklistSection): void;
}

export const AddNewSection: React.FC<AddNewSectionProps> = ({
  onSave,
  onCancel,
  checklistSectionsError,
}) => {
  const { t } = useTranslation();

  const {
    control,
    formMethods,
    sectionItems,
    onSwap,
    onSubmit,
    onAddItem,
    onEditItem,
    onDeleteItem,
  } = useAddNewSection(onSave);

  return (
    <FormProvider {...formMethods}>
      <motion.div
        exit={{ opacity: 0, scale: 0 }}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        className={`
          flex flex-col rounded-md bg-white shadow-sm origin-top
          p-4 sm:p-10 my-4 sm:my-6
          ${checklistSectionsError ? "input-error" : ""}
        `}
      >
        <FormElement
          required
          className="mb-4"
          control={control}
          id="checklistSectionName"
          label={t("checklistBuilder_section_name_label")}
        >
          {(field, { className }) => (
            <Input
              {...field}
              size="lg"
              className={`${className} bg-grey-50 hover:bg-white`}
              placeholder={t("checklistBuilder_section_name_placeholder")}
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            />
          )}
        </FormElement>

        <BuilderSectionItemList
          onSwap={onSwap}
          onAddItem={onAddItem}
          onEditItem={onEditItem}
          onDeleteItem={onDeleteItem}
          sectionItems={sectionItems}
        />

        <div className="flex items-center justify-end space-x-6 mt-4">
          <Button
            size="lg"
            color="grey"
            type="button"
            variant="outline"
            onClick={onCancel}
            className="w-full sm:w-32"
          >
            {t("general_cancel")}
          </Button>

          <Button size="lg" onClick={onSubmit} className="w-full sm:w-32" type="button">
            {t("general_save")}
          </Button>
        </div>
      </motion.div>
      <FormFieldError>{checklistSectionsError}</FormFieldError>
    </FormProvider>
  );
};
