import { useCallback } from "react";

import { useFileOperations } from "Hooks";
import { EolasFile } from "Utilities/types";
import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";
import { fetchAllData } from "@eolas-medical/core";

export const useDeleteSubmittedChecklist = (checklist: EolasFile) => {
  const { removeFile } = useFileOperations();
  const { setRequestStatus, isSuccessful, isLoading, error } = useRequestStatus();

  const onDelete = useCallback(() => {
    setRequestStatus({ status: "pending", error: "" });
    removeFile({
      variables: {
        id: checklist.id,
        parentID: checklist.parentID,
      },
    })
      .then(async () => {
        await fetchAllData();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error) => {
        const errorMessage = errorStore.captureError({
          error,
          source: "user",
        });
        setRequestStatus({ status: "error", error: errorMessage });
      });
  }, [setRequestStatus, checklist.id, checklist.parentID, removeFile]);

  return {
    error,
    isLoading,
    isSuccessful,
    onDelete,
  };
};
