import {
  PortfolioNativeComponent,
  PortfolioComplexComponent,
  FormElement,
  PortfolioFieldNames,
} from "modules/portfolio/types";
import { Control } from "react-hook-form";
import { PortfolioDatePicker } from "./components/PortfolioDatePicker/PortfolioDatePicker";
import { NumberFeedbackButtons } from "./components/PortfolioNumberFeedback/NumberFeedback";
import { PortfolioTextInput } from "./components/PortfolioTextInput/PortfolioTextInput";
import { PortfolioTags } from "./components/PortfolioTags/PortfolioTags";
import { PortfolioAttachments } from "../PortfolioAttachments/PortfolioAttachments";

const formComponents: { [type: string]: any } = {
  [PortfolioNativeComponent.DATE_PICKER]: PortfolioDatePicker,
  [PortfolioNativeComponent.NUMBER_FEEDBACK]: NumberFeedbackButtons,
  [PortfolioNativeComponent.TEXT_INPUT]: PortfolioTextInput,
  [PortfolioNativeComponent.BEST_ANSWER_SELECTOR]: PortfolioTags,
  [PortfolioComplexComponent.ATTACH_BUTTON]: PortfolioAttachments,
};

interface EventFormElementData {
  [key: string]: PortfolioFieldNames;
}
interface EventFormElementProps {
  item: FormElement;
  control: Control<EventFormElementData>;
}

const PortfolioFormElement = ({ item, control, ...rest }: EventFormElementProps) => {
  if (!item.componentConfig.type) {
    return null;
  }

  const Component = formComponents[item.componentConfig.type];

  if (!Component) return null;

  return (
    <div className="justify-center justify-self-center mx-auto mb-2">
      <Component
        {...item.componentConfig}
        required={item.required}
        control={control}
        fieldName={item.fieldName}
        type={item.fieldName}
        {...rest}
      />
    </div>
  );
};

export default PortfolioFormElement;
