import { Control } from "react-hook-form";
import { PasswordInput, PasswordValidationRules } from "UIKit";

interface PasswordInputValidatorProps {
  id?: string;
  label?: string;
  placeholder?: string;
  control: Control<any>;
  password: string;
  "data-testid"?: string;
}

export const PasswordInputValidator: React.FC<PasswordInputValidatorProps> = ({
  label,
  placeholder,
  id = "password",
  password,
  control,
  "data-testid": dataTestId = "create-password-input",
}) => {
  return (
    <div>
      <PasswordInput
        control={control}
        label={label}
        placeholder={placeholder}
        data-testid={dataTestId}
        autoFocus
        id={id}
      />
      <PasswordValidationRules password={password} />
    </div>
  );
};

export default PasswordInputValidator;
