import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "UIKit";
import { AlphabeticalIcon, DragIcon, CalendarIcon, HeartIcon } from "Assets";

export type ListOrderByOption = "dragAndDrop" | "date" | "alphabetical" | "recent" | "favourites";

export interface ListOrderByProps {
  options: ListOrderByOption[];
  selectedOption: ListOrderByOption;
  handleClick: (value: ListOrderByOption) => void;
}

export const optionLabels: Record<ListOrderByOption, string> = {
  alphabetical: "sort_label_alphabetical",
  date: "sort_label_date",
  dragAndDrop: "sort_label_drag",
  recent: "sort_label_recent",
  favourites: "sort_label_favourites",
};

const optionIcons: Record<ListOrderByOption, React.ReactNode> = {
  alphabetical: <AlphabeticalIcon className="mr-0 sm:mr-2" width={16} height={16} />,
  date: <CalendarIcon className="mr-0 sm:mr-2" width={16} height={16} />,
  dragAndDrop: <DragIcon className="mr-0 sm:mr-2" width={16} height={16} />,
  recent: <DragIcon className="mr-0 sm:mr-2" width={16} height={16} />,
  favourites: <HeartIcon className="mr-0 sm:mr-2" width={16} height={16} />,
};

// https://v2.tailwindcss.com/docs/optimizing-for-production#writing-purgeable-html
// can't use string interpolations (e.g: `grid-cols-${numberOfColumns}) due to purging`

const optionColumns: Record<number, string> = {
  "1": "grid-cols-1",
  "2": "grid-cols-2",
  "3": "grid-cols-3",
  "4": "grid-cols-4",
};

export const ListOrderBy = ({ selectedOption, options, handleClick }: ListOrderByProps) => {
  const { t } = useTranslation();

  const numberOfColumns = Math.min(4, options.length);
  const gridColumnsClass = optionColumns[numberOfColumns];

  return (
    <div className={`grid gap-4 ${gridColumnsClass}`}>
      {options.map((value) => {
        const Icon = optionIcons[value];

        return (
          <Button
            data-testid="pill-text"
            key={`sortby_${value}`}
            onClick={() => handleClick(value)}
            color={value === selectedOption ? "blue" : "grey"}
            variant={selectedOption === value ? "solid" : "outline"}
          >
            {Icon}
            <span className="hidden sm:inline">{t(optionLabels[value])}</span>
          </Button>
        );
      })}
    </div>
  );
};
