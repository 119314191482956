import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { userStore } from "@eolas-medical/core";

import { AddButton, ConfirmationModal, InnerPageWrapper, Modal, InformationModal } from "UIKit";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";
import {
  SupervisionStatus,
  SupervisionSummary,
  SupervisorRequestCreatedBy,
} from "modules/portfolio/types";
import SupervisionCard from "../../components/SupervisionCard/SupervisionCard";
import SupervisionList from "../../components/SupervisionList/SupervisionList";
import AssignSupervisorModal from "../../components/AssignSupervisorModal/AssignSupervisorModal";
import SupervisionPageTitle from "../../components/SupervisionPageTitle/SupervisionPageTitle";
import SendReminder from "../../components/SendReminder/SendReminder";

import useSupervisorsList from "modules/portfolio/data/useSupervisorsList";
import useSupervisionActions from "../../hooks/useSupervisionActions";

export const SupervisorsList = () => {
  const { t } = useTranslation();
  const { userID = "", userData } = userStore;
  const { supervisorsRequestList, supervisorsList, supervisorsListLoading } = useSupervisorsList(
    userID,
  );

  const {
    modalState,
    clickedItemValue,
    creatingRequest,
    resendingRequest,
    cancelingRequest,
    removingSupervision,
    onCloseModal,
    onClickAddSupervision,
    onCreateRequest,
    onClickCancel,
    onCancelRequest,
    onClickRemoveSupervision,
    onRemoveSupervision,
    onResendRequest,
    onClickResend,
  } = useSupervisionActions({ createdBy: SupervisorRequestCreatedBy.SUPERVISEE, ownerId: userID });

  const renderSupervisionRequest = useCallback(
    (supervision: SupervisionSummary) => {
      const { id, email, status, canRemind } = supervision;
      const commonProps = { id, email, status };

      if (status === SupervisionStatus.PENDING) {
        return (
          <SupervisionCard
            {...commonProps}
            onCancel={onClickCancel}
            sendReminderComponent={
              <SendReminder
                ownerId={userID}
                supervisorEmail={email}
                canRemind={canRemind}
                createdBy={SupervisorRequestCreatedBy.SUPERVISEE}
              />
            }
          />
        );
      }

      if (status === SupervisionStatus.DECLINED) {
        return (
          <SupervisionCard {...commonProps} onCancel={onClickCancel} onResend={onClickResend} />
        );
      }

      return <SupervisionCard {...commonProps} onRemove={onClickRemoveSupervision} />;
    },
    [userID, onClickCancel, onClickRemoveSupervision, onClickResend],
  );

  return (
    <InnerPageWrapper>
      <Modal open={modalState !== null} onClose={onCloseModal}>
        {modalState === "createSupervision" && (
          <AssignSupervisorModal
            onCancel={onCloseModal}
            onSubmit={onCreateRequest}
            isLoading={creatingRequest}
            ownerId={userID}
            userEmail={userData?.email || ""}
          />
        )}
        {modalState === "supervisionCreated" && (
          <InformationModal
            iconType="GreenTickIcon"
            title={t("portfolio_supervisor_created_title")}
            description={t("portfolio_supervisor_created_description")}
            doneLabel={t("portfolio_supervisor_created_done")}
            onDone={onCloseModal}
          />
        )}
        {modalState === "cancelRequest" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onCancelRequest}
            iconType="MajorIncidentIcon"
            title={t("portfolio_supervision_cancel_title")}
            description={t("portfolio_supervision_cancel_description")}
            acceptLabel={t("portfolio_supervision_cancel_accept")}
            declineLabel={t("portfolio_supervision_cancel_decline")}
            declineButtonProps={{ disabled: cancelingRequest }}
            acceptButtonProps={{ color: "blue", isLoading: cancelingRequest }}
          />
        )}
        {modalState === "removeSupervision" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onRemoveSupervision}
            iconType="MajorIncidentIcon"
            title={t("portfolio_supervisor_remove_title")}
            description={t("portfolio_supervisor_remove_description")}
            acceptLabel={t("portfolio_supervisor_remove_accept")}
            declineLabel={t("portfolio_supervisor_remove_decline")}
            declineButtonProps={{ disabled: removingSupervision }}
            acceptButtonProps={{ color: "red", isLoading: removingSupervision }}
          />
        )}
        {modalState === "resendRequest" && (
          <ConfirmationModal
            onDecline={onCloseModal}
            onAccept={onResendRequest}
            iconType="GreenTickIcon"
            title={t("portfolio_supervisee_resend_title")}
            description={clickedItemValue as string}
            acceptLabel={t("portfolio_supervisor_resend_accept")}
            declineLabel={t("portfolio_supervision_cancel_decline")}
            declineButtonProps={{ disabled: resendingRequest }}
            acceptButtonProps={{ color: "blue", isLoading: resendingRequest }}
          />
        )}
      </Modal>

      <SupervisionPageTitle
        title={t("portfolio_supervisors")}
        subtitle={t("portfolio_supervisors_subtitle")}
        toReplaceUrl={PortfolioConstants.SUPERVISORS_URL}
      />

      <AddButton onClick={onClickAddSupervision} data-testid="add-supervisor-button">
        {t("portfolio_supervisor_add")}
      </AddButton>

      <SupervisionList
        items={[supervisorsRequestList, supervisorsList]}
        renderSupervisionRequest={renderSupervisionRequest}
        isLoading={supervisorsListLoading}
        pendingRequestTitle={t("portoflio_supervisor_pending")}
        approvedRequestTitle={t("portfolio_supervisor_approved")}
        emptySupervisionText={t("portfolio_empty_supervisors")}
      />
    </InnerPageWrapper>
  );
};
