import { Assessor, EventStatus, EventType } from "modules/portfolio/types";
import { Button, Modal, Text } from "UIKit";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useReassignAssessor from "modules/portfolio/data/useReassignAssessor";
import AssignAssessorModal from "../../AssignAssessorModal/AssignAssessorModal";
import ReassignAssessorModal from "../../ReassignAssessorModal/ReassignAssessorModal";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";
import { appConfig } from "AppTypeConfig";

interface ReassignProps {
  eventId: string;
  eventStatus: EventStatus;
  eventType: EventType;
  assessor: Assessor;
  eventOwnerEmail: string;
  eventOwnerId: string;
}
const Reassign = ({
  eventId,
  eventOwnerId,
  eventStatus,
  eventType,
  assessor,
  eventOwnerEmail,
}: ReassignProps) => {
  const { t } = useTranslation();
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [showAssignAssesorModal, setShowAssignAssessorModal] = useState(false);
  const { reassigningAssessor, reassignAssessor } = useReassignAssessor(eventId);

  const handleReassignAssessor = useCallback(
    (email: string) => {
      reassignAssessor(
        {
          eventId,
          reassignBody: {
            userEmail: email,
            appType: appConfig.appType,
          },
        },
        {
          onSuccess: () => {
            trackEvent(AnalyticsEvents.REASSIGN_ASSESSOR, { eventType });
            setShowAssignAssessorModal(false);
          },
        },
      );
    },
    [reassignAssessor, eventId, eventType],
  );

  const handleShowReassignModal = useCallback(() => {
    setShowReassignModal(!showReassignModal);
    setShowAssignAssessorModal(true);
  }, [showReassignModal]);

  const handleCloseReassignModal = useCallback(() => {
    setShowReassignModal(false);
  }, []);

  const handleCloseReassignAssessorModal = useCallback(() => {
    setShowAssignAssessorModal(false);
  }, []);

  const renderReassignButton = () => {
    if (eventStatus !== EventStatus.PENDING_REVIEW && eventStatus !== EventStatus.DECLINED) {
      return null;
    }

    return (
      <Button
        iconLeft={"AddUserIcon"}
        onClick={() => setShowReassignModal(true)}
        isLoading={reassigningAssessor}
        variant="outline"
        size="md"
        color="black"
        className="border-grey-400 ml-2"
        data-testid="reassign-button"
      >
        <Text level={1} className="ml-2">
          {t("portfolio_reassign")}
        </Text>
      </Button>
    );
  };

  return (
    <>
      {renderReassignButton()}
      <Modal open={showReassignModal} onClose={handleCloseReassignModal}>
        <ReassignAssessorModal
          onAssign={handleShowReassignModal}
          onCancel={handleCloseReassignModal}
        />
      </Modal>
      <Modal open={showAssignAssesorModal} onClose={handleCloseReassignAssessorModal}>
        <AssignAssessorModal
          onCancel={handleCloseReassignAssessorModal}
          onSubmit={handleReassignAssessor}
          isLoading={reassigningAssessor}
          ownerId={eventOwnerId}
          currentAssessor={assessor}
          isReassigning
          eventStatus={eventStatus}
          eventOwnerEmail={eventOwnerEmail}
        />
      </Modal>
    </>
  );
};

export default Reassign;
