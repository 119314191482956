export type MyFilesKey = (params?: any) => any[];
export interface MyFilesKeys {
  [key: string]: MyFilesKey;
}

const myFilesKeys: MyFilesKeys = {
  all: () => ["my-files"],
  allFilesList: () => [...myFilesKeys.all(), "allFilesList"],
  allSections: () => ["sections"],
  sectionLists: () => [...myFilesKeys.allSections(), "list"],
  sectionList: (filters: any) => [...myFilesKeys.sectionLists(), { filters }],
  filesBySectionList: () => [...myFilesKeys.all(), "filesBySectionList"],
};

export default myFilesKeys;
