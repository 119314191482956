import React from "react";
import { userStore } from "@eolas-medical/core";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";

import { SetTokensFn } from "./types";
import { loginTokenService } from "API/LoginTokenService";

export const useCreateLoginToken = (setLoginTokens: SetTokensFn) => {
  const appID = userStore.appUserData.appID!;
  const { isLoading, error, isSuccessful, setRequestStatus } = useRequestStatus();

  const createToken = React.useCallback(async () => {
    setRequestStatus({ status: "pending", error: "" });
    try {
      const { data } = await loginTokenService.createToken(appID, "Demo", "Account");

      setLoginTokens((t) => [...t, data.token]);

      setRequestStatus({ status: "success", error: "" });
    } catch (err: any) {
      errorStore.captureError({ error: err.message, source: "user" });
      setRequestStatus({ status: "error", error: err.message });
    }
  }, [appID, setLoginTokens, setRequestStatus]);

  return {
    error,
    isLoading,
    isSuccessful,
    createToken,
  };
};
