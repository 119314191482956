import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { SupervisorRequestCreatedBy } from "modules/portfolio/types";
import { useNotifications } from "Components/Notifications";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";
import { createErrorNotification } from "Pages/MeTab/pages/Portfolio/helpers";
import { PortfolioErrorMessages } from "Pages/MeTab/pages/Portfolio/errors";
import useRemoveSupervision from "modules/portfolio/data/useRemoveSupervision";
import useApproveSupervisionRequest from "modules/portfolio/data/useApproveSupervisionRequest";
import useCancelSupervisionRequest from "modules/portfolio/data/useCancelSupervisionRequest";
import useCreateSupervisionRequest from "modules/portfolio/data/useCreateSupervisionRequest";
import useDeclineSupervisionRequest from "modules/portfolio/data/useDeclineSupervisionRequest";
import { appConfig } from "AppTypeConfig";

type SupervisionModalState =
  | "createSupervision"
  | "supervisionCreated"
  | "approveRequest"
  | "cancelRequest"
  | "declineRequest"
  | "removeSupervision"
  | "resendRequest"
  | null;
interface UseSupervisionActionsProps {
  ownerId: string;
  createdBy: SupervisorRequestCreatedBy;
  userEmail?: string | null | undefined;
}
const useSupervisionActions = ({ createdBy, ownerId, userEmail }: UseSupervisionActionsProps) => {
  const [modalState, setModalState] = useState<SupervisionModalState>(null);
  const [clickedItemValue, setClickedItemValue] = useState<string | null>(null);
  const { push, location } = useHistory();
  const { showNotification } = useNotifications();

  const { createRequest, creatingRequest } = useCreateSupervisionRequest(createdBy);
  const { cancelRequest, cancelingRequest } = useCancelSupervisionRequest(createdBy);
  const { removeSupervision, removingSupervision } = useRemoveSupervision(createdBy);
  const { approveRequest, approvingRequest } = useApproveSupervisionRequest();
  const { declineRequest, decliningRequest } = useDeclineSupervisionRequest();

  const isCreatedBySupervisee = createdBy === SupervisorRequestCreatedBy.SUPERVISEE;

  /**
   * It closes the opened modal
   */
  const handleCloseModal = useCallback(() => {
    setModalState(null);
  }, []);

  /**
   * Opens the AssignSupervisor modal
   */
  const handleClickAddSupervision = useCallback(() => {
    setModalState("createSupervision");
  }, []);

  /**
   * Creates a new supervision request
   */
  const handleCreateRequest = useCallback(
    (supervisorEmail: string) => {
      createRequest(
        { appType: appConfig.appType, supervisorEmail, createdBy, ownerId },
        {
          onSuccess: () => {
            if (isCreatedBySupervisee) {
              trackEvent(AnalyticsEvents.SUPERVISOR_ADDED_BY_SUPERVISEE);
            }
            setModalState("supervisionCreated");
          },
          onError: () => {
            showNotification(createErrorNotification(PortfolioErrorMessages.ADD_SUPERVISOR_ERROR));
            setModalState(null);
          },
        },
      );
    },
    [ownerId, createdBy, isCreatedBySupervisee, createRequest, showNotification],
  );

  /**
   * Opens the ConfirmCancel modal and stores the related request id
   */
  const handleClickCancel = useCallback((supervisionRequestId: string) => {
    setModalState("cancelRequest");
    setClickedItemValue(supervisionRequestId);
  }, []);

  /**
   * Opens the approveRequest modal and stores the related request id
   */
  const handleClickApprove = useCallback((supervisionRequestId: string) => {
    setModalState("approveRequest");
    setClickedItemValue(supervisionRequestId);
  }, []);

  /**
   * Opens the declineRequest modal and stores the related request id
   */
  const handleClickDecline = useCallback((supervisionRequestId: string) => {
    setModalState("declineRequest");
    setClickedItemValue(supervisionRequestId);
  }, []);

  /**
   * Opens the resendRequest modal and stores the related request email
   */
  const handleClickResend = useCallback((supervisionEmail: string) => {
    setModalState("resendRequest");
    setClickedItemValue(supervisionEmail);
  }, []);

  /**
   * Cancels a supervision request for previously saved supervision request id
   */
  const handleCancelRequest = useCallback(() => {
    if (clickedItemValue && modalState === "cancelRequest") {
      cancelRequest(clickedItemValue, {
        onSuccess: () => {
          if (isCreatedBySupervisee) {
            trackEvent(AnalyticsEvents.SUPERVISEE_CANCELLED_REQUEST);
          }
          handleCloseModal();
        },
        onError: () => {
          showNotification(
            createErrorNotification(PortfolioErrorMessages.CANCEL_SUPERVISION_ERROR),
          );
          setModalState(null);
        },
      });
    }
  }, [
    cancelRequest,
    handleCloseModal,
    showNotification,
    modalState,
    clickedItemValue,
    isCreatedBySupervisee,
  ]);

  /**
   * Approve a supervision request
   */
  const handleApproveRequest = useCallback(() => {
    if (clickedItemValue && modalState === "approveRequest") {
      approveRequest(
        {
          approvingUserId: ownerId,
          approvingUserEmail: userEmail,
          supervisionRequestId: clickedItemValue,
        },
        {
          onSuccess: () => {
            if (!isCreatedBySupervisee) {
              trackEvent(AnalyticsEvents.SUPERVISOR_APPROVED_REQUEST);
            }
            handleCloseModal();
          },
          onError: () => {
            showNotification(
              createErrorNotification(PortfolioErrorMessages.APPROVE_SUPERVISION_ERROR),
            );
            setModalState(null);
          },
        },
      );
    }
  }, [
    handleCloseModal,
    showNotification,
    isCreatedBySupervisee,
    modalState,
    clickedItemValue,
    userEmail,
    approveRequest,
    ownerId,
  ]);

  /**
   * Decline a supervision request
   */
  const handleDeclineRequest = useCallback(() => {
    if (clickedItemValue && modalState === "declineRequest") {
      declineRequest(
        {
          decliningUserId: ownerId,
          decliningUserEmail: userEmail,
          supervisionRequestId: clickedItemValue,
        },
        {
          onSuccess: () => {
            if (!isCreatedBySupervisee) {
              trackEvent(AnalyticsEvents.SUPERVISOR_DENIED_REQUEST);
            }
            handleCloseModal();
          },
          onError: () => {
            showNotification(
              createErrorNotification(PortfolioErrorMessages.DECLINE_SUPERVISION_ERROR),
            );
            setModalState(null);
          },
        },
      );
    }
  }, [
    handleCloseModal,
    showNotification,
    modalState,
    clickedItemValue,
    userEmail,
    isCreatedBySupervisee,
    declineRequest,
    ownerId,
  ]);

  const handleResendRequest = useCallback(() => {
    if (clickedItemValue) {
      createRequest(
        {
          appType: "EOLAS",
          supervisorEmail: clickedItemValue,
          createdBy,
          ownerId,
        },
        {
          onSuccess: () => {
            if (isCreatedBySupervisee) {
              trackEvent(AnalyticsEvents.SUPERVISEE_RESENT_INVITATION);
            }
            setModalState("supervisionCreated");
          },
          onError: () => {
            showNotification(
              createErrorNotification(PortfolioErrorMessages.RESENDING_REQUEST_ERROR),
            );
            setModalState(null);
          },
        },
      );
    }
  }, [
    ownerId,
    createdBy,
    createRequest,
    showNotification,
    isCreatedBySupervisee,
    clickedItemValue,
  ]);

  /**
   * Opens the ConfirmRemove modal and stores the related request id
   */
  const handleClickRemove = useCallback((supervisionId: string) => {
    setModalState("removeSupervision");
    setClickedItemValue(supervisionId);
  }, []);

  /**
   * Cancels a supervision request for previously saved supervision request id
   */
  const handleRemoveSupervision = useCallback(() => {
    if (clickedItemValue && modalState === "removeSupervision") {
      removeSupervision(clickedItemValue, {
        onSuccess: () => {
          isCreatedBySupervisee
            ? trackEvent(AnalyticsEvents.SUPERVISOR_REMOVED_BY_SUPERVISEE)
            : trackEvent(AnalyticsEvents.SUPERVISOR_REMOVED_THEMSELVES);

          handleCloseModal();
        },
        onError: () => {
          showNotification(
            createErrorNotification(PortfolioErrorMessages.REMOVE_SUPERVISION_ERROR),
          );
          setModalState(null);
        },
      });
    }
  }, [
    removeSupervision,
    handleCloseModal,
    showNotification,
    modalState,
    clickedItemValue,
    isCreatedBySupervisee,
  ]);

  /**
   * Redirects the user to the events of the supervisee
   */
  const handleClickSupervision = useCallback(
    (superviseeId: string, superviseeName: string) => {
      trackEvent(AnalyticsEvents.SUPERVISOR_OPENED_SUPERVISEE_PORTFOLIO);
      push(`${location.pathname}/${superviseeId}/${superviseeName}`);
    },
    [push, location],
  );

  return {
    modalState,
    clickedItemValue,
    creatingRequest,
    cancelingRequest,
    resendingRequest: creatingRequest,
    removingSupervision,
    approvingRequest,
    decliningRequest,
    onCloseModal: handleCloseModal,
    onClickAddSupervision: handleClickAddSupervision,
    onCreateRequest: handleCreateRequest,
    onClickCancel: handleClickCancel,
    onCancelRequest: handleCancelRequest,
    onClickApprove: handleClickApprove,
    onClickDecline: handleClickDecline,
    onClickResend: handleClickResend,
    onApproveRequest: handleApproveRequest,
    onResendRequest: handleResendRequest,
    onDeclineRequest: handleDeclineRequest,
    onClickRemoveSupervision: handleClickRemove,
    onRemoveSupervision: handleRemoveSupervision,
    onClickSupervision: handleClickSupervision,
  };
};

export default useSupervisionActions;
