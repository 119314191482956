import useFeedbackSummary from "modules/portfolio/data/useFeedbackSummary";
import { InnerModalWrapper, ModalBody, Text, ModalHeader, Title, Loader, Button } from "UIKit";
import { useMemo } from "react";
import { FeedbackAggregatedValue, FormElementType } from "modules/portfolio/types";
import { CommentBubbleIcon, FeedbackIcon } from "Assets";
import { useTranslation } from "react-i18next";
import { MAX_FEEDBACK_VALUE } from "Pages/MeTab/pages/Portfolio/constants";

interface FeedbackByType {
  [key: string]: FeedbackAggregatedValue[];
}

export interface ViewFeedbackModalProps {
  eventId: string;
  onClose(e: React.MouseEvent<HTMLButtonElement>): void;
}

const ViewFeedbackModal = ({ eventId, onClose }: ViewFeedbackModalProps) => {
  const { t } = useTranslation();
  const { feedbackSummary, feedbackSummaryLoading } = useFeedbackSummary(eventId);

  const feedbackByType = useMemo(() => {
    const initialState: FeedbackByType = {
      [FormElementType.STRING]: [],
      [FormElementType.NUMERIC]: [],
      [FormElementType.BOOLEAN]: [],
      [FormElementType.DATE]: [],
    };
    return feedbackSummary.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.type]: [...prev[curr.type], curr],
      };
    }, initialState);
  }, [feedbackSummary]);

  const hasReceivedFeedback = useMemo(() => {
    const feedbackTypes = Object.keys(feedbackByType);

    return feedbackTypes.some((type) => {
      switch (type) {
        case FormElementType.STRING:
        case FormElementType.NUMERIC:
          return feedbackByType[type].some(({ value }) => value.length > 0);
        case FormElementType.BOOLEAN:
        case FormElementType.DATE:
          return feedbackByType[type].length > 0;

        default:
          // default case, no feedback found for this type
          return false;
      }
    });
  }, [feedbackByType]);

  const additionalComments = feedbackByType[FormElementType.STRING][0]?.value;

  const NoFeedbackModal = () => {
    return (
      <InnerModalWrapper>
        <ModalBody>
          {feedbackSummaryLoading && <Loader />}
          {!feedbackSummaryLoading && (
            <div data-testid="view-no-feedback-modal-body" className="flex flex-col items-center">
              <FeedbackIcon />
              <Title level={4}> {t("portfolio_view_no_feedback_modal_title")}</Title>
              <Text level={2} className="text-center text-sm font-bold text-grey-700 mt-3 mb-6">
                {t("portfolio_view_no_feedback_modal_subtitle")}
              </Text>
              <Button
                data-testid="close-no-feedback-modal-button"
                className="w-full rounded-xl"
                onClick={onClose}
              >
                {t("portfolio_view_no_feedback_modal_button")}
              </Button>
            </div>
          )}
        </ModalBody>
      </InnerModalWrapper>
    );
  };

  return hasReceivedFeedback ? (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <Title level={5}>{t("portfolio_view_feedback_modal_title")}</Title>
      </ModalHeader>

      <ModalBody>
        {feedbackSummaryLoading && <Loader />}
        {!feedbackSummaryLoading && (
          <div data-testid="view-feedback-modal-body">
            <Title level={7}>{t("portfolio_view_feedback_modal_main_scores")}</Title>
            <div className="divide-y divide-grey-300 mb-10">
              {feedbackByType[FormElementType.NUMERIC].map((value) => (
                <div key={value.fieldName} className="flex flex-1 justify-between py-3">
                  <Text level={1}>{value?.componentConfig.title}</Text>
                  <Text level={1} className="text-blue-500">
                    {value?.value[0]} / {MAX_FEEDBACK_VALUE}
                  </Text>
                </div>
              ))}
            </div>

            <Title level={7}>{t("portfolio_view_feedback_modal_comments")}</Title>
            {additionalComments.map((comment, i) => (
              <div key={`comment-${i}`} className="flex flex-1 py-3">
                <div>
                  <CommentBubbleIcon />
                </div>
                <Text level={1} className="ml-2 text-grey-700">
                  {`"${comment}"`}
                </Text>
              </div>
            ))}
          </div>
        )}
      </ModalBody>
    </InnerModalWrapper>
  ) : (
    <NoFeedbackModal />
  );
};

export default ViewFeedbackModal;
