import { AppUser } from "API";
import { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { InnerModalWrapper, ModalHeader, Title, ModalBody } from "UIKit";

export interface UserBreakdownProps {
  users: Array<AppUser>;
}

export const UserBreakdownModal: React.FC<UserBreakdownProps> = ({ users }) => {
  const { t } = useTranslation();

  const admins = useMemo(() => {
    return users.filter((user) => user.accessLevel === "admin").length;
  }, [users]);

  const readOnly = useMemo(() => {
    return users.filter((user) => user.accessLevel === "user").length;
  }, [users]);

  const data = {
    labels: ["Activated", "Admin"],
    datasets: [
      {
        data: [readOnly, admins],
        backgroundColor: ["#48db69", "#FFDF00"],
      },
    ],
  };

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}> {t("manageUsers_usersBreakdown_title")}</Title>
      </ModalHeader>

      <ModalBody className="align-center">
        <div className="lg:w-96 self-center">
          <Doughnut data={data} type="line" />
        </div>
        <div className="flex flex-col items-center mt-12">
          <span className="font-semibold text-lg">{users.length}</span>
          <span className="text-sm">{t("manageUsers_usersBreakdown_totalUsers")}</span>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};
