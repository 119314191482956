import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";

import { AnalyticsEvents } from "@eolas-medical/core";

import { trackEvent } from "API/Analytics";
import { ConfirmationCodeIcon } from "Assets/Icons";
import { FormElement, Input, Button, Title, FormError, Modal } from "UIKit";

import { useResetPassword } from "../hooks";
import { PasswordInput } from "./PasswordInput";
import { PasswordInputValidator } from "./PasswordInputValidator";

import "../styles.css";
import { resetPasswordSendCode } from "../auth.actions";
import useAssistanceVerificationCode from "modules/authentication/data/useAssistanceVerificationCode";
import { VerificationCodeModalState } from "../signup/types";
import RequestAssistanceModal from "./RequestAssistanceModal";
import AssistanceSentModal from "./AssistanceSentModal";

interface PasswordResetProps {
  userEmail: any;
  onFormSubmit: any;
  onResendCode: any;
}

export const PasswordReset: React.FC<PasswordResetProps> = ({
  userEmail,
  onFormSubmit,
  onResendCode,
}) => {
  const { t } = useTranslation();
  const { control, isLoading, error, formMethods, onSubmit } = useResetPassword(
    userEmail,
    onFormSubmit,
  );

  const [modalState, setModalState] = useState<VerificationCodeModalState>(null);

  const { requestAssistanceVerificationCode } = useAssistanceVerificationCode();

  const handleCloseModal = useCallback(() => {
    setModalState(null);
  }, []);

  const handleAssistanceVerificationCode = useCallback(() => {
    requestAssistanceVerificationCode(
      { queryParams: { email: userEmail, origin: "FORGOT_PASSWORD" } },
      {
        onSuccess: () => {
          setModalState("assistanceSent");
        },
      },
    );
  }, [requestAssistanceVerificationCode, userEmail]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={onSubmit}
        className="flex flex-col justify-center flex-2 p-4 lg:p-16 space-y-4"
      >
        <Title level={6}>{t("createAccount_verifyText")}</Title>

        <FormElement
          required
          control={control}
          id="verificationCode"
          label={t("passwordReset_verification_code_label")}
        >
          {(field) => (
            <Input
              {...field}
              type="number"
              autoComplete="off"
              iconLeft={<ConfirmationCodeIcon />}
              data-testid="forgottenPassword-codeInput"
              placeholder={t("passwordReset_verification_code_placeholder")}
            />
          )}
        </FormElement>
        <div className="flex justify-center">
          <Button
            color="grey"
            type="button"
            variant="link"
            onClick={() => {
              trackEvent(AnalyticsEvents.RESEND_VERIFICATION_CODE);
              resetPasswordSendCode(userEmail);
              onResendCode();
            }}
          >
            {t("forgotPassword_resendCode")}
          </Button>

          <Button
            color="grey"
            type="button"
            variant="link"
            onClick={() => setModalState("requestAssistance")}
          >
            {t("user_not_received_verification_code")}
          </Button>
        </div>

        <Modal open={modalState !== null} onClose={handleCloseModal}>
          {modalState === "requestAssistance" ? (
            <RequestAssistanceModal onRequestAssistance={handleAssistanceVerificationCode} />
          ) : null}
          {modalState === "assistanceSent" ? (
            <AssistanceSentModal onDone={handleCloseModal} />
          ) : null}
        </Modal>

        <PasswordInputValidator id="resetPassword" label={t("passwordReset_password_label")} />

        <PasswordInput
          id="resetConfirmPassword"
          testID="confirm-password-input"
          label={t("passwordReset_confirm_password_label")}
        />

        <Button
          type="submit"
          className="self-end"
          isLoading={isLoading}
          data-testid="forgotPassword-submit"
        >
          {t("general_update")}
        </Button>

        {error && <FormError error={error} />}
      </form>
    </FormProvider>
  );
};
