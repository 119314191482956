import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { DeactivateMonoIcon, AttentionIcon } from "Assets";
import { Text, Input, Button, PageTitle, NavButton, FormElement, InnerPageWrapper } from "UIKit";

import { useMedusa } from "../data/useMedusa";
import { DisableMedusaModal, MedusaStatusView } from "../components";

export const ActivateMedusaPage: React.FC<RouteComponentProps> = observer(({ history }) => {
  const { t } = useTranslation();
  const [showDisableModal, toggleDisableModal] = useState(false);

  const {
    isActive,
    isLoading,
    status,
    requestStatus,
    control,
    error,
    cached,
    onSubmit,
    onDisable,
  } = useMedusa();

  const cachingState = useMemo(() => {
    if (status === "enabled") {
      return cached ? (
        <Text level={2} className="text-left">
          {t("contentManagement_medusa_caching_complete")}
        </Text>
      ) : (
        <Text level={2} className="text-left">
          {t("contentManagement_medusa_caching")}
        </Text>
      );
    }
    return null;
  }, [status, cached, t]);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={t("contentManagement_medusa_title")} />
        <NavButton onClick={history.goBack} />
        <Text level={1} className="text-center capitalize">
          <MedusaStatusView status={status} />
        </Text>
        {status !== "enabled" && (
          <Text level={1} className="text-center">
            {t("contentManagement_medusa_body1")}
          </Text>
        )}

        {cachingState}
        <form onSubmit={onSubmit}>
          <FormElement id="url" control={control}>
            {(field) => (
              <Input
                {...field}
                className="input pl-4 h-12 sm:h-14 box-content"
                style={{ borderRadius: "3rem" }}
                placeholder={t("contentManagement_medusa_placeholder")}
                rightSection={
                  <Button
                    type="submit"
                    variant="solid"
                    style={{ borderRadius: "3rem" }}
                    isLoading={isLoading}
                    disabled={
                      status === "pending" || status === "processing" || requestStatus === "pending"
                    }
                    className="h-10 sm:h-12 px-8 mr-1"
                  >
                    {status === "disabled"
                      ? t("contentManagement_medusa_add")
                      : t("contentManagement_medusa_update")}
                  </Button>
                }
              />
            )}
          </FormElement>
          {error && (
            <p className="text-red text-base text-center font-bold mb-6 line-clamp-1">
              <AttentionIcon className="h-4 w-4 align-middle inline mr-2" />
              <span className="align-middle">{error}</span>
            </p>
          )}
          <div className="flex justify-center">
            <Button
              type="button"
              disabled={!isActive}
              color="grey"
              variant="outline"
              className="h-10 sm:h-12 px-8"
              onClick={() => toggleDisableModal(true)}
            >
              <DeactivateMonoIcon className="h-6 w-6 inline" />
              &nbsp;&nbsp;
              {t("contentManagement_medusa_remove")}
            </Button>
          </div>
        </form>
      </InnerPageWrapper>
      <DisableMedusaModal
        open={showDisableModal}
        handleClose={() => toggleDisableModal(false)}
        handleDisable={onDisable}
      />
    </>
  );
});
