import { Text, Title } from "UIKit";
import { TeamMember } from "../../../../types";
import { generateS3PublicImageURL } from "Utilities";
import { avatar } from "Assets";

interface TeamMemberModalProps {
  teamMember: TeamMember;
}

const TeamMemberModal = ({ teamMember }: TeamMemberModalProps) => {
  return (
    <div
      key={teamMember.id}
      className={`flex flex-col justify-center items-center bg-white rounded-lg 
      border border-grey-300 px-12 py-8 w-120`}
      data-testid="team-member-modal"
    >
      <img
        alt=""
        className="mt-4 rounded-full object-cover h-28 w-28"
        src={teamMember.photoUrl ? generateS3PublicImageURL(teamMember.photoUrl) : avatar}
      />

      <div className="flex flex-col text-center space-y-2 mt-6">
        <Title level={6}>{teamMember?.name}</Title>
        <Text level={2} className="text-grey-600">
          {teamMember?.role}
        </Text>
      </div>

      <Text level={2} className="pt-6 text-justify self-start">
        {teamMember.bio}
      </Text>
    </div>
  );
};

export default TeamMemberModal;
