import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { sectionStore, userStore } from "@eolas-medical/core";

export interface IUseBootIntercomProps {
  isAuthenticateRoute: boolean;
  isSelectSpaceRoute: boolean;
  isMainRoute: boolean;
  newUser: boolean;
  isAdmin: boolean;
}

export const useBootIntercom = ({
  isAuthenticateRoute,
  isSelectSpaceRoute,
  isMainRoute,
  newUser,
  isAdmin,
}: IUseBootIntercomProps) => {
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    if (!userStore.userData) return;

    if (isAuthenticateRoute) {
      shutdown();
      boot();
    } else {
      boot({
        name: userStore.fullName,
        email: userStore.userData.email as string,
        userId: userStore.userID,
        customAttributes: {
          departmentId: isSelectSpaceRoute ? "no_department_id" : userStore.appUserData.appID,
          organisationName: isSelectSpaceRoute
            ? "not_organisation_name"
            : sectionStore.organisationName,
          organisationId: isSelectSpaceRoute ? "no_organisation_id" : sectionStore.organisationID,
          newUser: newUser || false,
          isAdmin,
        },
      });
    }
  }, [boot, isAuthenticateRoute, isMainRoute, isSelectSpaceRoute, shutdown, isAdmin, newUser]);
};
