import React from "react";

import { NationalGuidelineFileTile } from "UIKit/FileTile/NationalGuidelineFileTile";
import { EolasFile } from "@eolas-medical/core";
import { RegularFileTile } from "UIKit";
import { CommunitySectionType } from "../../pages/ClinicalCalculators/types";

export interface KnowledgeSectionItemProps {
  eolasFile: EolasFile;
  onSelectFile?(file: EolasFile): void;
  sectionType: string;
}

const KnowledgeSectionItem: React.FC<KnowledgeSectionItemProps> = ({
  eolasFile,
  onSelectFile,
  sectionType,
}) => {
  if (sectionType === CommunitySectionType.NATIONAL_RESOURCE) {
    return (
      <NationalGuidelineFileTile
        onSelectFile={onSelectFile}
        isAdmin={false}
        eolasFile={eolasFile}
      />
    );
  }
  return (
    <RegularFileTile
      className="h-20"
      onSelectFile={onSelectFile}
      isAdmin={false}
      eolasFile={eolasFile}
    />
  );
};

export default KnowledgeSectionItem;
