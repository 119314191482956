import { PersonalDataIcon } from "Assets";
import { useEnterKeySubmit } from "Hooks/useEnterKeySubmit/useEnterKeySubmit";
import { FormElement, Input } from "UIKit";
import { Control, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CreateAccountModalProps {
  errors: FieldErrors<{ firstName: string; surname: string }>;
  control: Control<any>;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}
const CreateAccountModal = ({ control, errors, onSubmit }: CreateAccountModalProps) => {
  const { t } = useTranslation();

  useEnterKeySubmit(onSubmit);

  return (
    <div data-testid="create-account-modal">
      <form onSubmit={onSubmit} className="flex flex-col space-y-4">
        <FormElement
          control={control}
          id="firstName"
          labelClassName="font-semibold"
          errorMessage={errors?.firstName?.message}
        >
          {(field) => (
            <Input
              {...field}
              autoFocus
              iconLeft={<PersonalDataIcon />}
              data-testid="create-account-firstName"
              placeholder={t("createAccount_yourName")}
            />
          )}
        </FormElement>

        <FormElement
          control={control}
          id="surname"
          labelClassName="font-semibold"
          errorMessage={errors?.surname?.message}
        >
          {(field) => (
            <Input
              {...field}
              iconLeft={<PersonalDataIcon />}
              data-testid="create-account-surname"
              placeholder={t("createAccount_yourSurname")}
            />
          )}
        </FormElement>
      </form>
    </div>
  );
};

export default CreateAccountModal;
