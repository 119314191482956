import { useHistory } from "react-router-dom";

import { MajorIncidentIcon } from "Assets";
import { Button, ModalBody, Title, Text, InnerModalWrapper } from "UIKit";

interface InvitationFailureProps {
  error?: string;
}

export const InvitationFailure: React.FC<InvitationFailureProps> = ({
  error = "Something was wrong with processing this invitation...",
}) => {
  const { push } = useHistory();

  return (
    <InnerModalWrapper>
      <ModalBody className="items-center">
        <MajorIncidentIcon className="h-20 w-20 mb-8" />
        <Title level={5}>Invitation Error</Title>

        <Text level={1} className="text-red">
          {error}
        </Text>

        <Button onClick={() => push("/")} className="w-full sm:w-1/2 mt-8">
          To Login
        </Button>
      </ModalBody>
    </InnerModalWrapper>
  );
};
