import { CreateFileInput } from "modules/myFiles/types";
import { useCallback, useState } from "react";
import { EolasFilePicker, SuccessModal, Text, Title } from "UIKit";
import { getFileExtension, getFileName } from "Utilities/fileHelpers";
import { EolasFileType } from "Utilities/types";
import { InnerModalWrapper, ModalHeader, ModalBody } from "../components";
import { BlobForm } from "./components/BlobForm";
import { FileTypeSelector } from "./components/FileTypeSelector";
import { LinkForm } from "./components/LinkForm";
import { BlobTypes } from "modules/generic.types";

interface FileModalState {
  step: number;
  selectedFileType: EolasFileType;
  blob?: File;
}

interface FileModalProps {
  onCloseModal(): void;
  onSubmit(args: CreateFileInput & { file?: File }): void;
  onBlobChange(file: File): void;
  onBack?: () => void;
  isLoading: boolean;
  existingFileNames: string[];
  allowedFileTypes?: EolasFileType[];
  title?: string;
  progress?: number;
  errorMessage?: string;
  isSuccessful?: boolean;
  defaultFileType?: EolasFileType;
  pickerFormLabel?: string;
  pickerChangeLabel?: string;
  pickerUploadLabel?: string;
  pickerDescriptionText?: string;
  pickerSubDescriptionText?: string;
  showDisclaimerText?: boolean;
  disclaimerText?: string;
}

export const AddFileModal = ({
  isLoading,
  isSuccessful,
  existingFileNames,
  progress = 0,
  allowedFileTypes = ["blob", "link"],
  defaultFileType = "blob",
  errorMessage = "",
  title = "",
  pickerChangeLabel = "",
  pickerFormLabel = "",
  pickerDescriptionText = "",
  pickerUploadLabel = "",
  pickerSubDescriptionText = "",
  onBlobChange,
  onCloseModal,
  onSubmit,
  onBack,
  showDisclaimerText,
  disclaimerText,
}: FileModalProps) => {
  const [state, setState] = useState<FileModalState>({
    step: 0,
    selectedFileType: defaultFileType,
  });

  const handleNext = useCallback(() => {
    if (state.step === 1) return;

    setState((prevState) => ({ ...prevState, step: prevState.step + 1 }));
  }, [state.step]);

  const handleBack = useCallback(() => {
    if (state.step === 0) return onCloseModal();

    setState((prevState) => ({ ...prevState, step: prevState.step - 1 }));
    if (onBack) onBack();
  }, [onBack, onCloseModal, state.step]);

  const handleTypeSelect = useCallback((type) => {
    setState((prevState) =>
      prevState.selectedFileType !== type
        ? { ...prevState, selectedFileType: type, blob: undefined }
        : prevState,
    );
  }, []);

  const handleBlobChange = useCallback(
    (file: File) => {
      setState((prevState) => ({ ...prevState, blob: file }));
      onBlobChange(file);
    },
    [onBlobChange],
  );

  const handleSubmitBlob = useCallback(
    (name: string) => {
      if (state.blob) {
        const ext = getFileExtension(state.blob) as BlobTypes;
        onSubmit({
          name,
          blobType: ext,
          size: state.blob.size,
          type: "BLOB",
          file: state.blob,
        });
      }
    },
    [state.blob, onSubmit],
  );

  const handleSubmitLink = useCallback(
    ({ name, url }) => {
      if (state.selectedFileType === "link") {
        onSubmit({ name, url, type: "LINK" });
      }
    },
    [state.selectedFileType, onSubmit],
  );

  if (isSuccessful) {
    return <SuccessModal onComplete={onCloseModal} text={`general_add_file_modal_success`} />;
  }

  return (
    <InnerModalWrapper data-testid="add-file-modal">
      <ModalHeader>
        <Title level={5}>{title}</Title>
      </ModalHeader>
      <ModalBody>
        {state.step === 0 && (
          <FileTypeSelector
            onSelect={handleTypeSelect}
            allowedTypes={allowedFileTypes}
            selectedType={state.selectedFileType}
            isDisabled={isLoading}
            className="mb-4"
          />
        )}

        {showDisclaimerText ? (
          <Text level={1} className="text-red-500 text-center">
            {disclaimerText}
          </Text>
        ) : null}

        {state.step === 0 && state.selectedFileType === "blob" && (
          <EolasFilePicker
            isDisabled={isLoading}
            onChange={handleBlobChange}
            value={state.blob ? `${state.blob.name}` : ""}
            fileType={state.selectedFileType}
            formLabel={pickerFormLabel}
            uploadLabel={pickerUploadLabel}
            changeLabel={pickerChangeLabel}
            descriptionText={pickerDescriptionText}
            subDescriptionText={pickerSubDescriptionText}
          />
        )}
        {state.selectedFileType === "link" && (
          <LinkForm
            onSubmit={handleSubmitLink}
            onBack={onCloseModal}
            isLoading={isLoading}
            existingFileNames={existingFileNames}
            errorMessage={errorMessage}
          />
        )}
        {state.selectedFileType === "blob" && (
          <BlobForm
            initialName={state.blob && getFileName(state.blob)}
            onSubmit={handleSubmitBlob}
            onBack={handleBack}
            onNext={handleNext}
            currentStep={state.step}
            isLoading={isLoading}
            existingFileNames={existingFileNames}
            errorMessage={errorMessage}
            progress={progress}
          />
        )}
      </ModalBody>
    </InnerModalWrapper>
  );
};
