import { useTranslation } from "react-i18next";

import { Button, CircleIcon, Text } from "UIKit";

import { User } from "API";
import { UserProfileIcon } from "Assets";
import { UpdateAccessLevelParams } from "./useAdminEntry";

interface AdminEntryProps {
  user: User;
  isLoading?: boolean;
  onUpdateUser: (params: UpdateAccessLevelParams) => void;
  onMakeLimitedAccess: () => void;
}

export const MakeAdminEntry: React.FC<AdminEntryProps> = ({
  user,
  isLoading,
  onMakeLimitedAccess,
  onUpdateUser,
}) => {
  const { t } = useTranslation();
  const { family_name = "", given_name = "", email = "" } = user!;

  return (
    <div className="flex flex-col lg:flex-row items-center p-2 border border-grey-300 rounded-2xl">
      <CircleIcon
        className="mr-2"
        width="w-10"
        height="h-10"
        icon={<UserProfileIcon />}
        backgroundColor="grey"
      />
      <div className="flex flex-1 flex-col items-center lg:items-start lg:mr-auto my-2">
        <Text level={1} className="text-lg line-clamp-1">{`${given_name} ${family_name}`}</Text>
        <Text level={1} className="text-grey-600 text-sm line-clamp-1">
          {email}
        </Text>
      </div>

      <Button
        size="sm"
        variant="outline"
        color={"blue"}
        disabled={isLoading}
        className={`rounded-full mb-2 lg:mb-0 w-full lg:w-auto lg:mr-2`}
        onClick={onMakeLimitedAccess}
      >
        {t("manageUsers_accessRequest_limited_makeAdmin")}
      </Button>

      <Button
        size="sm"
        variant="outline"
        color={"green"}
        isLoading={isLoading}
        className={`rounded-full w-full lg:w-auto`}
        onClick={() => {
          onUpdateUser({ userId: user.id, accessLevel: "admin" });
        }}
      >
        {t("manageUsers_accessRequest_makeAdmin")}
      </Button>
    </div>
  );
};
