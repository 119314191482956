import { BannerInfoWhite, BannerInfoYellow, XIcon } from "Assets";
import ReactDOM from "react-dom";
import { Text } from "UIKit";
import { useMediaQuery } from "Hooks";

interface LDNotificationBannerProps {
  text: string;
  drawerExpanded: boolean;
  onClose: () => void;
}

export const LDNotificationBanner: React.FC<LDNotificationBannerProps> = ({
  text,
  drawerExpanded,
  onClose,
}) => {
  const media = useMediaQuery();
  const isDesktop = media === "lg" || media === "xl" || media === "2xl";
  return ReactDOM.createPortal(
    <div
      className={`flex space-y-2 fixed top-2 xl:top-auto xl:bottom-8 left-4 right-4  xl:right-28 z-50 overflow-y-auto overflow-x-hidden bg-white xl:bg-yellow-50 xl:border-2 xl:border-yellow-200 rounded-lg items-center p-2 xl:p-6 ${
        drawerExpanded ? "xl:left-72" : "xl:left-28"
      }`}
    >
      <div className="flex items-center flex-1">
        <div className="rounded-sm w-10 h-10 flex bg-yellow-200 xl:bg-yellow-600 justify-center items-center min-w-max flex-shrink-0">
          {!isDesktop ? (
            <BannerInfoYellow className="w-7 h-7" />
          ) : (
            <BannerInfoWhite className="w-7 h-7" />
          )}
        </div>
        <Text level={1} className="ml-3 xl:ml-8 text-sm xl:text-lg">
          {text}
        </Text>
      </div>
      <XIcon onClick={onClose} className="cursor-pointer w-4 h-4 justify-self-end" />
    </div>,
    document.body,
  );
};
