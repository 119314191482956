import { useTranslation } from "react-i18next";

import { AccessRequest } from "Utilities/types";
import { Button, Text, FormFieldError } from "UIKit";
import { useAccessRequests } from "Hooks/usersService";
import { ChatIcon, CheckCircleIcon, UserIcon, XCircleTwoIcon } from "Assets";

export interface AccessRequestEntryProps {
  accessRequest: AccessRequest;
  refetchUsers(): void;
  requestTextTitle: string;
}

export const AccessRequestEntry: React.FC<AccessRequestEntryProps> = ({
  accessRequest,
  refetchUsers,
  requestTextTitle,
}) => {
  const { t } = useTranslation();

  const {
    acceptError,
    declineError,
    acceptLoading,
    declineLoading,
    onAcceptRequest,
    onDeclineRequest,
  } = useAccessRequests(refetchUsers);

  return (
    <div className="flex flex-col">
      <div className="flex items-start px-6 py-4">
        <UserIcon className="h-8 w-8 mr-4" />

        <div className="grid grid-cols-1 xl:grid-cols-3 items-center gap-2 w-full">
          <div className="grid grid-cols-1 xl:grid-cols-2 xl:col-span-2 gap-2 flex-1 items-center">
            <Text level={1} className="flex-1 line-clamp-1">
              {`${accessRequest.givenName} ${accessRequest.familyName}`}
            </Text>

            <Text level={2} className="flex-2 text-grey-700 line-clamp-1">
              {accessRequest.email}
            </Text>
          </div>

          <div className="order-2 xl:order-1 grid grid-cols-2 gap-2">
            <Button
              size="xs"
              color="grey"
              variant="outline"
              isLoading={acceptLoading}
              className="text-green rounded-full"
              onClick={() => onAcceptRequest(accessRequest.userId)}
            >
              <CheckCircleIcon className="w-4 h-4 mr-1" />
              <Text level={2}>{t("manageUsers_access_request_approve")}</Text>
            </Button>
            <Button
              size="xs"
              color="grey"
              variant="outline"
              isLoading={declineLoading}
              className="text-red rounded-full"
              onClick={() => onDeclineRequest(accessRequest.id)}
            >
              <XCircleTwoIcon className="w-4 h-4 mr-1" />
              <Text level={2}>{t("manageUsers_access_request_decline")}</Text>
            </Button>
          </div>

          {accessRequest.requestText ? (
            <div className="order-1 xl:order-2 xl:col-span-3">
              <Text level={2} className="text-grey-600 font-semibold">
                {requestTextTitle}
              </Text>
              <div className="flex items-start gap-2">
                <ChatIcon height={16} width={16} className="mt-1" />
                <Text level={1} className="w-full">
                  {accessRequest.requestText}
                </Text>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {(acceptError || declineError) && (
        <FormFieldError className="self-end">{acceptError || declineError}</FormFieldError>
      )}
    </div>
  );
};
