import React from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

export type TitleLevel = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export interface TitleProps extends React.ComponentPropsWithoutRef<"h1"> {
  level: TitleLevel;
}

const titleStyles: Record<TitleLevel, string> = {
  "1": "text-7xl font-extrabold", // Heading 1 in Figma
  "2": "text-6xl font-extrabold", // Heading 2 in Figma
  "3": "text-5xl font-extrabold", // Heading 3 in Figma
  "4": "text-4xl font-bold", // Title 1 in Figma
  "5": "text-3xl font-bold", // Title 2 in Figma
  "6": "text-lg md:text-2xl font-bold", // Subtitle 1 in Figma
  "7": "text-xl font-bold", // Subtitle 2 in Figma
  "8": "text-xl font-bold", // Subtitle 3 in Figma
  "9": "text-base font-bold", // Subtitle 4 in Figma
};

export const Title: React.FC<TitleProps> = ({ level, className, ...props }) => {
  const element = `h${Math.min(level, 6)}`;

  const titleClass = tw(`${titleStyles[level]} ${className}`);

  return React.createElement(element, { className: titleClass, ...props });
};
