import { queryApollo } from "API/functions/queryApollo";
import { mutateApollo } from "API/functions/mutateApollo";
import {
  EventForm,
  EventType,
  EventSummary,
  FeedbackSummary,
  FeedbackForm,
  FeedbackAggregatedValue,
  Assessor,
} from "../types";

import { makeBffApolloClient } from "API/functions/makeBffApolloClient";
import {
  EventListSummaryDocument,
  FeedbackListSummaryDocument,
  EventFormTemplateDocument,
  CreateEventFormDocument,
  EventFormByIdDocument,
  EventFormCreateInput,
  EventFormUpdateInput,
  SubmitEventForReviewDocument,
  UpdateEventFormDocument,
  SubmitNewEventForReviewDocument,
  EventFormSubmitNewInput,
  CompleteEventFormDocument,
  AssessorSummary,
  AssessorsSummaryDocument,
  GetOrCreateFeedbackFormDocument,
  SubmitFeedbackDocument,
  FeedbackFormUpdateInput,
  FeedbackAggregatedSummaryDocument,
  EventFormTemplatesSummaryDocument,
  EventFormCompleteNewInput,
  CompleteNewEventFormDocument,
  EventFeedbackDocument,
  GetAssignedAssessorsDocument,
  SendReminderAssessorDocument,
  EventRequestInput,
  GetRecentlyAssignedAssessorsDocument,
  GetSupervisorsListDocument,
  ReassignAssessorDocument,
  SupervisorsList,
  SupervisionSummary,
  CreateSupervisionRequestDocument,
  CreateSupervisionRequestInput,
  CancelSupervisionRequestDocument,
  RemoveSupervisionDocument,
  GetSuperviseeListDocument,
  SuperviseeList,
  ApproveSupervisionRequestDocument,
  DeclineSupervisionRequestDocument,
} from "modules/portfolio/client/portfolio.graphql";

export const portfolioClient = makeBffApolloClient("portfolio");

export const getEventsSummary = async (ownerId?: string) => {
  const eventSummary: EventSummary[] = await queryApollo(portfolioClient, {
    query: EventListSummaryDocument,
    variables: {
      userId: ownerId,
    },
  });

  return eventSummary;
};

export const getFeedbackSummary = async () => {
  const feedbackSummary: FeedbackSummary[] = await queryApollo(portfolioClient, {
    query: FeedbackListSummaryDocument,
    variables: {},
  });
  return feedbackSummary;
};
export const getEventFormTemplate = async (eventType: EventType) => {
  const eventFormTemplate = await queryApollo(portfolioClient, {
    query: EventFormTemplateDocument,
    variables: { eventType },
  });

  return eventFormTemplate;
};

export const createEvent = async (eventFormBody: EventFormCreateInput) => {
  const eventForm: EventForm = await mutateApollo(portfolioClient, {
    mutation: CreateEventFormDocument,
    variables: { eventFormBody },
  });

  return eventForm;
};

export const updateEvent = async ({
  eventId,
  eventBody,
}: {
  eventId: string;
  eventBody: EventFormUpdateInput;
}) => {
  const { date, title, areAttachmentsShared, eventValues } = eventBody;
  const eventForm: EventForm = await mutateApollo(portfolioClient, {
    mutation: UpdateEventFormDocument,
    variables: {
      eventId,
      input: { date, title, areAttachmentsShared, eventValues },
    },
  });

  return eventForm;
};

export const getEventForm = async (eventId: string, ownerId?: string) => {
  const eventForm = await queryApollo(portfolioClient, {
    query: EventFormByIdDocument,
    variables: { eventId, userId: ownerId },
  });

  return eventForm;
};

export const getEventFormTemplates = async () => {
  const eventForm = await queryApollo(portfolioClient, {
    query: EventFormTemplatesSummaryDocument,
    variables: {},
  });

  return eventForm;
};

export const submitEventForReview = async ({
  eventId,
  eventBody,
}: {
  eventId: string;
  eventBody: EventFormUpdateInput;
}) => {
  const { date, title, eventValues, areAttachmentsShared, eventRequest } = eventBody;
  const eventForm: EventForm = await mutateApollo(portfolioClient, {
    mutation: SubmitEventForReviewDocument,
    variables: {
      eventId,
      input: { date, title, eventValues, areAttachmentsShared, eventRequest },
    },
  });

  return eventForm;
};

export const submitNewEventForReview = async ({
  eventBody,
}: {
  eventBody: EventFormSubmitNewInput;
}) => {
  const eventForm: EventForm = await mutateApollo(portfolioClient, {
    mutation: SubmitNewEventForReviewDocument,
    variables: {
      input: eventBody,
    },
  });

  return eventForm;
};

export const completeEventForm = async ({
  eventId,
  eventBody = {},
}: {
  eventId: string;
  eventBody?: EventFormUpdateInput;
}) => {
  const { date, title, eventValues, areAttachmentsShared } = eventBody;
  const eventForm: EventForm = await mutateApollo(portfolioClient, {
    mutation: CompleteEventFormDocument,
    variables: { eventId, input: { date, title, eventValues, areAttachmentsShared } },
  });

  return eventForm;
};

export const completeNewEventForm = async ({
  eventBody,
}: {
  eventBody: EventFormCompleteNewInput;
}) => {
  const eventForm: EventForm = await mutateApollo(portfolioClient, {
    mutation: CompleteNewEventFormDocument,
    variables: {
      input: eventBody,
    },
  });

  return eventForm;
};

export const getAssessorsSummary = async (eventId: string) => {
  const assessorsSummary: AssessorSummary[] = await queryApollo(portfolioClient, {
    query: AssessorsSummaryDocument,
    variables: { eventId },
  });
  return assessorsSummary;
};

export const getOrCreateFeedbackForm = async (eventId: string) => {
  const feedbackForm: FeedbackForm = await mutateApollo(portfolioClient, {
    mutation: GetOrCreateFeedbackFormDocument,
    variables: { eventId },
  });
  return feedbackForm;
};

export const submitFeedback = async ({
  eventId,
  feedbackId,
  feedbackBody,
}: {
  eventId: string;
  feedbackId: string;
  feedbackBody: FeedbackFormUpdateInput;
}) => {
  const feedbackForm: FeedbackForm = await mutateApollo(portfolioClient, {
    mutation: SubmitFeedbackDocument,
    variables: {
      eventId,
      feedbackId,
      input: feedbackBody,
    },
  });
  return feedbackForm;
};

export const getFeedbackAggregatedSummary = async (eventId: string) => {
  const feedbackAggregatedSummary: FeedbackAggregatedValue[] = await queryApollo(portfolioClient, {
    query: FeedbackAggregatedSummaryDocument,
    variables: { eventId },
  });
  return feedbackAggregatedSummary;
};

export const getEventFeedback = async (eventId: string) => {
  const eventFeedback = await queryApollo(portfolioClient, {
    query: EventFeedbackDocument,
    variables: { eventId },
  });

  return eventFeedback;
};

export const getAssignedAssessors = async (eventId: string): Promise<Assessor[]> => {
  const assignedAssessors = await queryApollo(portfolioClient, {
    query: GetAssignedAssessorsDocument,
    variables: { eventId },
  });
  return assignedAssessors;
};

export const sendReminderAssessor = async ({
  eventId,
  reminderBody,
}: {
  eventId: string;
  reminderBody: EventRequestInput;
}): Promise<Assessor> => {
  const remindedAssessor = await mutateApollo(portfolioClient, {
    mutation: SendReminderAssessorDocument,
    variables: {
      eventId,
      input: reminderBody,
    },
  });
  return remindedAssessor;
};

export const getRecentlyAssingedAssessors = async (ownerId: string): Promise<Assessor[]> => {
  const recentlyAssginedAssessors = await queryApollo(portfolioClient, {
    query: GetRecentlyAssignedAssessorsDocument,
    variables: { ownerId },
  });
  return recentlyAssginedAssessors;
};

export const reassignAssessor = async ({
  eventId,
  reassignBody,
}: {
  eventId: string;
  reassignBody: EventRequestInput;
}): Promise<Assessor> => {
  const reassignedAssessor = await mutateApollo(portfolioClient, {
    mutation: ReassignAssessorDocument,
    variables: {
      eventId,
      input: reassignBody,
    },
  });
  return reassignedAssessor;
};

export const getSupervisorsList = async (ownerId: string): Promise<SupervisorsList> => {
  const supervisorsList = await queryApollo(portfolioClient, {
    query: GetSupervisorsListDocument,
    variables: { ownerId },
  });
  return supervisorsList;
};

export const getSuperviseesList = async (ownerId: string): Promise<SuperviseeList> => {
  const superviseesList = await queryApollo(portfolioClient, {
    query: GetSuperviseeListDocument,
    variables: { ownerId },
  });
  return superviseesList;
};

export const createSupervisionRequest = async ({
  appType,
  supervisorEmail,
  createdBy,
  ownerId,
}: CreateSupervisionRequestInput): Promise<SupervisionSummary> => {
  const supervisionRequest = await mutateApollo(portfolioClient, {
    mutation: CreateSupervisionRequestDocument,
    variables: {
      input: { appType, supervisorEmail, createdBy, ownerId },
    },
  });
  return supervisionRequest;
};

export const cancelSupervisionRequest = async (
  supervisionRequestId: string,
): Promise<{ deleted: boolean }> => {
  const supervisionDeleted = await mutateApollo(portfolioClient, {
    mutation: CancelSupervisionRequestDocument,
    variables: {
      supervisionRequestId,
    },
  });
  return supervisionDeleted;
};

export const removeSupervision = async (supervisionId: string): Promise<{ deleted: boolean }> => {
  const supervisionDeleted = await mutateApollo(portfolioClient, {
    mutation: RemoveSupervisionDocument,
    variables: {
      supervisionId,
    },
  });
  return supervisionDeleted;
};

export const approveSupervisionRequest = async ({
  approvingUserId,
  approvingUserEmail,
  supervisionRequestId,
}: any) => {
  const approvedSupervisionRequest = await mutateApollo(portfolioClient, {
    mutation: ApproveSupervisionRequestDocument,
    variables: {
      supervisionRequestId,
      input: { approvingUserEmail, approvingUserId },
    },
  });
  return approvedSupervisionRequest;
};

export const declineSupervisionRequest = async ({
  decliningUserId,
  decliningUserEmail,
  supervisionRequestId,
}: any) => {
  const declinedSupervisionRequest = await mutateApollo(portfolioClient, {
    mutation: DeclineSupervisionRequestDocument,
    variables: {
      supervisionRequestId,
      input: { decliningUserEmail, decliningUserId },
    },
  });
  return declinedSupervisionRequest;
};
