import { DeleteModal, SuccessModal } from "UIKit";
import useDeleteContentItem from "modules/contentRepository/data/useDeleteContentItem";
import { ContentItem } from "modules/contentRepository/types";
import { useTranslation } from "react-i18next";

interface DeleteContentItemModalProps {
  contentItem: ContentItem;
  onClose: () => void;
}

const DeleteContentItemModal = ({ contentItem, onClose }: DeleteContentItemModalProps) => {
  const { t } = useTranslation();

  const {
    deleteContentItem,
    deletingContentItem,
    deleteContentItemSuccess,
    deleteContentItemError,
  } = useDeleteContentItem();

  const handleDeleteSection = () => {
    deleteContentItem(contentItem);
  };

  if (deleteContentItemSuccess) {
    return <SuccessModal onComplete={onClose} text={t("repository_item_delete_successful")} />;
  }

  return (
    <div data-testid="delete-content-item-modal">
      <DeleteModal
        title={t("repository_item_delete_title")}
        onCloseModal={onClose}
        onDelete={handleDeleteSection}
        isLoading={deletingContentItem}
        error={deleteContentItemError}
        description={t("repository_item_delete_description")}
      />
    </div>
  );
};

export default DeleteContentItemModal;
