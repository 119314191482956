import React from "react";

import { useRequestStatus } from "Utilities";
import { errorStore } from "Stores/ErrorStore";

import { SetTokensFn } from "./types";
import { loginTokenService } from "API/LoginTokenService";

export const useLoginToken = (token: string, setTokens: SetTokensFn) => {
  const { isLoading, setRequestStatus } = useRequestStatus();
  const [tooltipText, setTooltipText] = React.useState<string>("manageUsers_copy_login_link");

  const onCopyClicked = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/auto-login/${encodeURIComponent(token)}`,
    );
    setTooltipText("manageUsers_login_tokens_link_copied");
  };

  const afterTooltipHide = () => setTooltipText("manageUsers_login_tokens_copy_link");

  const onDeleteToken = React.useCallback(async () => {
    setRequestStatus({ status: "pending", error: "" });

    try {
      await loginTokenService.deleteToken(token);
      setTokens((tokens) => tokens.filter((t) => t !== token));
    } catch (err: any) {
      errorStore.captureError({ error: err.message, source: "user" });
      setRequestStatus({ status: "error", error: err.message });
    }
  }, [token, setTokens, setRequestStatus]);

  return {
    isLoading,
    tooltipText,
    onDeleteToken,
    onCopyClicked,
    afterTooltipHide,
  };
};
