import { DeleteModal, SuccessModal } from "UIKit";
import useDeleteContentSection from "modules/contentRepository/data/useDeleteContentSection";
import { ContentSection, ContentRepositoryId } from "modules/contentRepository/types";
import { useTranslation } from "react-i18next";

interface DeleteContentSectionModalProps {
  contentRepositoryId: ContentRepositoryId;
  contentSection: ContentSection;
  onClose: () => void;
}

const DeleteContentSectionModal = ({
  contentRepositoryId,
  contentSection,
  onClose,
}: DeleteContentSectionModalProps) => {
  const { t } = useTranslation();

  const {
    deleteContentSection,
    deletingContentSection,
    deleteContentSectionSuccess,
    deleteContentSectionError,
  } = useDeleteContentSection();

  const handleDeleteSection = () => {
    deleteContentSection({ parentID: contentRepositoryId, sectionId: contentSection.id });
  };

  if (deleteContentSectionSuccess) {
    return <SuccessModal onComplete={onClose} text={t("my_files_delete_section_successful")} />;
  }

  return (
    <div data-testid="delete-content-section-modal">
      <DeleteModal
        title={t("my_files_delete_section")}
        onCloseModal={onClose}
        onDelete={handleDeleteSection}
        isLoading={deletingContentSection}
        error={deleteContentSectionError}
        description={t("deleteModal_delete_section_description")}
      />
    </div>
  );
};

export default DeleteContentSectionModal;
