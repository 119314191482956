import { overrideTailwindClasses as tw } from "tailwind-override";

export interface InnerModalWrapperProps {
  children: React.ReactNode;
  "data-testid"?: string;
}

export const InnerModalWrapper: React.FC<InnerModalWrapperProps> = ({
  children,
  "data-testid": dataTestId,
}) => {
  return (
    <div
      className={tw(`
        flex flex-col bg-white shadow-xl
        rounded-b-none rounded-t-sm md:rounded-b-lg md:rounded-t-lg
        max-h-90vh h-auto w-screen md:w-90vw lg:w-85vw xl:w-60vw 2xl:w-40vw relative overflow-hidden
      `)}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};
